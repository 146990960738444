import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class TimeValidator {

    toast: any;

    constructor(private toastCtrl: ToastController) {

    }

    validateHours(inputElement) {
        const initalValue = inputElement.value;
        inputElement.value = initalValue.replace(/[^0-9]*/g, '');
        if (inputElement.value) {
            if (parseInt(inputElement.value) > 12 || parseInt(inputElement.value) < 0 || (inputElement.value.length === 2 && inputElement.value == 0)) {
                inputElement.value = '01';
                if (!this.toast) {
                    this.showToast('hours');
                    this.toast = null;
                }
            }
            if (inputElement.value.length === 1 && inputElement.value != 0) {
                inputElement.value = '0' + inputElement.value;
            }

            if (inputElement.value.length > 2) {
                var hh = inputElement.value;
                inputElement.value = hh.replace(/^0+/, '');
            }
            else {
                inputElement.value = inputElement.value;
            }
        }

        if (initalValue !== inputElement.value) {
            // event.stopPropagation();
        }

        return inputElement.value;
    }

    validateMinutes(inputElement) {
        const initalValue = inputElement.value;
        inputElement.value = initalValue.replace(/[^0-9]*/g, '');
        if (inputElement.value) {
            if (parseInt(inputElement.value) > 59 || parseInt(inputElement.value) < 0) {
                inputElement.value = '00';
                if (!this.toast) {
                    this.showToast('minutes');
                }
            }
            if (inputElement.value.length === 1 && inputElement.value != 0) {
                inputElement.value = '0' + inputElement.value;
            }
            if (inputElement.value.length > 2) {
                var mm = inputElement.value;
                inputElement.value = mm.replace(/^0+/, '');
            }
            else {
                inputElement.value = inputElement.value;
            }

        }

        if (initalValue !== inputElement.value) {
            // event.stopPropagation();
        }

        return inputElement.value;

    }

    async showToast(inputType) {
        this.toast = await this.toastCtrl.create({
            message: inputType == 'hours' ? 'Hours should be between 1 to 12' : 'Minutes should be between 1 to 59',
            duration: 3000,
            position: 'top',
            color: "danger",
            cssClass: "toast-message"
        });

        this.toast.present();

        await this.toast.onDidDismiss();

        this.toast = null;
    }
}