import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { FilterHelper } from 'src/app/helpers/filter-helper';
import { LineItemService } from 'src/app/services/line-item/line-item.service';
import { WorkTicketService } from 'src/app/services/work-ticket/work-ticket.service';

@Component({
  selector: 'occ-filter-functional',
  templateUrl: './filter-functional.component.html',
  styleUrls: ['./filter-functional.component.scss'],
})
export class FilterFunctionalAreaComponent {
	@Output() closeFilter = new EventEmitter();
	@Output() navigate = new EventEmitter();

	functionalArea: string[];
	isWorkTicketSearchPage: false;
	pageReturn: string;

	subscribe = true;

	constructor(public lineItemSvc: LineItemService, public wktkSvc: WorkTicketService, public filterHelper: FilterHelper) {
		this.wktkSvc.functionalAreaWorkSubject.pipe(takeWhile(() => this.subscribe)).subscribe(functionalArea => {
			this.functionalArea = functionalArea.sort();
		});
		this.pageReturn = this.filterHelper.workticketSearchPage ? 'workticketPage' : 'selection';
	}

	hideFilter(): void {
		this.closeFilter.emit();
	}

	showPage(pageToShow): void {
		this.navigate.emit(pageToShow);
	}

	applyFilter(functionalArea: string): void {
		this.filterHelper.setFilter(functionalArea, this.filterHelper.functionalArea, 'FunctionalAreaFilter');
	}

	getCurrentCategory(functionalArea: string): boolean {
		if (this.filterHelper.functionalArea.find(x => x === functionalArea)) return true;
	}

	resetFilter(): void {
		this.filterHelper.functionalArea = [];
		this.filterHelper.applyFilters();
	}
}

