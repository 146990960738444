import { Injectable } from "@angular/core";
import { Storage } from '@ionic/storage-angular';

@Injectable({
    providedIn: 'root'
  })
  export class ErrorLogService { 
    errorData: any = [];
    constructor(private storage: Storage) {

    }

    async insert(name: string, data: any) {
        this.errorData = await this.get();
        if(this.errorData == null)
        {
            this.errorData = [];
        }
        this.errorData.push( {name: name, data: data});
        this.storage.set('error-log', this.errorData);
    }

    async get() {
      return await this.storage.get('error-log');
    }

    clear() {
        this.storage.remove('error-log');
    }
  }