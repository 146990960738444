import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';
import * as _ from 'lodash';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { ChangeOrdersProvider } from 'src/app/services/change-orders/change-orders.service';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';

@Component({
  selector: 'change-log-filter',
  templateUrl: './change-log-filter.component.html',
  styleUrls: ['./change-log-filter.component.scss'],
})
export class ChangeLogFilterComponent implements OnInit {
  @Input() changeOrderData?= [];
  @Input() boothChangeOrderData?= [];
  @Input() isBoothChangeOrder?= false;
  @Output() closeFilter?= new EventEmitter();
  @Output() filterChangeLogData?= new EventEmitter();
  selectedCriteria = {
    id: 0,
    name: "",
    value: ""
  };
  criteriaOptionsData = {
    vendors: [],
    invoices: [],
    locations: [],
    categories: [],
    changeTypes: ["New Order", "Revised Order", "Cancelled Order"],
    changeRequestData: ["Yes", "No"],
    createdUsers: [],
    revisionReasons: ["Quantity Changed", "Revised Comments", "Invoice Change", "DateTime Change", "Facility Location Change"]
  };

  desktopDatePickerOptions: IAngularMyDpOptions = {
    alignSelectorRight: true,
    dateFormat: 'mm/dd/yyyy',
    // showClearDateBtn: false
  };

  allSelectedCriteriaItems: any[];
  applyButtonEnabled = false;
  errorMessage: string;
  lowerSetupStartDate: any;
  upperSetupStartDate: any;
  lowerSetupCompleteDate: any;
  upperSetupCompleteDate: any;
  lowerCreatedDate: any;
  upperCreatedDate: any;
  lowerRevisedDate: any;
  upperRevisedDate: any;

  // modified datepicker dates
  datepickerLowerSetupStartDate: any;
  datepickerUpperSetupStartDate: any;
  datepickerLowerSetupCompleteDate: any;
  datepickerUpperSetupCompleteDate: any;
  datepickerLowerCreatedDate: any;
  datepickerUpperCreatedDate: any;
  datepickerLowerRevisedDate: any;
  datepickerUpperRevisedDate: any;

  eventId: string;
  accountId: string;

  constructor(public appBrowserHelper: AppBrowserHelper,
    public changeOrderProvider: ChangeOrdersProvider,
    public dataSvc: DataRefreshService,) {
    this.eventId = this.dataSvc.eventActive;
    this.accountId = this.dataSvc.showAccountActive;
    if (this.changeOrderProvider.eventId != this.eventId || this.changeOrderProvider.accountId != this.accountId) {
      this.changeOrderProvider.eventId = this.eventId;
      this.changeOrderProvider.accountId = this.accountId;
      this.changeOrderProvider.resetChangeLogFilterSelection();
    }

  }

  ngOnInit(): void {
    if (this.isBoothChangeOrder) {
      this.boothChangeOrderData.forEach(changeOrderData => {
        changeOrderData.vendorName ? this.criteriaOptionsData.vendors.push(changeOrderData.vendorName) : "";
        changeOrderData.booth ? this.criteriaOptionsData.invoices.push(changeOrderData.booth) : "";
        changeOrderData.locationInfo ? this.criteriaOptionsData.locations.push(changeOrderData.locationInfo) : "";
        changeOrderData.category ? this.criteriaOptionsData.categories.push(changeOrderData.category) : "";
        changeOrderData.createdBy ? this.criteriaOptionsData.createdUsers.push(changeOrderData.createdBy) : "";
      });
    } else {
      this.changeOrderData.forEach(changeOrderData => {
        if (changeOrderData.changeOrder) {
          changeOrderData.changeOrder.vendorName ? this.criteriaOptionsData.vendors.push(changeOrderData.changeOrder.vendorName) : "";
          changeOrderData.changeOrder.booth ? this.criteriaOptionsData.invoices.push(changeOrderData.changeOrder.booth) : "";
          changeOrderData.changeOrder.locationInfo ? this.criteriaOptionsData.locations.push(changeOrderData.changeOrder.locationInfo) : "";
          changeOrderData.changeOrder.category ? this.criteriaOptionsData.categories.push(changeOrderData.changeOrder.category) : "";
          changeOrderData.changeOrder.createdBy ? this.criteriaOptionsData.createdUsers.push(changeOrderData.changeOrder.createdBy) : "";
        }
      });
    }

    this.criteriaOptionsData.vendors = _.uniq(this.criteriaOptionsData.vendors, true);
    this.criteriaOptionsData.invoices = _.uniq(this.criteriaOptionsData.invoices, true);
    this.criteriaOptionsData.locations = _.uniq(this.criteriaOptionsData.locations, true);
    this.criteriaOptionsData.categories = _.uniq(this.criteriaOptionsData.categories, true);
    this.criteriaOptionsData.createdUsers = _.uniq(this.criteriaOptionsData.createdUsers, true);

    this.allSelectedCriteriaItems = this.changeOrderProvider.getChangeLogFilterSelection();

    if (this.allSelectedCriteriaItems.length == 0) {
      this.changeOrderProvider.resetChangeLogFilterSelection();
    }

    this.initCriteriaDates();
  }

  hideFilter() {
    this.closeFilter.emit();
  }

  navigateBackCriteria() {
    this.selectedCriteria = { id: 0, name: "", value: "" };
  }

  openOptionsForCriteria(selectedCriteria) {
    this.selectedCriteria = selectedCriteria;
    if ((this.selectedCriteria.id == 2 || this.selectedCriteria.id == 3 || this.selectedCriteria.id == 9 || this.selectedCriteria.id == 10) && this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].value.length == 2) {
      this.checkValidDateEntry();
    }
  }

  onDateChanged(event, key) {

  }

  checkValidDateEntry() {
    setTimeout(() => {
      this.applyButtonEnabled = false;
      switch (this.selectedCriteria.id) {
        case 2:
          this.lowerSetupStartDate = this.datepickerLowerSetupStartDate ? this.datepickerLowerSetupStartDate.singleDate.jsDate.toISOString() : this.lowerSetupStartDate;
          this.upperSetupStartDate = this.datepickerUpperSetupStartDate ? this.datepickerUpperSetupStartDate.singleDate.jsDate.toISOString() : this.upperSetupStartDate;
          this.validateDate(this.lowerSetupStartDate, this.upperSetupStartDate);
          break;
        case 3:
          this.lowerSetupCompleteDate = this.datepickerLowerSetupCompleteDate ? this.datepickerLowerSetupCompleteDate.singleDate.jsDate.toISOString() : this.lowerSetupCompleteDate;
          this.upperSetupCompleteDate = this.datepickerUpperSetupCompleteDate ? this.datepickerUpperSetupCompleteDate.singleDate.jsDate.toISOString() : this.upperSetupCompleteDate;
          this.validateDate(this.lowerSetupCompleteDate, this.upperSetupCompleteDate);
          break;
        case 9:
          this.lowerCreatedDate = this.datepickerLowerCreatedDate ? this.datepickerLowerCreatedDate.singleDate.jsDate.toISOString() : this.lowerCreatedDate;
          this.upperCreatedDate = this.datepickerUpperCreatedDate ? this.datepickerUpperCreatedDate.singleDate.jsDate.toISOString() : this.upperCreatedDate;
          this.validateDate(this.lowerCreatedDate, this.upperCreatedDate);
          break;
        case 10:
          this.lowerRevisedDate = this.datepickerLowerRevisedDate ? this.datepickerLowerRevisedDate.singleDate.jsDate.toISOString() : this.lowerRevisedDate;
          this.upperRevisedDate = this.datepickerUpperRevisedDate ? this.datepickerUpperRevisedDate.singleDate.jsDate.toISOString() : this.upperRevisedDate;
          this.validateDate(this.lowerRevisedDate, this.upperRevisedDate);
          break;
        default:
          break;
      }
    }, 500);
  }

  validateDate(lowerLimitDate, upperLimitDate) {
    if (lowerLimitDate && upperLimitDate) {
      let lowerBoundDate;
      let upperBoundDate;
      if (typeof lowerLimitDate == "object") {
        lowerBoundDate = new Date(lowerLimitDate.formatted);
      } else {
        lowerBoundDate = new Date(lowerLimitDate);
      }

      if (typeof upperLimitDate == "object") {
        upperBoundDate = new Date(upperLimitDate.formatted);
      } else {
        upperBoundDate = new Date(upperLimitDate);
      }

      if (lowerBoundDate > upperBoundDate) {
        this.applyButtonEnabled = false;
        this.errorMessage = 'Please enter a valid date range.';
      } else {
        this.applyButtonEnabled = true;
        this.errorMessage = '';
      }
    }
  }

  applyDateFilter() {
    switch (this.selectedCriteria.id) {
      case 2:
        this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].value = [];
        if (this.datepickerLowerSetupStartDate && this.datepickerUpperSetupCompleteDate) {
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].dateValue = [];
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].dateValue.push(this.datepickerLowerSetupStartDate);
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].dateValue.push(this.datepickerUpperSetupStartDate);
        }
        if (typeof this.lowerSetupStartDate == "object") {
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].value.push(this.lowerSetupStartDate.formatted);
        } else {
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].value.push(this.lowerSetupStartDate);
        }

        if (typeof this.upperSetupStartDate == "object") {
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].value.push(this.upperSetupStartDate.formatted);
        } else {
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].value.push(this.upperSetupStartDate);
        }
        break;
      case 3:
        this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].value = [];
        if (this.datepickerLowerSetupCompleteDate && this.datepickerUpperSetupCompleteDate) {
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].dateValue = [];
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].dateValue.push(this.datepickerLowerSetupCompleteDate);
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].dateValue.push(this.datepickerUpperSetupCompleteDate);
        }
        if (typeof this.lowerSetupCompleteDate == "object") {
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].value.push(this.lowerSetupCompleteDate.formatted);
        } else {
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].value.push(this.lowerSetupCompleteDate);
        }

        if (typeof this.upperSetupCompleteDate == "object") {
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].value.push(this.upperSetupCompleteDate.formatted);
        } else {
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].value.push(this.upperSetupCompleteDate);
        }
        break;
      case 9:
        this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].value = [];
        if (this.datepickerLowerCreatedDate && this.datepickerUpperCreatedDate) {
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].dateValue = [];
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].dateValue.push(this.datepickerLowerCreatedDate);
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].dateValue.push(this.datepickerUpperCreatedDate);
        }
        if (typeof this.lowerCreatedDate == "object") {
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].value.push(this.lowerCreatedDate.formatted);
        } else {
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].value.push(this.lowerCreatedDate);
        }

        if (typeof this.upperCreatedDate == "object") {
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].value.push(this.upperCreatedDate.formatted);
        } else {
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].value.push(this.upperCreatedDate);
        }
        break;
      case 10:
        this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].value = [];
        if (this.datepickerLowerRevisedDate && this.datepickerUpperRevisedDate) {
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].dateValue = [];
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].dateValue.push(this.datepickerLowerRevisedDate);
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].dateValue.push(this.datepickerUpperRevisedDate);
        }
        if (typeof this.lowerRevisedDate == "object") {
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].value.push(this.lowerRevisedDate.formatted);
        } else {
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].value.push(this.lowerRevisedDate);
        }

        if (typeof this.upperRevisedDate == "object") {
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].value.push(this.upperRevisedDate.formatted);
        } else {
          this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].value.push(this.upperRevisedDate);
        }
        break;
      default:
        break;
    }

    this.applyFilterOnData();
    this.navigateBackCriteria();
  }

  setOptionSelection(selectedValue) {
    let selectedOptionIndex = this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].value.findIndex((itemValue) => {
      return selectedValue == itemValue;
    });

    if (selectedOptionIndex >= 0) {
      this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].value.splice(selectedOptionIndex, 1);
    } else {
      this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].value.push(selectedValue);
    }

    this.applyFilterOnData();
  }

  clearAllFilters() {
    this.allSelectedCriteriaItems[0].value = [];
    this.allSelectedCriteriaItems[1].value = [];
    this.allSelectedCriteriaItems[2].value = [];
    this.allSelectedCriteriaItems[3].value = [];
    this.allSelectedCriteriaItems[4].value = [];
    this.allSelectedCriteriaItems[5].value = [];
    this.allSelectedCriteriaItems[6].value = [];
    this.allSelectedCriteriaItems[7].value = [];
    this.allSelectedCriteriaItems[8].value = [];
    this.allSelectedCriteriaItems[9].value = [];
    this.allSelectedCriteriaItems[10].value = [];
    this.allSelectedCriteriaItems[11].value = [];

    this.allSelectedCriteriaItems[2].dateValue = [];
    this.allSelectedCriteriaItems[3].dateValue = [];
    this.allSelectedCriteriaItems[9].dateValue = [];
    this.allSelectedCriteriaItems[10].dateValue = [];


    this.lowerSetupStartDate = "";
    this.datepickerLowerSetupStartDate = "";
    this.upperSetupStartDate = "";
    this.datepickerUpperSetupStartDate = "";

    this.lowerSetupCompleteDate = "";
    this.datepickerLowerSetupCompleteDate = "";
    this.upperSetupCompleteDate = "";
    this.datepickerUpperSetupCompleteDate = "";

    this.lowerCreatedDate = "";
    this.datepickerLowerCreatedDate = "";
    this.upperCreatedDate = "";
    this.datepickerUpperCreatedDate = "";

    this.lowerRevisedDate = "";
    this.datepickerLowerRevisedDate = "";
    this.upperRevisedDate = "";
    this.datepickerUpperRevisedDate = "";

    this.applyFilterOnData();
  }

  clearCurrentSelectedFilter() {
    this.allSelectedCriteriaItems[this.selectedCriteria.id - 1].value = [];

    switch (this.selectedCriteria.id) {
      case 2:
        this.lowerSetupStartDate = "";
        this.datepickerLowerSetupStartDate = "";
        this.upperSetupStartDate = "";
        this.datepickerUpperSetupStartDate = "";
        break;
      case 3:
        this.lowerSetupCompleteDate = "";
        this.datepickerLowerSetupCompleteDate = "";
        this.upperSetupCompleteDate = "";
        this.datepickerUpperSetupCompleteDate = "";
        break;
      case 9:
        this.lowerCreatedDate = "";
        this.datepickerLowerCreatedDate = "";
        this.upperCreatedDate = "";
        this.datepickerUpperCreatedDate = "";
        break;
      case 10:
        this.lowerRevisedDate = "";
        this.datepickerLowerRevisedDate = "";
        this.upperRevisedDate = "";
        this.datepickerUpperRevisedDate = "";
        break;
      default:
        break;
    }
    this.applyFilterOnData();
  }

  applyFilterOnData() {
    this.changeOrderProvider.setChangeLogFilterSelection(this.allSelectedCriteriaItems);
    this.filterChangeLogData.emit();
  }

  initCriteriaDates() {
    if (this.appBrowserHelper.isDesktop) {
      if (this.allSelectedCriteriaItems[1].value.length == 2) {
        this.lowerSetupStartDate = { formatted: this.allSelectedCriteriaItems[1].value[0] };
        this.upperSetupStartDate = { formatted: this.allSelectedCriteriaItems[1].value[1] };
        this.datepickerLowerSetupStartDate = this.allSelectedCriteriaItems[1].dateValue[0];
        this.datepickerUpperSetupStartDate = this.allSelectedCriteriaItems[1].dateValue[1];
      }
      if (this.allSelectedCriteriaItems[2].value.length == 2) {
        this.lowerSetupCompleteDate = { formatted: this.allSelectedCriteriaItems[2].value[0] };
        this.upperSetupCompleteDate = { formatted: this.allSelectedCriteriaItems[2].value[1] };
        this.datepickerLowerSetupCompleteDate = this.allSelectedCriteriaItems[2].dateValue[0];
        this.datepickerUpperSetupCompleteDate = this.allSelectedCriteriaItems[2].dateValue[1];
      }
      if (this.allSelectedCriteriaItems[8].value.length == 2) {
        this.lowerCreatedDate = { formatted: this.allSelectedCriteriaItems[8].value[0] };
        this.upperCreatedDate = { formatted: this.allSelectedCriteriaItems[8].value[1] };
        this.datepickerLowerCreatedDate = this.allSelectedCriteriaItems[8].dateValue[0];
        this.datepickerUpperCreatedDate = this.allSelectedCriteriaItems[8].dateValue[1];
      }
      if (this.allSelectedCriteriaItems[9].value.length == 2) {
        this.lowerRevisedDate = { formatted: this.allSelectedCriteriaItems[9].value[0] };
        this.upperRevisedDate = { formatted: this.allSelectedCriteriaItems[9].value[1] };
        this.datepickerLowerRevisedDate = this.allSelectedCriteriaItems[9].dateValue[0];
        this.datepickerUpperRevisedDate = this.allSelectedCriteriaItems[9].dateValue[1];
      }
    } else {
      if (this.allSelectedCriteriaItems[1].value.length == 2) {
        this.lowerSetupStartDate = this.allSelectedCriteriaItems[1].value[0];
        this.upperSetupStartDate = this.allSelectedCriteriaItems[1].value[1];
      }
      if (this.allSelectedCriteriaItems[2].value.length == 2) {
        this.lowerSetupCompleteDate = this.allSelectedCriteriaItems[2].value[0];
        this.upperSetupCompleteDate = this.allSelectedCriteriaItems[2].value[1];
      }
      if (this.allSelectedCriteriaItems[8].value.length == 2) {
        this.lowerCreatedDate = this.allSelectedCriteriaItems[8].value[0];
        this.upperCreatedDate = this.allSelectedCriteriaItems[8].value[1];
      }
      if (this.allSelectedCriteriaItems[9].value.length == 2) {
        this.lowerRevisedDate = this.allSelectedCriteriaItems[9].value[0];
        this.upperRevisedDate = this.allSelectedCriteriaItems[9].value[1];
      }
    }
  }

}
