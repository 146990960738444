import { CollaborationLineItemDTO } from './collaboration-line-item-dto';
import { FunctionalArea } from './functional-area';
import { WorkTicketDTO } from './work-ticket-dto';
import { ChangeOrderDTO } from './change-order-dto';

export class SearchItem {
	public functionalArea?: FunctionalArea;
	public lineItem?: CollaborationLineItemDTO;
	public lineItems?: CollaborationLineItemDTO[];
	public workTicket?: WorkTicketDTO;
	public changeOrder?: ChangeOrderDTO;

	constructor(fa?: FunctionalArea, li?: CollaborationLineItemDTO, lineItems?: CollaborationLineItemDTO[], wt?: WorkTicketDTO, co?: ChangeOrderDTO) {
		this.functionalArea = fa;
		this.lineItem = li;
		this.lineItems = lineItems;
		this.workTicket = wt;
		this.changeOrder = co;
	}
}
