import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavParams, ActionSheetController } from '@ionic/angular';
import { MentionConfig } from 'angular-mentions';
import { CollaborationLineItemDTO } from 'src/app/models/collaboration-line-item-dto';
import { ActivityService } from 'src/app/services/activity/activity.service';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';
import { UserService } from 'src/app/services/user/user.service';
import { WorkticketActivityService } from 'src/app/services/workticket-activity/workticket-activity.service';
import { takeWhile } from 'rxjs/operators';
import { CameraLinkToPhotoVerificationHelper } from 'src/app/helpers/camera-intermediary';
import { BoothServiceProvider } from 'src/app/services/booth/booth.service';
import { AuthTokenHelper } from 'src/app/helpers/auth-token-helper';
import { EsBoothsService } from 'src/app/services/es-booths/es-booths.service';

@Component({
	selector: 'app-photo-verification',
	templateUrl: './photo-verification.component.html',
	styleUrls: ['./photo-verification.component.scss'],
})
export class PhotoVerificationComponent implements OnInit, OnDestroy {

	public eventId: string;
	public accountId: string;
	public functionalAreaId: string;
	public lineItemId: string;
	public wtId: string;
	public cb: Function;
	public optionalNote: string;
	@Input() serviceRequestGUID: any;
	subscribe = true;
	mentionConfig: MentionConfig;
	opsMentionConfig: MentionConfig;
	boothGuid: string;
	boothLineItem: CollaborationLineItemDTO;

	constructor(
		public dataSvc: DataRefreshService,
		public navParams: NavParams,
		public activitySvc: ActivityService,
		public userSvc: UserService,
		public workTikActivitySvc: WorkticketActivityService,
		public cameraIntermediary: CameraLinkToPhotoVerificationHelper,
		public actionSheetCtrl: ActionSheetController,
		public boothService: BoothServiceProvider,
		public authTokenHelper: AuthTokenHelper,
		public esBoothService: EsBoothsService
	) {

		this.eventId = this.navParams.data.eventId;
		this.accountId = this.navParams.data.accountId;
		this.lineItemId = this.navParams.data.lineItemId;
		this.functionalAreaId = this.navParams.data.functionalAreaId;
		this.wtId = this.navParams.data.wtId;
		this.cb = this.navParams.data.cb;
		this.boothGuid = this.navParams.data.boothGuid;
		this.boothLineItem = this.navParams.data.boothLineItem;
	}

	ngOnInit() {
		this.userSvc.mentionsConfig.pipe(takeWhile(() => this.subscribe)).subscribe(mentionConfig => {
			this.mentionConfig = mentionConfig;
		});
		this.userSvc.opsMentionsConfig.pipe(takeWhile(() => this.subscribe)).subscribe(mentionConfig => {
			this.opsMentionConfig = mentionConfig;
		});
	}

	async presentWorkTicketCameraOptionsModal() {
		const actionSheet = await this.actionSheetCtrl.create({
			header: 'Add a Photo',
			buttons: [
				{
					text: 'Take a Photo',
					handler: () => {
						this.cameraIntermediary.takeWorkTicketPicture(false);
					}
				},
				{
					text: 'Upload From Gallery',
					handler: () => {
						this.cameraIntermediary.takeWorkTicketPicture(true);
					}
				},
				{
					text: 'Cancel',
					role: 'cancel',
				}
			]
		});
		await actionSheet.present();
	}

	confirmPhotoUpload() {
		if (this.wtId) {
			this.cameraIntermediary.queuedImages.forEach(imageString => {
				this.workTikActivitySvc.addPhoto(this.eventId, this.wtId, imageString, this.optionalNote);
			});
		} else if (this.boothGuid) {
			this.cameraIntermediary.queuedImages.forEach(imageString => {
				if (this.authTokenHelper.isExhibitUser) {
					this.esBoothService.addPhoto(this.eventId, this.boothGuid, this.boothLineItem, imageString, this.optionalNote);
				} else {
					this.boothService.addPhoto(this.eventId, this.boothGuid, this.boothLineItem, imageString, this.optionalNote);
				}
			});
		} else if(this.serviceRequestGUID) {
			this.cameraIntermediary.queuedImages.forEach(imageString => {
				this.esBoothService.addPhoto(this.eventId, this.boothGuid, this.boothLineItem, imageString, this.optionalNote, this.serviceRequestGUID);
			});
		} else {
			this.cameraIntermediary.queuedImages.forEach(imageString => {
				this.activitySvc.addPhoto(this.eventId, this.accountId, this.functionalAreaId, this.lineItemId, imageString, this.optionalNote);
			});
		}
		this.cb();
	}

	addAnotherPhoto(event) {
		this.cameraIntermediary.queueImages(event);
	}

	cancel() {
		this.cb(this.eventId, this.wtId, this.accountId, this.functionalAreaId, this.lineItemId);
	}

	ngOnDestroy() {
		this.subscribe = false;
		this.cameraIntermediary.queuedImages = [];
	}

	setMentionConfig() {
		if (this.wtId) {
			return this.opsMentionConfig;
		} else {
			return this.mentionConfig;
		}
	}

}