import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { KioskService } from 'src/app/services/kiosk/kiosk.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CreateMhaService } from 'src/app/services/create-mha/create-mha.service';

@Component({
  selector: 'occ-kiosk-freight-details',
  templateUrl: './kiosk-freight-details.component.html',
  styleUrls: ['./kiosk-freight-details.component.scss'],
})
export class KioskFreightDetailsComponent implements OnInit {
  @Input() accountName: any;
  @Output() onNext = new EventEmitter<any>();
  @Output() cancelMhaCreation = new EventEmitter();
  @Output() onFormInvalid = new EventEmitter<any>();
  @Output() loadNextMHASection = new EventEmitter<any>();
  @Output() loadPreviousMHASection = new EventEmitter<any>();

  shippingInfoForm: FormGroup;
  formValidated: any;
  isFormInvalid: boolean = false;
  freightDetailForm: FormGroup;
  isCartonsButtonClicked: boolean=false;
  isCratesButtonClicked: boolean=false;
  isCasesButtonClicked: boolean=false;
  isSkidsButtonClicked: boolean=false;
  isCarpetsButtonClicked: boolean=false;
  isRollsButtonClicked: boolean=false;
  isOtherButtonClicked: boolean=false;
  isWtValid: boolean = false;
  isPadQtyValid: boolean = false;
  isOtherQtyValid: boolean = false;
  isCarpetQtyValid: boolean = false;
  isSkidQtyValid: boolean = false;
  isCaseQty: boolean = false;
  isCartonQty: boolean = false;
  isCrateQty: boolean = false;
   
  constructor(
    public router: Router, 
    public kioskService: KioskService,
    public createMHAService: CreateMhaService, 
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.initializeFreightDetailForm(this.createMHAService.createMhaData.freight);
    this.createMHAService.currentMHADataSubject.subscribe(mhaData => {
      if (mhaData != null) {
        this.initializeFreightDetailForm(this.createMHAService.createMhaData.freight);
      }
    });
  }

  initializeFreightDetailForm(data = null) {
    this.freightDetailForm = this.formBuilder.group({
      totalWeight: new FormControl(data && data.totalWeight || null, [Validators.required, Validators.pattern(/^[1-9][0-9]*$/)]),

      loadingDockConsent: new FormControl(data && data.loadingDockConsent || 'N', []),
      resDeliveryConsent: new FormControl(data && data.resDeliveryConsent || 'N', []),
      insideDeliveryConsent: new FormControl(data && data.insideDeliveryConsent || 'N', []),
      liftGateConsent: new FormControl(data && data.liftGateConsent || 'N', []),
      padWrapConsent: new FormControl(data && data.padWrapConsent || 'N', []),
      airConsent: new FormControl(data && data.airConsent || 'N', []),
      stackedConsent: new FormControl(data && data.stackedConsent || 'N', []),
      additionalInstruction: new FormControl(data && data.additionalInstruction || null, []),

      deliveryOption: new FormControl(data && data.deliveryOption || null, [Validators.required]),

      crateQty: new FormControl(data && data.crateQty || null, [Validators.pattern(/^[0-9]*$/)]),
      crateComments: new FormControl(data && data.crateComments || null, []),
      cartonQty: new FormControl(data && data.cartonQty || null, [Validators.pattern(/^[0-9]*$/)]),
      cartonComments: new FormControl(data && data.cartonComments || null, []),
      caseQty: new FormControl(data && data.caseQty || null, [Validators.pattern(/^[0-9]*$/)]),
      caseColor: new FormControl(data && data.caseColor || null, []),
      caseComments: new FormControl(data && data.caseComments || null, []),
      skidQty: new FormControl(data && data.skidQty || null, [Validators.pattern(/^[0-9]*$/)]),
      skidWrapped: new FormControl(data && data.skidWrapped || false, []),
      skidComments: new FormControl(data && data.skidComments || null, []),
      carpetQty: new FormControl(data && data.carpetQty || null, [Validators.pattern(/^[0-9]*$/)]),
      carpetColor: new FormControl(data && data.carpetColor || null, []),
      carpetWrapped: new FormControl(data && data.carpetWrapped || false, []),
      carpetComments: new FormControl(data && data.carpetComments || null, []),
      padQty: new FormControl(data && data.padQty || null, [Validators.pattern(/^[0-9]*$/)]),
      padWrapped: new FormControl(data && data.padWrapped || false, []),
      padComments: new FormControl(data && data.padComments || null, []),
      otherQty: new FormControl(data && data.otherQty || null, [Validators.pattern(/^[0-9]*$/)]),
      otherComments: new FormControl(data && data.otherComments || null, []),
    })
    this.checkBtnClickValidations();
    this.checkInputChangeValidations();
  }

  cratesButtonClicked(){
    this.isCratesButtonClicked=!this.isCratesButtonClicked;
    if(!this.isCratesButtonClicked){
      this.freightDetailForm.patchValue({
        crateQty: null
      });
    }
  }
  cartonsButtonClicked(){
    this.isCartonsButtonClicked=!this.isCartonsButtonClicked;
    if(!this.isCartonsButtonClicked){
      this.freightDetailForm.patchValue({
        cartonQty: null
      });
    }
  }
  casesButtonClicked(){
    this.isCasesButtonClicked=!this.isCasesButtonClicked;
    if(!this.isCasesButtonClicked){
      this.freightDetailForm.patchValue({
        caseQty: null,
        caseColor: null
      });
    }
  }
  skidsButtonClicked(){
    this.isSkidsButtonClicked=!this.isSkidsButtonClicked;
    if(!this.isSkidsButtonClicked){
      this.freightDetailForm.patchValue({
        skidQty: null
      });
    }
  }
  carpetsButtonClicked(){
    this.isCarpetsButtonClicked=!this.isCarpetsButtonClicked;
    if(!this.isCarpetsButtonClicked){
      this.freightDetailForm.patchValue({
        carpetQty: null,
        carpetColor: null
      });
    }
  }
  rollsButtonClicked(){
    this.isRollsButtonClicked=!this.isRollsButtonClicked;
    if(!this.isRollsButtonClicked){
      this.freightDetailForm.patchValue({
        padQty: null
      });
    }
  }
  OtherButtonClicked(){
    this.isOtherButtonClicked=!this.isOtherButtonClicked;
    if(!this.isOtherButtonClicked){
      this.freightDetailForm.patchValue({
        otherQty: null
      });
    }
  }

  deliveryOptionMessage(): string {
    let deliveryOptionMessage = '';
    switch (this.freightDetailForm.value.deliveryOption) {
      case 'GROUND':
        deliveryOptionMessage = 'Delivery time depends on distance';
        break;
      case 'NEXT_DAY':
        deliveryOptionMessage = 'Deliver next business day';
        break;
      case 'SECOND_DAY':
        deliveryOptionMessage = 'Deliver second business day';
        break;
      case 'THIRD_FIFTH_DAY':
        deliveryOptionMessage = 'Deliver within 3-5 business days';
        break;
      case 'SPECIAL':
        deliveryOptionMessage = 'Volume, Van Line, Flatbed, Truckload';
        break;
      default:
        break;
    }
    return deliveryOptionMessage;
  }
  cancel(){
    this.cancelMhaCreation.emit();
  }

  goToPreviousStep() {
    this.loadPreviousMHASection.emit(3);
  }

  saveNext(){
    this.formValidated = true;
    this.createMHAService.createMhaData.freight = this.freightDetailForm.value;
    if (this.createMHAService.isMHASubmitted) {
      this.loadNextMHASection.emit(5);
    } else {
      if (this.freightDetailForm.valid && this.checkItemsValidation())
        this.loadNextMHASection.emit(5);
      else
        this.onFormInvalid.emit();
    }
  }

  checkItemsValidation(): boolean{
    let isPieceCountFormValid = true;
    if(this.isCartonsButtonClicked && !this.freightDetailForm.value.cartonQty){
      isPieceCountFormValid = false;
    }
    if(this.isCratesButtonClicked && !this.freightDetailForm.value.crateQty){
      isPieceCountFormValid = false;
    }
    if(this.isCasesButtonClicked && !this.freightDetailForm.value.caseQty){
      isPieceCountFormValid = false;
    }
    if(this.isSkidsButtonClicked && !this.freightDetailForm.value.skidQty){
      isPieceCountFormValid = false;
    }
    if(this.isCarpetsButtonClicked && !this.freightDetailForm.value.carpetQty){
      isPieceCountFormValid = false;
    }
    if(this.isRollsButtonClicked && !this.freightDetailForm.value.padQty){
      isPieceCountFormValid = false;
    }
    if(this.isOtherButtonClicked && !this.freightDetailForm.value.otherQty){
      isPieceCountFormValid = false;
    }
    return isPieceCountFormValid;
  }

  checkBtnClickValidations(){
    //crateButton
    this.isCratesButtonClicked = this.freightDetailForm.value.crateQty ? true : false;
    this.freightDetailForm.get("crateQty").valueChanges.subscribe(qtyValue => {
      this.isCratesButtonClicked = qtyValue ? true : false;
    });
    //cartonButton
    this.isCartonsButtonClicked = this.freightDetailForm.value.cartonQty ? true : false;
    this.freightDetailForm.get("cartonQty").valueChanges.subscribe(qtyValue => {
      this.isCartonsButtonClicked = qtyValue ? true : false;
    });
    //caseButton
    this.isCasesButtonClicked = (this.freightDetailForm.value.caseQty || this.freightDetailForm.value.caseColor) ? true : false;
    this.freightDetailForm.get("caseQty").valueChanges.subscribe(qtyValue => {
      this.isCasesButtonClicked = (qtyValue || this.freightDetailForm.value.caseColor) ? true : false;
    });
    // case color
    this.isCasesButtonClicked = this.freightDetailForm.value.caseColor ? true : false;
    this.freightDetailForm.get("caseColor").valueChanges.subscribe(colorValue => {
      this.isCasesButtonClicked = colorValue  ? true : false;
    });
    //skidButton
    this.isSkidsButtonClicked = this.freightDetailForm.value.skidQty ? true : false;
    this.freightDetailForm.get("skidQty").valueChanges.subscribe(qtyValue => {
      this.isSkidsButtonClicked = qtyValue ? true : false;
    });
    //carpetdButton
    this.isCarpetsButtonClicked = (this.freightDetailForm.value.carpetColor || this.freightDetailForm.value.carpetQty) ? true : false;
    this.freightDetailForm.get("carpetQty").valueChanges.subscribe(qtyValue => {
      this.isCarpetsButtonClicked = (qtyValue || this.freightDetailForm.value.carpetColor) ? true : false;
    });
    // carpet color
    this.isCarpetsButtonClicked = this.freightDetailForm.value.carpetColor ? true : false;
    this.freightDetailForm.get("carpetColor").valueChanges.subscribe(colorValue => {
      this.isCarpetsButtonClicked = colorValue ? true : false;
    });
    //padButton
    this.isRollsButtonClicked = this.freightDetailForm.value.padQty ? true : false;
    this.freightDetailForm.get("padQty").valueChanges.subscribe(qtyValue => {
      this.isRollsButtonClicked = qtyValue ? true : false;
    });
    //otherButton
    this.isOtherButtonClicked = this.freightDetailForm.value.otherQty ? true : false;
    this.freightDetailForm.get("otherQty").valueChanges.subscribe(qtyValue => {
      this.isOtherButtonClicked = qtyValue ? true : false;
    });
  }

  checkInputChangeValidations(){
    this.freightDetailForm.get("totalWeight").valueChanges.subscribe(wtValue => {
      if(wtValue>0 && this.freightDetailForm.controls.totalWeight.status == "INVALID"){
        this.isWtValid = true;
      } else{
        this.isWtValid = false;
      }
    });
    this.freightDetailForm.get("crateQty").valueChanges.subscribe(crateValue => {
      if(crateValue && this.freightDetailForm.controls.crateQty.status == "INVALID"){
        this.isCrateQty = true;
      } else{
        this.isCrateQty = false;
      }
    });
    this.freightDetailForm.get("cartonQty").valueChanges.subscribe(cartonValue => {
      if(cartonValue && this.freightDetailForm.controls.cartonQty.status == "INVALID"){
        this.isCartonQty = true;
      } else{
        this.isCartonQty = false;
      }
    });
    this.freightDetailForm.get("caseQty").valueChanges.subscribe(caseValue => {
      if(caseValue && this.freightDetailForm.controls.caseQty.status == "INVALID"){
        this.isCaseQty = true;
      } else{
        this.isCaseQty = false;
      }
    });
    this.freightDetailForm.get("skidQty").valueChanges.subscribe(skidValue => {
      if(skidValue && this.freightDetailForm.controls.skidQty.status == "INVALID"){
        this.isSkidQtyValid = true;
      } else{
        this.isSkidQtyValid = false;
      }
    });
    this.freightDetailForm.get("carpetQty").valueChanges.subscribe(carpetValue => {
      if(carpetValue && this.freightDetailForm.controls.carpetQty.status == "INVALID"){
        this.isCarpetQtyValid = true;
      } else{
        this.isCarpetQtyValid = false;
      }
    });
    this.freightDetailForm.get("padQty").valueChanges.subscribe(padValue => {
      if(padValue && this.freightDetailForm.controls.padQty.status == "INVALID"){
        this.isPadQtyValid = true;
      } else{
        this.isPadQtyValid = false;
      }
    });
    this.freightDetailForm.get("otherQty").valueChanges.subscribe(otherValue => {
      if(otherValue && this.freightDetailForm.controls.otherQty.status == "INVALID"){
        this.isOtherQtyValid = true;
      } else{
        this.isOtherQtyValid = false;
      }
    });
  }
}