import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MentionConfig } from 'angular-mentions';
import { takeWhile } from 'rxjs/operators';
import { StyleHelper } from 'src/app/helpers/style-helper';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'occ-activity-add-comment-mobile',
  templateUrl: './activity-add-comment-mobile.component.html',
  styleUrls: ['./activity-add-comment-mobile.component.scss'],
})
export class ActivityAddCommentMobileComponent implements OnInit {

  defaultValue: any = '';
  subscribe: any = true;
  mentionConfig: MentionConfig;
  @Output() addNote = new EventEmitter<boolean>();

  constructor(
    private modalCtrl: ModalController,
    public userService: UserService,
    public styleHelper: StyleHelper
  ) { 
  }
  
  ngOnInit() {
    this.userService.mentionsConfig.pipe(takeWhile(() => this.subscribe)).subscribe(mentionConfig => {
      if (mentionConfig)
        this.mentionConfig = mentionConfig;
    });
  }

  postNote(body) {
    this.modalCtrl.dismiss(body);
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
