import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { FilterHelper } from 'src/app/helpers/filter-helper';
import { SelectHelper } from 'src/app/helpers/select-helper';
import { Owner } from 'src/app/models/owner';
import { FunctionalAreaService } from 'src/app/services/functional-area/functional-area.service';

@Component({
  selector: 'occ-filter-owner',
  templateUrl: './filter-owner.component.html',
  styleUrls: ['./filter-owner.component.scss'],
})
export class FilterOwnerComponent implements OnInit {

	public ownerList: Owner[];
	public subscribe: boolean;
	@Input() isFunctionalAreasPage: boolean;

	@Output() filterOwner = new EventEmitter();
	@Output() closeFilter = new EventEmitter();
	@Output() navigate = new EventEmitter();

	isWorkTicketSearchPage: false;
	pageReturn: string;

	constructor(
		public functionalAreaSvc: FunctionalAreaService,
		public filterHelper: FilterHelper
	) {
		this.filterHelper.workticketSearchPage ? this.pageReturn = 'workticketPage' : 'selection';
	}

	ngOnInit() {
		this.subscribe = true;
		this.functionalAreaSvc.ownerList.pipe(takeWhile(() => this.subscribe)).subscribe(owners => {
			this.ownerList = owners.sort((a, b) => {
				if (a.owner.toLowerCase() < b.owner.toLowerCase()) { return -1; }
				if (a.owner.toLowerCase() > b.owner.toLowerCase()) { return 1; }
				return 0;
			});
		});
	}

	hideFilter(): void {
		this.closeFilter.emit();
	}

	getCurrentOwner(owner: string): boolean {
		if (this.isFunctionalAreasPage) {
			if (this.filterHelper.functionalAreasPageOwnerFilter.find(x => x.owner === owner)) return true;
		} else {
			if (this.filterHelper.functionalAreaOwner.find(x => x.owner === owner)) return true;
		}
	}

	applyFilter(owner: Owner): void {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		this.filterOwner.emit(owner);

		if (!this.isFunctionalAreasPage) {
			this.filterHelper.setFilter(owner, this.filterHelper.functionalAreaOwner, 'FunctionalAreaOwnerFilter');
		}
	}

	showPage(pageToShow): void {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		this.navigate.emit(pageToShow);
	}

	resetFilter(): void {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		// if (!this.isFunctionalAreasPage) this.filterHelper.ApplyFilters();
		if (!this.isFunctionalAreasPage) {
			this.filterHelper.functionalAreaOwner = [];
			this.filterHelper.applyFilters();
		}
		this.filterOwner.emit(null);
	}
}
