import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FunctionalArea } from 'src/app/models/functional-area';
import { SelectableOption } from 'src/app/models/selectable-option';
import { Show } from 'src/app/models/show';
import { ShowAccount } from 'src/app/models/show-account';
import { environment } from 'src/environments/environment';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { NewOrderDTO } from 'src/app/models/new-order-dto';
import { CollaborationLineItemDTO } from 'src/app/models/collaboration-line-item-dto';
import { ShowBooth } from 'src/app/models/show-booth-dto';
import { EventService } from 'src/app/services/event/event.service';
import { ShowAccountService } from 'src/app/services/show-account/show-account.service';
import { FunctionalAreaService } from 'src/app/services/functional-area/functional-area.service';
import { LoadingController, ModalController, PopoverController, IonContent, NavParams } from '@ionic/angular';
import { NewOrderProvider } from 'src/app/services/new-order/new-order.service';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';
import { HttpClient } from '@angular/common/http';
import { ToastService } from 'src/app/services/toast/toast.service';
import { BoothServiceProvider } from 'src/app/services/booth/booth.service';
import { takeWhile } from 'rxjs/operators';
import { ChangeOrderConfirmationModalComponent } from 'src/app/components/change-order-confirmation-modal/change-order-confirmation-modal.component';
import { OccMobileSelectModalComponent } from 'src/app/components/occ-mobile-select-modal/occ-mobile-select-modal.component';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'occ-new-order-modal',
  templateUrl: './new-order-modal.page.html',
  styleUrls: ['./new-order-modal.page.scss'],
})

export class NewOrderModalPage implements OnInit {

  @ViewChild('content') content: IonContent;
  loading: any;
  url = environment.apiUrl;

  show: Show;
  showAccount: ShowAccount;
  showName: string = "";
  private subscribe = true;
  selectedOptionsData: SelectableOption[][] = [];

  functionalAreas: FunctionalArea[] = [];

  isSearchResultEnable: boolean = false;
  searchResultSet = [];
  visibleResultSet = [];
  myDatePickerOptions: IAngularMyDpOptions = {
    alignSelectorRight: true,
    dateFormat: 'mm/dd/yyyy'
    //width: '250px',
    // height: '42px',
    // showTodayBtn: false,
    // indicateInvalidDate: true
  };
  eventId: string;
  accountId: string;
  partsList: any[];
  boothsList: any[];
  facilityList: any[];
  newOrderDataModel: any = {};
  locationList: any[];
  selectedFA: FunctionalArea;
  isTypeOverPart: boolean;
  isLaborPart: boolean;
  newOrderSaveData: NewOrderDTO;
  isSetUpStartDateTimeValid: boolean = true;
  isSetUpCompleteDateTimeValid: boolean = true;
  isTeardownStartDateTimeValid: boolean = true;
  isTeardownCompleteDateTimeValid: boolean = true;
  isSetUpSDConflict: boolean = false;
  isSetUpCDConflict: boolean = false;
  isTeardownSDConflict: boolean = false;
  isTeardownCDConflict: boolean = false;
  isFASelectOpen: boolean = false;
  isCollaborativePart: boolean = false;
  isPastShowOpenDate: boolean = false;
  isPartNumberNull: boolean = true;
  copiedLineItemdata: CollaborationLineItemDTO;
  boothDetails: ShowBooth;
  boothGUID: string;
  boothLineItemID: string;
  moveOutDate: Date;
  moveOutConvertedDate: Date;
  myDatePickerOptionsTeardown: IAngularMyDpOptions = {
    alignSelectorRight: true,
    dateFormat: 'mm/dd/yyyy'
    //width: '250px',
    // height: '42px',
    // showTodayBtn: false,
    // indicateInvalidDate: true
  };;

  datepickerMobileSetupStartDate: any;
  datepickerMobileSetupCompleteDate: any;
  datepickerMobileTearDownStartDate: any;
  datepickerMobileTearDownCompleteDate: any;

  @ViewChild('faSelectList') faSelectList: ElementRef;
  @ViewChild('desktopResultView') desktopResultView: ElementRef;
  @ViewChild('mobileResultView') mobileResultView: ElementRef;

  @HostListener('document:click', ['$event.target'])

  public onDocumentClick(target) {
    this.isFASelectOpen = false;
  }


  constructor(private activatedRoute: ActivatedRoute, private router: Router, private navParams: NavParams, public eventSvc: EventService, public showAcctSvc: ShowAccountService, public funcSvc: FunctionalAreaService, public popoverCtrl: PopoverController, public modalCtrl: ModalController, public newOrderService: NewOrderProvider, public dataSvc: DataRefreshService, public http: HttpClient, public toastService: ToastService, public loadingCtrl: LoadingController, public boothService: BoothServiceProvider) {
    this.eventId = this.dataSvc.eventActive;
    this.accountId = this.dataSvc.showAccountActive;
  }

  ngOnInit(): void {
    //this.eventId = this.activatedRoute.snapshot.paramMap.get('boothGUID');
    this.onSearchParamChange = _.debounce(this.onSearchParamChange, 300);
    this.boothGUID = this.navParams.data.boothGUID;
    this.boothLineItemID = this.navParams.data.boothLineItemID;
    this.eventSvc.currentEvent.pipe(takeWhile(() => this.subscribe)).subscribe(s => {
      this.show = s;
      if(this.show){
          this.showName = this.show.tssShowNumber ? (this.show.name + " (" + this.show.tssShowNumber + ")") : this.show.name
          this.moveOutConvertedDate = this.show.moveOutDate ? new Date(this.show.moveOutDate) : new Date("2030-08-22") ;
          this.moveOutConvertedDate.setDate(this.moveOutConvertedDate.getDate() + 1);
          this.moveOutDate = this.show.moveOutDate ? this.show.moveOutDate : new Date("2030-08-22") ;
      }
    });
    this.myDatePickerOptionsTeardown = {
      alignSelectorRight: true,
      dateFormat: 'mm/dd/yyyy',
      // height: '42px',
      // showTodayBtn: false,
      // indicateInvalidDate: true,
      disableSince: {year: Number(this.moveOutConvertedDate.getFullYear()), month: Number(this.moveOutConvertedDate.getUTCMonth()) + 1, day: Number(this.moveOutConvertedDate.getUTCDate())}
    };
    this.showAcctSvc.currentShowAccount.pipe(takeWhile(() => this.subscribe)).subscribe(sa => {
      this.showAccount = sa;
    });

    this.boothService.activeBooth.pipe(takeWhile(() => this.subscribe)).subscribe(boothData => {
      if (boothData) {
        this.boothDetails = boothData;
        this.accountId = this.boothDetails.showAccountGUID;
      }
    });

    this.funcSvc.allFunctionalAreasSubject.pipe(takeWhile(() => this.subscribe)).subscribe(functionalAreas => {
      this.functionalAreas = functionalAreas;
    });

    this.isPastShowOpenDate = this.eventSvc.getShowOpenDatePassedStatus();

    this.selectedFA = this.funcSvc.currentFunctionalArea;

    this.partsList = this.newOrderService.searchParts;
    this.boothsList = this.newOrderService.boothData;
    this.facilityList = this.newOrderService.facilityData;
    this.copiedLineItemdata = this.navParams.get('lineItemData');
    this.initializeNewOrderDataModel();
  }

  initializeNewOrderDataModel() {
    if (this.copiedLineItemdata) {
      if (this.boothGUID) {
        this.newOrderDataModel.functionalAreaValue = this.boothService.emptyFAGUID;
        this.newOrderDataModel.functionalAreaDisplay = "";
        this.newOrderDataModel.invoiceValue = this.boothDetails.boothGUID;
        this.newOrderDataModel.invoiceDisplay = this.boothDetails.boothName;
      } else {
        let functionalArea = this.functionalAreas.find(element => element.functionalAreaGuid == this.copiedLineItemdata.functionalAreaGuid);
        if (functionalArea) {
          this.newOrderDataModel.functionalAreaValue = functionalArea.functionalAreaGuid;
          this.newOrderDataModel.functionalAreaDisplay = functionalArea.name;
        }

        let boothData = this.boothsList.find(boothData => boothData.name == this.copiedLineItemdata.booth);
        if (boothData) {
          this.newOrderDataModel.invoiceValue = boothData.kafkaId;
          this.newOrderDataModel.invoiceDisplay = boothData.name;
        }
      }

      this.newOrderDataModel.searchParam = "";
      const selectedPart = this.partsList.find(partElement => this.copiedLineItemdata.partNumber.toString() == (partElement.groupNumber + "-" + partElement.subGroupNumber + "-" + partElement.partNumber));
      if (selectedPart) {
        this.newOrderDataModel.searchSelectedPart = selectedPart;
        this.checkIsPartNumberNull();
        this.newOrderDataModel.unitOfMeasureValue = this.getUnitShortVersion(selectedPart.unitOfMeasureDescription);
        this.newOrderDataModel.minQuantityValue = selectedPart.minimumQuantity;
        this.newOrderDataModel.stdPrice = "$" + parseFloat(selectedPart.price).toFixed(2);
        this.newOrderDataModel.partNumberString = selectedPart.groupNumber + "-" + selectedPart.subGroupNumber + "-" + selectedPart.partNumber;
        this.newOrderDataModel.partPropertyExtensionId = selectedPart.partPropertyExtensionId;
        this.setMandatoryDateValidation();
        this.checkLaborPart();
        this.newOrderDataModel.menValue = this.copiedLineItemdata.men;
        this.newOrderDataModel.hoursValue = this.copiedLineItemdata.hours;
        this.newOrderDataModel.quantityValue = this.copiedLineItemdata.quantity;

      } else {
        this.newOrderDataModel.searchSelectedPart = {};
        this.newOrderDataModel.unitOfMeasureValue = "";
        this.newOrderDataModel.minQuantityValue = "";
        this.newOrderDataModel.stdPrice = "";
        this.newOrderDataModel.partNumberString = "";
        this.newOrderDataModel.partPropertyExtensionId = null;
      }

      if (this.copiedLineItemdata.partSelections == null) {
        this.newOrderDataModel.selecatableOptionValue = [];
      } else {
        this.newOrderDataModel.selecatableOptionValue = this.copiedLineItemdata.partSelections;
      }

      this.getSelectableOptionsData();

      this.newOrderDataModel.locationValue = "";
      this.newOrderDataModel.locationDisplay = "";

      let facilityData = this.facilityList.find(facilityItem => facilityItem.kafkaId == this.copiedLineItemdata.showFacilityGuid);
      if (facilityData) {
        this.newOrderDataModel.facilityValue = facilityData.kafkaId;
        this.newOrderDataModel.facilityDisplay = facilityData.name;
        this.getLocationData();
      }

      this.newOrderDataModel.itemComment = this.copiedLineItemdata.vendorComments;
      this.newOrderDataModel.workTicketComment = this.copiedLineItemdata.ticketComments;
      this.newOrderDataModel.invoiceComment = this.copiedLineItemdata.invoiceComments;
      this.newOrderDataModel.isChangeRequest = false;
      this.newOrderDataModel.isCopyItemComment = false;

      if (this.copiedLineItemdata.setupDate) {
        this.newOrderDataModel.desktopSetupStartDateTime = this.copiedLineItemdata.setupDate;
        let setupStartDateTime = new Date(this.copiedLineItemdata.setupDate);
        this.newOrderDataModel.mobileSetupStartDate = {
          date: {
            year: setupStartDateTime.getUTCFullYear(),
            month: setupStartDateTime.getUTCMonth() + 1,
            day: setupStartDateTime.getUTCDate()
          },
          jsDate: setupStartDateTime
        };
        this.datepickerMobileSetupStartDate = {
          singleDate: this.newOrderDataModel.mobileSetupStartDate
        };
        this.newOrderDataModel.mobileSetupStartTime = ('0' + setupStartDateTime.getUTCHours()).slice(-2) + ":" + ('0' + setupStartDateTime.getUTCMinutes()).slice(-2);
        this.onDesktopSetUpDateChange(true);
        this.onMobileSetupDateChange(true);
      }

      if (this.copiedLineItemdata.endDate) {
        this.newOrderDataModel.desktopSetupCompleteDateTime = this.copiedLineItemdata.endDate;
        let setupEndDateTime = new Date(this.copiedLineItemdata.endDate);
        this.newOrderDataModel.mobileSetupCompleteDate = {
          date: {
            year: setupEndDateTime.getUTCFullYear(),
            month: setupEndDateTime.getUTCMonth() + 1,
            day: setupEndDateTime.getUTCDate()
          },
          jsDate: setupEndDateTime
        };
        this.datepickerMobileSetupCompleteDate = {
          singleDate: this.newOrderDataModel.mobileSetupCompleteDate
        };
        this.newOrderDataModel.mobileSetupCompleteTime = ('0' + setupEndDateTime.getUTCHours()).slice(-2) + ":" + ('0' + setupEndDateTime.getUTCMinutes()).slice(-2);
        this.onDesktopSetUpDateChange(false);
        this.onMobileSetupDateChange(false);
      }

      if (this.copiedLineItemdata.teardownStartDate) {
        this.newOrderDataModel.desktopTearDownStartDateTime = this.copiedLineItemdata.teardownStartDate;
        let teardownStartDate = new Date(this.copiedLineItemdata.teardownStartDate);
        this.newOrderDataModel.mobileTearDownStartDate = {
          date: {
            year: teardownStartDate.getUTCFullYear(),
            month: teardownStartDate.getUTCMonth() + 1,
            day: teardownStartDate.getUTCDate()
          },
          jsDate: teardownStartDate
        };
        this.datepickerMobileTearDownStartDate = {
          singleDate: this.newOrderDataModel.mobileTearDownStartDate
        };
        this.newOrderDataModel.mobileTearDownStartTime = ('0' + teardownStartDate.getUTCHours()).slice(-2) + ":" + ('0' + teardownStartDate.getUTCMinutes()).slice(-2);
        this.onDesktopTearDownDateChange(true);
        this.onMobileTearDownDateChange(true);
      }

      if (this.copiedLineItemdata.teardownEndDate) {
        this.newOrderDataModel.desktopTearDownCompleteDateTime = this.copiedLineItemdata.teardownEndDate;
        let teardownCompleteDate = new Date(this.copiedLineItemdata.teardownEndDate);
        this.newOrderDataModel.mobileTearDownCompleteDate = {
          date: {
            year: teardownCompleteDate.getUTCFullYear(),
            month: teardownCompleteDate.getUTCMonth() + 1,
            day: teardownCompleteDate.getUTCDate()
          },
          jsDate: teardownCompleteDate
        };
        this.datepickerMobileTearDownCompleteDate = {
          singleDate: this.newOrderDataModel.mobileTearDownCompleteDate
        };
        this.newOrderDataModel.mobileTearDownCompleteTime = ('0' + teardownCompleteDate.getUTCHours()).slice(-2) + ":" + ('0' + teardownCompleteDate.getUTCMinutes()).slice(-2);
        this.onDesktopTearDownDateChange(false);
        this.onMobileTearDownDateChange(false);
      }
    } else {
      this.newOrderDataModel.functionalAreaValue = this.selectedFA ? this.selectedFA.functionalAreaGuid : "";
      this.newOrderDataModel.functionalAreaDisplay = this.selectedFA ? this.selectedFA.name : "";
      this.newOrderDataModel.invoiceValue = this.boothGUID ? this.boothDetails.boothGUID : "";
      this.newOrderDataModel.invoiceDisplay = this.boothGUID ? this.boothDetails.boothName : "";
      this.newOrderDataModel.searchParam = "";
      this.newOrderDataModel.searchSelectedPart = {};
      this.newOrderDataModel.menValue = "";
      this.newOrderDataModel.hoursValue = "";
      this.newOrderDataModel.quantityValue = "";
      this.newOrderDataModel.unitOfMeasureValue = "";
      this.newOrderDataModel.minQuantityValue = "";
      this.newOrderDataModel.selecatableOptionValue = [];
      this.newOrderDataModel.facilityValue = "";
      this.newOrderDataModel.facilityDisplay = "";
      this.newOrderDataModel.locationValue = "";
      this.newOrderDataModel.locationDisplay = "";
      this.clearSetupTeardownDates();
      this.newOrderDataModel.itemComment = "";
      this.newOrderDataModel.workTicketComment = "";
      this.newOrderDataModel.invoiceComment = "";
      this.newOrderDataModel.isChangeRequest = false;
      this.newOrderDataModel.stdPrice = "";
      this.newOrderDataModel.partNumberString = "";
      this.newOrderDataModel.isCopyItemComment = false;
      this.newOrderDataModel.partPropertyExtensionId = null;

      if (this.selectedFA) {
        this.initSetupTearDownDates(this.selectedFA);
      }
    }
  }

  closeNewOrderModal() {
    this.modalCtrl.dismiss();
  }

  async saveNewOder() {
    let saveNewOrderConfirmPopup = await this.popoverCtrl.create({
      component: ChangeOrderConfirmationModalComponent,
      componentProps: { isNewOrder: true }
    });
    await saveNewOrderConfirmPopup.present();
    await saveNewOrderConfirmPopup.onDidDismiss().then((confirmStatus) => {
      if (confirmStatus.data &&  confirmStatus.data.dismissed === true) {
        this.setNewDataDTO();
      }
    });
  }

  setSelectableOptionValues(selectedValues) {
    this.newOrderDataModel.selecatableOptionValue = selectedValues;
  }

  copyItemComment() {
    this.newOrderDataModel.workTicketComment = this.newOrderDataModel.itemComment;
  }

  async openMobileSelectList(selectTitle) {

    let optionData = [];
    let dropdownSelectedValue = "";
    switch (selectTitle) {
      case "Facility":
        optionData = this.facilityList;
        dropdownSelectedValue = this.newOrderDataModel.facilityValue
        break;

      case "Location":
        optionData = this.locationList;
        dropdownSelectedValue = this.newOrderDataModel.locationValue
        break;

      case "FunctionalArea":
        optionData = this.functionalAreas;
        dropdownSelectedValue = this.newOrderDataModel.functionalAreaValue
        break;

      case "Invoice":
        optionData = this.boothsList;
        dropdownSelectedValue = this.newOrderDataModel.invoiceValue
        break;
    }

    const mobileSelectModal = await this.modalCtrl.create({
      component: OccMobileSelectModalComponent,
      componentProps: {
        selectTitle: selectTitle,
        optionsData: optionData,
        dropdownSelectedValue: dropdownSelectedValue
      },
      cssClass: 'modal-height-wrapper'
      });
    await mobileSelectModal.present();
    await mobileSelectModal.onDidDismiss().then(selectedDataValue => {
      if (selectedDataValue.data) {
        switch (selectTitle) {
          case "Facility":
            this.newOrderDataModel.facilityValue = selectedDataValue.data.value;
            this.getLocationData();
            const facilityDetails = this.facilityList.find(facilityData => facilityData.kafkaId == this.newOrderDataModel.facilityValue);
            if (facilityDetails) {
              this.newOrderDataModel.facilityDisplay = facilityDetails.name;
            }
            break;
          case "Location":
            this.newOrderDataModel.locationValue = selectedDataValue.data.value;
            const locationDetails = this.locationList.find(locationData => locationData.kafkaId == this.newOrderDataModel.locationValue);
            if (locationDetails) {
              this.newOrderDataModel.locationDisplay = locationDetails.name;
            }
            break;
          case "FunctionalArea":
            this.newOrderDataModel.functionalAreaValue = selectedDataValue.data.value;
            const faDetails = this.functionalAreas.find(faData => faData.functionalAreaGuid == this.newOrderDataModel.functionalAreaValue);
            if (faDetails) {
              this.newOrderDataModel.functionalAreaDisplay = faDetails.name;
              this.initSetupTearDownDates(faDetails);
            }
            break;
          case "Invoice":
            this.newOrderDataModel.invoiceValue = selectedDataValue.data.value;
            const boothData = this.boothsList.find(boothData => boothData.kafkaId == this.newOrderDataModel.invoiceValue);
            if (boothData) {
              this.newOrderDataModel.invoiceDisplay = boothData.name;
            }
            break;
        }
      }
    });
  }

  onSearchFocus() {
    if (this.visibleResultSet.length) {
      this.isSearchResultEnable = true;
    }
    this.content.scrollToPoint(0, 180);
  }

  onSearchBlur() {
    setTimeout(() => {
      this.isSearchResultEnable = false;
    }, 300);
  }

  onSearchParamChange() {
    this.searchResultSet = [];
    this.visibleResultSet = [];

    if (this.newOrderDataModel.searchParam.length > 0 && this.partsList.length > 0) {
      let searchString = this.newOrderDataModel.searchParam.toLowerCase();
      this.partsList.forEach((partDetailsObject) => {
         if (partDetailsObject.description.trim().toLowerCase().startsWith(searchString) || (partDetailsObject.groupNumber + "-" + partDetailsObject.subGroupNumber + "-" + partDetailsObject.partNumber).toString().toLowerCase().startsWith(searchString)) {
          this.searchResultSet.push(partDetailsObject)
        }
      });
      this.isSearchResultEnable = this.searchResultSet.length ? true : false;
      
      setTimeout(() => {
        this.visibleResultSet = this.searchResultSet;
        if (this.mobileResultView) {
          if (this.searchResultSet.length < 5) {
            this.mobileResultView.nativeElement.style.height = ((this.searchResultSet.length * 48) + 24) + 'px';
          } else {
            this.mobileResultView.nativeElement.style.height = '240px';
          }
        }
        if (this.desktopResultView) {
          if (this.searchResultSet.length < 6) {
            this.desktopResultView.nativeElement.style.height = ((this.searchResultSet.length * 48) + 24) + 'px';
          } else {
            this.desktopResultView.nativeElement.style.height = '320px';
          }
        }
      }, 100);
    }
  }

  selectSearchedPart(selectedPart) {
    this.newOrderDataModel.quantityValue = "";
    this.newOrderDataModel.menValue = "";
    this.newOrderDataModel.hoursValue = "";
    this.newOrderDataModel.searchSelectedPart = selectedPart;
    this.checkIsPartNumberNull();
    this.newOrderDataModel.selecatableOptionValue = []
    this.newOrderDataModel.unitOfMeasureValue = this.getUnitShortVersion(selectedPart.unitOfMeasureDescription);
    this.newOrderDataModel.minQuantityValue = selectedPart.minimumQuantity;
    this.newOrderDataModel.partNumberString = selectedPart.groupNumber + "-" + selectedPart.subGroupNumber + "-" + selectedPart.partNumber;
    this.newOrderDataModel.stdPrice = "$" + parseFloat(selectedPart.price).toFixed(2);
    this.newOrderDataModel.partPropertyExtensionId = selectedPart.partPropertyExtensionId;
    this.checkTypeOverPart();
    this.checkLaborPart();
    this.selectedOptionsData = [];
    this.getSelectableOptionsData();
    this.getSelectedPartStatus();
    this.newOrderDataModel.searchParam = "";
    this.setMandatoryDateValidation();
    this.onDesktopSetUpDateChange(true);
    this.onMobileSetupDateChange(true);
    this.onDesktopSetUpDateChange(false);
    this.onMobileSetupDateChange(false);
    this.onDesktopTearDownDateChange(true);
    this.onMobileTearDownDateChange(true);
    this.onDesktopTearDownDateChange(false);
    this.onMobileTearDownDateChange(false);
  }

  checkSearchPartSelected() {
    if (this.newOrderDataModel && this.newOrderDataModel.searchSelectedPart) {
      return Object.keys(this.newOrderDataModel.searchSelectedPart).length ? true : false;
    } else {
      return false;
    }
  }

  onQuantityChange() {
    if (this.newOrderDataModel.quantityValue) {
      this.newOrderDataModel.quantityValue = this.newOrderDataModel.quantityValue.toString().replace("-", "");
    }

    // this.newOrderDataModel.quantityValue = this.newOrderDataModel.quantityValue.toString().match('^[+]?[0-9]*\.?[0-9]+([eE][+]?[0-9]+)?$');
  }

  checkMinimumQuantityInput() {
    if (this.checkSearchPartSelected() && !this.newOrderDataModel.minQuantityValue) {
      return true;
    }

    return (this.newOrderDataModel.quantityValue && this.newOrderDataModel.minQuantityValue && (parseFloat(this.newOrderDataModel.quantityValue) >= parseFloat(this.newOrderDataModel.minQuantityValue))) ? true : false;
  }

  getLocationData() {
    this.newOrderDataModel.locationValue = "";
    this.newOrderService.getNewOrderLocations(this.eventId, this.accountId, this.newOrderDataModel.facilityValue.toString()).pipe(takeWhile(() => this.subscribe)).subscribe(locationList => {
      this.locationList = locationList;
      if (this.copiedLineItemdata) {
        let locationData = this.locationList.find(locationElement => locationElement.name == this.copiedLineItemdata.locationInfo);
        if (locationData) {
          this.newOrderDataModel.locationDisplay = locationData.name;
          this.newOrderDataModel.locationValue = locationData.kafkaId;
        }
      }
    });
  }

  checkTypeOverPart() {
    if (this.newOrderDataModel.searchSelectedPart.groupNumber && this.newOrderDataModel.searchSelectedPart.subGroupNumber && (this.newOrderDataModel.searchSelectedPart.groupNumber == '16' || this.newOrderDataModel.searchSelectedPart.groupNumber == '26' || this.newOrderDataModel.searchSelectedPart.groupNumber == '27' || this.newOrderDataModel.searchSelectedPart.subGroupNumber == '95' || (this.newOrderDataModel.searchSelectedPart.groupNumber == '17' && this.newOrderDataModel.searchSelectedPart.subGroupNumber == '80') || (this.newOrderDataModel.searchSelectedPart.groupNumber == '11' && this.newOrderDataModel.searchSelectedPart.subGroupNumber != '81') || (this.newOrderDataModel.searchSelectedPart.groupNumber == '25' && !(this.newOrderDataModel.searchSelectedPart.subGroupNumber == '1' || this.newOrderDataModel.searchSelectedPart.subGroupNumber == '13' || this.newOrderDataModel.searchSelectedPart.subGroupNumber == '30' || this.newOrderDataModel.searchSelectedPart.subGroupNumber == '43' || this.newOrderDataModel.searchSelectedPart.subGroupNumber == '61')))) {
      this.isTypeOverPart = true;
    } else {
      this.isTypeOverPart = false;
    }
  }

  checkLaborPart() {
    if (this.newOrderDataModel.searchSelectedPart.groupNumber != '30' && this.newOrderDataModel.searchSelectedPart.groupNumber != '31' && this.newOrderDataModel.searchSelectedPart.groupNumber != '35' && this.newOrderDataModel.searchSelectedPart.groupNumber != '36' && this.newOrderDataModel.searchSelectedPart.groupNumber != '37' && this.newOrderDataModel.searchSelectedPart.groupNumber != '38' && this.newOrderDataModel.searchSelectedPart.groupNumber != '39' && this.newOrderDataModel.searchSelectedPart.groupNumber != '51') {
      this.isLaborPart = false;
    } else {
      this.isLaborPart = true;
    }
  }

  getSelectableOptionsData() {
    if (this.newOrderDataModel.searchSelectedPart.passportPartId) {
      var emptyGuid = '00000000-0000-0000-0000-000000000000';
      this.newOrderService.getSelectableOptions(this.eventId, this.accountId, emptyGuid, emptyGuid, this.newOrderDataModel.searchSelectedPart.passportPartId.toString()).pipe(takeWhile(() => this.subscribe)).subscribe(selectableOptionsData => {
        this.selectedOptionsData = selectableOptionsData;
      });
    }
  }

  getSelectedPartStatus() {
    this.isCollaborativePart = false;
    this.newOrderService.getCollaborativePartStatus(this.eventId, this.accountId, this.newOrderDataModel.searchSelectedPart.passportPartId.toString()).pipe(takeWhile(() => this.subscribe)).subscribe(partStatus => {
      this.isCollaborativePart = partStatus;
    });
  }

  checkIsSaveDisable() {
    let saveStatus = false;
    if (!this.newOrderDataModel.facilityValue || !this.newOrderDataModel.locationValue || (!this.boothGUID && !this.newOrderDataModel.functionalAreaValue) || !this.newOrderDataModel.invoiceValue || !this.checkSearchPartSelected()) {
      saveStatus = true;
    }

    if (this.checkSearchPartSelected() && !this.checkMinimumQuantityInput()) {
      saveStatus = true;
    }

    if (this.checkSearchPartSelected() && this.isLaborPart && (!this.newOrderDataModel.menValue || !this.newOrderDataModel.hoursValue)) {
      saveStatus = true;
    }

    if (this.selectedOptionsData.length > 0 && this.selectedOptionsData.length !== this.newOrderDataModel.selecatableOptionValue.length) {
      saveStatus = true;
    }

    if (!this.isPastShowOpenDate) {
      if (!this.isPartNumberNull) {
        if (this.newOrderDataModel && this.newOrderDataModel.searchSelectedPart && this.newOrderDataModel.searchSelectedPart.partPropertyExtensionId) {
          let dateValidStatus = this.getDateMandatoryStatus(this.newOrderDataModel.searchSelectedPart.partPropertyExtensionId);
          if (dateValidStatus) {
            saveStatus = true;
          }
        }
      }
    }

    if (this.checkSetupDateConflicts() || this.checkTeardownDateConflicts()) {
      saveStatus = true;
    }

    return saveStatus;
  }

  async setNewDataDTO() {
    this.newOrderSaveData = {};
    this.newOrderSaveData.kafkaId = "00000000-0000-0000-0000-000000000000";
    this.newOrderSaveData.functionalAreaGuid = this.boothGUID ? this.boothService.emptyFAGUID : this.newOrderDataModel.functionalAreaValue;
    this.newOrderSaveData.description = this.newOrderDataModel.searchSelectedPart.description;

    const locationDetails = this.locationList.find(locationData => locationData.kafkaId == this.newOrderDataModel.locationValue);
    if (locationDetails) {
      this.newOrderSaveData.locationInfo = locationDetails.name;
    }

    this.newOrderSaveData.unitOfMeasure = this.newOrderDataModel.unitOfMeasureValue;
    this.newOrderSaveData.quantity = this.newOrderDataModel.quantityValue ? parseFloat(this.newOrderDataModel.quantityValue) : null;
    this.newOrderSaveData.men = this.newOrderDataModel.menValue;
    this.newOrderSaveData.hours = this.newOrderDataModel.hoursValue;
    this.newOrderSaveData.lineItemId = 0;
    this.newOrderSaveData.partNumber = this.newOrderDataModel.partNumberString;
    this.newOrderSaveData.detailDescription = this.newOrderDataModel.searchSelectedPart.description;
    this.newOrderSaveData.ticketNumber = null;

    const boothData = this.boothsList.find(boothData => boothData.kafkaId == this.newOrderDataModel.invoiceValue);
    if (boothData) {
      this.newOrderSaveData.booth = boothData.name;
    }

    if (this.isLaborPart) {
      this.newOrderSaveData.men = this.newOrderDataModel.menValue;
      this.newOrderSaveData.hours = this.newOrderDataModel.hoursValue;
    }

    this.newOrderSaveData.displayName = this.newOrderDataModel.searchSelectedPart.description;
    this.newOrderSaveData.vendorComments = this.newOrderDataModel.itemComment;
    this.newOrderSaveData.ticketComments = this.newOrderDataModel.workTicketComment;
    this.newOrderSaveData.invoiceComments = this.newOrderDataModel.invoiceComment;
    this.newOrderSaveData.partSelections = this.newOrderDataModel.selecatableOptionValue;
    this.newOrderSaveData.revisionReasonTypeId = "";
    this.newOrderSaveData.showLocationId = this.newOrderDataModel.locationValue;
    this.newOrderSaveData.showBoothId = this.newOrderDataModel.invoiceValue;
    this.newOrderSaveData.createdDate = new Date();
    this.newOrderSaveData.modifiedDate = new Date();
    this.newOrderSaveData.currentStatus = 1;
    this.newOrderSaveData.confirmSave = true;
    this.newOrderSaveData.partId = this.newOrderDataModel.searchSelectedPart.passportPartId;
    this.newOrderSaveData.partDescription = this.newOrderDataModel.searchSelectedPart.description;
    this.newOrderSaveData.changeRequest = this.newOrderDataModel.isChangeRequest;

    if (this.newOrderDataModel.desktopSetupStartDateTime) {
      let date = new Date(this.newOrderDataModel.desktopSetupStartDateTime);
      this.newOrderSaveData.setupStart = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    }

    if (this.newOrderDataModel.desktopSetupCompleteDateTime) {
      let date = new Date(this.newOrderDataModel.desktopSetupCompleteDateTime);
      this.newOrderSaveData.setupEnd = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    }

    if (this.newOrderDataModel.desktopTearDownStartDateTime) {
      let date = new Date(this.newOrderDataModel.desktopTearDownStartDateTime);
      this.newOrderSaveData.strikeStart = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    }

    if (this.newOrderDataModel.desktopTearDownCompleteDateTime) {
      let date = new Date(this.newOrderDataModel.desktopTearDownCompleteDateTime);
      this.newOrderSaveData.strikeEnd = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    }

    if (this.newOrderDataModel.mobileSetupStartDate && this.newOrderDataModel.mobileSetupStartTime) {
      this.newOrderSaveData.setupStart = new Date(this.newOrderDataModel.mobileSetupStartDate.date.year.toString() + "-" + this.newOrderDataModel.mobileSetupStartDate.date.month.toString() + "-" + this.newOrderDataModel.mobileSetupStartDate.date.day.toString() + " " + this.newOrderDataModel.mobileSetupStartTime);
    }

    if (this.newOrderDataModel.mobileSetupCompleteDate && this.newOrderDataModel.mobileSetupCompleteTime) {
      this.newOrderSaveData.setupEnd = new Date(this.newOrderDataModel.mobileSetupCompleteDate.date.year.toString() + "-" + this.newOrderDataModel.mobileSetupCompleteDate.date.month.toString() + "-" + this.newOrderDataModel.mobileSetupCompleteDate.date.day.toString() + " " + this.newOrderDataModel.mobileSetupCompleteTime);
    }

    if (this.newOrderDataModel.mobileTearDownStartDate && this.newOrderDataModel.mobileTearDownStartTime) {
      this.newOrderSaveData.strikeStart = new Date(this.newOrderDataModel.mobileTearDownStartDate.date.year.toString() + "-" + this.newOrderDataModel.mobileTearDownStartDate.date.month.toString() + "-" + this.newOrderDataModel.mobileTearDownStartDate.date.day.toString() + " " + this.newOrderDataModel.mobileTearDownStartTime);
    }

    if (this.newOrderDataModel.mobileTearDownCompleteDate && this.newOrderDataModel.mobileTearDownCompleteTime) {
      this.newOrderSaveData.strikeEnd = new Date(this.newOrderDataModel.mobileTearDownCompleteDate.date.year.toString() + "-" + this.newOrderDataModel.mobileTearDownCompleteDate.date.month.toString() + "-" + this.newOrderDataModel.mobileTearDownCompleteDate.date.day.toString() + " " + this.newOrderDataModel.mobileTearDownCompleteTime);
    }

    if (this.copiedLineItemdata) {
      this.newOrderSaveData.originLineItemId = this.copiedLineItemdata.passportId;
    }

      this.loading = await this.loadingCtrl.create({
      spinner: 'crescent',
      message: 'Saving New Order...',
			showBackdrop: false,
    });

    this.loading.present();

    this.http.post(`${this.url()}events/${this.eventId}/accounts/${this.accountId}/change-orders`, this.newOrderSaveData).subscribe(data => {
      this.loading.dismiss();
      this.closeNewOrderModal();
      this.toastService.open('Success! Change Order Saved.', 'success');
    }, error => {
      this.loading.dismiss();
      this.toastService.open('There was an error while saving your new order', 'danger');
    })
  }

  onDesktopSetUpDateChange(isStart) {
    if (this.newOrderDataModel.desktopSetupStartDateTime && this.newOrderDataModel.desktopSetupCompleteDateTime) {
      let setupStartDate = new Date(this.newOrderDataModel.desktopSetupStartDateTime);
      let setupEndDate = new Date(this.newOrderDataModel.desktopSetupCompleteDateTime);

      if ((setupStartDate > setupEndDate)) {
        this.isSetUpStartDateTimeValid = true;
        this.isSetUpCompleteDateTimeValid = false;
      } else {
        this.isSetUpStartDateTimeValid = true;
        this.isSetUpCompleteDateTimeValid = true;
      }
    }
  }

  onDesktopTearDownDateChange(isStart) {
    if (this.newOrderDataModel.desktopTearDownStartDateTime && this.newOrderDataModel.desktopTearDownCompleteDateTime) {
      let setupStartDate = new Date(this.newOrderDataModel.desktopTearDownStartDateTime);
      let setupEndDate = new Date(this.newOrderDataModel.desktopTearDownCompleteDateTime);

      if ((setupStartDate > setupEndDate)) {
        this.isTeardownStartDateTimeValid = true;
        this.isTeardownCompleteDateTimeValid = false;
      } else {
        this.isTeardownStartDateTimeValid = true;
        this.isTeardownCompleteDateTimeValid = true;
      }
    }
  }

  onMobileSetupDateChange(isStart) {
    setTimeout(() => {
      this.isSetUpStartDateTimeValid = true;
      this.isSetUpCompleteDateTimeValid = true;

      this.newOrderDataModel.mobileSetupStartDate = this.datepickerMobileSetupStartDate ? this.datepickerMobileSetupStartDate.singleDate : this.newOrderDataModel.mobileSetupStartDate;
      this.newOrderDataModel.mobileSetupCompleteDate = this.datepickerMobileSetupCompleteDate ? this.datepickerMobileSetupCompleteDate.singleDate : this.newOrderDataModel.mobileSetupCompleteDate;

      if(!this.newOrderDataModel.mobileSetupStartDate || !this.newOrderDataModel.mobileSetupStartTime){
        if (!this.isPastShowOpenDate && !this.isPartNumberNull && (this.newOrderDataModel.partPropertyExtensionId == 1 || this.newOrderDataModel.partPropertyExtensionId == 3 || this.newOrderDataModel.partPropertyExtensionId == 4 || this.newOrderDataModel.partPropertyExtensionId == 8)) {
          this.isSetUpStartDateTimeValid = false;
        }
      }
  
      if(!this.newOrderDataModel.mobileSetupCompleteDate || !this.newOrderDataModel.mobileSetupCompleteTime){
        if (!this.isPastShowOpenDate && !this.isPartNumberNull && (this.newOrderDataModel.partPropertyExtensionId == 1 || this.newOrderDataModel.partPropertyExtensionId == 3 || this.newOrderDataModel.partPropertyExtensionId == 4 || this.newOrderDataModel.partPropertyExtensionId == 8)) {
          this.isSetUpCompleteDateTimeValid = false;
        }
      }
  
      if (this.newOrderDataModel.mobileSetupStartDate && this.newOrderDataModel.mobileSetupStartTime && this.newOrderDataModel.mobileSetupCompleteDate && this.newOrderDataModel.mobileSetupCompleteTime) {
        let setupStartSplittedTime = this.newOrderDataModel.mobileSetupStartTime.split(":");
        // let setupStartDate = new Date(this.newOrderDataModel.mobileSetupStartDate.date.year, this.newOrderDataModel.mobileSetupStartDate.date.month, this.newOrderDataModel.mobileSetupStartDate.date.day, setupStartSplittedTime[0], setupStartSplittedTime[1]);
        // let setupStartDate = this.newOrderDataModel.mobileSetupStartDate.jsDate || new Date(this.newOrderDataModel.mobileSetupStartDate.date.year, this.newOrderDataModel.mobileSetupStartDate.date.month, this.newOrderDataModel.mobileSetupStartDate.date.day, setupStartSplittedTime[0], setupStartSplittedTime[1]);
        let setupStartDate = this.newOrderDataModel.mobileSetupStartDate.jsDate;
        setupStartDate.setHours(setupStartSplittedTime[0]);
        setupStartDate.setMinutes(setupStartSplittedTime[1]);
        let setupCompleteSplittedTime = this.newOrderDataModel.mobileSetupCompleteTime.split(":");
        // let setupCompleteDate = new Date(this.newOrderDataModel.mobileSetupCompleteDate.date.year, this.newOrderDataModel.mobileSetupCompleteDate.date.month, this.newOrderDataModel.mobileSetupCompleteDate.date.day, setupCompleteSplittedTime[0], setupCompleteSplittedTime[1]);
        // let setupCompleteDate = this.newOrderDataModel.mobileSetupCompleteDate.jsDate || new Date(this.newOrderDataModel.mobileSetupCompleteDate.date.year, this.newOrderDataModel.mobileSetupCompleteDate.date.month, this.newOrderDataModel.mobileSetupCompleteDate.date.day, setupCompleteSplittedTime[0], setupCompleteSplittedTime[1]);
        let setupCompleteDate = this.newOrderDataModel.mobileSetupCompleteDate.jsDate;
        setupCompleteDate.setHours(setupCompleteSplittedTime[0]);
        setupCompleteDate.setMinutes(setupCompleteSplittedTime[1]);
        if (setupStartDate.getTime() >= setupCompleteDate.getTime()) {
          this.isSetUpStartDateTimeValid = true;
          this.isSetUpCompleteDateTimeValid = false;
        } else {
          this.isSetUpStartDateTimeValid = true;
          this.isSetUpCompleteDateTimeValid = true;
        }
      }
    }, 300);
  }

  onMobileTearDownDateChange(isStart) {
    setTimeout(() => {
      this.isTeardownStartDateTimeValid = true;
      this.isTeardownCompleteDateTimeValid = true;

      this.newOrderDataModel.mobileTearDownStartDate = this.datepickerMobileTearDownStartDate ? this.datepickerMobileTearDownStartDate.singleDate : this.newOrderDataModel.teardownStartDate;
      this.newOrderDataModel.mobileTearDownCompleteDate = this.datepickerMobileTearDownCompleteDate ? this.datepickerMobileTearDownCompleteDate.singleDate : this.newOrderDataModel.teardownCompleteDate;

      if(!this.newOrderDataModel.mobileTearDownStartDate || !this.newOrderDataModel.mobileTearDownStartTime){
        if (!this.isPastShowOpenDate && !this.isPartNumberNull && (this.newOrderDataModel.partPropertyExtensionId == 2 || this.newOrderDataModel.partPropertyExtensionId == 3 || this.newOrderDataModel.partPropertyExtensionId == 4 || this.newOrderDataModel.partPropertyExtensionId == 8)) {
          this.isTeardownStartDateTimeValid = false;
        }
      }
  
      if(!this.newOrderDataModel.mobileTearDownCompleteDate || !this.newOrderDataModel.mobileTearDownCompleteTime){
        if (!this.isPastShowOpenDate && !this.isPartNumberNull && (this.newOrderDataModel.partPropertyExtensionId == 2 || this.newOrderDataModel.partPropertyExtensionId == 3 || this.newOrderDataModel.partPropertyExtensionId == 4 || this.newOrderDataModel.partPropertyExtensionId == 8)) {
          this.isTeardownCompleteDateTimeValid = false;
        }
      }
      if (this.newOrderDataModel.mobileTearDownStartDate && this.newOrderDataModel.mobileTearDownStartTime && this.newOrderDataModel.mobileTearDownCompleteDate && this.newOrderDataModel.mobileTearDownCompleteTime) {
        let teardownStartSplittedTime = this.newOrderDataModel.mobileTearDownStartTime.split(":");
        // let setupStartDate = new Date(this.newOrderDataModel.mobileTearDownStartDate.date.year, this.newOrderDataModel.mobileTearDownStartDate.date.month, this.newOrderDataModel.mobileTearDownStartDate.date.day, teardownStartSplittedTime[0], teardownStartSplittedTime[1]);
        let setupStartDate = this.newOrderDataModel.mobileTearDownStartDate.jsDate;
        setupStartDate.setHours(teardownStartSplittedTime[0]);
        setupStartDate.setMinutes(teardownStartSplittedTime[1]);
        let teardownCompleteSplittedTime = this.newOrderDataModel.mobileTearDownCompleteTime.split(":");
        // let setupCompleteDate = new Date(this.newOrderDataModel.mobileTearDownCompleteDate.date.year, this.newOrderDataModel.mobileTearDownCompleteDate.date.month, this.newOrderDataModel.mobileTearDownCompleteDate.date.day, teardownCompleteSplittedTime[0], teardownCompleteSplittedTime[1]);
        let setupCompleteDate = this.newOrderDataModel.mobileTearDownCompleteDate.jsDate;
        setupCompleteDate.setHours(teardownCompleteSplittedTime[0]);
        setupCompleteDate.setMinutes(teardownCompleteSplittedTime[1]);
        if (setupStartDate.getTime() >= setupCompleteDate.getTime()) {
          this.isTeardownStartDateTimeValid = true;
          this.isTeardownCompleteDateTimeValid = false;
        } else {
          this.isTeardownStartDateTimeValid = true;
          this.isTeardownCompleteDateTimeValid = true;
        }
      }
    }, 300);
  }

  calculateQuantity() {
    if (this.newOrderDataModel.menValue) {
      this.newOrderDataModel.menValue = this.newOrderDataModel.menValue.toString().replace("-", "");
    }

    if (this.newOrderDataModel.hoursValue) {
      this.newOrderDataModel.hoursValue = this.newOrderDataModel.hoursValue.toString().replace("-", "");
    }

    if (this.newOrderDataModel.menValue && this.newOrderDataModel.hoursValue) {
      this.newOrderDataModel.quantityValue = parseFloat(this.newOrderDataModel.menValue) * parseFloat(this.newOrderDataModel.hoursValue);
    } else {
      this.newOrderDataModel.quantityValue = "";
    }
  }

  onCopyCheckboxChecked() {
    if (this.newOrderDataModel.isCopyItemComment) {
      this.copyItemComment();
    }
  }

  getEstimatedTotal() {
    return (parseFloat(this.newOrderDataModel.quantityValue) * parseFloat(this.newOrderDataModel.searchSelectedPart.price)).toFixed(2);
  }

  // onFunctionalAreaChange(){
  //   const faDetails = this.functionalAreas.find(faData => faData.functionalAreaGuid == this.newOrderDataModel.functionalAreaValue);
  //   if (faDetails) {
  //     this.initSetupTearDownDates(faDetails);
  //   }
  // }

  initSetupTearDownDates(faDetails) {
    this.clearSetupTeardownDates();
    if (faDetails.setUpStartDateTime) {
      this.newOrderDataModel.desktopSetupStartDateTime = faDetails.setUpStartDateTime;

      let setupStartDateTime = new Date(faDetails.setUpStartDateTime);
      this.newOrderDataModel.mobileSetupStartDate = {
        date: {
          year: setupStartDateTime.getUTCFullYear(),
          month: setupStartDateTime.getUTCMonth() + 1,
          day: setupStartDateTime.getUTCDate()
        }
      };
      this.datepickerMobileSetupStartDate = {
        singleDate: this.newOrderDataModel.mobileSetupStartDate
      };
      this.newOrderDataModel.mobileSetupStartTime = ('0' + setupStartDateTime.getUTCHours()).slice(-2) + ":" + ('0' + setupStartDateTime.getUTCMinutes()).slice(-2);
      this.onDesktopSetUpDateChange(true);
      this.onMobileSetupDateChange(true);
    }

    if (faDetails.setUpCompleteDateTime) {
      this.newOrderDataModel.desktopSetupCompleteDateTime = faDetails.setUpCompleteDateTime;
      let setupEndDateTime = new Date(faDetails.setUpCompleteDateTime);
      this.newOrderDataModel.mobileSetupCompleteDate = {
        date: {
          year: setupEndDateTime.getUTCFullYear(),
          month: setupEndDateTime.getUTCMonth() + 1,
          day: setupEndDateTime.getUTCDate()
        }
      };
      this.datepickerMobileSetupCompleteDate = {
        singleDate: this.newOrderDataModel.setupCompleteDate
      };
      this.newOrderDataModel.mobileSetupCompleteTime = ('0' + setupEndDateTime.getUTCHours()).slice(-2) + ":" + ('0' + setupEndDateTime.getUTCMinutes()).slice(-2);
      this.onDesktopSetUpDateChange(false);
      this.onMobileSetupDateChange(false);
    }
  }

  openFASelect() {
    this.isFASelectOpen = !this.isFASelectOpen;
  }

  setFASelection(selectedFunctionalArea) {
    this.newOrderDataModel.functionalAreaValue = selectedFunctionalArea.functionalAreaGuid;
    this.newOrderDataModel.functionalAreaDisplay = selectedFunctionalArea.name;
    this.initSetupTearDownDates(selectedFunctionalArea);
  }

  getUnitShortVersion(unitValue) {
    let unitsData = [
      { 'id': 1, 'name': 'SQUARE FEET', 'code': 'SQFT' },
      { 'id': 2, 'name': 'CASE', 'code': 'CASE' },
      { 'id': 3, 'name': 'WEEK', 'code': 'WEEK' },
      { 'id': 4, 'name': 'DAY', 'code': 'DAY' },
      { 'id': 5, 'name': 'POUND', 'code': 'LB' },
      { 'id': 6, 'name': 'SHIPMENT', 'code': 'SHPT' },
      { 'id': 7, 'name': 'FLAT', 'code': 'FLAT' },
      { 'id': 8, 'name': 'EACH', 'code': 'EA' },
      { 'id': 9, 'name': 'HOUR', 'code': 'HR' },
      { 'id': 10, 'name': 'CUBIC FEET', 'code': 'CUFT' },
      { 'id': 11, 'name': 'SQUARE METER', 'code': 'SQM' },
      { 'id': 12, 'name': 'FOOT', 'code': 'FT' },
      { 'id': 13, 'name': 'SQUARE YARD', 'code': 'SQYD' },
      { 'id': 14, 'name': 'CENTUM WEIGHT', 'code': 'CWT' },
      { 'id': 15, 'name': 'ROLL', 'code': 'ROLL' },
      { 'id': 16, 'name': 'LINEAR FOOT', 'code': 'LNFT' }
    ];

    let matchedUnitDetails = unitsData.find(unitDetails => unitDetails.name.toLowerCase() == unitValue.toLowerCase());
    return matchedUnitDetails ? matchedUnitDetails.code : "";
  }

  clearSetupTeardownDates() {
    this.newOrderDataModel.desktopSetupStartDateTime = "";
    this.newOrderDataModel.mobileSetupStartDate = "";
    this.newOrderDataModel.mobileSetupStartTime = "";
    this.newOrderDataModel.desktopSetupCompleteDateTime = "";
    this.newOrderDataModel.mobileSetupCompleteDate = "";
    this.newOrderDataModel.mobileSetupCompleteTime = "";
    this.newOrderDataModel.desktopTearDownStartDateTime = "";
    this.newOrderDataModel.mobileTearDownStartDate = "";
    this.newOrderDataModel.mobileTearDownStartTime = "";
    this.newOrderDataModel.desktopTearDownCompleteDateTime = "";
    this.newOrderDataModel.mobileTearDownCompleteDate = "";
    this.newOrderDataModel.mobileTearDownCompleteTime = "";
  }

  setSetupDateVisibility() {
    return this.newOrderDataModel.partPropertyExtensionId != 2 ? true : false;
  }

  setTearDownDateVisibility() {
    return this.newOrderDataModel.partPropertyExtensionId != 1 ? true : false;
  }

  getDateMandatoryStatus(partPropertyExtensionId): boolean {
    let dateValidStatus = false;
    switch (partPropertyExtensionId) {
      case 1:
        if (this.isSetUpStartDateTimeValid && this.isSetUpCompleteDateTimeValid && ((this.newOrderDataModel.mobileSetupStartDate && this.newOrderDataModel.mobileSetupStartTime && this.newOrderDataModel.mobileSetupCompleteDate && this.newOrderDataModel.mobileSetupCompleteTime) || (this.newOrderDataModel.desktopSetupStartDateTime && this.newOrderDataModel.desktopSetupCompleteDateTime))) {
          dateValidStatus = false;
        } else {
          dateValidStatus = true;
        }
        break;
      case 2:
        if (this.isTeardownStartDateTimeValid && this.isTeardownCompleteDateTimeValid && ((this.newOrderDataModel.mobileTearDownStartDate && this.newOrderDataModel.mobileTearDownStartTime && this.newOrderDataModel.mobileTearDownCompleteDate && this.newOrderDataModel.mobileTearDownCompleteTime) || (this.newOrderDataModel.desktopTearDownStartDateTime && this.newOrderDataModel.desktopTearDownCompleteDateTime))) {
          dateValidStatus = false;
        } else {
          dateValidStatus = true;
        }
        break;
      case 3:
      case 4:
      case 8:
        if ((this.isSetUpStartDateTimeValid && this.isSetUpCompleteDateTimeValid && ((this.newOrderDataModel.mobileSetupStartDate && this.newOrderDataModel.mobileSetupStartTime && this.newOrderDataModel.mobileSetupCompleteDate && this.newOrderDataModel.mobileSetupCompleteTime) || (this.newOrderDataModel.desktopSetupStartDateTime && this.newOrderDataModel.desktopSetupCompleteDateTime)) && (this.isTeardownStartDateTimeValid && this.isTeardownCompleteDateTimeValid && ((this.newOrderDataModel.mobileTearDownStartDate && this.newOrderDataModel.mobileTearDownStartTime && this.newOrderDataModel.mobileTearDownCompleteDate && this.newOrderDataModel.mobileTearDownCompleteTime) || (this.newOrderDataModel.desktopTearDownStartDateTime && this.newOrderDataModel.desktopTearDownCompleteDateTime))))) {
          dateValidStatus = false;
        } else {
          dateValidStatus = true;
        }
        break;
      default:
        dateValidStatus = false;
    }
    return dateValidStatus;
  }

  setMandatoryDateValidation() {
    this.isSetUpStartDateTimeValid = true;
    this.isSetUpCompleteDateTimeValid = true;
    this.isTeardownStartDateTimeValid = true;
    this.isTeardownCompleteDateTimeValid = true;

    if (!this.isPastShowOpenDate) {
      if (!this.isPartNumberNull) {
        if (this.newOrderDataModel.partPropertyExtensionId == 1) {
          this.isSetUpStartDateTimeValid = false;
          this.isSetUpCompleteDateTimeValid = false;
          this.isTeardownStartDateTimeValid = true;
          this.isTeardownCompleteDateTimeValid = true;
          this.newOrderDataModel.mobileTearDownStartDate = "";
          this.newOrderDataModel.mobileTearDownStartTime = "";
          this.newOrderDataModel.desktopTearDownStartDateTime = "";
          this.newOrderDataModel.mobileTearDownCompleteDate = "";
          this.newOrderDataModel.mobileTearDownCompleteTime = "";
          this.newOrderDataModel.desktopTearDownCompleteDateTime = "";
        }
        if (this.newOrderDataModel.partPropertyExtensionId == 2) {
          this.isSetUpStartDateTimeValid = true;
          this.isSetUpCompleteDateTimeValid = true;
          this.isTeardownStartDateTimeValid = false;
          this.isTeardownCompleteDateTimeValid = false;
          this.newOrderDataModel.mobileSetupStartDate = "";
          this.newOrderDataModel.mobileSetupStartTime = "";
          this.newOrderDataModel.desktopSetupStartDateTime = "";
          this.newOrderDataModel.mobileSetupCompleteDate = "";
          this.newOrderDataModel.mobileSetupCompleteTime = "";
          this.newOrderDataModel.desktopSetupCompleteDateTime = "";
        }
        if (this.newOrderDataModel.partPropertyExtensionId == 3 || this.newOrderDataModel.partPropertyExtensionId == 4 || this.newOrderDataModel.partPropertyExtensionId == 8) {
          this.isSetUpStartDateTimeValid = false;
          this.isSetUpCompleteDateTimeValid = false;
          this.isTeardownStartDateTimeValid = false;
          this.isTeardownCompleteDateTimeValid = false;
        }
      }
    }
  }

  checkIsPartNumberNull() {
    if (this.newOrderDataModel.searchSelectedPart && this.newOrderDataModel.searchSelectedPart.partNumber == null) {
      this.isPartNumberNull = true;
    } else {
      this.isPartNumberNull = false;
    }
  }

  checkSetupDateConflicts() {
    this.isSetUpSDConflict = false;
    this.isSetUpCDConflict = false;
    this.isTeardownSDConflict = false;
    this.isTeardownCDConflict = false;
    if (this.newOrderDataModel.desktopSetupStartDateTime && this.newOrderDataModel.desktopSetupCompleteDateTime) {
      let setupStartDate = new Date(this.newOrderDataModel.desktopSetupStartDateTime);
      let setupEndDate = new Date(this.newOrderDataModel.desktopSetupCompleteDateTime);

      if ((setupStartDate > setupEndDate)) {
        this.isSetUpCDConflict = true;
        return true;
      }
    }

    if (this.newOrderDataModel.mobileSetupStartDate && this.newOrderDataModel.mobileSetupStartTime && this.newOrderDataModel.mobileSetupCompleteDate && this.newOrderDataModel.mobileSetupCompleteTime) {
      let setupStartSplittedTime = this.newOrderDataModel.mobileSetupStartTime.split(":");
      // let setupStartDate = new Date(this.newOrderDataModel.mobileSetupStartDate.date.year, this.newOrderDataModel.mobileSetupStartDate.date.month, this.newOrderDataModel.mobileSetupStartDate.date.day, setupStartSplittedTime[0], setupStartSplittedTime[1]);
      let setupStartDate = this.newOrderDataModel.mobileSetupStartDate.jsDate;
      setupStartDate.setHours(setupStartSplittedTime[0]);
      setupStartDate.setMinutes(setupStartSplittedTime[1]);
      let setupCompleteSplittedTime = this.newOrderDataModel.mobileSetupCompleteTime.split(":");
      // let setupCompleteDate = new Date(this.newOrderDataModel.mobileSetupCompleteDate.date.year, this.newOrderDataModel.mobileSetupCompleteDate.date.month, this.newOrderDataModel.mobileSetupCompleteDate.date.day, setupCompleteSplittedTime[0], setupCompleteSplittedTime[1]);
      let setupCompleteDate = this.newOrderDataModel.mobileSetupCompleteDate.jsDate;
      setupCompleteDate.setHours(setupCompleteSplittedTime[0]);
      setupCompleteDate.setMinutes(setupCompleteSplittedTime[1]);
      if (setupStartDate.getTime() >= setupCompleteDate.getTime()) {
        this.isSetUpCDConflict = true;
        return true;
      }
    }
    return false;
  }

  checkTeardownDateConflicts() {
    this.isSetUpSDConflict = false;
    this.isSetUpCDConflict = false;
    this.isTeardownSDConflict = false;
    this.isTeardownCDConflict = false;
    if (this.newOrderDataModel.desktopTearDownStartDateTime || this.newOrderDataModel.desktopTearDownCompleteDateTime) {
      let tearDownStartDate = this.newOrderDataModel.desktopTearDownStartDateTime ? new Date(this.newOrderDataModel.desktopTearDownStartDateTime) : "";
      let tearDownEndDate = this.newOrderDataModel.desktopTearDownCompleteDateTime ? new Date(this.newOrderDataModel.desktopTearDownCompleteDateTime) : "";

      let setupStartDate = this.newOrderDataModel.desktopSetupStartDateTime ? new Date(this.newOrderDataModel.desktopSetupStartDateTime) : "";
      let setupEndDate = this.newOrderDataModel.desktopSetupCompleteDateTime ? new Date(this.newOrderDataModel.desktopSetupCompleteDateTime) : "";

      if (setupEndDate && tearDownStartDate && setupEndDate > tearDownStartDate) {
        this.isTeardownSDConflict = true;
        return true;
      } else if (setupStartDate && tearDownStartDate && setupStartDate > tearDownStartDate) {
        this.isTeardownSDConflict = true;
        return true;
      }
      if (setupEndDate && tearDownEndDate && setupEndDate > tearDownEndDate) {
        this.isTeardownCDConflict = true;
        return true;
      } else if (setupStartDate && tearDownEndDate && setupStartDate > tearDownEndDate) {
        this.isTeardownCDConflict = true;
        return true;
      }
      if (tearDownStartDate && tearDownEndDate && tearDownStartDate > tearDownEndDate) {
        this.isTeardownCDConflict = true;
        return true;
      }
    }

    if ((this.newOrderDataModel.mobileTearDownStartDate && this.newOrderDataModel.mobileTearDownStartTime) || (this.newOrderDataModel.mobileTearDownCompleteDate && this.newOrderDataModel.mobileTearDownCompleteTime)) {
      let tearDownStartDate: any = "";
      if (this.newOrderDataModel.mobileTearDownStartDate && this.newOrderDataModel.mobileTearDownStartTime) {
        let teardownStartSplittedTime = this.newOrderDataModel.mobileTearDownStartTime.split(":");
        // tearDownStartDate = new Date(this.newOrderDataModel.mobileTearDownStartDate.date.year, this.newOrderDataModel.mobileTearDownStartDate.date.month, this.newOrderDataModel.mobileTearDownStartDate.date.day, teardownStartSplittedTime[0], teardownStartSplittedTime[1]);
        tearDownStartDate = this.newOrderDataModel.mobileTearDownStartDate.jsDate;
        tearDownStartDate.setHours(teardownStartSplittedTime[0]);
        tearDownStartDate.setMinutes(teardownStartSplittedTime[1]);
      }

      let tearDownEndDate: any = "";
      if (this.newOrderDataModel.mobileTearDownCompleteDate && this.newOrderDataModel.mobileTearDownCompleteTime) {
        let teardownCompleteSplittedTime = this.newOrderDataModel.mobileTearDownCompleteTime.split(":");
        // tearDownEndDate = new Date(this.newOrderDataModel.mobileTearDownCompleteDate.date.year, this.newOrderDataModel.mobileTearDownCompleteDate.date.month, this.newOrderDataModel.mobileTearDownCompleteDate.date.day, teardownCompleteSplittedTime[0], teardownCompleteSplittedTime[1]);
        tearDownEndDate = this.newOrderDataModel.mobileTearDownCompleteDate.jsDate;
        tearDownEndDate.setHours(teardownCompleteSplittedTime[0]);
        tearDownEndDate.setMinutes(teardownCompleteSplittedTime[1]);
      }

      let setupStartDate: any = "";
      if (this.newOrderDataModel.mobileSetupStartDate && this.newOrderDataModel.mobileSetupStartTime) {
        let setupStartSplittedTime = this.newOrderDataModel.mobileSetupStartTime.split(":");
        // setupStartDate = new Date(this.newOrderDataModel.mobileSetupStartDate.date.year, this.newOrderDataModel.mobileSetupStartDate.date.month, this.newOrderDataModel.mobileSetupStartDate.date.day, setupStartSplittedTime[0], setupStartSplittedTime[1]);
        setupStartDate =this.newOrderDataModel.mobileSetupStartDate.jsDate;
        setupStartDate.setHours(setupStartSplittedTime[0]);
        setupStartDate.setMinutes(setupStartSplittedTime[1]);
      }

      let setupCompleteDate: any = "";
      if (this.newOrderDataModel.mobileSetupCompleteDate && this.newOrderDataModel.mobileSetupCompleteTime) {
        let setupCompleteSplittedTime = this.newOrderDataModel.mobileSetupCompleteTime.split(":");
        // setupCompleteDate = new Date(this.newOrderDataModel.mobileSetupCompleteDate.date.year, this.newOrderDataModel.mobileSetupCompleteDate.date.month, this.newOrderDataModel.mobileSetupCompleteDate.date.day, setupCompleteSplittedTime[0], setupCompleteSplittedTime[1]);
        setupCompleteDate = this.newOrderDataModel.mobileSetupCompleteDate.jsDate;
        setupCompleteDate.setHours(setupCompleteSplittedTime[0]);
        setupCompleteDate.setMinutes(setupCompleteSplittedTime[1]);
      }

      if (setupCompleteDate && tearDownStartDate && setupCompleteDate.getTime() > tearDownStartDate.getTime()) {
        this.isTeardownSDConflict = true;
        return true;
      } else if (setupStartDate && tearDownStartDate && setupStartDate.getTime() > tearDownStartDate.getTime()) {
        this.isTeardownSDConflict = true;
        return true;
      }
      if (setupCompleteDate && tearDownEndDate && setupCompleteDate.getTime() > tearDownEndDate.getTime()) {
        this.isTeardownCDConflict = true;
        return true;
      } else if (setupStartDate && tearDownEndDate && setupStartDate.getTime() > tearDownEndDate.getTime()) {
        this.isTeardownCDConflict = true;
        return true;
      }
      if (tearDownStartDate && tearDownEndDate && tearDownStartDate.getTime() > tearDownEndDate.getTime()) {
        this.isTeardownCDConflict = true;
        return true;
      }
    }
    return false;
  }
}

