import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivationEnd, Router, RouterEvent } from '@angular/router';
import { Platform } from '@ionic/angular';
import { AuthTokenHelper } from 'src/app/helpers/auth-token-helper';
import { FilterHelper } from 'src/app/helpers/filter-helper';
import { NavParamHelper } from 'src/app/helpers/nav-param-helper';
import { SearchHelper } from 'src/app/helpers/search-helper';
import { SelectHelper } from 'src/app/helpers/select-helper';
import { Owner } from 'src/app/models/owner';
import { Status } from 'src/app/models/status';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';

@Component({
	selector: 'occ-filter-selection-workticket',
	templateUrl: './filter-selection-workticket.component.html',
	styleUrls: ['./filter-selection-workticket.component.scss'],
})
export class FilterSelectionWorkticketComponent implements OnInit {
	@Output() closeFilter = new EventEmitter();
	@Output() navigate = new EventEmitter();
	@Output() filterStatus = new EventEmitter();
	@Output() filterOwner = new EventEmitter();
	@Input() isFunctionalAreasPage: boolean;
	@Input() isWorkticketPage: boolean;
	eventId: string;
	accountId: string;
	filterTitleText: string;
	globalFilterExpanded: boolean;
	lineItemFilterExpanded: boolean;
	workticketFilterExpanded: boolean;

	constructor(
		public filterHelper: FilterHelper,
		public searchHelper: SearchHelper,
		public navParamHelper: NavParamHelper,
		public dataSvc: DataRefreshService,
		public platform: Platform,
		public router: Router,
		public authHelper: AuthTokenHelper,
	) {
		this.filterHelper.globalFilterExpandedSubject.subscribe(expandABandABand => {
			this.globalFilterExpanded = expandABandABand;
		});
		this.filterHelper.lineItemExpandedSubject.subscribe(expandABandABand => {
			this.lineItemFilterExpanded = expandABandABand;
		});
		this.filterHelper.workticketExpandedSubject.subscribe(expandABandABand => {
			this.workticketFilterExpanded = expandABandABand;
		});
	}

	ngOnInit() {
		this.filterTitleText = 'Filters';
		this.router.events.subscribe((event: RouterEvent) => {
			if (event instanceof ActivationEnd) {
				event.snapshot.params.eventId ? this.eventId = event.snapshot.params.eventId : "";
				this.navParamHelper.syncActiveInfo(this.eventId);
				if (this.authHelper.isOpsUser && this.filterHelper.eventId !== this.eventId) {
					this.searchHelper.searchString = '';
					this.searchHelper.searchItems.next([]);
					this.filterHelper.eventId = this.eventId;
					this.filterHelper.resetAllFilters();
				}				
			}
		});
	}

	hideFilter(): void {
		this.closeFilter.emit();
	}

	getCurrentFAStatusFilter(): Status[] {
		return this.isFunctionalAreasPage ? this.filterHelper.functionalAreasPageStatusFilter : this.filterHelper.functionalAreaStatus;
	}
	getCurrentOwnerFilter(): Owner[] {
		return this.isFunctionalAreasPage ? this.filterHelper.functionalAreasPageOwnerFilter : this.filterHelper.functionalAreaOwner;
	}

	getCurrentLIStatusFilter(): Status[] {
		return this.filterHelper.lineItemStatus;
	}

	getCurrentLIStartDateFilter(): string {
		return this.filterHelper.liStartDateFilter;
	}
	getCurrentLIEndDateFilter(): string {
		return this.filterHelper.liCompletedDateFilter;
	}

	getCurrentWTOrderDateFilter(): string {
		return this.filterHelper.wtOrderDateFilter;
	}

	getCurrentWTStartDateFilter(): string {
		return this.filterHelper.wtStartDateFilter;
	}
	getCurrentWTEndDateFilter(): string {
		return this.filterHelper.wtCompletedDateFilter;
	}

	getCurrentWTStatusFilter(): Status[] {
		return this.filterHelper.workTicketStatus;
	}

	resetFilter(): void {
		if (this.filterHelper.faFilterCount === undefined && this.filterHelper.liFilterCount === undefined) {
			return;
		}
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		if (!this.isFunctionalAreasPage) this.filterHelper.resetAllFilters();
		this.filterStatus.emit(null);
		this.filterOwner.emit(null);
	}

	showPage(pageToShow, itemtype: string): void {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		if (itemtype === 'lineitem') {
			this.filterHelper.lineItemStatusSelected = true;
			this.filterHelper.workTicketStatusSelected = false;
		} else if (itemtype === 'workticket') {
			this.filterHelper.lineItemStatusSelected = false;
			this.filterHelper.workTicketStatusSelected = true;
		}
		this.filterHelper.workticketSearchPageSubject.next(true);
		this.navigate.emit(pageToShow);
	}

	toggleAttachments() {
		this.filterHelper.showLineItemAttachmentsOnlySubject.next(!this.filterHelper.showLineItemAttachmentsOnly);
		this.filterHelper.applyFilters();
	}

	togglePhotos() {
		this.filterHelper.showItemsWithPhotosOnlySubject.next(!this.filterHelper.showItemsWithPhotosOnly);
		this.filterHelper.applyFilters();
	}
	expandGlobalFilter() {
		this.filterHelper.globalFilterExpandedSubject.next(!this.globalFilterExpanded);
	}
	expandLineItemFilter() {
		this.filterHelper.lineItemExpandedSubject.next(!this.lineItemFilterExpanded);
	}
	expandWorkTicketFilter() {
		this.filterHelper.workticketExpandedSubject.next(!this.workticketFilterExpanded);
	}
}
