import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import * as $ from 'jquery';
import { Storage } from '@ionic/storage';

@Injectable({
    providedIn: 'root'
})
export class KioskSessionService {

    sessionValid: any;
    isEventListenerRegistered: any;
    sessionExpiryTime = 60;
    countDownExpiryTime = 30;
    activityTrackingInterval: any;
    inactivityInterval: any;
    countdownInterval: any;
    inactivityTime: any = 0;
    countdownTime: any = 0;
    sessionTimeout: any;
    showPopup: any;
    platformWidth: any;

    public returnToStartSubject = new BehaviorSubject(false);


    constructor(
        private router: Router,
        private platform: Platform,
        private storage: Storage
    ) {
        this.platformWidth = this.platform.width();
     }

    startActivityTracking() {
        this.storage.get('isKioskSessionValid').then((isSessionValid) => {
            this.sessionValid = isSessionValid;
        });
        this.registerEvents();
        setTimeout(() => {
            this.activityTrackingInterval = setInterval(() => {
                if (!this.showPopup && !this.inactivityInterval && this.sessionValid) {
                    this.startInctivityTimer();
                }
            }, 1000);
        }, 2000);
    }

    stopActivityTracking() {
        this.unregisterEvents();
        clearInterval(this.activityTrackingInterval);
    }

    trackActivity() {
        this.resetInactivityTimer();
    }

    startInctivityTimer() {
        this.inactivityTime = 0;
        this.inactivityInterval = setInterval(() => {
            this.inactivityTime++;
            if (this.inactivityTime > this.sessionExpiryTime) {
                this.openModal();
                this.resetInactivityTimer();
            }
        }, 1000);
    }

    resetInactivityTimer() {
        // this.sessionValid = false;
        this.inactivityTime = 0;
        clearInterval(this.inactivityInterval);
        this.inactivityInterval = null;
    }

    startCountdownTimer() {
        this.countdownTime = 0;
        this.countdownInterval = setInterval(() => {
            this.countdownTime++;
        }, 1000)
    }

    resetCountdownTimer() {
        clearInterval(this.countdownInterval);
        this.countdownTime = 0;
    }

    registerEvents() {
        this.isEventListenerRegistered = true;
        document.addEventListener('mousemove', this.trackActivity.bind(this), true);
        document.addEventListener('touchmove', this.trackActivity.bind(this), true);
        document.addEventListener('keydown', this.trackActivity.bind(this), true);
        // console.log('event listener registered');
    }

    unregisterEvents() {
        this.isEventListenerRegistered = false;
        document.removeEventListener('mousemove', this.trackActivity.bind(this), true);
        document.removeEventListener('touchmove', this.trackActivity.bind(this), true);
        document.removeEventListener('keydown', this.trackActivity.bind(this), true);
        // console.log('event listener unregistered');
    }

    async openModal() {
        this.stopActivityTracking();
        this.showPopup = true;
        this.startCountdownTimer();
        setTimeout(() => {
            $("#session-popup").fadeIn(500);
        }, 50);

        clearTimeout(this.sessionTimeout);
        this.sessionTimeout = setTimeout(() => {
            this.clearKioskDetails();
        }, (this.countDownExpiryTime + 1) * 1000);
    }

    closeSessionPopup() {
        clearTimeout(this.sessionTimeout);
        $("#session-popup").hide();
        this.showPopup = false;
        this.resetCountdownTimer();
        if (!this.isEventListenerRegistered)
            this.startActivityTracking();
    }

    clearKioskDetails(){
        $("#session-popup").hide();
        this.showPopup = false;
        this.resetInactivityTimer();
        this.resetCountdownTimer();
        this.storage.remove('kiosk-account-info');
        this.storage.set('isKioskSessionValid', false);
        this.router.navigate(['kiosk-landing'], { replaceUrl: true });
        this.stopActivityTracking();
    }
}