import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { StatusHelper } from 'src/app/helpers/status-helper';
import { CollaborationLineItemDTO } from 'src/app/models/collaboration-line-item-dto';
import { Status } from 'src/app/models/status';
import { WorkTicketDTO } from 'src/app/models/work-ticket-dto';
import { BoothServiceProvider } from 'src/app/services/booth/booth.service';
import { FunctionalAreaService } from 'src/app/services/functional-area/functional-area.service';
import { WorkTicketService } from 'src/app/services/work-ticket/work-ticket.service';

@Component({
  selector: 'app-status-warning-popover',
  templateUrl: './status-warning-popover.component.html',
  styleUrls: ['./status-warning-popover.component.scss'],
})
export class StatusWarningPopoverComponent implements OnInit {

  eventId: string;
	accountId: string;
	functionalAreaId: string;
	workTicketId: string;
	newStatus: Status;
	lineItemsSubject: BehaviorSubject<CollaborationLineItemDTO[]>;
	lineItems: CollaborationLineItemDTO[];
	workTicketsSubject: BehaviorSubject<WorkTicketDTO[]>;
	workTickets: WorkTicketDTO[];
	boothId: string;
	statusText = 'Action Required';
	changeSource: string;

	constructor(
		public navParams: NavParams,
		public popoverCtrl: PopoverController,
		public funcSvc: FunctionalAreaService,
		public workTicketService: WorkTicketService,
		private boothService: BoothServiceProvider
	) {
		this.eventId = navParams.data.eventId;
		this.accountId = navParams.data.accountId;
		this.functionalAreaId = navParams.data.functionalAreaId;
		this.workTicketId = navParams.data.workTicketId;
		this.newStatus = navParams.data.newStatus;
		this.lineItemsSubject = navParams.data.lineItemsSubject;
		this.lineItems = navParams.data.lineItems;
		this.boothId = navParams.data.boothId;

		if (this.lineItems.find(x => x.status === StatusHelper.Status.OnHold)) {
			this.statusText = 'OnHold';
		}
		this.changeSource = this.workTicketId ? 'work ticket' : 'functional area';
		if(this.boothId)
		this.changeSource = 'booth';
	}

	confirm() {
		if (this.functionalAreaId) {
			this.funcSvc.put(this.eventId, this.accountId, this.functionalAreaId, this.newStatus, this.lineItemsSubject, this.lineItems);
		} else if (this.workTicketId) {
			this.workTicketService.put(this.eventId, this.workTicketService.allWorkTickets.find(x => x.workTicketGuid === this.workTicketId), this.newStatus, this.lineItemsSubject, this.lineItems);
		}
		else if(this.boothId)
		{
			this.boothService.setBoothStatus(this.eventId, this.boothId, null, this.newStatus.id, null, null, true);
				
		}

		this.popoverCtrl.dismiss();
	}

	cancel() {
		this.popoverCtrl.dismiss();
	}

  ngOnInit() {}

}
