import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CreateMHADTO } from 'src/app/models/create-mha-dto';
import { UpdateMHADTO } from 'src/app/models/update-mha-dto';
import { environment } from 'src/environments/environment';
import { ToastService } from '../toast/toast.service';
import { Storage } from '@ionic/storage';
import * as _ from 'lodash';
import { ZipcodePipe } from 'src/app/pipes/zipcode-number-format.pipe';

@Injectable({
  providedIn: 'root'
})
export class CreateMhaService {
  url = environment.apiUrl;

  public stateData: any = [
    {
      "display": "Alabama",
      "value": "AL"
    },
    {
      "display": "Alaska",
      "value": "AK"
    },
    {
      "display": "Arizona",
      "value": "AZ"
    },
    {
      "display": "Arkansas",
      "value": "AR"
    },
    {
      "display": "California",
      "value": "CA"
    },
    {
      "display": "Colorado",
      "value": "CO"
    },
    {
      "display": "Connecticut",
      "value": "CT"
    },
    {
      "display": "Delaware",
      "value": "DE"
    },
    {
      "display": "Florida",
      "value": "FL"
    },
    {
      "display": "Georgia",
      "value": "GA"
    },
    {
      "display": "Hawaii",
      "value": "HI"
    },
    {
      "display": "Idaho",
      "value": "ID"
    },
    {
      "display": "Illinois",
      "value": "IL"
    },
    {
      "display": "Indiana",
      "value": "IN"
    },
    {
      "display": "Iowa",
      "value": "IA"
    },
    {
      "display": "Kansas",
      "value": "KS"
    },
    {
      "display": "Kentucky",
      "value": "KY"
    },
    {
      "display": "Louisiana",
      "value": "LA"
    },
    {
      "display": "Maine",
      "value": "ME"
    },
    {
      "display": "Maryland",
      "value": "MD"
    },
    {
      "display": "Massachusetts",
      "value": "MA"
    },
    {
      "display": "Michigan",
      "value": "MI"
    },
    {
      "display": "Minnesota",
      "value": "MN"
    },
    {
      "display": "Mississippi",
      "value": "MS"
    },
    {
      "display": "Missouri",
      "value": "MO"
    },
    {
      "display": "Montana",
      "value": "MT"
    },
    {
      "display": "Nebraska",
      "value": "NE"
    },
    {
      "display": "Nevada",
      "value": "NV"
    },
    {
      "display": "New Hampshire",
      "value": "NH"
    },
    {
      "display": "New Jersey",
      "value": "NJ"
    },
    {
      "display": "New Mexico",
      "value": "NM"
    },
    {
      "display": "New York",
      "value": "NY"
    },
    {
      "display": "North Carolina",
      "value": "NC"
    },
    {
      "display": "North Dakota",
      "value": "ND"
    },
    {
      "display": "Ohio",
      "value": "OH"
    },
    {
      "display": "Oklahoma",
      "value": "OK"
    },
    {
      "display": "Oregon",
      "value": "OR"
    },
    {
      "display": "Pennsylvania",
      "value": "PA"
    },
    {
      "display": "Rhode Island",
      "value": "RI"
    },
    {
      "display": "South Carolina",
      "value": "SC"
    },
    {
      "display": "South Dakota",
      "value": "SD"
    },
    {
      "display": "Tennessee",
      "value": "TN"
    },
    {
      "display": "Texas",
      "value": "TX"
    },
    {
      "display": "Utah",
      "value": "UT"
    },
    {
      "display": "Vermont",
      "value": "VT"
    },
    {
      "display": "Virginia",
      "value": "VA"
    },
    {
      "display": "Washington",
      "value": "WA"
    },
    {
      "display": "West Virginia",
      "value": "WV"
    },
    {
      "display": "Wisconsin",
      "value": "WI"
    },
    {
      "display": "Wyoming",
      "value": "WY"
    }
  ];
  public countryData: any = [
    { display: 'US', value: "USA" }
  ];
  public mhaInfo: CreateMHADTO;
  public mhaUpdateInfo: UpdateMHADTO;
  public createMhaData: any;
  createMhaObject: any;
  currentMHADataSubject = new BehaviorSubject<CreateMHADTO>(null);
  mhaGUID: string;
  currentBoothId: string;
  boothNumber: string;
  mhaId: number;
  shippingZipCodeData: any;
  billingZipCodeData: any;

  isShippingFormValid: any;
  isShippingFormCompleted: any;
  isCarrierFormValid: any = true;
  isCarrierFormCompleted: any;
  showErrorsForCarrier: any;
  accountInfo: any;

  isCreateMHAInProgress: boolean = false;
  isMHASubmitted: boolean = false;

  constructor(
    public http: HttpClient,
    public toastService: ToastService,
    private storage: Storage,
    private zipcodePipe: ZipcodePipe
  ) {
    this.resetMhaFields();
  }

  createMHA(eventId: string, boothGUID: string) {
    this.mhaInfo.accountGuid = this.accountInfo && this.accountInfo.accountGuid ? this.accountInfo.accountGuid : "";
    this.mhaInfo.isUpdate = false;
    this.mhaInfo.notifyShowClose = false;
    this.mhaInfo.isCompleted = false;
    this.mhaInfo.btOrgAddress1 = this.createMhaData.billing.addressLine1;
    this.mhaInfo.btOrgAddress2 = this.createMhaData.billing.addressLine2;
    this.mhaInfo.btOrgCity = this.createMhaData.billing.city;
    this.mhaInfo.btOrgContactFirstname = this.createMhaData.billing.firstName ? this.createMhaData.billing.firstName.trim() : "";
    this.mhaInfo.btOrgContactLastname = this.createMhaData.billing.lastName ? this.createMhaData.billing.lastName.trim() : "";
    this.mhaInfo.btOrgContactPhone = this.createMhaData.billing.phoneNumber || "";
    this.mhaInfo.btOrgCountryCd = this.createMhaData.billing.country ? this.createMhaData.billing.country.value : "";
    this.mhaInfo.btOrgName = this.createMhaData.billing.companyName;
    this.mhaInfo.btOrgPostalCode = this.createMhaData.billing.postalCode;
    this.mhaInfo.btOrgStateCd = this.createMhaData.billing.state.value;
    this.mhaInfo.stOrgAddress1 = this.createMhaData.shipping.address;
    this.mhaInfo.stOrgAddress2 = this.createMhaData.shipping.address2;
    this.mhaInfo.stOrgCity = this.createMhaData.shipping.city;
    this.mhaInfo.stOrgContactFirstName = this.createMhaData.shipping.contactFName ? this.createMhaData.shipping.contactFName.trim() : "";
    this.mhaInfo.stOrgContactLastName = this.createMhaData.shipping.contactLName ? this.createMhaData.shipping.contactLName.trim() : "";
    this.mhaInfo.stOrgContactPhone = this.createMhaData.shipping.phoneNumber;
    this.mhaInfo.stOrgCountryCd = this.createMhaData.shipping.country.value;
    this.mhaInfo.stOrgName = this.createMhaData.shipping.companyName;
    this.mhaInfo.stOrgPostalCode = this.createMhaData.shipping.zipCode;
    this.mhaInfo.stOrgStateCd = this.createMhaData.shipping.state.value;
    this.mhaInfo.emergencyContact = this.createMhaData.shipping.emergencyContactFirstName.trim() + " " + this.createMhaData.shipping.emergencyContactLastName.trim();
    this.mhaInfo.emergencyPhone = this.createMhaData.shipping.emergencyContactMobileNo;
    this.mhaInfo.boothGuid = boothGUID;
    if (this.createMhaData.carrier.isExhibitTransportation == 2) {
      if (this.createMhaData.carrier.otherCarrierName) {
        this.mhaInfo.otherCarrierName = this.createMhaData.carrier.otherCarrierName;
      } else {
        this.mhaInfo.otherCarrierName = this.createMhaData.carrier.anotherCarrierInfo.display;
        this.mhaInfo.carrierKey = this.createMhaData.carrier.anotherCarrierInfo.value;
      }
    } else {
      this.mhaInfo.otherCarrierName = null;
      this.mhaInfo.carrierKey = null;
    }

    this.mhaInfo.otherCarrierType = "";
    this.mhaInfo.isFET = this.createMhaData.carrier.isExhibitTransportation == 1 ? true : false;

    return this.http.post(`${this.url()}kioskusers/event/${eventId}/mha/create`, this.mhaInfo);
  }

  updateMHA(eventId: string, MHAGuid: string, isSubmit?: boolean, notifyShowClose?: boolean) {
    this.mhaInfo.accountGuid = this.accountInfo && this.accountInfo.accountGuid ? this.accountInfo.accountGuid : "";
    this.mhaInfo.mHAGuid = MHAGuid;
    this.mhaInfo.totalWeight = this.createMhaData.freight.totalWeight;
    this.mhaInfo.loadingDockInd = this.createMhaData.freight.loadingDockConsent;
    this.mhaInfo.residenceInd = this.createMhaData.freight.resDeliveryConsent;
    this.mhaInfo.insideInd = this.createMhaData.freight.insideDeliveryConsent;
    this.mhaInfo.liftGateInd = this.createMhaData.freight.liftGateConsent;
    this.mhaInfo.padWrappedInd = this.createMhaData.freight.padWrapConsent;
    this.mhaInfo.airRideInd = this.createMhaData.freight.airConsent;
    this.mhaInfo.doNotStackInd = this.createMhaData.freight.stackedConsent;
    this.mhaInfo.comments = this.createMhaData.freight.additionalInstruction;
    this.mhaInfo.otherCarrierType = this.createMhaData.freight.deliveryOption;
    this.mhaInfo.crateQty = this.createMhaData.freight.crateQty;
    this.mhaInfo.crateComments = this.createMhaData.freight.crateComments;
    this.mhaInfo.cartonQty = this.createMhaData.freight.cartonQty;
    this.mhaInfo.cartonComments = this.createMhaData.freight.cartonComments;
    this.mhaInfo.caseQty = this.createMhaData.freight.caseQty;
    this.mhaInfo.caseColor = this.createMhaData.freight.caseColor;
    this.mhaInfo.caseComments = this.createMhaData.freight.caseComments;
    this.mhaInfo.skidQty = this.createMhaData.freight.skidQty;
    this.mhaInfo.skidWrapped = this.createMhaData.freight.skidWrapped ? 'Y' : 'N';
    this.mhaInfo.skidComments = this.createMhaData.freight.skidComments;
    this.mhaInfo.carpetQty = this.createMhaData.freight.carpetQty;
    this.mhaInfo.carpetColor = this.createMhaData.freight.carpetColor;
    this.mhaInfo.carpetWrapped = this.createMhaData.freight.carpetWrapped ? 'Y' : 'N';
    this.mhaInfo.carpetComments = this.createMhaData.freight.carpetComments;
    this.mhaInfo.padQty = this.createMhaData.freight.padQty;
    this.mhaInfo.padWrapped = this.createMhaData.freight.padWrapped ? 'Y' : 'N';
    this.mhaInfo.padComments = this.createMhaData.freight.padComments;
    this.mhaInfo.otherQty = this.createMhaData.freight.otherQty;
    this.mhaInfo.otherComments = this.createMhaData.freight.otherComments;
    this.mhaInfo.isUpdate = true;
    this.mhaInfo.notifyShowClose = notifyShowClose;
    if (notifyShowClose && this.accountInfo?.mobileInfo?.PhoneNo)
      this.mhaInfo.mobileNo = this.accountInfo.mobileInfo.PhoneNo;
    else
      this.mhaInfo.mobileNo = '';
    this.mhaInfo.isCompleted = isSubmit ? true : false;

    return this.http.post(`${this.url()}kioskusers/event/${eventId}/mha/create`, this.mhaInfo);
  }

  getSelectedMHAData(eventGuid, currentBoothId, MHAGuid) {
    return this.http.get<CreateMHADTO>(`${this.url()}kioskusers/event/${eventGuid}/booth/${currentBoothId}/MHA/${MHAGuid}`).pipe(map((data: any) => {
      if (data != null) {
        data.mha['isFET'] = data.isFET;
        data.mha['isOtherCarrier'] = data.isOtherCarrier;
        this.isMHASubmitted = data.mha.status == 'MHA COMPLETE' ? true : false;
        this.currentMHADataSubject.next(data.mha);
        this.prefillMHADetails();
      }
      return data;
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error to getting Outbound Shipping paperwork data', 'danger');
      }
    }));
  }

  prefillMHADetails() {
    this.currentMHADataSubject.subscribe((mhaData: any) => {
      if (mhaData != null) {

        //shipping info
        this.createMhaData.shipping = {};
        this.createMhaData.shipping.address = mhaData.stOrgAddress1;
        this.createMhaData.shipping.address2 = mhaData.stOrgAddress2;
        this.createMhaData.shipping.city = mhaData.stOrgCity;
        this.createMhaData.shipping.contactFName = mhaData.stOrgContactFirstName;

        this.createMhaData.shipping.contactLName = mhaData.stOrgContactLastName;
        this.createMhaData.shipping.phoneNumber = mhaData.stOrgContactPhone;
        this.createMhaData.shipping.country = _.find(this.countryData, ['value', mhaData.stOrgCountryCd]);
        this.createMhaData.shipping.companyName = mhaData.stOrgName;
        this.createMhaData.shipping.zipCode = mhaData.stOrgPostalCode ? this.zipcodePipe.transform(mhaData.stOrgPostalCode) : null;
        this.createMhaData.shipping.state = _.find(this.stateData, ['value', mhaData.stOrgStateCd]);
        this.createMhaData.shipping.emergencyContactFirstName = mhaData.emergencyContact?.split(" ")[0];
        this.createMhaData.shipping.emergencyContactLastName = mhaData.emergencyContact?.split(" ")[1];
        this.createMhaData.shipping.emergencyContactMobileNo = mhaData.emergencyPhone;

        //carrier/other details
        if (!mhaData.isOtherCarrier && !mhaData.isFET) {
          this.createMhaData.carrier.isExhibitTransportation = 2;
          this.createMhaData.carrier.anotherCarrierInfo = {
            display: mhaData.otherCarrierName,
            value: mhaData.carrierKey
          };
          this.createMhaData.carrier.otherCarrierName = "";
          this.createMhaData.carrier.isAgreeChecked = true;
        } else if (mhaData.isOtherCarrier && mhaData.otherCarrierName) {
          this.createMhaData.carrier.isExhibitTransportation = 2;
          this.createMhaData.carrier.anotherCarrierInfo = {
            display: "Other Carrier",
            value: 0
          };
          this.createMhaData.carrier.otherCarrierName = mhaData.otherCarrierName;
          this.createMhaData.carrier.isAgreeChecked = true;
        } else {
          this.createMhaData.carrier.isExhibitTransportation = 1;
          this.createMhaData.carrier.anotherCarrierInfo = {};
          this.createMhaData.carrier.otherCarrierName = "";
          this.createMhaData.carrier.isAgreeChecked = false;
        }

        //billing details
        this.createMhaData.billing.addressLine1 = mhaData.btOrgAddress1;
        this.createMhaData.billing.addressLine2 = mhaData.btOrgAddress2;
        this.createMhaData.billing.city = mhaData.btOrgCity;
        this.createMhaData.billing.firstName = mhaData.btOrgContactFirstname;
        this.createMhaData.billing.lastName = mhaData.btOrgContactLastname;
        this.createMhaData.billing.phoneNumber = mhaData.btOrgContactPhone;
        this.createMhaData.billing.companyName = mhaData.btOrgName;
        //this.createMhaData.billing.postalCode = mhaData.btOrgPostalCode;
        this.createMhaData.billing.postalCode = mhaData.btOrgPostalCode ? this.zipcodePipe.transform(mhaData.btOrgPostalCode) : null;;

        this.createMhaData.billing.state = _.find(this.stateData, ['value', mhaData.btOrgStateCd]);
        this.createMhaData.billing.country = _.find(this.countryData, ['value', mhaData.btOrgCountryCd]);
        this.compareAddress();

        //freight details
        this.createMhaData.freight = {};
        this.createMhaData.freight.totalWeight = mhaData.totalWt;
        this.createMhaData.freight.loadingDockConsent = mhaData.loadingDockInd;
        this.createMhaData.freight.resDeliveryConsent = mhaData.residenceInd;
        this.createMhaData.freight.insideDeliveryConsent = mhaData.insideInd;
        this.createMhaData.freight.liftGateConsent = mhaData.liftGateInd;
        this.createMhaData.freight.padWrapConsent = mhaData.padWrappedInd;
        this.createMhaData.freight.airConsent = mhaData.airRideInd;
        this.createMhaData.freight.stackedConsent = mhaData.doNotStackInd;
        this.createMhaData.freight.additionalInstruction = mhaData.comments;
        this.createMhaData.freight.deliveryOption = mhaData.ceCarrierType;
        this.createMhaData.freight.crateQty = mhaData.crateQty;
        this.createMhaData.freight.crateComments = mhaData.crateComments;
        this.createMhaData.freight.cartonQty = mhaData.cartonQty;
        this.createMhaData.freight.cartonComments = mhaData.cartonComments;
        this.createMhaData.freight.caseQty = mhaData.caseQty;
        this.createMhaData.freight.caseColor = mhaData.caseColor;
        this.createMhaData.freight.caseComments = mhaData.caseComments;
        this.createMhaData.freight.skidQty = mhaData.skidQty;
        this.createMhaData.freight.skidWrapped = mhaData.skidWrapped == 'Y' ? true : false;
        this.createMhaData.freight.skidComments = mhaData.skidComments;
        this.createMhaData.freight.carpetQty = mhaData.carpetQty;
        this.createMhaData.freight.carpetColor = mhaData.carpetColor;
        this.createMhaData.freight.carpetWrapped = mhaData.carpetWrapped == 'Y' ? true : false;
        this.createMhaData.freight.carpetComments = mhaData.carpetComments;
        this.createMhaData.freight.padQty = mhaData.padQty;
        this.createMhaData.freight.padWrapped = mhaData.padWrapped == 'Y' ? true : false;
        this.createMhaData.freight.padComments = mhaData.padComments;
        this.createMhaData.freight.otherQty = mhaData.otherQty;
        this.createMhaData.freight.otherComments = mhaData.otherComments;

        this.mhaId = mhaData.mhaId;
      }
    });
  }

  compareAddress() {
    this.createMhaData.billing.isSameShippingInfo = false;
    let matchCount: any = 0;
    let billingKeys = ['companyName', 'addressLine1', 'addressLine2', 'city', 'state', 'country', 'postalCode'];
    let shippingKeys = ['companyName', 'address', 'address2', 'city', 'state', 'country', 'zipCode'];
    if (!this.createMhaData.shipping)
      this.createMhaData.shipping = {};
    billingKeys.forEach((e, i) => {
      if (e == 'state' || e == 'country') {
        if (this.createMhaData.billing[e]?.value == this.createMhaData.shipping[shippingKeys[i]]?.value)
          matchCount++;
      } else if (this.createMhaData.billing[e] == this.createMhaData.shipping[shippingKeys[i]])
          matchCount++;
    });
    if (matchCount == billingKeys.length)
      this.createMhaData.billing.isSameShippingInfo = true;
  }

  validateZipCode(eventId, type) {
    let zipCodeValue, stateValue;
    switch (type) {
      case 'billing': {
        zipCodeValue = this.createMhaData.billing.postalCode.replace("-","");
        stateValue = this.createMhaData.billing.state.value;
        break;
      }
      case 'shipping': {
        zipCodeValue = this.createMhaData.shipping.zipCode.replace("-","");
        stateValue = this.createMhaData.shipping.state.value;
      }

    }

    return new Promise((resolve, reject) => {
      this.verifyZipCode(eventId, zipCodeValue).subscribe((res: any) => {
        if (res.state == stateValue)
          resolve(true);
        resolve(false);
      }, err => {
        resolve(false);
      })
    })

  }

  verifyZipCode(eventId, zipCodeValue) {
    let zipCode = zipCodeValue.trim().substring(0, 5);
    return this.http.get<[]>(`${this.url()}kioskusers/event/${eventId}/verifyZipCode/${zipCode}`);
  }

  resetMhaFields() {
    this.mhaId = null;
    this.mhaInfo = <CreateMHADTO>{};
    this.mhaUpdateInfo = <UpdateMHADTO>{};
    this.shippingZipCodeData = null;
    this.billingZipCodeData = null;
    this.createMhaData = {
      shipping: null,
      carrier: {
        isExhibitTransportation: 1,
        anotherCarrierInfo: {
          display: '',
          value: ''
        },
        otherCarrierName: "",
        isAgreeChecked: false
      },
      billing: {
        isSameShippingInfo: true,
        firstName: "",
        lastName: "",
        phoneCountryCode: {},
        mobileNo: "",
        companyName: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: {
          value: ""
        },
        country: {
          value: ""
        },
        postalCode: ""
      },
      freight: {
        totalWeight: 0,
        loadingDockConsent: 'N',
        resDeliveryConsent: 'N',
        insideDeliveryConsent: 'N',
        liftGateConsent: 'N',
        padWrapConsent: 'N',
        airConsent: 'N',
        stackedConsent: 'N',
        additionalInstruction: '',
        deliveryOption: '',
        crateQty: null,
        crateComments: '',
        cartonQty: null,
        cartonComments: '',
        caseQty: null,
        caseColor: '',
        caseComments: '',
        skidQty: null,
        skidWrapped: false,
        skidComments: '',
        carpetQty: null,
        carpetColor: '',
        carpetWrapped: false,
        carpetComments: '',
        padQty: null,
        padWrapped: false,
        padComments: '',
        otherQty: null,
        otherComments: ''
      }
    };

    this.createMhaObject = {
      shipping: null,
      carrier: {
        isExhibitTransportation: 1,
        anotherCarrierInfo: {},
        otherCarrierName: "",
        isAgreeChecked: false
      },
      billing: {
        isSameShippingInfo: true,
        firstName: "",
        lastName: "",
        phoneCountryCode: {},
        mobileNo: "",
        companyName: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: {},
        country: {},
        postalCode: ""
      },
      freight: null
    };
  }
}
