import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { Platform, ModalController, PopoverController } from '@ionic/angular';
import { AuthTokenHelper } from 'src/app/helpers/auth-token-helper';
import { NavParamHelper } from 'src/app/helpers/nav-param-helper';
import { StatusHelper } from 'src/app/helpers/status-helper';
import { Owner } from 'src/app/models/owner';
import { Show } from 'src/app/models/show';
import { Status } from 'src/app/models/status';
import { WorkTicketDTO } from 'src/app/models/work-ticket-dto';
import { WorkTicketGroup } from 'src/app/models/work-ticket-group';
import { EventService } from 'src/app/services/event/event.service';
import { UserService } from 'src/app/services/user/user.service';
import { WorkTicketTimelineService } from 'src/app/services/work-ticket-timeline/work-ticket-timeline.service';
import { WorkTicketService } from 'src/app/services/work-ticket/work-ticket.service';
import { takeWhile } from 'rxjs/operators';
import { WorkticketLineItemService } from 'src/app/services/workticket-line-item/workticket-line-item.service';
import { CollaborationLineItemDTO } from 'src/app/models/collaboration-line-item-dto';
import { BehaviorSubject } from 'rxjs';
import { BulkUpdateModalComponent } from 'src/app/components/bulk-update-modal/bulk-update-modal.component';
import { ItemActionMenuComponent } from 'src/app/components/item-action-menu/item-action-menu.component';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';
// import { StatusWarningPopoverComponent } from 'src/app/components/status-warning-popover/status-warning-popover.component';

@Component({
	selector: 'app-worktickets',
	templateUrl: './worktickets.page.html',
	styleUrls: ['./worktickets.page.scss'],
})
export class WorkticketsPage implements OnInit {
	// From route params
	eventId: string;
	statusMenuPopover: any;
	itemActionModal: any;
	displayCountIncrement = 50;

	showFilter = false;
	searchText = '';
	statusFilter: Status[] = [];
	ownerFilter: Owner[] = [];

	isMobile = this.platform.is('mobile');
	refreshEnabled = true;
	modalOpen: boolean; // to hide the footer when the modal is open.
	selectedTicket: WorkTicketDTO;

	subscribe = true;
	allWorkTickets: WorkTicketDTO[];
	currentWorkTickets: WorkTicketDTO[];
	currentNullWorkTickets: WorkTicketDTO[];
	categoryList: WorkTicketGroup[];
	functionalAreaList: WorkTicketGroup[];
	savedCatList: WorkTicketGroup[];
	show: Show;
	todoTab = true;
	completedTab = false;
	// TODO: Take a look at why we're using colors here
	todoButtonColor = 'primary';
	completedButtonColor = 'light';
	isLoading: boolean;
	firstLoad = false;
	Status = StatusHelper.Status;
	isSalesUser = false;
	currentWTTab = null;
	isCategoryView: boolean = false;
	isFAView: boolean = true;
	refresher: any;

	constructor(
		public router: Router,
		public platform: Platform,
		public navParamHelper: NavParamHelper,
		public eventSvc: EventService,
		public userSvc: UserService,
		public workTicketSvc: WorkTicketService,
		private ga: GoogleAnalytics,
		public workTicketTimelineSvc: WorkTicketTimelineService,
		public authTokenHelper: AuthTokenHelper,
		public workTicketLineItemSvc: WorkticketLineItemService,
		private activatedRoute: ActivatedRoute,
		public modalController: ModalController,
		public popoverCtrl: PopoverController,
		public dataSvc: DataRefreshService,
	) {

	}

	ngOnInit() {
		this.eventId = this.activatedRoute.snapshot.paramMap.get('eventId');
		this.dataSvc.eventActive = this.eventId;
		this.navParamHelper.syncActiveInfo(this.eventId);
		this.checkUserType();
		this.isLoading = true;
		this.workTicketSvc.currentWTTab.pipe(takeWhile(() => this.subscribe)).subscribe(c => {
			this.currentWTTab = c;
		});
		this.workTicketSvc.allWorkTicketsSubject.pipe(takeWhile(() => this.subscribe)).subscribe(wt => {
			if (this.refresher) {
				this.refresher.target.complete();
			}
			this.allWorkTickets = wt;
			if (this.currentWTTab === 'completed') {
				this.filterByCompleted();
			} else {
				this.filterByToDo();
			}
			if (this.firstLoad) {
				this.firstLoad = false;
				this.isLoading = false;
			}
		});
		this.firstLoad = true;
		this.eventSvc.currentEvent.pipe(takeWhile(() => this.subscribe)).subscribe(s => {
			this.show = s;
		});
		this.workTicketSvc.workTicketsExpanded.pipe(takeWhile(() => this.subscribe)).subscribe(exp => {
			this.savedCatList = exp;
		});
	}

	ionViewWillEnter() {
		this.eventSvc.get(this.eventId);
		this.userSvc.get(this.eventId);
		this.workTicketSvc.get(this.eventId);
		this.workTicketTimelineSvc.getTimeline(this.eventId, true);
	}

	async checkUserType() {
		this.isSalesUser = await this.authTokenHelper.IsSalesUser();
	}

	ionViewDidLoad() {
		this.ga.trackView('Work Tickets Page')
			.catch(e => console.log(e));
	}

	ionViewWillLeave() {
		if (this.statusMenuPopover) {
			this.statusMenuPopover.dismiss();
		}
		if (this.itemActionModal) {
			this.itemActionModal.dismiss();
		}
	}

	updateSearchText(searchText: string): void {
		this.searchText = searchText;
	}

	refresh(refresher): void {
		this.workTicketSvc.refresh(this.eventId);
		this.refresher = refresher;
	}

	async presentPopover(event, ticket: WorkTicketDTO) {
		this.statusMenuPopover = await this.popoverCtrl.create({
			component: ItemActionMenuComponent,
			id:'wt-action-popup',
			event: event,
			componentProps: { ticket: ticket, eventId: this.eventId }
		});
		await this.statusMenuPopover.present();

		await this.statusMenuPopover.onDidDismiss();
		this.clearSelectedArea();
		event.stopPropagation();
	}

	async presentItemActionModal(ticket: WorkTicketDTO, itemSliding: any) {
		this.itemActionModal = await this.modalController.create({
			component: ItemActionMenuComponent,
			id:'wt-action-modal',
			componentProps: { ticket: ticket, eventId: this.eventId }
		});
		await this.itemActionModal.present();

		await this.itemActionModal.onDidDismiss();
		itemSliding.close();
	}

	modalClosed() {
		this.modalOpen = false;
	}

	changeStatus(workTicket: WorkTicketDTO, newStatus: Status) {
		this.workTicketLineItemSvc.refresh(this.eventId, workTicket.workTicketGuid).subscribe(() => {
			// if (this.workTicketLineItemSvc.collaborationLineItemDto.getValue().find(x => x.status === StatusHelper.Status.OnHold) && (newStatus === StatusHelper.Status.New || newStatus === StatusHelper.Status.InProgress || newStatus === StatusHelper.Status.Complete)) {
			// 	this.presentWarningPopover(this.eventId, workTicket.workTicketGuid, newStatus, this.workTicketLineItemSvc.collaborationLineItemDto, this.workTicketLineItemSvc.collaborationLineItemDto.getValue());
			// } else {
			// }
			this.workTicketSvc.put(this.eventId, workTicket, newStatus, this.workTicketLineItemSvc.collaborationLineItemDto, this.workTicketLineItemSvc.currentLineItemDto);
		});
	}

	// async presentWarningPopover(eventId: string, workTicketId: string, newStatus: Status, lineItemsSubject: BehaviorSubject<CollaborationLineItemDTO[]>, lineItems?: CollaborationLineItemDTO[]) {
	// 	const popover = await this.popoverCtrl.create({
	// 		component: StatusWarningPopoverComponent,
	// 		componentProps: { eventId: eventId, workTicketId: workTicketId, newStatus: newStatus, lineItemsSubject: lineItemsSubject, lineItems: lineItems }
	// 	});
	// 	await popover.present();
	// }

	clearSelectedArea(): void {
		this.selectedTicket = null;
	}

	delayAndCloseIonItemSliding(itemSliding) {
		setTimeout(() => {
			itemSliding.close();
		}, 1);
	}

	pushDetailsPage(ticket: WorkTicketDTO): void {
		this.workTicketSvc.activeWorkTicketIdSubject.next(ticket.workTicketGuid);

		let currentWorkTicket = this.workTicketSvc.currentWorkTicketSubject.getValue();

		if (!currentWorkTicket || currentWorkTicket !== ticket) {
			// this.attachmentSvc.faAttachments.next([]);
			this.workTicketLineItemSvc.collaborationLineItemDto.next([]);
			this.workTicketLineItemSvc.currentLineItem.next(null);
		}

		currentWorkTicket = this.allWorkTickets.find(wt => wt.workTicketGuid === ticket.workTicketGuid);
		this.workTicketSvc.currentWorkTicketSubject.next(currentWorkTicket);

		this.router.navigate(["/events", this.eventId, 'worktickets', ticket.workTicketGuid, 'workticket-details']);
	}

	loadMoreWorkTickets(wtGroup: WorkTicketGroup) {
		wtGroup.displayCount += this.displayCountIncrement;
	}

	filterByToDo() {
		this.todoTab = true;
		this.completedTab = false;
		this.workTicketSvc.currentWTTab.next('todo');
		this.todoButtonColor = 'primary';
		this.completedButtonColor = 'light';
		this.currentWorkTickets = [];

		// filter out customer approved (4), ready for review (3) statuses, and have dates
		this.currentWorkTickets = this.allWorkTickets.filter(ticket => {
			if (ticket.currentStatus != null) {
				return ((ticket.currentStatus.id !== 3) && (ticket.currentStatus.id !== 4));
			} else {
				return;
			}
		});
		if (this.isCategoryView) {
			this.getCategoryList();
		} else {
			this.getFunctionalAreaList();
		}
	}

	filterByCompleted() {
		this.todoTab = false;
		this.completedTab = true;
		this.workTicketSvc.currentWTTab.next('completed');
		this.todoButtonColor = 'light';
		this.completedButtonColor = 'primary';
		this.currentWorkTickets = [];
		// filter out everything except customer approved (4) and ready for review (3) statuses
		this.currentWorkTickets = this.allWorkTickets.filter(ticket => {
			return ((ticket.currentStatus.id === 3) || (ticket.currentStatus.id === 4));
		});
		if (this.isCategoryView) {
			this.getCategoryList();
		} else {
			this.getFunctionalAreaList();
		}
	}

	getCategoryList() {
		const itemList = this.currentWorkTickets;
		this.categoryList = [];

		const newCategoryList = itemList.reduce(function (obj, item) {
			obj[item.partGroup] = obj[item.partGroup] || [];
			obj[item.partGroup].push(item);
			return obj;
		}, {});
		this.categoryList = Object.keys(newCategoryList).map(key => {
			return { groupName: key, numberOfWorkTickets: newCategoryList[key].length, isExpanded: false, displayCount: this.displayCountIncrement };
		});
		this.categoryList.sort((a, b) => a.groupName < b.groupName ? -1 : 1);

		// get saved states, if they exist
		if (this.savedCatList) {
			// gross, but works
			this.savedCatList.forEach(scl => {
				this.categoryList.forEach(cl => {
					if (scl.groupName === cl.groupName) {
						cl.isExpanded = scl.isExpanded;
					}
				});
			});
		}
	}

	getFunctionalAreaList() {
		const itemList = this.currentWorkTickets;
		this.functionalAreaList = [];

		const newFAList = itemList.reduce(function (obj, item) {
			obj[item.functionalAreaName] = obj[item.functionalAreaName] || [];
			obj[item.functionalAreaName].push(item);
			return obj;
		}, {});
		this.functionalAreaList = Object.keys(newFAList).map(key => {
			return { groupName: key, numberOfWorkTickets: newFAList[key].length, isExpanded: false, displayCount: this.displayCountIncrement };
		});
		this.functionalAreaList.sort((a, b) => a.groupName < b.groupName ? -1 : 1);

		// get saved states, if they exist
		if (this.savedCatList) {
			// gross, but works
			this.savedCatList.forEach(scl => {
				this.functionalAreaList.forEach(cl => {
					if (scl.groupName === cl.groupName) {
						cl.isExpanded = scl.isExpanded;
					}
				});
			});
		}
	}

	changeView() {
		this.isCategoryView = !this.isCategoryView;
		this.isFAView = !this.isFAView;
		if (this.currentWTTab === 'completed') {
			this.filterByCompleted();
		} else {
			this.filterByToDo();
		}
	}

	toggleExpandSelected(event: Event, tikGroup: WorkTicketGroup) {
		const currentState = tikGroup.isExpanded;

		this.categoryList.forEach(cat => {
			if (cat.groupName === tikGroup.groupName) {
				cat.isExpanded = currentState ? false : true;
			}
		});
	}

	expandItem(wtGroup: WorkTicketGroup): void {
		wtGroup.isExpanded = !wtGroup.isExpanded;
		wtGroup.displayCount = this.displayCountIncrement;
		if (this.isFAView) {
			this.savedCatList = this.functionalAreaList;
		} else {
			this.savedCatList = this.categoryList;
		}
	}

	expandAll(): void {
		if (this.isFAView) {
			this.functionalAreaList.map(wtGroup => {
				wtGroup.isExpanded = true;
				return wtGroup;
			});
			this.savedCatList = this.functionalAreaList;
		} else {
			this.categoryList.map(wtGroup => {
				wtGroup.isExpanded = true;
				return wtGroup;
			});
			this.savedCatList = this.categoryList;
		}
	}

	collapseAll(): void {
		if (this.isFAView) {
			this.functionalAreaList.map(wtGroup => {
				wtGroup.isExpanded = false;
				wtGroup.displayCount = this.displayCountIncrement;
				return wtGroup;
			});
			this.savedCatList = this.functionalAreaList;
		} else {
			this.categoryList.map(wtGroup => {
				wtGroup.isExpanded = false;
				wtGroup.displayCount = this.displayCountIncrement;
				return wtGroup;
			});
			this.savedCatList = this.categoryList;
		}
	}

	goBack() {
		// if (this.navCtrl.canGoBack()) {
		// 	this.navCtrl.pop();
		// }
	}

	async bulkUpdate(wtGroup) {
		const wtBulkUpdateModal = await this.modalController.create({
			component: BulkUpdateModalComponent,
			cssClass: 'bulk-update-modal',
			id: 'bulk-update-modal',
			showBackdrop: true,
			componentProps: { wtGroup: wtGroup, eventId: this.eventId, isFAView: this.isFAView }
		});
		return await wtBulkUpdateModal.present();
	}

	ngOnDestroy() {
		this.subscribe = false;
		this.workTicketSvc.allWorkTicketsSubject.next([]);
	}
}
