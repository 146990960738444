import { Component, OnInit } from '@angular/core';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { ModalController, NavParams } from '@ionic/angular';
import { MentionConfig } from 'angular-mentions';
import { CollaborationLineItemDTO } from 'src/app/models/collaboration-line-item-dto';
import { ShowBooth } from 'src/app/models/show-booth-dto';
import { ActivityService } from 'src/app/services/activity/activity.service';
import { BoothServiceProvider } from 'src/app/services/booth/booth.service';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { UserService } from 'src/app/services/user/user.service';
import { WorkticketActivityService } from 'src/app/services/workticket-activity/workticket-activity.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss'],
})
export class AddNoteComponent implements OnInit {
	public faID: string;
	public noteTitle: string;
	public liId: string;
	public wtId: string;
	public showAccountId: string;
	mentionConfig: MentionConfig;
	opsMentionConfig: MentionConfig;
	subscribe = true;
	boothData: ShowBooth;
	boothLineItem: CollaborationLineItemDTO;

	constructor(
		public modalCtrl: ModalController,
		public activitySvc: ActivityService,
		public navParams: NavParams,
		public toastService: ToastService,
		public keyboard: Keyboard,
		public dataSvc: DataRefreshService,
		public userSvc: UserService,
		public workTikActivitySvc: WorkticketActivityService,
		public boothService: BoothServiceProvider
	) {
		this.faID = this.navParams.get('faID');
		this.wtId = this.navParams.get('wtId');
		this.boothData = this.navParams.get('boothData');
		this.boothLineItem = this.navParams.get('boothLineItem');
		this.noteTitle = this.boothLineItem ? this.boothLineItem.displayName : this.boothData ? this.boothData.boothName : this.navParams.get('faName');
		this.liId = this.navParams.get('liId') || null;
		this.showAccountId = this.navParams.get('showAccountId') || this.dataSvc.showAccountActive;
		// this.keyboard.hideKeyboardAccessoryBar(false);
		this.userSvc.mentionsConfig.pipe(takeWhile(() => this.subscribe)).subscribe(mentionConfig => {
			this.mentionConfig = mentionConfig;
		});
		this.userSvc.opsMentionsConfig.pipe(takeWhile(() => this.subscribe)).subscribe(mentionConfig => {
			this.opsMentionConfig = mentionConfig;
		});
	}
  ngOnInit(): void {
    
  }

	dismiss() {
		// this.keyboard.hideKeyboardAccessoryBar(true);
		this.modalCtrl.dismiss();
	}

	postNote(note: string) {
		if (this.liId) {
			this.activitySvc.addNote(note, this.dataSvc.eventActive, this.showAccountId, this.faID, this.liId);
		} else if (this.wtId) {
			this.workTikActivitySvc.addNote(note, this.dataSvc.eventActive, this.wtId);
		} else if (this.boothLineItem) {
			this.boothService.addNote(note, this.dataSvc.eventActive, this.boothData, this.boothLineItem);
		} else if (this.boothData) {
			this.boothService.addNote(note, this.dataSvc.eventActive, this.boothData);
		} else {
			this.activitySvc.addNote(note, this.dataSvc.eventActive, this.dataSvc.showAccountActive, this.faID);
		}
		this.modalCtrl.dismiss();
	}

	ionViewDidLoad() {
		document
			.getElementsByTagName('ion-app')
			.item(0)
			.classList.add('modal-open');

	}

	ionViewWillLeave() {
		if (
			document
				.getElementsByTagName('ion-app')
				.item(0)
				.classList.contains('modal-open')
		)
			document
				.getElementsByTagName('ion-app')
				.item(0)
				.classList.remove('modal-open');

		this.subscribe = false;
	}

	setMentionConfig() {
		if (this.wtId) {
			return this.opsMentionConfig;
		} else {
			return this.mentionConfig;
		}
	}
}
