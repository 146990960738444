import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonAccordionGroup, ModalController } from '@ionic/angular';
import { StatusHelper } from 'src/app/helpers/status-helper';
import { EsBoothsService } from 'src/app/services/es-booths/es-booths.service';
import { ToastService } from 'src/app/services/toast/toast.service';

export enum SQFootFilterEnum {
  ALL = 'All',
  LESS_THAN_200 = 'Less than 200 SF',
  LESS_THAN_400 = 'Less than 400 SF',
  GREATER_THAN_400 = 'Greater than 400 SF'
}

@Component({
  selector: 'booth-filter',
  templateUrl: './booth-filter.component.html',
  styleUrls: ['./booth-filter.component.scss'],
})
export class BoothFilterComponent implements OnInit {
  @ViewChild(IonAccordionGroup, { static: true }) accordionGroup: IonAccordionGroup;
  @Input() eventId: any;

  selectedOption: any;
  boothStartRange: any;
  boothEndRange: any;
  featuresList: any = [];
  selectedBoothFeatures: any = [];
  allFeaturesSelected: any;
  selectedStatusIds: any = [];
  statusList: any = [];
  savingRange: any;
  squareFoot: string = '';

  constructor(
    private modalCtrl: ModalController,
    private toastService: ToastService,
    private esBoothService: EsBoothsService
  ) { }

  ngOnInit() {
    this.initializeFilterValues();
  }

  initializeFilterValues() {
    this.featuresList = [
      {
        title: 'Service Requests',
        id: 1,
        checked: false
      },
      {
        title: 'Orders',
        id: 2,
        checked: false
      },
      {
        title: 'Freight Orders',
        id: 3,
        checked: false
      },
      {
        title: 'MHAs',
        id: 4,
        checked: false
      }
    ];
    this.statusList = JSON.parse(JSON.stringify(StatusHelper.LineItemStatuses));
    this.loadFilters();
  }

  loadFilters() {
    this.boothEndRange = this.esBoothService.esBoothAppliedFilters.range.endRange || this.esBoothService.savedBoothRange.endRange;
    this.boothStartRange = this.esBoothService.esBoothAppliedFilters.range.startRange || this.esBoothService.savedBoothRange.startRange;
    this.esBoothService.esBoothAppliedFilters.status.map(statusId => {
      let status = this.statusList.find(e => e.id == statusId);
      if (status)
        status['checked'] = true;
    });
    this.esBoothService.esBoothAppliedFilters.features.map(featureId => {
      let feature = this.featuresList.find(e => e.id == featureId);
      if (feature)
        feature['checked'] = true;
    });
    this.squareFoot = this.esBoothService.esBoothAppliedFilters.squareFoot;
   // this.setSelectedStatus();
    this.setSelectedFeatures();
  }

  toggleAccordion(event) {
    this.selectedOption = this.accordionGroup.value;
  }

  toggleBoothFeature(feature) {
    feature.checked = !feature.checked;
    this.setSelectedFeatures();
  }

  setSelectedFeatures() {
    let selected = [];
    this.featuresList.map(x => x.checked ? selected.push(x.id) : null);
    this.selectedBoothFeatures = selected;
  }

  // setSelectedStatus() {
  //   let selected = [];
  //   this.statusList.map(x => x.checked ? selected.push(x.id) : null);
  //   this.selectedStatusIds = selected;
  // }

  // toggleBoothStatus(status) {
  //   status['checked'] = !status['checked'];
  //   this.setSelectedStatus();
  // }

  toggleAllFeatures() {
    setTimeout(() => {
      if (this.selectedBoothFeatures.length != this.featuresList.length) {
        this.featuresList.map(x => x.checked = true);
      } else {
        this.featuresList.map(x => x.checked = false);
      }
      this.setSelectedFeatures();
    }, 50);
  }

  saveRange() {
    this.savingRange = true;
    this.esBoothService.saveBoothRange(this.eventId, { StartValue: this.boothStartRange, EndValue: this.boothEndRange }).subscribe(res => {
      if (res) {
        this.savingRange = false;
        this.toastService.open('Booth Range Saved!', 'success');
      } else {
        this.toastService.open('There was an error while saving booth range', 'danger');
      }
    }, err => {
      this.toastService.open('There was an error while saving booth range', 'danger');
    });
  }

  applyFilters() {
    this.esBoothService.esBoothAppliedFilters = {
      range: {
        startRange: ((this.boothStartRange && this.boothStartRange.trim()) && (this.boothEndRange && this.boothEndRange.trim())) ? this.boothStartRange : "",
        endRange: ((this.boothStartRange && this.boothStartRange.trim()) && (this.boothEndRange && this.boothEndRange.trim())) ? this.boothEndRange : ""
      },
      features: this.selectedBoothFeatures,
      status: this.selectedStatusIds,
      squareFoot: this.squareFoot
    };
    let features = this.featuresList.filter(x => x.checked);
    let status = this.statusList.filter(x => x.checked);
    features.map(e => e['type'] = 'feature');
    status.map(e => e['type'] = 'status');
    let filters = features.concat(status);
    if (this.esBoothService.esBoothAppliedFilters.range.startRange) {
      filters.push({
        type: 'range',
        startRange: this.esBoothService.esBoothAppliedFilters.range.startRange,
        endRange: this.esBoothService.esBoothAppliedFilters.range.endRange
      });
      this.esBoothService.isRangeRemoved = false;
    }
    if(this.squareFoot != '')
    {
      filters.push({
        type: 'squareFoot',
        title: SQFootFilterEnum[this.squareFoot]
      });
    }
    this.esBoothService.appliedFiltersList = filters;
    this.modalCtrl.dismiss();
  }

  clearFilters() {
    this.esBoothService.isRangeRemoved = true;
    this.esBoothService.initializeFilters();
    this.initializeFilterValues();
    this.esBoothService.appliedFiltersList = [];
    this.squareFoot = '';
    this.modalCtrl.dismiss();
  }

  closeFilterModal() {
    this.modalCtrl.dismiss();
  }

}
