import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthTokenHelper } from 'src/app/helpers/auth-token-helper';
import { StatusHelper } from 'src/app/helpers/status-helper';
import { ActivityDTO } from 'src/app/models/activity-dto';
import { ActivityItem } from 'src/app/models/activity-item';
import { ActivityNote } from 'src/app/models/activity-note';
import { ActivityPhoto } from 'src/app/models/activity-photo';
import { CollaborationLineItemDTO } from 'src/app/models/collaboration-line-item-dto';
import { NoteUploadDTO } from 'src/app/models/note-upload-dto';
import { EsBoothDetailsItems } from 'src/app/models/es-booth-details-items';
import { ShowBooth } from 'src/app/models/show-booth-dto';
import { StatusChange } from 'src/app/models/status-change';
import { environment } from 'src/environments/environment';
import { ToastService } from '../toast/toast.service';
import { UserService } from '../user/user.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ImageUploadDTO } from 'src/app/models/image-upload-dto';

@Injectable({
  providedIn: 'root'
})
export class EsBoothsService {
  public activeBooth: ShowBooth;
  public activeBoothSubject = new BehaviorSubject<ShowBooth>(<ShowBooth>{});
  public esBooths = new BehaviorSubject<ShowBooth[]>([]);
  public esBoothItemsDto = new BehaviorSubject<EsBoothDetailsItems[]>([]);
  public boothListFilterClearSubject = new BehaviorSubject<boolean>(false);

  url = environment.apiUrl;
  public emptyFAGUID = "00000000-0000-0000-0000-000000000000";
  activityDTO: ActivityDTO;
  boothLIActivityItems = new BehaviorSubject<ActivityItem[]>([]);
  public notePostedScroll: Subject<boolean> = new Subject();
  public currentActivityItems: ActivityItem[];

  savedBoothRange: any = {
    startRange: '',
    endRange: ''
  };
  esBoothAppliedFilters: any;
  appliedFiltersList: any = [];
  appliedBoothListFilters: any = [];

  serviceRequestShowAccountId: any;
  isRangeRemoved: any;

  constructor(
    public http: HttpClient,
    public toastService: ToastService,
    public userSvc: UserService,
    public authHelper: AuthTokenHelper
  ) {
    this.boothLIActivityItems.subscribe(items => {
      this.currentActivityItems = items;
    });
    this.initializeFilters();
    this.initializeBoothListFilters();
  }

  initializeFilters() {
    this.esBoothAppliedFilters = {
      range: {
        startRange: "",
        endRange: ""
      },
      features: [],
      status: [],
      squareFoot: ''
    };
    this.savedBoothRange = {
      startRange: '',
      endRange: ''
    };
    this.appliedFiltersList = [];
  }

  initializeBoothListFilters() {
    this.appliedBoothListFilters = {
      statuses: [],
      orders: []
    };
  }

  getBoothsList(eventId: string) {
    this.http.get<Array<ShowBooth>>(`${this.url()}events/${eventId}/getBooths`).subscribe(boothsData => {
      if (boothsData) {
        boothsData.forEach(boothItem => {
          boothItem.statusId = boothItem.statusId ? boothItem.statusId : 1;
          boothItem.statusId = boothItem.statusId != null ? boothItem.statusId : 1;
          boothItem.statusDetails = StatusHelper.GetStatusFromId(boothItem.statusId)
        });
        this.getBoothRange(eventId).subscribe(range => {
          this.esBooths.next(_.uniqBy(boothsData, 'boothGUID'));
        }, err => {
          this.esBooths.next(_.uniqBy(boothsData, 'boothGUID'));
        })
      } else {
        this.esBooths.next([]);
      }
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error getting booths', 'danger');
      }
    });
  }

  getActivityData(eventId: string, boothGUID: string, lineItemId?: string, serviceRequestGUID?: any, showAccountGuid?: any) {

    // const showAccountGUID = this.activeBooth.showAccountGUID;
    let activityURL;
    if (serviceRequestGUID)
      activityURL = `${this.url()}events/${eventId}/accounts/${showAccountGuid}/functional-areas/${this.emptyFAGUID}` + `/issue-item/${serviceRequestGUID}/activity`;
    else
      activityURL = `${this.url()}events/${eventId}/accounts/${this.activeBooth.showAccountGUID}/functional-areas/${this.emptyFAGUID}` + (lineItemId ? `/line-items/${lineItemId}/activity` : `/booth/${boothGUID}/activity`);
    this.http.get<ActivityDTO>(activityURL).subscribe(data => {
      data.statusChanges.map(item => {
        if (serviceRequestGUID)
          item['status'] = StatusHelper.GetServiceRequestStatusFromId(item.statusId)
        else
          item['status'] = StatusHelper.GetStatusFromId(item.statusId)
      });

      this.activityDTO = data;
      this.addNotesToActivityItems();
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error refreshing the activity data', 'danger');
      }
    });
  }

  public addNotesToActivityItems(): void {
    const activityItems: ActivityItem[] = [];

    this.activityDTO.notes.forEach(note => {
      const styledNote = this.styleMentionedUsers(note);
      const ActivityItem: ActivityItem = {
        note: styledNote,
        createdDate: note.createdDate
      };
      activityItems.push(ActivityItem);
    });

    this.addPhotosToNotes(activityItems);
  }

  addPhotosToNotes(activityItems: ActivityItem[]): void {

    for (let i = 0; i < this.activityDTO.photos.length; i++) {
      const photo = this.activityDTO.photos[i];
      if (photo.noteGuid) {
        const noteIndex = activityItems.findIndex(
          n => n.note && n.note.kafkaId === photo.noteGuid
        );
        if (noteIndex !== -1) {
          activityItems[noteIndex].photo = photo;
          this.activityDTO.photos.splice(i, 1);
          i--;
        } else {
          const ActivityItem: ActivityItem = {
            photo,
            createdDate: photo.createdDate
          };
          activityItems.push(ActivityItem);
        }
      } else {
        // if the photo is not associated with a note, it must be a stand alone photo
        // So we'll just create its own ActivityItem for it.
        const ActivityItem: ActivityItem = {
          photo,
          createdDate: photo.createdDate
        };
        activityItems.push(ActivityItem);
      }
    }
    this.addStatusToNotes(activityItems);
  }

  addStatusToNotes(activityItems: ActivityItem[]): void {

    for (let i = 0; i < this.activityDTO.statusChanges.length; i++) {
      const status = this.activityDTO.statusChanges[i];
      if (status.noteGuid) {
        const noteIndex = activityItems.findIndex(
          n => n.note && n.note.kafkaId === status.noteGuid
        );
        if (noteIndex !== -1) {
          // if there is a note associated with this status change, we add the status change to the activity item
          // that contains the note.
          activityItems[noteIndex].statusChange = status;
          // since we added the status change already (in the note's ActivityItem) we need to remove it from the original array of status changes
          this.activityDTO.statusChanges.splice(i, 1);
          i--;
        } else {
          const ActivityItem: ActivityItem = {
            statusChange: status,
            createdDate: status.createdDate
          };
          activityItems.push(ActivityItem);
        }
      } else {
        const ActivityItem: ActivityItem = {
          statusChange: status,
          createdDate: status.createdDate
        };
        activityItems.push(ActivityItem);
      }
    }
    this.sortActivityItems(activityItems);
  }

  public sortActivityItems(activityItems: ActivityItem[]): void {
    activityItems.sort((a, b) => {
      return moment.utc(a.createdDate).diff(b.createdDate);
    });
    this.boothLIActivityItems.next(activityItems);
  }

  addPhoto(eventId: string, boothGuid: string, boothLineItem: CollaborationLineItemDTO, imageString: string, optionalNote: string, serviceRequestGUID?: any, ) {
    const usersToNotify = optionalNote ? this.userSvc.verifyMentionedUsers(optionalNote) : [];

    const imageUploadDTO: ImageUploadDTO = {
      image: imageString,
      note: optionalNote ? optionalNote : ""
    };

    if (boothLineItem || serviceRequestGUID) {
      let url;
      if (boothLineItem)
        url = `${this.url()}events/${eventId}/accounts/${this.activeBooth.showAccountGUID}/functional-areas/${this.emptyFAGUID}/line-items/${boothLineItem.kafkaId}/photos`;
      if (serviceRequestGUID)
        url = `${this.url()}events/${eventId}/accounts/${this.serviceRequestShowAccountId}/issue-item/${serviceRequestGUID}/photos`
      this.http.post<any>(url, imageUploadDTO).subscribe(
        data => {
          this.toastService.open('Photo added! It may take a moment to process your photo.', 'success');
          let noteAdded: ActivityNote = null;
          if (optionalNote) {
            noteAdded = {
              kafkaId: data.noteGuid,
              body: optionalNote,
              createdBy: data.createdBy,
              createdByGuid: data.createdByGuid,
              createdDate: data.createdDate,
              functionalAreaGuid: data.functionalAreaGuid,
              lineItemGuid: data.lineItemGuid
            };
          }
          this.addItemToActivityItems(data.createdDate, noteAdded, data, null, true);
        },
        err => {
          switch (err.status) {
            case 0: {
              this.toastService.open('Error 413: Photo is too large.', 'danger');
              break;
            }
            case 400: {
              // Bad model
              this.toastService.open('Error 400: Invalid photo format.', 'danger');
              break;
            }
            case 401:
            case 403: {
              // Unauthorized
              break;
            }
            default: {
              // Generic
              this.toastService.open('Error 500: Photo failed to post. Check your network connection.', 'danger');
              break;
            }
          }
        }
      );
    } else {
      this.http.post<any>(`${this.url()}events/${eventId}/accounts/${this.activeBooth.showAccountGUID}/booth/${this.activeBooth.boothGUID}/photos`, imageUploadDTO)
        .subscribe(data => {
          this.toastService.open('Photo added! It may take a moment to process your photo.', 'success');
          let noteAdded: ActivityNote = null;
          if (optionalNote) {
            noteAdded = {
              kafkaId: data.noteGuid,
              body: optionalNote,
              createdBy: data.createdBy,
              createdByGuid: data.createdByGuid,
              createdDate: data.createdDate,
              functionalAreaGuid: data.functionalAreaGuid,
              lineItemGuid: data.lineItemGuid
            };
          }
          this.addItemToActivityItems(data.createdDate, noteAdded, data, null, false);
        }, error => {
          switch (error.status) {
            case 0: {
              this.toastService.open('Error 413: Photo is too large.', 'danger');
              break;
            }
            case 400: {
              // Bad model
              this.toastService.open('Error 400: Invalid photo format.', 'danger');
              break;
            }
            case 401:
            case 403: {
              // Unauthorized
              break;
            }
            default: {
              // Generic
              this.toastService.open('Error 500: Photo failed to post. Check your network connection.', 'danger');
              break;
            }
          }
        });
    }
  }

  addNote(noteBody: string, eventId: string, boothData: ShowBooth, boothLineItem?: CollaborationLineItemDTO, cb?: any, serviceRequestGUID?: any) {
    const usersToNotify = this.userSvc.verifyMentionedUsers(noteBody);
    const newNote: NoteUploadDTO = {
      body: noteBody,
      usersToNotify: usersToNotify,
    };

    let url = `${this.url()}events/${eventId}/accounts/${boothData.showAccountGUID}/functional-areas/${this.emptyFAGUID}/booth/${boothData.boothGUID}/notes`;

    if (boothLineItem)
      url = `${this.url()}events/${eventId}/accounts/${boothData.showAccountGUID}/functional-areas/${this.emptyFAGUID}/line-items/${boothLineItem.kafkaId}/notes`;
    else if (serviceRequestGUID)
      url = `${this.url()}events/${eventId}/accounts/${boothData.showAccountGUID}/functional-areas/${this.emptyFAGUID}/issue-item/${serviceRequestGUID}/notes`;

    if (boothLineItem || serviceRequestGUID) {
      this.http.post<any>(url, newNote).subscribe(
        data => {
          this.toastService.open('Success! Note added.', 'success');
          this.addItemToActivityItems(data.createdDate, data, null, null, true);
          if (cb) cb(true);
        },
        err => {
          switch (err.status) {
            case 400: {
              // Bad model
              this.toastService.open('Error 400: Invalid note format.', 'danger');
              break;
            }
            case 401:
            case 403: {
              // Unauthorized
              break;
            }
            default: {
              // Generic
              this.toastService.open('Error 500: Note failed to post. Check your network connection.', 'danger');
              break;
            }
          }
        }
      );
    } else {
      this.http.post<any>(`${this.url()}events/${eventId}/accounts/${boothData.showAccountGUID}/functional-areas/${this.emptyFAGUID}/booth/${boothData.boothGUID}/notes`, newNote)
        .subscribe(data => {
          this.addItemToActivityItems(data.createdDate, data, null, null, false);
          this.toastService.open('Success! Note added...', 'success');
          if (cb) cb(true);
        }, error => {
          switch (error.status) {
            case 400:
              this.toastService.open('400 error: Either Booth Number do not match, or model state is invalid.', 'danger');
              break;
            case 404:
              this.toastService.open(`No booth with id: ${boothData.boothGUID}`, 'danger');
              break;
            default:
              this.toastService.open('Unable to add note for booth, unkown error.', 'danger');
              break;
          }
        });
    }
  }

  addItemToActivityItems(createdDate: Date, note?: ActivityNote, photo?: ActivityPhoto, statusChange?: StatusChange, isLineItem = false, spliceIndex?: number) {
    const activityItem: ActivityItem = {
      note: this.styleMentionedUsers(note),
      photo: photo,
      statusChange: statusChange,
      createdDate: createdDate
    };
    this.currentActivityItems.push(activityItem);
    this.notePostedScroll.next(true);
    this.boothLIActivityItems.next(this.currentActivityItems);
  }

  public styleMentionedUsers(note: ActivityNote) {
    if (!note) return;
    const styledNote = note;
    let note_original = JSON.parse(JSON.stringify(note));
    if (styledNote.body.includes('@')) {
      styledNote['isUserMentionedNote'] = true;
    }
    this.userSvc.currentShowAccountUsers.forEach(user => {
      if (note.body.includes('@') && note_original.body.includes('@' + user.displayName)) {
        styledNote['mentionedUser'] = '@' + user.displayName;
        styledNote['comment'] = note_original.body.split('@' + user.displayName)[1].trim();
      }
      user.kafkaId === this.authHelper.currentUserGuid.getValue()
        ? styledNote.body = note.body.replace('@' + user.displayName, '<span class="mentioned-current-user">@' + user.displayName + '</span>')
        : styledNote.body = note.body.replace('@' + user.displayName, '<span class="mentioned-user">@' + user.displayName + '</span>');
    });
    this.userSvc.currentShowUsers.forEach(user => {
      if (note.body.includes('@') && note_original.body.includes('@' + user.displayName)) {
        styledNote['mentionedUser'] = '@' + user.displayName;
        styledNote['comment'] = note_original.body.split('@' + user.displayName)[1].trim();
      }
      user.kafkaId === this.authHelper.currentUserGuid.getValue()
        ? styledNote.body = note.body.replace('@' + user.displayName, '<span class="mentioned-current-user">@' + user.displayName + '</span>')
        : styledNote.body = note.body.replace('@' + user.displayName, '<span class="mentioned-user">@' + user.displayName + '</span>');
    });
    return styledNote;
  }

  getBoothDetailsList(eventId: string, boothGuid: string, boothItemType?: number) {
    const btItemType = boothItemType == undefined ? 0 : boothItemType;
    this.http.get<Array<EsBoothDetailsItems>>(`${this.url()}events/${eventId}/booth/${boothGuid}/${btItemType}/booth-data`).subscribe(boothDetailsData => {
      if (boothDetailsData) {
        boothDetailsData.forEach(boothDetailsItem => {
          boothDetailsItem.currentStatus = boothDetailsItem.currentStatus != null ? boothDetailsItem.currentStatus : 1;
          boothDetailsItem.lineItemStatusId = boothDetailsItem.lineItemStatusId ? boothDetailsItem.lineItemStatusId : 1;
          if (!this.authHelper.isExhibitUser) {
            boothDetailsItem.status = StatusHelper.GetStatusFromId(boothDetailsItem.currentStatus);
          } else {
            boothDetailsItem.status = StatusHelper.ExhibitGetStatusFromId(boothDetailsItem.currentStatus);
          }
        });
        this.esBoothItemsDto.next(boothDetailsData);
      } else {
        this.esBoothItemsDto.next([]);
      }
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error getting booth details', 'danger');
      }
    });
  }

  getESBoothDetail(eventId: string, boothGUID: string) {

    this.http.get<Array<ShowBooth>>(`${this.url()}events/${eventId}/getExhibitorBooth/${boothGUID}`).subscribe(boothData => {
      if (boothData && boothData.length) {

        this.activeBooth = boothData[0];
        this.activeBoothSubject.next(boothData[0]);
      } else {
        this.activeBooth = <ShowBooth>{};
        this.activeBoothSubject.next(<ShowBooth>{})
      }
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error getting booth details', 'danger');
      }
    });
  }

  getServiceRequestDetails(eventId: string, serviceRequestGUID: string) {
    return this.http.get<any>(`${this.url()}events/${eventId}/exhibitorService/issue-items/${serviceRequestGUID}`).pipe(map(serviceRequestData => {
      if (serviceRequestData && serviceRequestData.length) {
        return serviceRequestData[0];
      } else {
        return null;
      }
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error getting Service Request details', 'danger');
      }
    }));
  }

  fetchActivityPhotos(): ActivityPhoto[] {
    const photos: ActivityPhoto[] = [];
    this.currentActivityItems.forEach(item => {
      if (item.photo) {
        photos.push(item.photo);
      }
    });
    return photos;
  }

  getBoothRange(eventId) {
    return this.http.get<any>(`${this.url()}events/${eventId}/exhibitorService/booth-range`).pipe(map(range => {
      if (range) {
        this.savedBoothRange = {
          startRange: range.startValue,
          endRange: range.endValue
        };
      }
      return range;
    })
    );
  }

  saveBoothRange(eventId, range) {
    return this.http.post<any>(`${this.url()}events/${eventId}/exhibitorService/booth-range/save`, range).pipe(map(range => {
      this.getBoothRange(eventId).subscribe(res => { });
      return range;
    })
    );
  }


  unSubscribeNotifiction(token: string) {
    return this.http.put<any>(`${this.url()}users/es/${token}/unsubscribe`, null)
      .pipe(map(data => {
        if (data.status === false) {
          this.toastService.open(data.message, 'danger');
          return false;
        } else if (data.status === true) {
          //this.toastService.open(data.message, 'success');
          return true;
        }
      }, err => {
        if (err.status !== 401 && err.status !== 403) {
          this.toastService.open('Please try after sometime', 'danger');
        }
      }));
  }

  getServiceRequestList(showId) {
    let url = `${this.url()}events/${showId}/exhibitorService/issue-items?MHAserviceRequest=true`;
    return this.http.get<any[]>(url)
      .pipe(map(data => {
        data.map(item => {
          item['status'] = StatusHelper.GetServiceRequestStatusFromId(item.currentStatus);
          item['kafkaId'] = item?.issueItemGuid;
          item['booth'] = item?.boothNumber;
          item['description'] = item?.issueText;
          item['accountName'] = item?.requestor;
          item['setupDate'] = item?.submittedDate;
        })
        return data;
      }, err => {
        if (err.status !== 401 && err.status !== 403) {
          this.toastService.open('There was an error while fetching service requests', 'danger');
        }
      }));
  }
}
