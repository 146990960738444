import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ShowBooth } from '../../models/show-booth-dto';
import { ToastType } from '../../models/toast-type';
import { ToastService } from '../toast/toast.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { GenericResponse } from '../../models/generic-response';
import { UserService } from '../user/user.service';
import { CollaborationLineItemDTO } from '../../models/collaboration-line-item-dto';
import { ActivityNote } from '../../models/activity-note';
import { ImageUploadDTO } from '../../models/image-upload-dto';
import { NoteUploadDTO } from '../../models/note-upload-dto';
import { ActivityItem } from '../../models/activity-item';
import { ActivityDTO } from '../../models/activity-dto';
import { AuthTokenHelper } from '../../helpers/auth-token-helper';
import { StatusHelper } from '../../helpers/status-helper';
import { ActivityPhoto } from '../../models/activity-photo';
import { StatusChange } from '../../models/status-change';
import { Attachment } from '../../models/attachment';
import { FileType } from '../../models/file-type';
import { FileTypeHelper } from '../../helpers/file-type-helper';
import { TimelineDTO } from '../../models/timeline-dto';
import { ChangeOrderDTO } from '../../models/change-order-dto';
import { EsBoothDetailsItems } from 'src/app/models/es-booth-details-items';

/*
  Generated class for the BoothServiceProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: 'root'
})
export class BoothServiceProvider {

  public showBooths = new BehaviorSubject<ShowBooth[]>([]);
  public activeBooth = new BehaviorSubject<ShowBooth>(null);
  public activeBoothLineItem = new BehaviorSubject<CollaborationLineItemDTO>(null);
  public activeBoothGuid: string;
  public allBoothLineItems = new BehaviorSubject<CollaborationLineItemDTO[]>([]);
  public currentBoothLineItems = new BehaviorSubject<CollaborationLineItemDTO[]>([]);
  public allServiceRequestItems = new BehaviorSubject<any[]>([]);
  public currentBoothServiceRequests = new BehaviorSubject<any[]>([]);

  url = environment.apiUrl;
  public emptyFAGUID = "00000000-0000-0000-0000-000000000000";
  boothLIActivityItems = new BehaviorSubject<ActivityItem[]>([]);
  public currentActivityItems: ActivityItem[];
  activityDTO: ActivityDTO;
  public notePostedScroll: Subject<boolean> = new Subject();
  public boothLIAttachments = new BehaviorSubject<Attachment[]>([]);
  public boothSearchData = new BehaviorSubject<CollaborationLineItemDTO[]>([]);
  public filterSelections: any[] = [];
  public filterSelectionCount: number = 0;

  currentTimelineSubject = new BehaviorSubject<TimelineDTO[]>(null);
  currentTimelineNotificationSubject = new BehaviorSubject<TimelineDTO[]>(null);
  public boothChangeLogData = new BehaviorSubject<ChangeOrderDTO[]>([]);
  public esBoothLiStatusSubject = new BehaviorSubject<any>(null);
  public esServiceRequestStatusSubject = new BehaviorSubject<any>(null);
  refreshInterval: any;

  constructor(
    public http: HttpClient,
    public toastService: ToastService,
    public userSvc: UserService,
    public authHelper: AuthTokenHelper,
  ) {
    this.boothLIActivityItems.subscribe(items => {
      this.currentActivityItems = items;
    });
  }

  getBoothList(eventId: string, boothGUID?: string, isFromBoothDetails?: boolean) {
    this.http.get<Array<ShowBooth>>(`${this.url()}events/${eventId}/booths`)
      .subscribe(data => {
        let sortedBooth = _.orderBy(data, ['isFavorite', 'showAccountName', 'boothName'], ['desc', 'asc', 'asc']);
        if(!isFromBoothDetails){
          this.getBoothLineItemList(eventId);
          if(boothGUID){
            this.getBoothServiceRequestsList(eventId, boothGUID);
          } else{
            this.getServiceRequestList(eventId).subscribe();
          }
        }
        this.showBooths.next(sortedBooth);
        if (boothGUID) {
          let currentBooth = _.find(sortedBooth, ['boothGUID', boothGUID]);
          this.activeBooth.next(currentBooth ? currentBooth : null);
          this.activeBoothGuid = currentBooth ? currentBooth.boothGUID : "";
        }
      }, err => {
        if (err.status !== 401 && err.status !== 403) {
          this.toastService.open('There was an error refreshing the booths data', 'danger');
        }
      });
  }

  getBoothLineItemList(eventId: string, boothGuid?: string) {
    boothGuid = boothGuid ? boothGuid : "00000000-0000-0000-0000-000000000000";
    this.http.get<Array<any>>(`${this.url()}events/${eventId}/booths/${boothGuid}/getLineItemsForBooth`)
      .subscribe(data => {
        // Removed Cancelled lineitems.
        data = _.filter(data, function (liData) {
          return (liData.lineItemStatusId !== 6 && liData.lineItemStatusId !== 7);
        });

        data = _.orderBy(data, ['displayName'], ['asc']);
        if (boothGuid == "00000000-0000-0000-0000-000000000000") {
          this.allBoothLineItems.next(data);
        } else {
          this.currentBoothLineItems.next(data);
        }
      }, err => {
        if (err.status !== 401 && err.status !== 403) {
          this.toastService.open('There was an error getting line items for booth', 'danger');
        }
      });
  }

  setFavoriteBooth(eventId: string, boothGuid: string): Observable<boolean> {
    return this.http.put<GenericResponse>(`${this.url()}events/${eventId}/booths/${boothGuid}/favorite`, null)
      .pipe(map(data => {
        if (data.status === 'error') {
          this.toastService.open(data.message, 'danger');
          return false;
        } else if (data.status === 'success') {
          this.toastService.open(data.message, 'success');
          return true;
        }
      }, err => {
        if (err.status !== 401 && err.status !== 403) {
          this.toastService.open('There was an error while adding booth to favorite ', 'danger');
        }
      }));
  }

  setBoothStatus(eventId: string, boothGUID: string, boothLineItem: CollaborationLineItemDTO, statusId: number, noteBody?: string, photoUri?: string
    , isFromWarningPopUp?: boolean) {
    const usersToNotify = noteBody ? this.userSvc.verifyMentionedUsers(noteBody) : [];
    const uploadDto: any = {
      usersToNotify: usersToNotify
    };

    const currentBoothLI = this.currentBoothLineItems.getValue();
    boothLineItem ? uploadDto.lineItemStatus = statusId : uploadDto.boothStatus = statusId
    noteBody ? uploadDto.noteBody = noteBody : null;
    photoUri ? uploadDto.photoUri = photoUri : null;

    if (isFromWarningPopUp) {
      currentBoothLI[0] ? uploadDto.lineItemGuid = currentBoothLI[0].kafkaId : uploadDto.lineItemGuid;
    }

    if (boothLineItem) {
      this.http.put<any>(`${this.url()}events/${eventId}/accounts/${boothLineItem.showAccountGuid}/functional-areas/${this.emptyFAGUID}/line-items/${boothLineItem.kafkaId}/booth/${boothGUID}`, uploadDto)
        .subscribe(data => {
          if(this.authHelper.isExhibitUser){
            this.esBoothLiStatusSubject.next(statusId);
            return;
          }
          
          let boothData = this.showBooths.getValue();
          boothData.forEach(booth => {
            if (booth.boothGUID == boothGUID) {
              if (boothLineItem && booth.boothLIData) {
                booth.boothLIData.forEach(liData => {
                  if (boothLineItem.kafkaId == liData.kafkaId)
                    liData.currentStatus = statusId
                });
              }
            }
          });
          this.showBooths.next(boothData);

          let selectedBoothLIs = this.currentBoothLineItems.getValue();
          selectedBoothLIs.forEach(liData => {
            if (boothLineItem.kafkaId == liData.kafkaId)
              liData.currentStatus = statusId
          });
          this.currentBoothLineItems.next(selectedBoothLIs);
          data.statusChange.status = StatusHelper.GetStatusFromId(data.statusChange.statusId);
          this.addItemToActivityItems(data.statusChange.createdDate, data.note, data.photo, data.statusChange, true);

          let boothLIData = this.activeBoothLineItem.getValue();
          if (boothLIData) {
            boothLIData.currentStatus = statusId;
            boothLIData.status = data.statusChange.status;
            this.activeBoothLineItem.next(boothLIData);
          }
        }, error => {
          switch (error.status) {
            case 400:
              this.toastService.open('400 error: Either Booth Number do not match, or model state is invalid.', 'danger');
              break;
            case 404:
              this.toastService.open(`No booth with id: ${boothGUID}`, 'danger');
              break;
            case 401:
            case 403: {
              // Unauthorized
              break;
            }
            default:
              this.toastService.open('Unable to update, unkown error.', 'danger');
              break;
          }
        });
    } 
    // else {
    //   this.http.put<any>(`${this.url()}events/${eventId}/booths/${boothGUID}/statusUpdate`, uploadDto)
    //     .subscribe(data => {
    //       let boothData = this.showBooths.getValue();
    //       boothData.forEach(booth => {
    //         if (booth.boothGUID == boothGUID) {
    //           booth.statusId = statusId;
    //         }
    //       });
    //       this.showBooths.next(boothData);
    //       data.statusChange.status = StatusHelper.GetStatusFromId(data.statusChange.statusId);
    //       this.addItemToActivityItems(data.statusChange.createdDate, data.note, data.photo, data.statusChange, false);
    //       if (isFromWarningPopUp && (statusId === StatusHelper.Status.NeedsAttention.id || statusId === StatusHelper.Status.ActionRequired.id)) {
    //         const lineItem = currentBoothLI[0];
    //         if (currentBoothLI.length === 1) {
    //           lineItem.status = StatusHelper.GetStatusFromId(statusId);
    //           uploadDto.lineItemGuid = lineItem.kafkaId;
    //           const liIndex = currentBoothLI.findIndex(li => li.kafkaId === lineItem.kafkaId);
    //           currentBoothLI.splice(liIndex, 1, lineItem);
    //           this.currentBoothLineItems.next(currentBoothLI);
    //         }
    //       } else {
    //         const updatedLineItems = StatusHelper.UpdateLineItemForBoothStatuses(StatusHelper.GetStatusFromId(statusId), currentBoothLI);
    //         this.currentBoothLineItems.next(updatedLineItems);
    //       }
    //     }, error => {
    //       switch (error.status) {
    //         case 400:
    //           this.toastService.open('400 error: Either Booth Number do not match, or model state is invalid.', 'danger');
    //           break;
    //         case 404:
    //           this.toastService.open(`No booth with id: ${boothGUID}`, 'danger');
    //           break;
    //         case 401:
    //         case 403: {
    //           // Unauthorized
    //           break;
    //         }
    //         default:
    //           this.toastService.open('Unable to update, unkown error.', 'danger');
    //           break;
    //       }
    //     });
    // }
  }

  setServiceRequestStatus(eventId: string, serviceRequest: any, statusId: number, noteBody?: string, photoUri?: string) {
      const uploadDto: any = {};
      serviceRequest ? uploadDto.status = statusId : uploadDto.boothStatus = statusId
      noteBody ? uploadDto.note = noteBody : null;
      photoUri ? uploadDto.photoUri = photoUri : null;
      uploadDto.issueItemGuid = serviceRequest.kafkaId ? serviceRequest.kafkaId : serviceRequest.issueItemGuid;
  
      if (serviceRequest) {
        this.http.post<any>(`${this.url()}events/${eventId}/exhibitorService/serviceRequest/update/Status`, uploadDto)
          .subscribe(data => {
              if(statusId == StatusHelper.Status.OnHold.id){
                this.esServiceRequestStatusSubject.next({ "serviceRequestGuid": serviceRequest.kafkaId ? serviceRequest.kafkaId : serviceRequest.issueItemGuid, "statusId": statusId, "commentsCount": serviceRequest.commentsCount + 1 });
              } else {
                this.esServiceRequestStatusSubject.next({ "serviceRequestGuid": serviceRequest.kafkaId ? serviceRequest.kafkaId : serviceRequest.issueItemGuid, "statusId": statusId, "commentsCount": serviceRequest.commentsCount });
              }

              //Added for regreshing updated status data on list page while upading status from details page.
              let selectedBoothServiceRequest = this.currentBoothServiceRequests.getValue();
              if(selectedBoothServiceRequest?.length>0){
                selectedBoothServiceRequest.forEach(srData => {
                  if (serviceRequest.issueItemGuid == srData.kafkaId)
                    srData.currentStatus = statusId
                });
                this.currentBoothServiceRequests.next(selectedBoothServiceRequest);
              }
          });
      } 
  }

  addPhoto(eventId: string, boothGuid: string, boothLineItem: CollaborationLineItemDTO, imageString: string, optionalNote: string) {

    const allBooths = this.showBooths.getValue();
    const boothData = allBooths.find(boothData => boothData.boothGUID == boothGuid);

    const usersToNotify = optionalNote ? this.userSvc.verifyMentionedUsers(optionalNote) : [];

    const imageUploadDTO: ImageUploadDTO = {
      image: imageString,
      note: optionalNote ? optionalNote : ""
    };

    if (boothLineItem) {
      this.http.post<any>(`${this.url()}events/${eventId}/accounts/${boothData.showAccountGUID}/functional-areas/${this.emptyFAGUID}/line-items/${boothLineItem.kafkaId}/photos`, imageUploadDTO).subscribe(
        data => {
          this.toastService.open('Photo added! It may take a moment to process your photo.', 'success');
          let noteAdded: ActivityNote = null;
          if (optionalNote) {
            noteAdded = {
              kafkaId: data.noteGuid,
              body: optionalNote,
              createdBy: data.createdBy,
              createdByGuid: data.createdByGuid,
              createdDate: data.createdDate,
              functionalAreaGuid: data.functionalAreaGuid,
              lineItemGuid: data.lineItemGuid
            };
          }
          this.addItemToActivityItems(data.createdDate, noteAdded, data, null, true);
        },
        err => {
          switch (err.status) {
            case 0: {
              this.toastService.open('Error 413: Photo is too large.', 'danger');
              break;
            }
            case 400: {
              // Bad model
              this.toastService.open('Error 400: Invalid photo format.', 'danger');
              break;
            }
            case 401:
            case 403: {
              // Unauthorized
              break;
            }
            default: {
              // Generic
              this.toastService.open('Error 500: Photo failed to post. Check your network connection.', 'danger');
              break;
            }
          }
        }
      );
    } else {
      this.http.post<any>(`${this.url()}events/${eventId}/accounts/${boothData.showAccountGUID}/booth/${boothData.boothGUID}/photos`, imageUploadDTO)
        .subscribe(data => {
          this.toastService.open('Photo added! It may take a moment to process your photo.', 'success');
          let noteAdded: ActivityNote = null;
          if (optionalNote) {
            noteAdded = {
              kafkaId: data.noteGuid,
              body: optionalNote,
              createdBy: data.createdBy,
              createdByGuid: data.createdByGuid,
              createdDate: data.createdDate,
              functionalAreaGuid: data.functionalAreaGuid,
              lineItemGuid: data.lineItemGuid
            };
          }
          this.addItemToActivityItems(data.createdDate, noteAdded, data, null, false);
        }, error => {
          switch (error.status) {
            case 0: {
              this.toastService.open('Error 413: Photo is too large.', 'danger');
              break;
            }
            case 400: {
              // Bad model
              this.toastService.open('Error 400: Invalid photo format.', 'danger');
              break;
            }
            case 401:
            case 403: {
              // Unauthorized
              break;
            }
            default: {
              // Generic
              this.toastService.open('Error 500: Photo failed to post. Check your network connection.', 'danger');
              break;
            }
          }
        });
    }
  }

  addNote(noteBody: string, eventId: string, boothData: ShowBooth, boothLineItem?: CollaborationLineItemDTO, cb?: any) {
    const usersToNotify = this.userSvc.verifyMentionedUsers(noteBody);
    const newNote: NoteUploadDTO = {
      body: noteBody,
      usersToNotify: usersToNotify,
    };


    if (boothLineItem) {
      this.http.post<any>(`${this.url()}events/${eventId}/accounts/${boothData.showAccountGUID}/functional-areas/${this.emptyFAGUID}/line-items/${boothLineItem.kafkaId}/notes`, newNote).subscribe(
        data => {
          this.toastService.open('Success! Note added.', 'success');
          this.addItemToActivityItems(data.createdDate, data, null, null, true);
          if (cb) cb(true);
        },
        err => {
          switch (err.status) {
            case 400: {
              // Bad model
              this.toastService.open('Error 400: Invalid note format.', 'danger');
              break;
            }
            case 401:
            case 403: {
              // Unauthorized
              break;
            }
            default: {
              // Generic
              this.toastService.open('Error 500: Note failed to post. Check your network connection.', 'danger');
              break;
            }
          }
        }
      );
    } else {
      this.http.post<any>(`${this.url()}events/${eventId}/accounts/${boothData.showAccountGUID}/functional-areas/${this.emptyFAGUID}/booth/${boothData.boothGUID}/notes`, newNote)
        .subscribe(data => {
          this.addItemToActivityItems(data.createdDate, data, null, null, false);
          this.toastService.open('Success! Note added...', 'success');
          if (cb) cb(true);
        }, error => {
          switch (error.status) {
            case 400:
              this.toastService.open('400 error: Either Booth Number do not match, or model state is invalid.', 'danger');
              break;
            case 404:
              this.toastService.open(`No booth with id: ${boothData.boothGUID}`, 'danger');
              break;
            default:
              this.toastService.open('Unable to add note for booth, unkown error.', 'danger');
              break;
          }
        });
    }
  }

  getActivityData(eventId: string, boothGUID: string, lineItemId?: string) {
    const showAccountGUID = this.activeBooth.getValue().showAccountGUID;
    const activityURL = `${this.url()}events/${eventId}/accounts/${showAccountGUID}/functional-areas/${this.emptyFAGUID}` + (lineItemId ? `/line-items/${lineItemId}/activity` : `/booth/${boothGUID}/activity`);
    this.http.get<ActivityDTO>(activityURL).subscribe(data => {
      this.activityDTO = data;
      this.addNotesToActivityItems();
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error refreshing the activity data', 'danger');
      }
    });
  }

  public addNotesToActivityItems(): void {
    const activityItems: ActivityItem[] = [];

    this.activityDTO.notes.forEach(note => {
      const styledNote = this.styleMentionedUsers(note);
      const ActivityItem: ActivityItem = {
        note: styledNote,
        createdDate: note.createdDate
      };
      activityItems.push(ActivityItem);
    });

    this.addPhotosToNotes(activityItems);
  }

  addPhotosToNotes(activityItems: ActivityItem[]): void {

    for (let i = 0; i < this.activityDTO.photos.length; i++) {
      const photo = this.activityDTO.photos[i];
      if (photo.noteGuid) {
        const noteIndex = activityItems.findIndex(
          n => n.note && n.note.kafkaId === photo.noteGuid
        );
        if (noteIndex !== -1) {
          activityItems[noteIndex].photo = photo;
          this.activityDTO.photos.splice(i, 1);
          i--;
        } else {
          const ActivityItem: ActivityItem = {
            photo,
            createdDate: photo.createdDate
          };
          activityItems.push(ActivityItem);
        }
      } else {
        // if the photo is not associated with a note, it must be a stand alone photo
        // So we'll just create its own ActivityItem for it.
        const ActivityItem: ActivityItem = {
          photo,
          createdDate: photo.createdDate
        };
        activityItems.push(ActivityItem);
      }
    }
    this.addStatusToNotes(activityItems);
  }

  addStatusToNotes(activityItems: ActivityItem[]): void {
   if(this.activityDTO.statusChanges != null)
   {
    for (let i = 0; i < this.activityDTO.statusChanges.length; i++) {
      const status = this.activityDTO.statusChanges[i];
      status.status = StatusHelper.GetStatusFromId(status.statusId);
      if (status.noteGuid) {
        const noteIndex = activityItems.findIndex(
          n => n.note && n.note.kafkaId === status.noteGuid
        );
        if (noteIndex !== -1) {
          // if there is a note associated with this status change, we add the status change to the activity item
          // that contains the note.
          activityItems[noteIndex].statusChange = status;
          // since we added the status change already (in the note's ActivityItem) we need to remove it from the original array of status changes
          this.activityDTO.statusChanges.splice(i, 1);
          i--;
        } else {
          const ActivityItem: ActivityItem = {
            statusChange: status,
            createdDate: status.createdDate
          };
          activityItems.push(ActivityItem);
        }
      } else {
        const ActivityItem: ActivityItem = {
          statusChange: status,
          createdDate: status.createdDate
        };
        activityItems.push(ActivityItem);
      }
    }
  }
    this.sortActivityItems(activityItems);
  }

  public sortActivityItems(activityItems: ActivityItem[]): void {
    activityItems.sort((a, b) => {
      return moment.utc(a.createdDate).diff(b.createdDate);
    });
    this.boothLIActivityItems.next(activityItems);
  }

  public styleMentionedUsers(note: ActivityNote) {
    if (!note) return;
    const styledNote = note;
    this.userSvc.currentShowAccountUsers.forEach(user => {
      user.kafkaId === this.authHelper.currentUserGuid.getValue()
        ? styledNote.body = note.body.replace('@' + user.displayName, '<span class="mentioned-current-user">@' + user.displayName + '</span>')
        : styledNote.body = note.body.replace('@' + user.displayName, '<span class="mentioned-user">@' + user.displayName + '</span>');
    });
    this.userSvc.currentShowUsers.forEach(user => {
      user.kafkaId === this.authHelper.currentUserGuid.getValue()
        ? styledNote.body = note.body.replace('@' + user.displayName, '<span class="mentioned-current-user">@' + user.displayName + '</span>')
        : styledNote.body = note.body.replace('@' + user.displayName, '<span class="mentioned-user">@' + user.displayName + '</span>');
    });
    return styledNote;
  }

  addItemToActivityItems(createdDate: Date, note?: ActivityNote, photo?: ActivityPhoto, statusChange?: StatusChange, isLineItem = false, spliceIndex?: number) {
    const activityItem: ActivityItem = {
      note: this.styleMentionedUsers(note),
      photo: photo,
      statusChange: statusChange,
      createdDate: createdDate
    };
    this.currentActivityItems.push(activityItem);
    this.notePostedScroll.next(true);
    this.boothLIActivityItems.next(this.currentActivityItems);
  }

  getBoothLiDetails(eventId: string, accountId: string, lineItemId: string): Observable<CollaborationLineItemDTO> {
    
    return this.http.get<CollaborationLineItemDTO>(`${this.url()}events/${eventId}/accounts/${accountId}/functional-areas/${this.emptyFAGUID}/line-items/${lineItemId}`)
      .pipe(map(data => {
        data.status = StatusHelper.GetStatusFromId(data.currentStatus);
        this.activeBoothLineItem.next(data);
        return data;
      }, err => {
        if (err.status !== 401 && err.status !== 403) {
          this.toastService.open('There was an error refreshing the line item data', 'danger');
        }
      }));
  }

  getAttachmentFiles(eventID: string) {
    this.http.get<Attachment[]>(`${this.url()}events/${eventID}/accounts/${this.activeBooth.getValue().showAccountGUID}/functional-areas/${this.emptyFAGUID}/line-items/${this.activeBoothLineItem.getValue().kafkaId}/files`)
      .subscribe(data => {
        data.forEach(file => {
          file.fileType = this.getFileType(file);
        });

        this.boothLIAttachments.next(data);
      }, err => {
        if (err.status !== 401 && err.status !== 403) {
          this.toastService.open('There was an error refreshing the file data', 'danger');
        }
      });
  }

  private getFileType(file: Attachment): FileType {
    const fileExtension = file.name.match(/\.[0-9a-z]+$/i).toString().toLowerCase();
    return FileTypeHelper.fileExtensionObject[fileExtension] || FileTypeHelper.fileTypeEnum.Unknown;
  }

  fetchActivityPhotos(): ActivityPhoto[] {
    const photos: ActivityPhoto[] = [];
    this.currentActivityItems.forEach(item => {
      if (item.photo) {
        photos.push(item.photo);
      }
    });
    return photos;
  }

  getBoothSearchData(eventId: string, boothGUID: string) {
    this.http.get<Array<CollaborationLineItemDTO>>(`${this.url()}events/${eventId}/booths/${boothGUID}/search`)
      .subscribe(data => {
        // Removed Cancelled lineitems.
        data = _.filter(data, function (liData) {
          return (liData.lineItemStatusId !== 6 && liData.lineItemStatusId !== 7);
        });

        data.forEach(searchItem => {
          searchItem.status = StatusHelper.GetStatusFromId(searchItem.currentStatus)
        })
        this.boothSearchData.next(data);
      }, err => {
        if (err.status !== 401 && err.status !== 403) {
          this.toastService.open('There was an error refreshing the search data', 'danger');
        }
      });
  }

  resetExhibitData() {
    this.showBooths.next([]);
    this.activeBooth.next(null);
    this.activeBoothLineItem.next(null);
    this.activeBoothGuid = "";
    this.allBoothLineItems.next([]);
    this.currentBoothLineItems.next([]);
    this.allServiceRequestItems.next([]);
    this.currentBoothServiceRequests.next([]);
  }

  getSearchFilterSelection() {
    return this.filterSelections;
  }

  resetBoothSearchFilterSelection() {
    this.filterSelections = [{
      name: "Global Filters",
      criterias: [
        { id: 1, name: "Attachments", value: [] },
        { id: 2, name: "Photos", value: [] }
      ],
      isExpanded: false
    }, {
      name: "Line Item Filters",
      criterias: [
        { id: 3, name: "Status", value: [] },
        { id: 4, name: "Vendor", value: [] },
        { id: 5, name: "Setup Start Date", value: [] },
        { id: 6, name: "Setup Complete Date", value: [] },
        { id: 7, name: "Location", value: [] },
        { id: 8, name: "Category", value: [] }
      ],
      isExpanded: false
    }];
    this.filterSelectionCount = 0;
  }

  setBoothSearchFilterSelection(filterSelections: any[]) {
    this.filterSelectionCount = 0;
    this.filterSelections = [...filterSelections];
    this.filterSelections.forEach(groupSelection => {
      groupSelection.criterias.forEach(criteriaItem => {
        if (criteriaItem.value.length > 0) {
          this.filterSelectionCount += 1;
        }
      });
    });
  }

  getBoothActivity(eventId: string, boothId: string) {
    const timelineStartDate = moment.utc(moment.now()).toDate();
    const showAccountGuid = this.activeBooth.getValue().showAccountGUID;
    this.http.post<TimelineDTO[]>(`${this.url()}events/${eventId}/accounts/${showAccountGuid}/booth/${boothId}/timeline`, timelineStartDate).subscribe(data => {
      this.currentTimelineSubject.next(this.setCurrentUserName(data));
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error refreshing the activity data', 'danger');
      }
    });
  }

  getBoothNotifications(eventId: string, boothId: string) {
    const timelineStartDate = moment.utc(moment.now()).toDate();
    const showAccountGuid = this.activeBooth.getValue().showAccountGUID;
    this.http.post<TimelineDTO[]>(`${this.url()}events/${eventId}/accounts/${showAccountGuid}/booth/${boothId}/timeline/${this.authHelper.currentUserGuid.getValue()}`, timelineStartDate).subscribe(data => {
      this.currentTimelineNotificationSubject.next(this.setCurrentUserName(data));
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error refreshing the notifications data', 'danger');
      }
    });
  }

  private setCurrentUserName(data: TimelineDTO[]): TimelineDTO[] {
    data.forEach(i => {
      if (i.statusId) {
        i.status = StatusHelper.getBoothStatusDetails(i.statusId);
      }
      i.senderName = i.senderGuid === this.authHelper.currentUserGuid.getValue() ? 'YOU' : i.senderName;
      i.receiverName = i.receiverGuid === this.authHelper.currentUserGuid.getValue() ? 'YOU' : i.receiverName;
      i.isActiveUser = i.receiverGuid === this.authHelper.currentUserGuid.getValue();
      i.location = i.lineItemNumber ? `LI#${i.lineItemNumber}`: i.boothName;
    });
 
    return data;
  }

  resetBoothActivity() {
    this.currentTimelineSubject.next([]);
    this.currentTimelineNotificationSubject.next([]);
  }

  cancelOrderForLineItem(eventId: string, accountId: string, lineItemId: string): Observable<boolean> {
    return this.http.post<boolean>(this.url() + 'events/' + eventId + '/accounts/' + accountId + '/change-orders/cancel-lineitem/' + lineItemId, {}).pipe(map(cancelOrderResponse => {
      this.toastService.open('Success! Cancel Order completed.', 'success');
      return cancelOrderResponse;
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error for canceling order.', 'danger');
      }
    }));
  }

  getBoothChangeLogs(eventId: string, showAccountGUID: string, boothGUID: string) {
    this.http.get<Array<ChangeOrderDTO>>(`${this.url()}events/${eventId}/accounts/${showAccountGUID}/change-orders/booth/${boothGUID}`)
      .subscribe(data => {
        const sortedLogData = _.orderBy(data, [function (changeOrder) {
          return new Date(changeOrder.createdDate).getTime();
        }], ['desc']);
        this.boothChangeLogData.next(sortedLogData);
      }, err => {
        if (err.status !== 401 && err.status !== 403) {
          this.toastService.open('There was an error refreshing the Change Order data', 'danger');
        }
      });
  }

  getChangeOrderByOrderId(eventId: string, showAccountGUID: string, changeOrderID: string){
    return this.http.get<ChangeOrderDTO>(`${this.url()}events/${eventId}/accounts/${showAccountGUID}/change-orders/${changeOrderID}`)
			.pipe(map(data => {
				return data;
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error change order data', 'danger');
				}
			}));
  }

  refreshBoothChangeOrders(eventId: string, showAccountGUID: string, boothGUID: string){
    this.refreshInterval = setInterval(() => {
      this.getBoothChangeLogs(eventId, showAccountGUID, boothGUID);
		}, 30000);
  }

  clearBoothChangeOrders(){
    if(this.refreshInterval){
      clearInterval(this.refreshInterval);
    }
  }

  getServiceRequestList(showId) {
    let url = `${this.url()}events/${showId}/exhibitorService/issue-items`;
    return this.http.get<any[]>(url)
      .pipe(map(data => {
        data.map(item => {
          item['status'] = StatusHelper.GetServiceRequestStatusFromId(item.currentStatus);
          item['kafkaId'] = item?.issueItemGuid;
          item['booth'] = item?.boothNumber;
          item['description'] = item?.issueText;
          item['accountName'] = item?.requestor;
          item['setupDate'] = item?.submittedDate;
        })
        this.allServiceRequestItems.next(data);
        return data;
      }, err => {
        if (err.status !== 401 && err.status !== 403) {
          this.toastService.open('There was an error while fetching service requests', 'danger');
        }
      }));
  }

  getBoothServiceRequestsList(eventId: string, boothGuid: string) {
    this.http.get<Array<any>>(`${this.url()}events/${eventId}/exhibitorService/booths/${boothGuid}/issue-items`)
      .subscribe(data => {
        data.map(item =>{
          item['status'] = StatusHelper.GetServiceRequestStatusFromId(item.currentStatus);
          item['kafkaId'] = item?.issueItemGuid;
          item['booth'] = item?.boothNumber;
          item['description'] = item?.issueText;
          item['accountName'] = item?.requestor;
          item['setupDate'] = item?.submittedDate;
        });
        this.currentBoothServiceRequests.next(data);
      }, err => {
        if (err.status !== 401 && err.status !== 403) {
          this.toastService.open('There was an error getting service requests for booth', 'danger');
        }
      });
  }
}
