import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';
import { KioskService } from 'src/app/services/kiosk/kiosk.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'occ-kiosk-print-loader',
  templateUrl: './kiosk-print-loader.component.html',
  styleUrls: ['./kiosk-print-loader.component.scss'],
})
export class KioskPrintLoaderComponent implements OnInit {

  @Input() boothNumber?: string;
  @Input() selectedMhaItem: any;
  @Input() kafkaId: any;
  @Input() showNumber: any;
  showButtonLoader: any;
  @Input() isMHASubmitted: boolean= false;

  constructor(
    private kioskService: KioskService,
    private viewCtrl: ModalController,
    public appBrowserHelper: AppBrowserHelper) {
  }
  ngOnInit(): void {
    if(this.isMHASubmitted) {
      if (this.kioskService.isKioskPrintDataLoaded) {
        this.viewCtrl.dismiss({
          isDismissed: true
        })
      } else {
        this.printLoad();
      }
    }
  }

  closeModalPopup() {
    this.viewCtrl.dismiss();
  }

  proceedToPrint() {
    if (this.kioskService.isKioskPrintDataLoaded) {
      this.viewCtrl.dismiss({
        isDismissed: true
      })
    } else {
      this.showButtonLoader = true;
      this.printLoad();
    }
  }

  printLoad(){
    let loaderInterval = setInterval(() => {
      if (this.kioskService.isKioskPrintDataLoaded) {
        clearInterval(loaderInterval);
        this.viewCtrl.dismiss({
          isDismissed: true
        })
      }
    }, 300)
  }

}
