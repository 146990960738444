import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthTokenHelper } from './auth-token-helper';
import { Storage } from '@ionic/storage-angular';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LoginAuthGuard implements CanActivate {
  isActive: boolean;
  constructor(public authTokenHelper: AuthTokenHelper, public router: Router,
    public storage: Storage, public authSvc: AuthenticationService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authTokenHelper.isTokenValid().then(async (data) => {
      this.isActive = data;
      if (this.isActive) {
        await this.storage.get('isSelfCheckIn').then((data) => {
          if (data == true) {
            this.authSvc.logout('self-checkin');
            return true;
          }
          else {
            this.router.navigateByUrl("/events");
            return false;
          }
        });
      }
      return true;
    });
  }
  
}
