import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';
import { AuthTokenHelper } from 'src/app/helpers/auth-token-helper';
import { NavParamHelper } from 'src/app/helpers/nav-param-helper';
import { SelectHelper } from 'src/app/helpers/select-helper';
import { FATabsEnum } from 'src/app/helpers/tabs-enum';
import { CollaborationLineItemDTO } from 'src/app/models/collaboration-line-item-dto';
import { WorkTicketDTO } from 'src/app/models/work-ticket-dto';
import { LineItemService } from 'src/app/services/line-item/line-item.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { WorkTicketService } from 'src/app/services/work-ticket/work-ticket.service';
import { WorkticketLineItemService } from 'src/app/services/workticket-line-item/workticket-line-item.service';
import { takeWhile } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'occ-workticket-details-line-item',
  templateUrl: './workticket-details-line-item.component.html',
  styleUrls: ['./workticket-details-line-item.component.scss'],
})
export class WorkticketDetailsLineItemComponent implements OnInit {
  @Input() lineItemId: string;
	tab: FATabsEnum;
	lineItem: CollaborationLineItemDTO;
	itemExpanded = false;
	ticketExpanded = false;
	salesUser = false;
	eventId: string;
	workTicketGuid: string;
	lisWorkticket: WorkTicketDTO;

	subscribe = true;

	constructor(
    public router: Router,
		public lineItemSvc: LineItemService,
		public wktkLineItemSvc: WorkticketLineItemService,
		private authTokenHelper: AuthTokenHelper,
		public navCtrl: NavController,
		public navParamHelper: NavParamHelper,
		public appBrowserHelper: AppBrowserHelper,
		public toastService: ToastService,
		public workTikSvc: WorkTicketService,
    private activatedRoute: ActivatedRoute
	) {
		this.eventId = this.activatedRoute.snapshot.paramMap.get('eventId');
		this.workTicketGuid = this.activatedRoute.snapshot.paramMap.get('workTicketGuid');
	}

	ngOnInit(): void {

		this.wktkLineItemSvc.currentLineItem.pipe(takeWhile(() => this.subscribe)).subscribe(lineItem => {
			this.lineItem = lineItem;
		});
		this.setupPage();
	}

	async setupPage() {
		this.salesUser = await this.authTokenHelper.IsSalesUser();
	}

	setTab(tab: FATabsEnum) {
		this.tab = tab;
	}

	isSelected(tab: FATabsEnum) {
		return this.tab === tab;
	}

	toggleItemComment() {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		if (this.lineItem.vendorComments)
			this.itemExpanded = !this.itemExpanded;
	}

	toggleTicketComment() {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		if (this.lineItem.ticketComments)
			this.ticketExpanded = !this.ticketExpanded;
	}

	goToWorkticket(workticketNum: number) {
		if (workticketNum === this.lineItem.ticketNumber) {
			this.lisWorkticket = this.workTikSvc.lookupWorkTicketById(this.workTicketGuid);
			this.workTikSvc.currentWorkTicketSubject.next(this.lisWorkticket);
      this.router.navigate(["/events", this.eventId, 'worktickets', this.workTicketGuid, 'workticket-details']);

		} else {
			return this.toastService.open('Invalid Workticket number.', 'danger');
		}
	}
}
