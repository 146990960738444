import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { PopoverController, NavController, ModalController } from '@ionic/angular';
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';
import { SelectHelper } from 'src/app/helpers/select-helper';
import { StatusHelper } from 'src/app/helpers/status-helper';
import { CollaborationLineItemDTO } from 'src/app/models/collaboration-line-item-dto';
import { Status } from 'src/app/models/status';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';
import { WorkTicketService } from 'src/app/services/work-ticket/work-ticket.service';
import { WorkticketLineItemService } from 'src/app/services/workticket-line-item/workticket-line-item.service';
import { ItemActionMenuComponent } from '../item-action-menu/item-action-menu.component';
import { takeWhile } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkTicketDTO } from 'src/app/models/work-ticket-dto';

@Component({
  selector: 'occ-workticket-line-item-list',
  templateUrl: './workticket-line-item-list.component.html',
  styleUrls: ['./workticket-line-item-list.component.scss'],
})
export class WorkticketLineItemListComponent implements OnInit {

  @ViewChild('searchbarInput') searchbarInput: ElementRef;
	@Input('isWorkTicketPage') isWorkTicketPage: Boolean;

	eventId: string;
	lineItemDto: CollaborationLineItemDTO[];
  	workTicket: WorkTicketDTO;
	menuOpen = false;
	statusMenuPopover: any;
	itemActionModal: any;
	selectedItem: CollaborationLineItemDTO;
	refreshEnabled = true;
	showMobileSearchbar = false;
	searchText = '';
	showFilter = false;
	needsAttentionModal: any;
	isLoading: boolean;
	firstLoad = false;
	wtId: string;
	viewPortItems: any;
	Status = StatusHelper.Status;

	private subscribe = true;

	constructor(
		public router: Router,
    	private activatedRoute: ActivatedRoute,
		public wtLineItemSvc: WorkticketLineItemService,
		public popoverCtrl: PopoverController,
		public navCtrl: NavController,
		public appBrowserHelper: AppBrowserHelper,
		public modalCtrl: ModalController,
		public dataSvc: DataRefreshService,
		public workTikSvc: WorkTicketService
	) {
		this.wtId = this.activatedRoute.snapshot.paramMap.get('workTicketId') || this.workTikSvc.activeWorkTicketId;
		this.eventId = this.activatedRoute.snapshot.paramMap.get('eventId') || this.dataSvc.eventActive;
	}

	ngOnInit() {
		this.isLoading = true;
		this.wtLineItemSvc.collaborationLineItemDto.pipe(takeWhile(() => this.subscribe)).subscribe(cliDto => {
			this.lineItemDto = cliDto;
			if (this.firstLoad) {
				this.firstLoad = false;
				this.isLoading = false;
			}
		});
    this.workTikSvc.currentWorkTicketSubject.pipe(takeWhile(() => this.subscribe)).subscribe(wtDetailDto => {
			this.workTicket = wtDetailDto;
		});
		this.firstLoad = true;
		this.wtLineItemSvc.refresh(this.eventId, this.wtId).subscribe();
	}

	ionViewWillLeave() {
		if (this.statusMenuPopover) {
			this.statusMenuPopover.dismiss();
		}
		if (this.itemActionModal) {
			this.itemActionModal.dismiss();
		}
	}

	changedList(event) {
		// checks to see if the index of the start item is 0
		return this.refreshEnabled = event.start === 0;
	}


	refresh(refresher): void {
		this.wtLineItemSvc.refresh(this.eventId, this.wtId).subscribe();
		refresher.target.complete();
	}

	async presentPopover(event, item: CollaborationLineItemDTO) {
    this.statusMenuPopover = await this.popoverCtrl.create({
			component: ItemActionMenuComponent,
			event: event,
			componentProps: { workTicketLineItem: item }
		});

		await this.statusMenuPopover.present();
		await this.statusMenuPopover.onDidDismiss();
    this.clearSelectedItem();
		event.stopPropagation();
	}

	changeStatus(lineItemId: any, status: Status) {
		this.wtLineItemSvc.put(this.eventId, this.wtId, lineItemId, status);
	}

	delayAndCloseIonItemSliding(itemSliding) {
		setTimeout(() => {
			itemSliding.close();
		}, 1);
	}

	updateSearchText(searchText: string): void {
		this.searchText = searchText;
	}

	toggleFilter(): boolean {
		if (this.showFilter === true) {
			return this.showFilter = false;
		}

		this.showFilter = true;
	}

	async presentLineItemStatusMenuModal(item: CollaborationLineItemDTO, itemSliding: any) {
    this.itemActionModal = await this.modalCtrl.create({
			component: ItemActionMenuComponent,
			componentProps: { workTicketLineItem: item }
		});
		await this.itemActionModal.present();
		await this.itemActionModal.onDidDismiss();
		itemSliding.close();
	}

	pushDetailsPage(lineItem: CollaborationLineItemDTO): void {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		// check the current line item value before doing anything
		const currentLineItem = this.wtLineItemSvc.currentLineItem.getValue();

		// if it doesn't exist or if it's different, update it.
		if (!currentLineItem || currentLineItem !== lineItem) {
			this.wtLineItemSvc.currentLineItem.next(lineItem);
		}

		this.router.navigate(["/events", this.eventId, 'workticket-details', this.wtId, 'line-items', lineItem.kafkaId, 'workticket-line-item-details']);
	}

	clearSelectedItem() {
		this.selectedItem = null;
	}

	ngOnDestroy() {
		this.subscribe = false;
		if (this.statusMenuPopover) {
			this.statusMenuPopover.dismiss();
		}

		if (this.itemActionModal) {
			this.itemActionModal.dismiss();
		}

	}

}
