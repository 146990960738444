import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'occ-makeover-search-modal',
  templateUrl: './makeover-search-modal.component.html',
  styleUrls: ['./makeover-search-modal.component.scss'],
})
export class MakeoverSearchModalComponent implements OnInit {
  searchInputParam: string;
  @Input() lineItemType;
  constructor(private popoverCtrl: PopoverController) {
  }

  ngOnInit() {
  }

  closeModalPopup() {
    this.searchInputParam='';
    this.popoverCtrl.dismiss();
  }

  getSearchResult() {
    this.popoverCtrl.dismiss({
      'searchText': this.searchInputParam ? this.searchInputParam : ""
    });
  }

}
