import { Status } from './status';

export class ChangeOrderDTO {
	public kafkaId?: string;
	public functionalAreaGuid?: string;
	public description?: string;
	public vendorName?: string;
	public locationInfo?: string;
	public status?: Status;
	public unitOfMeasure?: string;
	public quantity?: number;
	public lineItemId?: number;
	public partNumber?: number;
	public detailDescription?: string;
	public ticketNumber?: number;
	public setupStart?: Date;
	public setupEnd?: Date;
	public strikeStart?: Date;
	public strikeEnd?: Date;
	public booth?: string;
	public functionalAreaStatusEnum?: number;
	public currentStatus?: number;
	public displayName?: string;
	public vendorComments?: string;
	public ticketComments?: string;
	public revisionComments?: string;
	public attachments?: string;
	public hasPhotos?: boolean;
	public showAccountGuid?: string;
	public facility?: string;
	public partSelections?: Array<PartSelectionDto>;
	public showLocationId?: string;
	public showBoothId?: string;
	public revisionReasonTypeId?: string;
	public men?: number;
	public hours?: number;
	public confirmSave?: boolean;
	public createdDate?: Date;
	public modifiedDate?: Date;
	public changeRequest?: boolean;
	public revisionReasonId?: string;
	public partDescription?: string;
	public category?: string;
	public createdBy?: string;
	public isNewOrder?: boolean;
	public tssWorkTicketNumber: number;
	public isCancelled?: boolean;
	public isChangeLogRead?: boolean;
	public id: number;
}

export class PartSelectionDto {
	public materialSubTypeDescription: string;
	public partDescription: string;
	public basePartId: number;
	public passportPartId: number;
}
