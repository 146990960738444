import { Component, EventEmitter, Output } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { LineItemService } from 'src/app/services/line-item/line-item.service';
import { FilterHelper } from '../../../helpers/filter-helper';
import { SelectHelper } from '../../../helpers/select-helper';

@Component({
  selector: 'occ-filter-booth',
  templateUrl: './filter-booth.component.html',
  styleUrls: ['./filter-booth.component.scss'],
})
export class FilterBoothComponent {
	@Output() closeFilter = new EventEmitter();
	@Output() navigate = new EventEmitter();

	booths: string[];
	isWorkTicketSearchPage: false;
	pageReturn: string;

	subscribe = true;

	constructor(public lineItemSvc: LineItemService, public filterHelper: FilterHelper) {
		this.lineItemSvc.boothListSubject.pipe(takeWhile(() => this.subscribe)).subscribe(boothList => {
			this.booths = boothList.sort();
		});
		this.filterHelper.workticketSearchPage ? this.pageReturn = 'workticketPage' : 'selection';
	}

	hideFilter(): void {
		this.closeFilter.emit();
	}

	showPage(pageToShow): void {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		this.navigate.emit(pageToShow);
	}

	applyFilter(booth: string): void {
		this.filterHelper.setFilter(booth, this.filterHelper.booth, 'InvoiceFilter');
	}

	getCurrentBooth(booth: string): boolean {
		if (this.filterHelper.booth.find(x => x === booth)) return true;
	}

	resetFilter(): void {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		this.filterHelper.booth = [];
		this.filterHelper.applyFilters();
	}
}

