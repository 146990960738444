import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'occ-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
})
export class FormSelectComponent implements OnInit {
  @Input() placeholderText: string = "";
  @Input() isOtherCarrierVisible?: boolean = false;
  @Input() selectItemData: any = [];
  @Input() defaultSelectedValue?: any = {
    display: "",
    value: null
  };
  @Input() isDisabled: boolean;
  @Input() isDropdown: boolean;
  @Output() onSelectionChange = new EventEmitter();
  @Input() searchText: string = "";
  @Input() selectedCarrierSegment: any;
  public autoCompleteData: any = [];
  public isListVisible: boolean = false;
  public otherCarrierItem: any = {
    display: "Other Carrier",
    value: 0
  }

  constructor() { }

  ngOnInit() {
    this.autoCompleteData = this.selectItemData;
    this.searchText = this.defaultSelectedValue?.display ? this.defaultSelectedValue?.display : "";
  }

  setDataSelection(selectedItem) {
    this.isListVisible = false;
    this.searchText = selectedItem.display
    this.onSelectionChange.emit(selectedItem);
  }

  setDropdownWidth() {
    setTimeout(() => {
      $(".custom-select-popover .popover-content").css('width', ($(".autocomplete")[0].clientWidth + 'px'));
    }, 100);
  }

  getAutoCompleteItems() {
    this.autoCompleteData = [];
    if (this.searchText) {
      this.isListVisible = true;
      this.selectItemData.forEach(item => {
        if (item.display.toLowerCase().startsWith(this.searchText.toLowerCase())) {
          this.autoCompleteData.push(item);
        }
      });
    } else {
      this.isListVisible = false;
      this.autoCompleteData = this.selectItemData;
    }
  }

  clearSearchData() {
    if (this.searchText) {
      this.searchText = "";
      this.onSelectionChange.emit(null);
      this.getAutoCompleteItems();
    }
  }

  onChange(event) {
    this.onSelectionChange.emit(event.detail.value);
  }
}
