import { Injectable } from '@angular/core';
// import { ImageCompressService, ResizeOptions } from 'ng2-image-compress';
import { NgxImageCompressService, DOC_ORIENTATION } from "ngx-image-compress";
import { DomSanitizer } from '@angular/platform-browser';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { LoadingController, ModalController } from '@ionic/angular';
import { ToastService } from '../services/toast/toast.service';
import { ShowAccountService } from '../services/show-account/show-account.service';
import { EventService } from '../services/event/event.service';
import { ActivityService } from '../services/activity/activity.service';
import { StatusBar } from '@ionic-native/status-bar/ngx';

@Injectable()
export class CameraLinkToPhotoVerificationHelper {
	queuedImages = [];
	loading: any;
	confirmationModal: any;
	cb: Function;
	// resizeOptions: ResizeOptions;

	constructor(
		private camera: Camera,
		public activitySvc: ActivityService,
		public eventSvc: EventService,
		public acctSvc: ShowAccountService,
		public toastService: ToastService,
		public domSanitizer: DomSanitizer,
		// public imgCompressSvc: ImageCompressService,
		public imgCompressSvc: NgxImageCompressService,
		public loadingCtrl: LoadingController,
		public modalCtrl: ModalController,
		public statusBar: StatusBar
	) {
		// this.resizeOptions = new ResizeOptions();
		// this.resizeOptions.Resize_Max_Height = 1000;
		// this.resizeOptions.Resize_Max_Width = 1000;
		// this.resizeOptions.Resize_Quality = 80;
		// this.resizeOptions.Resize_Type = 'image/jpeg';
	}

	buildLoader() {
		this.loading = this.loadingCtrl.create({
			message: 'Optimizing image...',
			// dismissOnPageChange: true
		});
	} //  For desktop uploads using HTML 5

	async queueImages(event: any) {
		const photos = event.target.files;
		const supportedFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];
		for (let i = 0; i < photos.length; i++) {
			const file = photos[i];
			if (!file) return;
			if (supportedFileTypes.indexOf(file.type) === -1) {
				return this.toastService.open(
					'Unsupported file type. Aborting upload.',
					'danger'
				);
			}
			const imageString = await this.processImageFromFile(file);
			this.queuedImages.push(imageString);
		}
		this.buildLoader();
		// this.loading.present();
		if (!this.queuedImages) {
			// this.loading.dismiss();
			return this.toastService.open(
				'Image processing failed',
				'danger'
			);
		}
		// this.loading.dismiss();
	}

	async processImageFromFile(file: File) {
		// const imageResult = await ImageCompressService.filesArrayToCompressedImageSourceEx(
		// 	[file],
		// 	this.resizeOptions
		// );
		// const base64Image = await imageResult.toPromise();
		const imageData = await this.getBase64(file);
		const imageResult = await this.imgCompressSvc.compressFile(imageData.toString(), DOC_ORIENTATION.Default, 100, 80, 1000, 1000);
		// const base64Image = await imageResult.toPromise();
		// return base64Image.compressedImage.imageDataUrl;
		return imageResult;
	}

	async getBase64(file) {
		return new Promise((resolve, reject) => {
		  const reader = new FileReader();
		  reader.readAsDataURL(file);
		  reader.onload = () => {
			let encoded = reader.result.toString();
			resolve(encoded);
		  };
		  reader.onerror = error => reject(error);
		});
	  }
	  

	takeWorkTicketPicture(
		fromSavedPhotos?: boolean,
	) {
		const options: CameraOptions = {
			allowEdit: false,
			correctOrientation: true,
			destinationType: this.camera.DestinationType.DATA_URL,
			encodingType: this.camera.EncodingType.JPEG,
			mediaType: this.camera.MediaType.PICTURE,
			targetWidth: 1000,
			targetHeight: 1000
		};
		if (fromSavedPhotos) {
			options.sourceType = this.camera.PictureSourceType.PHOTOLIBRARY;
		}
		this.camera.getPicture(options).then(imageData => {
			this.statusBar.hide();
			this.statusBar.show();
			const base64Image = 'data:image/jpeg;base64,' + imageData;
			this.queuedImages.push(base64Image);
		});
	}
}
