import { AuthTokenHelper } from './auth-token-helper';
import { CollaborationLineItemDTO } from '../models/collaboration-line-item-dto';
import { FunctionalArea } from '../models/functional-area';
import { FunctionalAreaService } from '../services/functional-area/functional-area.service';
import { Injectable } from '@angular/core';
import { LineItemService } from '../services/line-item/line-item.service';
import { SearchItem } from '../models/search-item';
import { Subject } from 'rxjs';
import { WorkTicketDTO } from '../models/work-ticket-dto';
import { WorkTicketService } from '../services/work-ticket/work-ticket.service';
import { ChangeOrderDTO } from '../models/change-order-dto';

@Injectable()

export class SearchHelper {

	public searchString: string;
	public previousSearchString: string;
	public searchPage: boolean;
	public workticketSearchPage: boolean;

	public functionalAreasFoundByName: FunctionalArea[] = [];
	public functionalAreasFoundByRelative: FunctionalArea[] = [];
	public functionalAreasFoundByLocation: FunctionalArea[] = [];
	public functionalAreasFoundByAttachment: FunctionalArea[] = [];

	public lineItemsFoundByName: CollaborationLineItemDTO[] = [];
	public lineItemsFoundByNumber: CollaborationLineItemDTO[] = [];
	public lineItemsFoundByLocation: CollaborationLineItemDTO[] = [];
	public lineItemsFoundByTicketNumber: CollaborationLineItemDTO[] = [];
	public lineItemsFoundByAttachment: CollaborationLineItemDTO[] = [];
	public lineItemsFoundByFunctionalAreaName: CollaborationLineItemDTO[] = [];
	public lineItemsFoundByTicketComments: CollaborationLineItemDTO[] = [];
	public lineItemsFoundByVendorComments: CollaborationLineItemDTO[] = [];
	public lineItemsFoundByPartNumber: CollaborationLineItemDTO[] = [];

	public workTicketsFoundByName: WorkTicketDTO[] = [];
	public workTicketsFoundByNumber: WorkTicketDTO[] = [];
	public workTicketsFoundByCategory: WorkTicketDTO[] = [];
	public workTicketsFoundByLocation: WorkTicketDTO[] = [];
	public workTicketsFoundByFunctionalAreaName: WorkTicketDTO[] = [];

	public isSalesUser: boolean;

	public lineItems: CollaborationLineItemDTO[] = [];
	public functionalAreas: FunctionalArea[] = [];
	public workTickets: WorkTicketDTO[] = [];
	public changeOrders: ChangeOrderDTO[] = [];
	public searchItems = new Subject<SearchItem[]>();

	constructor(public funcSvc: FunctionalAreaService, public liSvc: LineItemService, public wtSvc: WorkTicketService, private authHelper: AuthTokenHelper) {
		this.checkSalesUser();
		funcSvc.allFunctionalAreasSubject.subscribe(areas => {
			this.functionalAreas = areas;
		});
		liSvc.allLineItemsSubject.subscribe(items => {
			this.lineItems = items;
		});
		wtSvc.allWorkTicketsSubject.subscribe(tickets => {
			const sortedTickets = wtSvc.sortWorkTickets(tickets);
			this.workTickets = sortedTickets;
		});
		liSvc.allChangeOrdersSubject.subscribe(changeOrders => {
			this.changeOrders = changeOrders;
		});
	}

	private async checkSalesUser() {
		this.isSalesUser = await this.authHelper.IsSalesUser();
	}

	public createSearchItems(fas: FunctionalArea[], lis: CollaborationLineItemDTO[], wts: WorkTicketDTO[], cos: ChangeOrderDTO[]): void {

		const items: SearchItem[] = [];
		if (fas) {
			fas.forEach(fa => {
				items.push(new SearchItem(fa, null));
			});
		}
		if (wts) {
			wts.forEach(wt => {
				items.push(new SearchItem(null, null, null, wt));
			});
		}
		if (lis) {
			lis.forEach(li => {
				if(li.lineItemStatusId !== 6 && li.lineItemStatusId !== 7)
				items.push(new SearchItem(null, li));
			});
		}
		if(cos){
			cos.forEach(co => {
				items.push(new SearchItem(null, null, null, null, co));
			});
		}
		this.searchItems.next(items);
	}


	public searchFunctionalAreas(searchString: string): FunctionalArea[] {
		this.clearFASearchItems();
		if (!searchString) return this.functionalAreas;
		searchString = searchString.toLowerCase().trim();

		this.populateFASearchItems(searchString);
		return  this.functionalAreasFoundByName.concat(this.functionalAreasFoundByRelative, this.functionalAreasFoundByLocation, this.functionalAreasFoundByAttachment);
	}

	private populateFASearchItems(searchString: string): void {
		this.functionalAreas.forEach((x: FunctionalArea) => {
			if (x.name.toLowerCase().startsWith(searchString)) {
				this.functionalAreasFoundByName.push(x);
			} else if ((x.breadCrumbs.parent.toLowerCase().startsWith(searchString) || x.breadCrumbs.grandparent.toLowerCase().startsWith(searchString))) {
				this.functionalAreasFoundByRelative.push(x);
			} else if ((x.showLocationName !== null && x.showLocationName.toLowerCase().startsWith(searchString))) {
				this.functionalAreasFoundByLocation.push(x);
			} else if ((x.attachments !== null && x.attachments.toLowerCase().startsWith(searchString))) {
				this.functionalAreasFoundByAttachment.push(x);
			}
		});
	}
	private clearFASearchItems(): void {
		this.functionalAreasFoundByName = [];
		this.functionalAreasFoundByRelative = [];
		this.functionalAreasFoundByLocation = [];
		this.functionalAreasFoundByAttachment = [];
	}

	private populateLISearchItems(searchString: string): void {
		if (!this.isSalesUser) {
			this.checkSalesUser();
		}
		this.lineItems.forEach((x: CollaborationLineItemDTO) => {
			if (x.displayName.toLowerCase().startsWith(searchString)) {
				this.lineItemsFoundByName.push(x);
			} else if (x.passportId.toString().startsWith(searchString)) {
				this.lineItemsFoundByNumber.push(x);
			} else if (x.locationInfo && x.locationInfo.toLowerCase().startsWith(searchString)) {
				this.lineItemsFoundByLocation.push(x);
			} else if (x.ticketNumber !== null && x.ticketNumber.toString().startsWith(searchString) && this.isSalesUser) {
				this.lineItemsFoundByTicketNumber.push(x);
			} else if (x.attachments && x.attachments.toLowerCase().startsWith(searchString)) {
				this.lineItemsFoundByAttachment.push(x);
			} else if (x.functionalAreaName && x.functionalAreaName.toLowerCase().startsWith(searchString)) {
				this.lineItemsFoundByFunctionalAreaName.push(x);
			} else if (x.partNumber && x.partNumber.toString().startsWith(searchString)) {
				this.lineItemsFoundByPartNumber.push(x);
			} else if (x.ticketComments && x.ticketComments.toLowerCase().startsWith(searchString) ) {
				this.lineItemsFoundByTicketComments.push(x);
			} else if (x.vendorComments && x.vendorComments.toLowerCase().startsWith(searchString)) {
				this.lineItemsFoundByVendorComments.push(x);
			}
		});
	}
	private clearLISearchItems(): void {
		this.lineItemsFoundByName = [];
		this.lineItemsFoundByNumber = [];
		this.lineItemsFoundByLocation = [];
		this.lineItemsFoundByTicketNumber = [];
		this.lineItemsFoundByAttachment = [];
		this.lineItemsFoundByFunctionalAreaName = [];
		this.lineItemsFoundByPartNumber = [];
		this.lineItemsFoundByTicketComments = [];
		this.lineItemsFoundByVendorComments = [];
	}

	public searchLineItems(searchString: string): CollaborationLineItemDTO[] {
		this.clearLISearchItems();
		if (!searchString) return this.lineItems;
		searchString = searchString.toLowerCase().trim();

		this.populateLISearchItems(searchString);

		return this.lineItemsFoundByName.concat(this.lineItemsFoundByNumber, this.lineItemsFoundByLocation, this.lineItemsFoundByTicketNumber, this.lineItemsFoundByAttachment, this.lineItemsFoundByFunctionalAreaName, this.lineItemsFoundByPartNumber, this.lineItemsFoundByTicketComments, this.lineItemsFoundByVendorComments);
	}

	public searchChangeOrders(searchString: string): ChangeOrderDTO[] {
		/*this.clearLISearchItems();
		if (!searchString) return this.lineItems;
		searchString = searchString.toLowerCase().trim();

		this.populateLISearchItems(searchString);

		return this.lineItemsFoundByName.concat(this.lineItemsFoundByNumber, this.lineItemsFoundByLocation, this.lineItemsFoundByTicketNumber, this.lineItemsFoundByAttachment, this.lineItemsFoundByFunctionalAreaName, this.lineItemsFoundByPartNumber, this.lineItemsFoundByTicketComments, this.lineItemsFoundByVendorComments);*/

		return this.changeOrders;
	}

	private populateWTSearchItems(searchString: string): void {
		if (!this.isSalesUser) {
			this.checkSalesUser();
		}
		this.workTickets.forEach((x: WorkTicketDTO) => {
			if (x.tssWorkTicketNumber.toString().startsWith(searchString)) {
				this.workTicketsFoundByNumber.push(x);
			} else if (x.category && x.category.toLowerCase().startsWith(searchString)) {
				this.workTicketsFoundByCategory.push(x);
			} else if (x.location && x.location.toLowerCase().startsWith(searchString)) {
				this.workTicketsFoundByLocation.push(x);
			} else if (x.functionalAreaName && x.functionalAreaName.toLowerCase().startsWith(searchString)) {
				this.workTicketsFoundByFunctionalAreaName.push(x);
			}
		});
	}
	private clearWTSearchItems(): void {
		this.workTicketsFoundByName = [];
		this.workTicketsFoundByNumber = [];
		this.workTicketsFoundByCategory = [];
		this.workTicketsFoundByLocation = [];
		this.workTicketsFoundByFunctionalAreaName = [];
	}

	public searchWorkTickets(searchString: string): WorkTicketDTO[] {
		this.clearWTSearchItems();
		if (!searchString) return this.workTickets;
		searchString = searchString.toLowerCase().trim();

		this.populateWTSearchItems(searchString);

		return this.workTicketsFoundByName.concat(this.workTicketsFoundByNumber, this.workTicketsFoundByLocation, this.workTicketsFoundByFunctionalAreaName, this.workTicketsFoundByCategory);
	}
}
