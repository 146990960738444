import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { MaterialHandlingAgreementDto } from 'src/app/models/material-handling-agreement';

@Component({
  selector: 'occ-kiosk-mha-modal',
  templateUrl: './kiosk-mha-modal.component.html',
  styleUrls: ['./kiosk-mha-modal.component.scss'],
})
export class KioskMhaModalComponent implements OnInit {

  text: string;
  currentMHA: MaterialHandlingAgreementDto;
  jReportUrl: string;
  printSuccessModalComponent: any;
	callback: Function;

  constructor(public navParams: NavParams,
    public viewCtrl: ModalController
    ) {
    this.currentMHA = this.navParams.get('currentMHA');
    this.jReportUrl = this.navParams.get('jReportUrl');
		this.callback = this.navParams.data.cb;
  }

  ngOnInit(): void {
    
  }

  dismiss() {
    this.viewCtrl.dismiss(true);
  }

  presentPrintLoadingModal() {
    //This time out required to complete input blur action complete
    setTimeout(() => {
      this.currentMHA.numberOfCopy = !this.currentMHA.numberOfCopy ? 1 : this.currentMHA.numberOfCopy;
      if (this.callback) {
        this.callback(this.currentMHA);
      }
    }, 300);
  }

  checkValidCopies(mhaItem: MaterialHandlingAgreementDto){
    if(mhaItem.numberOfCopy && mhaItem.numberOfCopy > 999){
      mhaItem.numberOfCopy = parseInt(mhaItem.numberOfCopy.toString().slice(0, 3));
    }
  }

  decreaseMhaPrintItem(mhaItem: MaterialHandlingAgreementDto){
    if(mhaItem.numberOfCopy && mhaItem.numberOfCopy > 1){
      mhaItem.numberOfCopy--;
    }
  }

  increaseMhaPrintItem(mhaItem: MaterialHandlingAgreementDto){
    if(mhaItem.numberOfCopy && mhaItem.numberOfCopy < 999){
      mhaItem.numberOfCopy++;
    }
  }

}
