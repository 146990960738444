import { ActivityNote } from './../../models/activity-note';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ActivityPhoto } from '../../models/activity-photo';
import { ActivityDTO } from '../../models/activity-dto';
import { ActivityItem } from '../../models/activity-item';
import * as moment from 'moment';
import { ToastService } from '../toast/toast.service';
import { ImageUploadDTO } from '../../models/image-upload-dto';
import { NoteUploadDTO } from '../../models/note-upload-dto';
import { BehaviorSubject, Subject } from 'rxjs';
import { StatusChange } from '../../models/status-change';
import { StatusHelper } from '../../helpers/status-helper';
import { UserService } from '../user/user.service';
import { AuthTokenHelper } from '../../helpers/auth-token-helper';
import { ToastType } from '../../models/toast-type';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';

@Injectable({
  providedIn: 'root'
})
export class WorkticketActivityService {
  url = environment.apiUrl;
  activityDTO: ActivityDTO;
  workTicketActivityItems = new BehaviorSubject<ActivityItem[]>([]);
  selectedPhoto = new BehaviorSubject<ActivityPhoto>(null);
  refreshActivityItems = false;
  public notePostedScroll: Subject<boolean> = new Subject();
  public currentWorkTicketActivityItems: ActivityItem[];

  constructor(
    public http: HttpClient,
    public toastService: ToastService,
    public userSvc: UserService,
    public authHelper: AuthTokenHelper,
    private ga: GoogleAnalytics,
  ) {
    this.workTicketActivityItems.subscribe(items => {
      this.currentWorkTicketActivityItems = items;
    });

  }

  get(eventId: string, workTicketGuid: string): void {
    this.refresh(eventId, workTicketGuid);
  }

  refresh(eventId: string, workTicketGuid: string, errorCallback?: Function): void {
    this.http.get<ActivityDTO>(`${this.url()}events/${eventId}/work-tickets/${workTicketGuid}/activity`)
      .subscribe(data => {
        this.activityDTO = data;
        // chain starts here
        this.addNotesToActivityItems();
      }, err => {
        if (err.status !== 401 && err.status !== 403) {
          this.toastService.open('There was an error refreshing the workticket activity data', 'danger');
        }
        if (errorCallback) errorCallback();
      });
  }

  public addNotesToActivityItems(): void {
    const activityItems: ActivityItem[] = [];

    this.activityDTO.notes.forEach(note => {
      const styledNote = this.styleMentionedUsers(note);
      const ActivityItem: ActivityItem = {
        note: styledNote,
        createdDate: note.createdDate
      };
      activityItems.push(ActivityItem);
    });

    this.addPhotosToNotes(activityItems);
  }

  addPhotosToNotes(activityItems: ActivityItem[]): void {
    for (let i = 0; i < this.activityDTO.photos.length; i++) {
      const photo = this.activityDTO.photos[i];
      if (photo.noteGuid) {
        const noteIndex = activityItems.findIndex(
          n => n.note && n.note.kafkaId === photo.noteGuid
        );
        if (noteIndex !== -1) {
          activityItems[noteIndex].photo = photo;
          this.activityDTO.photos.splice(i, 1);
          i--;
        } else {
          const ActivityItem: ActivityItem = {
            photo,
            createdDate: photo.createdDate
          };
          activityItems.push(ActivityItem);
        }
      } else {
        // if the photo is not associated with a note, it must be a stand alone photo
        // So we'll just create its own ActivityItem for it.
        const ActivityItem: ActivityItem = {
          photo,
          createdDate: photo.createdDate
        };
        activityItems.push(ActivityItem);
      }
    }
    this.addStatusToNotes(activityItems);
  }

  addStatusToNotes(activityItems: ActivityItem[]): void {
    for (let i = 0; i < this.activityDTO.statusChanges.length; i++) {
      const status = this.activityDTO.statusChanges[i];
      status.status = StatusHelper.GetStatusFromId(status.statusId);
      if (status.noteGuid) {
        const noteIndex = activityItems.findIndex(
          n => n.note && n.note.kafkaId === status.noteGuid
        );
        if (noteIndex !== -1) {
          // if there is a note associated with this status change, we add the status change to the activity item
          // that contains the note.
          activityItems[noteIndex].statusChange = status;
          // since we added the status change already (in the note's ActivityItem) we need to remove it from the original array of status changes
          this.activityDTO.statusChanges.splice(i, 1);
          i--;
        } else {
          const ActivityItem: ActivityItem = {
            statusChange: status,
            createdDate: status.createdDate
          };
          activityItems.push(ActivityItem);
        }
      } else {
        const ActivityItem: ActivityItem = {
          statusChange: status,
          createdDate: status.createdDate
        };
        activityItems.push(ActivityItem);
      }
    }
    this.sortActivityItems(activityItems);
  }

  public sortActivityItems(activityItems: ActivityItem[]): void {
    activityItems.sort((a, b) => {
      return moment.utc(a.createdDate).diff(b.createdDate);
    });
    this.workTicketActivityItems.next(activityItems);
  }

  public styleMentionedUsers(note: ActivityNote) {
    if (!note) return;
    const styledNote = note;
    this.userSvc.currentShowUsers.forEach(user => {
      user.kafkaId === this.authHelper.currentUserGuid.getValue()
        ? styledNote.body = note.body.replace('@' + user.displayName, '<span class="mentioned-current-user">@' + user.displayName + '</span>')
        : styledNote.body = note.body.replace('@' + user.displayName, '<span class="mentioned-user">@' + user.displayName + '</span>');
    });
    return styledNote;
  }

  getPhoto(eventId: string, workTicketGuid: string, photoId: string) {
    this.http
      // this route gets photos and notes. The photos controller only handles new photos and getting specific photos
      .get<ActivityPhoto>(`${this.url()}events/${eventId}/work-tickets/${workTicketGuid}/photoviewer/${photoId}/reportphoto`)
      .subscribe(data => {
        this.selectedPhoto.next(data);
      });
  }

  fetchPhotos(): ActivityPhoto[] {
    const photos: ActivityPhoto[] = [];
    const items = this.currentWorkTicketActivityItems;
    items.forEach(item => {
      if (item.photo) {
        photos.push(item.photo);
      }
    });
    return photos;
  }

  addNote(body: string, eventId: string, workTicketGuid: string, cb?) {
    const usersToNotify = this.userSvc.verifyMentionedUsers(body);
    const newNote: NoteUploadDTO = {
      body: body,
      usersToNotify: usersToNotify
    };
    this.http.post<ActivityNote>(`${this.url()}events/${eventId}/work-tickets/${workTicketGuid}/notes`, newNote).subscribe(
      data => {
        this.refreshActivityItems = true;
        this.addItemToActivityItems(data.createdDate, data, null, null);
        this.toastService.open('Success! Note added.', 'success');
        // this.refresh(functionalAreaId)
        if (cb) cb(true);
        this.ga.trackEvent('NoteAdded', 'NoteAdded');
        if (newNote.usersToNotify.length > 0) {
          this.ga.trackEvent('UserMentioned', 'UserMentioned');
        }
      },
      err => {
        switch (err.status) {
          case 400: {
            // Bad model
            this.toastService.open('Error 400: Invalid workticket note format.', 'danger');
            console.log(err);
            break;
          }
          case 401:
          case 403: {
            // Unauthorized
            break;
          }
          default: {
            // Generic
            this.toastService.open('Error 500: Workticket note failed to post. Check your network connection.', 'danger');
            break;
          }
        }
        if (cb) cb();
      }
    );
  }

  addItemToActivityItems(createdDate: Date, note?: ActivityNote, photo?: ActivityPhoto, statusChange?: StatusChange, spliceIndex?: number) {
    const activityItem: ActivityItem = {
      note: this.styleMentionedUsers(note),
      photo: photo,
      statusChange: statusChange,
      createdDate: createdDate
    };

    const items = this.currentWorkTicketActivityItems;
    const itemsSubject = this.workTicketActivityItems;

    items.push(activityItem);
    this.notePostedScroll.next(true);
    itemsSubject.next(items);
  }

  addPhoto(eventId: string, workTicketGuid: string, image?: string, note?: string) {
    const imageUploadDTO: ImageUploadDTO = {
      image,
      note
    };

    this.http.post<ActivityPhoto>(`${this.url()}events/${eventId}/work-tickets/${workTicketGuid}/photos`, imageUploadDTO).subscribe(
      data => {
        this.refreshActivityItems = true;
        let noteAdded: ActivityNote = null;
        if (note) {
          noteAdded = {
            kafkaId: data.noteGuid,
            body: note,
            createdBy: data.createdBy,
            createdByGuid: data.createdByGuid,
            createdDate: data.createdDate,
            functionalAreaGuid: data.functionalAreaGuid,
            lineItemGuid: data.lineItemGuid,
            workTicketGuid: data.workTicketGuid
          };
        }
        this.addItemToActivityItems(data.createdDate, noteAdded, data, null);
        this.toastService.open('Photo added! It may take a moment to process your photo.', 'success');
        this.ga.trackEvent('PhotoAdded', 'PhotoAdded');
      },
      err => {
        switch (err.status) {
          case 0: {
            // TODO: this is hacky. We need to figure out how to find whatever it is that is setting the status code to 413 in the network tab.
            this.toastService.open('Error 413: Photo is too large.', 'danger');
            break;
          }
          case 400: {
            // Bad model
            this.toastService.open('Error 400: Invalid photo format.', 'danger');
            break;
          }
          case 401:
          case 403: {
            // Unauthorized
            break;
          }
          default: {
            // Generic
            this.toastService.open('Error 500: Photo failed to post. Check your network connection.', 'danger');
            break;
          }
        }
      }
    );
  }
}