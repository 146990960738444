import { Directive, ElementRef, HostListener } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Directive({
  selector: 'input[occHours]'
})
export class HoursDirective {

  maxLimit: number = 12;
  minLimit: number = 0;
  toast: any;
  constructor(private _el: ElementRef, private toastCtrl: ToastController) { }

  @HostListener('input', ['$event']) async onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    if (this._el.nativeElement.value) {
      if (parseInt(this._el.nativeElement.value) > this.maxLimit || parseInt(this._el.nativeElement.value) < this.minLimit || (this._el.nativeElement.value.length ===2 && this._el.nativeElement.value == 0)) {
        this._el.nativeElement.value = '01';
        if(!this.toast){
          this.toast = await this.toastCtrl.create({
            message: 'Hours should be between 1 to 12',
            duration: 3000,
            position: 'top',
            color: "danger",
            cssClass: "toast-message"
          });
        
          this.toast.present();

          await this.toast.onDidDismiss();
          this.toast = null;
        }
      }
      if(this._el.nativeElement.value.length === 1 && this._el.nativeElement.value != 0) {
        this._el.nativeElement.value = '0' + this._el.nativeElement.value;
      }
      
      if(this._el.nativeElement.value.length > 2) {
        var hh = this._el.nativeElement.value;
        this._el.nativeElement.value = hh.replace(/^0+/, '');
      }
      else{
        this._el.nativeElement.value = this._el.nativeElement.value;
      }
    }
   
    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}

