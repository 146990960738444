import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ShowSetupService } from 'src/app/services/show-setup/show-setup.service';

@Component({
  selector: 'occ-vip-exhibitor-confirmation-popover',
  templateUrl: './vip-exhibitor-confirmation-popover.component.html',
  styleUrls: ['./vip-exhibitor-confirmation-popover.component.scss'],
})
export class VipExhibitorConfirmationPopoverComponent implements OnInit {

  isApiCalled: any = false;
  @Input() vipStatus: any;
  @Input() eventGuid: any;
  @Input() headerText: any;
  @Input() accountDetails: any;

  constructor(
    private popoverCtrl: PopoverController,
    private showSetupService: ShowSetupService,
  ) { }

  ngOnInit() { }

  updateVipStatus() {
    if (!this.isApiCalled) {
      this.isApiCalled = true;
      let requestData = {
        isVIP: this.vipStatus,
        showAccountGuid: this.accountDetails.kafkaId
      }
      this.showSetupService.updateVipStatus(this.eventGuid, requestData).subscribe(res => {
        this.isApiCalled = false;
        if (res)
          this.popoverCtrl.dismiss({ statusUpdated: true });
      })
    }
  }

  closePopover() {
    this.popoverCtrl.dismiss();
  }

}
