import { Pipe, PipeTransform } from '@angular/core';
import { FunctionalArea } from '../models/functional-area';

@Pipe({
	name: 'functionalAreaSearch',
})
export class FunctionalAreaSearchPipe implements PipeTransform {

	transform(functionalAreas: Array<FunctionalArea>, allFunctionalAreas: Array<FunctionalArea>, searchText: string, searchStatus = true): Array<FunctionalArea> {
		if (!functionalAreas) return [];
		if (!searchText) return functionalAreas;

		searchText = searchText.toLowerCase().trim();

		const results: Array<FunctionalArea> = [];
		const leftovers: Array<FunctionalArea> = [];

		// Name - Starts With
		functionalAreas.forEach(area => {
			if (area.name.toLowerCase().startsWith(searchText)) {
				results.push(area);
			} else {
				leftovers.push(area);
			}
		});

		// Parents Name - Starts With
		for (let i = 0; i < leftovers.length; i++) {
			const area = leftovers[i];
			if (area.parentFunctionalAreaGuid == null) {
				continue;
			}

			const parent = allFunctionalAreas.find(fa => fa.functionalAreaGuid === area.parentFunctionalAreaGuid);
			if (parent && parent.name.toLowerCase().startsWith(searchText)) {
				results.push(area);
				leftovers.splice(i, 1);
				i--;
			}
		}

		// GrandParents Name - Starts With
		for (let i = 0; i < leftovers.length; i++) {
			const area = leftovers[i];
			if (area.parentFunctionalAreaGuid == null) {
				continue;
			}

			const parent = allFunctionalAreas.find(fa => fa.functionalAreaGuid === area.parentFunctionalAreaGuid);
			if (!parent || parent.parentFunctionalAreaGuid == null) {
				continue;
			}

			const grandParent = allFunctionalAreas.find(gp => gp.functionalAreaGuid === parent.parentFunctionalAreaGuid);
			if (grandParent && grandParent.name.toLowerCase().startsWith(searchText)) {
				results.push(area);
				leftovers.splice(i, 1);
				i--;
			}
		}

		// Status - Starts With
		if (searchStatus) {
			for (let i = 0; i < leftovers.length; i++) {
				const area = leftovers[i];
				if (area.status.title.toLowerCase().startsWith(searchText)) {
					results.push(area);
					leftovers.splice(i, 1);
					i--;
				}
			}
		}

		return results;
	}
}
