import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BoothDTO } from 'src/app/models/booth-dto';
import { JReportDTO } from 'src/app/models/jreport-dto';
import { MaterialHandlingAgreementDto } from 'src/app/models/material-handling-agreement';
import { Show } from 'src/app/models/show';
import { environment } from 'src/environments/environment';
import { ToastService } from '../toast/toast.service';
import { map } from 'rxjs/operators';
import { KioskPrintRequestDTO } from 'src/app/models/kiosk-print-request-dto';

@Injectable({
  providedIn: 'root'
})
export class KioskService {
  url = environment.apiUrl;
  currentBooth: BoothDTO;

  linkAccountData: any;

  isKioskPrintDataLoaded: any;
  isShowClosed: boolean;

  allCountryCodes = [{
    display: "+1",
    value: 1,
    regExData: [/[0-9]/g, /[0-9]/g, /[0-9]/g, /[-]/g, /[0-9]/g, /[0-9]/g, /[0-9]/g, /[-]/g, /[0-9]/g, /[0-9]/g, /[0-9]/g, /[0-9]/g]
  }];
  defaultCountryCode = {
    display: "+1",
    value: 1,
    regExData: [/[0-9]/g, /[0-9]/g, /[0-9]/g, /[-]/g, /[0-9]/g, /[0-9]/g, /[0-9]/g, /[-]/g, /[0-9]/g, /[0-9]/g, /[0-9]/g, /[0-9]/g]
  };
  
  kioskBoothSearchSubject = new BehaviorSubject<BoothDTO[]>([]);
  mhaListSubject = new BehaviorSubject<MaterialHandlingAgreementDto[]>([]);
  currentShowName = new BehaviorSubject<Show>(null);
  carrierDataSubject = new BehaviorSubject<any[]>([]);
  
  constructor(public http: HttpClient,
    public toastService: ToastService) {
  }

  setCurrentBooth(currentBooth) {
    this.currentBooth = currentBooth;
  }

  getCurrentBooth() {
    return this.currentBooth;
  }

  getBoothSearchList(eventId: string, searchParam?: string, accountId?: string) {
    !searchParam ? searchParam = "" : "";
    this.http.get<BoothDTO[]>(`${this.url()}kioskusers/event/${eventId}/account/${accountId}/booth/search?query=${searchParam}`).subscribe(data => {
      this.kioskBoothSearchSubject.next(data);
    });
  }

  getMHAList(eventId: string, boothNumber: string) {
    this.http.get<MaterialHandlingAgreementDto[]>(`${this.url()}kioskusers/event/${eventId}/booth/${boothNumber}/MHA`).subscribe(data => {
      this.mhaListSubject.next(this.setZipCode(data));
    });
  }

  getFolShowName() {
    this.isShowClosed = false;
    this.http.get<Show>(`${this.url()}events/FOLShowName`)
      .subscribe(data => {
        if (data != null) {
          this.currentShowName.next(data);
          this.isShowClosed = this.getShowCloseStatus();
        }
      }, err => {
        if (err.status !== 401 && err.status !== 403) {
          this.toastService.open('There was an error to getting shows', 'danger');
        }
      });
  }

  getShippingLabelUrl(eventId: string, jreportUrl: string, numberOfCopies: number) {
    const jReport: JReportDTO = { uri: jreportUrl, NumberOfCopies:  numberOfCopies}
    const shippingLabelUrl = `${this.url()}kioskusers/event/${eventId}/shippinglabel`
    return this.http.post(shippingLabelUrl, jReport, { responseType: 'blob' as 'json' })
      .pipe(map(printData => {
        return printData
      }, err => {
        console.log(err);
        return err;
      }));
  }

  setZipCode(mhaData: MaterialHandlingAgreementDto[]){
    mhaData.forEach(mhaItem => {
      switch (mhaItem.country) {
        case "USA":
          if(mhaItem.postalCode && mhaItem.postalCode.length > 5 && !mhaItem.postalCode.includes("-")){
            mhaItem.postalCode =  mhaItem.postalCode.slice(0, 5) + "-" + mhaItem.postalCode.slice(5, mhaItem.postalCode.length);      
          }
          break;
        case "Canada":
          if(mhaItem.postalCode && !mhaItem.postalCode.includes(" ")){
            mhaItem.postalCode =  mhaItem.postalCode.slice(0, 3) + " " + mhaItem.postalCode.slice(3, mhaItem.postalCode.length);
          }
          break;
      
        default:
          break;
      }
    });
    return mhaData;
  }

  captureMHAPrintRequest(eventId: string, boothId: string, numberOfCopies: number, mhaId: number) {
    var emptyGuid = "00000000-0000-0000-0000-000000000000";
    var currentDate = new Date();
    const printReq: KioskPrintRequestDTO = { printDate: currentDate, printRequestType: "1", boothGuid: boothId, numberOfLabels: numberOfCopies, mhaId: mhaId, kioskName: null, mhaGuid: emptyGuid }
    const printReqUrl = `${this.url()}kioskaudit/event/${eventId}/KioskPrintRequest`
    return this.http.post<boolean>(printReqUrl, printReq)
      .pipe(map(printReqData => {
        return printReqData
      }, err => {
        console.log(err);
        return err;
      }));
  }

  verifyMobileNumber(mobileNumber) {
    const url = `${this.url()}notification/kioskusers/VerifyPhoneNo/${mobileNumber}`
    return this.http.get<any>(url);
  }

  getSendCode(mobileData: any, kafkaId: any = null) {
    const sendCodeUrl = `${this.url()}notification/kioskusers/account/${kafkaId}/SendSmsCode`
    return this.http.post<any>(sendCodeUrl, mobileData);
  }

  verifyCode(mobileData: any, accountKafkaId: any = null) {
    const sendCodeUrl = `${this.url()}notification/kioskusers/account/${accountKafkaId}/ValidateSmsCode`
    return this.http.post<any>(sendCodeUrl, mobileData);
  }

  verifyZipCode(eventId, zipCode) {
    let zipCodeValue = zipCode.trim().substring(0, 5);
    return this.http.get<[]>(`${this.url()}kioskusers/event/${eventId}/verifyZipCode/${zipCodeValue}`);
  }

  linkAccountToMobileNumber(eventId, accountData) {
    const url = `${this.url()}notification/kioskusers/account/${eventId}/AddAccountContact`;
    return this.http.post<any>(url, accountData);
  }

  searchAccount(kafkaId, searchQuery) {
    return this.http.get<[]>(`${this.url()}kioskusers/event/${kafkaId}/account/search?query=${searchQuery}`);
  }

  getCarrierList(){
    this.http.get<any[]>(`${this.url()}kioskusers/event/${this.currentShowName.getValue()?.kafkaId}/carriers`)
		.subscribe(data => {
			this.carrierDataSubject.next(data);
		}, err => {
			if (err.status !== 401 && err.status !== 403) {
				this.toastService.open('There was an error getting carrier data', 'danger');
			}
		});
  }

  getAccountInfo(showAccountId) {
    const url = `${this.url()}events/${this.currentShowName.getValue()?.kafkaId}/accountInfo/${showAccountId}`;
    return this.http.get<any>(url);
  }

  getShowCloseStatus(){
    let showData = this.currentShowName.getValue();
    if(showData && showData.closeDate){
      let showCloseDateValue = new Date(showData.closeDate).getTime();
      let currentDateValue = new Date().getTime();
      return showCloseDateValue < currentDateValue ? true : false;
    }else{
      return false;
    }
  }
  
  getSelfCheckInShow() {
    var emptyGuid = "00000000-0000-0000-0000-000000000000";
    return this.http.get<Show[]>(`${this.url()}kioskusers/event/${emptyGuid}`);
  }
}
