import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';
import { FilterHelper } from 'src/app/helpers/filter-helper';

@Component({
  selector: 'occ-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent {
	@Output() closeFilter = new EventEmitter();
	@Output() filterStatus = new EventEmitter();
	@Output() filterOwner = new EventEmitter();
	@Input() isFunctionalAreasPage: boolean;
	@Input() isWorkticketPage: boolean;

	currentPage: string;

	constructor(
		public appBrowserHelper: AppBrowserHelper,
		public filterHelper: FilterHelper,
	) {}

	ngOnInit(): void {
		this.currentPage = this.isWorkticketPage ? 'workticketPage' : 'selection';
	}

	hideFilter(): void {
		this.closeFilter.emit();
	}

	showPage(page): void {
		this.currentPage = page;
	}
}

