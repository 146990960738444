export class SocialOptions {
	public message: string;
	public subject: string;
	public files: [string];
	public url: string;
	public chooserTitle: string;
	public appPackageName: string;
	public azureUri: string;
	public photoName: string;
	public azureToken: string;
	public picId: string;
}
