import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';
import { KioskAuthGuard } from './helpers/kiosk-auth-guard';
import { LoginAuthGuard } from './helpers/login-auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  { path: 'login', 
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canActivate:[LoginAuthGuard]
   },
   { path: 'login/:qrcode/self-checkin', 
   loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
   canActivate:[LoginAuthGuard]
  },
  { path: 'events', 
    loadChildren: () => import('./pages/events/events.module').then( m => m.EventsPageModule),
    canActivate:[AuthGuard] 
  },
  {
    path: 'more-options',
    loadChildren: () => import('./pages/more-options/more-options.module').then( m => m.MoreOptionsPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/worktickets',
    loadChildren: () => import('./pages/worktickets/worktickets.module').then( m => m.WorkticketsPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'notification-preferences',
    loadChildren: () => import('./pages/notification-preferences/notification-preferences.module').then( m => m.NotificationPreferencesPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/accounts/:accountId/report',
    loadChildren: () => import('./pages/report/report.module').then( m => m.ReportPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/report',
    loadChildren: () => import('./pages/report/report.module').then( m => m.ReportPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'ops-list',
    loadChildren: () => import('./pages/ops-list/ops-list.module').then( m => m.OpsListPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/worktickets/:workTicketId/workticket-details',
    loadChildren: () => import('./pages/workticket-details/workticket-details.module').then( m => m.WorkticketDetailsPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/accounts/:accountId/dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/workticket-dashboard',
    loadChildren: () => import('./pages/workticket-dashboard/workticket-dashboard.module').then( m => m.WorkticketDashboardPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/accounts',
    loadChildren: () => import('./pages/accounts/accounts.module').then( m => m.AccountsPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/accounts/:accountId/timeline',
    loadChildren: () => import('./pages/timeline/timeline.module').then( m => m.TimelinePageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/booths/:boothId/booth-timeline',
    loadChildren: () => import('./pages/timeline/timeline.module').then( m => m.TimelinePageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/work-ticket-timeline',
    loadChildren: () => import('./pages/timeline/timeline.module').then( m => m.TimelinePageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/accounts/:accountId/show-hide-fa',
    loadChildren: () => import('./pages/show-hide-functional-area/show-hide-functional-area.module').then( m => m.ShowHideFunctionalAreaPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/accounts/:accountId/changeorders',
    loadChildren: () => import('./pages/change-order/change-order.module').then( m => m.ChangeOrderPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/accounts/:accountId/changeorders/:changeOrderId/change-log-details',
    loadChildren: () => import('./pages/change-log-details/change-log-details.module').then( m => m.ChangeLogDetailsPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/workticket-search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/accounts/:accountId/search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/booth/:boothId/booth-search',
    loadChildren: () => import('./pages/booth-search/booth-search.module').then( m => m.BoothSearchPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/accounts/:accountId/functional-areas',
    loadChildren: () => import('./pages/functional-areas/functional-areas.module').then( m => m.FunctionalAreasPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/accounts/:accountId/show-hide-fas',
    loadChildren: () => import('./pages/show-hide-functional-area/show-hide-functional-area.module').then( m => m.ShowHideFunctionalAreaPageModule),
    canActivate:[AuthGuard]
  },
  {
    path:'events/:eventId/accounts/:accountId/functional-areas/:functionalAreaId/functional-area-details',
    loadChildren: () => import('./pages/functional-area-details/functional-area-details.module').then( m => m.FunctionalAreaDetailsPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/accounts/:accountId/functional-areas/:functionalAreaId/line-items/:lineItemId/line-item-details',
    loadChildren: () => import('./pages/line-item-details/line-item-details.module').then( m => m.LineItemDetailsPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/booths/:boothId/booth-lineitems/:boothLineItemId/booth-lineitem-details',
    loadChildren: () => import('./pages/line-item-details/line-item-details.module').then( m => m.LineItemDetailsPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/booths/:boothId/sales-service-requests/:serviceRequestGUID/service-request-details/:tab',
    loadChildren: () => import('./pages/service-request-details/service-request-details.module').then( m => m.ServiceRequestDetailsPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/workticket-details/:workTicketGuid/line-items/:lineItemId/workticket-line-item-details',
    loadChildren: () => import('./pages/line-item-details/line-item-details.module').then( m => m.LineItemDetailsPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/booths/:boothId/booth-details',
    loadChildren: () => import('./pages/booth-details/booth-details.module').then( m => m.BoothDetailsPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/booths/:boothId/changeorders',
    loadChildren: () => import('./pages/booth-change-order/booth-change-order.module').then( m => m.BoothChangeOrderPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/booths/:boothId/changeorders/:changeOrderId/change-log-details',
    loadChildren: () => import('./pages/change-log-details/change-log-details.module').then( m => m.ChangeLogDetailsPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'download',
    loadChildren: () => import('./pages/download/download.module').then( m => m.DownloadPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'kiosk-home',
    loadChildren: () => import('./pages/kiosk/kiosk-home/kiosk-home.module').then( m => m.KioskHomePageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'kiosk-search',
    loadChildren: () => import('./pages/kiosk/kiosk-search/kiosk-search.module').then( m => m.KioskSearchPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: ':boothGuid/:boothNumber/kiosk-mha-list',
    loadChildren: () => import('./pages/kiosk/kiosk-mha-list/kiosk-mha-list.module').then( m => m.KioskMhaListPageModule),
    canActivate:[KioskAuthGuard]
  },
  {
    path: 'events/:eventId/ops-user-line-item-list',
    loadChildren: () => import('./pages/ops-user-line-item-list/ops-user-line-item-list.module').then( m => m.OpsUserLineItemListPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/es-booths',
    loadChildren: () => import('./pages/exhibitor-service/es-booths/es-booths.module').then( m => m.EsBoothsPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/es-booths/:boothGuId/exhibitor-booth-details',
    loadChildren: () => import('./pages/exhibitor-service/exhibitor-booth-details/exhibitor-booth-details.module').then( m => m.ExhibitorBoothDetailsPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'kiosk-authorization',
    loadChildren: () => import('./pages/kiosk/kiosk-authorization/kiosk-authorization.module').then( m => m.KioskAuthorizationPageModule),
    canActivate:[KioskAuthGuard]
  },
  
  {
    path: ':boothGuid/:boothNumber/kiosk-mha-options',
    loadChildren: () => import('./pages/kiosk/kiosk-mha-options/kiosk-mha-options.module').then( m => m.KioskMhaOptionsPageModule),
    canActivate:[KioskAuthGuard]
  },
  {
    path: ':boothGuid/:boothNumber/kiosk-create-mha',
    loadChildren: () => import('./pages/kiosk/kiosk-create-mha/kiosk-create-mha.module').then( m => m.KioskCreateMHAPageModule),
    canActivate:[KioskAuthGuard]
  },
  {
    path: ':boothGuid/:boothNumber/kiosk-update-mha/:MHAGuid',
    loadChildren: () => import('./pages/kiosk/kiosk-create-mha/kiosk-create-mha.module').then( m => m.KioskCreateMHAPageModule),
    canActivate:[KioskAuthGuard]
  },
  {
    path: 'kiosk-account-search',
    loadChildren: () => import('./pages/kiosk/account-search/account-search.module').then(m => m.AccountSearchPageModule)
  },
  {
    path: 'kiosk-link-account',
    loadChildren: () => import('./pages/kiosk/kiosk-link-account/kiosk-link-account.module').then(m => m.KioskLinkAccountPageModule)
  },
  {
    path: 'kiosk-booth-info',
    loadChildren: () => import('./pages/kiosk/kiosk-booth-info/kiosk-booth-info.module').then( m => m.KioskBoothInfoPageModule),
    canActivate:[KioskAuthGuard]
  },
  {
    path: ':boothGuid/:boothNumber/kiosk-outbound-shipping-labels',
    loadChildren: () => import('./pages/kiosk/kiosk-outbound-shipping-labels/kiosk-outbound-shipping-labels.module').then( m => m.KioskOutboundShippingLabelsPageModule),
    canActivate:[KioskAuthGuard]
  },
  {
    path: 'kiosk-shipping-option-mobile',
    loadChildren: () => import('./pages/kiosk/kiosk-shipping-option-mobile/kiosk-shipping-option-mobile.module').then( m => m.KioskShippingOptionMobilePageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'kiosk-events',
    loadChildren: () => import('./pages/kiosk/self-checkin/events/events.module').then( m => m.KioskEventsPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'events/:eventId/ops-service-requests/:serviceRequestGUID/service-request-details/:tab',
    loadChildren: () => import('./pages/service-request-details/service-request-details.module').then( m => m.ServiceRequestDetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'events/:eventId/booths/:boothGUID/service-requests/:serviceRequestGUID/service-request-details/:tab',
    loadChildren: () => import('./pages/service-request-details/service-request-details.module').then( m => m.ServiceRequestDetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'events/:eventId/es-booths/:boothId/line-items/:lineItemId/line-item-details',
    loadChildren: () => import('./pages/lineItem-detail-page/lineItem-detail-page.module').then(m => m.LineItemDetailModule),
    canActivate: [AuthGuard]
  },
  {
    path: ':unsubscribetoken/un',
    loadChildren: () => import('./pages/exhibitor-service/unsubscribe-notification/unsubscribe-notification.module').then( m => m.UnsubscribeNotificationPageModule)
  },
  {
    path: 'events/:eventId/sales-service-requests/:serviceRequestGUID/service-request-details/:tab',
    loadChildren: () => import('./pages/service-request-details/service-request-details.module').then( m => m.ServiceRequestDetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'events/:eventId/setup',
    loadChildren: () => import('./pages/show-setup/show-setup/show-setup.module').then( m => m.ShowSetupPageModule)
  },
  {
    path: 'events/:eventId/setup/show-setup/area',
    loadChildren: () => import('./pages/show-setup/area-setup/area-setup.module').then( m => m.AreaSetupPageModule)
  },
  {
    path: 'events/:eventId/setup/show-setup/freight',
    loadChildren: () => import('./pages/show-setup/freight-setup/freight-setup.module').then( m => m.FreightSetupPageModule)
  },
  {
    path: 'kiosk-landing',
    loadChildren: () => import('./pages/kiosk/kiosk-landing-page/kiosk-landing-page.module').then( m => m.KioskLandingPagePageModule),
    canActivate:[KioskAuthGuard]
  },

  {
    path: 'error-log',
    loadChildren: () => import('./pages/error-log/error-log.module').then( m => m.ErrorLogPageModule),
    canActivate: [AuthGuard]
  },
  
  //Don't add route below this line
  // Redirect to default route for unknown route
  { path: '**', redirectTo: 'events', pathMatch: 'full'}

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
