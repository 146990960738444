import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SetOpsUserService {

	url = environment.apiUrl;

	constructor(public http: HttpClient) {}

	get() {
		this.http.get<any>(`${this.url()}set-ops-user`)
		.subscribe(data => {
			console.log('successfully set user type', data);
		}, err => {
			console.log('error setting user type', err);
		});
	}
}
