import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { StatusHelper } from 'src/app/helpers/status-helper';
import { CollaborationLineItemDTO } from 'src/app/models/collaboration-line-item-dto';
import { FunctionalArea } from 'src/app/models/functional-area';
import { ShowBooth } from 'src/app/models/show-booth-dto';
import { Status } from 'src/app/models/status';
import { WorkTicketDTO } from 'src/app/models/work-ticket-dto';
import { BoothServiceProvider } from 'src/app/services/booth/booth.service';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';
import { FunctionalAreaService } from 'src/app/services/functional-area/functional-area.service';
import { LineItemService } from 'src/app/services/line-item/line-item.service';
import { ToastService } from 'src/app/services/toast/toast.service';
// import { StatusWarningPopoverComponent } from '../../status-warning-popover/status-warning-popover.component';
import * as _ from 'lodash';
import { EsBoothDetailsItems } from 'src/app/models/es-booth-details-items';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';

@Component({
	selector: 'occ-exhibitor-status-change',
	templateUrl: './exhibitor-status-change.component.html',
	styleUrls: ['./exhibitor-status-change.component.scss'],
})
export class ExhibitorStatusChangeComponent implements OnInit {
	statuses: Array<Status>;
	@Input() functionalArea?: FunctionalArea;
	@Input() lineItem?: CollaborationLineItemDTO;
	@Input() boothData?: ShowBooth;
	@Input() boothLineItem?: CollaborationLineItemDTO;
	@Input() workTicket?: WorkTicketDTO;
	@Input() workTicketLineItem?: CollaborationLineItemDTO;
	@Input() serviceRequest?: EsBoothDetailsItems;
	@Input() serviceBoothData: ShowBooth;
	eventId: string;
	accountId: string;
	isFromDetailsPage: boolean;
	isFromBoothDetailsPage: boolean;
	isFromWTDetailsPage: boolean;
	fromSearch: boolean;
	functionalAreaId: string;

	constructor(
		public navParams: NavParams,
		public funcSvc: FunctionalAreaService,
		public lineItemService: LineItemService,
		public modalCtrl: ModalController,
		public dataSvc: DataRefreshService,
		public toastSvc: ToastService,
		public popoverCtrl: PopoverController,
		public boothService: BoothServiceProvider,
		private ga: GoogleAnalytics
	) {
		this.eventId = this.navParams.data.eventId;
		this.accountId = this.dataSvc.showAccountActive;
		this.boothData = navParams.data.boothData;
		this.boothLineItem = navParams.data.boothLineItem;
		this.serviceBoothData = navParams.data.serviceBoothData;
		this.serviceRequest = navParams.data.serviceRequest;
		this.functionalArea = navParams.data.area || this.funcSvc.currentFuncAreaSubject.getValue();
		// get the functional area id from the functional area if it exists. If not, get it from the nav params (in the case of deep link to lidetails)
		this.functionalAreaId = this.functionalArea ? this.functionalArea.functionalAreaGuid : this.navParams.data.functionalAreaId;
		if (!this.functionalArea && this.functionalAreaId) {
			this.funcSvc.activateFunctionalArea(this.functionalAreaId);
		}
		
		if(this.serviceRequest){
			this.statuses = _.values(StatusHelper.LineItemStatuses);
		} else{
			if (this.boothData || this.boothLineItem || this.serviceBoothData) {
				this.statuses = _.values(StatusHelper.LineItemStatuses)
			} else {
				this.statuses = this.lineItem ? StatusHelper.LineItemStatuses : this.functionalArea ? StatusHelper.FunctionalAreaStatuses : StatusHelper.WorkTicketStatuses;
			}
		}
		
		this.isFromDetailsPage = this.navParams.data.isFromDetailsPage;
		this.isFromWTDetailsPage = this.navParams.data.isFromWTDetailsPage;
		this.fromSearch = this.navParams.data.fromSearch;
		if (!this.isFromDetailsPage && !this.isFromWTDetailsPage) {
			this.lineItemService.refresh(this.eventId, this.accountId, this.functionalAreaId).subscribe;
		}
	}

	ngOnInit() { }
	async changeStatus(newStatus: Status) {
		const isPopupOpened = await this.popoverCtrl.getTop();
		if (isPopupOpened) {
			this.popoverCtrl.dismiss({
				'newStatus': newStatus
			});
		}

		if (this.boothLineItem || this.boothData) {
			if (!this.boothLineItem) {
				// if (this.boothService.currentBoothLineItems.getValue().find(x => x.status === StatusHelper.ServiceRequestOptions.ServiceOnHold) && (newStatus === StatusHelper.ServiceRequestOptions.ServiceComplete || newStatus === StatusHelper.ServiceRequestOptions.ServiceComplete || newStatus === StatusHelper.ServiceRequestOptions.ServiceInProgress)) {
				// 	this.presentBoothPopover(this.dataSvc.eventActive, this.dataSvc.showAccountActive, this.boothService.activeBooth.getValue().boothGUID, newStatus, this.boothService.currentBoothLineItems, this.boothService.currentBoothLineItems.getValue());
				// } else {
				// }
				this.boothService.setBoothStatus(this.eventId, this.boothData.boothGUID, this.boothLineItem, newStatus.id);
			} else {
				this.boothService.setBoothStatus(this.eventId, this.boothData.boothGUID, this.boothLineItem, newStatus.id);
			}
		}
		if (this.serviceRequest) {
			this.boothService.setServiceRequestStatus(this.eventId, this.serviceRequest, newStatus.id);	
			this.ga.trackEvent('ServiceRequestStatusChanged', newStatus?.title);
		}
	}
	
	// async presentBoothPopover(eventId: string, accountId: string, boothId: string, newStatus: Status, lineItemsSubject: BehaviorSubject<CollaborationLineItemDTO[]>, lineItems?: CollaborationLineItemDTO[]) {
	// 	const popover = await this.popoverCtrl.create({
	// 		component: StatusWarningPopoverComponent,
	// 		componentProps: { eventId: eventId, accountId: accountId, boothId: boothId, newStatus: newStatus, lineItemsSubject: lineItemsSubject, lineItems: lineItems }
	// 	});
	// 	await popover.present();
	// }
}

