import { Status } from './status';

export class CollaborationLineItemDTO {
	public kafkaId?: string;
	public functionalAreaGuid?: string;
	public description?: string;
	public vendorName?: string;
	public locationInfo?: string;
	public status?: Status;
	public unitOfMeasure?: string;
	public quantity?: number;
	public passportId?: number;
	public partNumber?: number;
	public detailDescription?: string;
	public ticketNumber?: number;
	public setupDate?: Date;
	public endDate?: Date;
	public productionStartDate?: Date;
	public productionEndDate?: Date;
	public teardownStartDate?: Date;
	public teardownEndDate?: Date;
	public statusDate?: Date;
	public booth?: string;
	public functionalAreaStatusEnum?: number;
	public currentStatus?: number;
	public totalLineItems?: number;
	public completedLineItems?: number;
	public functionalAreaName?: string;
	public category?: string;
	public displayName?: string;
	public vendorComments?: string;
	public ticketComments?: string;
	public revisionComments?: string;
	public attachments?: string;
	public hasPhotos?: boolean;
	public showAccountGuid?: string;
	public facility?: string;
	public partSelections?: Array<PartSelectionDto>;
	public isInvoiced?: boolean; 
	public isFreightOrder?: boolean; 
	public isShowClosed?: boolean;
	public lineItemStatusId?: number;
	public men?: number;
	public hours?: number;
  	public invoiceComments?: string;
  	public showFacilityGuid?: string;
  	public showLocationGuid?: string;
  	public isVisible?: boolean;
	public statusCode?: number;
	public statusCodeDescription?: string;
	public workTicketGuid?: string;
	public partGroup?: string;
	public accountName?: string;
	public showAccountTypeId?: number;
	public tssOrderId?: number;
	public partGroupDescription: string;
	public subGroup?: string;
	public partSubGroup?: string;
    public notes?: string;
	public isLIChecked?: boolean;
}

export class PartSelectionDto {
	public materialSubTypeDescription: string;
	public partDescription: string;
	public basePartId: number;
	public passportPartId: number;
}
