import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuController, ModalController } from '@ionic/angular';
import { BoothDTO } from 'src/app/models/booth-dto';
import { CreateMhaService } from 'src/app/services/create-mha/create-mha.service';
import { KioskService } from 'src/app/services/kiosk/kiosk.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { Storage } from '@ionic/storage';
import { AcknowledgeFreightPackageComponent } from '../kiosk/acknowledge-freight-package/acknowledge-freight-package.component';
import { AuthTokenHelper } from 'src/app/helpers/auth-token-helper';
import { ExhibitorAccountDetailsService } from 'src/app/services/exhibitor-account-details/exhibitor-account-details.service';
import { takeWhile } from 'rxjs/operators';
import { ShowBooth } from 'src/app/models/show-booth-dto';
import { EventService } from 'src/app/services/event/event.service';
import { MHASubmitSuccessComponent } from '../kiosk/mhasubmit-success/mhasubmit-success.component';
import { MOPNotFoundComponent } from '../kiosk/mop-not-found/mop-not-found.component';

@Component({
  selector: 'occ-create-mha',
  templateUrl: './create-mha.component.html',
  styleUrls: ['./create-mha.component.scss'],
})
export class CreateMHAComponent implements OnInit, OnDestroy{

  @Input() eventGUID?: any;
  @Input() boothData?: ShowBooth;
  @Input() isMHAUpdate?: boolean;
  @Input() MHAGuid: string;
  @Input() isMHASubmitted?: boolean;
  
  currentSectionId: number;
  currentBoothId: string;
  toastText: string = "Complete the required sections to save a draft";
  eventGuid: string;
  boothNumber: string;
  currentBooth: BoothDTO = {};
  accountInfo: any = {};
  isToastVisible: boolean = false;
  isShippingFormCompleted: boolean = false;
  isCarrierFormCompleted: boolean = false;
  isBillingFormCompleted: boolean = false;
  isFreightFormCompleted: boolean = false;
  isCreateMHAInProgress: boolean = false;
  toastType: any = 'info';
  showConfirmationPopup: any;
  confirmationPopupType: any;
  isKioskUser: boolean = false;
  subscribe = true;

  constructor(
    public router: Router,
    public kioskService: KioskService,
    private menu: MenuController,
    private storage: Storage,
    private modalCtrl: ModalController,
    private activatedRoute: ActivatedRoute,
    public createMHAService: CreateMhaService,
    public toastService: ToastService,
    public tokenHelper: AuthTokenHelper,
    private exhibitorAccountDetailsSvc : ExhibitorAccountDetailsService,
    public eventSvc: EventService
  ) { }
 
  async ngOnInit() {
    this.isKioskUser = await this.tokenHelper.IsKioskUser();

    this.createMHAService.resetMhaFields();
    this.currentSectionId = 1;
    this.currentBoothId = this.activatedRoute.snapshot.paramMap.get('boothGuid');
    this.createMHAService.boothNumber = this.boothNumber;
    this.createMHAService.currentBoothId = this.currentBoothId;
    this.createMHAService.isCreateMHAInProgress = false;
    this.isShippingFormCompleted = false;
    this.isCarrierFormCompleted = false;
    this.isBillingFormCompleted = false;
    this.createMHAService.currentMHADataSubject.next(null);
    this.createMHAService.isMHASubmitted = false;

    if (this.MHAGuid) {
      this.createMHAService.mhaGUID = this.MHAGuid;
      this.isShippingFormCompleted = true;
      this.isCarrierFormCompleted = true;
      this.isBillingFormCompleted = true;
    }

    if(this.isKioskUser){
      this.MHAGuid = this.activatedRoute.snapshot.paramMap.get('MHAGuid');
      this.boothNumber = this.activatedRoute.snapshot.paramMap.get('boothNumber');
      this.isMHASubmitted = this.activatedRoute.snapshot.paramMap.get('isMHASubmitted') == 'true' ? true : false;
      await this.storage.get('kiosk-account-info').then((accountData) => {
        if (accountData != null) {
          this.accountInfo = JSON.parse(accountData);
          this.createMHAService.accountInfo = this.accountInfo ? this.accountInfo : {};
        }
      });
  
      this.menu.enable(false);
      this.currentBooth = this.kioskService.getCurrentBooth();
      this.kioskService.currentShowName.subscribe(showData => {
        if (showData != null) {
          this.eventGuid = showData.kafkaId;
          if (this.router.url.includes('kiosk-update-mha')) {
            this.createMHAService.getSelectedMHAData(this.eventGuid, this.currentBoothId, this.MHAGuid).subscribe((mhaData) => {
              this.currentSectionId = mhaData?.mha?.totalWt ? 5 : 4;
              if (this.currentSectionId != 5) {
                this.toastType = 'info';
                this.toastText = "Complete the required sections to save a draft";
                this.showToast();
              }
            });
          } else {
            this.currentSectionId = 1;
            if (this.currentSectionId != 5) {
              this.toastType = 'info';
              this.toastText = "Complete the required sections to save a draft";
              this.showToast();
            }
          }
        }
      });
    }else{
      this.eventGuid = this.eventGUID;
      this.boothNumber = this.boothData.boothName;

      this.currentBooth = <BoothDTO>{};
      this.currentBooth.boothNumber = this.boothData.boothName;
      this.currentBooth.name = this.boothData.showAccountName;
      this.currentBooth.kafkaId = this.boothData.boothGUID;
      this.currentBooth.usePrimaryCardInd = this.boothData.usePrimaryCardInd;
      this.currentBoothId = this.boothData.boothGUID;

      this.exhibitorAccountDetailsSvc.getExhibitAccountDetails(this.eventGUID, this.boothData?.passportShowAccountId);   
      this.exhibitorAccountDetailsSvc.exhibitorAccountDetails.pipe(takeWhile(() => this.subscribe)).subscribe(exhibitAccount => {
        this.accountInfo = exhibitAccount;
        this.accountInfo.accountGuid = this.boothData.accountGuid;
        this.createMHAService.accountInfo = this.accountInfo ? this.accountInfo : {};
      });

      this.eventSvc.currentEvent.pipe(takeWhile(() => this.subscribe)).subscribe(showData => {
        if (showData != null) {
          this.eventGuid = showData.kafkaId;
          this.kioskService.currentShowName.next(showData);
          if (this.isMHAUpdate) {
            this.createMHAService.getSelectedMHAData(this.eventGuid, this.currentBoothId, this.MHAGuid).subscribe((mhaData) => {
              this.currentSectionId = mhaData?.mha?.totalWt ? 5 : 4;
              if (this.currentSectionId != 5) {
                this.toastType = 'info';
                this.toastText = "Complete the required sections to save a draft";
                this.showToast();
              }
            });
          } else {
            this.currentSectionId = 1;
            if (this.currentSectionId != 5) {
              this.toastType = 'info';
              this.toastText = "Complete the required sections to save a draft";
              this.showToast();
            }
          }
        }
      });
    }
  }

  async setCurrentSection(currentSectionId) {
    this.createMHAService.showErrorsForCarrier = false;
    switch (currentSectionId) {
      case 2:
        if (!this.isShippingFormCompleted)
          return;
        break;
      case 3:
        if (!this.isCarrierFormCompleted)
          return;
        break;
      case 4:
        if (!this.isBillingFormCompleted)
          return;
        break;
      case 5:
        if (!this.createMHAService.createMhaData.freight.totalWeight) {
          return;
        }
        break;
      default:
        break;
    }

    if (!this.MHAGuid) {
      if (this.currentSectionId == 1) {
        if (this.isShippingFormCompleted && !this.createMHAService.isShippingFormValid) {
          this.showInvalidFormToast();
          return;
        }

        let res = await this.createMHAService.validateZipCode(this.eventGuid, 'shipping');
        if (!res) {
          this.showInvalidFormToast("Postal Code is invalid for the selected state");
          return;
        }

        if (currentSectionId > this.currentSectionId && currentSectionId != 2) {
          if (this.isCarrierFormCompleted && !this.createMHAService.isCarrierFormValid) {
            this.showInvalidFormToast();
            this.createMHAService.showErrorsForCarrier = true;
            this.currentSectionId = 2;
            return;
          }
        }

      }

      if (this.currentSectionId == 2 && currentSectionId > this.currentSectionId) {
        if (this.isCarrierFormCompleted && !this.createMHAService.isCarrierFormValid) {
          this.showInvalidFormToast();
          this.createMHAService.showErrorsForCarrier = true;
          return;
        }
      }
    }

    this.currentSectionId = currentSectionId;
    if (!this.MHAGuid) {
      if (this.currentSectionId == 4) {
        this.toastType = 'info';
        this.toastText = "Draft saved! Complete to save time later.";
      } else {
        this.toastType = 'info';
        this.toastText = "Complete the required sections to save a draft";
      }

      this.currentSectionId != 5 ? this.showToast() : "";
    } else {
      if (this.currentSectionId == 4) {
        this.toastType = 'info';
        this.toastText = "Complete the required sections to save a draft";
        this.showToast();
      }
    }
  }

  onMhaCreationCancel() {
    if(this.isKioskUser){
      this.router.navigate([this.currentBoothId, this.boothNumber, 'kiosk-outbound-shipping-labels'], { replaceUrl: true });
    }else{
      this.modalCtrl.dismiss();
    }
  }

  loadPreviousMHASection(preveiousSectionId) {
    this.setSection(preveiousSectionId);
  }

  loadNextMHASection(nextSectionId: number) {
    if (this.createMHAService.isMHASubmitted) {
      this.setSection(nextSectionId);
      return;
    }
    if (nextSectionId == 4) {
      if (!this.createMHAService.isCreateMHAInProgress) {
        if (this.MHAGuid) {
          this.setSection(nextSectionId);
        } else {
          this.createMHAService.isCreateMHAInProgress = true;
          this.createMHAService.createMHA(this.eventGuid, this.currentBoothId).subscribe((createMHAResponse: any) => {
            if (createMHAResponse && createMHAResponse.status == "success") {
              this.MHAGuid = createMHAResponse.kafkaId;
              this.createMHAService.mhaGUID = this.MHAGuid;
              this.createMHAService.mhaId = createMHAResponse.mhaId;
              this.showConfirmationPopup = true;
              this.confirmationPopupType = 'save-draft';
            } else {
              this.toastService.open('There was an error while creating Outbound Shipping paperwork', 'danger');
            }
            this.createMHAService.isCreateMHAInProgress = false;
          }, error => {
            this.toastService.open('There was an error while creating Outbound Shipping paperwork', 'danger');
            this.createMHAService.isCreateMHAInProgress = false;
          });
        }
      }
    } else if (nextSectionId == 5) {
      if (!this.createMHAService.isCreateMHAInProgress) {
        this.createMHAService.isCreateMHAInProgress = true;
        this.createMHAService.updateMHA(this.eventGuid, this.MHAGuid).subscribe((updateMHAResponse: any) => {
          if (updateMHAResponse && updateMHAResponse.status == "success") {
            this.setSection(nextSectionId);
          } else {
            this.toastService.open('There was an error while updating Outbound Shipping paperwork', 'danger');
          }
          this.createMHAService.isCreateMHAInProgress = false;
        }, error => {
          this.toastService.open('There was an error while updating Outbound Shipping paperwork', 'danger');
          this.createMHAService.isCreateMHAInProgress = false;
        });
      }
    } else {
      this.setSection(nextSectionId);
    }
  }

  setSection(nextSectionId: number) {
    this.currentSectionId = nextSectionId;
    switch (this.currentSectionId) {
      case 2:
        this.isShippingFormCompleted = true;
        this.createMHAService.isShippingFormCompleted = true;
        break;
      case 3:
        this.isCarrierFormCompleted = true;
        this.createMHAService.isCarrierFormCompleted = true;
        break;
      case 4:
        this.isBillingFormCompleted = true;
        break;
      default:
        break;
    }
    this.setCurrentSection(this.currentSectionId);
  }

  showToast() {
    if (this.createMHAService.isMHASubmitted) {
      return;
    }
    setTimeout(() => {
      this.isToastVisible = true;
      setTimeout(() => {
        this.isToastVisible = false;
      }, 4000);
    }, 500);
  }

  showInvalidFormToast(toastMessage = null) {
    this.toastText = toastMessage ? toastMessage : "Required fields must be completed to continue";
    this.toastType = 'warning';
    this.showToast();
  }

  closeDraftToast() {
    this.isToastVisible = false;
  }

  async openConfirmationPopup() {
    if(this.isKioskUser){
      this.confirmationPopupType = 'confirm-prompt';
      this.showConfirmationPopup = true;
    }else{
      let isShowClosed = this.kioskService.getShowCloseStatus();
      if (!isShowClosed) {
        this.closeConfirmationPrompt({
          notify: false
        }, false);
      } else if (isShowClosed && this.currentBooth?.usePrimaryCardInd != 'Y') {
        const mopNotFoundModal = await this.modalCtrl.create({
          component: MOPNotFoundComponent,
          cssClass: 'mop-not-found-modal',
          backdropDismiss: false
        });
        await mopNotFoundModal.present();
        const { data } = await mopNotFoundModal.onDidDismiss();
        if (data) {
          this.closeConfirmationPrompt({
            notify: false
          }, false);
        }
      } else {
        this.closeConfirmationPrompt({
          notify: false
        }, true);
      }
    }
  }

  async openPrintModal() {
    const freightAckModal = await this.modalCtrl.create({
      component: AcknowledgeFreightPackageComponent,
      cssClass: 'freight-acknowledge-modal',
      backdropDismiss: false,
      componentProps: {
        isDraft: true,
        isAcknowledgementVisible: false,
        isPrintViewVisible: true
      }
    });
    await freightAckModal.present();
    const { data } = await freightAckModal.onDidDismiss();
  }

  closeConfirmationPrompt(data, isSubmit?: boolean) {
    this.showConfirmationPopup = false;
    if (data) {
      this.createMHAService.isCreateMHAInProgress = true;
      this.createMHAService.updateMHA(this.eventGuid, this.MHAGuid, isSubmit, data.notify).subscribe(async (res: any) => {
        this.createMHAService.isCreateMHAInProgress = false;
        if (res.status == 'success') {
          if(this.isKioskUser){
            this.openPrintModal();
          }else{
            // Below implementation is for Exhibit Service User created MHA
            const mhaSubmitSuccessModal = await this.modalCtrl.create({
              id: 'mha-submit-success',
              component: MHASubmitSuccessComponent,
              cssClass: 'mha-submit-sucess-modal',
              backdropDismiss: false,
              componentProps:{
                isDraft: isSubmit ? false : true
              }
            });
            await mhaSubmitSuccessModal.present();
            mhaSubmitSuccessModal.onDidDismiss().then((codeData) => {
              if(codeData.data){
                switch (codeData.data.actionCode) {                
                  case 1:
                    setTimeout(() => {
                      this.modalCtrl.dismiss();
                    }, 200);
                    break;
                
                  case 2:
                    // this.showModal({
                    //   isAcknowledgementVisible: false,
                    //   isPrintViewVisible: true
                    // });
                    break;
                
                  default:
                    setTimeout(() => {
                      this.modalCtrl.dismiss();
                    }, 200);
                    // this.router.navigate([this.currentBoothId + '/'+ this.boothNumber + '/kiosk-outbound-shipping-labels']); 
                    break;
                }
              }
            });
          }
        } else {
          if (res.message)
            this.toastService.open(res.message, 'danger');
          else
            this.toastService.open('There was an error while updating Outbound Shipping paperwork', 'danger');
        }
      }, err => {
        this.createMHAService.isCreateMHAInProgress = false;
        this.toastService.open('There was an error while updating Outbound Shipping paperwork', 'danger');
      });
    }
  }

  closeSaveDraftPopup(data) {
    this.showConfirmationPopup = false;
    if (data) {
      if (data.continue) {
        this.setSection(4);
      } else {
        if (this.isKioskUser){
          this.openPrintModal();
        } else {
          this.modalCtrl.dismiss();
        }
        // this.router.navigate([this.currentBoothId, this.boothNumber, 'kiosk-outbound-shipping-labels'], { replaceUrl: true });
      }
    }
  }

  dismissMHAModal(){
    let requiredSections = [1,2]
    this.modalCtrl.dismiss({
      'isCloseClicked': requiredSections.includes(this.currentSectionId) ? true : false
    });
  }
  
  ngOnDestroy(): void {
    this.createMHAService.createMhaData = JSON.parse(JSON.stringify(this.createMHAService.createMhaObject));
    this.createMHAService.isShippingFormCompleted = false;
    this.createMHAService.isCarrierFormCompleted = false;
    this.createMHAService.mhaGUID = null;
  }
}

