import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingController, ModalController, Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { FileDisplayHelper } from 'src/app/helpers/file-display-helper';
import { Attachment } from 'src/app/models/attachment';
import { AttachmentService } from 'src/app/services/attachment/attachment.service';
import { BoothServiceProvider } from 'src/app/services/booth/booth.service';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';
import { FunctionalAreaService } from 'src/app/services/functional-area/functional-area.service';
import { LineItemService } from 'src/app/services/line-item/line-item.service';

@Component({
  selector: 'occ-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss'],
})
export class FileViewerComponent implements OnInit {
  @Input() isLineItems?: boolean;
	@Input() isBoothLineItem?: boolean;

	fileModal: ModalController;
	loading: LoadingController;
	attachments: Attachment[] = [];
	attachmentSubject: BehaviorSubject<Attachment[]>;
	subscribe = true;
	isLoading: boolean;
	firstLoad = false;
	itemType: string;

	constructor(
		public attachmentSvc: AttachmentService,
		public modalCtrl: ModalController,
		public platform: Platform,
		public dataSvc: DataRefreshService,
		public funcSvc: FunctionalAreaService,
		public lineItemSvc: LineItemService,
		public fileHelper: FileDisplayHelper,
		public boothService: BoothServiceProvider,
		private activatedRoute: ActivatedRoute,
	) {
	}

	ngOnInit() {
		this.isLoading = true;
		if (this.dataSvc.eventActive == undefined || this.dataSvc.showAccountActive == undefined) {
			this.dataSvc.showAccountActive = this.activatedRoute.snapshot.paramMap.get('accountId');
			this.dataSvc.eventActive = this.activatedRoute.snapshot.paramMap.get('eventId');
		}
		if (this.isBoothLineItem) {
			this.attachmentSubject = this.boothService.boothLIAttachments;
			this.itemType = 'file';
		} else {
			this.attachmentSubject = this.isLineItems ? this.attachmentSvc.liAttachments : this.attachmentSvc.faAttachments;
			this.itemType = this.isLineItems ? 'file' : 'attachment';
		}

		this.attachmentSubject.pipe(takeWhile(() => this.subscribe)).subscribe(files => {
			this.attachments = files;
			if (this.firstLoad) {
				this.firstLoad = false;
				this.isLoading = false;
			}
		});

		this.firstLoad = true;
		this.refresh();
	}

	showFile(fileName, fileId) {
		if (this.isBoothLineItem) {
			this.fileHelper.occAttachmentType = "boothLineItem";
			this.fileHelper.showFile(fileName, fileId);
		} else {
			this.fileHelper.occAttachmentType = this.isLineItems ? 'lineItem' : 'functionalArea';
			this.fileHelper.showFile(fileName, fileId);
		}
	}

	ngOnDestroy() {
		this.subscribe = false;

		if (this.fileModal) {
			this.fileModal.dismiss();
		}
	}

	refresh(refresher?): void {
		if (this.isLineItems) {
			this.attachmentSvc.refresh(this.dataSvc.eventActive, this.dataSvc.showAccountActive, this.funcSvc.activeFunctionalAreaId.getValue(), this.lineItemSvc.currentLineItem.getValue().kafkaId);
		} else if (this.isBoothLineItem) {
			this.boothService.getAttachmentFiles(this.dataSvc.eventActive);
		} else {
			this.attachmentSvc.refresh(this.dataSvc.eventActive, this.dataSvc.showAccountActive, this.funcSvc.activeFunctionalAreaId.getValue());
		}
		if (refresher) {
			refresher.target.complete();
		}
	}
}



