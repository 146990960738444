import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SocialOptions } from '../../models/social-options';
import { ToastService } from '../toast/toast.service';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Storage } from '@ionic/storage';
import { ActivityService } from '../activity/activity.service';
import { ActivityItem } from './../../models/activity-item';
import { AuthenticationService } from '../authentication/authentication.service';
import { AuthTokenHelper } from '../../helpers/auth-token-helper';
import { WorkticketActivityService } from '../workticket-activity/workticket-activity.service';
import { filter, takeWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SocialService {

	url = environment.apiUrl;
	public options = new SocialOptions();
	public azureUri: string;
	public azureToken: string;
	public activityItems: ActivityItem[];
	public photoName: string;
	eventId: string;
	accountId: string;
	photoId: string;
	subscribe = true;
	notFound = false;
	wtId: string;

	constructor(
		public http: HttpClient,
		public toastSvc: ToastService,
		private storage: Storage,
		public socialSharing: SocialSharing,
		public activitySvc: ActivityService,
		public activityService: ActivityService,
		private authSvc: AuthenticationService,
		private authTokenHelper: AuthTokenHelper,
		public workTikActivitySvc: WorkticketActivityService

	) {
		this.storage.get('azure_uri').then((val) => {
			this.azureUri = val;
		});
		this.storage.get('azure_token').then((val) => {
			this.azureToken = val;
		});
	}

	async ionViewCanEnter(): Promise<boolean> {
		if (await this.authTokenHelper.isTokenValid()) {
			return Promise.resolve(true);
		} else {
			const loginSuccessful = await this.authSvc.routeToLogin();
			return Promise.resolve(loginSuccessful);
		}
	}

	public shareImageOnMobileSocialMedia(eventId: string, accountId: string, imageId: string, photoName: string) {
		this.eventId = eventId;
		this.accountId = accountId;
		const photoId = imageId;

		this.activitySvc.getPhoto(this.eventId, this.accountId, photoId);
		this.activitySvc.selectedPhoto.pipe(filter(photo => photo != null)).pipe(takeWhile(() => this.subscribe)).subscribe(photo => {
			this.photoName = photo.name;
		}, err => this.notFound = true );

		const sharedLink = this.azureUri + '/' + photoName + this.azureToken;
		this.socialSharing.share('', '', sharedLink, '')
		.then(function(result) {
			this.onSuccess(result);
		}).catch(function(error) {
			console.log('error: not shared');

		});
	}

	public shareWorkTicketImageOnMobileSocialMedia(eventId: string, wtId: string, imageId: string, photoName: string) {
		this.eventId = eventId;
		this.wtId = wtId;
		const photoId = imageId;

		this.workTikActivitySvc.getPhoto(this.eventId, this.wtId, photoId);
		this.workTikActivitySvc.selectedPhoto.pipe(filter(photo => photo != null)).pipe(takeWhile(() => this.subscribe)).subscribe(photo => {
			this.photoName = photo.name;
		}, err => this.notFound = true );

		const sharedLink = this.azureUri + '/' + photoName + this.azureToken;
		this.socialSharing.share('', '', sharedLink, '')

		.then(function(result) {
			this.onSuccess(result);
		}).catch(function(error) {
			console.log('error: not shared');

		});
	}
	public onSuccess(result) {
		console.log('Share completed? ' + result.completed);
		console.log('Shared to app: ' + result.app);
	}

}
