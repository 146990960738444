import { Component, Input, OnInit } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { NavParams, LoadingController, ModalController } from '@ionic/angular';
import { AuthTokenHelper } from 'src/app/helpers/auth-token-helper';

@Component({
  selector: 'occ-file-display',
  templateUrl: './file-display.component.html',
  styleUrls: ['./file-display.component.scss'],
})
export class FileDisplayComponent implements OnInit {

  @Input() name: string;
	@Input() fileUrl: string;
	@Input() isPdf: boolean;
  
	browser: any;
	sanitizedFileUrl: SafeResourceUrl;
	loading: any;
	counter = 1;
	pdfViewerOptions = {
		url: null,
		httpHeaders: { 'Authorization': null }
	};

	constructor(
		public navParams: NavParams,
		public modalCtrl: ModalController,
		public sanitizer: DomSanitizer,
		public loadCtrl: LoadingController,
		public tokenHelper: AuthTokenHelper
	) {

	}

	async ngOnInit(): Promise<void> {
		this.sanitizedFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.fileUrl);
		this.pdfViewerOptions.url = this.fileUrl;

		this.tokenHelper.GetToken().then((token) => {
			this.pdfViewerOptions.httpHeaders.Authorization = 'Bearer ' + token;
		});
		this.loading = await this.loadCtrl.create({
			spinner: 'bubbles',
			message: 'Loading file..',
			showBackdrop: false,
		});
		this.loading.present();
	}

	dismissLoader() {
		this.loading.dismiss();
	}

	cancelLoader() {
		// this counter is ncecessary for proper functionality.
		// chrome needs to dismiss on the second call, other browsers dismiss on the first.
		const isChrome = window.navigator.vendor === 'Google Inc.';
		if (isChrome) {
			if (this.counter === 2) {
				this.loading.dismiss();
				return this.counter = 1;
			}
			return this.counter++;
		} else {
			this.loading.dismiss();
		}
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}

}
