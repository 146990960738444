import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'occ-sortby-mobile',
  templateUrl: './sortby-mobile.component.html',
  styleUrls: ['./sortby-mobile.component.scss'],
})
export class SortbyMobileComponent implements OnInit {
  @Input() sortOptions: any;
  @Input() defaultValue: any;
  
  constructor(public modalCtrl: ModalController) { }

  ngOnInit() {}

  closeModalPopup() {
    this.modalCtrl.dismiss();
  }

  setSortType(selectedSortOption: string) {
    this.modalCtrl.dismiss(selectedSortOption);
  }

}
