import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController, PopoverController } from '@ionic/angular';
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';
import { NavParamHelper } from 'src/app/helpers/nav-param-helper';
import { SelectHelper } from 'src/app/helpers/select-helper';
import { CollaborationLineItemDTO } from 'src/app/models/collaboration-line-item-dto';
import { ActivityService } from 'src/app/services/activity/activity.service';
import { AttachmentService } from 'src/app/services/attachment/attachment.service';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';
import { FunctionalAreaService } from 'src/app/services/functional-area/functional-area.service';
import { LineItemService } from 'src/app/services/line-item/line-item.service';
import { ItemActionMenuComponent } from '../item-action-menu/item-action-menu.component';

@Component({
  selector: 'occ-search-item-card',
  templateUrl: './search-item-card.component.html',
  styleUrls: ['./search-item-card.component.scss'],
})
export class SearchItemCardComponent implements OnInit {
  @Input() lineItem: CollaborationLineItemDTO;
	eventId: string;
	accountId: string;
	funcId: string;
	statusMenuPopover: any;

	constructor(
		public activitySvc: ActivityService,
		public attachmentSvc: AttachmentService,
		public liSvc: LineItemService,
		public navCtrl: NavController,
		public appBrowserHelper: AppBrowserHelper,
		public navParamHelper: NavParamHelper,
		public dataSvc: DataRefreshService,
		public popoverCtrl: PopoverController,
		public funcSvc: FunctionalAreaService,
    private activatedRoute: ActivatedRoute,
    private router: Router
	) {
		this.eventId = this.activatedRoute.snapshot.paramMap.get('eventId') || this.dataSvc.eventActive;
		this.accountId = this.activatedRoute.snapshot.paramMap.get('accountId') || this.dataSvc.showAccountActive;
		this.funcId = this.dataSvc.functionalAreaActive;
		this.navParamHelper.syncActiveInfo(this.eventId, this.accountId);
	}

	pushDetailsPage(item: CollaborationLineItemDTO) {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		// check the current line item value before doing anything
		const currentLineItem = this.liSvc.currentLineItem.getValue();

		// if it doesn't exist or if it's different, update it.
		if (!currentLineItem || currentLineItem !== item) {
			this.liSvc.currentLineItem.next(item);
		}

		const currentFunctionalArea = this.funcSvc.currentFunctionalAreas.find(fa => fa.functionalAreaGuid === item.functionalAreaGuid);
		this.funcSvc.currentFuncAreaSubject.next(currentFunctionalArea);
  
    this.router.navigateByUrl('events/' + this.eventId + '/accounts/' +  this.accountId + '/functional-areas/' +  item.functionalAreaGuid + '/line-items/' + item.kafkaId + "/line-item-details");
	}

	async presentPopover(event, item: CollaborationLineItemDTO) {
		this.statusMenuPopover = await this.popoverCtrl.create({
			component: ItemActionMenuComponent,
			event: event,
			componentProps: { item: item, fromSearch: true }
		});
		await this.statusMenuPopover.present();
		event.stopPropagation();
	}

  ngOnInit() {
  }

}
