import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ToastService } from '../toast/toast.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WorkTicketDTO } from '../../models/work-ticket-dto';
import { ToastType } from '../../models/toast-type';
import { StatusHelper } from '../../helpers/status-helper';
import { Status } from '../../models/status';
import * as moment from 'moment';
import { WorkTicketStatusChangeUploadDto } from '../../models/work-ticket-status-change-upload-dto';
import { WTTabsEnum } from '../../helpers/tabs-enum';
import { CollaborationLineItemDTO } from '../../models/collaboration-line-item-dto';
import { WorkTicketGroup } from '../../models/work-ticket-group';
import { WorkTicketDetails } from '../../models/work-ticket-details';
import { WorkticketActivityService } from '../workticket-activity/workticket-activity.service';
import { StatusChangeDownloadDto } from '../../models/status-change-download-dto';
import { UserService } from '../user/user.service';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { Attachment } from '../../models/attachment';

@Injectable({
  providedIn: 'root'
})
export class WorkTicketService {

	url = environment.apiUrl;
	activeWorkTicketIdSubject = new BehaviorSubject<string>(null);
	workTicketDTO = new BehaviorSubject<WorkTicketDTO[]>([]);
	currentWorkTicketDetailsSubject = new BehaviorSubject<WorkTicketDetails>(null);
	workTicketLineItemDTO = new BehaviorSubject<CollaborationLineItemDTO[]>([]);
	workTicketsExpanded = new BehaviorSubject<WorkTicketGroup[]>([]);
	functionalAreaAttachment = new BehaviorSubject<Attachment[]>([]);
	currentWTTab = new BehaviorSubject<string>('todo');
	locationWorkSubject = new BehaviorSubject<string[]>([]);
	categoryWorkSubject = new BehaviorSubject<string[]>([]);
	partGroupWorkSubject = new BehaviorSubject<string[]>([]);
	functionalAreaWorkSubject = new BehaviorSubject<string[]>([]);
	collaborationLineItemDto = new BehaviorSubject<CollaborationLineItemDTO[]>([]);
	facilityWorkSubject = new BehaviorSubject<string[]>([]);
	faAttachmentsLoading = new BehaviorSubject<boolean>(null);

	allWorkTicketsSubject = new BehaviorSubject<WorkTicketDTO[]>([]);  // whole list (was WorkTicketDTO)
	currentWorkTicketSubject = new BehaviorSubject<WorkTicketDTO>(null);	// single, current work ticket
	public allWorkTickets: WorkTicketDTO[];  // whole list (was WorkTicketDTO)
	public currentWorkTicket: WorkTicketDTO;  // single current work ticket

	public searchRequestComplete: boolean;
	public activeWorkTicketId: string;
	public currentTab = environment.environment === 'UAT' ? WTTabsEnum.Attachments : WTTabsEnum.LineItems;

	constructor(public http: HttpClient,
		public toastService: ToastService,
		public workTikActivitySvc: WorkticketActivityService,
		public userSvc: UserService,
		private ga: GoogleAnalytics,
		) {
		this.allWorkTicketsSubject.subscribe(dto => {
			this.allWorkTickets = dto;
		});
		this.currentWorkTicketSubject.subscribe(dto => {
			this.currentWorkTicket = dto;
		});
		this.activeWorkTicketIdSubject.subscribe(id => {
			this.activeWorkTicketId = id;
		});
	}

	setReferenceToWorkTicketDTO(): void {
		const allWorkTicketsDto = this.allWorkTickets;
		const workTicketGuid = this.currentWorkTicket ? this.currentWorkTicket.workTicketGuid : this.activeWorkTicketId;

		if (!allWorkTicketsDto || allWorkTicketsDto.length === 0 || !workTicketGuid) {
			return;
		}

		const workTicketDetailDto: WorkTicketDTO = allWorkTicketsDto.find(wt => wt.workTicketGuid === workTicketGuid);
		this.currentWorkTicketSubject.next(workTicketDetailDto);
	}

	get(showId: string): void {
		this.refresh(showId);
	}

	searchWorktickets(showId: string): Observable<WorkTicketDTO[]> {
		return this.http.get<any>(`${this.url()}events/${showId}/work-tickets/`)
		.pipe(map(data => {
				data.forEach(f => f.currentStatus = StatusHelper.GetStatusFromId(f.currentStatus));
				const sortedWorktickets = this.sortWorkTickets(data);
				this.populateFilterLists(sortedWorktickets);
				this.allWorkTicketsSubject.next(sortedWorktickets);
				this.searchRequestComplete = true;
				return sortedWorktickets;
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error while searching for worktickets', 'danger');
				}
			}));
	}

	refresh(showId: string, errorCallback?: Function) {
		this.http.get<any>(`${this.url()}events/${showId}/work-tickets`)
      .subscribe(data => {
        if(data!=null && data!= undefined ){
          data.forEach(f => f.currentStatus = StatusHelper.GetStatusFromId(f.currentStatus));
          this.allWorkTicketsSubject.next(data);
          this.setReferenceToWorkTicketDTO();
        }	}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error retrieving work tickets', 'danger');
				}
				if (errorCallback) errorCallback();
			});
	}

	getWorkTicketLineItem(showId: string, workTicketGuid: string ) {
		 this.http.get<CollaborationLineItemDTO[]>(`${this.url()}events/${showId}/work-tickets/line-items?workTicketGuid=${workTicketGuid}`)
		.subscribe(data => {
			 data.forEach(f => f.status = StatusHelper.GetStatusFromId(f.currentStatus));
			 this.workTicketLineItemDTO.next(data);
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error getting workticket data', 'danger');
				}
			}
		);
	}

	getFunctionalAreaAttachements(showId: string, workTicketGuid: string) {
		this.faAttachmentsLoading.next(true);
		this.http.get<Attachment[]>(`${this.url()}events/${showId}/work-tickets/${workTicketGuid}/files`)
		.subscribe(data => {
			this.functionalAreaAttachment.next(data);
			this.faAttachmentsLoading.next(false);
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error getting functional area attachments', 'danger');
				}
			}
		);
	}

	searchWorkTicketLineItems(showId: string ) {
		this.http.get<CollaborationLineItemDTO[]>(`${this.url()}events/${showId}/work-tickets/showaccounttype/0/all-line-items`)
	   .subscribe(data => {
			data.forEach(f => f.status = StatusHelper.GetStatusFromId(f.currentStatus));
			this.workTicketLineItemDTO.next(data);
			this.collaborationLineItemDto.next(data);
		   }, err => {
			   if (err.status !== 401 && err.status !== 403) {
				   this.toastService.open('There was an error while searching for workticket line items', 'danger');
			   }
		   }
	   );
   }

	put(eventId: string, workTicket: WorkTicketDTO, newStatus: Status, lineItemsSubject: BehaviorSubject<CollaborationLineItemDTO[]>, lineItems?: CollaborationLineItemDTO[], noteBody?: string, photoUri?: string) {
		// lookup functional area in the DTO by the id from the params
		const workTicketLookedUp = this.lookupWorkTicketById(workTicket.workTicketGuid);
		const usersToNotify = noteBody ? this.userSvc.verifyMentionedUsers(noteBody) : [];

		const original: WorkTicketDTO = JSON.parse(JSON.stringify(workTicket));
		let lineItemStatusesToRevertTo: CollaborationLineItemDTO[];
		const lineItemSvcLineItems = lineItemsSubject.getValue();
		const originalLineItemSvcLineItems = JSON.parse(JSON.stringify(lineItemSvcLineItems));

		workTicketLookedUp.currentStatus = newStatus;
		workTicketLookedUp.statusDate = moment.utc(moment.now()).toDate();

		this.allWorkTicketsSubject.next(this.allWorkTickets);

		const uploadDto: WorkTicketStatusChangeUploadDto = {
			lineItemGuid: null,
			noteBody: noteBody,
			photoUri: photoUri,
			workTicketStatus: newStatus.id,
			usersToNotify: usersToNotify
		};

		if (lineItems) {
			// save the state of the line items before changing them for revert on error.
			lineItemStatusesToRevertTo = JSON.parse(JSON.stringify(lineItems));
			if (newStatus === StatusHelper.Status.OnHold) {
				const lineItem = lineItems[0];
				if (lineItems.length === 1) {
					lineItem.status = newStatus;
					uploadDto.lineItemGuid = lineItem.kafkaId;
					const liIndex = lineItemSvcLineItems.findIndex(li => li.kafkaId === lineItem.kafkaId);
					lineItemSvcLineItems.splice(liIndex, 1, lineItem);
					lineItemsSubject.next(lineItemSvcLineItems);
				}
			} else {
				const updatedLineItems = StatusHelper.UpdateLineItemStatusesForWorkTicket(workTicket, lineItems);
				StatusHelper.updateCompletedCountForWorkTicket(workTicket, updatedLineItems);
				lineItemsSubject.next(updatedLineItems);
			}
		}

		this.http.put<StatusChangeDownloadDto>(`${this.url()}events/${eventId}/work-tickets/${workTicket.workTicketGuid}`, uploadDto)
			.subscribe(data => {
				data.statusChange.status = StatusHelper.GetStatusFromId(data.statusChange.statusId);
				this.workTikActivitySvc.addItemToActivityItems(data.statusChange.createdDate, data.note, data.photo, data.statusChange);
				this.ga.trackEvent('WorkTicketStatusChange', data.statusChange.status.title);
				if (uploadDto.usersToNotify.length > 0) {
					this.ga.trackEvent('UserMentioned', 'UserMentioned');
				}
				this.trackStatusChangeTiming(original, newStatus);
			}, error => {
				switch (error.status) {
					case 400:
						this.toastService.open('400 error: Either work ticket IDs do not match, or model state is invalid.', 'danger');
						break;
					case 404:
						this.toastService.open(`No work ticket with id: ${workTicket.workTicketGuid}`, 'danger');
						break;
					case 401:
					case 403: {
						// Unauthorized
						break;
					}
					default:
						this.toastService.open('Unable to update work ticket status change, unkown error.', 'danger');
						break;
				}
				workTicketLookedUp.currentStatus = original.currentStatus;
				workTicketLookedUp.currentStatus.id = original.currentStatus.id;
				workTicketLookedUp.lineItemCompleteCount = original.lineItemCompleteCount;
				this.allWorkTicketsSubject.next(this.allWorkTickets);
				lineItemsSubject.next(originalLineItemSvcLineItems);
			});
	}

	refreshDataForWorkTicketDetails(showId: string, workTicketGuid: string) {
		this.http.get<WorkTicketDetails>(`${this.url()}events/${showId}/work-tickets/${workTicketGuid}`)
		.pipe(map(data => {
			return data;
		}))
		.subscribe(data => {
			this.currentWorkTicketDetailsSubject.next(data);
		}, err => {
			if (err.status !== 401 && err.status !== 403) {
				this.toastService.open('There was an error refreshing the workticket details data', 'danger');
			}
		});
	}

	lookupWorkTicketById(workTicketId: string): WorkTicketDTO {
		const workTicket = this.allWorkTickets.find(wt => wt.workTicketGuid === workTicketId);
		return workTicket;
	}

	public sortWorkTickets(workTickets: WorkTicketDTO[]): WorkTicketDTO[] {
		// sort by vendor alphabetically
		workTickets.sort((a, b) => {
			if (a.tssWorkTicketNumber < b.tssWorkTicketNumber) {
				return -1;
			} else if (b.tssWorkTicketNumber < a.tssWorkTicketNumber) {
				return 1;
			}
			// if the vendor name is the same, sort by passport Id
			return a.currentStatus.id - b.currentStatus.id;
		});

		// then sort by lineItemId
		return workTickets;
	}

	public populateFilterLists(workTickets: WorkTicketDTO[]): void {
		const locations: string[] = [];
		const categories: string[] = [];
		const partGroups: string[] = [];
		const functionalAreas: string[] = [];
		const facilities: string[] = [];
		workTickets.forEach(wt => {
			if (wt.location && locations.indexOf(wt.location) === -1)
				locations.push(wt.location);
			if (wt.category && categories.indexOf(wt.category) === -1)
				categories.push(wt.category);
			if (wt.partGroup && partGroups.indexOf(wt.partGroup) === -1)
				partGroups.push(wt.partGroup);
			if (wt.functionalAreaName && functionalAreas.indexOf(wt.functionalAreaName) === -1)
				functionalAreas.push(wt.functionalAreaName);
			if (wt.facility && facilities.indexOf(wt.facility) === -1)
				facilities.push(wt.facility);
		});
		this.locationWorkSubject.next(locations);
		this.categoryWorkSubject.next(categories);
		this.partGroupWorkSubject.next(partGroups);
		this.functionalAreaWorkSubject.next(functionalAreas);
		this.facilityWorkSubject.next(facilities);
	}

	activateWorkTicket(workTicketId: string) {
		const workTicketToSetAsCurrent = this.allWorkTickets.find(wt => wt.workTicketGuid === workTicketId);
		this.currentWorkTicketSubject.next(workTicketToSetAsCurrent);
		this.currentWorkTicket = workTicketToSetAsCurrent;
		this.activeWorkTicketIdSubject.next(workTicketId);
	}

	trackStatusChangeTiming(originalWT: WorkTicketDTO, newStatus: Status) {
		if (originalWT.currentStatus.id === StatusHelper.Status.OnHold.id) {
			this.ga.trackEvent('WorkTicketChangedFromActionRequiredStatus', moment(originalWT.statusDate).utc().fromNow(true), null, moment.utc().diff(moment.utc(originalWT.statusDate).valueOf()));
		}
	}

	lineItemEmail(data: any, filename: string) {
		const filterLineItem = {
			liList: data,
			fileName: filename
		  }
		this.http.post(`${this.url()}digest/lineitem/email`, filterLineItem)
			.subscribe(data => {
				if (data == null) {
					this.toastService.open('Report has send on your email successfully.', 'success');
				}
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
				  this.toastService.open('There was an error sending email. Please try again!', 'danger');
				}
			  });
	}

}
