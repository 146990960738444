import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';
import { Location } from '@angular/common';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'occ-kiosk-footer',
  templateUrl: './kiosk-footer.component.html',
  styleUrls: ['./kiosk-footer.component.scss'],
})
export class KioskFooterComponent implements OnInit {

  @Input() isShippingLabel?: boolean;
  @Input() isBackButtonVisible?: boolean = true;
  @Output() onTabChangedEvent? = new EventEmitter<boolean>();
  @Input() boothNumber?: string;
  @Input() isOutboundShipping?:boolean;
  @Input() isBoothSearch?:boolean;
  @Input() printLabel?:boolean;
  @Input() boothId?:string;
  @Input() showBoothSearch: any = true;

  constructor(private navCtrl: NavController, 
    private appBrowserHelper: AppBrowserHelper,
    public router: Router,
    private location: Location,
    private storage: Storage
    ) 
    {
      
  }

  ngOnInit(): void {

  }

  changeTabSelection(isShippingLabel: boolean) {
    this.isShippingLabel = isShippingLabel;
    this.onTabChangedEvent.emit(isShippingLabel);
  }

  navigateBack(){
    //this.navCtrl.pop();
    if(this.router.url == '/kiosk-authorization'){
      this.storage.get('isSelfCheckIn').then((data) => {
        if(data == true)
       this.router.navigate(['kiosk-events']);
      })
    }
    else{
    this.location.back();
    }
  }
  
  navigateToShippingLabel(){
    this.router.navigate([this.boothId + '/'+ this.boothNumber + '/kiosk-mha-list']); 
  }
  navigateToOutboundShipping()
  {
    this.router.navigate([this.boothId + '/'+ this.boothNumber + '/kiosk-outbound-shipping-labels']); 
  }
  navigateToMHAOptions()
  {
    this.router.navigate(['/kiosk-booth-info'], { replaceUrl: true });
  }
}