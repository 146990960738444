import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'alphabeticSort'
})
export class AlphabeticSortPipe implements PipeTransform {

  transform(dataSet: any[], arg: string): any[] {
    if(arg === 'events') {
      dataSet =  dataSet.sort((obj1, obj2) => obj1.name.localeCompare(obj2.name));
    } else if(arg === 'exibitors') {
      dataSet = dataSet.sort((obj1, obj2) => obj1.showAccountName.localeCompare(obj2.showAccountName));
    } else if(arg === 'booths') {
      dataSet =  dataSet.sort((obj1, obj2) => obj1.boothName.localeCompare(obj2.boothName));
    }
    return dataSet;
  }
}
