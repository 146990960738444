import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BoothServiceProvider } from 'src/app/services/booth/booth.service';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';
import { LineItemService } from 'src/app/services/line-item/line-item.service';

@Component({
  selector: 'change-order-details',
  templateUrl: './change-order-details.component.html',
  styleUrls: ['./change-order-details.component.scss'],
})
export class ChangeOrderDetailsComponent implements OnInit {
  @Input() currentOrderDetails: any;
	@Input() boothGUID?: string;
	eventId: string;
	accountId: string;
	allRevisionData: any[] = [];
	latestRevisionData: any = {};
	olderRevisionData: any = {};
	isNewOrderDetails:boolean = false;
	selectedRevIndex: number;
	isCancelled: boolean = false;
	groupNumber: string;
  constructor(	public dataSvc: DataRefreshService,
		public lineItemSvc: LineItemService,
		public boothService: BoothServiceProvider,
    private activatedRoute: ActivatedRoute) { }

    ngOnInit(): void {
      if(this.boothGUID){
        this.eventId =  this.activatedRoute.snapshot.paramMap.get('eventId') ;
        this.accountId = this.boothService.activeBooth.getValue().showAccountGUID;
      } else {
        this.eventId =  this.activatedRoute.snapshot.paramMap.get('eventId') || this.dataSvc.eventActive;
        this.accountId = this.activatedRoute.snapshot.paramMap.get('accountId') || this.dataSvc.showAccountActive;
        
        if(this.eventId == "undefined" || this.accountId == "undefined") {
          let URLSegement = document.URL.split('/').reverse();
          this.eventId = URLSegement["5"];
          this.accountId = URLSegement["3"];
        };
      }
  
      if (this.currentOrderDetails.lineItemId !== 0) {
        this.lineItemSvc.getChangeOrderByLineItemId(this.eventId, this.accountId, this.currentOrderDetails.lineItemId).subscribe(changeOrders => {
          this.allRevisionData = changeOrders.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
          this.selectedRevIndex = this.allRevisionData.findIndex((revData) => revData.id == this.currentOrderDetails.id);
          if(this.selectedRevIndex >= 0){
            this.latestRevisionData = this.allRevisionData[this.selectedRevIndex];
            if(this.selectedRevIndex < this.allRevisionData.length-1){
              this.olderRevisionData = this.allRevisionData[this.selectedRevIndex + 1];
              this.isNewOrderDetails = true;
            }
          }
          if(this.allRevisionData.length > 0)
          {
            var partNumberSplit = this.allRevisionData[0].partNumber != null ? this.allRevisionData[0].partNumber.toString().split('-') : "";
            this.groupNumber = partNumberSplit[0];
          }
        });
      } else {
        this.lineItemSvc.getChangeOrderByOrderId(this.eventId, this.accountId, this.currentOrderDetails.id).subscribe(changeOrder => {
          this.allRevisionData.push(changeOrder);
          this.latestRevisionData = changeOrder;
          if(this.allRevisionData.length > 0)
          {
            var partNumberSplit = this.allRevisionData[0].partNumber != null ? this.allRevisionData[0].partNumber.toString().split('-') : "";
            this.groupNumber = partNumberSplit[0];
          }
        });
      }	
    }
  
    checkRevisedReason(reasonId) {
      if (this.allRevisionData.length > 1) {
        let revReasons = this.allRevisionData[0].revisionReasonId ? this.allRevisionData[0].revisionReasonId.split(",") : [];
        return revReasons.length ? revReasons.includes(reasonId) : false;
      }
      return false;
    }
  
    getReviseReasonString(reasonIdString) {
      let concatedReasons = "";
      if (reasonIdString) {
        let idArray = reasonIdString.split(',').filter(Boolean);
        idArray.forEach(reasonId => {
          switch (reasonId) {
            case "1":
              concatedReasons = concatedReasons + ", Quantity Changed";
              break;
            case "11":
              concatedReasons = concatedReasons + ", Date Time Change";
              break;
            case "3":
              concatedReasons = concatedReasons + ", Revised Comments";
              break;
            case "9":
              concatedReasons = concatedReasons + ", Facility Location Change";
              break;
            case "10":
              concatedReasons = concatedReasons + ", Invoice Change";
              break;
            case "5":
              concatedReasons = concatedReasons + ", Other";
              break;
          }
        });
        concatedReasons = concatedReasons.substring(1);
      }
      return concatedReasons;
    }
  
    getOrderRevisionText() {
      if(this.currentOrderDetails.lineItemId == 0 || this.currentOrderDetails.isNewOrder)
      {
  
        return this.currentOrderDetails.originLineItemId != null ? "New - Copy of Line Item : "+ this.currentOrderDetails.originLineItemId : "New";
      }
      else {
      if (this.allRevisionData.length) {
        let ascendingAllRevData = [...this.allRevisionData].reverse();
        let matchedRevIndex = ascendingAllRevData.findIndex((revData) => revData.id == this.currentOrderDetails.id);
        return "Revision " + (matchedRevIndex + 1);
      }
    }
      return;
    }
  
    isEmptyObject(obj) {
      return (obj && (Object.keys(obj).length === 0));
      }

}
