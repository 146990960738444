import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage-angular";
import { Device } from '@capacitor/device';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { AuthTokenHelper } from "src/app/helpers/auth-token-helper";
import * as moment from 'moment';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { Show } from "src/app/models/show";

@Injectable({
    providedIn: 'root'
})

export class UserActivityService {

    url = environment.apiUrl;
    sessionId: any;

    constructor(
        private http: HttpClient,
        private storage: Storage,
        private tokenHelper: AuthTokenHelper
    ) {

    }

    async captureEvent(eventType) {
        const info = await Device.getInfo();
        let requestObject = {
            activity: eventType.toUpperCase(),
            sessionId: eventType != 'login' ? this.sessionId : null,
            deviceModel: info.model,
            platform: info.platform,
            timeStamp: new Date().toISOString()
        };
        await this.storeEvent(requestObject).subscribe(res => {
            if (res.sessionId && eventType == 'login') {
                this.sessionId = res.sessionId;
                this.storage.set('sessionId', res.sessionId);
            }
        })
    }

    storeEvent(requestObject) {
        return this.http.post<any>(`${this.url()}users/activity`, requestObject).pipe(map((data: any) => {
            return data;
        }, err => {

        }));
    }

    getUserActivityReport(event: Show){
        this.http.get<any>(`${this.url()}users/activity`).subscribe((data: any) => {
            const dataArray: any = data;
            const headerList = Object.keys(data[0] || data);
            const options = {
                headers: headerList,
                nullToEmptyString: true
            };
            const currentDate: string = moment().utc().format('YYYY_MM_DD');
            new AngularCsv(dataArray, `User_Activity_Report_${currentDate}`, options);
        },  err => {

        });
    }

}
