import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { CollaborationLineItemDTO } from 'src/app/models/collaboration-line-item-dto';
import { Status } from 'src/app/models/status';
import { WorkticketLineItemService } from 'src/app/services/workticket-line-item/workticket-line-item.service';

@Component({
  selector: 'occ-bulk-update-confirmation-modal',
  templateUrl: './bulk-update-confirmation-modal.component.html',
  styleUrls: ['./bulk-update-confirmation-modal.component.scss'],
})
export class BulkUpdateConfirmationModalComponent implements OnInit {

	confirmSave: boolean;
  constructor(public popoverCtrl: PopoverController) {
		}

  ngOnInit() {}

  dismiss() {
	this.confirmSave = false;
			this.popoverCtrl.dismiss();
	}
	confirmStatusUpdate() {
		this.confirmSave = true;
		this.popoverCtrl.dismiss({
			'dismissed': true
		});
	}

}
