import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { IAngularMyDpOptions, IMyInputFieldChanged } from 'angular-mydatepicker';
import { TimeValidator } from 'src/app/helpers/time-validation-helper';


@Component({
  selector: 'freight-datetime',
  templateUrl: './freight-datetime.component.html',
  styleUrls: ['./freight-datetime.component.scss'],
})
export class FreightDatetimeComponent implements OnInit, AfterViewInit {
  @ViewChild('monthInputRef') monthInputRef: ElementRef;
  @ViewChild('dayInputRef') dayInputRef: ElementRef;
  @ViewChild('yearInputRef') yearInputRef: ElementRef;

  timeValue: string = '';
  monthInputValue: string = '';
  dayInputValue: string = '';
  yearInputValue: string = '';
  isMonthFocused: boolean = false;
  isDayFocused: boolean = false;
  isYearFocused: boolean = false;
  isValidDate: boolean = true;
  public placeholder: string = 'MM/DD/YYYY';
  @Input() freightEmptiesDate?: any;
  @Output() onDateChange?= new EventEmitter();
  @Output() calendarToggle?= new EventEmitter();
  copiedFreightEmptiesDate: any;

  defaultEmptiesDateOptions: IAngularMyDpOptions = {
    alignSelectorRight: true,
    // showClearDateBtn: false,
    dateFormat: 'mm/dd/yyyy',
    disableUntil: {
      year: 2016,
      month: 12,
      day: 31
    },
    inline: false,
    showFooterToday: true,
    todayTxt: 'Today',
    dateRange: false
  }

  daysInMonth: Array<number> = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  constructor(private timeValidator: TimeValidator) { }

  ngOnInit() {
    this.copiedFreightEmptiesDate = _.cloneDeep(this.freightEmptiesDate);
    this.timeValue = '';
    if (this.copiedFreightEmptiesDate) {
      let copiedFreightEmptiesDate = new Date(this.copiedFreightEmptiesDate);
      this.copiedFreightEmptiesDate = {
        singleDate: {
          date: { month: copiedFreightEmptiesDate.getMonth() + 1, day: copiedFreightEmptiesDate.getDate(), year: copiedFreightEmptiesDate.getFullYear() }
        }
      };
      this.timeValue = ('0' + copiedFreightEmptiesDate.getHours()).slice(-2) + ":" + ('0' + copiedFreightEmptiesDate.getMinutes()).slice(-2);
      this.monthInputValue = (copiedFreightEmptiesDate.getMonth() + 1).toString().padStart(2, '0');
      this.dayInputValue = copiedFreightEmptiesDate.getDate().toString().padStart(2, '0');
      this.yearInputValue = copiedFreightEmptiesDate.getFullYear().toString();
    }
  }

  ngAfterViewInit(): void {
    this.monthInputRef.nativeElement.onkeydown = (event) => {
      var key = event.keyCode || event.charCode;
      if (key == 8 && this.monthInputValue.length == 0) {
        this.monthInputValue = '';
      }
      if (key == 39 && this.monthInputRef.nativeElement.selectionStart == this.monthInputValue.length) {
        setTimeout(() => {
          this.dayInputRef.nativeElement.setSelectionRange(0, 0);
          this.dayInputRef.nativeElement.focus();
        }, 10);
      }
    };

    this.dayInputRef.nativeElement.onkeydown = (event) => {
      var key = event.keyCode || event.charCode;
      if (key == 8 && (this.dayInputValue.length == 0 || this.dayInputRef.nativeElement.selectionStart == 0)) {
        setTimeout(() => {
          this.monthInputRef.nativeElement.focus();
        }, 10);
      }

      if (key == 37 && this.dayInputRef.nativeElement.selectionStart == 0) {
        setTimeout(() => {
          const end = this.monthInputRef.nativeElement.value.length;
          this.monthInputRef.nativeElement.setSelectionRange(end, end);
          this.monthInputRef.nativeElement.focus();
        }, 10);
      }

      if (key == 39 && this.dayInputRef.nativeElement.selectionStart == this.dayInputValue.length) {
        setTimeout(() => {
          this.yearInputRef.nativeElement.setSelectionRange(0, 0);
          this.yearInputRef.nativeElement.focus();
        }, 10);
      }
    };

    this.yearInputRef.nativeElement.onkeydown = (event) => {
      var key = event.keyCode || event.charCode;
      if (key == 8 && (this.yearInputValue.length == 0 || this.yearInputRef.nativeElement.selectionStart == 0)) {
        setTimeout(() => {
          this.dayInputRef.nativeElement.focus();
        }, 10);
      }

      if (key == 37 && this.yearInputRef.nativeElement.selectionStart == 0) {
        setTimeout(() => {
          const end = this.dayInputRef.nativeElement.value.length;
          this.dayInputRef.nativeElement.setSelectionRange(end, end);
          this.dayInputRef.nativeElement.focus();
        }, 10);
      }
    };
  }

  changedDefaultTime() {
    this.timeValue = this.timeValue ? this.timeValue : '';
    let checkTimeValue = this.timeValue ? this.timeValue : '00:00'
    let setupCompleteSplittedTime = checkTimeValue.split(":");

    let newfreightDateTime = {
      "freightTime": this.timeValue,
      "freightDate": '',
      "freightDateTime": null
    }

    let copiedFreightEmptiesDate = this.copiedFreightEmptiesDate.singleDate;

    if(copiedFreightEmptiesDate?.date?.year.toString() && (copiedFreightEmptiesDate?.date?.month - 1) && copiedFreightEmptiesDate?.date?.day){
      newfreightDateTime = {
        "freightTime": this.timeValue,
        "freightDate": copiedFreightEmptiesDate ? copiedFreightEmptiesDate : '',
        "freightDateTime": copiedFreightEmptiesDate ? (new Date(copiedFreightEmptiesDate.date.year.toString(), (copiedFreightEmptiesDate.date.month - 1), copiedFreightEmptiesDate.date.day, parseInt(setupCompleteSplittedTime[0]), parseInt(setupCompleteSplittedTime[1]), 0, 0)) : ""
      }
    }
    
    this.onDateChange.emit(newfreightDateTime);
  }

  onDateSelectionChanged(e = null) {
    if (e)
      this.copiedFreightEmptiesDate = e;
    let copiedFreightEmptiesDate = e ? e.singleDate : this.copiedFreightEmptiesDate.singleDate;
    this.changedDefaultTime();
    this.monthInputValue = (copiedFreightEmptiesDate?.date.month).toString().padStart(2, '0');
    this.dayInputValue = copiedFreightEmptiesDate?.date.day.toString().padStart(2, '0');
    this.yearInputValue = copiedFreightEmptiesDate?.date.year.toString();
  }

  onInputFieldChanged(event: IMyInputFieldChanged, dateInput: any) {
    if (!event.value) {
      dateInput.selectionDayTxt = '';
    }
    if (event.value && (event.value.length === 2 || event.value.length === 5)) {
      dateInput.selectionDayTxt = event.value + '/';
    }
    if (event.value && (event.value.length > 2) && !event.value.includes('/')) {
      let result = event.value.replace(/^(\d{2})(\d{2})/, '$1/$2/');
      dateInput.selectionDayTxt = result;
    }
  }

  onDateInputChange(inputType) {
    this.isValidDate = true;
    switch (inputType) {
      case 1:
        this.checkMonthValidity();
        if (this.monthInputValue?.length == 2) {
          setTimeout(() => {
            this.dayInputRef.nativeElement.setSelectionRange(0, 0);
            this.dayInputRef.nativeElement.focus();
          }, 10);
        }
        break;
      case 2:
        this.checkDayValidity();
        if (this.dayInputValue?.length == 2) {
          setTimeout(() => {
            this.yearInputRef.nativeElement.setSelectionRange(0, 0);;
            this.yearInputRef.nativeElement.focus();
          }, 10);
        }
        break;
      case 3:
        this.checkYearValidity();
        break;

      default:
        break;
    }

    if (this.monthInputValue && this.dayInputValue && this.yearInputValue && this.yearInputValue.length == 4) {
      let isValidDateInputs = (this.monthInputValue + this.dayInputValue + this.yearInputValue).match(/^[0-9]+$/) != null;
      let date = new Date(parseInt(this.yearInputValue), parseInt(this.monthInputValue) - 1, parseInt(this.dayInputValue));

      if (isValidDateInputs && date instanceof Date && !isNaN(date.valueOf())) {
        this.copiedFreightEmptiesDate = {
          singleDate: {
            date: {
              month: parseInt(this.monthInputValue),
              day: parseInt(this.dayInputValue),
              year: parseInt(this.yearInputValue)
            }
          }
        };
        this.changedDefaultTime();
        this.isValidDate = true;
      } else {
        this.isValidDate = false;
      }
    } else {
      this.copiedFreightEmptiesDate = {
        singleDate: {
          date: {
            month: parseInt(this.monthInputValue),
            day: parseInt(this.dayInputValue),
            year: parseInt(this.yearInputValue)
          }
        }
      };
      this.changedDefaultTime();
    }
  }

  getPlaceHolder(inputType) {
    if (this.isMonthFocused || this.isDayFocused || this.isYearFocused || this.monthInputValue || this.dayInputValue || this.yearInputValue) {
      return "";
    } else {
      let placeolderString = '';
      switch (inputType) {
        case 1:
          placeolderString = 'MM';
          break;
        case 2:
          placeolderString = 'DD';
          break;
        case 3:
          placeolderString = 'YYYY';
          break;

        default:
          break;
      }
      return placeolderString;
    }
  }

  checkDayValidity() {
    if (this.dayInputValue.match(/^[0-9]+$/) == null) {
      let cursorPosition = this.monthInputRef.nativeElement.selectionStart;
      this.dayInputRef.nativeElement.value = this.dayInputValue = this.dayInputValue.replace(/\D/g, '')
      this.dayInputRef.nativeElement.setSelectionRange(cursorPosition, cursorPosition);
    }

    // Update range based on selected month
    let endRange = parseInt(this.monthInputValue) ? this.daysInMonth[parseInt(this.monthInputValue) - 1] : 31;

    if (this.dayInputValue && !(_.inRange(parseInt(this.dayInputValue), 0, endRange + 1))) {
      this.dayInputRef.nativeElement.value = this.dayInputValue = '';
    }

    if (this.dayInputValue.length == 2 && parseInt(this.dayInputValue) == 0) {
      this.dayInputRef.nativeElement.value = this.dayInputValue = '';
    }
  }

  checkMonthValidity() {
    if (this.monthInputValue.match(/^[0-9]+$/) == null) {
      let cursorPosition = this.monthInputRef.nativeElement.selectionStart;
      this.monthInputRef.nativeElement.value = this.monthInputValue = this.monthInputValue.replace(/\D/g, '')
      this.monthInputRef.nativeElement.setSelectionRange(cursorPosition, cursorPosition);
    }

    if (this.monthInputValue && !(_.inRange(parseInt(this.monthInputValue), 0, 13))) {
      this.monthInputRef.nativeElement.value = this.monthInputValue = '';
    }

    if (this.monthInputValue.length == 2 && parseInt(this.monthInputValue) == 0) {
      this.monthInputRef.nativeElement.value = this.monthInputValue = '';
    }

    this.checkDayValidity();
  }

  checkYearValidity() {
    if (this.yearInputValue.match(/^[0-9]+$/) == null) {
      let cursorPosition = this.yearInputRef.nativeElement.selectionStart;
      this.yearInputRef.nativeElement.value = this.yearInputValue = this.yearInputValue.replace(/\D/g, '')
      this.yearInputRef.nativeElement.setSelectionRange(cursorPosition, cursorPosition);
    }

    if (this.yearInputValue && this.yearInputValue.length == 4 && !(_.inRange(parseInt(this.yearInputValue), 2017, 2101))) {
      this.yearInputRef.nativeElement.value = this.yearInputValue = '';
    }

    if(this.yearInputValue){
      // Check selected year is leap year or not
      this.daysInMonth[1] = (new Date(parseInt(this.yearInputValue), 1, 29).getDate() === 29) ? 29 : 28;
    }

    this.checkDayValidity();
  }

  emitCalendarToggle(event) {
    this.calendarToggle.emit(event);
  }

  openCalendar(datePickerRef: any){
    setTimeout(() => {
      datePickerRef.openCalendar()
    }, 100);
  }
}
