
import { Injectable } from '@angular/core';
import { FilteredWorkTicketTimeline } from '../../models/work-ticket-timeline-filter-dto';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimelineFilterService {
	chosenFilters = new BehaviorSubject<FilteredWorkTicketTimeline[]>([]);
}
