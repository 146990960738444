import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthTokenHelper } from './helpers/auth-token-helper';
import { AuthenticationService } from './services/authentication/authentication.service';
import { AppBrowserHelper } from './helpers/app-browser-helper';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { EventService } from './services/event/event.service';
import { TokenInterceptor } from './helpers/token.interceptor';
import { NavParamHelper } from './helpers/nav-param-helper';
import { BulkUpdateModalComponent } from './components/bulk-update-modal/bulk-update-modal.component';
import { NeedsAttentionComponent } from './components/needs-attention/needs-attention.component';
import { FileExtensionHelper } from './helpers/file-extension-helper';
import { File } from '@ionic-native/file/ngx';
import { FilterHelper } from './helpers/filter-helper';
import { SearchHelper } from './helpers/search-helper';
import { CameraHelper } from './helpers/camera-helper';
import { CameraLinkToPhotoVerificationHelper } from './helpers/camera-intermediary';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { MentionModule } from 'angular-mentions';
import { EventsPageModule } from './pages/events/events.module';
import { WorkticketsPageModule } from './pages/worktickets/worktickets.module';
import { FormsModule } from '@angular/forms';
import { PhotoVerificationComponent } from './components/photo-verification/photo-verification.component';
import { AddNoteComponent } from './components/add-note/add-note.component';
import { StatusWarningPopoverComponent } from './components/status-warning-popover/status-warning-popover.component';
import { PushNotificationService } from './services/push-notification/push-notification.service';
import { Camera } from '@ionic-native/camera/ngx';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { StyleHelper } from './helpers/style-helper';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Printer } from '@ionic-native/printer/ngx';
import { BottomMenuComponent } from './components/bottom-menu/bottom-menu.component';
// import { BulkUpdateLineItemStatusModalComponent } from './components/bulk-update-line-item-status-modal/bulk-update-line-item-status-modal.component';
import { BulkUpdateConfirmationModalComponent } from './components/bulk-update-confirmation-modal/bulk-update-confirmation-modal-component';
import { KioskPrintConfirmationModalComponent } from './components/kiosk-print-confirmation-modal/kiosk-print-confirmation-modal.component';
import { KioskLogoutConfirmationComponent } from './components/kiosk-logout-confirmation/kiosk-logout-confirmation.component';
import { KioskSessionModalComponent } from './components/kiosk-session-modal/kiosk-session-modal.component';
import { ExhibitorStatusChangeComponent } from './components/exhibitor-service/exhibitor-status-change/exhibitor-status-change.component';
import { BoothDetailsFilterComponent } from './components/exhibitor-service/booth-details-filter/booth-details-filter.component';
import { EncryptHelper } from './helpers/encrypt-helper';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';

@NgModule({
    declarations: [
        AppComponent,
        SideMenuComponent,
        BottomMenuComponent,
        BulkUpdateModalComponent,
        NeedsAttentionComponent,
        PhotoVerificationComponent,
        AddNoteComponent,
        StatusWarningPopoverComponent,
        // BulkUpdateLineItemStatusModalComponent,
        BulkUpdateConfirmationModalComponent,
        KioskPrintConfirmationModalComponent,
        KioskLogoutConfirmationComponent,
        KioskSessionModalComponent,
        ExhibitorStatusChangeComponent,
        BoothDetailsFilterComponent
    ],
    imports: [
        MentionModule,
        BrowserModule,
        FormsModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        EventsPageModule,
        WorkticketsPageModule,
        VirtualScrollerModule,
        HttpClientModule,
        AngularMyDatePickerModule,
        IonicStorageModule.forRoot()
    ],
    providers: [
        AuthTokenHelper,
        AuthenticationService,
        AppBrowserHelper,
        GoogleAnalytics,
        EventService,
        NavParamHelper,
        FileExtensionHelper,
        File,
        FilterHelper,
        SearchHelper,
        CameraHelper,
        Camera,
        StatusBar,
        Keyboard,
        CameraLinkToPhotoVerificationHelper,
        PushNotificationService,
        StyleHelper,
        FileOpener,
        InAppBrowser,
        Storage,
        SocialSharing,
        Printer,
        EncryptHelper,
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
