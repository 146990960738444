import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { AlertController, IonRouterOutlet, MenuController, ModalController, Platform, PopoverController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { AddEventComponent } from 'src/app/components/add-event/add-event.component';
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';
import { AuthTokenHelper } from 'src/app/helpers/auth-token-helper';
import { Show } from 'src/app/models/show';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';
import { EventService } from 'src/app/services/event/event.service';
import { PushNotificationService } from 'src/app/services/push-notification/push-notification.service';
import { ShowAccountService } from 'src/app/services/show-account/show-account.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage';
import { ShowFiltersService } from 'src/app/services/show-setup/show-filters.service';

@Component({
	selector: 'app-events',
	templateUrl: './events.page.html',
	styleUrls: ['./events.page.scss'],
})
export class EventsPage implements OnInit {
	showActiveEvents = true;
	filteredShows: Show[];
	shows: Show[];
	subscribe = true;
	isLoading: boolean;
	firstLoad = false;
	searchActive: boolean;
	searchChange: Subject<string> = new Subject<string>();
	defaultSearchString: string;
	searchString: string;
	onPage: boolean;
	isDownloaderView = false;
	errorCount = 0;
	isAlphaNumericSort: boolean;
	constructor(public showAcctSvc: ShowAccountService,
		public router: Router,
		public eventSvc: EventService,
		public authTokenHelper: AuthTokenHelper,
		public toastService: ToastService,
		public appBrowserHelper: AppBrowserHelper,
		public dataSvc: DataRefreshService,
		public authSvc: AuthenticationService,
		public pushSvc: PushNotificationService,
		public platform: Platform,
		public popoverCtrl: PopoverController,
		public modalCtrl: ModalController,
		private ga: GoogleAnalytics,
		public menu: MenuController,
		public alertCtrl: AlertController,
		private routerOutlet: IonRouterOutlet,
		private storage: Storage,
		private showFiltersService: ShowFiltersService
		) { }

	async ngOnInit() {
		this.isDownloaderView = true;
		let isKioskUser = await this.authTokenHelper.IsKioskUser();
		if (isKioskUser)
			this.router.navigate(['kiosk-authorization']);
		this.authTokenHelper.checkUserType();
		this.shows = [];
		this.eventSvc.eventLoading.pipe(takeWhile(() => this.subscribe)).subscribe(loading => {
			this.isLoading = loading;
		});
		if (this.appBrowserHelper.isApp) {
			this.pushSvc.registerForPush();
		}
	}

	ngAfterViewInit() {
		this.eventSvc.events.pipe(takeWhile(() => this.subscribe)).subscribe(shows => {
			this.shows = shows;
			if (this.firstLoad) {
				this.clearInput();
				this.firstLoad = false;
				this.isLoading = false;
			} else if (this.shows && !this.searchString && this.onPage) {
				this.clearInput();
			}

		});
		this.firstLoad = true;
	}

	ngOnDestroy() {
		this.subscribe = false;
	}

	ionViewWillEnter() {
		this.isAlphaNumericSort = false;
		this.onPage = true;
		this.eventSvc.get();
		this.eventSvc.clearData();
		this.storage.set('isESAccountInfoActive', false);
		// if (this.platform.is('mobileweb') && document.URL.startsWith('http') && !document.URL.startsWith('http://localhost:80')) {
		// 	this.toastService.open('Did you know we have a mobile app? Tap here to download the app for your device.', 'primary', true, 5000);
		// }
	}

	ionViewDidLeave() {
		this.clearInput();
		this.onPage = false;
	}

	ionViewDidLoad() {
		this.ga.trackView('Events Page')
			.catch(e => console.log(e));
	}

	refresh(refresher): void {
		this.eventSvc.refresh();
		refresher.target.complete();
	}

	getNewOpsPageViewPermission(): boolean {
		var retValue = false;
		var userName = this.authTokenHelper.currentUserName.getValue().toLowerCase();
		var userList = environment.userList;
		if (userList().toLowerCase().includes(userName) || userList() === '') {
			retValue = true
		}

		return retValue;
	}

	goToNextPage(eventId: string): void {
		const showAcctDto = this.showAcctSvc.showAccounts.getValue();
		const currentShow = this.showAcctSvc.currentShowAccount.getValue();
		if (!showAcctDto || !currentShow || currentShow.kafkaId !== eventId) {
			this.showAcctSvc.showAccounts.next(null);
		}
		this.dataSvc.eventActive = eventId;
		this.ga.trackEvent('EventAccessed', this.shows.find(s => s.kafkaId === eventId).name);
		if (this.authTokenHelper.isOpsUser) {
			// this.getNewOpsPageViewPermission() == false ? this.goToWorkTicketsPage(eventId) :
			this.goToOpsUserLineItemListPage(eventId);
		} else if (this.authTokenHelper.isExhibitUser) {
			this.router.navigate(['events', eventId, 'es-booths']);
		}
		else {
			this.goToAccountsPage(eventId);
		}
	}

	async addEvent() {
		const modal = await this.modalCtrl.create({
			component: AddEventComponent,
			cssClass: 'add-event-modal modal-height-wrapper',
			swipeToClose: true,
			backdropDismiss: false,
			presentingElement: this.routerOutlet.nativeEl
		});
		return await modal.present();
	}

	goToAccountsPage(eventId: string): void {
		this.router.navigate(["/events", eventId, 'accounts']);
	}

	goToWorkTicketsPage(eventId: string): void {
		this.router.navigate(["/events", eventId, 'worktickets']);
	}

	goToOpsUserLineItemListPage(eventId: string): void {
		this.showFiltersService.resetFilters = true;
		this.showFiltersService.currentShowId = eventId;
		this.router.navigate(["/events", eventId, 'ops-user-line-item-list']);
	}

	favoriteEvent(eventId: string) {
		this.eventSvc.favoriteShow(eventId);
	}

	async removeEventForUser(event: string, eventSliding?: any) {
		if (eventSliding) {
			eventSliding.close();
		}
		const alert = await this.alertCtrl.create({
			cssClass: 'my-custom-class',
			header: 'Remove Show',
			message: 'Are you sure you want to disassociate yourself from this event?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
					cssClass: 'secondary',
					handler: (blah) => {

					}
				}, {
					text: 'Confirm',
					handler: () => {
						this.eventSvc.removeShow(event);
					}
				}
			]
		});
		await alert.present();
	}

	searchChanged() {
		this.searchChange.next(null);
	}

	clearInput() {
		this.searchString = '';
	}

	dismissDownloader() {
		this.isDownloaderView = false;
	}

	redirectToDownload() {
		//window.open('android-app://com.freemanco.onsitechecklist.dev', '_self', 'location=no');;
		this.router.navigate(['download']);
	}

	redirectToErrorLog() {
		this.errorCount += 1;
		if(this.errorCount == 5)
		{	
		this.router.navigate(['error-log']);
		this.errorCount = 0;
		}
	}

}
