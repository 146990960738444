import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastService } from '../toast/toast.service';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';

/*
  Generated class for the NewOrderProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: 'root'
})
export class NewOrderProvider {
	public searchParts: any[] = [];
	public boothData: any[] = [];
	public facilityData: any[] = [];
	public isSearchPartStored: boolean = false;
	url = environment.apiUrl;

	constructor(public http: HttpClient, public toastService: ToastService,) {
		console.log('Hello NewOrderProvider Provider');
	}

	getNewOrderFunctionalAreas(eventId: string, accountId: string): Observable<any[]> {
		return this.http.get<any[]>(`${this.url()}events/${eventId}/accounts/${accountId}/change-orders/showFunctionalArea`)
			.pipe(map(showFAData => {
				return showFAData;
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error getting Functional Areas.', 'danger');
				}
			}));
	}

	getNewOrderFacilities(eventId: string, accountId: string): Observable<any[]> {
		return this.http.get<any[]>(`${this.url()}events/${eventId}/accounts/${accountId}/change-orders/showFacilities`)
			.pipe(map(facilityData => {
				this.facilityData = [...facilityData];
				return facilityData;
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error getting Facilities',  'danger');
				}
			}));
	}

	getNewOrderBooths(eventId: string, accountId: string): Observable<any[]> {
		return this.http.get<any[]>(`${this.url()}events/${eventId}/accounts/${accountId}/change-orders/showBooths`)
			.pipe(map(boothsData => {
				this.boothData = [...boothsData];
				return boothsData;
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error getting Booths.',  'danger');
				}
			}));
	}

	getNewOrderAllParts(eventId: string, accountId: string): Observable<any[]> {
		return this.http.get<any[]>(`${this.url()}events/${eventId}/accounts/${accountId}/change-orders/searchParts`)
			.pipe(map(searchPartData => {
				this.searchParts = [...searchPartData];
				return searchPartData;
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error getting all parts.', 'danger');
				}
			}));
	}

	getNewOrderLocations(eventId: string, accountId: string, facilityId: string): Observable<any[]> {
		return this.http.get<any[]>(`${this.url()}events/${eventId}/accounts/${accountId}/change-orders/showLocations/${facilityId}`)
			.pipe(map(locationData => {
				return locationData;
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error getting locations.', 'danger');
				}
			}));
	}

	getSelectableOptions(eventId: string, accountId: string, functionalAreaId: string, lineItemId: string, partId: string): Observable<any[][]> {
		return this.http.get<any[]>(`${this.url()}events/${eventId}/accounts/${accountId}/functional-areas/${functionalAreaId}/line-items/${lineItemId}/partSelections/${partId}`)
			.pipe(map(selectableOptionData => {
				let selectableDataArray = [];
				let sortedData = _.sortBy(selectableOptionData, ['selectableLevel', 'basePartId']);

				while (sortedData.length > 0) {
					let matchDataObject = sortedData[0];
					selectableDataArray.push(_.remove(sortedData, function (dataObject) {
						return dataObject.basePartId == matchDataObject.basePartId;
					}));
				}

				return selectableDataArray;
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error getting selectable options.', 'danger');
				}
			}));
	}

	getCollaborativePartStatus(eventId: string, accountId: string, partId: string): Observable<boolean> {
		return this.http.get<boolean>(`${this.url()}events/${eventId}/accounts/${accountId}/change-orders/IsCollabPart/${partId}`)
			.pipe(map(partStatus => {
				return partStatus;
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error getting part status.', 'danger');
				}
			}));
	}

	getSearchPartsdata(eventId, accountId){
		forkJoin([this.getNewOrderAllParts(eventId, accountId), this.getNewOrderBooths(eventId, accountId), this.getNewOrderFacilities(eventId, accountId)]).subscribe(results => {
			this.isSearchPartStored = true;
		  });
	}

	checkSearchPartsData(eventId, showAccountGUID){
		if (!this.isSearchPartStored) {
			this.getSearchPartsdata(eventId, showAccountGUID);
		}
	}	
}
