import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { FilterHelper } from 'src/app/helpers/filter-helper';
import { SelectHelper } from 'src/app/helpers/select-helper';
import { LineItemService } from 'src/app/services/line-item/line-item.service';
import { WorkTicketService } from 'src/app/services/work-ticket/work-ticket.service';

@Component({
  selector: 'occ-filter-location',
  templateUrl: './filter-location.component.html',
  styleUrls: ['./filter-location.component.scss'],
})
export class FilterLocationComponent {
	@Output() closeFilter = new EventEmitter();
	@Output() navigate = new EventEmitter();

	locations: string[];
	isWorkTicketSearchPage: false;
	pageReturn: string;

	subscribe = true;

	constructor(public lineItemSvc: LineItemService, public wktkSvc: WorkTicketService, public filterHelper: FilterHelper) {
		if (this.filterHelper.workticketSearchPage) {
			this.pageReturn = 'workticketPage';
		}

		if (this.filterHelper.lineItemStatusSelected) {
			this.lineItemSvc.locationListSubject.pipe(takeWhile(() => this.subscribe)).subscribe(locationList => {
				this.locations = locationList.sort();
			 });
		} else {
			this.wktkSvc.locationWorkSubject.pipe(takeWhile(() => this.subscribe)).subscribe(locationList => {
				this.locations = locationList.sort();
			});
		}
	}

	hideFilter(): void {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		this.closeFilter.emit();
	}

	showPage(pageToShow): void {
		this.navigate.emit(pageToShow);
	}

	applyFilter(location: string): void {
		if (this.filterHelper.lineItemStatusSelected) {
			this.filterHelper.setFilter(location, this.filterHelper.liLocation, 'LineItemLocationFilter');
		} else {
			this.filterHelper.setFilter(location, this.filterHelper.wtLocation, 'WorkTicketLocationFilter');
		}
	}

	getCurrentLocation(location: string): boolean {
		if (this.filterHelper.lineItemStatusSelected) {
			if (this.filterHelper.liLocation.find(x => x === location)) return true;
		} else {
			if (this.filterHelper.wtLocation.find(x => x === location)) return true;
		}
	}

	resetFilter(): void {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		if (this.filterHelper.lineItemStatusSelected) {
			this.filterHelper.liLocation = [];
		} else {
			this.filterHelper.wtLocation = [];
		}
		this.filterHelper.applyFilters();
	}
}
