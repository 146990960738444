import { Component, Input, OnInit } from '@angular/core';
import { AuthTokenHelper } from 'src/app/helpers/auth-token-helper';
import { SelectHelper } from 'src/app/helpers/select-helper';
import { FATabsEnum } from 'src/app/helpers/tabs-enum';
import { CollaborationLineItemDTO } from 'src/app/models/collaboration-line-item-dto';
import { FunctionalArea } from 'src/app/models/functional-area';
import { BoothServiceProvider } from 'src/app/services/booth/booth.service';
import { FunctionalAreaService } from 'src/app/services/functional-area/functional-area.service';
import { LineItemService } from 'src/app/services/line-item/line-item.service';
import { takeWhile } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'occ-details-line-item',
	templateUrl: './details-line-item.component.html',
	styleUrls: ['./details-line-item.component.scss'],
})
export class DetailsLineItemComponent implements OnInit {
	@Input() functionalAreaId?: string;
	@Input() boothId?: string;
	@Input() lineItemId: string;
	@Input() eventId?: string;
	@Input() showAccountGuId?: string;

	tab: FATabsEnum;
	functionalAreaDto: FunctionalArea[];
	lineItem: CollaborationLineItemDTO;
	itemExpanded = false;
	ticketExpanded = false;
	salesUser = false;
	lineitemData = new CollaborationLineItemDTO();
	subscribe = true;
	groupNumber: string;
	constructor(
		public funcSvc: FunctionalAreaService,
		public lineItemSvc: LineItemService,
		public authTokenHelper: AuthTokenHelper,
		public boothService: BoothServiceProvider,
		private router: Router,
		private activatedRoute: ActivatedRoute
	) {

	}

	ngOnInit(): void {
		if (!this.authTokenHelper.isExhibitUser) {
			if (this.boothId) {
				this.boothService.activeBoothLineItem.pipe(takeWhile(() => this.subscribe)).subscribe(boothLIData => {
					this.lineItem = boothLIData;
					this.setGroupNumber();
				});
			} else {
				this.funcSvc.allFunctionalAreasSubject.pipe(takeWhile(() => this.subscribe)).subscribe(faDto => {
					this.functionalAreaDto = faDto;
				});
				this.lineItemSvc.currentLineItem.pipe(takeWhile(() => this.subscribe)).subscribe(lineItem => {
					this.lineItem = lineItem;
					this.setGroupNumber();
				});
			}
			this.setupPage();
		} else {
			this.boothService.getBoothLiDetails(this.eventId, this.showAccountGuId, this.lineItemId).subscribe(lineitemData => {
				this.lineitemData = lineitemData;
			}, error => {
			});
		}
	}

	setGroupNumber() {
		//Convert any negative quantity (credit line item) to postive quantity
		if (this.lineItem) {
			this.lineItem.quantity = Math.abs(this.lineItem.quantity);
			var partNumberSplit = this.lineItem.partNumber.toString().split('-');
			this.groupNumber = partNumberSplit[0];
		}
	}

	async setupPage() {
		this.salesUser = await this.authTokenHelper.IsSalesUser();
	}

	setTab(tab: FATabsEnum) {
		this.tab = tab;
	}

	isSelected(tab: FATabsEnum) {
		return this.tab === tab;
	}

	toggleItemComment() {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		if (this.lineItem.vendorComments)
			this.itemExpanded = !this.itemExpanded;
	}

	toggleTicketComment() {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		if (this.lineItem.ticketComments)
			this.ticketExpanded = !this.ticketExpanded;
	}

	navigateToBoothDetails() {
		let boothGUID =  this.activatedRoute.snapshot.paramMap.get('boothId') ;
		this.router.navigateByUrl('events/' + this.eventId + '/es-booths/' + boothGUID + '/exhibitor-booth-details');
	}
}
