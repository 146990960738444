import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthTokenHelper } from 'src/app/helpers/auth-token-helper';

@Component({
  selector: 'occ-mhasubmit-success',
  templateUrl: './mhasubmit-success.component.html',
  styleUrls: ['./mhasubmit-success.component.scss'],
})
export class MHASubmitSuccessComponent implements OnInit {

  @Input() isDraft: boolean;

  isEndSession: boolean = false;
  isKioskUser: boolean = false;

  constructor(
    public modalCtrl: ModalController,
    public tokenHelper: AuthTokenHelper,
  ) { }

  async ngOnInit() { 
    this.isKioskUser = await this.tokenHelper.IsKioskUser();
  }

  closeModal(actionCode: number) {
    this.modalCtrl.dismiss({
      "actionCode": actionCode
    });
  }

  endKioskSession() {
    this.isEndSession = true;
    setTimeout(async () => {
      let currentModal = await this.modalCtrl.getTop();
      if (currentModal && currentModal.id == "mha-submit-success") {
        this.closeModal(3);
      }
    }, 5000);
  }
}
