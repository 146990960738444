import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { KioskService } from 'src/app/services/kiosk/kiosk.service';
import { Storage } from '@ionic/storage';
import { ModalController } from '@ionic/angular';
import { MOPNotFoundComponent } from '../mop-not-found/mop-not-found.component';
import { AcknowledgeFreightPackageComponent } from '../acknowledge-freight-package/acknowledge-freight-package.component';
import { CreateMhaService } from 'src/app/services/create-mha/create-mha.service';
import { MHASubmitSuccessComponent } from '../mhasubmit-success/mhasubmit-success.component';
import { KioskSessionService } from 'src/app/services/kiosk/kiosk-session.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthTokenHelper } from 'src/app/helpers/auth-token-helper';

@Component({
  selector: 'mha-confirmation',
  templateUrl: './mha-confirmation.component.html',
  styleUrls: ['./mha-confirmation.component.scss'],
})
export class MhaConfirmationComponent implements OnInit {

  @Output() onShowConfirmationPopup = new EventEmitter<any>();  
  @Output() cancelMhaCreation = new EventEmitter();
  @Output() loadPreviousMHASection = new EventEmitter<any>();
  
  isTermAgreed: any;
  currentBoothId: string = '';
  boothNumber: string = '';

  constructor(
    public createMhaService: CreateMhaService,
    public kioskService: KioskService,
    public modalCtrl: ModalController,
    public kioskSessionService: KioskSessionService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private storage: Storage,    
    public tokenHelper: AuthTokenHelper
  ) { }

  ngOnInit() {
    this.currentBoothId = this.activatedRoute.snapshot.paramMap.get('boothGuid');
    this.boothNumber = this.activatedRoute.snapshot.paramMap.get('boothNumber');

    if (this.createMhaService.isMHASubmitted) {
      this.isTermAgreed = this.createMhaService.isMHASubmitted;
    }
  }

  async openConfirmationPopup() {
    if(this.createMhaService.isMHASubmitted){
      this.cancelMhaCreation.emit();
      return;
    }

    if(this.tokenHelper.IsExhibitUser){
      this.onShowConfirmationPopup.emit();
      return;
    }

    if(this.kioskService.getShowCloseStatus()){
      const currentBoothInfo = await this.storage.get('currentBooth');
      if(currentBoothInfo && currentBoothInfo.usePrimaryCardInd == 'Y'){
        const freightAckModal = await this.modalCtrl.create({
          component: AcknowledgeFreightPackageComponent,
          cssClass: 'freight-acknowledge-modal',
          backdropDismiss: false,
          componentProps: {isAcknowledgementVisible: true}
        });
        await freightAckModal.present();
        const { data } = await freightAckModal.onDidDismiss();
        if(data){
          if(data.isSaveAsDraft){
            this.showModal({
              isAcknowledgementVisible: false,
              isDraftMessageVisible: true,
              isPrintDraft: true
            });
          }else if(data.isSubmitMHA){
            const mhaSubmitSuccessModal = await this.modalCtrl.create({
              id: 'mha-submit-success',
              component: MHASubmitSuccessComponent,
              cssClass: 'mha-submit-sucess-modal',
              backdropDismiss: false
            });
            await mhaSubmitSuccessModal.present();
            mhaSubmitSuccessModal.onDidDismiss().then((codeData) => {
              console.log(codeData.data);
              if(codeData.data){
                switch (codeData.data.actionCode) {                
                  case 1:
                    this.router.navigate([this.currentBoothId + '/'+ this.boothNumber + '/kiosk-outbound-shipping-labels']); 
                    break;
                
                  case 2:
                    this.showModal({
                      isAcknowledgementVisible: false,
                      isPrintViewVisible: true
                    });
                    break;
                
                  case 3:
                    this.kioskSessionService.clearKioskDetails();
                    break;
                
                  default:
                    this.router.navigate([this.currentBoothId + '/'+ this.boothNumber + '/kiosk-outbound-shipping-labels']); 
                    break;
                }
              }
            });
          }          
        }
      } else {
        const mopNotFoundModal = await this.modalCtrl.create({
          component: MOPNotFoundComponent,
          cssClass: 'mop-not-found-modal',
          backdropDismiss: false
        });
        await mopNotFoundModal.present();
        const { data } = await mopNotFoundModal.onDidDismiss();
        if(data) {
          this.showModal({
            isAcknowledgementVisible: false,
            isPrintViewVisible: true,
            isDraft: true,
          })
        }
      }
    } else {
      this.onShowConfirmationPopup.emit();
    }    
  }

  showModal(modalData: any){
    setTimeout(async () => {
      let classNames = modalData.isDraftMessageVisible ? 'draft-saved-modal' : 'mha-submit-print-modal';
      const ackModal = await this.modalCtrl.create({
        component: AcknowledgeFreightPackageComponent,
        cssClass: classNames,
        backdropDismiss: false,
        componentProps: modalData
      });
      await ackModal.present();

      ackModal.onDidDismiss().then(data => {
        if(modalData.isDraftMessageVisible) {
          this.showModal({
            isAcknowledgementVisible: false,
            isPrintViewVisible: true,
            isDraft: true,
          });
        }
      })
    }, 100);
  }

  getMOPStatus(){

  }

  cancelMHACreation() {
    this.cancelMhaCreation.emit();    
  }

  goToPreviousStep() {
    this.loadPreviousMHASection.emit(4);
  }

  getSaveButtonTitle(){
    return this.kioskService.getShowCloseStatus() ? 'Confirm & Submit' : 'Save & Complete Draft';
  }

  getNonEditCarrierInfo() {
    if (this.createMhaService.createMhaData.carrier.isExhibitTransportation == 1) {
      return 'Freeman Transportation';
    } else {
      return this.createMhaService.createMhaData.carrier.otherCarrierName ? this.createMhaService.createMhaData.carrier.otherCarrierName : this.createMhaService.createMhaData.carrier.anotherCarrierInfo.display;
    }
  }
}
