import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeWhile } from 'rxjs/operators';
import { ShowSearch } from 'src/app/models/show-search';
import { EventService } from 'src/app/services/event/event.service';

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.scss'],
})
export class AddEventComponent implements OnInit {
  requestedShowResults: ShowSearch[];
	requestedShow: string;
	userSearch = new BehaviorSubject<string>('');
	subscribe = true;
	isLoading = false;
  constructor(public modalCtrl: ModalController,
		public eventSvc: EventService,
		public alertCtrl: AlertController) {
      this.requestedShow = '';
		  this.requestedShowResults = [];
     }

  ngOnInit() {
    this.eventSvc.addedEvent.pipe(takeWhile(() => this.subscribe)).subscribe(data => {
      this.requestedShowResults = data;
    });
		this.userSearch.pipe(debounceTime(400)).subscribe(query => {
			if (query.length > 0) {
				this.eventSvc.requestShow(query);
			} else {
				this.requestedShowResults = [];
			}
		});
		this.eventSvc.showLoading.subscribe(l => this.isLoading = l);
  }

  dismiss() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
	}

	clearInput() {
		this.requestedShow = '';
		this.requestedShowResults = [];
	}

	clear() {
		this.eventSvc.addedEvent.next(null);
	}

	searchEvent() {
		this.eventSvc.requestShow(this.requestedShow);
	}

	addShow(showToAdd: ShowSearch) {
		this.addVerification(showToAdd.kafkaId);
	}

	async addVerification(kafkaId: string) {
		const response = new Subject<boolean>();
		response.subscribe(data => {
			if (data) {
				this.requestedShowResults.filter(x => x.kafkaId === kafkaId).forEach(x => x.isInShow = true);
			}
		});
			const alert = await this.alertCtrl.create({
			  cssClass: 'my-custom-class',
			  header: 'Add Show',
			  message: 'Are you sure you want to add yourself to this show?',
			  buttons: [
				{
				  text: 'Cancel',
				  role: 'cancel',
				  cssClass: 'secondary',
				  handler: (blah) => {
					
				  }
				}, {
				  text: 'Confirm',
				  handler: () => {
					this.addShows(kafkaId, response);
				  }
				}
			  ]
			});
		
			await alert.present();
	}

	addShows(kafkaId ,response) {
		this.eventSvc.addShow(kafkaId).subscribe(data => {
			response.next(data);
			response.complete();
		});
		this.dismiss();
	  }

	ngOnDestroy() {
		this.eventSvc.addedEvent.next([]);
		this.requestedShow = '';
		this.eventSvc.errorSearchingForShow = false;
		this.subscribe = false;
	}

}
