import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PartSelectionDto } from 'src/app/models/change-order-dto';
import { SelectableDropdownData } from 'src/app/models/selectable-dropdown-data';
import { SelectableOption } from 'src/app/models/selectable-option';
import { SelectOptionModalComponent } from '../select-option-modal/select-option-modal.component';

@Component({
  selector: 'occ-selectable-options',
  templateUrl: './occ-selectable-options.component.html',
  styleUrls: ['./occ-selectable-options.component.scss'],
})
export class OccSelectableOptionsComponent implements OnInit {
  @Input() partDesc: string;
  @Input() selectableOptionData: SelectableOption[][];
  @Input() previousSelectedData: any[];
  @Input() isChangeOrder: boolean;

  @Output() onSelectionChanged: EventEmitter<any> = new EventEmitter<any>();

  items: SelectableDropdownData[] = [];

  constructor(public modalCtrl: ModalController) {

  }

  ngOnInit(): void {
    this.getDropDownOptions();

    if (this.previousSelectedData !== null && this.previousSelectedData.length > 0) {
      this.onSelectionChanged.emit(this.previousSelectedData);
    }
  }

  getDropDownOptions() {
    let matchedSOData = this.getPreviousSelection(this.selectableOptionData[0][0].partDescription);
    this.items.push({
      partDesc: this.selectableOptionData[0][0].partDescription,
      dataModel: matchedSOData ? matchedSOData.materialSubTypeDescription : "",
      mobileSelectionValue: matchedSOData ? matchedSOData.materialSubTypeDescription : "",
      options: this.selectableOptionData[0]
    });

    if (matchedSOData) {
      this.getNextSelectableOptions(1);
    }
  }

  optionChanged(data, dropdownIndex) {
    this.items[dropdownIndex].options.forEach((option) => {
      if (option.materialSubTypeId == data) {
        this.items[dropdownIndex].mobileSelectionValue = option.materialSubTypeDescription;
      }
    });

    this.setDataValues();

    if (data === 0) {
      this.clearSelection(dropdownIndex);
    } else {
      this.getNextSelectableOptions(dropdownIndex + 1);
    }
  }

  setDataValues() {
    let selectionDataArray: PartSelectionDto[] = [];
    this.items.forEach((item) => {
      var selectedDataObject = item.options.find((option) => {
        return option.materialSubTypeDescription == item.dataModel.toString()
      });
      if (selectedDataObject) {
        selectionDataArray.push({
          materialSubTypeDescription: item.mobileSelectionValue,
          partDescription: item.partDesc,
          basePartId: selectedDataObject.basePartId,
          passportPartId: selectedDataObject.passportPartId
        });
      }
    });

    this.onSelectionChanged.emit(selectionDataArray);
  }

  getNextSelectableOptions(optionsArrayIndex) {
    if (this.items.length < (optionsArrayIndex + 1)) {
      if (this.selectableOptionData[optionsArrayIndex]) {
        let matchedSOData = this.getPreviousSelection(this.selectableOptionData[optionsArrayIndex][0].partDescription);
        this.items.push({
          partDesc: this.selectableOptionData[optionsArrayIndex][0].partDescription,
          dataModel: matchedSOData ? matchedSOData.materialSubTypeDescription : "",
          mobileSelectionValue: matchedSOData ? matchedSOData.materialSubTypeDescription : "",
          options: this.selectableOptionData[optionsArrayIndex]
        });

        if (matchedSOData) {
          this.getNextSelectableOptions(optionsArrayIndex + 1);
        }
      }
    }
  }

  clearSelection(selectedIndex) {
    this.items[selectedIndex].dataModel = "";
    this.items.splice(selectedIndex + 1, this.items.length - 1);
  }

  async presentSelectableOptionModal(selectedIndex) {
    const optionModal = await this.modalCtrl.create({
      component: SelectOptionModalComponent,
      componentProps: { optionsData: this.items[selectedIndex] }
    });
    await optionModal.present();
    await optionModal.onDidDismiss().then(newSelectionData => {
      if (newSelectionData) {
        newSelectionData.data.mobileSelectionValue = "";
        this.items[selectedIndex].options.forEach((option) => {
          if (option.materialSubTypeId == newSelectionData.data) {
            newSelectionData.data.mobileSelectionValue = option.materialSubTypeDescription;
            newSelectionData.data = newSelectionData.data.mobileSelectionValue;
          }
        });
        this.items[selectedIndex].dataModel = newSelectionData.data;
        this.setDataValues();
        if (newSelectionData.data == "") {
          this.clearSelection(selectedIndex);
        } else {
          this.getNextSelectableOptions(selectedIndex + 1);
        }
      }
    });
  }

  getPreviousSelection(partDesc) {
    if (Array.isArray(this.previousSelectedData)) {
      return this.previousSelectedData.find((dataItem) => {
        return dataItem.partDescription == partDesc;
      });
    } else {
      return;
    }
  }
}

