
export enum FATabsEnum {
	Details,
	Activity,
	Attachments,
	LineItems,
}

export enum BoothTabsEnum {
	Details,
	Activity,
	Attachments,
	LineItems,
}

export enum LITabsEnum {
	Details,
	Activity,
	Files,
}

export enum WTTabsEnum {
	Details,
	Activity,
	Attachments,
	LineItems,
}

export enum ATTabsEnum {
	Activity,
	Notifications
}
