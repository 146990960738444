import { NgModule } from "@angular/core";
import { FunctionalAreaSearchPipe } from "./functional-area-search.pipe";
import { LineItemSearchPipe } from "./line-item-search.pipe";
import { ShowHideSearchPipe } from "./show-hide-search.pipe";
import { WorkTicketGroupPipe } from "./work-ticket-group.pipe";
import { SearchFilterPipe } from './search-filter.pipe';
import { EsBoothsFilterPipe } from './es-booths-filter.pipe';
import { EsBoothListFilterPipe } from './es-booth-list-filter.pipe';
import { AlphabeticSortPipe } from './alphabetic-sort.pipe';

@NgModule({
	declarations: [
        FunctionalAreaSearchPipe,
		LineItemSearchPipe,
		ShowHideSearchPipe,
        WorkTicketGroupPipe,
        SearchFilterPipe,
        EsBoothsFilterPipe,
        EsBoothListFilterPipe,
        AlphabeticSortPipe
	],
	imports: [],
	exports: [
		FunctionalAreaSearchPipe,
		LineItemSearchPipe,
		ShowHideSearchPipe,
        WorkTicketGroupPipe,
		SearchFilterPipe,
		EsBoothsFilterPipe,
		EsBoothListFilterPipe,
		AlphabeticSortPipe
	]
})
export class PipesModule { }