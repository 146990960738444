import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import * as $ from "jquery";

@Component({
  selector: 'occ-country-code',
  templateUrl: './country-code.component.html',
  styleUrls: ['./country-code.component.scss'],
})
export class CountryCodeComponent implements OnInit {

  @Input() countryCodes: any;
  @Input() defaultCountryCode: any;
  @Output() onCountryCodeSelection = new EventEmitter<any>();

  currentCountryCode: string = "+1";
  constructor() { }

  ngOnInit() {
    this.setupSortByDropdown();
    this.currentCountryCode = this.defaultCountryCode && this.defaultCountryCode.display ? this.defaultCountryCode.display : "+1"
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.defaultCountryCode){
      this.currentCountryCode = changes.defaultCountryCode.currentValue ? changes.defaultCountryCode.currentValue.display : "+1";
    }
  }

  setupSortByDropdown() {
    $('.dropdown').click(function () {
      $(this).attr('tabindex', 1).focus();
      $(this).toggleClass('active');
      $(this).find('.dropdown-menu').slideToggle(300);
    });
    $('.dropdown').focusout(function () {
      $(this).removeClass('active');
      $(this).find('.dropdown-menu').slideUp(300);
    });
    $('.dropdown .dropdown-menu li').click(function () {
      $(this).parents('.dropdown').find('input').attr('value', $(this).attr('id'));
    });
  }

  setCountryCodeSelection(countryCode: any) {
    this.currentCountryCode = countryCode.display;
    this.onCountryCodeSelection.emit(countryCode);
  }
}

