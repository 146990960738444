import { FunctionalAreaStatusChangeUploadDto } from './../../models/functional-area-status-change-upload-dto';
import { StatusChangeDownloadDto } from './../../models/status-change-download-dto';
import { Status } from './../../models/status';
import { ToastService } from './../toast/toast.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { FunctionalAreaSorter } from '../../helpers/functional-area-sorter';
import { ActivityService } from '../activity/activity.service';
import { StatusHelper } from '../../helpers/status-helper';
import { CollaborationLineItemDTO } from '../../models/collaboration-line-item-dto';
import { FATabsEnum } from '../../helpers/tabs-enum';
import { FunctionalArea } from '../../models/functional-area';
import { LoadingController } from '@ionic/angular';
import *as moment from 'moment';
import { UserService } from '../user/user.service';
import { Owner } from '../../models/owner';
import { AuthTokenHelper } from '../../helpers/auth-token-helper';
import { ToastType } from '../../models/toast-type';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { FileDownloadHelper } from 'src/app/helpers/file-download-helper';

@Injectable({
  providedIn: 'root'
})
export class FunctionalAreaService {

	url = environment.apiUrl;
	allFunctionalAreasSubject = new BehaviorSubject<FunctionalArea[]>([]);
	currentFuncAreaSubject = new BehaviorSubject<FunctionalArea>(null);
	activeFunctionalAreaId = new BehaviorSubject<string>(null);
	ownerList = new BehaviorSubject<Owner[]>([]);
	isLoading = new BehaviorSubject<boolean>(false);

	public currentFunctionalAreas: FunctionalArea[];
	public currentFunctionalArea: FunctionalArea;
	public activeFunctionalAreaIdValue: string;
	public currentTab = environment.environment === 'UAT' ? FATabsEnum.Attachments : FATabsEnum.LineItems;

	constructor(
		public http: HttpClient,
		public activitySvc: ActivityService,
		public toastService: ToastService,
		public loadCtrl: LoadingController,
		public userSvc: UserService,
		public authTokenHelper: AuthTokenHelper,
		private ga: GoogleAnalytics,
		private downloadHelper: FileDownloadHelper
	) {
		this.allFunctionalAreasSubject.subscribe(dto => {
			this.currentFunctionalAreas = dto;
		});
		this.currentFuncAreaSubject.subscribe(dto => {
			this.currentFunctionalArea = dto;
		});
		this.activeFunctionalAreaId.subscribe(id => {
			this.activeFunctionalAreaIdValue = id;
		});
	}

	activateFunctionalArea(functionalAreaId: string) {
		const functionalAreaToSetAsCurrent = this.currentFunctionalAreas.find(fa => fa.functionalAreaGuid === functionalAreaId);
		this.currentFuncAreaSubject.next(functionalAreaToSetAsCurrent);
		this.currentFunctionalArea = functionalAreaToSetAsCurrent;
		this.activeFunctionalAreaId.next(functionalAreaId);
	}

	get(eventId: string, projectAccountId: string, replaceStatusOfFunctionalArea?: boolean): void {
		this.isLoading.next(true);
		this.refresh(eventId, projectAccountId, null, replaceStatusOfFunctionalArea);
	}

	setFaReferenceToFaDTO(): void {
		const faDto = this.currentFunctionalAreas;
		const faGuid = this.currentFunctionalArea ? this.currentFunctionalArea.functionalAreaGuid : this.activeFunctionalAreaIdValue;

		if (!faDto || faDto.length === 0 || !faGuid) {
			return;
		}

		const faDetailDto: FunctionalArea = faDto.find(fa => fa.functionalAreaGuid === faGuid);
		this.currentFuncAreaSubject.next(faDetailDto);
	}

	getBreadcrumbsForFa(allFunctionalAreas: FunctionalArea[], functionalArea: FunctionalArea): any {
		if (functionalArea.parentFunctionalAreaGuid === null || !allFunctionalAreas.find(fa => fa.functionalAreaGuid === functionalArea.parentFunctionalAreaGuid)) {
			return { parent: '', grandparent: '', grandparentFunctionalAreaGuid: '' };
		}

		const parent = allFunctionalAreas.find(fa => fa.functionalAreaGuid === functionalArea.parentFunctionalAreaGuid);
		if (parent.parentFunctionalAreaGuid === null || !allFunctionalAreas.find(gp => gp.functionalAreaGuid === parent.parentFunctionalAreaGuid)) {
			return { parent: parent.name, grandparent: '', grandparentFunctionalAreaGuid: '' };
		}

		const grandParent = allFunctionalAreas.find(gp => gp.functionalAreaGuid === parent.parentFunctionalAreaGuid);
		return { parent: parent.name, grandparent: grandParent.name, grandparentFunctionalAreaGuid: grandParent.functionalAreaGuid };
	}

	setParentValue(allFunctionalAreas: FunctionalArea[], functionalArea: FunctionalArea) {
		return allFunctionalAreas.findIndex(fa => fa.parentFunctionalAreaGuid === functionalArea.functionalAreaGuid) > -1;
	}

	setBreadcrumbs(functionalAreas: FunctionalArea[]): void {
		functionalAreas.forEach(functionalArea => {
			functionalArea.breadCrumbs = this.getBreadcrumbsForFa(functionalAreas, functionalArea);
			functionalArea.isParent = this.setParentValue(functionalAreas, functionalArea);
		});

	}

	// Send line items subject from line item service to avoid circular reference
	put(eventId: string, accountId: string, functionalAreaId: string, newStatus: Status, lineItemsSubject: BehaviorSubject<CollaborationLineItemDTO[]>, lineItems?: CollaborationLineItemDTO[], noteBody?: string, photoUri?: string) {
		// lookup functional area in the DTO by the id from the params
		const functionalArea = this.lookupFunctionalAreaById(functionalAreaId);
		const usersToNotify = noteBody ? this.userSvc.verifyMentionedUsers(noteBody) : [];

		// make a copy of the original before changing the status
		const original: FunctionalArea = JSON.parse(JSON.stringify(functionalArea));
		let lineItemStatusesToRevertTo: CollaborationLineItemDTO[];
		const lineItemSvcLineItems = lineItemsSubject.getValue();
		const originalLineItemSvcLineItems = JSON.parse(JSON.stringify(lineItemSvcLineItems));

		functionalArea.status = newStatus;
		functionalArea.statusId = newStatus.id;
		functionalArea.statusDate = moment.utc(moment.now()).toDate();

		// After changing the FA status, we need to put the updated functional area into our canonical list of FAs to it appears to update instantly.
		// Remember that currentFunctionalAreas subscribes to allFunctionalAreasSubject.
		this.allFunctionalAreasSubject.next(this.currentFunctionalAreas);

		this.setFaReferenceToFaDTO();

		const uploadDto: FunctionalAreaStatusChangeUploadDto = {
			lineItemGuid: null,
			noteBody: noteBody,
			photoUri: photoUri,
			functionalAreaStatus: newStatus.id,
			usersToNotify: usersToNotify
		};

		// const originalActivityItems = JSON.parse(JSON.stringify(this.activitySvc.functionalAreaActivityItems.getValue()));
		// this.activitySvc.addItemToActivityItems(date, null, null, faStatusChange);
		if (lineItems) {
			// save the state of the line items before changing them for revert on error.
			lineItemStatusesToRevertTo = JSON.parse(JSON.stringify(lineItems));
			if (newStatus === StatusHelper.Status.OnHold) {
				const lineItem = lineItems[0];
				if (lineItems.length === 1) {
					if (lineItem.status !== StatusHelper.Status.New) {
						functionalArea.completedLineItems--;
					}
					lineItem.status = newStatus;
					uploadDto.lineItemGuid = lineItem.kafkaId;
					const liIndex = lineItemSvcLineItems.findIndex(li => li.kafkaId === lineItem.kafkaId);
					lineItemSvcLineItems.splice(liIndex, 1, lineItem);
					lineItemsSubject.next(lineItemSvcLineItems);
				}
			} else {
				const updatedLineItems = StatusHelper.UpdateLineItemStatuses(functionalArea, lineItems);
				StatusHelper.updateCompletedCount(functionalArea, updatedLineItems);
				lineItemsSubject.next(updatedLineItems);
			}
		}

		this.http.put<StatusChangeDownloadDto>(`${this.url()}events/${eventId}/accounts/${accountId}/functional-areas/${functionalArea.functionalAreaGuid}`, uploadDto) // send up new uploadDto
			.subscribe(data => { // this is now going to give us a statuschangedowloaddto
				data.statusChange.status = StatusHelper.GetStatusFromId(data.statusChange.statusId);
				this.activitySvc.addItemToActivityItems(data.statusChange.createdDate, data.note, data.photo, data.statusChange, false);
				this.ga.trackEvent('FunctionalAreaStatusChange', data.statusChange.status.title);
				if (uploadDto.usersToNotify.length > 0) {
					this.ga.trackEvent('UserMentioned', 'UserMentioned');
				}
				this.trackStatusChangeTiming(original, newStatus);
				// NOTE: Keep this for when it's decided we want instant updates again
				// update the status change with user info
				// const activityItems = this.activitySvc.functionalAreaActivityItems.getValue();
				// const index = activityItems.findIndex(item => item.createdDate === date);
				// this.activitySvc.addItemToActivityItems(data.createdDate, null, null, data, false, index);
			}, error => {
				switch (error.status) {
					case 400:
						this.toastService.open('400 error: Either functional area IDs do not match, or model state is invalid.', 'danger');
						break;
					case 404:
						this.toastService.open(`No functional area with id: ${functionalArea.functionalAreaGuid}`, 'danger');
						break;
					case 401:
					case 403: {
						// Unauthorized
						break;
					}
					default:
						this.toastService.open('Unable to update, unkown error.', 'danger');
						break;
				}

				// this.activitySvc.functionalAreaActivityItems.next(originalActivityItems);
				functionalArea.status = original.status;
				functionalArea.statusId = original.statusId;
				functionalArea.completedLineItems = original.completedLineItems;
				this.allFunctionalAreasSubject.next(this.currentFunctionalAreas);
				this.setFaReferenceToFaDTO();
				lineItemsSubject.next(originalLineItemSvcLineItems);
			});
	}

	refresh(eventId: string, accountId: string, errorCallback?: Function, replaceStatusOfFunctionalArea = false ): void {
		this.http.get<FunctionalArea[]>(`${this.url()}events/${eventId}/accounts/${accountId}/functional-areas`)
			.pipe(map(data => {
				data.forEach(f => f.status = StatusHelper.GetStatusFromId(f.statusId));
				data = FunctionalAreaSorter.sort(data);
				this.setBreadcrumbs(data);
				this.populateFaOwnerList(data);
				return data;
			}))
			.subscribe(data => {
				if (this.currentFunctionalArea && replaceStatusOfFunctionalArea) {
					const freshCopyOfCurrentFunctionalArea = data.find(fa => fa.functionalAreaGuid === this.currentFunctionalArea.functionalAreaGuid);
					if (freshCopyOfCurrentFunctionalArea && freshCopyOfCurrentFunctionalArea.status.id !== this.currentFunctionalArea.status.id) {
						// In some cases the user might navigate before the server has a chance to finish the status update.
						// When that happens the server will return a copy of the functional area with the old status information.
						// If that happens we want to overwrite that functional area's status info with the latest in our local data before updating our copy of all funcitonal areas.
						// Once the server catches up the info will match and the overwrite will be skipped.
						freshCopyOfCurrentFunctionalArea.status = this.currentFunctionalArea.status;
						freshCopyOfCurrentFunctionalArea.statusDate = this.currentFunctionalArea.statusDate;
						freshCopyOfCurrentFunctionalArea.statusId = this.currentFunctionalArea.statusId;
					}
					this.currentFuncAreaSubject.next(freshCopyOfCurrentFunctionalArea);
				} else if (this.currentFunctionalArea) {
					this.currentFuncAreaSubject.next(data.find(fa => fa.functionalAreaGuid === this.currentFunctionalArea.functionalAreaGuid));
				}

				this.allFunctionalAreasSubject.next(data);
				this.setFaReferenceToFaDTO();
				this.isLoading.next(false);
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error refreshing the functional area data', 'danger');
				}
				if (errorCallback) errorCallback();
			});
	}



	updateFunctionalAreaDetailDto(statusId: number, totalLineItems: number, completedLineItems: number) {
		const faDetailDto = this.currentFunctionalArea;
		if (statusId) {
			faDetailDto.status = StatusHelper.GetStatusFromId(statusId);
		}
		if (completedLineItems || totalLineItems) {
			faDetailDto.totalLineItems = totalLineItems;
			faDetailDto.completedLineItems = completedLineItems;
		}
		this.currentFuncAreaSubject.next(faDetailDto);
	}

	lookupFunctionalAreaById(functionalAreaId: string): FunctionalArea {
		const functionalArea = this.currentFunctionalAreas.find(fa => fa.functionalAreaGuid === functionalAreaId);
		return functionalArea;
	}

	refreshDataForFunctionalArea(eventId: string, accountId: string, functionalAreaId: string) {
		this.http.get<FunctionalArea>(`${this.url()}events/${eventId}/accounts/${accountId}/functional-areas/${functionalAreaId}`)
		.pipe(map(data => {
			data.status = StatusHelper.GetStatusFromId(data.statusId);
			data.breadCrumbs = this.getBreadcrumbsForFa(this.currentFunctionalAreas, data);
			return data;
		}))
		.subscribe(data => {
			this.currentFuncAreaSubject.next(data);
		}, err => {
			if (err.status !== 401 && err.status !== 403) {
				this.toastService.open('There was an error refreshing the functional area data', 'danger');
			}
		});
	}

	populateFaOwnerList(functionalAreas: FunctionalArea[]) {
		const ownerList: Owner[] = [{owner: '(No owner)', value: null}];
		functionalAreas.forEach(fa => {
			const tempOwner = {owner: fa.owner, value: fa.owner};
			if (fa.owner && ownerList.findIndex(x => x.owner === tempOwner.owner) === -1) {
				ownerList.push(tempOwner);
			}
		});
		this.ownerList.next(ownerList);
	}

	trackStatusChangeTiming(originalFA: FunctionalArea, newStatus: Status) {
		if (originalFA.status.id === StatusHelper.Status.OnHold.id) {
			this.ga.trackEvent('FunctionalAreaChangedFromOnHoldStatus', moment(originalFA.statusDate).utc().fromNow(true), null, moment.utc().diff(moment.utc(originalFA.statusDate).valueOf()));
		}
	}

	getfile(url, name, loading) {
		this.http.get(url, { responseType: 'blob' }).subscribe(data => this.downloadHelper.downloadFile(data, name, loading)),
		error => console.log('Error downloading the file.'),
		() => console.info('OK');
	}

	
}
