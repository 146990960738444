import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { SelectableDropdownData } from 'src/app/models/selectable-dropdown-data';

@Component({
  selector: 'select-option-modal',
  templateUrl: './select-option-modal.component.html',
  styleUrls: ['./select-option-modal.component.scss'],
})
export class SelectOptionModalComponent {

  optionsData: SelectableDropdownData;

  constructor(private modalCtrl: ModalController, private navParam: NavParams) {
    this.optionsData = this.navParam.get('optionsData');
  }

  setSelection(){
    this.modalCtrl.dismiss(this.optionsData);
  }

  cancelModal() {
		this.modalCtrl.dismiss();
  }
  
  onSelectionChanged(selectedData){
    console.log(selectedData);
  }

}
