import { FunctionalArea } from './../models/functional-area';
import { AdminFunctionalArea } from './../models/admin-functional-area';



export class FunctionalAreaSorter {
	static sort(areas: Array<FunctionalArea>, sortedAreas = new Array<FunctionalArea>(), parentId?: string, parentIndex = -1): Array<FunctionalArea> {
		areas.sort((a, b) => a.sequenceNumber - b.sequenceNumber);

		while (parentId !== null && areas && areas.length > 0) {
			for (let i = 0; i < areas.length; i++) {
				if (areas[i].parentFunctionalAreaGuid === parentId || areas[i].parentFunctionalAreaGuid === null) {
					sortedAreas.splice(parentIndex + 1, 0, areas.splice(i, 1)[0]);
					parentIndex++;
					i--;
				}
			}

			parentIndex = parentId ? sortedAreas.findIndex(area => area.functionalAreaGuid === parentId) + 1 : 0;
			parentId = sortedAreas[parentIndex] ? sortedAreas[parentIndex].functionalAreaGuid : null;
		}
		if (areas.length > 0) {
			sortedAreas = sortedAreas.concat(areas.splice(0, areas.length));
		}
		return sortedAreas;
	}

	static sortAdminAreas(areas: Array<AdminFunctionalArea>, sortedAreas = new Array<AdminFunctionalArea>(), parentId?: string, parentIndex = -1): Array<AdminFunctionalArea> {
		areas.sort((a, b) => a.sequenceNumber - b.sequenceNumber);

		while (parentId !== null && areas && areas.length > 0) {
			for (let i = 0; i < areas.length; i++) {
				if (areas[i].parentFunctionalAreaGuid === parentId || areas[i].parentFunctionalAreaGuid === null) {
					sortedAreas.splice(parentIndex + 1, 0, areas.splice(i, 1)[0]);
					parentIndex++;
					i--;
				}
			}

			parentIndex = parentId ? sortedAreas.findIndex(area => area.functionalAreaGuid === parentId) + 1 : 0;
			parentId = sortedAreas[parentIndex] ? sortedAreas[parentIndex].functionalAreaGuid : null;
		}

		return sortedAreas;
	}
}
