import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ShowBooth } from 'src/app/models/show-booth-dto';
import { ShowArea } from 'src/app/models/show-setup/show-area-dto';
import { ShowSetupService } from 'src/app/services/show-setup/show-setup.service';
import * as _ from 'lodash';

@Component({
  selector: 'occ-booth-assignment-status',
  templateUrl: './booth-assignment-status.component.html',
  styleUrls: ['./booth-assignment-status.component.scss'],
})
export class BoothAssignmentStatusComponent implements OnInit {
  
  @Input() eventGUID: string; 
  @Input() isFreightZone: any;
  @Input() allAreaList: ShowArea[] = [];
  
  allBoothsList: ShowBooth[] = [];
  allFilteredBoothsList: ShowBooth[] = [];
  selectedBooths: ShowBooth[] = []; 
  selectedAreas: ShowArea[] = [];
  selectedBoothSegment: string = "Unassigned";
  allAssignedBooths: string[] = [];
  isLoading: boolean = true;

  constructor(
    private popoverCtrl: PopoverController,
    public showSetupService: ShowSetupService
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.allAreaList = _.filter(this.allAreaList,(areaItem) => {
      return areaItem.name?.length;
    });

    this.allAreaList.forEach(areaItem => {
      areaItem.name = areaItem.name.trim();
      this.allAssignedBooths = this.allAssignedBooths.concat(areaItem.booths.map(booth => booth.boothGuid));
    });

    this.allAreaList = this.allAreaList.slice().sort((a,b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase(), undefined, {numeric: true}));

    this.showSetupService.getBoothsList(this.eventGUID).subscribe(async (boothsData) => {
      this.allBoothsList = boothsData; 
      this.getSegmentBooths(); 
      this.isLoading = false;    
    });
  }

  toggleBoothSelection(boothItem: ShowBooth){
    boothItem.isBoothSelected = !boothItem.isBoothSelected;
    this.selectedBooths = _.filter(this.allBoothsList, (boothItem) => {
      return boothItem.isBoothSelected;
    });
  }

  toggleAreaSelection(areaItem: ShowArea){
    areaItem.isAreaSelected = !areaItem.isAreaSelected;
    this.selectedAreas = _.filter(this.allAreaList, (areaItem) => {
      return areaItem.isAreaSelected;
    });
  }

  getSegmentBooths(){
    switch (this.selectedBoothSegment) {
      case "All":
        this.allFilteredBoothsList = this.allBoothsList;
        break;

      case "Assigned":
        this.allFilteredBoothsList = _.filter(this.allBoothsList, (boothItem) => {
          return this.allAssignedBooths.includes(boothItem.boothGUID);
        });
        break;

      case "Unassigned":
        this.allFilteredBoothsList = _.filter(this.allBoothsList, (boothItem) => {
          return !this.allAssignedBooths.includes(boothItem.boothGUID);
        });
        break;
    
      default:
        this.allFilteredBoothsList = this.allBoothsList;
        break;
    }
  }

  saveBoothAssignment(){
    this.showSetupService.saveBoothAssignments(this.eventGUID, {
      areaGuids: this.selectedAreas.map(area => area.areaGuid),
      isInsert: true,
      boothGuids: this.selectedBooths.map(booth => booth.boothGUID)
    }).subscribe((boothAssignmentResponse) => {
      if(boothAssignmentResponse){
        this.popoverCtrl.dismiss({
          'isBoothAssignmentSaved': true
        });
      }
    });
  }

  closeBoothAssignment(){
    this.popoverCtrl.dismiss();
  }
}
