import { Component, Input, OnInit } from '@angular/core';
import { CollaborationLineItemDTO } from '../../models/collaboration-line-item-dto';
import { FunctionalAreaService } from './../../services/functional-area/functional-area.service';
import { Status } from '../../models/status';
import { FunctionalArea } from '../../models/functional-area';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';
import { StatusHelper } from './../../helpers/status-helper';
import { LineItemService } from '../../services/line-item/line-item.service';
import { DataRefreshService } from '../../services/data-refresh/data-refresh.service';
import { ToastService } from '../../services/toast/toast.service';
import { BehaviorSubject } from 'rxjs';
import { WorkTicketDTO } from '../../models/work-ticket-dto';
import { WorkTicketService } from '../../services/work-ticket/work-ticket.service';
import { WorkticketLineItemService } from '../../services/workticket-line-item/workticket-line-item.service';
import { ShowBooth } from '../../models/show-booth-dto';
import { BoothServiceProvider } from '../../services/booth/booth.service';
import * as _ from 'lodash';
// import { StatusWarningPopoverComponent } from '../status-warning-popover/status-warning-popover.component';
import { FreightZone } from 'src/app/models/show-setup/freight-zone-dto';
import { FreightSetupService } from 'src/app/services/show-setup/freight-setup.service';

@Component({
	selector: 'change-status-menu',
	templateUrl: './change-status-menu.component.html',
	styleUrls: ['./change-status-menu.component.scss'],
})
export class ChangeStatusMenuComponent implements OnInit {

	statuses: Array<Status>;
	@Input() functionalArea?: FunctionalArea;
	@Input() lineItem?: CollaborationLineItemDTO;
	@Input() workTicket?: WorkTicketDTO;
	@Input() workTicketLineItem?: CollaborationLineItemDTO;
	@Input() boothData?: ShowBooth;
	@Input() boothLineItem?: CollaborationLineItemDTO;
	@Input() FreightZonelist: FreightZone;
	eventId: string;
	accountId: string;
	isFromDetailsPage: boolean;
	isFromBoothDetailsPage: boolean;
	isFromWTDetailsPage: boolean;
	fromSearch: boolean;

	functionalAreaId: string;

	constructor(
		public navParams: NavParams,
		public funcSvc: FunctionalAreaService,
		public lineItemService: LineItemService,
		public workTikSvc: WorkTicketService,
		public modalCtrl: ModalController,
		public dataSvc: DataRefreshService,
		public toastSvc: ToastService,
		public popoverCtrl: PopoverController,
		public wkTkLineItemSvc: WorkticketLineItemService,
		public boothService: BoothServiceProvider,
		public freightSetupService: FreightSetupService


	) {
		this.eventId = this.dataSvc.eventActive;
		this.accountId = this.dataSvc.showAccountActive;
		this.boothData = navParams.data.boothData;
		this.boothLineItem = navParams.data.boothLineItem;
		this.functionalArea = navParams.data.area || this.funcSvc.currentFuncAreaSubject.getValue();
		// get the functional area id from the functional area if it exists. If not, get it from the nav params (in the case of deep link to lidetails)
		this.functionalAreaId = this.functionalArea ? this.functionalArea.functionalAreaGuid : this.navParams.data.functionalAreaId;
		// if we had to get the functional area id from the nav params, it probably isn't set as the active functional area.
		// We'll set that here since some things reference the active functional area.
		if (!this.functionalArea && this.functionalAreaId) {
			this.funcSvc.activateFunctionalArea(this.functionalAreaId);
		}
		this.workTicketLineItem = navParams.data.workTicketLineItem;
		this.FreightZonelist = navParams.data.FreightZonelist;
		this.lineItem = navParams.data.item;
		this.workTicket = navParams.data.ticket;
		if (this.boothData || this.boothLineItem) {
			this.statuses = _.values(StatusHelper.Status)
		} else if(this.FreightZonelist){
			this.statuses = _.values(StatusHelper.ZoneStatusOption)
		}
		else {
			this.statuses = this.lineItem || this.workTicketLineItem || this.workTicket ? StatusHelper.LineItemStatuses : this.functionalArea ? StatusHelper.FunctionalAreaStatuses : StatusHelper.WorkTicketStatuses;
			if(this.workTicketLineItem || this.workTicket){
				this.statuses = _.filter(this.statuses, function(statusItem) { return statusItem.id != 4; });
			}
		}

		this.isFromDetailsPage = this.navParams.data.isFromDetailsPage;
		this.isFromWTDetailsPage = this.navParams.data.isFromWTDetailsPage;
		this.fromSearch = this.navParams.data.fromSearch;
		if (!this.isFromDetailsPage && !this.isFromWTDetailsPage) {
			this.lineItemService.refresh(this.eventId, this.accountId, this.functionalAreaId).subscribe;
		}
	}
	ngOnInit(): void {

	}

	async changeStatus(newStatus: Status) {
		const modalDetails = await this.modalCtrl.getTop();
		if(modalDetails && modalDetails.id != 'bulk-update-modal'){
			this.modalCtrl.dismiss({
				'newStatus': newStatus
			});
		}

		const isPopupOpened = await this.popoverCtrl.getTop();
		if(isPopupOpened){
			this.popoverCtrl.dismiss({
				'newStatus': newStatus
			});
		}

		if (this.navParams.data.item) {
			this.lineItemService.put(
				this.dataSvc.eventActive,
				this.dataSvc.showAccountActive,
				this.lineItem.functionalAreaGuid,
				this.lineItem.kafkaId,
				newStatus,
			);
		} else if (this.navParams.data.area) {
			if (this.isFromDetailsPage) {
				// if we're coming from the details page, we don't want to refresh
				// if (this.lineItemService.collaborationLineItemDto.getValue().find(x => x.status === StatusHelper.Status.OnHold) && (newStatus === StatusHelper.Status.Approved || newStatus === StatusHelper.Status.Complete || newStatus === StatusHelper.Status.InProgress)) {
				// 	this.presentPopover(this.dataSvc.eventActive, this.dataSvc.showAccountActive, this.funcSvc.activeFunctionalAreaId.getValue(), newStatus, this.lineItemService.collaborationLineItemDto, this.lineItemService.collaborationLineItemDto.getValue());
				// } else {
				// }
				this.funcSvc.put(this.dataSvc.eventActive, this.dataSvc.showAccountActive, this.funcSvc.activeFunctionalAreaId.getValue(), newStatus, this.lineItemService.collaborationLineItemDto, this.lineItemService.collaborationLineItemDto.getValue());

			} else {
				// if we're coming from anywhere else, we need to get the line items first, then do it.
				this.lineItemService.refresh(this.eventId, this.accountId, this.navParams.data.area.functionalAreaGuid).subscribe(() => {
					// if (this.lineItemService.collaborationLineItemDto.getValue().find(x => x.status === StatusHelper.Status.OnHold) && (newStatus === StatusHelper.Status.Approved || newStatus === StatusHelper.Status.Complete || newStatus === StatusHelper.Status.InProgress)) {
					// 	this.presentPopover(this.dataSvc.eventActive, this.dataSvc.showAccountActive, this.functionalArea.functionalAreaGuid, newStatus, this.lineItemService.collaborationLineItemDto, this.lineItemService.collaborationLineItemDto.getValue());
					// } else {
					// }
					this.funcSvc.put(this.dataSvc.eventActive, this.dataSvc.showAccountActive, this.functionalArea.functionalAreaGuid, newStatus, this.lineItemService.collaborationLineItemDto, this.lineItemService.collaborationLineItemDto.getValue());
				});
			}
		}
		
		if (this.navParams.data.workTicketLineItem) {
			this.wkTkLineItemSvc.put(
				this.eventId,
				this.workTikSvc.allWorkTickets.find(wt => wt.tssWorkTicketNumber === this.workTicketLineItem.ticketNumber).workTicketGuid,
				this.workTicketLineItem.kafkaId,
				newStatus,
			);
		} else if (this.navParams.data.ticket) {
			if (this.isFromWTDetailsPage) {
				// if (this.wkTkLineItemSvc.collaborationLineItemDto.getValue().find(x => x.status === StatusHelper.Status.OnHold) && (newStatus === StatusHelper.Status.New || newStatus === StatusHelper.Status.InProgress || newStatus === StatusHelper.Status.Complete)) {
				// 	this.presentWTPopover(this.eventId, this.workTicket.workTicketGuid, newStatus, this.wkTkLineItemSvc.collaborationLineItemDto, this.wkTkLineItemSvc.currentLineItemDto);
				// } else {
				// }
				this.workTikSvc.put(this.eventId, this.workTicket, newStatus, this.wkTkLineItemSvc.collaborationLineItemDto, this.wkTkLineItemSvc.currentLineItemDto);
			} else {
				this.wkTkLineItemSvc.refresh(this.eventId, this.workTicket.workTicketGuid);
				// if (this.wkTkLineItemSvc.collaborationLineItemDto.getValue().find(x => x.status === StatusHelper.Status.OnHold) && (newStatus === StatusHelper.Status.New || newStatus === StatusHelper.Status.InProgress || newStatus === StatusHelper.Status.Complete)) {
				// 	this.presentWTPopover(this.eventId, this.workTicket.workTicketGuid, newStatus, this.wkTkLineItemSvc.collaborationLineItemDto, this.wkTkLineItemSvc.currentLineItemDto);
				// } else {
				// }
				this.workTikSvc.put(this.eventId, this.workTicket, newStatus, this.wkTkLineItemSvc.collaborationLineItemDto, this.wkTkLineItemSvc.currentLineItemDto);
			}
		}
		if (this.boothLineItem || this.boothData) {
			if (!this.boothLineItem) {
				// if we're coming from the details page, we don't want to refresh
				// if (this.boothService.currentBoothLineItems.getValue().find(x =>x.status === StatusHelper.Status.OnHold) && (newStatus === StatusHelper.Status.Approved || newStatus === StatusHelper.Status.Complete || newStatus === StatusHelper.Status.InProgress)) {
				// 	this.presentBoothPopover(this.dataSvc.eventActive, this.dataSvc.showAccountActive, this.boothService.activeBooth.getValue().boothGUID, newStatus, this.boothService.currentBoothLineItems, this.boothService.currentBoothLineItems.getValue());
				// } else {
				// }
				this.boothService.setBoothStatus(this.eventId, this.boothData.boothGUID, this.boothLineItem, newStatus.id);

			} else {
				this.boothService.setBoothStatus(this.eventId, this.boothService.activeBoothGuid, this.boothLineItem, newStatus.id);
			}
		}
		if (this.FreightZonelist) {
			if (newStatus === StatusHelper.ZoneStatusOption.Complete || newStatus === StatusHelper.ZoneStatusOption.InProgress || StatusHelper.ZoneStatusOption.Overdue) {
				this.freightSetupService.setfreightZoneStatus(this.eventId, this.FreightZonelist, newStatus.id);
			}
		}		
	}

	// async presentPopover(eventId: string, accountId: string, functionalAreaId: string, newStatus: Status, lineItemsSubject: BehaviorSubject<CollaborationLineItemDTO[]>, lineItems?: CollaborationLineItemDTO[]) {
	// 	const popover = await this.popoverCtrl.create({
	// 		component: StatusWarningPopoverComponent,
	// 		componentProps: { eventId: eventId, accountId: accountId, functionalAreaId: functionalAreaId, newStatus: newStatus, lineItemsSubject: lineItemsSubject, lineItems: lineItems }
	// 	});
	// 	await popover.present();
	// }

	// async presentWTPopover(eventId: string, workTicketId: string, newStatus: Status, lineItemsSubject: BehaviorSubject<CollaborationLineItemDTO[]>, lineItems?: CollaborationLineItemDTO[]) {
	// 	const popover = await this.popoverCtrl.create({
	// 		component: StatusWarningPopoverComponent,
	// 		componentProps: { eventId: eventId, workTicketId: workTicketId, newStatus: newStatus, lineItemsSubject: lineItemsSubject, lineItems: lineItems }
	// 	});
	// 	await popover.present();
	// }
	// async presentBoothPopover(eventId: string, accountId: string, boothId: string, newStatus: Status, lineItemsSubject: BehaviorSubject<CollaborationLineItemDTO[]>, lineItems?: CollaborationLineItemDTO[]) {
	// 	const popover = await this.popoverCtrl.create({
	// 		component: StatusWarningPopoverComponent,
	// 		componentProps: { eventId: eventId, accountId: accountId, boothId: boothId, newStatus: newStatus, lineItemsSubject: lineItemsSubject, lineItems: lineItems }
	// 	});
	// 	await popover.present();
	// }
}
