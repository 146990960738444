import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';

@Injectable()
export class AppBrowserHelper {
	constructor(public platform: Platform) {
	}

	public get isApp(): boolean {
		return this.platform.is('mobile') || document.URL.includes('devapp=true');
	}

	public get isDesktop(): boolean {
		return (this.platform.is('desktop'));
	}

	public get isMobileWeb(): boolean {
		return (this.platform.is('mobileweb'));
	}

	public get isAndroid(): boolean {
		return this.platform.is('android');
	}
	public get isIos(): boolean {
		return this.platform.is('ios');
	}

	public get isCapacitor(): boolean {
		return this.platform.is('capacitor');
	}

	public get isKarmaTest(): boolean {
		return window.location.href.includes('http://localhost:9876');
	}

	checkPlatform() {
		console.log(this.platform.is('ios'), 'iosdevice');
		console.log(this.platform.is('android'), 'android device');
		console.log(this.platform.is('desktop'), 'desktop');
		console.log(this.platform.is('tablet'), 'tablet');
		console.log(this.platform.is('mobileweb'), 'mobileweb');
		console.log(this.platform.is('phablet'), 'phablet');
		console.log(this.platform.is('capacitor'), 'capacitor');
	}
}
