import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController, PopoverController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { SelectHelper } from 'src/app/helpers/select-helper';
import { StatusHelper } from 'src/app/helpers/status-helper';
import { CollaborationLineItemDTO } from 'src/app/models/collaboration-line-item-dto';
import { FunctionalArea } from 'src/app/models/functional-area';
import { ShowBooth } from 'src/app/models/show-booth-dto';
import { Status } from 'src/app/models/status';
import { WorkTicketDTO } from 'src/app/models/work-ticket-dto';
import { BoothServiceProvider } from 'src/app/services/booth/booth.service';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';
import { FunctionalAreaService } from 'src/app/services/functional-area/functional-area.service';
import { LineItemService } from 'src/app/services/line-item/line-item.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { WorkTicketService } from 'src/app/services/work-ticket/work-ticket.service';
import { WorkticketLineItemService } from 'src/app/services/workticket-line-item/workticket-line-item.service';
import { ChangeOrderModalComponent } from '../change-order-modal/change-order-modal.component';

@Component({
  selector: 'occ-change-order-menu',
  templateUrl: './change-order-menu.component.html',
  styleUrls: ['./change-order-menu.component.scss'],
})
export class ChangeOrderMenuComponent implements OnInit {

  statuses: Array<Status>;
	functionalArea: FunctionalArea;
	lineItem: CollaborationLineItemDTO;
	workTicket: WorkTicketDTO;
	workTicketLineItem: CollaborationLineItemDTO;
	eventId: string;
	accountId: string;
	isFromDetailsPage: boolean;
	isFromWTDetailsPage: boolean;
	fromSearch: boolean;
	functionalAreaId: string;
	boothData: ShowBooth;
	boothLineItem: CollaborationLineItemDTO;

	constructor(
		public navParams: NavParams,
		public funcSvc: FunctionalAreaService,
		public lineItemService: LineItemService,
		public workTikSvc: WorkTicketService,
		public modalCtrl: ModalController,
		public dataSvc: DataRefreshService,
		public toastSvc: ToastService,
		public popoverCtrl: PopoverController,
		public wkTkLineItemSvc: WorkticketLineItemService,
		public boothService: BoothServiceProvider
	) {
		this.eventId = this.dataSvc.eventActive;
		this.accountId = this.dataSvc.showAccountActive;
		this.boothData = navParams.data.booth;
		this.boothService.activeBooth.next(this.boothData);
		this.boothLineItem = navParams.data.boothLineItem;
		this.boothService.activeBoothLineItem.next(this.boothLineItem);
		this.functionalArea = navParams.data.area || this.funcSvc.currentFuncAreaSubject.getValue();
		// get the functional area id from the functional area if it exists. If not, get it from the nav params (in the case of deep link to lidetails)
		this.functionalAreaId = this.functionalArea ? this.functionalArea.functionalAreaGuid : this.navParams.data.functionalAreaId;
		// if we had to get the functional area id from the nav params, it probably isn't set as the active functional area.
		// We'll set that here since some things reference the active functional area.
		if (!this.functionalArea && this.functionalAreaId) {
			this.funcSvc.activateFunctionalArea(this.functionalAreaId);
		}
		this.workTicketLineItem = navParams.data.workTicketLineItem;
		this.lineItem = navParams.data.item;
		this.workTicket = navParams.data.ticket;
		if (this.boothLineItem) {
			this.statuses = StatusHelper.ChangeOrderStatuses;
		} else {
			this.statuses = this.lineItem ? StatusHelper.ChangeOrderStatuses : null;
			this.isFromDetailsPage = this.navParams.data.isFromDetailsPage;
			this.isFromWTDetailsPage = this.navParams.data.isFromWTDetailsPage;
			this.fromSearch = this.navParams.data.fromSearch;
			if (!this.isFromDetailsPage && !this.isFromWTDetailsPage) {
				this.lineItemService.refresh(this.eventId, this.accountId, this.functionalAreaId).subscribe;
			}
		}
	}

	//TODO: Since this no longer just changes status, should we change the name of this method or abstract the choice to another method?
	async changeStatus(newStatus: Status) {
    const modalDetails = await this.modalCtrl.getTop();
		if(modalDetails){
			this.modalCtrl.dismiss({
				'newStatus': newStatus
			});
		}

		const isPopupOpened = await this.popoverCtrl.getTop();
		if(isPopupOpened){
			this.popoverCtrl.dismiss({
				'newStatus': newStatus
			});
		}

		if (SelectHelper.CheckSelectedText(window.getSelection())) return;

		if (this.navParams.data.item || this.boothLineItem) {
			if (newStatus === StatusHelper.ChangeOrderStatus.ReviseAsChangeOrder) {
				this.presenChangeOrderModal(newStatus, 'li-modal');
			}
		}
	}

	async presenChangeOrderModal(status: Status, extraClass?: string) {
		const functionalArea = this.functionalArea ? this.functionalArea : this.lineItem ? this.lineItem.functionalAreaGuid : '';

    const changeOrder = await this.modalCtrl.create({
      component: ChangeOrderModalComponent,
      cssClass: 'change-order-modal modal-height-wrapper ' + extraClass,
      showBackdrop: true,
      componentProps: { 
        newStatus: status,
        workTicket: this.workTicket,
        functionalArea: functionalArea,
        lineItem: this.boothLineItem ? this.boothLineItem : this.lineItem,
        workTicketLineItem: this.workTicketLineItem,
        fromSearch: this.fromSearch,
        boothGUID: this.boothData ? this.boothData.boothGUID : "",
              boothLineItemID: this.boothLineItem ? this.boothLineItem.kafkaId : ""
       }
    });
    await changeOrder.present();
	}

  ngOnInit() {}

}
