import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'occ-part-group-filter',
  templateUrl: './part-group-filter.component.html',
  styleUrls: ['./part-group-filter.component.scss'],
})
export class PartGroupFilterComponent implements OnInit {

  @Input() partGroups: any = [];
  @Input() subGroups: any = [];
  @Input() parts: any = [];
  @Input() currentAppliedPartFilters: any = [];
  @Output() partFilterChangeEvent = new EventEmitter<any>();

  searchList: any = [];
  selectedSearchSegment: string = "1";
  searchPlaceHolder: string = "Select Part Group Name";
  searchText: string = "";

  selectedPartFilterData: any = [];

  constructor() {

  }

  ngOnInit() {
    if(!this.currentAppliedPartFilters){
      this.currentAppliedPartFilters = [];
    }
    this.prepareHierarchyPartData();
    this.setCurrentAppliedPartFilters();
    this.updateSearchDataSet();
  }

  prepareHierarchyPartData() {
    this.partGroups.forEach((groupItem) => {
      let splittedPartNumber = groupItem.partNumber.split("-");
      groupItem.allSubgroups = _.filter(_.cloneDeep(this.subGroups), function(sgItem) { return sgItem.partNumber.startsWith(splittedPartNumber[0] + "-"); });
      groupItem.allSubgroups.forEach(sgItem => {
        let splittedSGPartNumber = sgItem.partNumber.split("-");
        sgItem.allParts = _.filter(_.cloneDeep(this.parts), function(partItem) { return partItem.partNumber.startsWith(splittedPartNumber[0] + "-" + splittedSGPartNumber[1]  + "-"); });
        sgItem.selectedParts = [];
        sgItem.isPartSelectionVisible = false;
      });
      groupItem.selectedSubgroups = [];
      groupItem.isSubGroupSelectionVisible = false;
    });

    this.subGroups.forEach((subGroupItem) => {
      let splittedPartNumber = subGroupItem.partNumber.split("-");
      subGroupItem.allParts = _.filter(_.cloneDeep(this.parts), function(partItem) { return partItem.partNumber.includes("-" + splittedPartNumber[1]  + "-"); });
      subGroupItem.selectedParts = [];
      subGroupItem.isPartSelectionVisible = false;
    });
    
  }

  setCurrentAppliedPartFilters(){
    this.selectedPartFilterData = _.cloneDeep(this.currentAppliedPartFilters);
    this.selectedPartFilterData.forEach(filterItem => {
      if (filterItem.type == "group") {
        let matchedGroup = _.find(this.partGroups, { 'name': filterItem.name});
        let selectedSubgroupNames = filterItem.selectedSubgroups.map(selectedSgItem => selectedSgItem.name);
        filterItem['allSubgroups'] = matchedGroup ? matchedGroup.allSubgroups : []; 
        _.remove(filterItem['allSubgroups'], function(sgItem) {
          return selectedSubgroupNames.includes(sgItem.name);
        });
        filterItem.selectedSubgroups.forEach(selectedSGItem => {
          let matchedSubGroup = _.find(this.subGroups, { 'name': selectedSGItem.name});
          let selectedPartNames = selectedSGItem.selectedParts.map(selectedPartItem => selectedPartItem.name);
          selectedSGItem['allParts'] = matchedSubGroup ? matchedSubGroup.allParts : [];
          _.remove(selectedSGItem['allParts'], function(partItem) {
            return selectedPartNames.includes(partItem.name);
          });
        });
      } else if (filterItem.type == "subgroup") {
        let matchedSubGroup = _.find(this.subGroups, { 'name': filterItem.name});
        let selectedPartNames = filterItem.selectedParts.map(selectedPartItem => selectedPartItem.name);
        filterItem['allParts'] = matchedSubGroup ? matchedSubGroup.allParts : [];
        _.remove(filterItem['allParts'], function(partItem) {
          return selectedPartNames.includes(partItem.name);
        });
      }
    });
  }

  updateSearchDataSet() {
    switch (this.selectedSearchSegment) {
      case "1":
        this.searchPlaceHolder = "Search Part Group Name";
        break;
      case "2":
        this.searchPlaceHolder = "Search Subgroup Name";
        break;
      case "3":
        this.searchPlaceHolder = "Search Part Name";
        break;

      default:
        break;
    }
    this.getSearchResult();
  }

  getSearchResult() {
    switch (this.selectedSearchSegment) {
      case "1":
        if (this.searchText.length) {
          this.searchList = _.filter(this.partGroups, (partGroupItem) => { return partGroupItem.name.toLowerCase().startsWith(this.searchText.toLowerCase()) && !partGroupItem.isChecked; })
        } else {
          this.searchList = this.partGroups;
        }
        break;
      case "2":
        if (this.searchText.length) {
          this.searchList = _.filter(this.subGroups, (subGroupItem) => { return subGroupItem.name.toLowerCase().startsWith(this.searchText.toLowerCase()) && !subGroupItem.isChecked; })
        } else {
          this.searchList = this.subGroups;
        }
        break;
      case "3":
        if (this.searchText.length) {
          this.searchList = _.filter(this.parts, (partItem) => { return partItem.name.toLowerCase().startsWith(this.searchText.toLowerCase()) && !partItem.isChecked; })
        } else {
          this.searchList = this.parts;
        }

      default:
        break;
    }
  }

  clearSearch() {
    if (this.searchText.length) {
      this.searchText = '';
      this.searchList = [];
    }
  }

  setSearchItemSelection(selectedItem) {
    switch (selectedItem.filterType) {
      case "0":
        this.partGroups.forEach(groupItem => {
          if (selectedItem.name == groupItem.name) {
            groupItem.isChecked = true;
            selectedItem.isChecked = true;
          }
        });
        this.prepareGroupData(selectedItem);
        break;
      case "11":
        this.subGroups.forEach(subGroupItem => {
          if (selectedItem.name == subGroupItem.name) {
            subGroupItem.isChecked = true;
            selectedItem.isChecked = true;
          }
        });
        this.prepareSubgroupData(selectedItem);
        break;
      case "12":
        this.parts.forEach(parts => {
          if (selectedItem.name == parts.name) {
            parts.isChecked = true;
            selectedItem.isChecked = true;
          }
        });
        this.preparePartData(selectedItem);
        break;

      default:
        break;
    }
    this.prepareFilterSelectionData();
    this.clearSearch();
  }

  prepareGroupData(selectedItem) {
    selectedItem.type = "group"
    this.selectedPartFilterData.push(selectedItem);
  }

  prepareSubgroupData(selectedItem) {
    selectedItem.type = "subgroup"
    this.selectedPartFilterData.push(selectedItem);
  }

  preparePartData(selectedItem) {
    selectedItem.type = "part"
    this.selectedPartFilterData.push(selectedItem);
  }

  enableNewItemAddition(currentItem) {
    if (currentItem?.type == 'group') {
      currentItem.isSubGroupSelectionVisible = true;
    }

    if (currentItem?.type == 'subgroup') {
      currentItem.isPartSelectionVisible = true;
    }

  }

  enablePartSelection(subGroupItem) {
    subGroupItem.isPartSelectionVisible = true;
  }

  removeMainFilter(partFilterIndex, type) {
    switch (type) {
      case "group":
        this.partGroups.forEach(partGroupItem => {
          if (partGroupItem.name == this.selectedPartFilterData[partFilterIndex]?.name) {
            this.selectedPartFilterData[partFilterIndex].selectedSubgroups.forEach((element, sgIndex) => {
              element.allParts = _.sortBy(element.allParts?.concat(element.selectedParts), ['name']);
              element.selectedParts = [];
            });

            this.selectedPartFilterData[partFilterIndex].allSubgroups = this.selectedPartFilterData[partFilterIndex].allSubgroups?.concat(this.selectedPartFilterData[partFilterIndex].selectedSubgroups);
            this.selectedPartFilterData[partFilterIndex].allSubgroups = _.sortBy(this.selectedPartFilterData[partFilterIndex].allSubgroups, ['name']);
            this.selectedPartFilterData[partFilterIndex].selectedSubgroups = [];
            this.selectedPartFilterData[partFilterIndex].isSubGroupSelectionVisible = false;
            partGroupItem.isChecked = false;
          }
        });
        break;
      case "subgroup":
        this.subGroups.forEach(subGroupItem => {
          if (subGroupItem.name == this.selectedPartFilterData[partFilterIndex]?.name) {
            subGroupItem.isChecked = false;
            this.selectedPartFilterData[partFilterIndex].allParts = this.selectedPartFilterData[partFilterIndex].allParts?.concat(this.selectedPartFilterData[partFilterIndex].selectedParts);
            this.selectedPartFilterData[partFilterIndex].allParts = _.sortBy(this.selectedPartFilterData[partFilterIndex].allParts, ['name']);
            this.selectedPartFilterData[partFilterIndex].selectedParts = [];
            this.selectedPartFilterData[partFilterIndex].isPartSelectionVisible = false;
          }
        });
        break;
      case "part":
        this.parts.forEach(partItem => {
          if (partItem.name == this.selectedPartFilterData[partFilterIndex]?.name) {
            partItem.isChecked = false;
          }
        });
        break;

      default:
        break;
    }

    this.selectedPartFilterData.splice(partFilterIndex, 1);
    this.getSearchResult();
    this.prepareFilterSelectionData();
  }

  setChildForParent(parentIndex, childIndex) {
    if (this.selectedPartFilterData[parentIndex].type == "group") {
      let removedItems = this.selectedPartFilterData[parentIndex].allSubgroups.splice(childIndex, 1);
      this.selectedPartFilterData[parentIndex].selectedSubgroups = this.selectedPartFilterData[parentIndex].selectedSubgroups?.concat(removedItems);
      this.selectedPartFilterData[parentIndex].selectedSubgroups = _.sortBy(this.selectedPartFilterData[parentIndex].selectedSubgroups, ['name']);
      this.selectedPartFilterData[parentIndex].isSubGroupSelectionVisible = false;
    } else if (this.selectedPartFilterData[parentIndex].type == "subgroup") {
      let removedItems = this.selectedPartFilterData[parentIndex].allParts.splice(childIndex, 1);
      this.selectedPartFilterData[parentIndex].selectedParts = this.selectedPartFilterData[parentIndex].selectedParts?.concat(removedItems);
      this.selectedPartFilterData[parentIndex].selectedParts = _.sortBy(this.selectedPartFilterData[parentIndex].selectedParts, ['name']);
      this.selectedPartFilterData[parentIndex].isPartSelectionVisible = false;
    }
    this.prepareFilterSelectionData();
  }

  removeChildFromParent(parentIndex, childIndex) {
    if (this.selectedPartFilterData[parentIndex].type == "group") {
      // First remove all selected parts under Subgroup & then remove subgroup
      this.selectedPartFilterData[parentIndex].selectedSubgroups[childIndex].allParts = this.selectedPartFilterData[parentIndex].selectedSubgroups[childIndex].allParts?.concat(this.selectedPartFilterData[parentIndex].selectedSubgroups[childIndex].selectedParts);
      this.selectedPartFilterData[parentIndex].selectedSubgroups[childIndex].allParts = _.sortBy(this.selectedPartFilterData[parentIndex].selectedSubgroups[childIndex].allParts, ['name']);
      this.selectedPartFilterData[parentIndex].selectedSubgroups[childIndex].selectedParts = [];
      let removedSubgroup = this.selectedPartFilterData[parentIndex].selectedSubgroups.splice(childIndex, 1);
      this.selectedPartFilterData[parentIndex].allSubgroups = this.selectedPartFilterData[parentIndex].allSubgroups?.concat(removedSubgroup);
      this.selectedPartFilterData[parentIndex].allSubgroups = _.sortBy(this.selectedPartFilterData[parentIndex].allSubgroups, ['name']);
    } else if (this.selectedPartFilterData[parentIndex].type == "subgroup") {
      let removedPart = this.selectedPartFilterData[parentIndex].selectedParts.splice(childIndex, 1);
      this.selectedPartFilterData[parentIndex].allParts = this.selectedPartFilterData[parentIndex].allParts?.concat(removedPart);
      this.selectedPartFilterData[parentIndex].allParts = _.sortBy(this.selectedPartFilterData[parentIndex].allParts, ['name']);
    }
    this.prepareFilterSelectionData();
  }

  setPartSelectionForGroup(groupIndex, subgroupIndex, partindex) {
    let removedItems = this.selectedPartFilterData[groupIndex].selectedSubgroups[subgroupIndex].allParts.splice(partindex, 1);
    this.selectedPartFilterData[groupIndex].selectedSubgroups[subgroupIndex].selectedParts = this.selectedPartFilterData[groupIndex].selectedSubgroups[subgroupIndex].selectedParts.concat(removedItems);
    this.selectedPartFilterData[groupIndex].selectedSubgroups[subgroupIndex].selectedParts = _.sortBy(this.selectedPartFilterData[groupIndex].selectedSubgroups[subgroupIndex].selectedParts, ['name']);
    this.selectedPartFilterData[groupIndex].selectedSubgroups[subgroupIndex].isPartSelectionVisible = false;
    this.prepareFilterSelectionData();
  }

  removePartSelectionForGroup(groupIndex, subgroupIndex, partindex) {
    let removedPart = this.selectedPartFilterData[groupIndex].selectedSubgroups[subgroupIndex].selectedParts.splice(partindex, 1);
    this.selectedPartFilterData[groupIndex].selectedSubgroups[subgroupIndex].allParts = this.selectedPartFilterData[groupIndex].selectedSubgroups[subgroupIndex].allParts?.concat(removedPart);
    this.selectedPartFilterData[groupIndex].selectedSubgroups[subgroupIndex].allParts = _.sortBy(this.selectedPartFilterData[groupIndex].selectedSubgroups[subgroupIndex].allParts, ['name']);
    this.prepareFilterSelectionData();
  }

  prepareFilterSelectionData() {
    this.currentAppliedPartFilters = _.cloneDeep(this.selectedPartFilterData);
    this.currentAppliedPartFilters.forEach(filterItem => {
      if (filterItem.type == "group") {
        delete filterItem['allSubgroups'];
        filterItem.selectedSubgroups.forEach(selectedSGItem => {
          delete selectedSGItem['allParts'];
        });
      } else if (filterItem.type == "subgroup") {
        delete filterItem['allParts'];
      }
    });
    this.partFilterChangeEvent.emit(this.currentAppliedPartFilters);
  }
}
