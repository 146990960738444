import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, Subject, EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { debounceTime, switchMap } from 'rxjs/operators';
import * as moment from 'moment';
import { ToastService } from '../toast/toast.service';
import { AuthTokenHelper } from '../../helpers/auth-token-helper';
import { StatusHelper } from '../../helpers/status-helper';
import { OpsTimelineDTO } from '../../models/opsTimeline-dto';
import { FilteredWorkTicketTimeline } from '../../models/work-ticket-timeline-filter-dto';
import { TimelineFilterService } from '../timeline-filter/timeline-filter.service';


@Injectable({
  providedIn: 'root'
})
export class WorkTicketTimelineService {
	url = environment.apiUrl;
	currentTimelineSubject = new BehaviorSubject<OpsTimelineDTO[]>(null);
	currentTimelineNotificationSubject = new BehaviorSubject<OpsTimelineDTO[]>(null);
	timelineLoading = new BehaviorSubject<boolean>(true);
	activityTimelineService = new Subject();
	notificationTimelineService = new Subject();
	filters: FilteredWorkTicketTimeline[];
	timelineStartDate: Date;
	notificationStartDate: Date;

	constructor(public http: HttpClient, public toastSvc: ToastService, public authTokenHelper: AuthTokenHelper, public filterSvc: TimelineFilterService) {
		this.filterSvc.chosenFilters.subscribe(f => this.filters = f);
		this.resetActivityStartDate();
		this.resetNotificationStartDate();
		this.activityTimelineService.pipe(debounceTime(500)).pipe(switchMap((eventId: string) => this.filters.length > 0 ? this.getFilteredTimeline(eventId, this.filters) : this.getTimeline(eventId)
		)).subscribe(data => {
			this.setCurrentUserName(data);
			this.currentTimelineSubject.next(data);
			if (data.length < 1) {
				this.timelineLoading.next(false);
			}
		});
		this.notificationTimelineService.pipe(debounceTime(500)).pipe(switchMap((eventId: string) => this.filters.length > 0 ? this.getFilteredTimelineNotifications(eventId, this.filters) : this.getTimelineNotifications(eventId)
		)).pipe(debounceTime(500)).subscribe(data => {
			this.setCurrentUserName(data);
			this.currentTimelineNotificationSubject.next(data);
			if (data.length < 1) {
				this.timelineLoading.next(false);
			}
		});
	}
	resetNotificationStartDate() {
		this.notificationStartDate = moment.utc(moment.now()).toDate();
	}

	resetActivityStartDate() {
		this.timelineStartDate = moment.utc(moment.now()).toDate();
	}

	public getTimeline(eventId: string, ignoreError?: boolean): Observable<OpsTimelineDTO[]> {
		this.timelineLoading.next(true);
		this.currentTimelineSubject.next([]);
		if ((eventId !== null && eventId !== undefined) && eventId.length > 0) {
			return this.http.post<OpsTimelineDTO[]>(`${this.url()}events/${eventId}/work-ticket-timeline`, this.timelineStartDate).pipe(catchError(error => { return new Observable<OpsTimelineDTO[]>(); }));
		} else {
			return EMPTY ;
		}
	}
	public getFilteredTimeline(eventId: string, filterStrings: FilteredWorkTicketTimeline[], ignoreError?: boolean): Observable<OpsTimelineDTO[]> {
		this.timelineLoading.next(true);
		this.currentTimelineSubject.next([]);
		return this.http.post<OpsTimelineDTO[]>(`${this.url()}events/${eventId}/work-ticket-timeline/filter`, { startDate: this.timelineStartDate, filters: filterStrings });
	}

	public getTimelineNotifications(eventId: string): Observable<OpsTimelineDTO[]> {
		this.timelineLoading.next(true);
		this.currentTimelineNotificationSubject.next([]);
		return this.http.post<OpsTimelineDTO[]>(`${this.url()}events/${eventId}/work-ticket-timeline/${this.authTokenHelper.currentUserGuid.getValue()}`, this.notificationStartDate);
	}
	public getFilteredTimelineNotifications(eventId: string, filterStrings: FilteredWorkTicketTimeline[]): Observable<OpsTimelineDTO[]> {
		this.timelineLoading.next(true);
		this.currentTimelineNotificationSubject.next([]);
		return this.http.post<OpsTimelineDTO[]>(`${this.url()}events/${eventId}/work-ticket-timeline/${this.authTokenHelper.currentUserGuid.getValue()}/filter`, { startDate: this.timelineStartDate, filters: filterStrings });
	}

	private setCurrentUserName(data: OpsTimelineDTO[]) {
		data.forEach(i => {
			if (i.statusId) {
				i.status = StatusHelper.GetStatusFromId(i.statusId);
			}
			i.senderName = i.senderGuid === this.authTokenHelper.currentUserGuid.getValue() ? 'YOU' : i.senderName;
			i.receiverName = i.receiverGuid === this.authTokenHelper.currentUserGuid.getValue() ? 'YOU' : i.receiverName;
			i.isActiveUser = i.receiverGuid === this.authTokenHelper.currentUserGuid.getValue();
			i.location = i.lineItemNumber ? `LI#${i.lineItemNumber}` : this.getOpsUserMentionMessage(i);
		});
	}

	private getOpsUserMentionMessage(timelineitem){
		if(timelineitem.tssWorkTicketNumber){
			return `WT#${timelineitem.tssWorkTicketNumber}`;
		} else {
			return timelineitem.functionalAreaName;
		}
	}
}
