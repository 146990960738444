import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonAccordionGroup, ModalController } from '@ionic/angular';
import { ShowSetupService } from 'src/app/services/show-setup/show-setup.service';
import * as _ from 'lodash';

@Component({
  selector: 'occ-service-providers',
  templateUrl: './service-providers.component.html',
  styleUrls: ['./service-providers.component.scss'],
})
export class ServiceProvidersComponent implements OnInit {
  selectedOption: any = '';
  isAlreadyExist: boolean;
  serviceTypeName: string = '';
  newSubtypeName: string = '';
  @Input() eventGUID: string;
  @Input() serviceTypeGUID?: string;
  @Input() serviceType?: string;
  @Input() isNewServiceType?: boolean;
  @Input() serviceSubTypes?: any[] = [];
  serviceSubTypesData: any[] = [];
  isServiceSubTypeExist: boolean = false;
  isServiceTypeSaved: boolean = false;
  @ViewChild(IonAccordionGroup, { static: true }) accordionGroup: IonAccordionGroup;

  
  constructor(
    private modalCtrl: ModalController,
    private showSetupService: ShowSetupService
  ) {
  }
  
  ngOnInit() {
    if(this.serviceType != null){
      this.serviceTypeName = this.serviceType;
    }
    this.serviceSubTypesData = [...this.serviceSubTypes];
    setTimeout(() => {
      this.selectedOption = 'service-types';
    }, 300);
  }

  closeModal() {
    this.modalCtrl.dismiss({
      isServiceTypeSaved: this.isServiceTypeSaved
    });
  }

  toggleAccordion(event) {
    this.selectedOption = this.accordionGroup.value;
  }

  saveServiceType() {
    this.showSetupService.submitServiceTypes(this.eventGUID, this.serviceTypeName, this.serviceTypeGUID).subscribe((saveAreaResponse) => {
      if(saveAreaResponse){
        this.serviceTypeGUID = saveAreaResponse.kafkaId;
        this.serviceType = this.serviceTypeName;
        this.toggleAccordion('service-subtypes');
        this.isServiceTypeSaved = true;
      }
    });
  }

  checkServiceTypeExist() {
    if (this.serviceTypeName != undefined && this.serviceTypeName.trim() != "") {
      this.serviceTypeName= this.serviceTypeName.trim();
      this.showSetupService.checkServiceTypeExist(this.eventGUID, this.serviceTypeName, null).subscribe((submitResponse) => {
        this.isAlreadyExist = submitResponse;
      });
    }
  }

  addNewSubtype(){
    this.newSubtypeName = this.newSubtypeName.trim();
    this.serviceSubTypesData.push({
      type: this.newSubtypeName
    });
    this.newSubtypeName = '';
  }

  removeSubType(subTypeName: string){
    _.remove(this.serviceSubTypesData, function(stItem) {
      return stItem.type.toLowerCase() == subTypeName.toLowerCase();
    })
  }

  onNewSubtypeNameChange(){
    this.newSubtypeName = this.newSubtypeName.trim();
    if(this.newSubtypeName.length == 0){
      return this.isServiceSubTypeExist = false;
    }
    
    let duplicateSubType = _.find(this.serviceSubTypesData, (subTypeItem) => { 
      return subTypeItem.type.toLowerCase() == this.newSubtypeName.toLowerCase();
    });
    
    this.isServiceSubTypeExist = duplicateSubType ? true : false;
  }

  saveServiceSubTypeData(){
    this.showSetupService.submitServiceSubtype(this.eventGUID, {
      parentKafkaId: this.serviceTypeGUID,
      subTypes: this.serviceSubTypesData.map(subTypeItem => subTypeItem.type),
      isNew: this.isNewServiceType ? true : false
    }).subscribe((boothSaveResponse) => {
      if(boothSaveResponse){
        this.isServiceTypeSaved = true;
        this.closeModal();
      }
    });    
  }
  isValidServiceType() {
    return this.isAlreadyExist || this.serviceTypeName == null || this.serviceTypeName == undefined || this.serviceTypeName.trim() == '' || (this.serviceTypeName.trim() == this.serviceType);
  }
}
