import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { AuthTokenHelper } from 'src/app/helpers/auth-token-helper';
import { MobileNumberFormatPipe } from 'src/app/pipes/mobile-number-format.pipe';
import { ZipcodePipe } from 'src/app/pipes/zipcode-number-format.pipe';
import { CreateMhaService } from 'src/app/services/create-mha/create-mha.service';
import { KioskService } from 'src/app/services/kiosk/kiosk.service';

@Component({
  selector: 'occ-mha-shipping-info',
  templateUrl: './mha-shipping-info.component.html',
  styleUrls: ['./mha-shipping-info.component.scss'],
})
export class MhaShippingInfoComponent implements OnInit {
  @Input() isFormDisabled: any = true;
  @Input() eventGuid: any;
  @Output() onNext = new EventEmitter<any>();
  @Output() cancelMhaCreation = new EventEmitter();
  @Output() onFormInvalid = new EventEmitter<any>();
  @Output() loadNextMHASection = new EventEmitter<any>();
  shippingInfoForm: FormGroup;
  formValidated: any;
  

  constructor(
    private router: Router,
    private storage: Storage,
    public kioskService: KioskService,
    private menuCtrl: MenuController,
    private formBuilder: FormBuilder,
    public createMhaService: CreateMhaService,
    private mobileNumberFormat: MobileNumberFormatPipe,
    private zipcodePipe: ZipcodePipe,
    public tokenHelper: AuthTokenHelper
  ) { }

  async ngOnInit() {
    let isKioskUser = await this.tokenHelper.IsKioskUser();
    if(isKioskUser){
      this.menuCtrl.enable(false);
    }
    this.initializeShippingInfoForm();


    if (this.createMhaService.createMhaData.shipping)
      this.initializeShippingInfoForm(this.createMhaService.createMhaData.shipping)
    else {
      this.kioskService.currentShowName
        .subscribe(showData => {
          if (showData && this.router.url.includes('kiosk-create-mha'))
            this.getAccountInfo();
        });
    }

  }

  initializeShippingInfoForm(data = null) {
    this.shippingInfoForm = this.formBuilder.group({
      contactFName: new FormControl({ value: data && data.contactFName || null, disabled: this.isFormDisabled }, [Validators.required, Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/)]),
      contactLName: new FormControl({ value: data && data.contactLName || null, disabled: this.isFormDisabled }, [Validators.required, Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/)]),
      phoneNumber: new FormControl({ value: data && data.phoneNumber || null, disabled: this.isFormDisabled }, [Validators.required, Validators.minLength(12)]),
      companyName: new FormControl({ value: data && data.companyName || null, disabled: this.isFormDisabled }, [Validators.required]),
      address: new FormControl({ value: data && data.address || null, disabled: this.isFormDisabled }, [Validators.required]),
      address2: new FormControl({ value: data && data.address2 || null, disabled: this.isFormDisabled }, []),
      city: new FormControl({ value: data && data.city || null, disabled: this.isFormDisabled }, [Validators.required]),
      state: new FormControl({ value: data && data.state || null, disabled: this.isFormDisabled }, [Validators.required]),
      country: new FormControl({ value: data && data.country || null, disabled: this.isFormDisabled }, [Validators.required]),
      zipCode: new FormControl({ value: data && data.zipCode || null, disabled: this.isFormDisabled }, [Validators.required,Validators.pattern(/^[0-9-]*$/), Validators.minLength(5)]),
      emergencyContactFirstName: new FormControl({ value: data && data.emergencyContactFirstName || null, disabled: this.isFormDisabled }, [Validators.required, Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/)]),
      emergencyContactLastName: new FormControl({ value: data && data.emergencyContactLastName || null, disabled: this.isFormDisabled }, [Validators.required, Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/)]),
      emergencyContactMobileNo: new FormControl({ value: data && data.emergencyContactMobileNo || null, disabled: this.isFormDisabled }, [Validators.required, Validators.minLength(12)]),
    })

    setTimeout(() => {
      this.shippingInfoForm.valueChanges.subscribe(() => {
        this.createMhaService.isShippingFormValid = this.shippingInfoForm.valid;
        if (this.createMhaService.isShippingFormCompleted) {
          this.formValidated = true;
          if (this.shippingInfoForm.valid)
            this.createMhaService.createMhaData.shipping = this.shippingInfoForm.value;
        }
      })
    }, 500);
  }

  getAccountInfo() {
    this.storage.get('currentBooth').then(booth => {
      if (booth) {
        this.kioskService.getAccountInfo(booth.passportShowAccountId).subscribe(res => {
          if (res) {
            if (res.country) {
              let country = this.createMhaService.countryData.find(x => x.value == res.country);
              res.country = country || res.country;
            }
            if (res.state) {
              let state = this.createMhaService.stateData.find(x => x.value == res.state);
              res.state = state || res.state;
            }
            res.zipcode = res.zipcode ? this.zipcodePipe.transform(res.zipcode) : null;
            res.phoneNumber = res.phoneNumber ? this.mobileNumberFormat.transform(res.phoneNumber) : null;
            this.initializeShippingInfoForm(res);
          }
        });
      }
    })
  }

  onPhoneNumberChange(event, controlName) {
    let objectToUpdate = {};
    if (!event.isNumberInvalid) {
      objectToUpdate[controlName] = event.phoneNumber;
      this.shippingInfoForm.patchValue(objectToUpdate);
    } else {
      this.shippingInfoForm.controls[controlName].setErrors({ pattern: true });
    }
  }

  setCountryCodeSelection(currentCountryCodeSelection) {
  }

  onSelectionChange(event, controlName) {
    let objectToUpdate = {};
    objectToUpdate[controlName] = event;
    this.shippingInfoForm.patchValue(objectToUpdate);
  }

  cancelMHACreation() {
    this.cancelMhaCreation.emit();
  }

  async goToNextStep() {
    if (this.createMhaService.mhaGUID) {
      this.loadNextMHASection.emit(2);
      return;
    }

    this.formValidated = true;
    this.createMhaService.createMhaData.shipping = this.shippingInfoForm.value;
    if (this.shippingInfoForm.valid || this.createMhaService.mhaGUID) {
      if (!this.createMhaService.mhaGUID) {
        let res = await this.createMhaService.validateZipCode(this.eventGuid, 'shipping');
        if (!res) {
          this.onFormInvalid.emit("Postal Code is invalid for the selected state");
          return;
        }
      }
      this.loadNextMHASection.emit(2);
    }
    else
      this.onFormInvalid.emit();
  }

  changeZipCodeFormat(event){
    let zipCode = this.zipcodePipe.transform(event.target.value);
    this.shippingInfoForm.patchValue({zipCode});
  }

}
