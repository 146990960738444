import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterHelper } from 'src/app/helpers/filter-helper';
import { SelectHelper } from 'src/app/helpers/select-helper';
import { StatusHelper } from 'src/app/helpers/status-helper';
import { Status } from 'src/app/models/status';

@Component({
  selector: 'occ-filter-status',
  templateUrl: './filter-status.component.html',
  styleUrls: ['./filter-status.component.scss'],
})
export class FilterStatusComponent {
	@Output() closeFilter = new EventEmitter();
	@Output() navigate = new EventEmitter();
	@Output() filterStatus = new EventEmitter<Status>();
	@Input() isFunctionalAreasPage: boolean;

	statuses: Array<Status>;
	selectedItem: number;
	isWorkTicketSearchPage: boolean;
	pageReturn: string;

	constructor(public filterHelper: FilterHelper) {
		if (this.filterHelper.workticketSearchPage) {
			this.statuses = StatusHelper.WorkTicketStatuses;
			this.pageReturn = 'workticketPage';
			this.isWorkTicketSearchPage = true;
		} else {
			this.statuses = this.filterHelper.lineItemStatusSelected ? StatusHelper.LineItemStatuses : StatusHelper.FunctionalAreaFilterStatuses;
			this.pageReturn = 'selection';
		}

	}

	hideFilter(): void {
		this.closeFilter.emit();
	}

	getCurrentStatus(status: Status) {
		if (this.isFunctionalAreasPage) {
			if (this.filterHelper.functionalAreasPageStatusFilter.find (x => x === status)) return true;
		} else if (this.filterHelper.lineItemStatusSelected) {
			if (this.filterHelper.lineItemStatus.find(x => x === status)) return true;
		} else if (this.filterHelper.workTicketStatusSelected) {
			if (this.filterHelper.workTicketStatus.find(x => x === status)) return true;
		} else {
			if (this.filterHelper.functionalAreaStatus.find(x => x === status)) return true;
		}
	}

	applyFilter(status: Status, index: number): void {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		this.filterStatus.emit(status);
		this.selectedItem = index;

		this.filterHelper.setStatus(status, this.filterHelper.lineItemStatusSelected, this.filterHelper.workTicketStatusSelected);
		this.filterHelper.applyFilters();

	}

	resetFilter(): void {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		if (this.filterHelper.lineItemStatusSelected) {
			this.filterHelper.lineItemStatus = [];
		} else if (this.filterHelper.workTicketStatusSelected) {
			this.filterHelper.workTicketStatus = [];
		} else {
			this.filterHelper.functionalAreaStatus = [];
		}
		if (!this.isFunctionalAreasPage) this.filterHelper.applyFilters();
		this.filterStatus.emit(null);
	}

	showPage(pageToShow): void {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		this.navigate.emit(pageToShow);
	}
}
