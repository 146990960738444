import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { ShowBooth } from 'src/app/models/show-booth-dto';
import { EsBoothsService } from 'src/app/services/es-booths/es-booths.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { ExhibitAccountDetailsComponent } from '../exhibit-account-details/exhibit-account-details.component';
import { IonContent, ModalController } from '@ionic/angular';

@Component({
  selector: 'occ-es-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  subscribe = true;
  esBoothsData: any = [];
  esBoothsFilteredData: any = [];
  isLoading: boolean = false;
  isSearchActive: boolean = false;
  @Input() showGuid: any;
  @ViewChild(IonContent, { static: false }) content: IonContent;
  searchString: string = "";
  constructor( public esBoothService: EsBoothsService, private router: Router, private modalCtrl: ModalController) { }

  ngOnInit() {
    this.esBoothService.esBooths.pipe(takeWhile(() => this.subscribe)).subscribe(esBoothsData => {
      this.isLoading = true;
      this.esBoothsData = _.chain(esBoothsData)
        .groupBy(x => x.showAccountGUID )
        .map((value, key) => ({ showAccountGUID: key, booths: value }))
        .value();
        this.getSearchResult();
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
    });
  }

  pushToBoothDetailsPage(boothGUID) {
    this.router.navigateByUrl('events/' + this.showGuid + '/es-booths/' + boothGUID + '/exhibitor-booth-details');
  }

  openSearchModal() {
    this.isSearchActive = !this.isSearchActive;
  }

  async navigateToAccountPage(showAccountGUID,accountId) {
    let accountDetailsModal = await this.modalCtrl.create({
      component: ExhibitAccountDetailsComponent,
      cssClass: 'exhibit-account-details',
      backdropDismiss: false,
      componentProps: {
      'showGuid':this.showGuid,
      'exhibitorAccountGuid': showAccountGUID,
      'accountId': accountId
      }
      });
      accountDetailsModal.present();
  }

  clearSearchData() {
    this.searchString = '';
    this.getSearchResult();
  }

  getSearchResult() {
    this.esBoothsFilteredData = [];
    this.esBoothsData.forEach(element => {
      var data = {
        showAccountGUID: element.showAccountGUID,
        showAccountName: element.booths[0]?.showAccountName,
        accountId: element.booths[0]?.passportShowAccountId,
        booths: element.booths,
        isVIP: element.booths[0]?.isVIP
      }
      this.esBoothsFilteredData.push(data);    
    });
    if(this.searchString){
      this.esBoothsFilteredData = _.remove(this.esBoothsFilteredData, (account) => {
          return (account.booths[0]?.showAccountName.toLowerCase().startsWith(this.searchString.toLowerCase()));
        });
    }
    this.content ? this.content.scrollToTop() : "";
  }

}
