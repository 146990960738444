import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'occ-sortby',
  templateUrl: './sortby.component.html',
  styleUrls: ['./sortby.component.scss'],
})
export class SortbyComponent implements OnInit {
  @Input() sortOptions: any;
  @Input() defaultValue: any;
  @Output() onSortSelectionOption = new EventEmitter<any>();
  isDropdownActive:boolean = false;
  
  @ViewChild('sortDropdown') sortDropdown: ElementRef;
  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    if (this.sortDropdown && !this.sortDropdown.nativeElement.contains(target) && this.isDropdownActive) {
        this.isDropdownActive = !this.isDropdownActive;
    }
  }

  currentSortOptionValue: string = "";
  displaySubText: string = "";
  constructor() { }

  ngOnInit() {
    this.isDropdownActive = false;
    this.currentSortOptionValue = this.defaultValue && this.defaultValue.display ? this.defaultValue.display : ""
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.defaultValue){
      this.currentSortOptionValue = changes.defaultValue.currentValue ? changes.defaultValue.currentValue.display : "";
    }
  }

  setSortSelection(sortOption: any) {
    this.isDropdownActive = !this.isDropdownActive
    this.currentSortOptionValue = sortOption.display;
    this.displaySubText = sortOption.displaySubText;
    this.onSortSelectionOption.emit(sortOption);
  }

  onInputFocusChange(){
    this.isDropdownActive = !this.isDropdownActive;
  }
}
