import { Injectable } from '@angular/core';
import { StatusHelper } from './../../helpers/status-helper';
import { Status } from './../../models/status';
import { HttpClient } from '@angular/common/http';
import { StatusChangeDownloadDto } from './../../models/status-change-download-dto';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { CollaborationLineItemDTO } from '../../models/collaboration-line-item-dto';
import { ToastService } from '../toast/toast.service';
import { FunctionalAreaService } from '../functional-area/functional-area.service';
import { ActivityService } from '../activity/activity.service';
import { LineItemStatusChangeUploadDto } from '../../models/line-item-status-change-upload-dto';
import { LITabsEnum } from '../../helpers/tabs-enum';
import { FunctionalArea } from '../../models/functional-area';
import { LoadingController } from '@ionic/angular';
import * as moment from 'moment';
import { UserService } from '../user/user.service';
import { ToastType } from '../../models/toast-type';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { LocationDTO } from '../../models/location-dto';
import { BoothDTO } from '../../models/booth-dto';
import { ChangeOrderDTO } from '../../models/change-order-dto';
import * as _ from 'lodash';
import { SelectableOption } from '../../models/selectable-option';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LineItemService {
	url = environment.apiUrl;
	collaborationLineItemDto = new BehaviorSubject<CollaborationLineItemDTO[]>([]);
	locationDto = new BehaviorSubject<LocationDTO[]>([]);
	boothDto = new BehaviorSubject<BoothDTO[]>([]);
	allLineItemsSubject = new BehaviorSubject<CollaborationLineItemDTO[]>([]);
	currentLineItem = new BehaviorSubject<CollaborationLineItemDTO>(null);
	vendorListSubject = new BehaviorSubject<string[]>([]);
	boothListSubject = new BehaviorSubject<string[]>([]);
	locationListSubject = new BehaviorSubject<string[]>([]);
	categoryListSubject = new BehaviorSubject<string[]>([]);
	facilityLineItemSubject = new BehaviorSubject<string[]>([]);
	lineItemsLoading = new BehaviorSubject<boolean>(null);
	changeOrdersLoading = new BehaviorSubject<boolean>(null);
	public currentLineItemDto: CollaborationLineItemDTO[];
	public currentLineItemValue: CollaborationLineItemDTO;
	public currentTab: LITabsEnum;
	public searchRequestComplete: boolean;
	changeOrderDto = new BehaviorSubject<ChangeOrderDTO[]>([]);
	allChangeOrdersSubject = new BehaviorSubject<ChangeOrderDTO[]>([]);

	constructor(
		public http: HttpClient,
		public toastService: ToastService,
		public funcSvc: FunctionalAreaService,
		public activitySvc: ActivityService,
		public loadCtrl: LoadingController,
		public userSvc: UserService,
		private ga: GoogleAnalytics,
	) {
		this.collaborationLineItemDto.subscribe(dto => {
			this.currentLineItemDto = dto;
		});
		this.currentLineItem.subscribe(li => {
			this.currentLineItemValue = li;
		});
	}

	get(showId: string, accountId: string, functionalAreaId: string, replaceStatusOfLineItem?: boolean) {
		this.lineItemsLoading.next(true);
		this.refresh(showId, accountId, functionalAreaId, null, replaceStatusOfLineItem).subscribe();
	}

	searchLineItems(showId: string, accountId: string, data: string): Observable<CollaborationLineItemDTO[]> {
		this.lineItemsLoading.next(true);
		this.searchRequestComplete = false;
		return this.http.get<CollaborationLineItemDTO[]>(`${this.url()}events/${showId}/accounts/${accountId}/functional-areas/0/line-items/search?query=${data}`)
			.pipe(map(data => {
				data.forEach(f => f.status = StatusHelper.GetStatusFromId(f.currentStatus));
				const sortedLineItems = this.sortLineItems(data);
				this.populateFilterLists(sortedLineItems);
				this.collaborationLineItemDto.next(sortedLineItems);
				this.lineItemsLoading.next(false);
				this.searchRequestComplete = true;
				return sortedLineItems;
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error refreshing the line item data', 'danger');
				}
			}));
	}

	searchChangeOrders(eventId: string, accountId: string, data: string): Observable<ChangeOrderDTO[]> {
		this.changeOrdersLoading.next(true);
		this.searchRequestComplete = false;
		return this.http.get<ChangeOrderDTO[]>(`${this.url()}events/${eventId}/accounts/${accountId}/change-orders`)
			.pipe(map(data => {
				data.forEach(f => f.status = StatusHelper.GetStatusFromId(f.currentStatus));
				const sortedChangeOrders = this.sortChangeOrders(data);
				//this.populateFilterLists(sortedLineItems);
				this.changeOrderDto.next(sortedChangeOrders);
				this.changeOrdersLoading.next(false);
				this.searchRequestComplete = true;
				return sortedChangeOrders;
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error refreshing the change order data', 'danger');
				}
			}));
	}

	getChangeOrderByLineItemId(eventId: string, accountId: string, lineItemId: number): Observable<ChangeOrderDTO[]> {
		return this.http.get<ChangeOrderDTO[]>(`${this.url()}events/${eventId}/accounts/${accountId}/change-orders/line-item/${lineItemId}`)
			.pipe(map(data => {
				return data;
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error change order data', 'danger');
				}
			}));
	}

	getChangeOrderByOrderId(eventId: string, accountId: string, orderId: number): Observable<ChangeOrderDTO> {
		return this.http.get<ChangeOrderDTO>(`${this.url()}events/${eventId}/accounts/${accountId}/change-orders/${orderId}`)
			.pipe(map(data => {
				return data;
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error change order data', 'danger');
				}
			}));
	}

	getDetails(eventId: string, accountId: string, functionalAreaId: string, lineItemId: string): void {
		const lineItemsArray = this.currentLineItemDto;
		if (lineItemsArray.length < 1) {
			this.get(eventId, accountId, functionalAreaId);
		}
		this.refreshDetails(eventId, accountId, functionalAreaId, lineItemId);
	}

	setCurrentLineItem(lineItemId: string): void {
		const lineItem = this.currentLineItemDto.find(li => li.kafkaId === lineItemId);
		this.currentLineItem.next(lineItem);
	}


	refresh(showId: string, accountId: string, functionalAreaId: string, errorCallback?: Function, replaceStatusOfLineItem = true): Observable<boolean> {
		return this.http.get<Array<CollaborationLineItemDTO>>(`${this.url()}events/${showId}/accounts/${accountId}/functional-areas/${functionalAreaId}/line-items`)
			.pipe(map((data) => {
				data.forEach(f => f.status = StatusHelper.GetStatusFromId(f.currentStatus));

				if (this.currentLineItemValue && replaceStatusOfLineItem) {
					const freshCopyOfCurrentLineItem = data.find(li => li.kafkaId === this.currentLineItemValue.kafkaId);
					if (freshCopyOfCurrentLineItem && freshCopyOfCurrentLineItem.status.id !== this.currentLineItemValue.status.id) {
						freshCopyOfCurrentLineItem.status = this.currentLineItemValue.status;
						freshCopyOfCurrentLineItem.statusDate = this.currentLineItemValue.statusDate;
						// StatusHelper.UpdateFunctionalAreaStatus(this.funcSvc.currentFunctionalArea, data, freshCopyOfCurrentLineItem);
					}
				}

				const sortedLineItems = this.sortLineItems(data);
				this.collaborationLineItemDto.next(sortedLineItems);
				this.lineItemsLoading.next(false);

				return true;
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error refreshing the line item data', 'danger');
				}
				if (errorCallback) errorCallback();
			}));
	}

	refreshDetails(eventId: string, accountId: string, functionalAreaId: string, lineItemId: string, errorCallback?: Function): void {
		this.http.get<CollaborationLineItemDTO>(`${this.url()}events/${eventId}/accounts/${accountId}/functional-areas/${functionalAreaId}/line-items/${lineItemId}`)
			.pipe(map(data => {
				// this needs revised to match actual implementation
				data.status = StatusHelper.GetStatusFromId(data.currentStatus);
				return data;
			}))
			.subscribe(data => {
				if (this.currentLineItemValue) {
					if (data.status.id !== this.currentLineItemValue.status.id) {
						data = this.currentLineItemValue;
					}
				}

				this.currentLineItem.next(data);
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error refreshing the account data', 'danger');
				}
				if (errorCallback) errorCallback();
			});
	}

	getUpdatedLineItem(eventId: string, accountId: string, functionalAreaId: string, lineItemId: string): Observable<CollaborationLineItemDTO> {
		return this.http.get<CollaborationLineItemDTO>(`${this.url()}events/${eventId}/accounts/${accountId}/functional-areas/${functionalAreaId}/line-items/${lineItemId}`)
			.pipe(map(data => {
				// this needs revised to match actual implementation
				data.status = StatusHelper.GetStatusFromId(data.currentStatus);
				this.currentLineItemValue = data;
				return data;
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error refreshing lineitem data', 'danger');
				}
			}));
	}

	getLocations(eventId: string, accountId: string, functionalAreaId: string): Observable<LocationDTO[]> {
		return this.http.get<LocationDTO[]>(`${this.url()}events/${eventId}/accounts/${accountId}/functional-areas/${functionalAreaId}/line-items/locations`)
			.pipe(map(data => {
				this.locationDto.next(data);
				return data;
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error getting locations.', 'danger');
				}
			}));
	}

	getBooths(eventId: string, accountId: string, functionalAreaId: string, locationId: string): Observable<BoothDTO[]> {
		return this.http.get<BoothDTO[]>(`${this.url()}events/${eventId}/accounts/${accountId}/functional-areas/${functionalAreaId}/line-items/locations/${locationId}`)
			.pipe(map(data => {
				this.boothDto.next(data);
				return data;
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error getting booths.', 'danger');
				}
			}));
	}

	getChangeOrders(eventId: string, accountId: string): Observable<ChangeOrderDTO[]> {
		return this.http.get<ChangeOrderDTO[]>(`${this.url()}events/${eventId}/accounts/${accountId}/change-orders`)
			.pipe(map(data => {
				this.changeOrderDto.next(data);
				return data;
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error getting change log.', 'danger');
				}
			}));
	}

	lookupLineItemById(lineItemId: string) {
		const lineItem = this.currentLineItemDto.find(li => li.kafkaId === lineItemId);
		return lineItem;
	}

	put(showId: string, accountId: string, functionalAreaId: string, lineItemId: string, newStatus: Status, noteBody?: string, photoUri?: string, cb?: Function): void {
		const currentLineItem = this.currentLineItemValue;

		const lineItem = this.lookupLineItemById(lineItemId);
		const usersToNotify = noteBody ? this.userSvc.verifyMentionedUsers(noteBody) : [];

		// To keep all the necessary values for the detail dto. Will be redundant after booth is sent with line item list.
		if (currentLineItem && currentLineItem.kafkaId === lineItemId) {
			lineItem.booth = currentLineItem.booth;
		}

		// make a copy of the original before changing the status to revert back to in case of error
		const originalLineItem: CollaborationLineItemDTO = JSON.parse(JSON.stringify(lineItem));
		const originalLineItemDto: CollaborationLineItemDTO[] = JSON.parse(JSON.stringify(this.collaborationLineItemDto.getValue()));

		const functionalAreaDetailDto = this.funcSvc.currentFunctionalAreas.find(fa => fa.functionalAreaGuid === functionalAreaId);
		const originalFunctionalAreaDto: FunctionalArea[] = JSON.parse(JSON.stringify(this.funcSvc.allFunctionalAreasSubject.getValue()));
		// we don't need to make a copy of the functional area detail dto (the source for the functional area information on the fa details page) because
		// we are syncing that with the fa with that id that is in the functionalAreasDto.

		// const originalActivityItems = JSON.parse(JSON.stringify(this.activitySvc.lineItemActivityItems.getValue()));

		lineItem.status = newStatus;
		lineItem.statusDate = moment.utc(moment.now()).toDate();

		// NOTE: Keep this for when it's decided we want instant updates again
		// const date = new Date();
		// const liStatusChange: StatusChange = {
		// 	kafkaId: '',
		// 	functionalAreaGuid: '',
		// 	createdDate: date,
		// 	createdBy: 'saving...',
		// 	statusId: lineItem.status.id,
		// 	status: lineItem.status
		// };
		this.collaborationLineItemDto.next(this.currentLineItemDto);
		this.allLineItemsSubject.next(this.currentLineItemDto);
		this.currentLineItem.next(lineItem);
		// this.activitySvc.addItemToActivityItems(date, null, null, liStatusChange, true);
		// StatusHelper.UpdateFunctionalAreaStatus(functionalAreaDetailDto, this.currentLineItemDto, lineItem);
		this.funcSvc.currentFuncAreaSubject.next(functionalAreaDetailDto);

		const uploadDto: LineItemStatusChangeUploadDto = {
			noteBody: noteBody,
			photoUri: photoUri,
			lineItemStatus: newStatus.id,
			usersToNotify: usersToNotify
		};

		this.http.put<StatusChangeDownloadDto>(`${this.url()}events/${showId}/accounts/${accountId}/functional-areas/${functionalAreaId}/line-items/${lineItem.kafkaId}`, uploadDto)
			.subscribe(data => {
				data.statusChange.status = StatusHelper.GetStatusFromId(data.statusChange.statusId);
				this.activitySvc.addItemToActivityItems(data.statusChange.createdDate, data.note, data.photo, data.statusChange, true);
				this.ga.trackEvent('LineItemStatusChange', data.statusChange.status.title);
				if (uploadDto.usersToNotify.length > 0) {
					this.ga.trackEvent('UserMentioned', 'UserMentioned');
				}
				this.trackStatusChangeTiming(originalLineItem, newStatus);
				// NOTE: Keep this for when it's decided we want instant updates again
				// const activityItems = this.activitySvc.lineItemActivityItems.getValue();
				// const index = activityItems.findIndex(item => item.createdDate === date);
				// this.activitySvc.addItemToActivityItems(data.statusChangeDto.createdDate, null, null, data.statusChangeDto, true, index);
			}, error => {
				switch (error.status) {
					case 400:
						this.toastService.open('400 error: Either line item IDs do not match, or model state is invalid.', 'danger');
						break;
					case 404:
						this.toastService.open(`No line item with id: ${lineItem.kafkaId}`, 'danger');
						break;
					case 401:
					case 403: {
						// Unauthorized
						break;
					}
					default:
						this.toastService.open('Unable to update, unknown error.', 'danger');
						break;
				}

				if (lineItem.kafkaId === this.currentLineItem.getValue().kafkaId)
					this.currentLineItem.next(originalLineItem);

				this.collaborationLineItemDto.next(originalLineItemDto);
				this.funcSvc.allFunctionalAreasSubject.next(originalFunctionalAreaDto);
				this.funcSvc.setFaReferenceToFaDTO();
				// this.activitySvc.lineItemActivityItems.next(originalActivityItems);
			});
	}

	public sortLineItems(lineItems: CollaborationLineItemDTO[]): CollaborationLineItemDTO[] {
		// sort by vendor alphabetically
		lineItems.sort((a, b) => {
			if (a.vendorName < b.vendorName) {
				return -1;
			} else if (b.vendorName < a.vendorName) {
				return 1;
			}
			// if the vendor name is the same, sort by passport Id
			return a.passportId - b.passportId;
		});

		// then sort by lineItemId
		return lineItems;
	}

	public sortChangeOrders(changeOrders: ChangeOrderDTO[]): ChangeOrderDTO[] {
		// sort by vendor alphabetically
		changeOrders.sort((a, b) => {
			if (a.createdDate < b.createdDate) {
				return -1;
			} else if (b.createdDate < a.createdDate) {
				return 1;
			}
			// if the vendor name is the same, sort by passport Id
			return a.lineItemId - b.lineItemId;
		});

		// then sort by lineItemId
		return changeOrders;
	}

	public populateFilterLists(lineItems: CollaborationLineItemDTO[]): void {
		const vendors: string[] = [];
		const booths: string[] = [];
		const locations: string[] = [];
		const categories: string[] = [];
		lineItems.forEach(li => {
			if (li.vendorName && vendors.indexOf(li.vendorName) === -1)
				vendors.push(li.vendorName);
			if (li.booth && booths.indexOf(li.booth) === -1)
				booths.push(li.booth);
			if (li.locationInfo && locations.indexOf(li.locationInfo) === -1)
				locations.push(li.locationInfo);
			if (li.category && categories.indexOf(li.category) === -1)
				categories.push(li.category);
		});

		this.vendorListSubject.next(vendors);
		this.boothListSubject.next(booths);
		this.locationListSubject.next(locations);
		this.categoryListSubject.next(categories);
	}

	searchWorkTicketLineItems(showId: string): Observable<CollaborationLineItemDTO[]> {
		return this.http.get<CollaborationLineItemDTO[]>(`${this.url()}events/${showId}/work-tickets/showaccounttype/0/all-line-items`)
			.pipe(map(data => {
				let sortedLineItems = [];
				if(data){
					data.forEach(f => f.status = StatusHelper.GetStatusFromId(f.currentStatus));
					sortedLineItems = this.sortLineItems(data);
					this.populateFilterLists(sortedLineItems);
					this.collaborationLineItemDto.next(sortedLineItems);
					this.searchRequestComplete = true;
				}
				return sortedLineItems;
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error searching work tickets.', 'danger');
				}
			}));
	}

	trackStatusChangeTiming(originalLI: CollaborationLineItemDTO, newStatus: Status) {
		if (originalLI.status.id === StatusHelper.Status.OnHold.id) {
			this.ga.trackEvent('LineItemChangedFromActionRequiredStatus', moment(originalLI.statusDate).utc().fromNow(true), null, moment.utc().diff(moment.utc(originalLI.statusDate).valueOf()));
		}
	}

	getSelectableOptions(eventId: string, accountId: string, functionalAreaId: string, lineItemGUID: string): Observable<SelectableOption[][]> {
		// lineItemGUID = 'A53D12F3-0A6B-4FD5-8283-28AE07761F18';
		// PartId is null for this as we are using the same Api for ReviseChangeOrder and NewChangeOrder
		return this.http.get<any[]>(`${this.url()}events/${eventId}/accounts/${accountId}/functional-areas/${functionalAreaId}/line-items/${lineItemGUID}/partselections/null`)
			.pipe(map(selectableOptionsData => {
				let selectableDataArray = [];
				let sortedData = _.sortBy(selectableOptionsData, ['selectableLevel', 'basePartId']);

				while (sortedData.length > 0) {
					let matchDataObject = sortedData[0];
					selectableDataArray.push(_.remove(sortedData, function (dataObject) {
						return dataObject.basePartId == matchDataObject.basePartId;
					}));
				}

				return selectableDataArray;
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error getting selectable options.', 'danger');
				}
			}));
	}

	getAssociatedLineItems(eventId: string, accountId: string, functionalAreaGuid: string, lineItemGUID: string): Observable<any[]> {
		return this.http.get<any[]>(`${this.url()}events/${eventId}/accounts/${accountId}/functional-areas/${functionalAreaGuid}/line-items/${lineItemGUID}/getGroupedLineItemsForOrder`)
			.pipe(map(associatedItems => {
				return associatedItems;
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error getting associated line items.', 'danger');
				}
			}));
	}

	cancelOrderForLineItem(eventId: string, accountId: string, lineItemGUID: string): Observable<boolean> {
		return this.http.post<boolean>(this.url() + 'events/' + eventId + '/accounts/' + accountId + '/change-orders/cancel-lineitem/' + lineItemGUID, {}).pipe(map(cancelOrderResponse => {
			this.toastService.open('Success! Cancel Order completed.', 'success');
			return cancelOrderResponse;
		}, err => {
			if (err.status !== 401 && err.status !== 403) {
				this.toastService.open('There was an error for canceling order.', 'danger');
			}
		}));
	}
}
