import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'occ-occ-mobile-select-modal',
  templateUrl: './occ-mobile-select-modal.component.html',
  styleUrls: ['./occ-mobile-select-modal.component.scss'],
})
export class OccMobileSelectModalComponent {

  optionsData: any;
  selectedOption: any;
  currentSelectTitle: string;

  constructor(private modalCtrl: ModalController, private navParam: NavParams) {
    this.optionsData = this.navParam.get('optionsData');
    this.currentSelectTitle = this.navParam.get('selectTitle');
    this.selectedOption = this.navParam.get('dropdownSelectedValue');
  }

  setSelection(){
    this.modalCtrl.dismiss({
      title: this.currentSelectTitle,
      value: this.selectedOption
    });
  }

  cancelModal() {
		this.modalCtrl.dismiss();
  }
  
  onSelectionChanged(selectedData){
  }
}

