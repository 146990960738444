import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonAccordionGroup, LoadingController, ModalController } from '@ionic/angular'; 
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';
import * as _ from 'lodash';
import { Area } from '../../../models/show-setup/area-dto';
import { ShowSetupService } from '../../../services/show-setup/show-setup.service';
import { ShowArea } from 'src/app/models/show-setup/show-area-dto';
import { ShowBooth } from 'src/app/models/show-booth-dto';
import { ToastService } from 'src/app/services/toast/toast.service';
import { AreaTypeEnum } from '../../../helpers/area-type-enum';


@Component({
  selector: 'occ-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.scss'],
})
export class AreaComponent implements OnInit {
  @ViewChild(IonAccordionGroup, { static: true }) accordionGroup: IonAccordionGroup;
  @Input() areaItem: ShowArea;
  @Input() eventGUID: string;
  @Input() isNewArea: string;
  @Input() isAreaCopy: boolean;
  selectedOption: any = '';
  areaData = <Area>{};
  boothSearchParam: string = '';
  allBoothsList: ShowBooth[] = [];
  searchFilteredData: ShowBooth[] = [];
  selectedBooths: ShowBooth[] = [];
  selectedBoothSegment: string = "1";
  isAllBoothSelected: boolean = false;
  isAllBoothVisible: boolean = false;
  isAlreadyExist: boolean = false;
  isRangeApplied: boolean = false;
  isAreaSaved: boolean = false;
  isRangeCloseVisible: boolean = false;
  boothStartRange: string = '';
  boothEndRange: string = '';
  currentAreaGUID: string = '';
  areaLabel = '';
  constructor(
    private modalCtrl: ModalController,
    public appBrowserHelper: AppBrowserHelper,
    public showSetupService: ShowSetupService,
    public toastService: ToastService,
    public loadingCtrl: LoadingController
  ) {
    
  }

  ngOnInit() {   
    if (this.appBrowserHelper.isDesktop) {
      setTimeout(() => {
        this.selectedOption = 'groupname';
      }, 300);
    }
  }

  ngAfterViewInit() {
    this.initializeGroupingForm(this.areaItem);
    this.showSetupService.getBoothsList(this.eventGUID).subscribe((boothsData) => {
      this.allBoothsList = boothsData;
      this.setSelectedBooths();
      this.getSearchResult();
    });
  }

  initializeGroupingForm(data) {
    this.areaData = <Area>{};
    if(this.isAreaCopy){
      this.areaData.kafkaId = '';
      this.areaData.isActive = true;
      this.areaData.areaType = AreaTypeEnum.Booth_Zone;
      this.areaData.name = data?.name ? data.name.trim() : '';
      this.currentAreaGUID = '';
      this.checkAreaExist();
    } else {
      this.areaData.kafkaId = data != undefined ? data.areaGuid : '';
      this.areaData.isActive = data != undefined ? null : true;
      this.areaData.areaType = data != undefined ? data.areaType : AreaTypeEnum.Booth_Zone;
      this.areaData.name = data != undefined ? data.name.trim() : '';
      this.currentAreaGUID = data?.areaGuid ? data.areaGuid : '';
      this.areaLabel = this.areaData.name;
    }
  }

  setSelectedBooths(){
    if(this.areaItem && this.areaItem.booths?.length){
      let allSelectedBoothGUIDs = this.areaItem.booths.map(booth => booth.boothGuid);
      this.allBoothsList.forEach(boothItem => {
        if(allSelectedBoothGUIDs.includes(boothItem.boothGUID)){
          boothItem.isBoothSelected = true;
          this.selectedBooths.push(boothItem);
        }
      });
      this.selectedBooths = _.uniqBy(this.selectedBooths, function(selectedBoothItem) {
        return selectedBoothItem.boothGUID;
      })
    }
  }

  toggleAccordion(event) {
    this.selectedOption = this.accordionGroup.value;
  }

  closeModal() {
    this.modalCtrl.dismiss({
      'isAreaSaved': this.isAreaSaved
    });
  }

  async saveAreas() {
    this.showSetupService.submitAreaGrouping(this.eventGUID, this.areaData).subscribe((saveAreaResponse) => {
      if(saveAreaResponse){
        this.currentAreaGUID = saveAreaResponse.kafkaId;
        this.isAreaSaved = true;
        this.areaLabel = this.areaData.name;
      }
    });
  }

  isValidName() {
    return this.isAlreadyExist || this.areaData.name == null || this.areaData.name == undefined || this.areaData.name.trim() == '' || ((!this.isNewArea && !this.isAreaCopy) && (this.areaData.name.trim() == this.areaItem.name));
  }
  checkAreaExist() {
    if (this.areaData.name != undefined && this.areaData.name.trim() != "") {
      this.areaData.name = this.areaData.name.trim();
      this.showSetupService.checkAreaExist(this.eventGUID, this.areaData.areaType, this.areaData.name).subscribe((submitResponse) => {
        this.isAlreadyExist = submitResponse;
      });
    }
  }

  onBoothSearchParamChange(){
    this.isRangeApplied = false;
    this.getSearchResult();
  }

  getSearchResult(){
    let isBoothRangeValid = true;
    if(this.boothStartRange?.length && this.boothEndRange?.length){
      isBoothRangeValid = (this.boothEndRange?.toLowerCase().localeCompare(this.boothStartRange?.toLowerCase(), 'en', { numeric: true }) != -1);
      if(!isBoothRangeValid){
        this.toastService.open('Start range must be less than End range for booth search.', 'danger');
        return;
      }
    }

    let boothData = this.selectedBoothSegment == "4" ? this.selectedBooths : this.allBoothsList;

    if(!this.boothSearchParam.length){
      this.searchFilteredData = boothData;
    } else {
      this.searchFilteredData = _.filter(boothData, (boothItem) => {
        return boothItem.boothName.toLowerCase().includes(this.boothSearchParam?.toLowerCase());
      });
      this.isAllBoothVisible = true;
    }

    if(isBoothRangeValid){
      if(this.boothStartRange?.length){
        this.searchFilteredData = _.filter(this.searchFilteredData, (srItem) => {
          return srItem.boothName?.toLowerCase().localeCompare(this.boothStartRange?.toLowerCase(), 'en', { numeric: true }) != -1;
        });
      }
  
      if(this.boothEndRange?.length){
        this.searchFilteredData = _.filter(this.searchFilteredData, (srItem) => {
          return srItem.boothName?.toLowerCase().localeCompare(this.boothEndRange?.toLowerCase(), 'en', { numeric: true }) != 1;
        });
      }
    }

    this.getEvenOddBoothList();
    this.updateAllSectionStatus();
    this.getSortedDataList(this.searchFilteredData);
  }

  clearSearch(){
    this.boothSearchParam = '';
    this.getSearchResult();
  }

  getBoothRangeFilterData(){
    this.isRangeCloseVisible = !this.isRangeCloseVisible;
    this.boothStartRange = this.isRangeCloseVisible ? this.boothStartRange : '';
    this.boothEndRange = this.isRangeCloseVisible ? this.boothEndRange : '';
    this.isRangeApplied = this.isRangeCloseVisible ? true : false;

    this.getSearchResult();
    this.isAllBoothVisible = true;
  }

  showAllBooths(){
    this.isAllBoothVisible = !this.isAllBoothVisible;
  }

  toggleAllBoothSelection(isToggleClicked?: boolean){    
    if(isToggleClicked){
      this.isAllBoothSelected = !this.isAllBoothSelected;
    } else {
      this.isAllBoothSelected = this.isRangeApplied? this.isRangeApplied : !this.isAllBoothSelected;
    }
    
    this.searchFilteredData.forEach(boothItem => {
      boothItem.isBoothSelected = this.isAllBoothSelected;
    });

    if(this.isAllBoothSelected){
      this.searchFilteredData.forEach(boothItem => {
        this.selectedBooths.push(boothItem);
      });
      
      this.selectedBooths = _.uniqBy(this.selectedBooths, function(selectedBoothItem) {
        return selectedBoothItem.boothGUID;
      })
    } else{
      this.searchFilteredData.forEach(boothItem => {
        _.remove(this.selectedBooths, function(selectedBoothItem) {
          return selectedBoothItem.boothGUID == boothItem.boothGUID;
        });
      });
    }
  }

  toggleBoothSelection(boothItem: ShowBooth){
    boothItem.isBoothSelected = !boothItem.isBoothSelected;
    if(boothItem.isBoothSelected){
      this.selectedBooths.push(boothItem);
      this.selectedBooths = _.uniqBy(this.selectedBooths, function(selectedBoothItem) {
        return selectedBoothItem.boothGUID;
      })
    } else{
      _.remove(this.selectedBooths, function(selectedBoothItem) {
        return selectedBoothItem.boothGUID == boothItem.boothGUID;
      });
    }
    this.updateAllSectionStatus();
  }
  
  getEvenOddBoothList() { 
    switch (this.selectedBoothSegment) {
      case "2":
        {
          this.searchFilteredData = _.filter(this.searchFilteredData, function (booths) { return booths.boothName.replace(/\D/g,'') ? booths.boothName.replace(/\D/g,'') % 2 == 0 : false; });
          if(this.isRangeApplied){
            this.setSelectedBooths();
            this.toggleAllBoothSelection();
          }
          break;
        }
      case "3":
        {
          this.searchFilteredData = _.filter(this.searchFilteredData, function (booths) { return booths.boothName.replace(/\D/g,'') ? booths.boothName.replace(/\D/g,'') % 2 != 0 : true; });
          if(this.isRangeApplied){
            this.setSelectedBooths();
            this.toggleAllBoothSelection();
          }
          break;
        }
      case "4":
        {
          this.searchFilteredData = [...this.searchFilteredData];
          break;
        }
      
      case "1":
      default:
        {
          if(this.isRangeApplied){
            this.setSelectedBooths();
            this.toggleAllBoothSelection();
          }
        }
    }
  }

  updateAllSectionStatus(){
    this.isAllBoothSelected = _.find(this.searchFilteredData, (boothItem) => { return !boothItem.isBoothSelected; }) ? false : true ;
  }

  async saveAreaBooths(){
    let loading = await this.loadingCtrl.create({
      message: 'Saving area data... '
    });
    await loading.present();
    this.showSetupService.submitAreaBooths(this.eventGUID, {
      areaGuid: this.isNewArea || this.isAreaCopy ? this.currentAreaGUID : this.areaItem.areaGuid,
      isInsert: this.isNewArea || this.isAreaCopy ? true : false,
      boothGuids: this.selectedBooths.map(booth => booth.boothGUID),
      isNew: (this.isNewArea || this.isAreaCopy) ? true : false
    }).subscribe((boothSaveResponse) => {
      loading.dismiss();
      if(boothSaveResponse){
        this.modalCtrl.dismiss({
          'isAreaSaved': true
        });
      }
    });
  }

  onRangeInputChange(){
    this.isRangeCloseVisible = false;
  }

  getSortedDataList(boothData) {
    return boothData.sort((a, b) => {
      if (!a.boothName || !b.boothName) {
        return;
      }
      return a.boothName.toLowerCase().localeCompare(b.boothName.toLowerCase(), 'en', { numeric: true });
    });
  }
}
