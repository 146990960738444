import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingController, ModalController, NavParams } from '@ionic/angular';
import { takeWhile } from 'rxjs/operators';
import { BoothDTO } from 'src/app/models/booth-dto';
import { LineItemService } from 'src/app/services/line-item/line-item.service';

@Component({
  selector: 'occ-booth-selection-modal',
  templateUrl: './booth-selection-modal.component.html',
  styleUrls: ['./booth-selection-modal.component.scss'],
})

export class BoothSelectionModalComponent implements OnInit, OnDestroy {

	booths: BoothDTO[];
	subscribe = true;
	selectedBoothId: string;
	callback: Function;
	isLoading: boolean;
	loading: any;

	constructor(
		public lineItemSvc: LineItemService,
		public modalCtrl: ModalController,
		public navParams: NavParams,
		public loadingCtrl: LoadingController
	) {
		
	}
	async ngOnInit(): Promise<void> {
		this.isLoading = true;
		this.loading = await this.loadingCtrl.create({
			message: 'Fetching booths...'
		});
		await this.loading.present();

		this.callback = this.navParams.data.cb;
		this.lineItemSvc.boothDto.pipe(takeWhile(() => this.subscribe)).subscribe(boothDto => {
			this.booths = boothDto;
			this.loading.dismiss().then(() => {
				this.isLoading = false;
			});
		});
		this.selectedBoothId = this.navParams.data.selectedBoothId;
	}

	selectBooth() {
		if (this.callback) {
			if (this.selectedBoothId) {
				this.callback(this.selectedBoothId);
			} else {
				this.callback('None');
			}
		}
		this.modalCtrl.dismiss();
	}

	cancelModal() {
		this.modalCtrl.dismiss();
	}

	ngOnDestroy() {
		this.subscribe = false;
	}
}

