import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { OpsTimelineDTO } from 'src/app/models/opsTimeline-dto';
import { Status } from 'src/app/models/status';
import { TimelineDTO } from 'src/app/models/timeline-dto';

@Component({
  selector: 'occ-timeline-status-change',
  templateUrl: './timeline-status-change.component.html',
  styleUrls: ['./timeline-status-change.component.scss'],
})
export class TimelineStatusChangeComponent implements OnInit {
  @Input() item: TimelineDTO;
	@Input() opsItem: OpsTimelineDTO;

	date: string;

	ngOnInit() {
		this.date = this.item ? moment(this.item.createdDate).utc().fromNow() : moment(this.opsItem.createdDate).utc().fromNow();
	}

  getSenderTextColor(status: Status){
    if(status.id != 1 && status.id != 2){
      return "white";
    }
    return 
  }
}
