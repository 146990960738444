import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Storage } from '@ionic/storage';
import { AuthTokenHelper } from 'src/app/helpers/auth-token-helper';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';

@Component({
  selector: 'occ-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  public eventId: string = undefined;
  public accountId: string = undefined
  public boothId: string = undefined;
  public canGo: boolean = false;
  public underDevelop: boolean = false;
  public page: string = 'Checklist';
  public params: any = {};
  constructor(
    private activatedRoute: ActivatedRoute,
    public authTokenHelper: AuthTokenHelper,
    public router: Router,
    public dataSvc: DataRefreshService,
    private storage: Storage
  ) {
    this.setActivePage((this.router.url).split('/').reverse()?.[0]);
   
  }

  async ngOnInit() {
    this.disableMenu(await this.storage.get('navigation_param'),await this.storage.get('navigation_pagename'));
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof ActivationEnd) {
        this.params = event.snapshot.params;
        let url = event.snapshot.url[0]?.path;
        if(url && url!="more-options" && url!="notification-preferences"){
          this.storage.set('navigation_param', this.params);
          this.storage.set('navigation_pagename', url);
          this.disableMenu(this.params, url);
        }
        this.setActivePage((this.router.url).split('/').reverse()?.[0]);
      }
    });
  }

  async navigateToPage(pageName){
    this.params = await this.storage.get('navigation_param');
    this.eventId = this.params.eventId != undefined ? this.params.eventId : this.eventId;
    this.accountId = this.params.accountId != undefined ? this.params.accountId : this.accountId;
    this.boothId = this.params.boothId != undefined ? this.params.boothId : this.boothId;
    switch (pageName) {
      case "Checklist":
        if (await this.authTokenHelper.isExhibitUser && this.eventId){ 
          this.router.navigate(['events', this.eventId , 'es-booths']);
        }
        else if (await this.authTokenHelper.isOpsUser && this.eventId){ 
          this.router.navigate(['events', this.eventId , 'ops-user-line-item-list']);
        }
        else if(this.eventId && this.accountId) {
          this.router.navigate(['events', this.eventId , 'accounts', this.accountId, 'functional-areas']);
        }
        else if(this.eventId && this.boothId) {
          this.router.navigate(['events', this.eventId, 'booths', this.boothId, 'booth-details']);
        }
        else {
          this.router.navigate(['events']);
        }
        this.page = "Checklist";
        break;
      case "Dashboard":
        if (await this.authTokenHelper.isOpsUser){
          this.router.navigate(['events', this.eventId, 'workticket-dashboard']);
        }
        else {
          if(this.boothId){ }
          else {
            this.router.navigate(['events', this.eventId, 'accounts', this.accountId, 'dashboard']);
           }
         }
        break;
      case "Change Log":
        if(this.boothId){
          this.router.navigate(['events', this.eventId, 'booths', this.boothId, 'changeorders']);
         }
        else {
          this.router.navigate(['events', this.eventId, 'accounts', this.accountId, 'changeorders']);
        }
        break;
      case "Activity":
        if (await this.authTokenHelper.isOpsUser){
          this.router.navigate(['events', this.eventId, 'work-ticket-timeline']);
        } else {
          if(this.boothId){
            this.router.navigate(['events', this.eventId, 'booths', this.boothId, 'booth-timeline']);
          }
          else {
            this.router.navigate(['events', this.eventId, 'accounts', this.accountId, 'timeline']);
          }
        }       
        break;
      case "Search":
        if (await this.authTokenHelper.isOpsUser){
          this.router.navigate(['events', this.eventId, 'workticket-search']);
        } else {
          if(this.boothId){
            this.router.navigate(['events', this.eventId, 'booth', this.boothId, 'booth-search']);
          }else{
            this.router.navigate(['events', this.eventId, 'accounts', this.accountId, 'search']);
          }
        }   
        
        break;
      case "More":
        this.router.navigate(['more-options']);
        this.page = "more";
        break;
    
      default:
        break;
    }
    this.activatePage();
  }


  async activatePage() {
    let routerPage = "";
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        routerPage = (e.url).split('/').reverse()?.[0];
        if(routerPage == "")
        {
          routerPage =  (this.router.url).split("/").reverse()?.[0];
        }
        this.setActivePage(routerPage);
      }
    });
  }

  async disableMenu(param, url) {
    if(url == 'events') {
      this.eventId = undefined;
      this.accountId = undefined;
      this.boothId = undefined;
    }
    if (this.authTokenHelper.isOpsUser && param.eventId) {
      this.underDevelop = false;
      return this.canGo = true;
    }
    else if (this.authTokenHelper.isExhibitUser && param.eventId) {
      this.underDevelop = true;
      return this.canGo = false;
    }
    else {
      if ((param.eventId != undefined && param.accountId != undefined) ||
          (param.eventId != undefined && param.boothId != undefined) ||
          (this.eventId != undefined && this.boothId != undefined) ||
          (this.eventId != undefined && this.accountId != undefined)) {
            if(param.boothId != undefined || this.boothId != undefined) {
              this.underDevelop = true;
            }
            else { this.underDevelop = false;}
          return this.canGo = true;
      }
      else {
        return this.canGo = false;
      }  
    }
  }

  setActivePage(routerPage) {
    switch (routerPage) {
      case 'events':
        this.page = 'Checklist';
        break;
      case 'dashboard':
        this.page = 'Dashboard';
        break;
      case 'changeorders':
        this.page = 'Change Log';
        break;
      case 'timeline':
        this.page = 'Activity';
        break;
      case 'search':
        this.page = 'Search';
        break;
      case 'more-options':
        this.page = 'More';
        break;
      case 'functional-areas':
        this.page = 'Checklist';
        break;
      case 'workticket-dashboard':
        this.page = 'Dashboard';
        break;
      case 'work-ticket-timeline':
        this.page = 'Activity';
        break;
      case 'workticket-search':
        this.page = 'Search';
        break;
      case 'booth-timeline':
        this.page = 'Activity';
        break;
      case 'booth-search':
        this.page = 'Search';
        break;
      case 'change-log-details':
        this.page = 'Change Log';
      break;
      case 'setup':
      case 'area':
      case 'freight':
        this.page = 'More';
        break;
      default:
        this.page = 'Checklist';
        break;
    }
  }
}
