import { LineItemService } from './../services/line-item/line-item.service'
import { FunctionalAreaService } from './../services/functional-area/functional-area.service';
import { Injectable } from '@angular/core';
import { EventService } from '../services/event/event.service';
import { ShowAccountService } from '../services/show-account/show-account.service';
import { WorkTicketService } from '../services/work-ticket/work-ticket.service';

@Injectable()

export class NavParamHelper {

	constructor(
		public eventSvc: EventService,
		public acctSvc: ShowAccountService,
		public faSvc: FunctionalAreaService,
		public liSvc: LineItemService,
		public workTicketService: WorkTicketService,
	) { }

	syncActiveInfo(eventId: string, accountId?: string, faId?: string, wtId?: string) {
		if (accountId) {
			if (this.acctSvc.activeShowAccountId !== accountId) {
				this.acctSvc.activeShowAccountId = accountId;
			}
		}
		if (faId) {
			if (this.faSvc.activeFunctionalAreaId.getValue() !== faId) {
				this.faSvc.activeFunctionalAreaId.next(faId);
			}
		}
		if (wtId) {
			if (this.workTicketService.activeWorkTicketIdSubject.getValue() !== wtId) {
				this.workTicketService.activeWorkTicketIdSubject.next(wtId);
			}
		}
		if (!accountId && !faId && !wtId) {
			if (this.eventSvc.eventIdSubject.getValue() !== eventId) {
				this.eventSvc.eventIdSubject.next(eventId);
			}
		}
	}

	public getLastViewFromUrl(): string {
		const url = window.location.href;
		const splitUrl = url.split('/');
		const lastSection = splitUrl[splitUrl.length - 1];
		if (lastSection !== '') {
			return lastSection;
		} else {
			return splitUrl[splitUrl.length - 2];
		}
	}

	public getNavParamsFromUrl() {
		const url = window.location.href;
		const splitUrl = url.split('/');

		const availableNavParams = {
			eventId: '',
			accountId: '',
			functionalAreaId: '',
			lineItemId: '',
			workTicketId: ''
		};

		for (let i = 1; i < splitUrl.length; i++) {
			const section = splitUrl[i];
			const previousSection = splitUrl[i - 1];
			switch (previousSection) {
				case 'events':
					availableNavParams.eventId = section;
					break;

				case 'accounts':
					availableNavParams.accountId = section;
					break;

				case 'functional-areas':
					availableNavParams.functionalAreaId = section;
					break;

				case 'line-items':
					availableNavParams.lineItemId = section;
					break;

				case 'worktickets':
					availableNavParams.workTicketId = section;
					break;
			}
		}
		return availableNavParams;
	}

}
