import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SMSCode } from 'src/app/helpers/sms-helper';
import { KioskService } from 'src/app/services/kiosk/kiosk.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'kiosk-code-verification',
  templateUrl: './code-verification.component.html',
  styleUrls: ['./code-verification.component.scss'],
})
export class CodeVerificationComponent implements OnInit {

  @Output() verficationResponse = new EventEmitter<any>();
  @Input() mobileInfo: any;
  verficationCode: string;
  isInvalidCode: boolean = false;
  tosAgree: boolean = false;
  allCountryCodes = [{
    display: "+1",
    value: 1,
    regExData: [/[0-9]/g, /[0-9]/g, /[0-9]/g, /[-]/g, /[0-9]/g, /[0-9]/g, /[0-9]/g, /[-]/g, /[0-9]/g, /[0-9]/g, /[0-9]/g, /[0-9]/g]
  }];
  defaultCountryCode = {
    display: "+1",
    value: 1,
    regExData: [/[0-9]/g, /[0-9]/g, /[0-9]/g, /[-]/g, /[0-9]/g, /[0-9]/g, /[0-9]/g, /[-]/g, /[0-9]/g, /[0-9]/g, /[0-9]/g, /[0-9]/g]
  };
  isShowLoader: boolean;
  sendBtnText: string = "Send Code";
  constructor(public kioskService: KioskService,
    public toastService: ToastService,
    private storage: Storage,
    private platform: Platform
    ) { }

  ngOnInit() {}

  verifyCode() {
    this.isInvalidCode = false;
    this.mobileInfo.Code = this.verficationCode;
    this.kioskService.verifyCode(this.mobileInfo).subscribe((smsVerificationResponsedata) => {
      if(smsVerificationResponsedata != null){
        smsVerificationResponsedata['mobileInfo'] = this.mobileInfo;
        this.storage.set('kiosk-account-info', JSON.stringify(smsVerificationResponsedata));
        if(!smsVerificationResponsedata.codeVerified){
          this.isInvalidCode = true;
          console.log(this.isInvalidCode);
        }
        else {
          this.verficationResponse.emit({
            smsVerificationResponsedata: this.mobileInfo,
            response: smsVerificationResponsedata
          });
        }
      }
    });
  }

  setCountryCodeSelection(currentCountryCode: any){

  }

  sendAuthorizationCode() {
    this.isInvalidCode = false;
    this.isShowLoader = true;
    this.sendBtnText = "Sending code...";
    this.kioskService.getSendCode(this.mobileInfo).subscribe((sendCodeResponse: any) => {
      switch (sendCodeResponse) {
        case SMSCode.SMSSent:

          break;

        case SMSCode.MobileNotExist:
          break;

        case SMSCode.SMSNotSent:
          this.toastService.open('Error: SMS not sent .', 'danger');
          break;

        default:
          break;
      }
      this.isShowLoader = false;
      this.sendBtnText = "Send Code";
    }, (error: any) => {
      this.toastService.open('Error occurred during mobile number verification.', 'danger');
      this.isShowLoader = false;
      this.sendBtnText = "Send Code";
    });
  }

  onPhoneNumberChange(numberChangeInfo){
    this.mobileInfo.PhoneNo = numberChangeInfo.phoneNumber.replace(/-/g, "");
  }

  checkCodeValidity(){
    this.isInvalidCode = (this.verficationCode && this.verficationCode.replace(/[^0-9]/g,'').length != this.verficationCode.length)  ? true : false;   
  }

  isDesktopView() {
    return this.platform.width() > 767 ? true : false;
  }
}
