import { Injectable } from '@angular/core';
import { StatusHelper } from '../../helpers/status-helper';
import { Status } from '../../models/status';
import { HttpClient } from '@angular/common/http';
import { StatusChangeDownloadDto } from '../../models/status-change-download-dto';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CollaborationLineItemDTO } from '../../models/collaboration-line-item-dto';
import { ToastService } from '../toast/toast.service';
import { ActivityService } from '../activity/activity.service';
import { LineItemStatusChangeUploadDto } from '../../models/line-item-status-change-upload-dto';
import { LITabsEnum } from '../../helpers/tabs-enum';
import { LoadingController } from '@ionic/angular';
import * as moment from 'moment';
import { UserService } from '../user/user.service';
import { ToastType } from '../../models/toast-type';
import { WorkTicketService } from '../work-ticket/work-ticket.service';
import { WorkTicketDTO } from '../../models/work-ticket-dto';
import { LineItemService } from '../line-item/line-item.service';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { GenericResponse } from 'src/app/models/generic-response';

@Injectable({
  providedIn: 'root'
})
export class WorkticketLineItemService {
  url = environment.apiUrl;
  collaborationLineItemDto = new BehaviorSubject<CollaborationLineItemDTO[]>([]);
  allLineItemsSubject = new BehaviorSubject<CollaborationLineItemDTO[]>([]);
  currentLineItem = new BehaviorSubject<CollaborationLineItemDTO>(null);
  vendorListSubject = new BehaviorSubject<string[]>([]);
  boothListSubject = new BehaviorSubject<string[]>([]);
  locationListSubject = new BehaviorSubject<string[]>([]);
  categoryListSubject = new BehaviorSubject<string[]>([]);
  public currentLineItemDto: CollaborationLineItemDTO[];
  public currentLineItemValue: CollaborationLineItemDTO;
  public currentTab: LITabsEnum;
  public searchRequestComplete: boolean;
  public isOpsFilterClearSubject = new BehaviorSubject<boolean>(false);
  public isBulkUpdateStatusSubject = new BehaviorSubject<boolean>(false);
  constructor(
    public http: HttpClient,
    public toastService: ToastService,
    public activitySvc: ActivityService,
    public loadCtrl: LoadingController,
    public userSvc: UserService,
    public workTikService: WorkTicketService,
    public lineItemSvc: LineItemService,
    private ga: GoogleAnalytics,
  ) {
    this.collaborationLineItemDto.subscribe(dto => {
      this.currentLineItemDto = dto;
    });
    this.currentLineItem.subscribe(li => {
      this.currentLineItemValue = li;
    });
  }

  get(showId: string, workTicketGuid: string, lineItemId: string, replaceStatusOfLineItem?: boolean) {
    this.refresh(showId, workTicketGuid, lineItemId, replaceStatusOfLineItem).subscribe();
  }

  searchLineItems(showId: string, accountType?: number): Observable<CollaborationLineItemDTO[]> {
    const accType = accountType == undefined ? 0 : accountType;
    this.searchRequestComplete = false;
    return this.http.get<CollaborationLineItemDTO[]>(`${this.url()}events/${showId}/work-tickets/showaccounttype/${accType}/all-line-items/`)
      .pipe(map(data => {
        data.forEach(f => f.status = StatusHelper.GetStatusFromId(f.currentStatus));
        const sortedLineItems = this.sortLineItems(data);
        this.populateFilterLists(sortedLineItems);
        this.collaborationLineItemDto.next(sortedLineItems);
        this.searchRequestComplete = true;
        return sortedLineItems;
      }, err => {
        if (err.status !== 401 && err.status !== 403) {
          this.toastService.open('There was an error while searching workticket line item data', 'danger');
        }
      }));
  }

  setCurrentLineItem(lineItemId: string): void {
    const lineItem = this.currentLineItemDto.find(li => li.kafkaId === lineItemId);
    this.currentLineItem.next(lineItem);
  }

  // refresh(showId: string, workTicketNumber: number, replaceStatusOfLineItem = true, errorCallback?: Function): Observable<boolean> {
  // 	return this.http.get<Array<CollaborationLineItemDTO>>(`${this.url()}events/${showId}/work-tickets`)
  refresh(showId: string, workTicketGuid: string, lineItemId?: string, replaceStatusOfLineItem = true, errorCallback?: Function): Observable<boolean> {
    return this.http.get<Array<CollaborationLineItemDTO>>(`${this.url()}events/${showId}/work-tickets/line-items?workTicketGuid=${workTicketGuid}`)
      .pipe(map((data) => {
        if (data.length > 0) {
          if (lineItemId) {
            const currentLineItem = data.find(li => li.kafkaId === lineItemId);
            this.currentLineItem.next(currentLineItem);
          }
          data.forEach(lineItem => lineItem.status = StatusHelper.GetStatusFromId(lineItem.currentStatus));
          const sortedLineItems = this.sortLineItems(data);
          this.collaborationLineItemDto.next(sortedLineItems);
          return true;
        }
      }, err => {
        if (err.status !== 401 && err.status !== 403) {
          this.toastService.open('There was an error refreshing the workticket line item data', 'error_outline');
        }
        if (err.status === 404) {
          this.toastService.open('Failed to refresh workticket line item data', 'danger');
        }
        if (errorCallback) errorCallback();
      }));
  }


  lookupLineItemById(lineItemId: string) {
    const lineItem = this.currentLineItemDto.find(li => li.kafkaId === lineItemId) ? this.currentLineItemDto.find(li => li.kafkaId === lineItemId) : this.lineItemSvc.currentLineItemDto.find(li => li.kafkaId === lineItemId);
    return lineItem;
  }


  put(showId: string, workTicketId: string, lineItemId: string, newStatus: Status, noteBody?: string, photoUri?: string, cb?: Function): void {
    const currentLineItem = this.currentLineItemValue;
    const currentLineItemList = this.currentLineItemDto.length > 0 ? this.currentLineItemDto : this.lineItemSvc.currentLineItemDto;

    const lineItem = this.lookupLineItemById(lineItemId);
    const usersToNotify = noteBody ? this.userSvc.verifyMentionedUsers(noteBody) : [];
    // To keep all the necessary values for the detail dto. Will be redundant after booth is sent with line item list.
    if (currentLineItem && currentLineItem.kafkaId === lineItemId) {
      lineItem.booth = currentLineItem.booth;
    }

    // make a copy of the original before changing the status to revert back to in case of error
    const originalLineItem: CollaborationLineItemDTO = JSON.parse(JSON.stringify(lineItem));
    const originalLineItemDto: CollaborationLineItemDTO[] = JSON.parse(JSON.stringify(this.collaborationLineItemDto.getValue()));

    const workTicketDetailDto = this.workTikService.allWorkTickets.find(wt => wt.workTicketGuid === workTicketId);
    const originalWorkTicketDto: WorkTicketDTO[] = JSON.parse(JSON.stringify(this.workTikService.allWorkTicketsSubject.getValue()));

    // const originalActivityItems = JSON.parse(JSON.stringify(this.activitySvc.lineItemActivityItems.getValue()));

    lineItem.status = newStatus;
    lineItem.statusDate = moment.utc(moment.now()).toDate();

    // NOTE: Keep this for when it's decided we want instant updates again
    // const date = new Date();
    // const liStatusChange: StatusChange = {
    // 	kafkaId: '',
    // 	functionalAreaGuid: '',
    // 	createdDate: date,
    // 	createdBy: 'saving...',
    // 	statusId: lineItem.status.id,
    // 	status: lineItem.status
    // };
    this.collaborationLineItemDto.next(currentLineItemList);
    this.allLineItemsSubject.next(currentLineItemList);
    this.currentLineItem.next(lineItem);

    StatusHelper.UpdateWorkTicketStatus(workTicketDetailDto, currentLineItemList, lineItem);
    this.workTikService.currentWorkTicketSubject.next(workTicketDetailDto);

    const uploadDto: LineItemStatusChangeUploadDto = {
      noteBody: noteBody,
      photoUri: photoUri,
      lineItemStatus: newStatus.id,
      usersToNotify: usersToNotify
    };

    this.http.put<StatusChangeDownloadDto>(`${this.url()}events/${showId}/work-tickets/${workTicketId}/line-items/${lineItem.kafkaId}`, uploadDto)
      .subscribe(data => {
        data.statusChange.status = StatusHelper.GetStatusFromId(data.statusChange.statusId);
        this.activitySvc.addItemToActivityItems(data.statusChange.createdDate, data.note, data.photo, data.statusChange, true);
        this.ga.trackEvent('LineItemStatusChange', data.statusChange.status.title);
        if (uploadDto.usersToNotify.length > 0) {
          this.ga.trackEvent('UserMentioned', 'UserMentioned');
        }
        this.trackStatusChangeTiming(originalLineItem, newStatus);
        // NOTE: Keep this for when it's decided we want instant updates again
        // const activityItems = this.activitySvc.lineItemActivityItems.getValue();
        // const index = activityItems.findIndex(item => item.createdDate === date);
        // this.activitySvc.addItemToActivityItems(data.statusChangeDto.createdDate, null, null, data.statusChangeDto, true, index);
      }, error => {
        switch (error.status) {
          case 400:
            this.toastService.open('400 error: Either IDs do not match, or model state is invalid.', 'danger');
            break;
          case 404:
            this.toastService.open(`No events with id: ${showId}`, 'danger');
            break;
          case 401:
          case 403: {
            // Unauthorized
            break;
          }
          default:
            this.toastService.open('Unable to update work ticket line item status.', 'danger');
            break;
        }

        if (lineItem.kafkaId === this.currentLineItem.getValue().kafkaId)
          this.currentLineItem.next(originalLineItem);

        this.collaborationLineItemDto.next(originalLineItemDto);
        this.workTikService.allWorkTicketsSubject.next(originalWorkTicketDto);
        this.workTikService.setReferenceToWorkTicketDTO();
      });
  }


  public sortLineItems(lineItems: CollaborationLineItemDTO[]): CollaborationLineItemDTO[] {
    // sort by vendor alphabetically
    lineItems.sort((a, b) => {
      if (a.vendorName < b.vendorName) {
        return -1;
      } else if (b.vendorName < a.vendorName) {
        return 1;
      }
      // if the vendor name is the same, sort by passport Id
      return a.passportId - b.passportId;
    });

    // then sort by lineItemId
    return lineItems;
  }

  public populateFilterLists(lineItems: CollaborationLineItemDTO[]): void {
    const vendors: string[] = [];
    const booths: string[] = [];
    const locations: string[] = [];
    const categories: string[] = [];
    lineItems.forEach(li => {
      if (li.vendorName && vendors.indexOf(li.vendorName) === -1)
        vendors.push(li.vendorName);
      if (li.booth && booths.indexOf(li.booth) === -1)
        booths.push(li.booth);
      if (li.locationInfo && locations.indexOf(li.locationInfo) === -1)
        locations.push(li.locationInfo);
      if (li.category && categories.indexOf(li.category) === -1)
        categories.push(li.category);
    });

    this.vendorListSubject.next(vendors);
    this.boothListSubject.next(booths);
    this.locationListSubject.next(locations);
    this.categoryListSubject.next(categories);
  }

  trackStatusChangeTiming(originalLI: CollaborationLineItemDTO, newStatus: Status) {
    if (originalLI.status.id === StatusHelper.Status.OnHold.id) {
      this.ga.trackEvent('LineItemChangedFromOnHoldStatus', moment(originalLI.statusDate).utc().fromNow(true), null, moment.utc().diff(moment.utc(originalLI.statusDate).valueOf()));
    }
  }

  //bulk Update Line Item status
  putBulkUpdate(showId: string, lineItems: CollaborationLineItemDTO[], newStatus: Status, cb?: Function): void {
    this.isBulkUpdateStatusSubject.next(false);
    let uploadDto: LineItemStatusChangeUploadDto[] = [];

    var lineItemIds = '';
    
    lineItems.forEach(li => {
      lineItemIds = lineItemIds + ',' + li.kafkaId;
    });
    lineItemIds = lineItemIds.substring(1);
    var dataToSend = { 'LineItemIds': lineItemIds, 'NewStatusId': newStatus.id };
    this.http.put<GenericResponse>(`${this.url()}events/${showId}/work-tickets/null/line-items/bulk-update-lineItem`, dataToSend)
      .subscribe(data => {
        this.isBulkUpdateStatusSubject.next(true);
        if (data.status === 'error') {
          this.toastService.open(data.message, 'danger');
        } else if (data.status === 'success') {
          this.toastService.open(data.message, 'success');
        }

      }, error => {
        this.isBulkUpdateStatusSubject.next(true);
        switch (error.status) {
          case 400:
            this.toastService.open('400 error: Either IDs do not match, or model state is invalid.', 'danger');
            break;
          case 404:
            this.toastService.open(`No events with id: ${showId}`, 'danger');
            break;
          case 401:
          case 403: {
            // Unauthorized
            break;
          }
          default:
            this.toastService.open('Unable to update work ticket line item status.', 'danger');
            break;
        }

      });
  }

  getServiceRequestList(showId) {
    let url = `${this.url()}events/${showId}/exhibitorService/issue-items`;
    return this.http.get<any[]>(url)
      .pipe(map(data => {
        data.map(item => {
          item['status'] = StatusHelper.GetServiceRequestStatusFromId(item.currentStatus);
          item['kafkaId'] = item?.issueItemGuid;
          item['booth'] = item?.boothNumber;
          item['description'] = item?.issueText;
          item['accountName'] = item?.requestor;
          item['setupDate'] = item?.submittedDate;
        })
        return data;
      }, err => {
        if (err.status !== 401 && err.status !== 403) {
          this.toastService.open('There was an error while fetching service requests', 'danger');
        }
      }));
  }

  // production setup Date time
  productionScheduleDateTime(eventGUID: any,productionDateTime: any) {
    return this.http.post(`${this.url()}events/${eventGUID}/showsetup/productionSetups/save`,productionDateTime).pipe(map((productionScheduleResponse: any) => {
      if (productionScheduleResponse && productionScheduleResponse.status == "success") {
        this.toastService.open(productionScheduleResponse.message, 'success');
        return productionScheduleResponse;
      } else {
        this.toastService.open('There was an error while saving production date time', 'danger');
        return false;
      }
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error while saving production date time', 'danger');
      }
      return false;
    }));
  }

  //add note in bulk update
  addNoteBulkUpdate(eventGUID:any, accountId: string, functionalAreaId: string, addNote:any) {
    accountId = accountId ? accountId : "00000000-0000-0000-0000-000000000000"; 
		functionalAreaId = functionalAreaId ? functionalAreaId : "00000000-0000-0000-0000-000000000000";
    return this.http.post(`${this.url()}events/${eventGUID}/accounts/${accountId}/functional-areas/${functionalAreaId}/bulk-update-lineItem-note`,addNote).pipe(map((addNoteResponse: any) => {
      if (addNoteResponse && addNoteResponse.status == "success") {
        this.toastService.open(addNoteResponse.message, 'success');
        return addNoteResponse;
      } else {
        this.toastService.open('There was an error while saving notes', 'danger');
        return false;
      }
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error while saving notes', 'danger');
      }
      return false;
    }))
   
  }
}
