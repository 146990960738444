import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FunctionalArea } from '../../models/functional-area';
import { DomSanitizer } from '@angular/platform-browser';
import { ActionSheetController, ModalController, Platform, IonContent, NavParams, AlertController } from '@ionic/angular';
import { ActivityService } from '../../services/activity/activity.service';
import { FunctionalAreaService } from '../../services/functional-area/functional-area.service';
import { AppBrowserHelper } from './../../helpers/app-browser-helper';
import { Status } from '../../models/status';
import { ToastService } from '../../services/toast/toast.service';
import { CollaborationLineItemDTO } from '../../models/collaboration-line-item-dto';
import { LineItemService } from '../../services/line-item/line-item.service';
import { CameraHelper } from './../../helpers/camera-helper';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { DataRefreshService } from '../../services/data-refresh/data-refresh.service';
import { UserService } from '../../services/user/user.service';
import { WorkTicketDTO } from '../../models/work-ticket-dto';
import { WorkTicketService } from '../../services/work-ticket/work-ticket.service';
import { WorkticketLineItemService } from '../../services/workticket-line-item/workticket-line-item.service';
import { CameraLinkToPhotoVerificationHelper } from '../../helpers/camera-intermediary';
import { ShowBooth } from '../../models/show-booth-dto';
import { BoothServiceProvider } from '../../services/booth/booth.service';
import { takeWhile } from 'rxjs/operators';
import { LineItemSelectionComponent } from '../line-item-selection/line-item-selection.component';
import { MentionConfig } from 'angular-mentions';
import { EventService } from 'src/app/services/event/event.service';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';

@Component({
	selector: 'app-needs-attention',
	templateUrl: './needs-attention.component.html',
	styleUrls: ['./needs-attention.component.scss'],
})
export class NeedsAttentionComponent implements OnInit {

	@ViewChild('content') content: IonContent;
	@ViewChild('addNote', { read: ElementRef }) addNote: ElementRef;

	@Input() newStatus?: Status;
	@Input() bulkUpdateTickets?: WorkTicketDTO[];
	@Input() lineItem?: CollaborationLineItemDTO;
	@Input() workTicket?: WorkTicketDTO;
	@Input() functionalArea?: FunctionalArea;
	@Input() workTicketLineItem?: CollaborationLineItemDTO;
	@Input() boothData?: ShowBooth;
	@Input() boothLineItem?: CollaborationLineItemDTO;
	@Input() boothSRItem?: any;
	@Input() fromSearch?: boolean;
	@Input() isServiceRequest?: boolean;

	currentLineItem: string;
	lineItemDto: CollaborationLineItemDTO[];

	lineItemSelectionModal: any;
	modalTitle: string;
	selectedLineItem: CollaborationLineItemDTO;
	selectedLineItemId: string;
	cb: Function;
	keyboardIsOpen: boolean;
	noneSelected: boolean;
	mentionConfig: MentionConfig;
	opsMentionConfig: MentionConfig;

	private subscribe = true;
	public eventId: string;
	public accountId: string;
	public functionalAreaId: string;
	public placeholderText: string;

	constructor(
		public funcSvc: FunctionalAreaService,
		public appBrowserHelper: AppBrowserHelper,
		public activitySvc: ActivityService,
		public toastService: ToastService,
		public lineItemService: LineItemService,
		public cameraHelper: CameraHelper,
		public cameraIntermediary: CameraLinkToPhotoVerificationHelper,
		public modalCtrl: ModalController,
		public actionSheetCtrl: ActionSheetController,
		public domSanitizer: DomSanitizer,
		public keyboard: Keyboard,
		public dataSvc: DataRefreshService,
		public platform: Platform,
		public userSvc: UserService,
		public workTicketSvc: WorkTicketService,
		public workTicketLineItemSvc: WorkticketLineItemService,
		public boothService: BoothServiceProvider,
		public eventSvc: EventService,
		public navParams: NavParams,
		private alertCtrl: AlertController,
		private ga: GoogleAnalytics
	) { 
		this.functionalArea = this.navParams.data.functionalArea || this.funcSvc.currentFunctionalArea;
		this.lineItem = this.navParams.data.lineItem;
		this.workTicket = this.navParams.data.workTicket;
		this.workTicketLineItem = this.navParams.data.workTicketLineItem;
		this.newStatus = this.navParams.data.newStatus;
		this.fromSearch = this.navParams.data.fromSearch;
		this.eventId = this.dataSvc.eventActive;
		this.accountId = this.dataSvc.showAccountActive;
		this.boothData = this.navParams.data.boothData;
		this.boothLineItem = this.navParams.data.boothLineItem;
		this.eventId = this.eventSvc.eventId;
		this.accountId = this.dataSvc.showAccountActive;
		if (this.boothData) {
			this.modalTitle = this.boothLineItem ? this.boothLineItem.displayName : this.boothData.boothName;
		} else {
			this.modalTitle = this.lineItem ? this.lineItem.displayName + ' (' + this.lineItem.passportId + ')'
				: this.functionalArea ? this.functionalArea.name
				: this.workTicketLineItem ? this.workTicketLineItem.displayName + '(' + this.workTicketLineItem.passportId + ')'
				: this.workTicket ? 'Work Ticket #: ' + this.workTicket.tssWorkTicketNumber : '';
						
		}

		// this.keyboard.hideKeyboardAccessoryBar(false);
		this.keyboard.disableScroll(true);
		//this.cb = this.navParams.data.cb;
	}

	ionViewWillEnter() {
		if (this.workTicket) {
			this.workTicketSvc.get(this.eventId);
			this.workTicketSvc.getWorkTicketLineItem(this.eventId, this.workTicket.workTicketGuid);
		} else if (this.functionalArea) {
			if (!this.fromSearch) {
				this.lineItemService.get(this.dataSvc.eventActive, this.dataSvc.showAccountActive, this.functionalArea.functionalAreaGuid);
			}
			this.funcSvc.get(this.dataSvc.eventActive, this.dataSvc.showAccountActive);
		}
	}


	ngOnDestroy() {
		if (this.lineItemSelectionModal) {
			this.lineItemSelectionModal.dismiss();
		}
		this.cameraIntermediary.queuedImages = [];
		this.subscribe = false;
	}

	ngOnInit() {
		if (this.workTicket || this.workTicketLineItem) {
			this.workTicketSvc.workTicketLineItemDTO.pipe(takeWhile(() => this.subscribe)).subscribe(cliDto => {
				this.lineItemDto = cliDto;
				this.placeholderText = this.lineItemDto.length === 0 ? 'None' : 'Select';
			});
		} else if (!this.boothData) {
			this.lineItemService.collaborationLineItemDto.pipe(takeWhile(() => this.subscribe)).subscribe(cliDto => {
				this.lineItemDto = cliDto;
				this.placeholderText = this.lineItemDto.length === 0 ? 'None' : 'Select';
			});
		}
		this.userSvc.mentionsConfig.pipe(takeWhile(() => this.subscribe)).subscribe(mentionConfig => {
			this.mentionConfig = mentionConfig;
		});
		this.userSvc.opsMentionsConfig.pipe(takeWhile(() => this.subscribe)).subscribe(mentionConfig => {
			this.opsMentionConfig = mentionConfig;
		});
	}

	setKeyboardOpenStatus(isOpen: boolean) {
		this.keyboardIsOpen = isOpen;
		// const scrollToHeight = this.platform.is('ios') ? 160 : 195;
		if (isOpen && this.cameraIntermediary.queuedImages.length) {
			// auto scroll to text input (height of the image + height of the toolbar under the image)
			this.content.scrollToPoint(0, 222);
		}
	}

	dismiss(isCancelButton? : boolean) {
		this.keyboard.disableScroll(false);
		if(isCancelButton && this.isServiceRequest){
			this.svcCancelAlert();
		} else{
			this.modalCtrl.dismiss();
		}
		// to set the modalOpen property and replace the footer.
		if (this.cb) {
			this.cb();
		}
	}

	async presentLineItemSelectionModal() {
		if (this.lineItemDto.length) {
			const options = {
				cssClass: 'line-item-selection-modal'
			};
			let selectedId = null;
			if (this.selectedLineItem) {
				selectedId = this.selectedLineItem.kafkaId;
			}
			this.lineItemSelectionModal = await this.modalCtrl.create({
			  component: LineItemSelectionComponent,
			  showBackdrop: true,
			  componentProps: { cb: this.setSelectedLineItem.bind(this), selectedLineItemId: selectedId, workTicket: this.workTicket }
			});
			await this.lineItemSelectionModal.present();
		}
	}

	setSelectedLineItem(lineItemId: string) {
		if (lineItemId === 'None') {
			this.noneSelected = true;
		}
		this.selectedLineItem = this.lineItemDto.find(li => li.kafkaId === lineItemId);
	}

	async presentCameraOptionsModal() {
		const lineItemGuid = this.lineItem ? this.lineItem.kafkaId : null;
		const functionalAreaGuid = this.functionalArea ? this.functionalArea.functionalAreaGuid : this.workTicketLineItem ? this.workTicketLineItem.functionalAreaGuid : null;

		const actionSheet = await this.actionSheetCtrl.create({
			header: 'Add a Photo',
			buttons: [
				{
					text: 'Take a Photo',
					handler: () => {
						if (this.workTicket) {
							this.cameraHelper.takePicture(this.eventId, '', '', lineItemGuid, true, false, true);
						} else if (this.boothData) {
							this.cameraHelper.takePicture(this.eventId, this.accountId, functionalAreaGuid, lineItemGuid, true, false, true, this.boothData.boothGUID, this.boothLineItem);
						} else {
							this.cameraHelper.takePicture(this.eventId, this.accountId, functionalAreaGuid, lineItemGuid, true, false, true);
						}
					}
				},
				{
					text: 'Upload From Gallery',
					handler: () => {
						if (this.workTicket) {
							this.cameraHelper.takePicture(this.eventId, '', '', lineItemGuid, true, true, true);
						} else if (this.boothData) {
							this.cameraHelper.takePicture(this.eventId, this.accountId, functionalAreaGuid, lineItemGuid, true, true, true, this.boothData.boothGUID, this.boothLineItem);
						} else {
							this.cameraHelper.takePicture(this.eventId, this.accountId, functionalAreaGuid, lineItemGuid, true, true, true);
						}
					}
				},
				{
					text: 'Cancel',
					role: 'cancel',
				}
			]
		});
		await actionSheet.present();
	}

	confirm(noteBody: string) {
		// the image is the raw image property of this class which is subscribed to the queued image property of the camera helper.
		if (this.lineItem) { // line item status change
			this.uploadLineItem(noteBody);
		} else if (this.functionalArea) { // functional area status change
			this.uploadFunctionalArea(noteBody);
		} else if (this.workTicketLineItem) {
			this.uploadWorkTicketLineItem(noteBody);
		} else if (this.workTicket) {
			this.uploadWorkTicket(noteBody);
		} else if (this.bulkUpdateTickets && this.bulkUpdateTickets.length) {
			this.updateWtBulk(noteBody);
		} else if (this.boothData) {
			this.uploadBooth(noteBody);
		} else if (this.isServiceRequest) {
			this.uploadServiceRequest(noteBody);
		}
		this.dismiss();
	}

	uploadLineItem(noteBody: string) {
		if (this.cameraIntermediary.queuedImages.length) {
			this.cameraIntermediary.queuedImages.forEach(photo => {
				this.lineItemService.put(this.dataSvc.eventActive, this.dataSvc.showAccountActive, this.lineItem.functionalAreaGuid, this.lineItem.kafkaId, this.newStatus, noteBody, photo);
			});
		} else {
			this.lineItemService.put(this.dataSvc.eventActive, this.dataSvc.showAccountActive, this.lineItem.functionalAreaGuid, this.lineItem.kafkaId, this.newStatus, noteBody);
		}
	}

	uploadFunctionalArea(noteBody: string) {
		if (this.selectedLineItemId) {
			this.selectedLineItem = this.lineItemService.collaborationLineItemDto.getValue().find(li => li.kafkaId === this.selectedLineItemId);
		}
		if (!this.selectedLineItem) { // no line item is selected
			if (this.cameraIntermediary.queuedImages.length) {
				this.cameraIntermediary.queuedImages.forEach(photo => {
					this.funcSvc.put(this.dataSvc.eventActive, this.dataSvc.showAccountActive, this.functionalArea.functionalAreaGuid, this.newStatus, this.lineItemService.collaborationLineItemDto, [], noteBody, photo);
				});
			} else {
				this.funcSvc.put(this.dataSvc.eventActive, this.dataSvc.showAccountActive, this.functionalArea.functionalAreaGuid, this.newStatus, this.lineItemService.collaborationLineItemDto, [], noteBody);
			}
			this.dismiss();
			return;
		}
		const oldLineItem = this.lineItemService.collaborationLineItemDto.getValue().find(li => li.kafkaId === this.selectedLineItem.kafkaId);
		if (this.cameraIntermediary.queuedImages.length) {
			this.cameraIntermediary.queuedImages.forEach(photo => {
				this.funcSvc.put(this.dataSvc.eventActive, this.dataSvc.showAccountActive, this.functionalArea.functionalAreaGuid, this.newStatus, this.lineItemService.collaborationLineItemDto, [oldLineItem], noteBody, photo);
			});
		} else {
			this.funcSvc.put(this.dataSvc.eventActive, this.dataSvc.showAccountActive, this.functionalArea.functionalAreaGuid, this.newStatus, this.lineItemService.collaborationLineItemDto, [oldLineItem], noteBody);
		}
	}

	uploadWorkTicket(noteBody: string) {
		const lineItemSubject = this.fromSearch ? this.lineItemService.allLineItemsSubject : this.workTicketLineItemSvc.collaborationLineItemDto;
		if (this.selectedLineItemId) {
			this.selectedLineItem = this.workTicketSvc.workTicketLineItemDTO.getValue().find(li => li.kafkaId === this.selectedLineItemId);
		}
		if (this.selectedLineItem) {
			if (this.cameraIntermediary.queuedImages.length) {
				this.cameraIntermediary.queuedImages.forEach(photo => {
					this.workTicketSvc.put(this.dataSvc.eventActive, this.workTicket, this.newStatus, lineItemSubject, this.lineItemDto, noteBody, photo);
				});
			} else {
				this.workTicketSvc.put(this.dataSvc.eventActive, this.workTicket, this.newStatus, lineItemSubject, this.lineItemDto, noteBody);
			}
		} else {
			if (this.cameraIntermediary.queuedImages.length) {
				this.cameraIntermediary.queuedImages.forEach(photo => {
					this.workTicketSvc.put(this.dataSvc.eventActive, this.workTicket, this.newStatus, lineItemSubject, [], noteBody, photo);
				});
			} else {
				this.workTicketSvc.put(this.dataSvc.eventActive, this.workTicket, this.newStatus, lineItemSubject, [], noteBody);
			}
		}
	}

	updateWtBulk(noteBody) {
		const lineItemSubject = this.fromSearch ? this.lineItemService.allLineItemsSubject : this.workTicketLineItemSvc.collaborationLineItemDto;
		this.bulkUpdateTickets.forEach(wt => {
			if (this.cameraIntermediary.queuedImages.length) {
				this.cameraIntermediary.queuedImages.forEach(photo => {
					this.workTicketSvc.put(this.dataSvc.eventActive, wt, this.newStatus, lineItemSubject, this.lineItemDto, noteBody, photo);
				});
			} else {
				this.workTicketSvc.put(this.dataSvc.eventActive, wt, this.newStatus, lineItemSubject, this.lineItemDto, noteBody);
			}
		});
	}

	uploadWorkTicketLineItem(noteBody: string) {
		if (this.cameraIntermediary.queuedImages.length) {
			this.cameraIntermediary.queuedImages.forEach(photo => {
				this.workTicketLineItemSvc.put(this.dataSvc.eventActive, this.workTicketLineItem.workTicketGuid, this.workTicketLineItem.kafkaId, this.newStatus, noteBody, photo);
			});
		} else {
			this.workTicketLineItemSvc.put(this.dataSvc.eventActive, this.workTicketLineItem.workTicketGuid, this.workTicketLineItem.kafkaId, this.newStatus, noteBody);
		}
	}

	uploadBooth(noteBody: string) {
		if (this.cameraIntermediary.queuedImages.length) {
			this.cameraIntermediary.queuedImages.forEach(photo => {
				this.boothService.setBoothStatus(this.eventId, this.boothData.boothGUID, this.boothLineItem, this.newStatus.id, noteBody, photo);
			});
		} else {
			this.boothService.setBoothStatus(this.eventId, this.boothData.boothGUID, this.boothLineItem, this.newStatus.id, noteBody);
		}
	}

	uploadServiceRequest(noteBody: string) {
		if (this.cameraIntermediary.queuedImages.length) {
			this.cameraIntermediary.queuedImages.forEach(photo => {
				this.boothService.setServiceRequestStatus(this.eventId, this.boothSRItem, this.newStatus.id, noteBody, photo);
			});
		} else {
			this.boothService.setServiceRequestStatus(this.eventId, this.boothSRItem, this.newStatus.id, noteBody);
		}
		this.ga.trackEvent('ServiceRequestStatusChanged', this.newStatus?.title);
	}

	addAnotherPhoto(event) {
		this.cameraIntermediary.queueImages(event);
	}

	setMentionConfig() {
		if (this.workTicket) {
			return this.opsMentionConfig;
		} else {
			return this.mentionConfig;
		}
	}

	async svcCancelAlert(){
		const alert = await this.alertCtrl.create({
			cssClass: 'my-custom-class',
			header: 'Alert',
			message: 'If you cancel this, service request status will remain unchanged. Are you sure you want to cancel?',
			buttons: [
				{
					text: 'No',
					role: 'cancel',
					cssClass: 'secondary',
					handler: () => {}
				}, {
					text: 'Yes',
					handler: () => {
						this.modalCtrl.dismiss();
					}
				}
			]
		});
		await alert.present();
	}
}
