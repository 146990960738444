import { Component, Input, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import * as moment from 'moment';
import { OpsTimelineDTO } from 'src/app/models/opsTimeline-dto';
import { TimelineDTO } from 'src/app/models/timeline-dto';

@Component({
  selector: 'occ-timeline-photo-note',
  templateUrl: './timeline-photo-note.component.html',
  styleUrls: ['./timeline-photo-note.component.scss'],
})
export class TimelinePhotoNoteComponent implements OnInit {
  @Input() item: TimelineDTO;
	@Input() opsItem: OpsTimelineDTO;
	date: string;
	azureUri: string;
	azureToken: string;
	thumbnailError = false;
	postTypeString: string;

	constructor(private storage: Storage) {
		this.storage.get('azure_uri').then((val) => {
			this.azureUri = val;
		});
		this.storage.get('azure_token').then((val) => {
			this.azureToken = val;
		});
	}

	ngOnInit() {
		if (this.item) {
			this.date = moment(this.item.createdDate).utc().fromNow();
			this.postTypeString = this.item.postType.toLowerCase() === 'photo' ? 'uploaded photos to' : 'posted a note in';
		} else {
			this.date = moment(this.opsItem.createdDate).utc().fromNow();
			this.postTypeString = this.opsItem.postType.toLowerCase() === 'photo' ? 'uploaded photos to' : 'posted a note in';
		}
	}

	updateUrl(event: any, photoName: string) {
		if (!this.thumbnailError) {
			event.target.src = this.azureUri + '/' + photoName + this.azureToken;
		}
		this.thumbnailError = true;
	}
}
