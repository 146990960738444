import { Injectable } from '@angular/core';
// import { ImageCompressService, ResizeOptions } from 'ng2-image-compress';
import { DomSanitizer } from '@angular/platform-browser';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { LoadingController, ModalController } from '@ionic/angular';
import { ToastService } from '../services/toast/toast.service';
import { ShowAccountService } from '../services/show-account/show-account.service';
import { EventService } from '../services/event/event.service';
import { ActivityService } from '../services/activity/activity.service';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { CameraLinkToPhotoVerificationHelper } from './camera-intermediary';
import { CollaborationLineItemDTO } from '../models/collaboration-line-item-dto';
import { PhotoVerificationComponent } from '../components/photo-verification/photo-verification.component';

@Injectable()
export class CameraHelper {
	confirmationModal: any;
	cb: Function;
	// resizeOptions: ResizeOptions;

	constructor(
		private camera: Camera,
		public activitySvc: ActivityService,
		public eventSvc: EventService,
		public acctSvc: ShowAccountService,
		public toastService: ToastService,
		public domSanitizer: DomSanitizer,
		// public imgCompressSvc: ImageCompressService,
		public loadingCtrl: LoadingController,
		public modalCtrl: ModalController,
		public camerIntermediary: CameraLinkToPhotoVerificationHelper,
		public statusBar: StatusBar
	) {
		// this.resizeOptions = new ResizeOptions();
		// this.resizeOptions.Resize_Max_Height = 1000;
		// this.resizeOptions.Resize_Max_Width = 1000;
		// this.resizeOptions.Resize_Quality = 80;
		// this.resizeOptions.Resize_Type = 'image/jpeg';
	}

	// For desktop uploads using HTML 5
	async uploadImage(event: any, eventId: string, accountId: string, functionalAreaId: string, lineItemId?: string, cb?: Function, serviceRequestGUID?:any) {
		accountId = accountId ? accountId : "00000000-0000-0000-0000-000000000000"; 
		functionalAreaId = functionalAreaId ? functionalAreaId : "00000000-0000-0000-0000-000000000000"; 
		this.camerIntermediary.queueImages(event);
		this.confirmationModal = await this.modalCtrl.create({
			component: PhotoVerificationComponent, 
			cssClass: 'photo-verification-modal',
			componentProps : { eventId: eventId, accountId: accountId, functionalAreaId: functionalAreaId, lineItemId: lineItemId, serviceRequestGUID, cb: this.dismissVerificationModal.bind(this)}
		});
		await this.confirmationModal.present();
	}

	async uploadWorkTicketImage(event: any, eventId: string, wtId: string) {
		this.camerIntermediary.queueImages(event);
		
		this.confirmationModal = await this.modalCtrl.create({
			component: PhotoVerificationComponent, 
			cssClass: 'photo-verification-modal',
			componentProps : {event: event, eventId: eventId, wtId: wtId, cb: this.dismissVerificationModal.bind(this)}
		});
		await this.confirmationModal.present();
	}

	async uploadBoothImage(event: any, eventId: string, boothGuid: string, boothLineItem?: CollaborationLineItemDTO) {
		this.camerIntermediary.queueImages(event);
		
		this.confirmationModal = await this.modalCtrl.create({
			component: PhotoVerificationComponent, 
			cssClass: 'photo-verification-modal',
			componentProps : {event: event, eventId: eventId, boothGuid: boothGuid, boothLineItem: boothLineItem, cb: this.dismissVerificationModal.bind(this)}
		});
		await this.confirmationModal.present();
	}

	async queueImageForUpload(event: any) {
		this.camerIntermediary.queueImages(event);
	}

	takePicture(eventId: string, accountId?: string, functionalAreaId?: string, lineItemId?: string, twoStepUpload?: boolean, fromSavedPhotos?: boolean, fromNeedsAttention?: boolean, boothGuid?: string, boothLineItem?: CollaborationLineItemDTO, serviceRequestGUID?:any) {
		const options: CameraOptions = {
			allowEdit: false,
			correctOrientation: true,
			destinationType: this.camera.DestinationType.DATA_URL,
			encodingType: this.camera.EncodingType.JPEG,
			mediaType: this.camera.MediaType.PICTURE,
			targetWidth: 1000,
			targetHeight: 1000,
		};
		if (fromSavedPhotos) {
			options.sourceType = this.camera.PictureSourceType.PHOTOLIBRARY;
		}

		this.camera.getPicture(options).then(async imageData => {
			this.statusBar.hide();
			this.statusBar.show();

			const base64Image = 'data:image/jpeg;base64,' + imageData;
			this.camerIntermediary.queuedImages.push(base64Image);

			if (!fromNeedsAttention) {
				// only display the confirmation when it's not coming from the needs attention modal

				this.confirmationModal = await this.modalCtrl.create({
					component: PhotoVerificationComponent,
					id:'photo-upload-confirmation',
					componentProps: { eventId: eventId, accountId: accountId, boothGuid: boothGuid, boothLineItem: boothLineItem, functionalAreaId: functionalAreaId, lineItemId: lineItemId, serviceRequestGUID, cb: this.dismissVerificationModal.bind(this) }
				});
				await this.confirmationModal.present();
			}
		}, (err) => {
			this.statusBar.hide();
			this.statusBar.show();
		});
	}

	takeWorkTicketPicture(eventId: string, workTicketId: string, lineItemId?: string, twoStepUpload?: boolean, fromSavedPhotos?: boolean, fromNeedsAttention?: boolean) {
		const options: CameraOptions = {
			allowEdit: false,
			correctOrientation: true,
			destinationType: this.camera.DestinationType.DATA_URL,
			encodingType: this.camera.EncodingType.JPEG,
			mediaType: this.camera.MediaType.PICTURE,
			targetWidth: 1000,
			targetHeight: 1000,
		};
		if (fromSavedPhotos) {
			options.sourceType = this.camera.PictureSourceType.PHOTOLIBRARY;
		}
		this.camera.getPicture(options).then(async imageData => {
			this.statusBar.hide();
			this.statusBar.show();

			const base64Image = 'data:image/jpeg;base64,' + imageData;

			this.camerIntermediary.queuedImages.push(base64Image);

			if (!fromNeedsAttention) {
				// only display the confirmation when it's not coming from the needs attention modal
				const options = {
					cssClass: 'photo-upload-confirmation'
				};

				this.confirmationModal = await this.modalCtrl.create({
					component: PhotoVerificationComponent,
					id:'photo-upload-confirmation',
					componentProps: { eventId: eventId, wtId: workTicketId, cb: this.dismissVerificationModal.bind(this) }
				});
				await this.confirmationModal.present();
			}
		}, (err) => {
			this.statusBar.hide();
			this.statusBar.show();
		});
	}

	dismissVerificationModal(eventId?, accountId?, functionalAreaId?, lineItemId?) {
		if (this.confirmationModal) {
			this.confirmationModal.dismiss();
		}
		if (this.cb && eventId) {
			this.cb();
		} else if (eventId && accountId && functionalAreaId) {
			this.takePicture(eventId, accountId, functionalAreaId, lineItemId, false, true, false);
		}
	}
}
