import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ToastService } from '../toast/toast.service';
import { ShowAccountUserDTO } from '../../models/show-account-user-dto';
import { ToastType } from '../../models/toast-type';
import { AddUserResponse } from '../../models/generic-response';
import { AddedOpsUser } from '../../models/added-ops-user';
import { UserPreferencesDto } from '../../models/user-preferences-dto';
import { MentionConfig } from 'angular-mentions';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	url = environment.apiUrl;
	showAccountUserDTO = new BehaviorSubject<ShowAccountUserDTO[]>([]);
	showUserDTO = new BehaviorSubject<ShowAccountUserDTO[]>([]);
	public currentShowAccountUsers: ShowAccountUserDTO[];
	public currentShowUsers: ShowAccountUserDTO[];
	public mentionsConfig = new BehaviorSubject<MentionConfig>(null);
	public opsMentionsConfig = new BehaviorSubject<MentionConfig>(null);
	public addedOpsUsers = new BehaviorSubject<AddedOpsUser[]>([]);
	public nonOpsUsers = new BehaviorSubject<AddedOpsUser[]>([]);
	public userRequest: Subscription;

	constructor(public http: HttpClient, public toastService: ToastService) {
		this.showAccountUserDTO.subscribe(dto => {
			this.currentShowAccountUsers = dto;
			this.setupMentions(dto);
		});
		this.showUserDTO.subscribe(dto => {
			this.currentShowUsers = dto;
			this.setupMentions(dto);
		});
	}

	get(showId: string, showAccountId?: string): void {
		if (showAccountId) {
			this.refresh(showId, showAccountId);
		} else {
			this.refreshShow(showId);
		}
	}

	refresh(showId: string, showAccountId: string, errorCallback?: Function) {
		this.http.get<any>(`${this.url()}events/${showId}/accounts/${showAccountId}/users`)
			.subscribe(data => {
				this.showAccountUserDTO.next(data);
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error retrieving user information for the show account', 'danger');
				}
				if (errorCallback) errorCallback();
			});
	}

	refreshShow(showId: string, errorCallback?: Function) {
		this.http.get<any>(`${this.url()}events/${showId}/users`)
			.subscribe(data => {
				this.showUserDTO.next(data);
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error retrieving user information for the show', 'danger');
				}
				if (errorCallback) errorCallback();
			});
	}

	addOpsUser(addedOpsUser: AddedOpsUser) {
		this.http.put<AddUserResponse>(`${this.url()}opsUsers/${addedOpsUser.externalId}`, addedOpsUser)
			.subscribe(data => {
				this.toastService.open(data.message, 'success');
				this.getAddedOpsUsers();
			}, err => {
				this.toastService.open(err.message, 'danger');
			});
	}

	lookForUser(user: AddedOpsUser) {
		this.userRequest = this.http.post<AddedOpsUser[]>(`${this.url()}opsUsers/ad`, user)
			.subscribe(data => {
				this.nonOpsUsers.next(data);
			}, err => {
				this.toastService.open('Error searching for AD user', 'danger');
			});
	}

	getAddedOpsUsers() {
		this.http.get<AddedOpsUser[]>(`${this.url()}opsUsers`)
			.subscribe(data => {
				this.addedOpsUsers.next(data);
			});
	}

	deleteOpsUser(externalId) {
		this.http.delete<AddUserResponse>(`${this.url()}opsUsers/${externalId}`)
			.subscribe(data => {
				this.toastService.open(data.message, 'success');
				this.getAddedOpsUsers();
			}, err => {
				this.toastService.open(err.message, 'danger');
			});
	}

	setupMentions(userList: any) {
		if (userList === null)
			return;
		const opsUserList = userList.filter(ul => ul.isOpsUser === '1' || ul.isOpsUser === 'True');
		this.opsMentionsConfig.next({
			mentions: [{
				items: opsUserList.length ? opsUserList : [],
				labelKey: 'displayName',
				triggerChar: '@',
				maxItems: 3,
				dropUp: true
			}]
		});
		this.mentionsConfig.next({
			mentions: [{
				items: userList,
				labelKey: 'displayName',
				triggerChar: '@',
				maxItems: 3,
				dropUp: true
			}]
		});
	}

	verifyMentionedUsers(note: string): ShowAccountUserDTO[] {
		const verifiedUsers = [];
		if (this.currentShowAccountUsers.length > 0) {
			this.currentShowAccountUsers.forEach(e => {
				if (note && note.includes(e.displayName)) {
					verifiedUsers.push(e);
				}
			});
		} else if (this.currentShowUsers.length > 0) {
			this.currentShowUsers.forEach(e => {
				if (note && note.includes(e.displayName)) {
					verifiedUsers.push(e);
				}
			});
		}
		return verifiedUsers;
	}

	refreshMentionConfig() {
		if (this.currentShowAccountUsers.length > 0) {
			this.setupMentions(this.currentShowAccountUsers);
		} else if (this.currentShowUsers.length > 0) {
			this.setupMentions(this.currentShowUsers);
		}
	}

	getUserPreferences(): Observable<UserPreferencesDto> {
		return this.http.get<UserPreferencesDto>(`${this.url()}users/preferences`);
	}

	updateUserPreferences(preferences: UserPreferencesDto): Observable<UserPreferencesDto> {
		return this.http.post<UserPreferencesDto>(`${this.url()}users/preferences`, preferences);
	}

	//for freight selected users
	getSelectedUsers(showId: string, freightId: string, errorCallback?: Function) {
		return this.http.get<any>(`${this.url()}events/${showId}/freightSetup/freight/${freightId}/users`).pipe(map((data: any) => {
			return data;
		}, err => {
			if (err.status !== 401 && err.status !== 403) {
				this.toastService.open('There was an error to getting selected users data data', 'danger');
			}
		}));
	}

	getOpsUsers(showId: string) {
		return this.http.get<any>(`${this.url()}events/${showId}/users`).pipe(map((data: any) => {
			return data;
		}, err => {
			if (err.status !== 401 && err.status !== 403) {
				this.toastService.open('There was an error to getting ops users data', 'danger');
			}
		}));
	}
}
