import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { NewServiceRequest } from 'src/app/models/new-service-request-dto';
import { ShowBooth } from 'src/app/models/show-booth-dto';
import { environment } from 'src/environments/environment';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class NewServiceRequestService {

  url = environment.apiUrl;
  accountsData: any[] = [];
  boothsData: ShowBooth[] = [];
  serviceTypeData: ShowBooth[] = [];
  serviceIssueGUID: any = null;
  allCountryCodes = [{
    display: "+1",
    value: 1,
    regExData: [/[0-9]/g, /[0-9]/g, /[0-9]/g, /[-]/g, /[0-9]/g, /[0-9]/g, /[0-9]/g, /[-]/g, /[0-9]/g, /[0-9]/g, /[0-9]/g, /[0-9]/g]
  }];
  defaultCountryCode = {
    display: "+1",
    value: 1,
    regExData: [/[0-9]/g, /[0-9]/g, /[0-9]/g, /[-]/g, /[0-9]/g, /[0-9]/g, /[0-9]/g, /[-]/g, /[0-9]/g, /[0-9]/g, /[0-9]/g, /[0-9]/g]
  };

  allNewRequestData: any = {};

  constructor(
    public http: HttpClient,
    public toastService: ToastService,
    public loadingCtrl: LoadingController
  ) { }

  getAccounts(eventGuid) {
    return this.http.get<any>(`${this.url()}events/${eventGuid}/exhibitorServices/accounts`).pipe(map((data: any) => {
      if (data != null) {
        this.accountsData = data;
      }
      return data;
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error to getting accounts data', 'danger');
      }
    }));
  }

  getBoothsList(eventId: string) {
    return this.http.get<Array<ShowBooth>>(`${this.url()}events/${eventId}/getBooths`).pipe(map((data: any) => {
      if (data != null) {
        data = _.orderBy(_.uniqBy(data, 'boothGUID'), ['boothName'], ['asc']);
        this.boothsData = data;
      }
      return data;
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error to getting booths data', 'danger');
      }
    }));
  }

  getServiceTypesData(eventId: string) {
    return this.http.get<Array<any>>(`${this.url()}events/${eventId}/exhibitorservice/requestType`).pipe(map((data: any) => {
      if (data != null) {
        this.serviceTypeData = data;
      }
      return data;
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error to getting Service Type data', 'danger');
      }
    }));
  }

  submitServiceRequest(eventGUID: string, requestPayload: NewServiceRequest) {
    return this.http.post(`${this.url()}events/${eventGUID}/exhibitorService/serviceRequest/create`, requestPayload).pipe(map((createRequestResponse: any) => {
      this.loadingCtrl.dismiss();
      if (createRequestResponse && createRequestResponse.status == "success") {
        this.toastService.open(createRequestResponse.message, 'success');
        // this.serviceIssueGUID = createRequestResponse.kafkaId ? createRequestResponse.kafkaId : null;
        this.serviceIssueGUID = null;
        return requestPayload;
      } else {
        this.toastService.open('There was an error while creating service request', 'danger');
        return false;
      }
    }, err => {
      this.loadingCtrl.dismiss();
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error while creating service request', 'danger');
      }
    }));
  }

  updateServiceRequest(eventGUID: string, requestData: any) {
    return this.http.post(`${this.url()}events/${eventGUID}/exhibitorService/serviceRequest/contactinfo/update`, requestData).pipe(map((createRequestResponse: any) => {
      this.loadingCtrl.dismiss();
      if (createRequestResponse && createRequestResponse.status == "success") {
        this.toastService.open(createRequestResponse.message, 'success');
        return true;
      } else {
        this.toastService.open('There was an error while creating service request', 'danger');
        return false;
      }
    }, err => {
      this.loadingCtrl.dismiss();
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error while creating service request', 'danger');
      }
    })
    )
  }
}
