import { Component, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { AlertController, LoadingController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { AuthTokenHelper } from 'src/app/helpers/auth-token-helper';
import { AuthenticationService } from './services/authentication/authentication.service';
import { KioskSessionService } from './services/kiosk/kiosk-session.service';
import { KioskService } from './services/kiosk/kiosk.service';
import { VersionService } from './services/version/version.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { environment } from 'src/environments/environment';
import { UserActivityService } from './services/user/user-activity.service';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  isDesktop: boolean = true;
  isBottomMenu: boolean = true;

  constructor(
    private storage: Storage,
    private platform: Platform,
    private router: Router,
    private tokenHelper: AuthTokenHelper,
    public kioskSessionService: KioskSessionService,
    private versionService: VersionService,
    private authService: AuthenticationService,
    public kioskService: KioskService,
    public loadingController: LoadingController,
    private ga: GoogleAnalytics,
    private zone: NgZone,
    private alert: AlertController,
    private authTokenHelper: AuthTokenHelper,
    private userActivityService: UserActivityService
  ) {

    this.platform.ready().then(() => {
      this.initializeApp();
			this.ga.startTrackerWithId('UA-117971414-1')
			  .then(() => {
				console.log('Google analytics is ready now');
				this.ga.debugMode();
			  })
			  .catch(e => console.log('Error starting GoogleAnalytics', e));
		});
    
    //this.storage.create();
    this.kioskSessionService.stopActivityTracking();
    this.isDesktop = this.platform.is('desktop');
    this.tokenHelper.isUserLoggedIn.subscribe((data) => {
      if(data)
      this.versionService.get();
    })

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        const routerPage = (e.url).split('/').reverse()?.[0];
        gtag('config', 'UA-117971414-1', {
          'page_path': e.urlAfterRedirects ,
          'page_title': routerPage.replace('-', ' '),
          'user_id:': this.tokenHelper.currentUserGuid.getValue()
        });
        this.ga.trackView(routerPage.replace('-', ' '));
        if (routerPage == 'login' || routerPage == 'download' || routerPage.includes('kiosk')
          || routerPage == '' || routerPage == 'self-checkin' ||  routerPage == 'un') {
          this.isBottomMenu = true;
          if (routerPage.includes('kiosk') && !this.kioskSessionService.isEventListenerRegistered) {
            this.tokenHelper.IsKioskUser().then(isKiosk => {
              if (isKiosk && this.router.url != '/kiosk-authorization' && this.router.url != '/kiosk-events')
                this.kioskSessionService.startActivityTracking();
            })
          }
        }
        else {
          this.isBottomMenu = false;
        }
      }
    });

  }

  async setTokens() {
    this.authService.token = await this.authTokenHelper.GetToken();
    this.authService.idToken = await this.authTokenHelper.GetIDToken();
    this.userActivityService.sessionId = await this.storage.get('sessionId');
  }

  async ngOnInit() {
    await this.storage.create();
    this.tokenHelper.SetCurrentUser();
    this.tokenHelper.checkUserType();
    this.tokenHelper.checkUserLoggedIn();
    // Set token values
    this.setTokens();

    let refreshCount = 0;
    setInterval(() => {
      const paramURL = (this.router.url).substring((this.router.url).lastIndexOf('/'));
      if(paramURL != '/un') {
        this.tokenHelper.isTokenValid().then((isValid) => {
          if (!isValid) {
            this.tokenHelper.isRefreshTokenValid().then(async (isRefreshValid) => {
              if (!isRefreshValid) {
                if (this.router.url != '/login') { this.authService.logout(); }
                refreshCount = 0;
              } else {
                const refreshToken = await this.tokenHelper.GetRefreshToken();
                if (refreshCount == 0) {
                  refreshCount = 1;
                  this.authService.refresh(refreshToken).then((data) => {
                    if (data != null) {
                      refreshCount = 0;
                      this.navigatePage();
                    }
                  });
                }
              }
            })
          }
        })
      }
    }, 1000);
  }

  isDesktopView() {
    return this.platform.width() > 767 ? true : false;
  }

  async navigatePage() {
    const isKiosk = await this.tokenHelper.IsKioskUser();
    const loaders = await this.loadingController.getTop();
      if (isKiosk) {
        await this.kioskService.getFolShowName();
        this.router.navigate(['kiosk-landing'], { replaceUrl: true });
      } else {
        this.router.navigate(['events'], { replaceUrl: true });
      }
      if(loaders !== undefined) {
        await this.loadingController.dismiss();
      }
  }

  initializeApp() {
    console.log('inside initializeApp');
    App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {

      this.zone.run(() => {
        console.log('inside zone run');
        const domain = environment.domainName();
        const pathArray = (event.url).split(domain);
 
        // Get the last element with pop()
        const appPath = pathArray.pop();
        if (appPath) {
          this.router.navigateByUrl(appPath);
        }
        console.log('appPath', appPath);
        console.log('event', event);
        console.log('domain', domain, pathArray);
      });
    });
  }
}
