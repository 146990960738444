import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Platform } from '@ionic/angular';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';
import { StatusHelper } from 'src/app/helpers/status-helper';
import { BoothServiceProvider } from 'src/app/services/booth/booth.service';
import { takeWhile } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'occ-booth-search-filter',
  templateUrl: './booth-search-filter.component.html',
  styleUrls: ['./booth-search-filter.component.scss'],
})
export class BoothSearchFilterComponent implements OnInit {
  @Output() applySearchFilters?= new EventEmitter();
  @Output() closeFilter?= new EventEmitter();
  allSelectedCriteriaItems: any[];
  isOptionSelectionMode: boolean = false;
  subscribe: boolean = true;
  applyButtonEnabled: boolean = false;
  lowerSetupStartDate: any;
  datepickerLowerSetupStartDate: any;
  upperSetupStartDate: any;
  datepickerUpperSetupStartDate: any;
  lowerSetupCompleteDate: any;
  datepickerLowerSetupCompleteDate: any;
  upperSetupCompleteDate: any;
  datepickerUpperSetupCompleteDate: any;
  errorMessage: string;

  criteriaOptionsData = {
    statuses: StatusHelper.LineItemStatuses,
    vendors: [],
    locations: [],
    categories: []
  };

  desktopDatePickerOptions: IAngularMyDpOptions = {
    alignSelectorRight: true,
    dateFormat: 'mm/dd/yyyy'
    // height: '42px',
    // showTodayBtn: false,
    // indicateInvalidDate: true
  };

  selectedCriteria: any = {
    id: 0,
    name: "",
    value: ""
  };

  constructor(
    public boothService: BoothServiceProvider,
    public platform: Platform,
    public appBrowserHelper: AppBrowserHelper
  ) {

  }

  ngOnInit(): void {
    this.boothService.boothSearchData.pipe(takeWhile(() => this.subscribe)).subscribe(serachData => {
      serachData.forEach(liData => {
        liData.vendorName ? this.criteriaOptionsData.vendors.push(liData.vendorName) : "";
        liData.locationInfo ? this.criteriaOptionsData.locations.push(liData.locationInfo) : "";
        liData.category ? this.criteriaOptionsData.categories.push(liData.category) : "";
      });

      this.criteriaOptionsData.vendors = _.uniq(this.criteriaOptionsData.vendors, true);
      this.criteriaOptionsData.locations = _.uniq(this.criteriaOptionsData.locations, true);
      this.criteriaOptionsData.categories = _.uniq(this.criteriaOptionsData.categories, true);
    });
    if (this.boothService.filterSelections.length == 0) {
      this.boothService.resetBoothSearchFilterSelection();
    }
    this.allSelectedCriteriaItems = this.boothService.getSearchFilterSelection();
    this.initCriteriaDates();
  }

  initCriteriaDates() {
    if (this.appBrowserHelper.isDesktop) {
      this.allSelectedCriteriaItems[1].criterias.forEach(liCriteriaData => {
        if (liCriteriaData.id == 5 && liCriteriaData.value.length == 2) {
          // this.lowerSetupStartDate = { formatted: liCriteriaData.value[0] };
          this.lowerSetupStartDate = liCriteriaData.value[0];
          // this.upperSetupStartDate = { formatted: liCriteriaData.value[1] };
          this.upperSetupStartDate = liCriteriaData.value[1];
          this.datepickerLowerSetupStartDate = liCriteriaData.dateValue[0];
          this.datepickerUpperSetupStartDate = liCriteriaData.dateValue[1];
        }
        if (liCriteriaData.id == 6 && liCriteriaData.value.length == 2) {
          this.lowerSetupCompleteDate = { formatted: liCriteriaData.value[0] };
          this.upperSetupCompleteDate = { formatted: liCriteriaData.value[1] };
          this.datepickerLowerSetupCompleteDate = liCriteriaData.dateValue[0];
          this.datepickerUpperSetupCompleteDate = liCriteriaData.dateValue[1];
        }
      });
    } else {
      this.allSelectedCriteriaItems[1].criterias.forEach(liCriteriaData => {
        if (liCriteriaData.id == 5 && liCriteriaData.value.length == 2) {
          this.lowerSetupStartDate = liCriteriaData.value[0];
          this.upperSetupStartDate = liCriteriaData.value[1];
        }
        if (liCriteriaData.id == 6 && liCriteriaData.value.length == 2) {
          this.lowerSetupCompleteDate = liCriteriaData.value[0];
          this.upperSetupCompleteDate = liCriteriaData.value[1];
        }
      });
    }
  }

  hideFilter() {
    this.closeFilter.emit();
  }

  navigateBackCriteria() {
    this.isOptionSelectionMode = false;
    this.selectedCriteria = { id: 0, name: "", value: "" };
  }

  openOptionsForCriteria(selectedCriteria) {
    if (selectedCriteria.id == 1 || selectedCriteria.id == 2) {
      this.isOptionSelectionMode = false;
      selectedCriteria.value = selectedCriteria.value.length == 0 ? [true] : [];
      selectedCriteria.id == 1 ? this.allSelectedCriteriaItems[0].criterias[0] = selectedCriteria : this.allSelectedCriteriaItems[0].criterias[1] = selectedCriteria;
      this.boothService.setBoothSearchFilterSelection(this.allSelectedCriteriaItems);
      this.applyBoothLIFilter()
    } else {
      this.isOptionSelectionMode = true;
    }

    this.selectedCriteria = selectedCriteria;
    if (this.selectedCriteria.id == 5 || this.selectedCriteria.id == 6) {
      this.checkValidDateEntry();
    }
  }

  expandGroupFilter(groupIndex) {
    this.allSelectedCriteriaItems[groupIndex].isExpanded = !this.allSelectedCriteriaItems[groupIndex].isExpanded;
  }

  getGroupFilterCount(groupCriteriaIndex) {
    let groupFilterCount = 0;
    this.allSelectedCriteriaItems[groupCriteriaIndex].criterias.forEach(criteria => {
      groupFilterCount = criteria.value.length > 0 ? groupFilterCount + 1 : groupFilterCount;
    });
    return groupFilterCount == 0 ? "" : groupFilterCount;
  }

  setOptionSelection(selectedValue) {
    let matchedIndex = this.selectedCriteria.value.findIndex(itemValue => {
      return itemValue == selectedValue;
    })
    if (matchedIndex !== -1) {
      this.selectedCriteria.value.splice(matchedIndex, 1)
    } else {
      this.selectedCriteria.value.push(selectedValue);
    }

    this.allSelectedCriteriaItems[1].criterias.forEach(optionData => {
      if (this.selectedCriteria.id == optionData.id) {
        optionData = this.selectedCriteria;
      }
    });

    this.boothService.setBoothSearchFilterSelection(this.allSelectedCriteriaItems);
    this.applyBoothLIFilter()
  }

  getStatusTitle(statusSelectionData) {
    return StatusHelper.getBoothStatusDetails(statusSelectionData[0]).title;
  }

  checkValidDateEntry() {
    setTimeout(() => {
      this.applyButtonEnabled = false;
      switch (this.selectedCriteria.id) {
        case 5:
          this.lowerSetupStartDate = this.datepickerLowerSetupStartDate ? this.datepickerLowerSetupStartDate.singleDate.jsDate.toISOString() : this.lowerSetupStartDate;
          this.upperSetupStartDate = this.datepickerUpperSetupStartDate ? this.datepickerUpperSetupStartDate.singleDate.jsDate.toISOString() : this.upperSetupStartDate;
          this.validateDate(this.lowerSetupStartDate, this.upperSetupStartDate);
          break;
        case 6:
          this.lowerSetupCompleteDate = this.datepickerLowerSetupCompleteDate ? this.datepickerLowerSetupCompleteDate.singleDate.jsDate.toISOString() : this.lowerSetupCompleteDate;
          this.upperSetupCompleteDate = this.datepickerUpperSetupCompleteDate ? this.datepickerUpperSetupCompleteDate.singleDate.jsDate.toISOString() : this.upperSetupCompleteDate;
          this.validateDate(this.lowerSetupCompleteDate, this.upperSetupCompleteDate);
          break;
        default:
          break;
      }
    }, 500);
  }

  validateDate(lowerLimitDate, upperLimitDate) {
    if (lowerLimitDate && upperLimitDate) {
      let lowerBoundDate;
      let upperBoundDate;
      if (typeof lowerLimitDate == "object") {
        lowerBoundDate = new Date(lowerLimitDate.formatted);
      } else {
        lowerBoundDate = new Date(lowerLimitDate);
      }

      if (typeof upperLimitDate == "object") {
        upperBoundDate = new Date(upperLimitDate.formatted);
      } else {
        upperBoundDate = new Date(upperLimitDate);
      }

      if (lowerBoundDate > upperBoundDate) {
        this.applyButtonEnabled = false;
        this.errorMessage = 'Please enter a valid date range.';
      } else {
        this.applyButtonEnabled = true;
        this.errorMessage = '';
      }
    }
  }

  applyDateFilter() {
    switch (this.selectedCriteria.id) {
      case 5:
        this.selectedCriteria.value = [];
        if (this.datepickerLowerSetupStartDate && this.datepickerUpperSetupStartDate) {
          this.selectedCriteria.dateValue = [];
          this.selectedCriteria.dateValue.push(this.datepickerLowerSetupStartDate);
          this.selectedCriteria.dateValue.push(this.datepickerUpperSetupStartDate);
        }

        if (typeof this.lowerSetupStartDate == "object") {
          this.selectedCriteria.value.push(this.lowerSetupStartDate.formatted);
        } else {
          this.selectedCriteria.value.push(this.lowerSetupStartDate);
        }

        if (typeof this.upperSetupStartDate == "object") {
          this.selectedCriteria.value.push(this.upperSetupStartDate.formatted);
        } else {
          this.selectedCriteria.value.push(this.upperSetupStartDate);
        }

        this.allSelectedCriteriaItems[1].criterias.forEach(optionData => {
          if (this.selectedCriteria.id == optionData.id) {
            optionData = this.selectedCriteria;
          }
        });

        break;
      case 6:
        this.selectedCriteria.value = [];

        if (this.datepickerLowerSetupCompleteDate && this.datepickerUpperSetupCompleteDate) {
          this.selectedCriteria.dateValue = [];
          this.selectedCriteria.dateValue.push(this.datepickerLowerSetupCompleteDate);
          this.selectedCriteria.dateValue.push(this.datepickerUpperSetupCompleteDate);
        }

        if (typeof this.lowerSetupCompleteDate == "object") {
          this.selectedCriteria.value.push(this.lowerSetupCompleteDate.formatted);
        } else {
          this.selectedCriteria.value.push(this.lowerSetupCompleteDate);
        }

        if (typeof this.upperSetupCompleteDate == "object") {
          this.selectedCriteria.value.push(this.upperSetupCompleteDate.formatted);
        } else {
          this.selectedCriteria.value.push(this.upperSetupCompleteDate);
        }
        this.allSelectedCriteriaItems[1].criterias.forEach(optionData => {
          if (this.selectedCriteria.id == optionData.id) {
            optionData = this.selectedCriteria;
          }
        });
        break;
      default:
        break;
    }
    this.boothService.setBoothSearchFilterSelection(this.allSelectedCriteriaItems);
    this.applyBoothLIFilter();
    this.navigateBackCriteria();
  }

  clearAllFilters() {
    this.lowerSetupStartDate = "";
    this.upperSetupStartDate = "";
    this.datepickerLowerSetupStartDate = "";
    this.datepickerUpperSetupStartDate = "";
    this.lowerSetupCompleteDate = "";
    this.upperSetupCompleteDate = "";
    this.datepickerLowerSetupCompleteDate = "";
    this.datepickerUpperSetupCompleteDate = "";
    this.boothService.resetBoothSearchFilterSelection();
    this.allSelectedCriteriaItems = this.boothService.getSearchFilterSelection();
    this.applyBoothLIFilter();
  }

  clearCurrentSelectedFilter() {
    if (this.selectedCriteria.id) {
      this.lowerSetupStartDate = "";
      this.upperSetupStartDate = "";
      this.datepickerLowerSetupStartDate = "";
      this.datepickerUpperSetupStartDate = "";
    }

    if (this.selectedCriteria.id) {
      this.lowerSetupCompleteDate = "";
      this.upperSetupCompleteDate = "";
      this.datepickerLowerSetupCompleteDate = "";
      this.datepickerUpperSetupCompleteDate = "";
    }

    this.selectedCriteria.value = [];
    this.allSelectedCriteriaItems[1].criterias.forEach(optionData => {
      if (this.selectedCriteria.id == optionData.id) {
        optionData = this.selectedCriteria;
      }
    });
    this.boothService.setBoothSearchFilterSelection(this.allSelectedCriteriaItems);
    this.applyBoothLIFilter();
  }

  applyBoothLIFilter() {
    setTimeout(() => {
      this.applySearchFilters.emit();
    }, 100);
  }
}
