import { Directive, ElementRef, HostListener } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Directive({
  selector: 'input[numbersOnly]'
})
export class NumberDirective {

  maxAllowedCopies: number = 999;
  toast: any;
  constructor(private _el: ElementRef, private toastCtrl: ToastController) { }

  @HostListener('input', ['$event']) async onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    if (this._el.nativeElement.value) {
      if (parseInt(this._el.nativeElement.value) > this.maxAllowedCopies) {
        this._el.nativeElement.value = parseInt(this._el.nativeElement.value).toString().slice(0, 3);
        if(!this.toast){
          this.toast = await this.toastCtrl.create({
            message: 'Quantity must be a number between 1 and 999.',
            duration: 3000,
            position: 'top',
            color: "danger",
            cssClass: "kiosk-toast-alert"
          });
        
          this.toast.present();

          await this.toast.onDidDismiss();
          this.toast = null;
        }
      }
      this._el.nativeElement.value = parseInt(this._el.nativeElement.value);
    }
    if (this._el.nativeElement.value === 0) {
      this._el.nativeElement.value = 1;
    }
    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }

  @HostListener("blur", ["$event.target"])
  onBlur(target) {
    if (!target.value) {
      target.value = 1;
    } else if (parseInt(target.value) == 0) {
      target.value = 1;
    }
  }
}
