import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SMSCode } from 'src/app/helpers/sms-helper';
import { KioskService } from 'src/app/services/kiosk/kiosk.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'kiosk-send-code',
  templateUrl: './send-code.component.html',
  styleUrls: ['./send-code.component.scss'],
})
export class SendCodeComponent implements OnInit {

  @Output() setSendCodeStatus?= new EventEmitter();

  countryCode: string;
  phoneNumber: string;
  allCountryCodes = [{
    display: "+1",
    value: 1,
    regExData: [/[0-9]/g, /[0-9]/g, /[0-9]/g, /[-]/g, /[0-9]/g, /[0-9]/g, /[0-9]/g, /[-]/g, /[0-9]/g, /[0-9]/g, /[0-9]/g, /[0-9]/g]
  }];
  defaultCountryCode = {
    display: "+1",
    value: 1,
    regExData: [/[0-9]/g, /[0-9]/g, /[0-9]/g, /[-]/g, /[0-9]/g, /[0-9]/g, /[0-9]/g, /[-]/g, /[0-9]/g, /[0-9]/g, /[0-9]/g, /[0-9]/g]
  };

  public isNumberInvalid: boolean = false;
  public enableSendCode: boolean = false;
  public isShowLoader: boolean = false;
  public sendBtnText: string = "Locate";
  public SMSCodeEnum = SMSCode;
  sendCodeData = {
    PhoneNo: '',
    VerifyPhone: false,
    IsKafka: true,
    Code: 1
  };

  constructor(public kioskService: KioskService, private toastService: ToastService, private router: Router) { }

  ngOnInit() { }

  verifyMobileNumber() {
    this.isShowLoader = true;
    this.sendBtnText = 'Locating...';
    let mobileNumber = this.phoneNumber.replace(/-/g, "");
    this.sendCodeData = {
      "PhoneNo": this.phoneNumber.replace(/-/g, ""),
      "VerifyPhone": false,
      "IsKafka": false,
      "Code": this.defaultCountryCode.value
    }
    this.kioskService.verifyMobileNumber(mobileNumber).subscribe((res) => {
      let sendCodeResponse = res ? 2 : 1;
      this.setSendCodeStatus.emit({
        data: this.sendCodeData,
        response: sendCodeResponse
      });
      this.isShowLoader = false;
      this.sendBtnText = "Locate";
    }, (error: any) => {
      this.toastService.open('Error occurred during mobile number verification.', 'danger');
      this.isShowLoader = false;
      this.sendBtnText = "Locate";
      if (error.status == 401) {
        this.router.navigateByUrl("/login");
      }
    });
  }

  sendAuthorizationCode() {
    this.isShowLoader = true;
    this.sendBtnText = "Sending code...";
    this.sendCodeData = {
      "PhoneNo": this.phoneNumber.replace(/-/g, ""),
      "VerifyPhone": true,
      "IsKafka": true,
      "Code": this.defaultCountryCode.value
    }
    this.kioskService.getSendCode(this.sendCodeData).subscribe((sendCodeResponse: any) => {
      this.setSendCodeStatus.emit({
        data: this.sendCodeData,
        response: sendCodeResponse
      });
      switch (sendCodeResponse) {
        case this.SMSCodeEnum.SMSSent:

          break;

        case this.SMSCodeEnum.MobileNotExist:
          break;

        case this.SMSCodeEnum.SMSNotSent:
          this.toastService.open('Error: SMS not sent .', 'danger');
          break;

        default:
          break;
      }
      this.isShowLoader = false;
      this.sendBtnText = "Send Code";
    }, (error: any) => {
      this.toastService.open('Error occurred during mobile number verification.', 'danger');
      this.isShowLoader = false;
      this.sendBtnText = "Send Code";
      if (error.status == 401) {
        this.router.navigateByUrl("/login");
      }
    });
  }

  setCountryCodeSelection(currentCountryCodeSelection) {
    console.log(currentCountryCodeSelection)
  }

  onPhoneNumberChange(numberChangeInfo) {
    this.phoneNumber = numberChangeInfo.phoneNumber;
    this.isNumberInvalid = numberChangeInfo.isNumberInvalid;
    this.enableSendCode = numberChangeInfo.enableSendCode;
  }
}
