import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class EncryptHelper {

    constructor() {
    }

    encrypt(encryptionString, secretKey) {
        // random salt for derivation
        var keySize = 256;
        var salt = CryptoJS.lib.WordArray.random(16);
        // well known algorithm to generate key
        var key = CryptoJS.PBKDF2(secretKey, salt, {
            keySize: keySize / 32,
            iterations: 100
        });
        // random IV
        var iv = CryptoJS.lib.WordArray.random(128 / 8);
        // specify everything explicitly
        var encrypted = CryptoJS.AES.encrypt(encryptionString, key, {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC
        });
        // combine everything together in base64 string
        var result = CryptoJS.enc.Base64.stringify(salt.concat(iv).concat(encrypted.ciphertext));
        return result;
    }
}