import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ToastService } from '../toast/toast.service';
import { ShowAccount } from '../../models/show-account';
import { ToastType } from '../../models/toast-type';

@Injectable({
  providedIn: 'root'
})
export class ShowAccountService {
	url = environment.apiUrl;
	public showAccounts = new BehaviorSubject<ShowAccount[]>([]);
	public currentShowAccount = new BehaviorSubject<ShowAccount>(null);
	public activeShowAccountId: string;
	showAccountLoading = new BehaviorSubject<boolean>(null);

	constructor(public http: HttpClient, public toastService: ToastService) {}

	get(eventId, accountId?) {
		this.showAccountLoading.next(true);
		this.refresh(eventId, accountId);
	}

	refresh(eventId: string, accountId?: string) {
		this.http.get<ShowAccount[]>(`${this.url()}events/${eventId}/accounts`)
		.subscribe(data => {
			this.showAccounts.next(data);
			this.showAccountLoading.next(false);
			if (accountId) {
				this.currentShowAccount.next(data.find(x => {
					return x.kafkaId === accountId;
				}));
			}
		}, err => {
			if (err.status !== 401 && err.status !== 403) {
				this.toastService.open('There was an error refreshing the account data', 'danger');
			}
		});
	}
}
