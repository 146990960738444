import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController, PopoverController } from '@ionic/angular';
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';
import { NavParamHelper } from 'src/app/helpers/nav-param-helper';
import { SelectHelper } from 'src/app/helpers/select-helper';
import { CollaborationLineItemDTO } from 'src/app/models/collaboration-line-item-dto';
import { WorkTicketDTO } from 'src/app/models/work-ticket-dto';
import { ActivityService } from 'src/app/services/activity/activity.service';
import { AttachmentService } from 'src/app/services/attachment/attachment.service';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';
import { LineItemService } from 'src/app/services/line-item/line-item.service';
import { WorkticketLineItemService } from 'src/app/services/workticket-line-item/workticket-line-item.service';
import { environment } from 'src/environments/environment';
import { ItemActionMenuComponent } from '../item-action-menu/item-action-menu.component';

@Component({
  selector: 'occ-search-wtitem-card',
  templateUrl: './search-wtitem-card.component.html',
  styleUrls: ['./search-wtitem-card.component.scss'],
})
export class SearchWTItemCardComponent implements OnInit {
  @Input() lineItem: CollaborationLineItemDTO;
	@Input() workTicket: WorkTicketDTO;

	eventId: string;
	statusMenuPopover: any;
	envIsUAT = environment.environment === 'UAT';

	constructor(
		public activitySvc: ActivityService,
		public attachmentSvc: AttachmentService,
		public liSvc: LineItemService,
		public navCtrl: NavController,
		public appBrowserHelper: AppBrowserHelper,
		public navParamHelper: NavParamHelper,
		public dataSvc: DataRefreshService,
		public popoverCtrl: PopoverController,
		public wrkTikLISvc: WorkticketLineItemService,
    private activatedRoute: ActivatedRoute,
    public router: Router
	) {
		this.eventId = this.activatedRoute.snapshot.paramMap.get('eventId') || this.dataSvc.eventActive;
		this.navParamHelper.syncActiveInfo(this.eventId);
	}

  ngOnInit() {}

	pushDetailsPage(item: CollaborationLineItemDTO) {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		// check the current line item value before doing anything
		const currentLineItem = this.wrkTikLISvc.currentLineItem.getValue();

		// if it doesn't exist or if it's different, update it.
		if (!currentLineItem || currentLineItem !== item) {
			this.wrkTikLISvc.currentLineItem.next(item);
		}

    this.router.navigateByUrl('events/' + this.eventId + '/workticket-details/' +  this.workTicket.workTicketGuid + '/line-items/' +  item.kafkaId + '/workticket-line-item-details');
	}

	async presentPopover(event, item: CollaborationLineItemDTO) {
    this.statusMenuPopover = await this.popoverCtrl.create({
			component: ItemActionMenuComponent,
			componentProps: { workTicketLineItem: item, fromSearch: true },
      event: event
		});

		await this.statusMenuPopover.present();
		event.stopPropagation();
	}

}
