import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { KioskService } from 'src/app/services/kiosk/kiosk.service';
import { KioskLogoutConfirmationComponent } from '../kiosk-logout-confirmation/kiosk-logout-confirmation.component';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'occ-kiosk-header',
  templateUrl: './kiosk-header.component.html',
  styleUrls: ['./kiosk-header.component.scss'],
})
export class KioskHeaderComponent implements OnInit {

  @Input() isHomeBtnVisible?: boolean = true;
  @Input() isEndSessionVisble?: boolean = true;
  folShowName: string;
  sessionValid: boolean;

  constructor(
    public authSvc: AuthenticationService,
    public kioskService: KioskService,
    private router: Router,
    public navCtrl: NavController,
    private modalCtrl: ModalController,
    private storage: Storage
  ) {
    this.storage.get('isKioskSessionValid').then((isSessionValid) => {
      this.sessionValid = isSessionValid;
  });
}

  ngOnInit() {
    if (this.kioskService.currentShowName.getValue() == null) {
      this.kioskService.getFolShowName();
    }
    this.kioskService.currentShowName
      .subscribe(showData => {
        if (showData != null)
          this.folShowName = showData.alternateName ? showData.alternateName : showData.name;
      });
      
  }
  async confirmationPopup() {
      let logoutModal = await this.modalCtrl.create({
        component: KioskLogoutConfirmationComponent,
        cssClass: 'Kiosk-Logout-Confirmation',
        backdropDismiss: false,
      });
  
      await logoutModal.present();
  }
}
