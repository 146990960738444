import { Pipe, PipeTransform } from '@angular/core';
import { WorkTicketDTO } from '../models/work-ticket-dto';

@Pipe({
	name: 'wtCategoryFilter',
	pure: false
})
export class WorkTicketGroupPipe implements PipeTransform {
	transform(items: WorkTicketDTO[], group: string, viewType): any[] {
		if (!items) return [];
		if (!group) return items;
		group = group.toLowerCase();
		if(viewType == 'categoryView'){
			return items.filter(it => {
				if(it && it.partGroup){
					return it.partGroup.toLowerCase() == group;
				}
			});
		}else{
			return items.filter(it => {
				if(it && it.functionalAreaName){
					return it.functionalAreaName.toLowerCase() == group;
				}
			});
		}
		
	}
}
