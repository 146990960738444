import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { FilterHelper } from 'src/app/helpers/filter-helper';
import { SelectHelper } from 'src/app/helpers/select-helper';
import { LineItemService } from 'src/app/services/line-item/line-item.service';

@Component({
  selector: 'occ-filter-vendor',
  templateUrl: './filter-vendor.component.html',
  styleUrls: ['./filter-vendor.component.scss'],
})
export class FilterVendorComponent {
	@Output() closeFilter = new EventEmitter();
	@Output() navigate = new EventEmitter();

	vendors: string[];
	isWorkTicketSearchPage: false;
	pageReturn: string;

	subscribe = true;

	constructor(public lineItemSvc: LineItemService, public filterHelper: FilterHelper) {
		this.lineItemSvc.vendorListSubject.pipe(takeWhile(() => this.subscribe)).subscribe(vendorList => {
			this.vendors = vendorList.sort();
		});
		this.filterHelper.workticketSearchPage ? this.pageReturn = 'workticketPage' : 'selection';
	}

	hideFilter(): void {
		this.closeFilter.emit();
	}

	showPage(pageToShow): void {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		this.navigate.emit(pageToShow);
	}

	applyFilter(vendor: string): void {
		this.filterHelper.setFilter(vendor, this.filterHelper.vendor, 'VendorFilter');
	}

	getCurrentVendor(vendor: string): boolean {
		if (this.filterHelper.vendor.find(x => x === vendor)) return true;
	}

	resetFilter(): void {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		this.filterHelper.vendor = [];
		this.filterHelper.applyFilters();
	}
}
