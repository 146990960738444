import { Pipe, PipeTransform } from '@angular/core';
import { AdminFunctionalArea } from '../models/admin-functional-area';

@Pipe({
	name: 'showHideSearch',
})
export class ShowHideSearchPipe implements PipeTransform {
	transform(functionalAreas: Array<AdminFunctionalArea>, searchText: string): Array<AdminFunctionalArea> {
		if (!functionalAreas) return [];
		if (!searchText) return functionalAreas;

		searchText = searchText.toLowerCase().trim();
		const functionalAreasFoundByName: AdminFunctionalArea[] = [];
		const functionalAreasFoundByRelative: AdminFunctionalArea[] = [];

		functionalAreas.forEach(x => {
			if (x.name.toLowerCase().startsWith(searchText)) {
				functionalAreasFoundByName.push(x);
			} else if ((x.breadCrumbs.parent.toLowerCase().startsWith(searchText) || x.breadCrumbs.grandparent.toLowerCase().startsWith(searchText))) {
				functionalAreasFoundByRelative.push(x);
			}
		});

		return functionalAreasFoundByName.concat(functionalAreasFoundByRelative);
	}
}
