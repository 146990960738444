import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { FilterHelper } from 'src/app/helpers/filter-helper';
import { LineItemService } from 'src/app/services/line-item/line-item.service';
import { WorkTicketService } from 'src/app/services/work-ticket/work-ticket.service';

@Component({
  selector: 'occ-filter-part-group',
  templateUrl: './filter-part-group.component.html',
  styleUrls: ['./filter-part-group.component.scss'],
})
export class FilterPartGroupComponent {
	@Output() closeFilter = new EventEmitter();
	@Output() navigate = new EventEmitter();

	partGroup: string[];
	isWorkTicketSearchPage: false;
	pageReturn: string;

	subscribe = true;

	constructor(public lineItemSvc: LineItemService, public wktkSvc: WorkTicketService, public filterHelper: FilterHelper) {
		// this.lineItemSvc.categoryListSubject.takeWhile(() => this.subscribe).subscribe(partGroup => {
		// 	this.partGroup = partGroup.sort();

		// });
		if (this.filterHelper.workticketSearchPage) {
			this.pageReturn = 'workticketPage';
			this.wktkSvc.partGroupWorkSubject.pipe(takeWhile(() => this.subscribe)).subscribe(partGroup => {
				this.partGroup = partGroup.sort();
			});
		} else {
			this.lineItemSvc.locationListSubject.pipe(takeWhile(() => this.subscribe)).subscribe(partGroup => {
				this.partGroup = partGroup.sort();
			 });
		}
	}

	hideFilter(): void {
		this.closeFilter.emit();
	}

	showPage(pageToShow): void {
		this.navigate.emit(pageToShow);
	}

	applyFilter(partGroup: string): void {
		this.filterHelper.setFilter(partGroup, this.filterHelper.partGroup, 'PartGroupFilter');
	}

	getCurrentPartGroup(partGroup: string): boolean {
		if (this.filterHelper.partGroup.find(x => x === partGroup)) return true;
	}

	resetFilter(): void {
		this.filterHelper.partGroup = [];
		this.filterHelper.applyFilters();
	}
}
