
import { Injectable } from '@angular/core';
import { FilteredLineItemTimeline } from '../../models/work-ticket-timeline-filter-dto';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LineItemFilterServiceProvider {
	chosenFilters = new BehaviorSubject<FilteredLineItemTimeline[]>([]);
}
