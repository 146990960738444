import { Component, Input, OnInit } from '@angular/core';
import { OpsTimelineDTO } from 'src/app/models/opsTimeline-dto';
import { TimelineDTO } from 'src/app/models/timeline-dto';

@Component({
  selector: 'occ-timeline-item',
  templateUrl: './timeline-item.component.html',
  styleUrls: ['./timeline-item.component.scss'],
})
export class TimelineItemComponent implements OnInit {
  @Input() timelineItem: TimelineDTO;
  @Input() opsTimelineItem: OpsTimelineDTO;
  
  constructor() { }

  ngOnInit() {}

}
