import { Injectable } from '@angular/core';
// import { App } from 'ionic-angular';
import { Subscription, interval } from 'rxjs';
import { single } from 'rxjs/operators';
import { EventService } from '../event/event.service';
import { ShowAccountService } from '../show-account/show-account.service';
import { FunctionalAreaService } from '../functional-area/functional-area.service';
import { LineItemService } from '../line-item/line-item.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { ActivityService } from '../activity/activity.service';
import { AttachmentService } from '../attachment/attachment.service';
import { Platform } from '@ionic/angular';
import { FATabsEnum, LITabsEnum } from '../../helpers/tabs-enum';
import { FilterHelper } from '../../helpers/filter-helper';
import { TimelineService } from '../timeline/timeline.service';
import { VersionService } from '../version/version.service';
import { WorkTicketService } from '../work-ticket/work-ticket.service';
import { WorkticketLineItemService } from '../workticket-line-item/workticket-line-item.service';
import { SetOpsUserService } from '../set-ops-user/set-ops-user.service';
import { WorkTicketTimelineService } from '../work-ticket-timeline/work-ticket-timeline.service';

@Injectable({
  providedIn: 'root'
})
export class DataRefreshService {
	public subscription;
	public eventActive: string;
	public showAccountActive: string;
	public functionalAreaActive: string;
	public lineItemActive: string;
	public workTicketActive: string;
	public dashboardActive: boolean;
	public refresher: Subscription;
	public currentView: any;
	public onPause;
	public onResume;
	public interval = 30000;

	public pageIds: string[] = [
		'accounts',
		'events',
		'functional-area-details',
		'functional-areas',
		'line-item-details',
		'login',
		'dashboard',
		'search',
		'workticket-search',
		'timeline',
		'worktickets',
		'work-ticket-timeline',
		'workticket-details',
		'workticket-line-item-details'];

	constructor(
		// public appCtrl: App,
		public eventSvc: EventService,
		public accountSvc: ShowAccountService,
		public funcSvc: FunctionalAreaService,
		public itemSvc: LineItemService,
		public dashboardSvc: DashboardService,
		public activitySvc: ActivityService,
		public attachmentSvc: AttachmentService,
		public platform: Platform,
		public filterHelper: FilterHelper,
		public timelineSvc: TimelineService,
		public versionSvc: VersionService,
		public workTicSvc: WorkTicketService,
		public workTicLnItmSvc: WorkticketLineItemService,
		public setOpsUserSvc: SetOpsUserService,
		public workTikTimelineSvc: WorkTicketTimelineService,
	) {
		// this.subscription = this.appCtrl.viewDidEnter.subscribe(view => {
		// 	this.currentView = view;
		// 	this.updateActiveViews(view);
		// });
		this.onPause = this.platform.pause.subscribe(() => {
			this.unsubscribe();
		});
		this.onResume = this.platform.resume.subscribe(() => {
			this.updateActiveViews(this.currentView);
		});
	}

	public checkPage(view): boolean {
		const splitId: string[] = view.id.split('/');
		const parsedId = splitId[splitId.length - 1];
		return this.pageIds.indexOf(parsedId) !== -1;
	}

	public updateActiveViews(view) {
		// Don't update view if it's a popover
		if (!this.checkPage(view)) return;

		this.versionSvc.get();
		if (view.id !== 'login') {
			this.setOpsUserSvc.get();
		}

		this.unsubscribe();
		this.eventActive = view.data.eventId;
		this.showAccountActive = view.data.accountId;
		this.functionalAreaActive = view.data.functionalAreaId;
		this.lineItemActive = view.data.lineItemId;
		this.workTicketActive = view.data.workTicketId;
		this.dashboardActive = false;

		let refreshFn;

		switch (view.id) {

			case 'dashboard':
			case 'DashboardPage':
				refreshFn = this.dashboardRefresh.bind(this);
				this.dashboardActive = true;
				break;

			case 'events':
			case 'EventsPage':
				refreshFn = this.eventRefresh.bind(this);
				break;

			case 'accounts':
			case 'AccountsPage':
				refreshFn = this.accountRefresh.bind(this);
				break;

			case 'functional-areas':
			case 'FunctionalAreasPage':
				refreshFn = this.functionalAreaRefresh.bind(this);
				break;

			case 'functional-area-details':
			case 'FunctionalAreaDetailsPage':
				refreshFn = this.singleFunctionalAreaRefresh.bind(this);
				break;

			case 'line-item-details':
			case 'LineItemDetailsPage':
				refreshFn = this.singleLineItemRefresh.bind(this);
				break;

			case 'search':
			case 'SearchPage':
				refreshFn = this.searchRefresh.bind(this);
				break;

			case 'workTickets':
			case 'worktickets':
			case 'WorkTicketsPage':
				refreshFn = this.workTicketsRefresh.bind(this);
				break;

			case 'workticket-details':
			case 'WorkticketDetailsPage':
			case 'workticket-line-item-details':
			case 'occ-workticket-details-line-item':
			case 'WorkticketLineItemDetailsPage':
				refreshFn = this.workTicketDetailsRefresh.bind(this);
				break;

			case 'workticket-search':
			case 'WorkticketSearchPage':
				refreshFn = this.workTicketSearchRefresh.bind(this);
				break;

			case 'work-ticket-timeline':
			case 'WorkticketTimeline':
				refreshFn = this.workTicketSearchRefresh.bind(this);
				break;

			default:
				refreshFn = null;
				break;

		}

		this.subscribe(refreshFn);
	}

	public subscribe(refreshFn) {
		this.unsubscribe();

		if (refreshFn) {
			this.refresher = interval(this.interval).subscribe(val => {
				refreshFn.call();
			});
		}

	}

	public unsubscribe() {
		if (this.refresher) {
			this.refresher.unsubscribe();
			this.refresher = null;
		}
	}

	public eventRefresh(): void {
		if (!this.eventActive) {
			this.eventSvc.refresh();
		}
	}

	public accountRefresh(): void {
		if (this.eventActive) {
			if (!this.showAccountActive)
				this.accountSvc.refresh(this.eventActive);
		}
	}

	public functionalAreaRefresh(): void {
		if (!this.showAccountActive) {
			return;
		}
		this.funcSvc.refresh(this.eventActive, this.showAccountActive);
	}

	public workTicketsRefresh(): void {
		if (!this.workTicketActive) {
			return;
		}
		this.workTicSvc.refresh(this.eventActive);
	}

	public workTicketDetailsRefresh(): void {
		if (!this.workTicketActive) {
			return;
		}
		this.workTicLnItmSvc.refresh(this.eventActive, this.workTicketActive);
	}

	public workTicketSearchRefresh(): void {
		if (!this.workTicketActive) {
			return;
		}
		this.workTicLnItmSvc.refresh(this.eventActive, this.workTicketActive);
	}


	public workTicketTimelineRefresh(): void {
		if (!this.eventActive) {
			return;
		}
		this.workTikTimelineSvc.getTimeline(this.eventActive);
	}

	public dashboardRefresh(): void {
		if (this.dashboardActive) {
			this.dashboardSvc.refresh(this.eventActive, this.showAccountActive);
		}
	}

	public singleFunctionalAreaRefresh(): void {
		if (this.functionalAreaActive && !this.lineItemActive) {
			if (this.funcSvc.currentTab === FATabsEnum.LineItems) {
				this.itemSvc
					.refresh(this.eventActive, this.showAccountActive, this.functionalAreaActive)
					.pipe(single())
					.subscribe();
			}
			this.funcSvc.refreshDataForFunctionalArea(this.eventActive, this.showAccountActive, this.functionalAreaActive);
			this.activityRefresh(false);
			this.attachmentRefresh(false);
		}
	}

	public singleLineItemRefresh(): void {
		if (this.lineItemActive) {
			this.itemSvc.refreshDetails(this.eventActive, this.showAccountActive, this.functionalAreaActive, this.lineItemActive);
			this.activityRefresh(true);
			this.attachmentRefresh(true);
		}
	}

	public searchRefresh(): void {
		if (this.eventActive && this.showAccountActive) {
			if (this.filterHelper.faFilterCount > 0 && this.filterHelper.liFilterCount === 0) {
				this.functionalAreaRefresh();
			} else if (this.filterHelper.liFilterCount > 0 && this.filterHelper.faFilterCount === 0) {
				this.lineItemRefresh();
			} else {
				this.functionalAreaRefresh();
				this.lineItemRefresh();
			}
		}
	}

	public lineItemRefresh(): void {
		if (!this.eventActive || !this.showAccountActive) return;
		this.itemSvc.searchLineItems(this.eventActive, this.showAccountActive, '').subscribe( lineItems => {
			this.itemSvc.allLineItemsSubject.next(lineItems);
		});
	}

	public activityRefresh(lineItem: boolean): void {
		if (lineItem) {
			if (this.itemSvc.currentTab === LITabsEnum.Activity) {
				this.activitySvc.refresh(this.eventActive, this.showAccountActive, this.functionalAreaActive, this.lineItemActive);
			}
		} else {
			if (this.funcSvc.currentTab === FATabsEnum.Activity || this.funcSvc.currentTab === FATabsEnum.Details) {
				this.activitySvc.refresh(this.eventActive, this.showAccountActive, this.functionalAreaActive, null);
			}
		}
	}

	public attachmentRefresh(lineItem: boolean): void {
		if (lineItem) {
			if (this.itemSvc.currentTab === LITabsEnum.Files) {
				this.attachmentSvc.refresh(this.eventActive, this.showAccountActive, this.functionalAreaActive, this.lineItemActive);
			}
		} else {
			if (this.funcSvc.currentTab === FATabsEnum.Attachments) {
				this.attachmentSvc.refresh(this.eventActive, this.showAccountActive, this.functionalAreaActive, null);
			}
		}
	}

}
