import { Component, OnInit } from '@angular/core';
import { KioskSessionService } from './../../services/kiosk/kiosk-session.service';

@Component({
  selector: 'occ-kiosk-session-modal',
  templateUrl: './kiosk-session-modal.component.html',
  styleUrls: ['./kiosk-session-modal.component.scss'],
})
export class KioskSessionModalComponent implements OnInit {

  percentValue: any = 10;
  circleSize: any = 156;
  strokeWidth: any = 6;
  countdownFontSize: any = '4rem';
  secondsTextFontSize: any = '0.9rem';

  constructor(
    public kioskSessionService: KioskSessionService
  ) { }

  ngOnInit() {
    this.setStylesForCountdownCircle();
  }

  ngAfterViewInit() {
    this.initializeCounter();
  }

  setStylesForCountdownCircle() {
    if (this.kioskSessionService.platformWidth > 2700) {
      this.circleSize = 350;
      this.strokeWidth = 15;
      this.countdownFontSize = '8.5rem';
      this.secondsTextFontSize = '2rem';
    }
  }

  closeModal() {
    this.kioskSessionService.closeSessionPopup();
  }

  getGradientValue() {
    return 'linear-gradient(180deg, #ed1c73 ' + (2 + (3.5 * this.kioskSessionService.countdownTime)) + '%, white )';
  }

  initializeCounter() {
    let percent = 0;

    var circle = document.querySelector('.progress-circle');
    var radius = circle['r'].baseVal.value;
    var circumference = radius * 2 * Math.PI;

    circle['style'].strokeDasharray = `${circumference} ${circumference}`;
    circle['style'].strokeDashoffset = `${circumference}`;

    let setProgress = () => {
      percent += (100 / this.kioskSessionService.countDownExpiryTime);
      const offset = circumference - percent / 100 * circumference;
      circle['style'].strokeDashoffset = '' + offset;
    };

    setInterval(setProgress, 1000);
  }

}
