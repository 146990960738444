import { SelectHelper } from './select-helper';
import { LoadingController, ModalController, Platform } from '@ionic/angular';
import { FileExtensionHelper } from './file-extension-helper';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { environment } from '../../environments/environment';
import { InAppBrowser, InAppBrowserObject, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { DataRefreshService } from '../services/data-refresh/data-refresh.service';
import { FunctionalAreaService } from '../services/functional-area/functional-area.service';
import { LineItemService } from '../services/line-item/line-item.service';
import { AuthTokenHelper } from './auth-token-helper';
import { AttachmentService } from '../services/attachment/attachment.service';
import { Injectable } from '@angular/core';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { FileDisplayComponent } from '../components/file-display/file-display.component';
//import { BoothServiceProvider } from '../services/booth/booth.service';

@Injectable({
	providedIn: 'root'
})
export class FileDisplayHelper {

	occAttachmentType: string;
	loading: any;
	fileModal: any;
	browser: InAppBrowserObject;
	baseUrl = environment.fileBaseUrl();

	constructor(
		public platform: Platform,
		private fileHelper: FileExtensionHelper,
		private fileOpener: FileOpener,
		public dataSvc: DataRefreshService,
		public funcSvc: FunctionalAreaService,
		public lineItemSvc: LineItemService,
		public modalCtrl: ModalController,
		private authTokenHelper: AuthTokenHelper,
		public inAppBrowser: InAppBrowser,
		public loadCtrl: LoadingController,
		public attachmentSvc: AttachmentService,
		public ga: GoogleAnalytics,
		//public boothService: BoothServiceProvider,
	) { }


	showFile(name: string, fileId: string) {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		this.ga.trackEvent('FileAccessed', 'FileAccessed');
		console.log('file accessed');
		if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
			this.handleFileForBrowser(name, fileId);
		} else {
			this.getAndDisplayFile(name, fileId);
		}
	}

	handleFileForBrowser(name: string, fileId: string) {
		const isPdf = FileExtensionHelper.isPdf(name);
		if (isPdf) {
			this.getAndDisplayPdfBrowser(name, fileId);
		} else {
			this.displayFileInHubDocumentViewer(name, fileId);
		}
	}

	getAndDisplayFile(name: string, fileId: string) {
		// this.fileHelper.checkFile(fileId, name)
		// 	.then((fileExists) => {
		// 		const mimeType = this.fileHelper.getMimeType(name);

		// 		if (fileExists) {
		// 			const fileUrl = this.fileHelper.getFileUrl(fileId, name);
		// 			console.log('getAndDisplayPdf - File exists ' + fileUrl);
		// 			this.fileOpener.open(fileUrl, mimeType)
		// 				.then((params) => {
		// 					console.log(params);
		// 					console.log('successfully opened file');
		// 				})
		// 				.catch((err) => console.log(err));
		// 		} else {
		// 			const mimeType = this.fileHelper.getMimeType(name);
		// 			this.downloadFile(fileId, name).then((fileUrl) => {

		// 				if (this.loading && this.loading.dismiss) {
		// 					this.loading.dismiss();
		// 				}
		// 				this.fileOpener.open(fileUrl, mimeType)
		// 					.then((params) => {
		// 						console.log(params);
		// 						console.log('successfully opened file');
		// 					})
		// 					.catch((err) => console.log(err));
		// 			});
		// 		}
		// 	})
		// 	.catch((err) => { console.log('There was an error attempting to access file storage.', JSON.stringify(err)); });
		const mimeType = this.fileHelper.getMimeType(name);
		this.downloadFile(fileId, name).then((fileUrl) => {

			if (this.loading && this.loading.dismiss) {
				this.loading.dismiss();
			}
			this.fileOpener.open(fileUrl, mimeType)
				.then((params) => {
					console.log(params);
					console.log('successfully opened file');
				})
				.catch((err) => console.log(err));
		});
	}

	async getAndDisplayPdfBrowser(name: string, fileId: string) {
		const options = {
			cssClass: 'file-modal-wrapper'
		};
		let pdfFileUrl;
		switch (this.occAttachmentType) {
			case 'lineItem':
				pdfFileUrl = `${environment.apiUrl()}events/${this.dataSvc.eventActive}/accounts/${this.dataSvc.showAccountActive}/functional-areas/${this.funcSvc.activeFunctionalAreaId.getValue()}/line-items/${this.lineItemSvc.currentLineItem.getValue().kafkaId}/files/${fileId}`;
				break;
			case 'boothLineItem':
				// pdfFileUrl = `${environment.apiUrl()}events/${this.dataSvc.eventActive}/accounts/${this.boothService.activeBooth.getValue().showAccountGUID}/functional-areas/${this.boothService.emptyFAGUID}/line-items/${this.boothService.activeBoothLineItem.getValue().kafkaId}/files/${fileId}`;
				break;
			case 'functionalArea':
				pdfFileUrl = `${environment.apiUrl()}events/${this.dataSvc.eventActive}/accounts/${this.dataSvc.showAccountActive}/functional-areas/${this.funcSvc.activeFunctionalAreaId.getValue()}/files/${fileId}`;
				break;
			case 'workTicket':
				pdfFileUrl = `${environment.apiUrl()}events/${this.dataSvc.eventActive}/accounts/${this.dataSvc.showAccountActive}/functional-areas/${this.funcSvc.activeFunctionalAreaId.getValue()}/files/${fileId}`;
				break;
		}

		this.fileModal = await this.modalCtrl.create({
			component: FileDisplayComponent,
			cssClass: 'file-viewer-modal',
            componentProps: { 'fileUrl': pdfFileUrl, 'name': name, 'isPdf': true }
		});
		await this.fileModal.present();
	}

	displayFileInHubDocumentViewer(name: string, fileId: string) {
		// since the document id property is called 'fileId' on functional area attachments and 'passportFileId' on line item files, the document id is being set dynamically depending on which parameter comes in
		const documentId = fileId;
		const fileName = name;
		//  Security key to allow file view on android and ie11
		const securityKey = 'AA64D121-F079-4BE2-9B40-891A4C03EFC8';

		this.authTokenHelper.GetAccessToken().then(async token => {
			const fileUrl = `${this.baseUrl}${documentId}&filename=${fileName}&t=${token}&s=${securityKey}`;

			const options = {
				cssClass: 'file-modal-wrapper'
			};

			if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
				let pdfFileUrl;
				switch (this.occAttachmentType) {
					case 'lineItem':
						pdfFileUrl = `${environment.apiUrl()}events/${this.dataSvc.eventActive}/accounts/${this.dataSvc.showAccountActive}/functional-areas/${this.funcSvc.activeFunctionalAreaId.getValue()}/line-items/${this.lineItemSvc.currentLineItem.getValue().kafkaId}/files/${fileId}`;
						break;
					case 'boothLineItem':
						// pdfFileUrl = `${environment.apiUrl()}events/${this.dataSvc.eventActive}/accounts/${this.boothService.activeBooth.getValue().showAccountGUID}/functional-areas/${this.boothService.emptyFAGUID}/line-items/${this.boothService.activeBoothLineItem.getValue().kafkaId}/files/${fileId}`;
						break;
					case 'functionalArea':
						pdfFileUrl = `${environment.apiUrl()}events/${this.dataSvc.eventActive}/accounts/${this.dataSvc.showAccountActive}/functional-areas/${this.funcSvc.activeFunctionalAreaId.getValue()}/files/${fileId}`;
						break;
					case 'workTicket':
						pdfFileUrl = `${environment.apiUrl()}events/${this.dataSvc.eventActive}/accounts/${this.dataSvc.showAccountActive}/functional-areas/${this.funcSvc.activeFunctionalAreaId.getValue()}/files/${fileId}`;
						break;
				}
				this.loading = await this.loadCtrl.create({
					spinner: 'bubbles',
					message: 'Donwloading file..',
					showBackdrop: false,
				});
				this.loading.present();
				this.funcSvc.getfile(pdfFileUrl, name, this.loading);

			} else {
				const iabOptions: InAppBrowserOptions = {
					presentationstyle: 'pagesheet',
					toolbarposition: 'top'
				};
				this.browser = this.inAppBrowser.create(fileUrl, '_self', iabOptions);
			}
		});
	}

	async downloadFile(fileId: string, fileName: string): Promise<any> {
		this.loading = await this.loadCtrl.create({
			spinner: 'bubbles',
			message: 'Downloading file..',
		});
		await this.loading.present();
		if (this.occAttachmentType === 'lineItem') {
			return this.attachmentSvc.downloadLineItemAttachment(
				this.dataSvc.eventActive,
				this.dataSvc.showAccountActive,
				this.funcSvc.activeFunctionalAreaId.getValue(),
				this.lineItemSvc.currentLineItem.getValue().kafkaId,
				fileId,
				fileName);
		} else {

			return this.attachmentSvc.downloadFunctionalAreaFile(
				this.dataSvc.eventActive,
				this.dataSvc.showAccountActive,
				this.funcSvc.activeFunctionalAreaId.getValue(),
				fileId,
				fileName);
		}

	}
}
