import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonAccordionGroup, ModalController } from '@ionic/angular';
import { takeWhile } from 'rxjs/operators';
import { ExhibitorAccountDto } from 'src/app/models/exhibitor-account-dto';
import { ExhibitorAccountDetailsService } from 'src/app/services/exhibitor-account-details/exhibitor-account-details.service';

@Component({
  selector: 'occ-exhibit-account-details',
  templateUrl: './exhibit-account-details.component.html',
  styleUrls: ['./exhibit-account-details.component.scss'],
})
export class ExhibitAccountDetailsComponent implements OnInit {
  @ViewChild(IonAccordionGroup, { static: true }) accordionGroup: IonAccordionGroup;
  selectedOption: any = '';
  subscribe = true;
  exhibitAccountDtls:ExhibitorAccountDto;
  @Input() exhibitorAccountGuid: any;
  @Input() showGuid: any;
  @Input() accountId: any;
  booths :any;
  constructor( private modalCtrl: ModalController,
    private exhibitorAccountDetailsSvc : ExhibitorAccountDetailsService,
    private router: Router) { 
    }
    
    ngOnInit() {
      this.exhibitorAccountDetailsSvc.getExhibitAccountDetails(this.showGuid,this.accountId);   
      this.exhibitorAccountDetailsSvc.exhibitorAccountDetails.pipe(takeWhile(() => this.subscribe)).subscribe(exhibitAccount => {
        this.exhibitAccountDtls = exhibitAccount;
      });
      setTimeout(() => {
        this.selectedOption = 'Contact';
      }, 300);

  }

  pushToBoothDetailsPage(boothGUID) {
    this.closeFilterModal();
    setTimeout(() => {
      this.router.navigateByUrl('events/' + this.showGuid + '/es-booths/' + boothGUID + '/exhibitor-booth-details');
    }, 500);
  }

  closeFilterModal() {
    this.modalCtrl.dismiss({
    });
  }

  toggleAccordion(event) {
    this.selectedOption = this.accordionGroup.value;
  }

  ngOnDestroy(): void {
    this.subscribe = false;
  }
}

