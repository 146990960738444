import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';
import { AuthTokenHelper } from 'src/app/helpers/auth-token-helper';
import { environment } from 'src/environments/environment';
import { ToastService } from '../toast/toast.service';
import * as moment from 'moment';
import { Credentials } from 'src/app/models/credentials';
import { UserDTO } from 'src/app/models/user/user-dto';
import { SelectHelper } from 'src/app/helpers/select-helper';
import { map } from 'rxjs/operators';

import { Storage } from '@ionic/storage-angular';
import { LoadingController, ToastController } from '@ionic/angular';
import { ErrorLogService } from '../error-log/error-log.service';
import { UserActivityService } from '../user/user-activity.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  baseUrl = environment.loginUrl();
  token: any;
  idToken: any;

	constructor(
		public http: HttpClient,
		public storage: Storage,
		public toastService: ToastService,
		private appBrowserHelper: AppBrowserHelper,
		private tokenHelper: AuthTokenHelper,
    public activeRoute: ActivatedRoute,
    public router: Router,
	public toastController: ToastController,
	public loadingCtrl: LoadingController,
	private errorLogSvc: ErrorLogService,
	private userActivity: UserActivityService
	) {
	}

	async isLoggedIn(): Promise<boolean> {
		if (await this.tokenHelper.isTokenValid()) {
			this.tokenHelper.SetCurrentUser();
			return Promise.resolve(true);
		} else {
			return await this.routeToLogin() ? Promise.resolve(true) : Promise.resolve(false);
		}
	}

	async routeToLogin(): Promise<boolean> {
		if (this.tokenHelper.isRefreshTokenValid()) {
			const refreshToken = await this.tokenHelper.GetRefreshToken();
			await this.refresh(refreshToken);
			if (this.appBrowserHelper.isApp) {
				const activeNav = this.activeRoute.snapshot.data;
				activeNav.push('login');
				return Promise.resolve(false);
			}
			return Promise.resolve(true);
		}

		if (window.location.hash.includes('#code')) {
			if (await this.parsePassportHash(window.location.hash)) {
				const redirectUrl = await this.storage.get('redirectUrl');
				window.location.hash = '';
				window.location.href = redirectUrl;
			}
			return Promise.resolve(false);
		}

		await this.loginWithPassport();
		return Promise.resolve(false);
	}

	async loginWithPassport(): Promise<void> {

		await this.storage.set('redirectUrl', window.location.href);

		// redirect to passport here
		const redirectUri = this.getRedirectUrl();
		const passportUrl = `${environment.identityBaseUrl()}identity/connect/authorize?
		response_type=code%20id_token%20token&client_id=BAMOCgMFBAUOBQkEBAwIDA2&
		redirect_uri=${redirectUri}&scope=openid%20profile%20email%20fileviewer&
		nonce=N0.99831757934241571508365748845&state=150836574884506527345081526681`;
		window.location.href = passportUrl;
		this.tokenHelper.SetCurrentUser();

		return Promise.resolve();
	}

	getRedirectUrl(): string {
		return window.location.origin + '/';
	}

	async parsePassportHash(hash: string): Promise<boolean> {
		const hashes = hash.split('&');
		const idToken = hashes.find(h => h.startsWith('id_token')).split('=')[1];
		const accessToken = hashes.find(h => h.startsWith('access_token')).split('=')[1];
		const expiresIn = hashes.find(h => h.startsWith('expires_in')).split('=')[1];

		const storagePromises = [
			this.storage.set('id_token', idToken),
			this.storage.set('token', accessToken),
			this.storage.set('token_expiration', moment.utc().add(expiresIn, 'seconds').toISOString())
		];
		await Promise.all(storagePromises);
		await this.ssoTokenRequest();
		return Promise.resolve(true);
	}

	async login(credentials: Credentials): Promise<void> {
		return this.http.post<UserDTO>(this.baseUrl + 'login', credentials).pipe(map(
			async data => {
				await this.setTokens(data, credentials.thirtyDay);
				setTimeout(() => {
					this.userActivity.captureEvent('login');
				}, 1000);
				this.tokenHelper.SetCurrentUser();
			}))
			.toPromise()
			.catch(error => {
				this.handleError(error);
				return Promise.reject('Error logging in');
			});
	}

	async loginWithQRCode(qRCodeToken: string): Promise<void> {
		return this.http.get<UserDTO>(this.baseUrl + `loginWithQRCode/${qRCodeToken}`).pipe(map(
			async data => {
				await this.setTokens(data, false);
				this.tokenHelper.SetCurrentUser();
				let sessionTimer = setInterval(() => {
					this.tokenHelper.isTokenValid().then((isValid) => {
						if(!isValid){
							this.tokenHelper.isRefreshTokenValid().then((isRefreshValid) => {
								if(!isRefreshValid)
								{
									this.logout();
									clearTimeout(sessionTimer);
								}
							})
						}
					})
				}, 1000);

			}))
			.toPromise()
			.catch(error => {
				this.handleError(error);
				return Promise.reject('Error logging in');
			});
	}


	async refresh(refreshToken): Promise<string> {
		const loading = await this.loadingCtrl.create({
			message: '<p>Please wait...</p>',
		});
		if (this.router.url == '/login') {
			await loading.present();
		}
		const data = await this.http.post<UserDTO>(this.baseUrl + 'refreshlogin', { token: refreshToken }).toPromise();
		await this.setTokens(data, false);
		return Promise.resolve(data.occToken);
	}

	async logout(page? : string) {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		await this.userActivity.captureEvent('logout');
		const idToken = await this.storage.get('id_token');
		await this.storage.clear();
		this.tokenHelper.isUserLoggedIn.next(false);
		this.errorLogSvc.clear();
		this.token = null;
		this.idToken = null;
		this.userActivity.sessionId = null;
		if(page != 'self-checkin') {
			this.router.navigate(['login'], { replaceUrl: true });
		}
	}

	async ssoTokenRequest(): Promise<void> {
		// Freeman token needs to be present in the header of the sso call.
		return this.http.get<UserDTO>(this.baseUrl + 'sso').pipe(map(async data => {
			await this.setTokens(data, false);
		})).toPromise();
	}

	private async setTokens(data: UserDTO, setRefresh: boolean): Promise<void> {
		const tokenExpiration = moment.utc().add(45, 'minutes');
		const refreshTokenExpiration = moment.utc().add(30, 'days');
		const storagePromises = [
			this.storage.set('token', data.identityToken.accessToken),
			this.storage.set('token_expiration', tokenExpiration.toISOString()),
			this.storage.set('occ_token', data.occToken),
			this.storage.set('azure_uri', data.azureToken.photosUri),
			this.storage.set('azure_token', data.azureToken.accessToken),
			this.storage.set('azure_ad_token', data.azureAdToken)
		];

		this.token = data.occToken;
		this.idToken = data.identityToken.accessToken;

		this.tokenHelper.isUserLoggedIn.next(true);
		if (setRefresh) {
			let refreshtoken = data.identityToken.refresh_token != undefined ? data.identityToken.refresh_token : data.identityToken.refreshToken;
			storagePromises.push(this.storage.set('refresh_token', refreshtoken));
			storagePromises.push(this.storage.set('refresh_token_expiration', refreshTokenExpiration.toISOString()));
		}

		await Promise.all(storagePromises);
		this.tokenHelper.SetCurrentUser();

		return Promise.resolve();
	}

	private handleError(error) {
		switch (error.status) {
			case 400:
				this.presentErrorToast('Invalid username or password.');
				break;
			case 408:
				this.presentErrorToast('Apologies, the server timed out. Please contact an administrator.');
				break;
			case 401:
			case 403: {
				// Unauthorized
				break;
			}
			default:
				this.presentErrorToast('Apologies, there seems to be a problem on our end.');
				break;
		}
	}

	async presentErrorToast(msg: string) {
		const toast = await this.toastController.create({
		  message: msg,
		  duration: 2000,
		  position: 'top',
		  color: 'danger',
		  buttons: [
			{
			  text: 'X',
			  role: 'cancel',
			  handler: () => {
				console.log('Cancel clicked');
			  }
			}
		  ]
		});
		toast.present();
	}
}
