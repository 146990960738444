import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { KioskSessionService } from 'src/app/services/kiosk/kiosk-session.service';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'occ-kiosk-logout-confirmation',
  templateUrl: './kiosk-logout-confirmation.component.html',
  styleUrls: ['./kiosk-logout-confirmation.component.scss'],
})
export class KioskLogoutConfirmationComponent implements OnInit {
  accountName: string;
  @Input() isReturnToStart: boolean = false;
  constructor(public modalCtrl:ModalController,
    private router: Router,
    public kioskSessionService: KioskSessionService, private storage :Storage) { }

  ngOnInit() {
    this.storage.get('kiosk-account-info').then((accountData) => {
      if (accountData != null){
        let accountInfo = JSON.parse(accountData);
        this.accountName = accountInfo.accountName;
      }
    });
  }

  ionViewWillEnter(){
    
  }

  dismiss(){
    this.modalCtrl.dismiss();
  }

  authenticationPopup()
  {
    this.modalCtrl.dismiss().then(()  => {
      this.kioskSessionService.clearKioskDetails();
    });
  }

}
