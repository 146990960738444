import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IonAccordionGroup, LoadingController, ModalController } from '@ionic/angular';
import { ShowBooth } from 'src/app/models/show-booth-dto';
import { SearchFilterPipe } from 'src/app/pipes/search-filter.pipe';
import { NewServiceRequestService } from 'src/app/services/new-service-request/new-service-request.service';
import * as _ from 'lodash';
import { NewServiceRequest } from 'src/app/models/new-service-request-dto';

@Component({
  selector: 'occ-add-service-request',
  templateUrl: './add-service-request.component.html',
  styleUrls: ['./add-service-request.component.scss'],
})
export class AddServiceRequestComponent implements OnInit {
  @ViewChild(IonAccordionGroup, { static: true }) accordionGroup: IonAccordionGroup;
  @Input() eventGUID: string;
  @Input() accountGUID: any;
  @Input() boothGUID: any;
  @Input() serviceRequestData: any;
  selectedOption: any = '';
  filterAccounts: any;
  filterBooth: any;
  accountSearchParam: string = '';
  boothSearchParam: string = '';
  accountsList: any[] = [];
  boothsList: any[] = [];
  serviceTypeData: any[] = [];
  accountsSearchFilteredData: any[] = [];
  boothsSearchFilteredData: any[] = [];
  currentSelectedAccount: any = null;
  currentSelectedBooth: ShowBooth;
  currentSelectedServiceType: any;
  currentSelectedServiceSubType: any;
  isNotify: boolean = false;
  isAccountInfoSaved: boolean = false;
  isAdditionalRequest: boolean = true;
  requestDescription: string = '';
  accountForm: FormGroup;
  contactInfoUpdated: any;
  notifyStatus: any;
  isBoothavailable: any = null;
  constructor(
    private modalCtrl: ModalController,
    public newSRSvc: NewServiceRequestService,
    public loadingCtrl: LoadingController
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.selectedOption = 'accountInfo';
    }, 300);
    this.submitServiceRequest = _.debounce(this.submitServiceRequest, 1000)
    this.newSRSvc.allNewRequestData = {};
    this.newSRSvc.serviceIssueGUID = null;
    this.newSRSvc.getAccounts(this.eventGUID).subscribe((accountsData) => {
      this.accountsList = accountsData;
      if (this.accountGUID) {
        let account = this.accountsList.find(x => x.kafkaId == this.accountGUID);
        if (account)
          this.currentSelectedAccount = account;
      }
    });
    this.newSRSvc.getBoothsList(this.eventGUID).subscribe((boothsData) => {
      this.boothsList = boothsData;
      if (this.boothGUID) {
        let booth = this.boothsList.find(x => x.boothGUID == this.boothGUID);
        if (booth) {
          this.currentSelectedBooth = booth;
          this.boothsSearchFilteredData.push(this.currentSelectedBooth);
          this.selectedOption = 'booth-details';
        }
        else{
          this.isBoothavailable = "N";
        }
      }
    });

    this.newSRSvc.getServiceTypesData(this.eventGUID).subscribe((serviceTypeData) => {
      serviceTypeData.forEach(item => {
        if (!item.parentKafkaId) {
          item.subTypeValues = _.sortBy(_.filter(serviceTypeData, ["parentKafkaId", item.kafkaId]), ['type']);
          this.serviceTypeData.push(item);
        }
      });
      this.serviceTypeData = _.sortBy(this.serviceTypeData, ['type']);
      if (this.serviceRequestData && this.serviceRequestData.requestType) {
        let requestType = this.serviceTypeData.find(x => x.type == this.serviceRequestData.requestType);
        if (requestType) {
          this.currentSelectedServiceType = requestType;
          if (this.serviceRequestData.subType) {
            let subType = requestType.subTypeValues.find(x => x.type == this.serviceRequestData.subType);
            if (subType) {
              this.currentSelectedServiceSubType = subType;
            }
          }
        }
      }
    });
    
    if (this.serviceRequestData) {
      this.requestDescription = this.serviceRequestData.issueText;
      this.notifyStatus = this.serviceRequestData.notificationInd == 'Y' ? true : false;
      this.isNotify = JSON.parse(JSON.stringify(this.notifyStatus));
      this.initializeAccountForm(this.serviceRequestData);
    } else {
      this.initializeAccountForm();
    }

    this.getSearchResult('accounts');

  }

  initializeAccountForm(data = null) {
    this.accountForm = new FormGroup({
      'contactName': new FormControl(data && data.contactName ? data.contactName : '', this.isNotify ? [Validators.required, Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/)] : [Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/)]),
      'mobileNumber': new FormControl(data && data.contactPhone ? data.contactPhone : '', this.isNotify ? [Validators.required, Validators.minLength(12)] : [Validators.minLength(12)]),
      'emailAddress': new FormControl(data && data.contactEmailAddress ? data.contactEmailAddress : '', this.isNotify ? [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)] : [Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])
    });
    setTimeout(() => {
      if (this.serviceRequestData) {
        this.accountForm.valueChanges.subscribe(data => {
          if (data) {
            this.contactInfoUpdated = true;
          }
        })
      }
    }, 1000);
  }

  toggleAccordion(event) {
    if (!this.serviceRequestData) {
      if ((this.accordionGroup.value == "booth-details" && !this.currentSelectedAccount) || (this.accordionGroup.value == "requestDetails" && !this.isAccountInfoSaved)) {
        this.accordionGroup.value = this.selectedOption;
        return;
      }
    }
    this.selectedOption = this.accordionGroup.value;
  }

  getSearchResult(searchField: string) {
    switch (searchField) {
      case 'accounts':
        {
          this.accountsSearchFilteredData = new SearchFilterPipe().transform(this.accountsList, { type: 'service-request-account', searchParam: this.accountSearchParam });
          break;
        }
      case 'booths':
        {
          this.boothsSearchFilteredData = new SearchFilterPipe().transform(this.boothsList, { type: 'service-request-booths', showAccountGUID: this.boothSearchParam });
          break;
        }

      default:
        break;
    }
  }

  clearSearch(searchField: string) {
    switch (searchField) {
      case 'accounts':
        {
          this.accountSearchParam = "";
          this.getSearchResult('accounts');
          break;
        }
      case 'booths':
        {
          this.boothSearchParam = "";
          this.getSearchResult('booths');
          break;
        }

      default:
        break;
    }
  }

  setSelection(currentSelectedItem, searchField) {
    switch (searchField) {
      case 'accounts':
        {
          this.accountSearchParam = "";
          this.getSearchResult('accounts');
          this.currentSelectedAccount = currentSelectedItem;
          this.boothSearchParam = this.currentSelectedAccount.kafkaId;
          this.getSearchResult('booths');
          this.selectedOption = this.boothsSearchFilteredData.length > 0 ? 'booth-details': this.selectedOption;
          this.isBoothavailable = this.boothsSearchFilteredData.length > 0 ? 'Y' : 'N'
          break;
        }
      case 'booths':
        {
          this.currentSelectedBooth = currentSelectedItem;
          break;
        }

      case 'service_type':
        {
          this.currentSelectedServiceType = currentSelectedItem;
          break;
        }

      case 'service_subtype':
        {
          this.currentSelectedServiceSubType = currentSelectedItem;
          break;
        }

      default:
        break;
    }
  }

  clearSelection(searchField: string) {
    switch (searchField) {
      case 'accounts':
        {
          this.currentSelectedAccount = null;
          this.currentSelectedBooth = null;
          this.isBoothavailable = null;
          break;
        }

      case 'booths':
        {
          this.currentSelectedBooth = null;
          break;
        }

      case 'service_type':
        {
          this.currentSelectedServiceType = null;
          this.currentSelectedServiceSubType = null;
          break;
        }

      case 'service_subtype':
        {
          this.currentSelectedServiceSubType = null;
          break;
        }

      default:
        break;
    }
  }

  changeNotifyStatus(isNotifyEnabled: boolean) {
    if (this.serviceRequestData && isNotifyEnabled != this.notifyStatus)
      this.contactInfoUpdated = true;
    this.isNotify = isNotifyEnabled;
    if (this.isNotify) {
      this.accountForm.controls.contactName.setValidators([Validators.required, Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/)]);
      this.accountForm.controls.mobileNumber.setValidators([Validators.required, Validators.minLength(12)]);
      this.accountForm.controls.emailAddress.setValidators([Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]);
    } else {
      this.accountForm.controls.contactName.setValidators([Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/)]);
      this.accountForm.controls.mobileNumber.setValidators([Validators.minLength(12)]);
      this.accountForm.controls.emailAddress.setValidators([Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]);
    }
    this.accountForm.controls.contactName.updateValueAndValidity();
    this.accountForm.controls.mobileNumber.updateValueAndValidity();
    this.accountForm.controls.emailAddress.updateValueAndValidity();
  }

  saveAccountDetails() {
    if (Object.keys(this.newSRSvc.allNewRequestData).length) {
      this.newSRSvc.allNewRequestData.accountName = this.currentSelectedAccount.name;
      this.newSRSvc.allNewRequestData.isNotifyExhibitUser = this.isNotify;
      this.newSRSvc.allNewRequestData.contactName = this.accountForm.controls.contactName.status == 'VALID' ? this.accountForm.controls.contactName.value : '';
      this.newSRSvc.allNewRequestData.mobileNumber = this.accountForm.controls.mobileNumber.status == 'VALID' ? this.accountForm.controls.mobileNumber.value : '';
      this.newSRSvc.allNewRequestData.emailAddress = this.accountForm.controls.emailAddress.status == 'VALID' ? this.accountForm.controls.emailAddress.value : '';
    } else {
      this.newSRSvc.allNewRequestData = {
        accountName: this.currentSelectedAccount.name,
        accoundGUID: this.currentSelectedAccount.kafkaId,
        isNotifyExhibitUser: this.isNotify,
        contactName: this.accountForm.controls.contactName.status == 'VALID' ? this.accountForm.controls.contactName.value : '',
        mobileNumber: this.accountForm.controls.mobileNumber.status == 'VALID' ? this.accountForm.controls.mobileNumber.value : '',
        emailAddress: this.accountForm.controls.emailAddress.status == 'VALID' ? this.accountForm.controls.emailAddress.value : '',
        serviceRequests: []
      };
    }

    this.selectedOption = 'requestDetails';
    this.isAccountInfoSaved = true;
  }

  setCountryCodeSelection(currentCountryCode: any) {

  }

  onPhoneNumberChange(event) {
    this.accountForm.controls.mobileNumber.markAsDirty();
    this.accountForm.patchValue({
      mobileNumber: event.phoneNumber
    });
    if (event.isNumberInvalid) {
      this.accountForm.controls.mobileNumber.setErrors({ pattern: true });
    }
  }

  getAccordianIcon(currentSection) {
    let isSectionCompleted = false;
    if ((currentSection == 'accountInfo' && this.currentSelectedAccount) || (currentSection == 'booth-details' && this.isAccountInfoSaved)) {
      isSectionCompleted = true;
    }

    if (isSectionCompleted) {
      if (this.selectedOption != currentSection) {
        return 'done'
      } else {
        return 'remove'
      }
    } else {
      if (this.selectedOption == currentSection) {
        return 'remove'
      } else {
        return 'add'
      }
    }
  }

  getSubmitVisibilityStatus() {
    return !(this.currentSelectedBooth && this.requestDescription && ((this.currentSelectedServiceType && !this.currentSelectedServiceType.subTypeValues.length) || (this.currentSelectedServiceType && this.currentSelectedServiceType.subTypeValues.length && this.currentSelectedServiceSubType)));
  }

  async submitServiceRequest() {
    let loading = await this.loadingCtrl.create({
      message: 'Submitting service request... '
    });
    await loading.present();
    let serviceRequestData = <NewServiceRequest>{};
    serviceRequestData.accountGuid = this.currentSelectedAccount.kafkaId;
    serviceRequestData.boothGuid = this.currentSelectedBooth.boothGUID;
    serviceRequestData.contactName = this.newSRSvc.allNewRequestData.contactName;
    serviceRequestData.phoneNo = this.newSRSvc.allNewRequestData.mobileNumber.replace(/-/g, '');
    serviceRequestData.emailAddress = this.newSRSvc.allNewRequestData.emailAddress;
    serviceRequestData.notifyServiceRequestInd = this.isNotify ? 'Y' : 'N';
    serviceRequestData.subTypeGuid = this.currentSelectedServiceSubType.kafkaId;
    serviceRequestData.typeKey = this.currentSelectedServiceType.ceKey;
    serviceRequestData.description = this.requestDescription;
    serviceRequestData.issueGuid = this.newSRSvc.serviceIssueGUID;

    this.newSRSvc.submitServiceRequest(this.eventGUID, serviceRequestData).subscribe((submitSRResponse) => {
      if (submitSRResponse) {
        this.newSRSvc.allNewRequestData.serviceRequests.push(submitSRResponse);
        this.selectedOption = '';
        this.isAdditionalRequest = false;
        this.isAccountInfoSaved = false;
        this.isNotify = false;
        this.initializeAccountForm();
      }
    });
  }

  async updateServiceRequest() {
    let loading = await this.loadingCtrl.create({
      message: 'Submitting service request... '
    });
    await loading.present();
    let requestObject = {
      issueGuid: this.serviceRequestData.issueGuid,
      issueItemGuid: this.serviceRequestData.issueItemGuid,
      notificationInd: this.isNotify ? 'Y' : 'N',
      contactName: this.accountForm.value.contactName,
      contactPhone: this.accountForm.value.mobileNumber,
      emailAddress: this.accountForm.value.emailAddress
    }

    this.newSRSvc.updateServiceRequest(this.eventGUID, requestObject).subscribe(res => {
      if (res)
        this.modalCtrl.dismiss({updated: true});
    });
  }

  closeServiceRequestModal() {
    this.newSRSvc.allNewRequestData = [];
    this.modalCtrl.dismiss();
  }

  getSubmittedSRDetails(srItem) {
    let serviceTypeData = _.find(this.serviceTypeData, ['ceKey', srItem.typeKey]);
    let serviceSubTypeData = _.find(serviceTypeData.subTypeValues, ['kafkaId', srItem.subTypeGuid])
    return serviceTypeData.type + "" + (serviceSubTypeData.type ? " - " + serviceSubTypeData.type : "");
  }

  getBoothName(srItem) {
    let boothData = _.find(this.boothsList, ['boothGUID', srItem.boothGuid]);
    return boothData.boothName ? boothData.boothName : "";
  }

  getSaveButtonDisableStatus() {
    if (!this.currentSelectedBooth) {
      return true;
    }

    if (this.isNotify) {
      if ((this.accountForm.controls.contactName.status == 'INVALID' || (this.accountForm.controls.mobileNumber.value && this.accountForm.controls.mobileNumber.invalid) || (this.accountForm.controls.emailAddress.value && this.accountForm.controls.emailAddress.invalid) || (this.accountForm.controls.mobileNumber.status == 'INVALID' && this.accountForm.controls.emailAddress.status == 'INVALID'))) {
        return true;
      } else {
        return false;
      }
    } else {
      if ((this.accountForm.controls.contactName.value && this.accountForm.controls.contactName.invalid) || (this.accountForm.controls.mobileNumber.value && this.accountForm.controls.mobileNumber.invalid) || (this.accountForm.controls.emailAddress.value && this.accountForm.controls.emailAddress.invalid)) {
        return true;
      } else {
        return false;
      }
    }
  }

  createAnotherServiceRequest() {
    this.currentSelectedBooth = null;
    this.currentSelectedServiceType = null;
    this.currentSelectedServiceSubType = null;
    this.requestDescription = '';
    this.isAdditionalRequest = true;
    this.selectedOption = "booth-details";
  }

  createNewServiceRequest() {
    this.selectedOption = 'accountInfo';
    this.accountSearchParam = '';
    this.accountsSearchFilteredData = [];
    this.currentSelectedAccount = null;
    this.currentSelectedBooth = null;
    this.currentSelectedServiceType = null;
    this.currentSelectedServiceSubType = null;
    this.isNotify = false;
    this.isAccountInfoSaved = false;
    this.isAdditionalRequest = true;
    this.requestDescription = '';
    this.newSRSvc.allNewRequestData = {};
    this.initializeAccountForm();
  }

  onDescriptionChange() {
    this.requestDescription = this.requestDescription.trim();
  }
}