import { LineItemService } from '../services/line-item/line-item.service';
import { FunctionalAreaService } from '../services/functional-area/functional-area.service';
import { WorkTicketService } from '../services/work-ticket/work-ticket.service';
import { Status } from '../models/status';
import { FunctionalArea } from '../models/functional-area';
import { CollaborationLineItemDTO } from '../models/collaboration-line-item-dto';
import { WorkTicketDTO } from '../models/work-ticket-dto';
import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import * as moment from 'moment';
import { SelectHelper } from './select-helper';
import { AuthTokenHelper } from './auth-token-helper';
import { SearchHelper } from './search-helper';
import { Owner } from '../models/owner';
import { BehaviorSubject } from 'rxjs';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { ChangeOrderDTO } from '../models/change-order-dto';

@Injectable()
export class FilterHelper {
	public eventId: string;
	public accountId: string;
	public workticketId: string;
	public functionalAreasEventId: string;
	public functionalAreasAccountId: string;
	public liCompletedDateFilter: string;
	public liStartDateFilter: string;
	public wtCompletedDateFilter: string;
	public wtStartDateFilter: string;
	public wtOrderDateFilter: string;

	public lineItemStatusSelected: boolean;
	public workTicketStatusSelected: boolean;
	public workticketSearchPageSubject = new BehaviorSubject<boolean>(false);
	public workticketSearchPage = false;

	// Functional Area Filters
	public functionalAreasPageStatusFilter: Status[] = [];
	public functionalAreasPageOwnerFilter: Owner[] = [];

	// Filters
	public functionalAreaStatus: Status[] = [];
	public functionalAreaOwner: Owner[] = [];
	public showAttachmentsOnlySubject = new BehaviorSubject<boolean>(false);
	public showAttachmentsOnly = false;
	public showLineItemAttachmentsOnlySubject = new BehaviorSubject<boolean>(false);
	public showLineItemAttachmentsOnly = false;
	public showItemsWithPhotosOnlySubject = new BehaviorSubject<boolean>(false);
	public showItemsWithPhotosOnly = false;
	public lineItemStatus: Status[] = [];
	public workTicketStatus: Status[] = [];
	public tssWorkTicketNumber: number[] = [];
	public vendor: string[] = [];
	public booth: string[] = [];
	public liLocation: string[] = [];
	public wtLocation: string[] = [];
	public category: string[] = [];
	public partGroup: string[] = [];
	public functionalArea: string[] = [];
	public facility: string[] = [];
	public liStartDateUpper: Moment;
	public liStartDateLower: Moment;
	public liCompletedDateUpper: Moment;
	public liCompletedDateLower: Moment;
	public wtOrderDateUpper: Moment;
	public wtOrderDateLower: Moment;
	public wtStartDateUpper: Moment;
	public wtStartDateLower: Moment;
	public wtCompletedDateUpper: Moment;
	public wtCompletedDateLower: Moment;
	public functionalAreasfilterCount  = 0;
	public faFilters: any;
	public liFilters: any;
	public wtFilters: any;
	public coFilters: any;
	public globalFilters: any;
	public liFilterCount: number;
	public faFilterCount: number;
	public wtFilterCount: number;
	public coFilterCount: number;
	public globalFilterCount: number;
	isSalesUser = false;

	// Lis/Fas/Wts are the unfiltered arrays. All filtered items go into the searchItems subject.
	public lineItems: CollaborationLineItemDTO[] = [];
	public functionalAreas: FunctionalArea[] = [];
	public workTickets: WorkTicketDTO[] = [];
	public changeOrders: ChangeOrderDTO[] = [];
	
	// Remember if filter list is opened or closed
	public globalFilterExpandedSubject = new BehaviorSubject<boolean>(false);
	public functionalAreaExpandedSubject = new BehaviorSubject<boolean>(false);
	public lineItemExpandedSubject = new BehaviorSubject<boolean>(false);
	public workticketExpandedSubject = new BehaviorSubject<boolean>(false);

	constructor(
		public funcSvc: FunctionalAreaService,
		public liSvc: LineItemService,
		public wktkSvc: WorkTicketService,
		public authHelper: AuthTokenHelper,
		public searchHelper: SearchHelper,
		public authTokenHelper: AuthTokenHelper,
		public ga: GoogleAnalytics,
		) {
		funcSvc.allFunctionalAreasSubject.subscribe(areas => {
			this.functionalAreas = areas;
			if (this.functionalAreas.length > 0 && this.liSvc.searchRequestComplete) {
				this.checkUserType();
				this.applyFilters();
			}
		});
		liSvc.allLineItemsSubject.subscribe(items => {
			this.lineItems = items;
			if (this.lineItems.length > 0 || this.liSvc.searchRequestComplete) {
				this.applyFilters();
			}
		});
		// if (!this.lineItems) {
			wktkSvc.allWorkTicketsSubject.subscribe(items => {
				this.workTickets = items;
				if (this.workTickets.length > 0 || this.wktkSvc.searchRequestComplete) {
					this.applyFilters();
				}
			});
		// }
		liSvc.allChangeOrdersSubject.subscribe(cos => {
			this.changeOrders = cos;
			if (this.changeOrders.length > 0 || this.liSvc.searchRequestComplete) {
				this.applyFilters();
			}
		});

		this.showAttachmentsOnlySubject.subscribe(showAttachments => {
			if (showAttachments) {
				this.ga.trackEvent('FilterApplied', 'AttachmentFilter');
			}
			this.showAttachmentsOnly = showAttachments;
		});

		this.showLineItemAttachmentsOnlySubject.subscribe(showAttachments => {
			if (showAttachments) {
				this.ga.trackEvent('FilterApplied', 'LineItemAttachmentFilter');
			}
			this.showLineItemAttachmentsOnly = showAttachments;
		});

		this.showItemsWithPhotosOnlySubject.subscribe(showItemsWithPhotos => {
			if (showItemsWithPhotos) {
				this.ga.trackEvent('FilterApplied', 'PhotoFilter');
			}
			this.showItemsWithPhotosOnly = showItemsWithPhotos;
		});

		this.workticketSearchPageSubject.subscribe(wtSearchPage => {
			this.workticketSearchPage = wtSearchPage;
		});
	}

	async checkUserType() {
		this.isSalesUser = await this.authTokenHelper.IsSalesUser();
	}

	public applyFilters(): void {
		let fas = this.searchHelper.searchFunctionalAreas(this.searchHelper.searchString);
		let lis = this.searchHelper.searchLineItems(this.searchHelper.searchString);
		let wts = this.searchHelper.searchWorkTickets(this.searchHelper.searchString);
		let cos = this.searchHelper.searchChangeOrders(this.searchHelper.searchString);

		lis = this.applyLineItemFilters(lis);
		fas = this.applyFunctionalAreaFilters(fas);
		wts = this.applyWorkTicketFilters(wts);
		cos = this.applyChangeOrderFilters(cos);

		this.countFilters();
		this.checkFilterExpansions();
		if (this.faFilterCount > 0 && this.liFilterCount === 0 && this.wtFilterCount === 0 && this.coFilterCount === 0) {
			this.searchHelper.createSearchItems(fas, null, null, cos);
		} else if (this.liFilterCount > 0 && this.faFilterCount === 0 && this.wtFilterCount === 0 && this.coFilterCount === 0) {
			this.searchHelper.createSearchItems(null, lis, null, cos);
		} else if (this.wtFilterCount > 0 && this.faFilterCount === 0 && this.liFilterCount === 0 && this.coFilterCount === 0) {
			this.searchHelper.createSearchItems(null, null, wts, null);
		} else if (this.wtFilterCount === 0 && this.faFilterCount === 0 && this.liFilterCount === 0 && this.coFilterCount > 0) {
			this.searchHelper.createSearchItems(null, null, null, cos);
		} else {
			this.searchHelper.createSearchItems(fas, lis, wts, cos);
		}
	}

	public setStatus(status: Status, islineItem?: boolean, isworkTicket?: boolean): void {
		if (this.lineItemStatusSelected || islineItem === true) {
			const filterActive = this.lineItemStatus.findIndex(x => x === status);
			if (filterActive !== -1) {
				this.lineItemStatus.splice(filterActive, 1);
			} else {
				this.ga.trackEvent('FilterApplied', 'LineItemStatusFilter');
				this.lineItemStatus.push(status);
			}
		} else if (this.workTicketStatusSelected || isworkTicket === true) {
			const filterActive = this.workTicketStatus.findIndex(x => x === status);
			if (filterActive !== -1) {
				this.workTicketStatus.splice(filterActive, 1);
			} else {
				this.ga.trackEvent('FilterApplied', 'WorkTicketStatusFilter');
				this.workTicketStatus.push(status);
			}
		} else {
			const filterActive = this.functionalAreaStatus.findIndex(x => x === status);
			if (filterActive !== -1) {
				this.functionalAreaStatus.splice(filterActive, 1);
			} else {
				this.ga.trackEvent('FilterApplied', 'FunctionalAreaStatusFilter');
				this.functionalAreaStatus.push(status);
			}
		}
	}

	public setVendor(vendor: string): void {
		if (this.vendor.indexOf(vendor) === -1) {
			this.vendor.push(vendor);
		}
	}

	public setPartGroup(partGroup: string): void {
		if (this.partGroup.indexOf(partGroup) === -1) {
			this.partGroup.push(partGroup);
		}
	}

	public setFunctionalArea(functionalArea: string): void {
		if (this.functionalArea.indexOf(functionalArea) === -1) {
			this.functionalArea.push(functionalArea);
		}
	}

	public setFilter(item, filter, filterType) {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		const filterActive = filter.findIndex(x => x === item);
		if (filterActive !== -1) {
			filter.splice(filterActive, 1);
		} else {
			this.ga.trackEvent('FilterApplied', filterType);
			filter.push(item);
		}
		this.applyFilters();
	}

	public resetAllFilters(): void {
		this.functionalAreaStatus = [];
		this.functionalAreaOwner = [];
		this.showAttachmentsOnlySubject.next(false);
		this.showLineItemAttachmentsOnlySubject.next(false);
		this.showItemsWithPhotosOnlySubject.next(false);
		this.lineItemStatus = [];
		this.vendor = [];
		this.booth = [];
		this.liLocation = [];
		this.liStartDateUpper = null;
		this.liStartDateLower = null;
		this.liCompletedDateUpper = null;
		this.liCompletedDateLower = null;
		this.wtLocation = [];
		this.workTicketStatus = [];
		this.wtOrderDateUpper = null;
		this.wtOrderDateLower = null;
		this.wtStartDateUpper = null;
		this.wtStartDateLower = null;
		this.wtCompletedDateUpper = null;
		this.wtCompletedDateLower = null;
		this.category = [];
		this.liStartDateFilter = null;
		this.liCompletedDateFilter = null;
		this.wtOrderDateFilter = null;
		this.wtStartDateFilter = null;
		this.wtCompletedDateFilter = null;
		this.partGroup = [];
		this.functionalArea = [];
		this.facility = [];
		this.applyFilters();
	}


	private applyLineItemFilters(lineItems: CollaborationLineItemDTO[]): CollaborationLineItemDTO[] {
		let lis = lineItems;
		lis = this.applyLineItemStatusFilter(lis);
		lis = this.applyLineItemVendorFilter(lis);
		lis = this.applyLineItemStartDateFilter(lis);
		lis = this.applyLineItemCompletedDateFilter(lis);
		lis = this.applyLineItemBoothFilter(lis);
		lis = this.applyLineItemLocationFilter(lis);
		lis = this.applyCategoryFilter(lis);
		lis = this.applyLineItemAttachmentFilter(lis);
		lis = this.applyLineItemOnlyAttachmentFilter(lis);
		lis = this.applyLineItemPhotoFilter(lis);
		lis = this.applyLineItemFacilityFilter(lis);
		return lis;
	}

	private applyFunctionalAreaFilters(functionalAreas: FunctionalArea[]): FunctionalArea[] {
		let fas = functionalAreas;
		fas = this.RemoveHiddenFunctionalAreas(fas);
		fas = this.applyFunctionalAreaOwnerFilter(fas);
		fas = this.applyFunctionalAreaStatusFilter(fas);
		fas = this.applyFunctionalAttachmentFilter(fas);
		fas = this.applyFunctionalAreaPhotoFilter(fas);
		return fas;
	}

	private applyWorkTicketFilters(workTickets: WorkTicketDTO[]): WorkTicketDTO[] {
		let wts = workTickets;
		wts = this.applyWorkTicketNumberFilter(wts);
		wts = this.applyWorkTicketStatusFilter(wts);
		wts = this.applyWorkTicketPartGroupFilter(wts);
		wts = this.applyWorkTicketLocationFilter(wts);
		wts = this.applyWorkTicketFunctionalAreaFilter(wts);
		wts = this.applyWorkTicketOrderDateFilter(wts);
		wts = this.applyWorkTicketStartDateFilter(wts);
		wts = this.applyWorkTicketCompletedDateFilter(wts);
		wts = this.applyWorkTicketPhotoFilter(wts);
		wts = this.applyWorkTicketFacilityFilter(wts);
		return wts;
	}

	private applyLineItemFacilityFilter(lineitems: CollaborationLineItemDTO[]): CollaborationLineItemDTO[] {
		if (!lineitems || this.facility.length === 0) {
			return lineitems;
		}
		return [].concat.apply([], this.facility.map(x => lineitems.filter(li => li.facility === x)));
	}

	private applyWorkTicketFacilityFilter(workTickets: WorkTicketDTO[]): WorkTicketDTO[] {
		if (!workTickets || this.facility.length === 0) {
			return workTickets;
		}
		return [].concat.apply([], this.facility.map(x => workTickets.filter(wt => wt.facility === x)));
	}

	private applyWorkTicketPhotoFilter(workTickets: WorkTicketDTO[]): WorkTicketDTO[] {
		if (!workTickets || !this.showItemsWithPhotosOnly) {
			return workTickets;
		}
		return workTickets.filter(wt => wt.hasPhotos);
	}

	private applyWorkTicketFunctionalAreaFilter(workTickets: WorkTicketDTO[]): WorkTicketDTO[] {
		if (!workTickets || this.functionalArea.length === 0) {
			return workTickets;
		}
		return [].concat.apply([], this.functionalArea.map(x => workTickets.filter(wt => wt.functionalAreaName === x)));
	}

	private applyWorkTicketPartGroupFilter(workTickets: WorkTicketDTO[]): WorkTicketDTO[] {
		if (!workTickets || this.partGroup.length === 0) {
			return workTickets;
		}
		return [].concat.apply([], this.partGroup.map(x => workTickets.filter(wt => wt.partGroup === x)));
	}

	private applyWorkTicketLocationFilter(workTickets: WorkTicketDTO[]): WorkTicketDTO[] {
		if (!workTickets || this.wtLocation.length === 0) {
			return workTickets;
		}
		return [].concat.apply([], this.wtLocation.map(x => workTickets.filter(wt => wt.location === x)));
	}

	private applyWorkTicketNumberFilter(workTickets: WorkTicketDTO[]): WorkTicketDTO[] {
		if (!workTickets || this.tssWorkTicketNumber.length === 0) {
			return workTickets;
		}
		return [].concat.apply([], this.tssWorkTicketNumber.map(x => workTickets.filter(li => li.tssWorkTicketNumber === x)));
	}

	private applyWorkTicketStatusFilter(workTickets: WorkTicketDTO[]): WorkTicketDTO[] {
		if (!workTickets || this.workTicketStatus.length === 0) {
			return workTickets;
		}
		return [].concat.apply([], this.workTicketStatus.map(x => workTickets.filter(wt => wt.currentStatus === x)));
	}

	private applyWorkTicketOrderDateFilter(workTickets: WorkTicketDTO[]): WorkTicketDTO[] {
		if (!workTickets || !this.wtOrderDateLower || !this.wtOrderDateUpper) {
			return workTickets;
		}
		let filteredWorkTickets: WorkTicketDTO[] = null;
		filteredWorkTickets = workTickets.filter(wt => this.dateIsBefore(wt.orderDate, this.wtOrderDateUpper) && this.dateIsAfter(wt.orderDate, this.wtOrderDateLower));
		return filteredWorkTickets;
	}

	private applyWorkTicketStartDateFilter(workTickets: WorkTicketDTO[]): WorkTicketDTO[] {
		if (!workTickets || !this.wtStartDateLower || !this.wtStartDateUpper) {
			return workTickets;
		}
		let filteredWorkTickets: WorkTicketDTO[] = null;
		filteredWorkTickets = workTickets.filter(wt => this.dateIsBefore(wt.setupCompleteDate, this.wtStartDateUpper) && this.dateIsAfter(wt.setupCompleteDate, this.wtStartDateLower));
		return filteredWorkTickets;
		}

	private applyWorkTicketCompletedDateFilter(workTickets: WorkTicketDTO[]): WorkTicketDTO[] {
		if (!workTickets || !this.wtCompletedDateLower || !this.wtCompletedDateUpper) {
			return workTickets;
		}
		let filteredWorkTickets: WorkTicketDTO[] = null;
		filteredWorkTickets = workTickets.filter(wt => this.dateIsBefore(wt.setupCompleteDate, this.wtCompletedDateUpper) && this.dateIsAfter(wt.setupCompleteDate, this.wtCompletedDateLower));
		return filteredWorkTickets;
		}

	private RemoveHiddenFunctionalAreas(functionalAreas: FunctionalArea[]): FunctionalArea[] {
		if (this.isSalesUser) {
			return functionalAreas.filter(x => x.hiddenFromSales === false);
		} else {
			return functionalAreas.filter(x => x.hiddenFromCustomers === false);
		}
	}

	private applyFunctionalAreaStatusFilter(functionalAreas: FunctionalArea[]): FunctionalArea[] {
		if (!functionalAreas || this.functionalAreaStatus.length === 0) {
			return functionalAreas;
		}
		return [].concat.apply([], this.functionalAreaStatus.map(x => functionalAreas.filter(fa => fa.status === x)));
	}

	private applyFunctionalAttachmentFilter(functionalAreas: FunctionalArea[]): FunctionalArea[] {
		if (!functionalAreas || !this.showAttachmentsOnly) {
			return functionalAreas;
		}
		return functionalAreas.filter(fa => fa.attachments !== null);
	}

	private applyFunctionalAreaPhotoFilter(functionalAreas: FunctionalArea[]): FunctionalArea[] {
		if (!functionalAreas || !this.showItemsWithPhotosOnly) {
			return functionalAreas;
		}
		return functionalAreas.filter(fa => fa.hasPhotos);
	}

	private applyFunctionalAreaOwnerFilter(functionalAreas: FunctionalArea[]): FunctionalArea[] {
		if (!this.functionalAreas || this.functionalAreaOwner.length === 0) {
			return functionalAreas;
		}
		return [].concat.apply([], this.functionalAreaOwner.map(x => functionalAreas.filter(fa => fa.owner === x.value)));
	}

	private applyLineItemAttachmentFilter(lineItems: CollaborationLineItemDTO[]): CollaborationLineItemDTO[] {
		if (!lineItems || !this.showAttachmentsOnly) {
			return lineItems;
		}
		return lineItems.filter(li => li.attachments !== null);
	}

	private applyLineItemOnlyAttachmentFilter(lineItems: CollaborationLineItemDTO[]): CollaborationLineItemDTO[] {
		if (!lineItems || !this.showLineItemAttachmentsOnly) {
			return lineItems;
		}
		return lineItems.filter(li => li.attachments == '1');
	}

	private applyLineItemPhotoFilter(lineItems: CollaborationLineItemDTO[]): CollaborationLineItemDTO[] {
		if (!lineItems || !this.showItemsWithPhotosOnly) {
			return lineItems;
		}
		return lineItems.filter(li => li.hasPhotos);
	}

	private applyLineItemStatusFilter(lineItems: CollaborationLineItemDTO[]): CollaborationLineItemDTO[] {
		if (!lineItems || this.lineItemStatus.length === 0) {
			return lineItems;
		}
		return [].concat.apply([], this.lineItemStatus.map(x => lineItems.filter(li => li.status === x)));
	}

	private applyLineItemVendorFilter(lineItems: CollaborationLineItemDTO[]): CollaborationLineItemDTO[] {
		if (!lineItems || this.vendor.length === 0) {
			return lineItems;
		}
		return [].concat.apply([], this.vendor.map(x => lineItems.filter(li => li.vendorName === x)));
	}

	private applyLineItemStartDateFilter(lineItems: CollaborationLineItemDTO[]): CollaborationLineItemDTO[] {
		if (!lineItems || !this.liStartDateLower || !this.liStartDateUpper) {
			return lineItems;
		}
		let filteredLineItems: CollaborationLineItemDTO[] = null;
		filteredLineItems = lineItems.filter(li => this.dateIsBefore(li.setupDate, this.liStartDateUpper) && this.dateIsAfter(li.setupDate, this.liStartDateLower));
		return filteredLineItems;
		}

	private applyLineItemCompletedDateFilter(lineItems: CollaborationLineItemDTO[]): CollaborationLineItemDTO[] {
		if (!lineItems || !this.liCompletedDateLower || !this.liCompletedDateUpper) {
			return lineItems;
		}

		let filteredLineItems: CollaborationLineItemDTO[] = null;
		filteredLineItems = lineItems.filter(li => this.dateIsBefore(li.endDate, this.liCompletedDateUpper) && this.dateIsAfter(li.endDate, this.liCompletedDateLower));
		return filteredLineItems;
		}

	private applyLineItemBoothFilter(lineItems: CollaborationLineItemDTO[]): CollaborationLineItemDTO[] {
		if (!lineItems || this.booth.length === 0) {
			return lineItems;
		}
		return [].concat.apply([], this.booth.map(x => lineItems.filter(li => li.booth === x)));
	}

	private applyLineItemLocationFilter(lineItems: CollaborationLineItemDTO[]): CollaborationLineItemDTO[] {
		if (!lineItems || this.liLocation.length === 0) {
			return lineItems;
		}

		return [].concat.apply([], this.liLocation.map(x => lineItems.filter(li => li.locationInfo === x)));
	}

	private applyCategoryFilter(lineItems: CollaborationLineItemDTO[]): CollaborationLineItemDTO[] {
		if (!lineItems || this.category.length === 0) {
			return lineItems;
		}

		return [].concat.apply([], this.category.map(x => lineItems.filter(li => li.category === x)));
	}

	private applyChangeOrderFilters(changeorders: ChangeOrderDTO[]): ChangeOrderDTO[] {
		// let cos = changeorders;
		return changeorders;
	}

	public setLineItemStartDateFilter(lowerBound: moment.Moment, upperBound: moment.Moment): void {
		this.liStartDateFilter = `${lowerBound.local().format('MM/DD/YYYY')} - ${upperBound.local().format('MM/DD/YYYY')}`;
		this.liStartDateUpper = upperBound;
		this.liStartDateLower = lowerBound;
	}

	public setLineItemCompletedDateFilter(lowerBound: moment.Moment, upperBound: moment.Moment): void {
		this.liCompletedDateFilter = `${lowerBound.local().format('MM/DD/YYYY')} - ${upperBound.local().format('MM/DD/YYYY')}`;
		this.liCompletedDateUpper = upperBound;
		this.liCompletedDateLower = lowerBound;
	}

	public setWorkTicketOrderDateFilter(lowerBound: moment.Moment, upperBound: moment.Moment): void {
		this.wtOrderDateFilter = `${lowerBound.local().format('MM/DD/YYYY')} - ${upperBound.local().format('MM/DD/YYYY')}`;
		this.wtOrderDateUpper = upperBound;
		this.wtOrderDateLower = lowerBound;
	}

	public setWorkTicketStartDateFilter(lowerBound: moment.Moment, upperBound: moment.Moment): void {
		this.wtStartDateFilter = `${lowerBound.local().format('MM/DD/YYYY')} - ${upperBound.local().format('MM/DD/YYYY')}`;
		this.wtStartDateUpper = upperBound;
		this.wtStartDateLower = lowerBound;
	}

	public setWorkTicketCompletedDateFilter(lowerBound: moment.Moment, upperBound: moment.Moment): void {
		this.wtCompletedDateFilter = `${lowerBound.local().format('MM/DD/YYYY')} - ${upperBound.local().format('MM/DD/YYYY')}`;
		this.wtCompletedDateUpper = upperBound;
		this.wtCompletedDateLower = lowerBound;
	}

	public dateIsAfter(date1, date2): boolean {
		return moment.utc(date1).isSameOrAfter(moment.utc(date2), 'day');
	}

	public dateIsBefore(date1, date2): boolean {
		return moment.utc(date1).isSameOrBefore(moment.utc(date2), 'day');
	}

	public countFilters() {
		this.globalFilters = [
			this.showAttachmentsOnly,
			this.showItemsWithPhotosOnly,
			this.facility.length
		];
		this.faFilters = [
			this.functionalAreaStatus.length,
			this.functionalAreaOwner.length,
		];
		this.liFilters = [
			this.lineItemStatus.length,
			this.vendor.length,
			this.booth.length,
			this.liLocation.length,
			this.liStartDateUpper,
			this.liCompletedDateUpper,
			this.category.length,
			this.showLineItemAttachmentsOnly,
		];
		this.wtFilters = [
			this.tssWorkTicketNumber.length,
			this.workTicketStatus.length,
			this.wtLocation.length,
			this.partGroup.length,
			this.functionalArea.length,
			this.wtStartDateUpper,
			this.wtCompletedDateUpper,
			this.wtOrderDateUpper,
		];
		this.coFilters = [];

		this.faFilterCount = 0;
		this.liFilterCount = 0;
		this.wtFilterCount = 0;
		this.coFilterCount = 0;
		this.globalFilterCount = 0;

		if (this.faFilters !== null) {
			for (let i = 0; i < this.faFilters.length; i++) {
				if (this.faFilters[i]) {
					this.faFilterCount++;
				}
			}
		}

		if (this.liFilters !== null) {
			for (let i = 0; i < this.liFilters.length; i++) {
				if (this.liFilters[i]) {
					this.liFilterCount++;
				}
			}

		}
		if (this.wtFilters !== null) {
			for (let i = 0; i < this.wtFilters.length; i++) {
				if (this.wtFilters[i]) {
					this.wtFilterCount++;
				}
			}

		}
		if (this.coFilters !== null) {
			for (let i = 0; i < this.coFilters.length; i++) {
				if (this.coFilters[i]) {
					this.coFilterCount++;
				}
			}

		}
		if (this.globalFilters !== null) {
			for (let i = 0; i < this.globalFilters.length; i++) {
				if (this.globalFilters[i]) {
					this.globalFilterCount++;
				}
			}
		}
	}

	public checkFilterExpansions() {
		this.globalFilterCount > 0 ? this.globalFilterExpandedSubject.next(true) : this.globalFilterExpandedSubject.next(false);
		this.faFilterCount > 0  ? this.functionalAreaExpandedSubject.next(true) : this.functionalAreaExpandedSubject.next(false);
		this.liFilterCount > 0  ? this.lineItemExpandedSubject.next(true) : this.lineItemExpandedSubject.next(false);
		this.wtFilterCount > 0  ? this.workticketExpandedSubject.next(true) : this.workticketExpandedSubject.next(false);
	}
}
