import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DashboardDTO } from '../../models/dashboard-dto';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { ToastService } from '../toast/toast.service';
import { EventService } from '../event/event.service';
import { ShowAccountService } from '../show-account/show-account.service';
import * as moment from 'moment';
import { ShowAccount } from '../../models/show-account';
import { Show } from '../../models/show';
import { Storage } from '@ionic/storage';
import { ToastType } from '../../models/toast-type';
import { WorkTicketDashboardDTO } from 'src/app/models/wt-dashboard-dto';
@Injectable({
	providedIn: 'root'
})
export class DashboardService {

	url = environment.apiUrl;
	dashboardDTO = new BehaviorSubject<DashboardDTO>(null);
	wtDashboardDTO = new BehaviorSubject<WorkTicketDashboardDTO>(null);
	public currentDashboardDTO: DashboardDTO;

	constructor(public http: HttpClient, public toastService: ToastService, public eventSvc: EventService, public showAccountSvc: ShowAccountService, public storage: Storage
	) {
		this.dashboardDTO.subscribe(dto => {
			this.currentDashboardDTO = dto;
		});
	}

	get(showId: string, showAccountId: string): void {
		this.refresh(showId, showAccountId);
	}

	refresh(showId: string, showAccountId: string, errorCallback?: Function) {
		this.http.get<DashboardDTO>(`${this.url()}events/${showId}/accounts/${showAccountId}/dashboard`)
			.subscribe(data => {
				this.dashboardDTO.next(data);
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error refreshing the dashboard data', 'danger');
				}
				if (errorCallback) errorCallback();
			});
	}

	download(showId: string, showAccountId: string, event: Show, account: ShowAccount, isApp?: number) {
		this.http.get(`${this.url()}events/${showId}/accounts/${showAccountId}/dashboard-history/${isApp}`)
			.subscribe(data => {
				if (data == null) {
					this.toastService.open('Report has send on your email successfully.', 'success');
				}
				else {
					const dataArray: any = data;
					const headerList = Object.keys(data[0] || data);
					const options = {
						headers: headerList,
						nullToEmptyString: true
					};
					const currentDate: string = moment().utc().format('YYYY_MM_DD');
					new AngularCsv(dataArray, `${event.name}_${event.tssShowNumber}_${account.name}_StatusChangeHistory_${currentDate}`, options);
				}
			}, console.error);
	}

	downloadOps(showId: string, event: Show, isApp?: number) {
		this.http.get(`${this.url()}events/${showId}/dashboard-ops-history/${isApp}`)
			.subscribe(data => {
				if (data == null) {
					this.toastService.open('Report has send on your email successfully.', 'success');
				}
				else {
					const dataArray: any = data;
					const headerList = Object.keys(data[0] || data);
					const options = {
						headers: headerList,
						nullToEmptyString: true
					};
					const currentDate: string = moment().utc().format('YYYY_MM_DD');
					new AngularCsv(dataArray, `${event.name}_${event.tssShowNumber}_StatusChangeHistory_${currentDate}`, options);
				}
			}, console.error);
	}

	getWtDashboard(showId: string): void {
		this.refreshWtDashboard(showId);
	}

	refreshWtDashboard(showId: string, errorCallback?: Function) {
		this.http.get<WorkTicketDashboardDTO>(`${this.url()}events/${showId}/work-tickets/dashboard`)
			.subscribe(data => {
				this.wtDashboardDTO.next(data);
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error refreshing the dashboard data', 'danger');
				}
				if (errorCallback) errorCallback();
			});
	}

}
