import { Status } from './../models/status';
import { FunctionalArea } from '../models/functional-area';
import { CollaborationLineItemDTO } from '../models/collaboration-line-item-dto';
import { WorkTicketDTO } from '../models/work-ticket-dto';

export class StatusHelper {
	public static Status = {
		New: { id: 1, color: 'status-new', icon: 'radio_button_checked', title: 'New' },
		InProgress: { id: 2, color: 'status-in-progress', icon: 'timelapse', title: 'In Progress' },
		// NeedsAttention: { id: 5, color: 'status-needs-attention', icon: 'error', title: 'Needs Attention' },
		// ActionRequired: { id: 6, color: 'status-on-hold', icon: 'remove_circle', title: 'Action Required' },
		OnHold: { id: 7, color: 'status-on-hold', icon: 'remove_circle', title: 'On Hold' },
		Complete: { id: 3, color: 'status-complete', icon: 'check_circle', title: 'Complete' },
		Approved: { id: 4, color: 'status-approved', icon: 'check_circle', title: 'Approved' },
	};

	public static ChangeOrderStatus = {
		ReviseAsChangeOrder: { id: 9, color: 'black', icon: 'create', title: 'Revise Order' }
	};

	public static ActivityOptions = {
		NewOrder: { id: 10, color: 'black', icon: 'note_add', title: 'New Order' },
		ReviseOrder: { id: 11, color: 'black', icon: 'create', title: 'Revised Order' },
		ChangeRequest: { id: 12, color: 'black', icon: 'change_circle', title: 'Change Request' },
		CancelledOrder: { id: 13, color: 'black', icon: 'cancel_presentation', title: 'Cancelled Order' },
	};

	public static ServiceRequestOptions = {
		NewService: { id: 14, color: 'service-line-status', icon: 'radio_button_unchecked', title: 'New' },
		Acknowledged: { id: 15, color: 'service-line-status', icon: 'local_library', title: 'Acknowledged' },
		ServiceInProgress: { id: 16, color: 'service-line-status', icon: 'donut_large', title: 'In Progress' },
		ServiceOnHold: { id: 17, color: 'service-line-status', icon: 'error', title: 'On Hold' },
		ServiceComplete: { id: 18, color: 'service-line-status', icon: 'check_circle_outline', title: 'Complete' },
		ServiceApproved: { id: 19, color: 'service-line-status', icon: 'circle', title: 'Approved' },
		
	};

	public static ZoneStatusOption = {
		NotStarted: { id: 20, color: 'status-new', icon: 'radio_button_checked', title: 'Not Started' },
		InProgress: { id: 21, color: 'status-in-progress', icon: 'timelapse', title: 'In Progress' },
		Complete: { id: 22, color: 'zone-status', icon: 'circle', title: 'Completed' },
		Overdue: { id: 23, color: 'overdue', icon: 'warning_amber', title: 'Overdue' },
	};

	public static FAFilterIcons = {
		PhotoNotification: { id: 30, color: 'black', icon: 'camera_alt', title: 'Photo' },
		Note: { id: 31, color: 'black', icon: 'note', title: 'Note' }
	};

	public static FunctionalAreaStatuses: Status[] = StatusHelper.CreateStatusArray('functionalArea');
	public static LineItemStatuses: Status[] = StatusHelper.CreateStatusArray('lineItem');
	public static WorkTicketStatuses: Status[] = StatusHelper.CreateStatusArray('workTicket');
	public static FunctionalAreaFilterStatuses: Status[] = StatusHelper.CreateStatusArray('functionalAreaFilter');
	public static ChangeOrderStatuses: Status[] = StatusHelper.CreateStatusArray('changeOrder');
	public static ActivityFilterOptions: Status[] = StatusHelper.CreateStatusArray('Activity');
	public static ServiceFilterOptions: Status[] = StatusHelper.CreateStatusArray('ServiceRequestOptions');
	public static ZoneStatus: Status[] = StatusHelper.CreateStatusArray('ZoneStatus');

	public static CreateStatusArray(type: string) {
		
		const arr = [];
		for (const key in this.Status) {
			if (type === 'functionalArea' || type === 'functionalAreaFilter') continue;
			if (key === 'Approved' && type === 'workTicket') continue;
			if (type == 'changeOrder') continue;
			if (type == 'ServiceRequestOptions') continue;
			arr.push(this.Status[key]);
		}
		if (type === 'functionalAreaFilter' || type === 'Activity') {
			for (const key in this.FAFilterIcons) {
				arr.push(this.FAFilterIcons[key]);
			}
		}
		if (type === 'changeOrder') {
			for (const key in this.ChangeOrderStatus) {
				arr.push(this.ChangeOrderStatus[key]);
			}
		}
		if (type === 'Activity') {
			for (const key in this.ActivityOptions) {
				arr.push(this.ActivityOptions[key]);
			}
		}

		if (type === 'ServiceRequestOptions') {
			for (const key in this.ServiceRequestOptions) {
				arr.push(this.ServiceRequestOptions[key]);
			}
		}

		if (type === 'ZoneStatus') {
			for (const key in this.ZoneStatusOption) {
				arr.push(this.ZoneStatusOption[key]);
			}
		}
		return arr;
	}

	public static GetStatusFromId(id: number): Status {
		return this.LineItemStatuses.find(fas => fas.id === id) || this.Status.New;
	}

	public static GetServiceRequestStatusFromId(id: number): Status {
		return this.LineItemStatuses.find(li => li.id === id) || this.Status.New;
	}
	
	public static ExhibitGetStatusFromId(id: number): Status {
		return this.LineItemStatuses.find(fas => fas.id === id)  || this.Status.New;
	}

	public static FreightZoneGetStatusFromId(id: number): Status {
		return this.ZoneStatus.find(fas => fas.id === id)  || this.ZoneStatusOption.NotStarted;
	}

	/** Update the functional area status based on its line item statuses. */
	// public static UpdateFunctionalAreaStatus(functionalArea: FunctionalArea, lineItems: CollaborationLineItemDTO[], changedLineItem: CollaborationLineItemDTO): void {
	// 	const index = lineItems.findIndex(li => li.kafkaId === changedLineItem.kafkaId);
	// 	if (index !== -1) {
	// 		lineItems.splice(index, 1, changedLineItem);
	// 	}

	// 	functionalArea.completedLineItems = lineItems.filter(li => li.status === this.Status.Approved || li.status === this.Status.Complete).length;

	// 	if (lineItems.find(x => x.status === this.Status.OnHold)) {
	// 		if (changedLineItem.status === this.Status.OnHold) {
	// 			functionalArea.status = this.GetNewFunctionalAreaStatus(functionalArea.status, changedLineItem.status);
	// 		}
	// 	} else if (lineItems.find(x => x.status === this.Status.OnHold)) {
	// 		functionalArea.status = this.GetNewFunctionalAreaStatus(functionalArea.status, this.Status.OnHold);
	// 	} else if (lineItems.every(x => x.status === this.Status.Approved)) {
	// 		functionalArea.status = this.GetNewFunctionalAreaStatus(functionalArea.status, this.Status.Approved);
	// 	} else if (lineItems.every(x => x.status === this.Status.Complete || x.status === this.Status.Approved)) {
	// 		functionalArea.status = this.GetNewFunctionalAreaStatus(functionalArea.status, this.Status.Complete);
	// 	} else if (lineItems.every(x => x.status === this.Status.New || x.status == null)) {
	// 		functionalArea.status = this.GetNewFunctionalAreaStatus(functionalArea.status, this.Status.New);
	// 	} else if (lineItems.find(x => x.status === this.Status.New || x.status === this.Status.InProgress || x.status == null)) {
	// 		functionalArea.status = this.GetNewFunctionalAreaStatus(functionalArea.status, this.Status.InProgress);
	// 	}
	// }

	public static UpdateWorkTicketStatus(workTicket: WorkTicketDTO, lineItems: CollaborationLineItemDTO[], changedLineItem: CollaborationLineItemDTO): void {
		const index = lineItems.findIndex(li => li.kafkaId === changedLineItem.kafkaId);
		const filteredLineItems = lineItems.filter(li => li.ticketNumber === workTicket.tssWorkTicketNumber);
		if (index !== -1) {
			lineItems.splice(index, 1, changedLineItem);
		}

		workTicket.lineItemCompleteCount = filteredLineItems.filter(li => li.status === this.Status.Approved || li.status === this.Status.Complete).length;

		if (lineItems.find(x => x.status === this.Status.OnHold)) {
			if (changedLineItem.status === this.Status.OnHold) {
				workTicket.currentStatus = this.GetNewFunctionalAreaStatus(workTicket.currentStatus, changedLineItem.status);
			}
		} else if (filteredLineItems.find(x => x.status === this.Status.OnHold)) {
			workTicket.currentStatus = this.GetNewFunctionalAreaStatus(workTicket.currentStatus, this.Status.OnHold);
		} else if (filteredLineItems.every(x => x.status === this.Status.Complete || x.status === this.Status.Approved)) {
			workTicket.currentStatus = this.GetNewFunctionalAreaStatus(workTicket.currentStatus, this.Status.Complete);
		} else if (filteredLineItems.every(x => x.status === this.Status.New || x.status == null)) {
			workTicket.currentStatus = this.GetNewFunctionalAreaStatus(workTicket.currentStatus, this.Status.New);
		} else if (filteredLineItems.find(x => x.status === this.Status.New || x.status === this.Status.InProgress || x.status == null)) {
			workTicket.currentStatus = this.GetNewFunctionalAreaStatus(workTicket.currentStatus, this.Status.InProgress);
		}
	}

	/** Update line item statuses based on the status of the functional area. Also updates functional area line item count. */
	public static UpdateLineItemStatuses(functionalArea: FunctionalArea, lineItems: CollaborationLineItemDTO[]): CollaborationLineItemDTO[] {
		const statusToIgnore = [];
		let statusToChangeTo: Status;

		switch (functionalArea.status) {
			case this.Status.Approved:
				statusToChangeTo = this.Status.Approved;
				break;
			case this.Status.Complete:
				statusToIgnore.push(this.Status.Approved);
				statusToChangeTo = this.Status.Complete;
				break;
			case this.Status.InProgress:
				statusToIgnore.push(this.Status.Approved);
				statusToIgnore.push(this.Status.Complete);
				statusToChangeTo = this.Status.InProgress;
		}

		if (!statusToChangeTo) return lineItems;

		// Change the line item statuses except those with the ignored statuses
		lineItems.filter(lineItem => statusToIgnore === null || lineItem.status !== statusToIgnore.find(leStatus => leStatus.title === lineItem.status.title)).forEach(lineItem => lineItem.status = statusToChangeTo);
		// Update the functional area line item counts
		// functionalArea.completedLineItems = lineItems.filter(li => li.status === this.Status.Approved || li.status === this.Status.Complete).length;
		return lineItems;
	}

	/** Update line item statuses based on the status of the work ticket. */
	public static UpdateLineItemStatusesForWorkTicket(workTicket: WorkTicketDTO, lineItems: CollaborationLineItemDTO[]): CollaborationLineItemDTO[] {
		const statusToIgnore = [];
		let statusToChangeTo: Status;

		switch (workTicket.currentStatus) {
			case this.Status.New:
				statusToIgnore.push(this.Status.Approved);
				statusToIgnore.push(this.Status.Complete);
				statusToChangeTo = this.Status.New;
				break;
			case this.Status.InProgress:
				statusToIgnore.push(this.Status.Approved);
				statusToIgnore.push(this.Status.Complete);
				statusToChangeTo = this.Status.InProgress;
				break;
			case this.Status.Complete:
				statusToIgnore.push(this.Status.Approved);
				statusToChangeTo = this.Status.Complete;
				break;
		}

		if (!statusToChangeTo) return lineItems;

		// Change the line item statuses except those with the ignored statuses
		lineItems.filter(lineItem => statusToIgnore === null || lineItem.status !== statusToIgnore.find(leStatus => leStatus.title === lineItem.status.title)).forEach(lineItem => lineItem.status = statusToChangeTo);
		// Update the functional area line item counts
		// functionalArea.completedLineItems = lineItems.filter(li => li.status === this.Status.Approved || li.status === this.Status.Complete).length;
		return lineItems;
	}

		/** Update line item statuses based on the status of the functional area. Also updates functional area line item count. */
		// public static UpdateLineItemForBoothStatuses(status: Status, lineItems: CollaborationLineItemDTO[]): CollaborationLineItemDTO[] {
		// 	const statusToIgnore = [];
		// 	let statusToChangeTo: Status;
	
		// 	switch (status) {
		// 		case this.Status.Approved:
		// 			statusToChangeTo = this.Status.Approved;
		// 			break;
		// 		case this.Status.Complete:
		// 			statusToIgnore.push(this.Status.Approved);
		// 			statusToChangeTo = this.Status.Complete;
		// 			break;
		// 		case this.Status.InProgress:
		// 			statusToIgnore.push(this.Status.Approved);
		// 			statusToIgnore.push(this.Status.Complete);
		// 			statusToChangeTo = this.Status.InProgress;
		// 	}
	
		// 	if (!statusToChangeTo) return lineItems;
	
		// 	// Change the line item statuses except those with the ignored statuses
		// 	lineItems.filter(lineItem => statusToIgnore === null || lineItem.status !== statusToIgnore.find(leStatus => leStatus.title === lineItem.status.title))
		// 	.forEach(lineItem => {
		// 		lineItem.status = statusToChangeTo;
		// 		lineItem.currentStatus = statusToChangeTo.id;
		// 	});
		// 	return lineItems;
		// }

	public static updateCompletedCount(functionalArea: FunctionalArea, lineItems: CollaborationLineItemDTO[]) {
		let numberOfCompletedItems = 0;
		for (let i = 0; i < lineItems.length; i++) {
			if (lineItems[i].status === this.Status.Approved || lineItems[i].status === this.Status.Complete) {
				numberOfCompletedItems++;
			}
		}
		functionalArea.completedLineItems = numberOfCompletedItems;

	}

	public static updateCompletedCountForWorkTicket(workTicket: WorkTicketDTO, lineItems: CollaborationLineItemDTO[]) {
		let numberOfCompletedItems = 0;
		for (let i = 0; i < lineItems.length; i++) {
			if (lineItems[i].status === this.Status.Approved || lineItems[i].status === this.Status.Complete) {
				numberOfCompletedItems++;
			}
		}
		workTicket.lineItemCompleteCount = numberOfCompletedItems;

	}

	private static GetNewFunctionalAreaStatus(oldStatus: Status, newStatus: Status): Status {
		if (oldStatus === newStatus) {
			return oldStatus;
		}

		return newStatus;
	}

	public static getBoothStatusDetails(statusId: number): Status {
		let boothStatusData
		switch (statusId) {
			case 1:
				boothStatusData = this.Status.New;
				break;
			case 2:
				boothStatusData = this.Status.InProgress;
				break;
			case 3:
				boothStatusData = this.Status.Complete;
				break;
			case 4:
				boothStatusData = this.Status.Approved;
				break;
			// case 5:
			// 	boothStatusData = this.Status.NeedsAttention;
			// 	break;
			case 7:
				boothStatusData = this.Status.OnHold;
				break;
			default:
				boothStatusData = this.Status.New;
		}
		return boothStatusData
	}
}
