import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController, PopoverController } from '@ionic/angular';
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';
import { NavParamHelper } from 'src/app/helpers/nav-param-helper';
import { SelectHelper } from 'src/app/helpers/select-helper';
import { WorkTicketDTO } from 'src/app/models/work-ticket-dto';
import { AttachmentService } from 'src/app/services/attachment/attachment.service';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';
import { WorkTicketService } from 'src/app/services/work-ticket/work-ticket.service';
import { WorkticketLineItemService } from 'src/app/services/workticket-line-item/workticket-line-item.service';
import { ItemActionMenuComponent } from '../item-action-menu/item-action-menu.component';

@Component({
  selector: 'occ-search-work-card',
  templateUrl: './search-work-card.component.html',
  styleUrls: ['./search-work-card.component.scss'],
})
export class SearchWorkCardComponent implements OnInit {

  @Input() workTicket: WorkTicketDTO;

	eventId: string;
	statusMenuPopover: any;

	constructor(
		public attachmentSvc: AttachmentService,
		public navCtrl: NavController,
		public appBrowserHelper: AppBrowserHelper,
		public navParamHelper: NavParamHelper,
		public dataSvc: DataRefreshService,
		public popoverCtrl: PopoverController,
		public wrkTikLISvc: WorkticketLineItemService,
		public wrkTickSvc: WorkTicketService,
    private activatedRoute: ActivatedRoute,
    public router: Router
	) {
		this.eventId = this.activatedRoute.snapshot.paramMap.get('eventId') || this.dataSvc.eventActive;
		this.navParamHelper.syncActiveInfo(this.eventId);
	}

  ngOnInit() {}

	pushDetailsPage(item: WorkTicketDTO) {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		// check the current work ticket value before doing anything
		const currentWorkTicket = this.wrkTickSvc.currentWorkTicketSubject.getValue();

		// if it doesn't exist or if it's different, update it.
		if (!currentWorkTicket || currentWorkTicket !== item) {
			this.wrkTickSvc.currentWorkTicketSubject.next(item);
		}

    this.router.navigateByUrl('events/' + this.eventId + '/worktickets/' +  this.workTicket.workTicketGuid + '/workticket-details');
	}

	async presentPopover(event, workTicket: WorkTicketDTO) {
    this.statusMenuPopover = await this.popoverCtrl.create({
			component: ItemActionMenuComponent,
			componentProps: { ticket: workTicket, fromSearch: true },
      event: event
		});

		await this.statusMenuPopover.present();
		event.stopPropagation();
	}

}
