import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import * as moment from 'moment';
import { Moment } from 'moment';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';
import { FilterHelper } from 'src/app/helpers/filter-helper';
import { SelectHelper } from 'src/app/helpers/select-helper';

@Component({
	selector: 'occ-filter-date',
	templateUrl: './filter-date.component.html',
	styleUrls: ['./filter-date.component.scss'],
})
export class FilterDateComponent {
	public errorMessage: string;
	@Input() dateFilterType: string;
	@Output() navigate = new EventEmitter();
	@Output() closeFilter = new EventEmitter();
	myDatePickerOptions: IAngularMyDpOptions = {
		alignSelectorRight: true,
		dateFormat: 'mm/dd/yyyy'

		// showClearDateBtn: false
		// this has height and width properties that might be useful for styling
	};

	lowerBoundDate: any;
	datepickerLowerBoundDate: any;
	upperBoundDate: any;
	datepickerUpperBoundDate: any;

	isWorkTicketSearchPage: false;
	pageReturn: string;

	applyButtonEnabled = false;

	constructor(
		public appBrowserHelper: AppBrowserHelper,
		public filterHelper: FilterHelper,
		public ga: GoogleAnalytics,
	) {
		this.filterHelper.workticketSearchPage ? this.pageReturn = 'workticketPage' : 'selection';
	}

	ngOnInit() {

		if (this.filterHelper.lineItemStatusSelected) {
			this.lineItemInit();
		} else {
			this.workTicketInit();
		}
		this.checkForValidEntry();
	}

	lineItemInit() {
		if (this.dateFilterType === 'start') {
			if (!(this.filterHelper.liStartDateLower && this.filterHelper.liStartDateUpper)) {
				return;
			}

			this.formatInitialDates(this.filterHelper.liStartDateLower, this.filterHelper.liStartDateUpper);

		} else { // must be completed date
			if (!(this.filterHelper.liCompletedDateLower && this.filterHelper.liCompletedDateUpper)) {
				return;
			}

			this.formatInitialDates(this.filterHelper.liCompletedDateLower, this.filterHelper.liCompletedDateUpper);

		}
	}

	workTicketInit() {
		if (this.dateFilterType === 'start') {
			if (!(this.filterHelper.wtStartDateLower && this.filterHelper.wtStartDateUpper)) {
				return;
			}

			this.formatInitialDates(this.filterHelper.wtStartDateLower, this.filterHelper.wtStartDateUpper);

		} else if (this.dateFilterType === 'completed') { // must be completed date
			if (!(this.filterHelper.wtCompletedDateLower && this.filterHelper.wtCompletedDateUpper)) {
				return;
			}

			this.formatInitialDates(this.filterHelper.wtCompletedDateLower, this.filterHelper.wtCompletedDateUpper);

		} else if (this.dateFilterType === 'order') {
			if (!(this.filterHelper.wtOrderDateLower && this.filterHelper.wtOrderDateUpper)) {
				return;
			}

			this.formatInitialDates(this.filterHelper.wtOrderDateLower, this.filterHelper.wtOrderDateUpper);
		}
	}

	checkForValidEntry() {
		setTimeout(() => {

			this.lowerBoundDate = this.datepickerLowerBoundDate ? this.datepickerLowerBoundDate.singleDate : this.lowerBoundDate;
			this.upperBoundDate = this.datepickerUpperBoundDate ? this.datepickerUpperBoundDate.singleDate : this.upperBoundDate;

			if (this.appBrowserHelper.isDesktop) {
				if (this.lowerBoundDate && typeof this.lowerBoundDate == "object" && 'jsDate' in this.lowerBoundDate)
					this.lowerBoundDate['jsdate'] = this.lowerBoundDate.jsDate;

				if (this.upperBoundDate && typeof this.upperBoundDate == "object" && 'jsDate' in this.upperBoundDate)
					this.upperBoundDate['jsdate'] = this.upperBoundDate.jsDate;
			}

			if (!(this.lowerBoundDate && this.upperBoundDate)) {
				return;
			}

			let lowerBoundDate: Moment;
			let upperBoundDate: Moment;

			lowerBoundDate = this.lowerBoundDate.formatted ? moment.utc(this.lowerBoundDate.formatted) : moment.utc(this.lowerBoundDate, 'YYYY-MM-DD');
			upperBoundDate = this.upperBoundDate.formatted ? moment.utc(this.upperBoundDate.formatted) : moment.utc(this.upperBoundDate, 'YYYY-MM-DD');


			if (!(lowerBoundDate <= upperBoundDate)) {
				this.errorMessage = 'Please enter a valid date range.';
				return this.applyButtonEnabled = false;
			}

			if (this.filterHelper.liStartDateFilter && this.dateFilterType === 'completed') {
				// if this is a completed date filter and the start date filter is applied,
				// the lower bound of the completed filter range must be after the earliest start date.
				this.errorMessage = `Please enter a date range that does not conflict with the applied start date filter: ${this.filterHelper.liStartDateFilter}`;
				return this.applyButtonEnabled = this.filterHelper.dateIsAfter(lowerBoundDate, this.filterHelper.liStartDateLower);
			}

			if (this.filterHelper.liCompletedDateFilter && this.dateFilterType === 'start') {
				// if this is a start date filter and the completed date filter is applied,
				// the lower bound of the start filter range must be earlier than the earliest completed date.
				this.errorMessage = `Please enter a date range that does not conflict with the applied completed date filter: ${this.filterHelper.liCompletedDateFilter}`;
				return this.applyButtonEnabled = this.filterHelper.dateIsBefore(lowerBoundDate, this.filterHelper.liCompletedDateLower);
			}

			if (this.filterHelper.wtStartDateFilter && this.dateFilterType === 'completed') {
				// if this is a completed date filter and the start date filter is applied,
				// the lower bound of the completed filter range must be after the earliest start date.
				this.errorMessage = `Please enter a date range that does not conflict with the applied start date filter: ${this.filterHelper.wtStartDateFilter}`;
				return this.applyButtonEnabled = this.filterHelper.dateIsAfter(lowerBoundDate, this.filterHelper.wtStartDateLower);
			}

			if (this.filterHelper.wtCompletedDateFilter && this.dateFilterType === 'start') {
				// if this is a start date filter and the completed date filter is applied,
				// the lower bound of the start filter range must be earlier than the earliest completed date.
				this.errorMessage = `Please enter a date range that does not conflict with the applied completed date filter: ${this.filterHelper.wtCompletedDateFilter}`;
				return this.applyButtonEnabled = this.filterHelper.dateIsBefore(lowerBoundDate, this.filterHelper.wtCompletedDateLower);
			}

			return this.applyButtonEnabled = true;
		}, 500);
	}

	showPage(pageToShow): void {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		this.navigate.emit(pageToShow);
	}

	hideFilter(): void {
		this.closeFilter.emit();
	}

	formatInitialDates(lowerDate: Moment, upperDate: Moment) {
		if (this.appBrowserHelper.isDesktop) {
			// Desktop uses myDatePicker which reads the current selection from the 'formatted' property of an object
			// so we need to build one with the existing values.
			// Later when we submit the filter, we'll need a javascript date object to parse to a utc date/time object so we add that to our custom object here too.
			this.lowerBoundDate = {
				jsdate: lowerDate.toDate(),
				formatted: lowerDate.format('MM/DD/YYYY')
			};
			this.datepickerLowerBoundDate = {
				singleDate: {
					date: {
						year: new Date(lowerDate.toDate()).getFullYear(),
						month: new Date(lowerDate.toDate()).getMonth() + 1,
						day: new Date(lowerDate.toDate()).getDate()
					},
					jsDate: lowerDate.toDate(),
					...this.lowerBoundDate
				}
			}
			this.upperBoundDate = {
				jsdate: upperDate.toDate(),
				formatted: upperDate.format('MM/DD/YYYY')
			};
			this.datepickerUpperBoundDate = {
				singleDate: {
					date: {
						year: new Date(upperDate.toDate()).getFullYear(),
						month: new Date(upperDate.toDate()).getMonth() + 1,
						day: new Date(upperDate.toDate()).getDate()
					},
					jsDate: lowerDate.toDate(),
					...this.upperBoundDate
				}
			}
		} else {
			// mobile date picker current vales must be strings in the format YYYY-MM-DD to display the current selection.
			// format the extisting selected values from Moment format so that they'll show up.
			this.lowerBoundDate = lowerDate.toISOString();
			this.upperBoundDate = upperDate.toISOString();
		}

	}

	formatDatesForSubmission() {
		if (!this.appBrowserHelper.isDesktop) {
			// format mobile start date stuff
			this.lowerBoundDate = {
				jsdate: new Date(this.lowerBoundDate),
				formatted: this.lowerBoundDate
			};
			this.upperBoundDate = {
				jsdate: new Date(this.upperBoundDate),
				formatted: this.upperBoundDate
			};
		}
	}

	applyFilter(): void {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		this.formatDatesForSubmission();

		if (this.filterHelper.lineItemStatusSelected) {
			if (this.dateFilterType === 'start') {
				this.filterHelper.setLineItemStartDateFilter(moment.utc(this.lowerBoundDate.jsdate), moment.utc(this.upperBoundDate.jsdate));
				this.ga.trackEvent('FilterApplied', 'LineItemStartDateFilter');
			} else {
				this.filterHelper.setLineItemCompletedDateFilter(moment.utc(this.lowerBoundDate.jsdate), moment.utc(this.upperBoundDate.jsdate));
				this.ga.trackEvent('FilterApplied', 'LineItemCompleteDateFilter');
			}
		} else {
			if (this.dateFilterType === 'start') {
				this.filterHelper.setWorkTicketStartDateFilter(moment.utc(this.lowerBoundDate.jsdate), moment.utc(this.upperBoundDate.jsdate));
				this.ga.trackEvent('FilterApplied', 'WorkTicketStartDateFilter');
			} else if (this.dateFilterType === 'completed') {
				this.filterHelper.setWorkTicketCompletedDateFilter(moment.utc(this.lowerBoundDate.jsdate), moment.utc(this.upperBoundDate.jsdate));
				this.ga.trackEvent('FilterApplied', 'WorkTicketCompleteDateFilter');
			} else if (this.dateFilterType === 'order') {
				this.filterHelper.setWorkTicketOrderDateFilter(moment.utc(this.lowerBoundDate.jsdate), moment.utc(this.upperBoundDate.jsdate));
				this.ga.trackEvent('FilterApplied', 'WorkTicketOrderDateFilter');
			}
		}


		this.filterHelper.applyFilters();
		this.showPage(this.pageReturn);
	}

	resetFilter(): void {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		if (this.filterHelper.lineItemStatusSelected) {
			if (this.dateFilterType === 'start') {
				this.filterHelper.liStartDateLower = this.filterHelper.liStartDateUpper = this.filterHelper.liStartDateFilter = null;
			} else {
				this.filterHelper.liCompletedDateLower = this.filterHelper.liCompletedDateUpper = this.filterHelper.liCompletedDateFilter = null;
			}
		} else {
			if (this.dateFilterType === 'start') {
				this.filterHelper.wtStartDateLower = this.filterHelper.wtStartDateUpper = this.filterHelper.wtStartDateFilter = null;
			} else if (this.dateFilterType === 'complete') {
				this.filterHelper.wtCompletedDateLower = this.filterHelper.wtCompletedDateUpper = this.filterHelper.wtCompletedDateFilter = null;
			} else if (this.dateFilterType === 'order') {
				this.filterHelper.wtOrderDateLower = this.filterHelper.wtOrderDateUpper = this.filterHelper.wtOrderDateFilter = null;
			}
		}

		this.upperBoundDate = this.lowerBoundDate = '';
		this.datepickerLowerBoundDate = "";
		this.datepickerUpperBoundDate = "";
		this.filterHelper.applyFilters();
	}
}
