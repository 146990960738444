import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController, PopoverController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { AuthTokenHelper } from 'src/app/helpers/auth-token-helper';
import { SelectHelper } from 'src/app/helpers/select-helper';
import { StatusHelper } from 'src/app/helpers/status-helper';
import { CollaborationLineItemDTO } from 'src/app/models/collaboration-line-item-dto';
import { FunctionalArea } from 'src/app/models/functional-area';
import { Status } from 'src/app/models/status';
import { WorkTicketDTO } from 'src/app/models/work-ticket-dto';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';
import { FunctionalAreaService } from 'src/app/services/functional-area/functional-area.service';
import { LineItemService } from 'src/app/services/line-item/line-item.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { WorkTicketService } from 'src/app/services/work-ticket/work-ticket.service';
import { WorkticketLineItemService } from 'src/app/services/workticket-line-item/workticket-line-item.service';

@Component({
  selector: 'occ-line-item-change-status',
  templateUrl: './line-item-change-status.component.html',
  styleUrls: ['./line-item-change-status.component.scss'],
})
export class LineItemChangeStatusComponent implements OnInit {

  statuses: Array<Status>;
	coStatuses: Array<Status>;
	functionalArea: FunctionalArea;
	lineItem: CollaborationLineItemDTO;
	workTicket: WorkTicketDTO;
	workTicketLineItem: CollaborationLineItemDTO;
	eventId: string;
	accountId: string;
	isFromDetailsPage: boolean;
	isFromWTDetailsPage: boolean;
	fromSearch: boolean;
	functionalAreaId: string;

	constructor(
		public navParams: NavParams,
		public funcSvc: FunctionalAreaService,
		public lineItemService: LineItemService,
		public workTikSvc: WorkTicketService,
		public modalCtrl: ModalController,
		public dataSvc: DataRefreshService,
		public toastSvc: ToastService,
		public popoverCtrl: PopoverController,
		public wkTkLineItemSvc: WorkticketLineItemService,
		public authTokenHelper: AuthTokenHelper,

	) {
		
	}

  ngOnInit() {
    this.eventId = this.dataSvc.eventActive;
		this.accountId = this.dataSvc.showAccountActive;
		this.functionalArea = this.navParams.data.area || this.funcSvc.currentFuncAreaSubject.getValue();
		// get the functional area id from the functional area if it exists. If not, get it from the nav params (in the case of deep link to lidetails)
		this.functionalAreaId = this.functionalArea ? this.functionalArea.functionalAreaGuid : this.navParams.data.functionalAreaId;
		// if we had to get the functional area id from the nav params, it probably isn't set as the active functional area.
		// We'll set that here since some things reference the active functional area.
		if (!this.functionalArea && this.functionalAreaId) {
			this.funcSvc.activateFunctionalArea(this.functionalAreaId);
		}
		this.workTicketLineItem =this. navParams.data.workTicketLineItem;
		this.lineItem = this.navParams.data.item;
		this.workTicket = this.navParams.data.ticket;
		this.statuses = this.lineItem ? StatusHelper.LineItemStatuses : null;
		this.coStatuses = this.lineItem ? StatusHelper.ChangeOrderStatuses : null;
		this.isFromDetailsPage = this.navParams.data.isFromDetailsPage;
		this.isFromWTDetailsPage = this.navParams.data.isFromWTDetailsPage;
		this.fromSearch = this.navParams.data.fromSearch;
		if (!this.isFromDetailsPage && !this.isFromWTDetailsPage) {
			this.lineItemService.refresh(this.eventId, this.accountId, this.functionalAreaId).subscribe;
		}
  }

	//TODO: Since this no longer just changes status, should we change the name of this method or abstract the choice to another method?
	changeStatus(newStatus: Status) {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		
		if (this.navParams.data.item) {
			if (newStatus === StatusHelper.ChangeOrderStatus.ReviseAsChangeOrder) {
				this.presentChangeOrderModal(newStatus, 'li-modal');
			} else {
				this.lineItemService.put(
					this.dataSvc.eventActive,
					this.dataSvc.showAccountActive,
					this.lineItem.functionalAreaGuid,
					this.lineItem.kafkaId,
					newStatus,
				);
			}
		}
		
    if(this.modalCtrl){
      this.modalCtrl.dismiss(newStatus)
    }

    if(this.popoverCtrl){
      this.popoverCtrl.dismiss(newStatus)
    }
		// this.viewCtrl.dismiss(newStatus);
	}

	async presentChangeOrderModal(status: Status, extraClass?: string) {
    // Uncomment below code once FA Details implemented

		// const functionalArea = this.functionalArea ? this.functionalArea : this.lineItem ? this.lineItem.functionalAreaGuid : '';
		
    // const needsAttentionModal = await this.modalCtrl.create({
    //   component: ChangeOrderModal,
    //   showBackdrop: true,
    //   componentProps: { newStatus: status, workTicket: this.workTicket, functionalArea: functionalArea, lineItem: this.lineItem, workTicketLineItem: this.workTicketLineItem, fromSearch: this.fromSearch }
    // });
    // await needsAttentionModal.present();
	}
}
