import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { GenericResponse } from 'src/app/models/generic-response';
import { Show } from 'src/app/models/show';
import { ShowSearch } from 'src/app/models/show-search';
import { environment } from 'src/environments/environment';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  url = environment.apiUrl;
	public events = new BehaviorSubject<Show[]>([]);
	public currentEvent = new BehaviorSubject<Show>(null);
	public eventIdSubject = new BehaviorSubject<string>(null);
	public eventId: string;
	public addedEvent = new BehaviorSubject<ShowSearch[]>(null);
	public errorSearchingForShow: boolean;
	public eventLoading = new BehaviorSubject<boolean>(null);
	public showLoading = new BehaviorSubject<boolean>(false);
	public favoriteShows;
  
  constructor(
    public http: HttpClient,
    public toastService: ToastService
  )
     {
      this.eventIdSubject.subscribe(id => {
        this.eventId = id;
      });
     }

     public get(eventId?: string) {
      this.eventLoading.next(true);
      this.refresh(eventId);
    }

    refresh(eventId?: string) {
      this.http.get<Array<Show>>(`${this.url()}events/`)
        .subscribe(data => {
          this.events.next(data);
          this.eventLoading.next(false);
          if (eventId) {
            this.currentEvent.next(data.find(show => {
              return show.kafkaId === eventId;
            }));
          }
        }, err => {
          if (err.status !== 401 && err.status !== 403) {
            this.toastService.open('There was an error refreshing the event data', 'danger');
          }
        });
    }

    public clearData() {
      this.http.post<String>(`${this.url()}clear-old-show-data`, '')
        .subscribe(data => {
        });
    }
  
    public requestShow(searchCriteria: string) {
      this.showLoading.next(true);
      this.http.post<ShowSearch[]>(`${this.url()}events/search`, { searchCriteria })
        .subscribe(data => {
          this.addedEvent.next(data);
          this.errorSearchingForShow = data === null ? true : false;
          this.showLoading.next(false);
        }, err => {
          if (err.status !== 401 && err.status !== 403) {
            this.toastService.open('There was an error getting the event data', 'danger');
          }
          this.showLoading.next(false);
        });
    }
  
    public addShow(eventId: string): Subject<boolean> {
      const response = new Subject<boolean>();
      this.http.put<GenericResponse>(`${this.url()}events/${eventId}`, null)
        .subscribe(data => {
          if (data.status === 'error') {
            response.next(false);
            response.complete();
            this.toastService.open(data.message, 'danger');
          } else if (data.status === 'success') {
            response.next(true);
            response.complete();
            this.toastService.open(data.message, 'success');
            this.get();
          }
        }, err => {
          if (err.status !== 401 && err.status !== 403) {
            response.next(false);
            response.complete();
            this.toastService.open('There was an error adding event','danger');
          }
        });
      return response;
    }
  
    public removeShow(eventId: string): Subject<boolean> {
      const response = new Subject<boolean>();
      this.http.delete<GenericResponse>(`${this.url()}events/${eventId}`)
        .subscribe({
          next: data => {
            if (data.status === 'error') {
              response.next(false);
              response.complete();
              this.toastService.open(data.message,'danger');
            } else if (data.status === 'success') {
              response.next(true);
              response.complete();
              this.toastService.open(data.message, 'success');
              this.get();
            }
          },
          error: error => {
            if (error.status !== 401 && error.status !== 403) {
                  response.next(false);
                  response.complete();
                  this.toastService.open('There was an error adding event','danger');
                }
            }
          });
      return response;
    }
  
    public favoriteShow(eventId: string) {
      this.http.put<GenericResponse>(`${this.url()}events/${eventId}/favorite`, null)
        .subscribe(data => {
          if (data.status === 'error') {
            this.toastService.open(data.message, 'danger');
          } else if (data.status === 'success') {
            this.toastService.open(data.message, 'success');
            this.refresh();
          }
        }, err => {
          if (err.status !== 401 && err.status !== 403) {
            this.toastService.open('There was an error adding event', 'danger');
          }
        });
    }
  
    public getEMSCloseStatus(eventId:string) {
      let currentSelectedShow = this.currentEvent.getValue();
      if (currentSelectedShow) {
        return currentSelectedShow.isEmsClosed ? true : false;
      }else{
        this.get(eventId);
      }
      return false;
    }
  
    public getShowOpenDatePassedStatus() {
      let currentSelectedShow = this.currentEvent.getValue();
      if (currentSelectedShow && currentSelectedShow.openDate) {
        let showOpenDate = new Date(currentSelectedShow.openDate);
        let currentDate = new Date();
  
        if (currentDate.getTime() > showOpenDate.getTime()) {
          return true;
        }
      }
      return false;
    }
}
