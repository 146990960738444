import { ModalController, NavParams } from '@ionic/angular';
import { CollaborationLineItemDTO } from './../../models/collaboration-line-item-dto';
import { LineItemService } from './../../services/line-item/line-item.service';
import { Component, OnDestroy } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { WorkTicketService } from '../../services/work-ticket/work-ticket.service';
import { WorkTicketDTO } from '../../models/work-ticket-dto';
import { takeWhile } from 'rxjs/operators';

@Component({
	selector: 'app-line-item-selection',
	templateUrl: './line-item-selection.component.html',
	styleUrls: ['./line-item-selection.component.scss'],
})
export class LineItemSelectionComponent implements OnDestroy {

	lineItems: CollaborationLineItemDTO[];
	subscribe = true;
	selectedLineItemId: string;
	callback: Function;
	isLoading: boolean;
	loading: any;
	workTicket: WorkTicketDTO;

	constructor(
		public lineItemSvc: LineItemService,
		public navParams: NavParams,
		public loadingCtrl: LoadingController,
		public workTicketSvc: WorkTicketService,
		public modalCtrl: ModalController,
	) {
		this.workTicket = this.navParams.data.workTicket;
		this.isLoading = true;

		this.showLoader();

		this.callback = this.navParams.data.cb;
		if (this.workTicket) {
			this.workTicketSvc.workTicketLineItemDTO.pipe(takeWhile(() => this.subscribe)).subscribe(liDto => {
				this.lineItems = liDto;
				this.hideLoader();
			});
		} else {
			this.lineItemSvc.collaborationLineItemDto.pipe(takeWhile(() => this.subscribe)).subscribe(liDto => {
				this.lineItems = liDto;
				this.hideLoader();
			});
		}
		this.selectedLineItemId = this.navParams.data.selectedLineItemId;
	}

	showLoader() {
		// this.loadingCtrl.create({
		// 	message: 'Fetching line items...'
		// }).then((res) => {
		// 	res.present();
		// });
	}

	hideLoader() {
		// this.loadingCtrl.dismiss().then((res) => {
		// 	this.isLoading = false;
		// }).catch((error) => {
		// 	this.isLoading = false;
		// });
	}

	selectLineItem() {
		if (this.callback) {
			if (this.selectedLineItemId) {
				this.callback(this.selectedLineItemId);
			} else {
				this.callback('None');
			}
		}
		this.modalCtrl.dismiss();
	}

	cancelModal() {
		this.modalCtrl.dismiss();
	}

	ngOnDestroy() {
		this.subscribe = false;
	}
}
