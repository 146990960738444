import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'occ-load-spinner',
  templateUrl: './load-spinner.component.html',
  styleUrls: ['./load-spinner.component.scss'],
})
export class LoadSpinnerComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
