import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';
import { AuthTokenHelper } from 'src/app/helpers/auth-token-helper';
import { environment } from 'src/environments/environment';
import { FunctionalAreaService } from '../functional-area/functional-area.service';
import { LineItemService } from '../line-item/line-item.service';
import { ToastService } from '../toast/toast.service';
import { WorkTicketService } from '../work-ticket/work-ticket.service';
import { WorkticketLineItemService } from '../workticket-line-item/workticket-line-item.service';
import { takeWhile } from 'rxjs/operators';
import { FATabsEnum, LITabsEnum } from 'src/app/helpers/tabs-enum';
import { Router } from '@angular/router';
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token,} from '@capacitor/push-notifications';
import { ErrorLogService } from '../error-log/error-log.service';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  url = environment.apiUrl;

	public subscribe = true;
	public activeUserGuid: string;
	public deviceUpdate: any;

	public deepLinkPage: string;
	public deepLinkParams: any;
  constructor(public http: HttpClient,
		public platform: Platform,
		public toastService: ToastService,
		public authTokenHelper: AuthTokenHelper,
		public appBrowserHelper: AppBrowserHelper,
		public zone: NgZone,
		public funcSvc: FunctionalAreaService,
		public lineItemSvc: LineItemService,
		public workTicketSvc: WorkTicketService,
		public workTicketLineItemSvc: WorkticketLineItemService,
    private router: Router,
    private errorLogSvc: ErrorLogService
  ) { }

  registerForPush() {
    this.errorLogSvc.insert('Notification Registration', 'Start');
    this.authTokenHelper.currentUserGuid.pipe(takeWhile(() => this.subscribe)).subscribe(x => this.activeUserGuid = x);

    this.errorLogSvc.insert('Active Userguid',  this.activeUserGuid);
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        this.errorLogSvc.insert('Permission Granted', result);
        console.log('We have permission to send push notifications');
        PushNotifications.register();
      } else {
        this.errorLogSvc.insert('Permission Denied', result);
        console.log('We do not have permission to send push notifications');
      }
    });

    PushNotifications.addListener('registration', (token: Token) => {
      this.errorLogSvc.insert('Registration Token', token);
      this.getRegistrationId(token);
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      this.errorLogSvc.insert('Error In Registration', error);
      console.log('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener('pushNotificationReceived', notification => {
        this.zone.run(() => 
        //this.toastService.open(notification?.data?.message, 'success')
        console.log(notification)
        );
        this.errorLogSvc.insert('pushNotificationReceived', notification);
        console.log(notification);
      },
    );

    PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
        console.log(notification);
        this.errorLogSvc.insert('pushNotificationActionPerformed', notification);
        this.navigateOnNotification(notification?.notification);
      },
    );

  }

    async getRegistrationId(handle) {
      const registrationHandle = {
        handle: handle.value
      };
      const hubRegistrationId = await this.http.post(`${this.url()}notification/`, registrationHandle, { responseType: 'text' }).toPromise();
      this.errorLogSvc.insert('hubRegistrationId', hubRegistrationId);
      this.registerOnNotificationHub(hubRegistrationId, handle);
    }
  
    registerOnNotificationHub(hubRegistrationId, handle) {
      this.deviceUpdate = {
        Handle: handle.value,
        Tags: [this.activeUserGuid]
      };
      if (this.platform.is('android')) {
        this.deviceUpdate.platform = 'gcm';
      }
      if (this.platform.is('ios')) {
        this.deviceUpdate.platform = 'apns';
      }
  
      console.log('deviceUpdate: ', this.deviceUpdate);
      this.http.put(`${this.url()}notification/${hubRegistrationId}`, this.deviceUpdate)
        .subscribe(data => {
          this.errorLogSvc.insert('device registered', data);
          console.log('device registered: ', data);
        }, error => {
          switch (error.status) {
            case 400:
              this.toastService.open('400 error while trying to register with the notificaiton hub', 'danger');
              break;
            case 404:
              this.toastService.open('404 error while trying to register with the notification hub','danger');
              break;
            case 401:
            case 403: {
              this.toastService.open('403 error while trying to register with the notification hub', 'danger');
              break;
            }
            default:
              // this.toastService.open('Unable to update, unknown error.', 'error_outline', ToastType.default, 'error', 10);
              break;
          }
        });
    }
  
    navigateOnNotification(notification: any) {
      if (this.appBrowserHelper.isIos) {
        notification.data = notification.data.aps;
      }
      if (!this.validateLinking(notification)) return;
      this.deepLinkPage = this.setDeepLinkPage(notification);
      this.deepLinkParams = this.setDeepLinkParams(notification);
      this.prepServices(notification);
      this.errorLogSvc.insert('Deeplink', this.deepLinkPage);
      switch (this.deepLinkPage) {
        case 'functional-area-details':
          this.router.navigateByUrl('events/'+ this.deepLinkParams.eventId + '/accounts/' + this.deepLinkParams.accountId + '/functional-areas/'+ this.deepLinkParams.functionalAreaId +'/functional-area-details');
          break;

        case 'line-item-details':
          this.router.navigateByUrl('events/'+ this.deepLinkParams.eventId +'/accounts/'+ this.deepLinkParams.accountId +'/functional-areas/'+ this.deepLinkParams.functionalAreaId +'/line-items/'+ this.deepLinkParams.lineItemId +'/line-item-details');
          break;

        case 'workticket-line-item-details':
          this.router.navigateByUrl('events/'+ this.deepLinkParams.eventId +'/workticket-details/'+ this.deepLinkParams.workTicketGuid +'/line-items/'+ this.deepLinkParams.lineItemId +'/workticket-line-item-details');
          break;

        case 'workticket-details':
          this.router.navigateByUrl('events/'+ this.deepLinkParams.eventId +'/worktickets/'+ this.deepLinkParams.workTicketId +'/workticket-details');
          break;

        case 'change-log-details':
          this.router.navigateByUrl('events/'+ this.deepLinkParams.eventId +'/accounts/'+ this.deepLinkParams.accountId +'/changeorders/'+ this.deepLinkParams.changeOrderId +'/change-log-details');
          break;

        case 'service-request-details':
          this.router.navigateByUrl('events/' + this.deepLinkParams.eventId + '/booths/' + this.deepLinkParams.boothId + '/service-requests/' + this.deepLinkParams.issueItemId + '/service-request-details' + '/1');
          break;

        case 'service-request-details':
          this.router.navigateByUrl('events/' + this.deepLinkParams.eventId + '/booths/' + this.deepLinkParams.boothId + '/service-requests/' + this.deepLinkParams.issueItemId + '/service-request-details' + '/1');
        
        default:
          break;
        }
    }
  
    validateLinking(notification: any) {
      this.errorLogSvc.insert('validateLink', notification.data);
      if (this.authTokenHelper.isOpsUser) {
        return notification.data.workTicketId;
      } else {
        if(notification.data.message.indexOf('New Order') != -1 || notification.data.message.indexOf('Revised Order') != -1
          || notification.data.message.indexOf('Cancelled Order') != -1){
          return notification.data.lineItemId;
        }else{
          return notification.data.functionalAreaId;
        }
      }
    }
  
    setDeepLinkPage(notification: any): string {
      if (this.authTokenHelper.isOpsUser) {
        return notification.data.lineItemId ? 'workticket-line-item-details' : 'workticket-details';
      } else {
        if(notification.data.message.indexOf('New Order') != -1 || notification.data.message.indexOf('Revised Order') != -1
        || notification.data.message.indexOf('Cancelled Order') != -1){
          return 'change-log-details';
        } else if (notification.data.message.indexOf('MHA') != -1) {
          return 'service-request-details';
        }
        else{
          return notification.data.lineItemId ? 'line-item-details' : 'functional-area-details';
        }
      }
    }
  
    setDeepLinkParams(notification: any): any {
      switch (this.deepLinkPage) {
        case 'functional-area-details':
          return {
            eventId: notification.data.eventId,
            accountId: notification.data.accountId,
            functionalAreaId: notification.data.functionalAreaId,
            pageTab: notification.data.readyForReview ? null : FATabsEnum.Activity,
            isDeepLink: true
          };
  
        case 'line-item-details':
          return {
            eventId: notification.data.eventId,
            accountId: notification.data.accountId,
            functionalAreaId: notification.data.functionalAreaId,
            lineItemId: notification.data.lineItemId,
            pageTab: notification.data.readyForReview ? null : LITabsEnum.Activity,
            isDeepLink: true
          };
  
        case 'workticket-line-item-details':
          return {
            eventId: notification.data.eventId,
            workTicketGuid: notification.data.workTicketId,
            functionalAreaId: notification.data.functionalAreaId,
            showAccountId: notification.data.accountId,
            lineItemId: notification.data.lineItemId,
            pageTab: notification.data.readyForReview ? null : LITabsEnum.Activity,
            isDeepLink: true
          };
  
        case 'workticket-details':
          return {
            eventId: notification.data.eventId,
            workTicketId: notification.data.workTicketId,
            pageTab: notification.data.readyForReview ? null : FATabsEnum.Activity,
            isDeepLink: true
          };
        
        case 'change-log-details':
          return {
            eventId: notification.data.eventId,
            accountId: notification.data.accountId,
            changeOrderId: notification.data.lineItemId,
            isDeepLink: true
          };
        case 'service-request-details':
          return {
            evenId: notification.data.eventId,
            accountId: notification.data.accountId,
            boothId: notification.data.functionalAreaId,
            issueItemId: notification.data.workTicketId,
            isDeepLink: true
          }
          
        default:
          break;
        }
    }

    prepServices(notification: any) {
      if (this.deepLinkPage === 'functional-area-details') {
        this.funcSvc.activateFunctionalArea(notification.data.functionalAreaId);
      } else if (this.deepLinkPage === 'line-item-details') {
        this.lineItemSvc.setCurrentLineItem(notification.data.lineItemId);
      } else if (this.deepLinkPage === 'workticket-line-item-details') {
        this.workTicketLineItemSvc.setCurrentLineItem(notification.data.lineItemId);
      } else if (this.deepLinkPage === 'workticket-details') {
        this.workTicketSvc.activateWorkTicket(notification.data.workTicketId);
      }
    }
}
