import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'occ-cancel-order-confirmation',
  templateUrl: './cancel-order-confirmation.component.html',
  styleUrls: ['./cancel-order-confirmation.component.scss'],
})

export class CancelOrderConfirmationComponent {
  text: string;

  constructor(public popOverCtrl: PopoverController) {
  }

  dismissCancelOrder(){
    this.popOverCtrl.dismiss(false);
  }

  confirmCancelOrder(){
    this.popOverCtrl.dismiss(true);
  }
}

