import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { WorkTicketDetails } from 'src/app/models/work-ticket-details';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';
import { WorkTicketService } from 'src/app/services/work-ticket/work-ticket.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'occ-workticket-info',
  templateUrl: './workticket-info.component.html',
  styleUrls: ['./workticket-info.component.scss'],
})
export class WorkticketInfoComponent implements OnInit, OnDestroy {

  @Input() workTicketId: string;
	subscribe = true;
	workTicket: WorkTicketDetails;
	isLoading: boolean;
	firstLoad = false;


	constructor(
		public workTicketSvc: WorkTicketService,
		public dataSvc: DataRefreshService
	) {
	}

	ngOnInit() {
		this.isLoading = true;
		this.workTicket = null;
		this.workTicketSvc.refreshDataForWorkTicketDetails(this.dataSvc.eventActive, this.workTicketId);
		this.workTicketSvc.currentWorkTicketDetailsSubject.pipe(takeWhile(() => this.subscribe)).subscribe(wt => {
			this.workTicket = wt;
			if (this.firstLoad) {
				this.firstLoad = false;
				this.isLoading = false;
			}
		});
		this.firstLoad = true;
	}

	refresh(refresher) {
		this.workTicketSvc.refreshDataForWorkTicketDetails(this.dataSvc.eventActive, this.workTicketId);
		refresher.target.complete();
	}

	ngOnDestroy() {
		this.subscribe = false;
	}

}
