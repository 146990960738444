import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthTokenHelper } from 'src/app/helpers/auth-token-helper';
import { ExhibitorAccountDto } from 'src/app/models/exhibitor-account-dto';
import { environment } from 'src/environments/environment';
import { ToastService } from '../toast/toast.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class ExhibitorAccountDetailsService {
  public exhibitorAccountDetails = new BehaviorSubject<ExhibitorAccountDto>({});
  url = environment.apiUrl;

  constructor(private httpClient : HttpClient,
    public userSvc: UserService,
    public authHelper: AuthTokenHelper,
    public toastService: ToastService,) { }

    
    getExhibitAccountDetails(showId: string, accountId : number, errorCallback?: Function) {
    let exhAccountDtlURL=`${this.url()}events/${showId}/accountInfo/${accountId}`
      this.httpClient.get<ExhibitorAccountDto>(exhAccountDtlURL)
        .subscribe(data => {
          this.exhibitorAccountDetails.next(data);
        }, err => {
          if (err.status !== 401 && err.status !== 403) {
            this.toastService.open('There was an error retrieving user information for the show account', 'danger');
          }
          if (errorCallback) errorCallback();
        });
    }

  }

