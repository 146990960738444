import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { filter, takeWhile } from 'rxjs/operators';
import { AuthTokenHelper } from 'src/app/helpers/auth-token-helper';
import { FilterHelper } from 'src/app/helpers/filter-helper';
import { NavParamHelper } from 'src/app/helpers/nav-param-helper';
import { SearchHelper } from 'src/app/helpers/search-helper';
import { StatusHelper } from 'src/app/helpers/status-helper';
import { DashboardDTO } from 'src/app/models/dashboard-dto';
import { DashboardSubset } from 'src/app/models/dashboard-subset';
import { Show } from 'src/app/models/show';
import { ShowAccount } from 'src/app/models/show-account';
import { Status } from 'src/app/models/status';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';
import { EventService } from 'src/app/services/event/event.service';
import { FunctionalAreaService } from 'src/app/services/functional-area/functional-area.service';
import { ShowAccountService } from 'src/app/services/show-account/show-account.service';
//import { TabService } from 'src/app/services/tab/tab.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'occ-dashboard-view',
  templateUrl: './dashboard-view.component.html',
  styleUrls: ['./dashboard-view.component.scss'],
})
export class DashboardViewComponent implements OnInit ,OnDestroy{
	@Input() title: string;
	@Input() isLineItem = false;

	subscribe = true;
	dashboardDto: DashboardDTO;
	showId: string;
	showAccountId: string;
	functionalAreaId: string;
	statuses = StatusHelper.LineItemStatuses.filter(status => status.title != 'Photo' && status.title != 'Note');
	widthArray: string[] = [];
	valueArray: number[] = [];
	show: Show;
	showAccount: ShowAccount;
	liDashboardSubset: DashboardSubset;
	totalDashboardItems: number;
	wtDashboardSubset: DashboardSubset;
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public navCtrl: NavController,
		public dashboardSvc: DashboardService,
		public authTokenHelper: AuthTokenHelper,
		public toastService: ToastService,
		public navParamHelper: NavParamHelper,
		public funcSvc: FunctionalAreaService,
		public dataSvc: DataRefreshService,
		public showSvc: EventService,
		public showAccountSvc: ShowAccountService,
		public authSvc: AuthenticationService,
	//	public tabSvc: TabService,
		public filterHelper: FilterHelper,
		public searchHelper: SearchHelper) { 
      this.showId = this.activatedRoute.snapshot.paramMap.get('eventId') || this.dataSvc.eventActive;
		if (!authTokenHelper.isOpsUser) {
			this.showAccountId = this.activatedRoute.snapshot.paramMap.get('accountId') || this.dataSvc.showAccountActive;
		}
    }
  async ngOnInit() {
    
		if (await this.authTokenHelper.IsOpsUser()) {
			this.statuses =  StatusHelper.WorkTicketStatuses;
			this.dashboardSvc.wtDashboardDTO.pipe(takeWhile(() => this.subscribe)).pipe(filter(dto => dto !== null)).subscribe(dto => {
				this.wtDashboardSubset = {
					total: dto.wtNew + dto.wtInProgress + dto.wtOnHold + dto.wtComplete + dto.wtApproved,
					new: dto.wtNew,
					inProgress: dto.wtInProgress,
					onHold: dto.wtOnHold,
					complete: dto.wtComplete,
					approved: dto.wtApproved
				};
				this.calculateValues();
			});
		} else {
			this.dashboardSvc.dashboardDTO.pipe(takeWhile(() => this.subscribe)).pipe(filter(dto => dto !== null)).subscribe(dto => {
				this.liDashboardSubset = {
					total: dto.totalLineItems,
					new: dto.liNew,
					inProgress: dto.liInProgress,
					onHold: dto.liOnHold,
					complete: dto.liComplete,
					approved: dto.liApproved
				};

				this.calculateValues();
			});
		}
	}

	calculateValues() {
		var subset;
		if (this.authTokenHelper.isOpsUser) {
			subset = this.wtDashboardSubset;
			this.totalDashboardItems = this.wtDashboardSubset.total;
		}
		else {
			subset = this.liDashboardSubset;
			this.totalDashboardItems = this.liDashboardSubset.total;
		}


		this.widthArray = [
			`${subset.new / subset.total * 95}%`,
			`${subset.inProgress / subset.total * 95}%`,
			`${subset.onHold / subset.total * 95}%`,
			`${subset.complete / subset.total * 95}%`,
			`${subset.approved / subset.total * 95}%`,
		];
		this.valueArray = [
			subset.new,
			subset.inProgress,
			subset.onHold,
			subset.complete,
			subset.approved,
		];
		if(!this.authTokenHelper.isOpsUser){
			this.widthArray.push(`${subset.customerApproved / subset.total * 95}%`);
			this.valueArray.push(subset.customerApproved);
		}
	}

	searchFilterStatus(status: Status): void {
		this.filterHelper.resetAllFilters();
		this.searchHelper.searchString = '';
		this.setFilterHelperIds();
		if (this.authTokenHelper.isOpsUser) {
			this.filterHelper.setStatus(status, false, true);
		} else {
			this.filterHelper.setStatus(status, this.isLineItem);
		}
		if(this.showAccountId){
			this.router.navigate(["events", this.showId, 'accounts', this.showAccountId, 'search']); 
		} else {
			this.router.navigate(["events", this.showId, 'workticket-search']);
		}		
	}

	searchFilterVendor(vendor: string): void {
		this.filterHelper.resetAllFilters();
		this.searchHelper.searchString = '';
		this.setFilterHelperIds();
		this.filterHelper.setVendor(vendor);
		this.router.navigate(["events", this.showId, 'accounts', this.showAccountId, 'search']);
	}

	setFilterHelperIds() {
		this.filterHelper.eventId = this.showId;
		this.filterHelper.accountId = this.showAccountId;
	}

	ngOnDestroy() {
		this.subscribe = false;
	}

}
