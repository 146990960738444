import { Component, Input, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';
import { AuthTokenHelper } from 'src/app/helpers/auth-token-helper';
import { SelectHelper } from 'src/app/helpers/select-helper';
import { LITabsEnum } from 'src/app/helpers/tabs-enum';
import { ActivityItem } from 'src/app/models/activity-item';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';
import { SocialService } from 'src/app/services/social/social.service';
import { UserService } from 'src/app/services/user/user.service';
import { WorkticketActivityService } from 'src/app/services/workticket-activity/workticket-activity.service';
import { WorkticketLineItemService } from 'src/app/services/workticket-line-item/workticket-line-item.service';
import { takeWhile } from 'rxjs/operators';
import { PhotoCarouselComponent } from '../photo-carousel/photo-carousel.component';
import { Storage } from '@ionic/storage-angular';

@Component({
	selector: 'occ-work-ticket-photo-note-viewer',
	templateUrl: './work-ticket-photo-note-viewer.component.html',
	styleUrls: ['./work-ticket-photo-note-viewer.component.scss'],
})
export class WorkTicketPhotoNoteViewerComponent implements OnInit, OnDestroy {

	@Input() eventId: string;
	@Input() wtId: string;
	public isMobile = false;
	public azureUri: string;
	public azureToken: string;
	public isLoading: boolean;
	private subscribe = true;
	public activityItems: ActivityItem[];
	private activityItemsSubject: BehaviorSubject<ActivityItem[]>;
	@ViewChild('scroll') scroll: any;
	public firstLoad = false;
	@ViewChildren('itemElements') itemElements: any[];
	public showOverlay = true;
	public activeUserGuid: string;
	public activeUserName: string;
	public photoViewerModal;


	constructor(
		public workTikActivitySvc: WorkticketActivityService,
		private storage: Storage,
		public authTokenHelper: AuthTokenHelper,
		public userSvc: UserService,
		public dataSvc: DataRefreshService,
		public modalCtrl: ModalController,
		public socialSvc: SocialService,
		public navCtrl: NavController,
		public appBrowserHelper: AppBrowserHelper,
		public workTicketLineItemSvc: WorkticketLineItemService
	) {
		this.isMobile = this.checkMobile();
		this.storage.get('azure_uri').then((val) => {
			this.azureUri = val;
		});
		this.storage.get('azure_token').then((val) => {
			this.azureToken = val;
		});
	}

	ngOnInit() {
		this.isLoading = true;
		this.activityItems = [];
		this.activityItemsSubject = this.workTikActivitySvc.workTicketActivityItems;
		this.workTikActivitySvc.notePostedScroll.pipe(takeWhile(() => this.subscribe)).subscribe(() => {
			setTimeout(() => {
				this.scroller();
			}, 100);
		});

		this.activityItemsSubject.pipe(takeWhile(() => this.subscribe)).subscribe(items => {
			// this.activityItems = [];
			// if (this.activitySvc.refreshActivityItems) {
			// 	this.activitySvc.refreshActivityItems = false;
			// } else {
			this.activityItems = items;
			if (this.firstLoad) {
				// setTimeout(() => {
				// 	this.scroller();
				// }, 100);
				this.checkForItemsReady();
				this.firstLoad = false;
				this.isLoading = false;
			}
			// }
		});

		this.authTokenHelper.currentUserGuid.pipe(takeWhile(() => this.subscribe)).subscribe(x => this.activeUserGuid = x);
		this.authTokenHelper.currentUserName.pipe(takeWhile(() => this.subscribe)).subscribe(x => this.activeUserName = x);
		this.userSvc.showAccountUserDTO.pipe(takeWhile(() => this.subscribe)).subscribe(users => {
			this.activityItems = this.highlightMentions(this.activityItems);
		});


		this.firstLoad = true;
		this.workTikActivitySvc.get(this.eventId, this.wtId);


	}

	checkMobile(): boolean {
		return window.innerWidth <= 767;
	}

	scroller(): void {
		if (this.scroll)
			this.scroll.nativeElement.scrollTop = this.scroll.nativeElement.scrollHeight;
	}

	checkForItemsReady(): void {
		let timer = 0;
		const interval = setInterval(() => {
			timer += 10;
			if ((this.itemElements && this.itemElements.length > 0 && this.scroll) || timer >= 10000) {
				this.scroller();
				this.showOverlay = false;
				clearInterval(interval);
			}
		});
	}

	highlightMentions(items: ActivityItem[]): ActivityItem[] {
		items.forEach(item => {
			if (item.note) {
				item.note = this.workTikActivitySvc.styleMentionedUsers(item.note);
			}
		});
		return items;
	}

	textAreaAdjust(e) {
		e.target.style.height = '1px';
		e.target.style.height = (10 + e.target.scrollHeight) + 'px';
	}

	async presentPhotoViewer(photoId: string) {
		this.photoViewerModal = await this.modalCtrl.create({
			component: PhotoCarouselComponent,
			cssClass: 'photo-viewer-modal',
			showBackdrop: true,
			componentProps: {
				wtId: this.wtId,
				photoId,
				azureUri: this.azureUri,
				azureToken: this.azureToken,
			}
		});
		await this.photoViewerModal.present();
	}

	pushLineItemsPage(lineItemGuid: string) {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		if (!lineItemGuid) return;
		const lineItem = this.workTicketLineItemSvc.currentLineItemDto.find(li => li.kafkaId === lineItemGuid);
		if (!lineItem) return;

		const linkParams = {
			eventId: this.eventId,
			workTicketGuid: this.wtId,
			functionalAreaId: lineItem.functionalAreaGuid,
			showAccountId: lineItem.showAccountGuid,
			lineItemId: lineItem.kafkaId,
			pageTab: LITabsEnum.Activity
		};
		// this.navCtrl.setRoot('workticket-line-item-details', linkParams, { animate: !this.appBrowserHelper.isDesktop }, x => {
		// 	if (x === true) {
		// 		this.tabSvc.setHistory('workticket-line-item-details', linkParams);
		// 	}
		// });
	}

	sharePicture(photoId: string, photoName: string) {
		this.socialSvc.shareWorkTicketImageOnMobileSocialMedia(this.eventId, this.wtId, photoId, photoName);
	}

	ngOnDestroy() {
		this.subscribe = false;
	}

}
