import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { EmptyDataIndicatorComponent } from './empty-data-indicator/empty-data-indicator.component';
import { LoadSpinnerComponent } from './load-spinner/load-spinner.component';
import { WorkticketLineItemListComponent } from './workticket-line-item-list/workticket-line-item-list.component';
import { WorkticketInfoComponent } from './workticket-info/workticket-info.component';
import { WorkTicketFileViewerComponent } from './work-ticket-file-viewer/work-ticket-file-viewer.component';
import { FileDisplayComponent } from './file-display/file-display.component';
import { WorkTicketPhotoNoteViewerComponent } from './work-ticket-photo-note-viewer/work-ticket-photo-note-viewer.component';
import { PhotoCarouselComponent } from './photo-carousel/photo-carousel.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CancelOrderConfirmationComponent } from './cancel-order-confirmation/cancel-order-confirmation.component';
import { FilterStatusComponent } from './filter/filter-status/filter-status.component';
import { DashboardViewComponent } from './dashboard-view/dashboard-view.component';
import { WorkticketDetailsLineItemComponent } from './workticket-details-line-item/workticket-details-line-item.component';
import { PhotoNoteViewerComponent } from './photo-note-viewer/photo-note-viewer.component';
import { WorkTicketLineItemFileViewerComponent } from './work-ticket-line-item-file-viewer/work-ticket-line-item-file-viewer.component';
import { ActivityTimelineTabsComponent } from './activity-timeline-tabs/activity-timeline-tabs.component';
import { ActivityTimelineComponent } from './activity-timeline/activity-timeline.component';
import { MyNotificationsTimelineComponent } from './my-notifications-timeline/my-notifications-timeline.component';
import { ActivityTimelineFilterComponent } from './activity-timeline-filter/activity-timeline-filter.component';
import { TimelineItemComponent } from './timeline-item/timeline-item.component';
import { TimelineMentionComponent } from './timeline-mention/timeline-mention.component';
import { TimelineStatusChangeComponent } from './timeline-status-change/timeline-status-change.component';
import { TimelinePhotoNoteComponent } from './timeline-photo-note/timeline-photo-note.component';
import { ChangeLogFilterComponent } from './change-log-filter/change-log-filter.component';
import { ChangeOrderItemCardComponent } from './change-order-item-card/change-order-item-card.component';
import { ChangeOrderDetailsComponent } from './change-order-details/change-order-details.component';
import { SearchWorkCardComponent } from './search-work-card/search-work-card.component';
import { SearchWTItemCardComponent } from './search-wtitem-card/search-wtitem-card.component';
import { BoothSearchFilterComponent } from './booth-search-filter/booth-search-filter.component';
// import { MyDatePickerModule } from 'mydatepicker';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { SearchAreaCardComponent } from './search-area-card/search-area-card.component';
import { SearchItemCardComponent } from './search-item-card/search-item-card.component';
import { FilterFacilityComponent } from './filter/filter-facility/filter-facility.component';
import { FilterFunctionalAreaComponent } from './filter/filter-functional/filter-functional.component';
import { FilterLocationComponent } from './filter/filter-location/filter-location.component';
import { FilterSelectionComponent } from './filter/filter-selection/filter-selection.component';
import { FilterDateComponent } from './filter/filter-date/filter-date.component';
import { OccFabComponent } from './occ-fab/occ-fab.component';
import { ChangeOrderModalComponent } from './change-order-modal/change-order-modal.component';
import { FilterComponent } from './filter/filter.component';
import { FilterBoothComponent } from './filter/filter-booth/filter-booth.component';
import { FilterCategoryComponent } from './filter/filter-category/filter-category.component';
import { FilterOwnerComponent } from './filter/filter-owner/filter-owner.component';
import { FilterPartGroupComponent } from './filter/filter-part-group/filter-part-group.component';
import { FilterSelectionWorkticketComponent } from './filter/filter-selection-workticket/filter-selection-workticket.component';
import { FilterVendorComponent } from './filter/filter-vendor/filter-vendor.component';
import { LineItemListComponent } from './line-item-list/line-item-list.component';
import { BoothSelectionModalComponent } from './booth-selection-modal/booth-selection-modal.component';
import { ChangeStatusMenuComponent } from './change-status-menu/change-status-menu.component';
import { DetailsComponent } from './details/details.component';
import { FileViewerComponent } from './file-viewer/file-viewer.component';
import { LineItemFilterComponent } from './line-item-filter/line-item-filter.component';
import { LineItemChangeStatusComponent } from './line-item-change-status/line-item-change-status.component';
import { DetailsLineItemComponent } from './details-line-item/details-line-item.component';
import { IonicModule } from '@ionic/angular';
import { ChangeOrderConfirmationModalComponent } from './change-order-confirmation-modal/change-order-confirmation-modal.component';
import { OccMobileSelectModalComponent } from './occ-mobile-select-modal/occ-mobile-select-modal.component';
import { LineItemSelectionComponent } from './line-item-selection/line-item-selection.component';
import { NewOrderModalPage } from '../pages/new-order-modal/new-order-modal.page';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { KioskFooterComponent } from './kiosk-footer/kiosk-footer.component';
import { KioskHeaderComponent } from './kiosk-header/kiosk-header.component';
import { KioskMhaModalComponent } from './kiosk-mha-modal/kiosk-mha-modal.component';
import { KioskPrintLoaderComponent } from './kiosk-print-loader/kiosk-print-loader.component';
import { KioskPrintUnsuccessfulComponent } from './kiosk-print-unsuccessful/kiosk-print-unsuccessful.component';
import { OccSelectableOptionsComponent } from './occ-selectable-options/occ-selectable-options.component';
import { SelectOptionModalComponent } from './select-option-modal/select-option-modal.component';
import { ItemActionMenuComponent } from './item-action-menu/item-action-menu.component';
import { NumberDirective } from '../directives/number.directive';
import { OpsLineItemsSortbyComponent } from './ops-line-items-sortby/ops-line-items-sortby.component';
import { LocationSelectionModalComponent } from './location-selection-modal/location-selection-modal.component';
import { MakeoverFilterComponent } from './makeover-filter/makeover-filter.component';
import { ChangeOrderMenuComponent } from './change-order-menu/change-order-menu.component';
import { MakeoverSearchModalComponent } from './makeover-search-modal/makeover-search-modal.component';
import { HoursDirective } from '../directives/hours.directive';
import { MinutesDirective } from '../directives/minutes.directive';
import { ExhibitAccountDetailsComponent } from './exhibitor-service/exhibit-account-details/exhibit-account-details.component';
import { SortbyComponent } from './exhibitor-service/sortby/sortby.component';
import { MentionModule } from 'angular-mentions';
import { AccountComponent } from './exhibitor-service/account/account.component';
import { SendCodeComponent } from './kiosk/send-code/send-code.component';
import { CodeVerificationComponent } from './kiosk/code-verification/code-verification.component';
import { KioskLinkAccountModalComponent } from './kiosk-link-account-modal/kiosk-link-account-modal.component';
import { CountryCodeComponent } from './kiosk/country-code/country-code.component';
import { PhoneNumberComponent } from './kiosk/phone-number/phone-number.component';
import { SortbyMobileComponent } from './exhibitor-service/sortby-mobile/sortby-mobile.component';
import { BoothDetailsComponent } from './exhibitor-service/booth-details/booth-details.component';
import { MHACarrierComponent } from './kiosk/mhacarrier/mhacarrier.component';
import { MHACarrierTermsComponent } from './kiosk/mhacarrier-terms/mhacarrier-terms.component';
import { FormSelectComponent } from './form-select/form-select.component';
import { MHABillingComponent } from './kiosk/mhabilling/mhabilling.component';
import { MobileNumberFormatPipe } from '../pipes/mobile-number-format.pipe';
import { KioskFreightDetailsComponent } from './kiosk/kiosk-freight-details/kiosk-freight-details.component';
import { MhaShippingInfoComponent } from './kiosk/mha-shipping-info/mha-shipping-info.component';
import { MOPNotFoundComponent } from './kiosk/mop-not-found/mop-not-found.component';
import { AcknowledgeFreightPackageComponent } from './kiosk/acknowledge-freight-package/acknowledge-freight-package.component';
import { MhaConfirmationComponent } from './kiosk/mha-confirmation/mha-confirmation.component';
import { MhaConfirmationModalComponent } from './kiosk/mha-confirmation-modal/mha-confirmation-modal.component';
import { AddServiceRequestComponent } from './exhibitor-service/add-service-request/add-service-request.component';
import { ServiceRequestCardComponent } from './exhibitor-service/service-request-card/service-request-card.component';
import { BoothFilterComponent } from './booth-filter/booth-filter.component';
import { PipesModule } from '../pipes/pipes.module';
import { ActivityAddCommentMobileComponent } from './activity-add-comment-mobile/activity-add-comment-mobile.component';
import { AreaComponent } from './show-setup/area/area.component';
import { BoothAssignmentStatusComponent } from './show-setup/booth-assignment-status/booth-assignment-status.component';
import { ServiceProvidersComponent } from './show-setup/service-providers/service-providers.component';
import { FreightComponent } from './show-setup/freight/freight.component';
import { FreightDatetimeComponent } from './show-setup/freight-datetime/freight-datetime.component';
import { DuplicateZonesComponent } from './show-setup/duplicate-zones/duplicate-zones.component';
import { VipExhibitorConfirmationPopoverComponent } from './vip-exhibitor-confirmation-popover/vip-exhibitor-confirmation-popover.component';
import { MHASubmitSuccessComponent } from './kiosk/mhasubmit-success/mhasubmit-success.component';
import { BulkUpdateLineItemStatusModalComponent } from '../components/bulk-update-line-item-status-modal/bulk-update-line-item-status-modal.component';
import { PartGroupFilterComponent } from './part-group-filter/part-group-filter.component';
import { ShowFilterConfirmationComponent } from './show-setup/show-filter-confirmation/show-filter-confirmation.component';
import { ZipcodePipe } from '../pipes/zipcode-number-format.pipe';
import { CreateMHAComponent } from './create-mha/create-mha.component';
import { MhaCardComponent } from './exhibitor-service/mha-card/mha-card.component';

@NgModule({
  declarations: [
    EmptyDataIndicatorComponent,
    LoadSpinnerComponent,
    WorkticketLineItemListComponent,
    WorkticketInfoComponent,
    WorkTicketFileViewerComponent,
    FileDisplayComponent,
    WorkTicketPhotoNoteViewerComponent,
    PhotoCarouselComponent,
    OccFabComponent,
    ChangeOrderModalComponent,
    CancelOrderConfirmationComponent,
    FilterComponent,
    FilterBoothComponent,
    FilterCategoryComponent,
    FilterDateComponent,
    FilterFacilityComponent,
    FilterFunctionalAreaComponent,
    FilterLocationComponent,
    FilterOwnerComponent,
    FilterPartGroupComponent,
    FilterSelectionComponent,
    FilterSelectionWorkticketComponent,
    FilterStatusComponent,
    FilterVendorComponent,
    DashboardViewComponent,
    DashboardViewComponent,
    DashboardViewComponent,
    WorkticketDetailsLineItemComponent,
    PhotoNoteViewerComponent,
    WorkTicketLineItemFileViewerComponent,
    ActivityTimelineTabsComponent,
    ActivityTimelineComponent,
    MyNotificationsTimelineComponent,
    ActivityTimelineFilterComponent,
    TimelinePhotoNoteComponent,
    TimelineItemComponent,
    TimelineMentionComponent,
    TimelineStatusChangeComponent,
    LineItemListComponent,
    BoothSelectionModalComponent,
    ChangeStatusMenuComponent,
    LineItemSelectionComponent,
    OccMobileSelectModalComponent,
    DetailsComponent,
    FileViewerComponent,
    ChangeLogFilterComponent,
    ChangeOrderItemCardComponent,
    ChangeOrderDetailsComponent,
    SearchWorkCardComponent,
    SearchWTItemCardComponent,
    BoothSearchFilterComponent,
    SearchAreaCardComponent,
    SearchItemCardComponent,
    LineItemFilterComponent,
    LineItemChangeStatusComponent,
    FileViewerComponent,
    DetailsLineItemComponent,
    ChangeOrderConfirmationModalComponent,
    OccMobileSelectModalComponent,
    LineItemSelectionComponent,
    NewOrderModalPage,
    KioskFooterComponent,
    KioskHeaderComponent,
    KioskMhaModalComponent,
    KioskPrintLoaderComponent,
    KioskPrintUnsuccessfulComponent,
    OccSelectableOptionsComponent,
    SelectOptionModalComponent,
    ItemActionMenuComponent,
    NumberDirective,
    OpsLineItemsSortbyComponent,
    MakeoverSearchModalComponent,
    LocationSelectionModalComponent,
    MakeoverFilterComponent,
    ChangeOrderMenuComponent,
    HoursDirective,
    MinutesDirective,
    ExhibitAccountDetailsComponent,
    SortbyComponent,
    SortbyMobileComponent,
    BoothDetailsComponent,
    AccountComponent,
    CountryCodeComponent,
    SendCodeComponent,
    CodeVerificationComponent,
    KioskLinkAccountModalComponent,
    PhoneNumberComponent,
    MobileNumberFormatPipe,
    KioskFreightDetailsComponent,
    MHACarrierComponent,
    MHACarrierTermsComponent,
    FormSelectComponent,
    MHABillingComponent,
    MobileNumberFormatPipe,
    MhaShippingInfoComponent,
    MOPNotFoundComponent,
    AcknowledgeFreightPackageComponent,
    MhaConfirmationComponent,
    MhaConfirmationModalComponent,
    AddServiceRequestComponent,
    ServiceRequestCardComponent,
    BoothFilterComponent,
    ActivityAddCommentMobileComponent,
    AreaComponent,
    BoothAssignmentStatusComponent,
    ServiceProvidersComponent,
    FreightComponent,
    FreightDatetimeComponent,
    DuplicateZonesComponent,
    VipExhibitorConfirmationPopoverComponent,
    MHASubmitSuccessComponent,
    BulkUpdateLineItemStatusModalComponent,
    PartGroupFilterComponent,
    ShowFilterConfirmationComponent,
    ZipcodePipe,
    CreateMHAComponent,
    MhaCardComponent
  ],
  exports: [
    EmptyDataIndicatorComponent,
    LoadSpinnerComponent,
    WorkticketLineItemListComponent,
    WorkticketInfoComponent,
    WorkTicketFileViewerComponent,
    FileDisplayComponent,
    WorkTicketPhotoNoteViewerComponent,
    PhotoCarouselComponent,
    OccFabComponent,
    ChangeOrderModalComponent,
    CancelOrderConfirmationComponent,
    FilterComponent,
    FilterBoothComponent,
    FilterCategoryComponent,
    FilterDateComponent,
    FilterFacilityComponent,
    FilterFunctionalAreaComponent,
    FilterLocationComponent,
    FilterOwnerComponent,
    FilterPartGroupComponent,
    FilterSelectionComponent,
    FilterSelectionWorkticketComponent,
    FilterStatusComponent,
    FilterVendorComponent,
    DashboardViewComponent,
    DashboardViewComponent,
    DashboardViewComponent,
    WorkticketDetailsLineItemComponent,
    PhotoNoteViewerComponent,
    WorkTicketLineItemFileViewerComponent,
    ActivityTimelineTabsComponent,
    ActivityTimelineComponent,
    MyNotificationsTimelineComponent,
    ActivityTimelineFilterComponent,
    TimelinePhotoNoteComponent,
    TimelineItemComponent,
    TimelineMentionComponent,
    TimelineStatusChangeComponent,
    LineItemListComponent,
    BoothSelectionModalComponent,
    ChangeStatusMenuComponent,
    LineItemSelectionComponent,
    OccMobileSelectModalComponent,
    DetailsComponent,
    FileViewerComponent,
    ChangeLogFilterComponent,
    ChangeOrderItemCardComponent,
    ChangeOrderDetailsComponent,
    SearchWorkCardComponent,
    SearchWTItemCardComponent,
    BoothSearchFilterComponent,
    SearchAreaCardComponent,
    SearchItemCardComponent,
    LineItemFilterComponent,
    LineItemChangeStatusComponent,
    FileViewerComponent,
    DetailsLineItemComponent,
    ChangeOrderConfirmationModalComponent,
    OccMobileSelectModalComponent,
    LineItemSelectionComponent,
    NewOrderModalPage,
    KioskFooterComponent,
    KioskHeaderComponent,
    KioskMhaModalComponent,
    KioskPrintLoaderComponent,
    KioskPrintUnsuccessfulComponent,
    OccSelectableOptionsComponent,
    SelectOptionModalComponent,
    ItemActionMenuComponent,
    NumberDirective,
    OpsLineItemsSortbyComponent,
    MakeoverSearchModalComponent,
    LocationSelectionModalComponent,
    MakeoverFilterComponent,
    ChangeOrderMenuComponent,
    HoursDirective,
    MinutesDirective,
    ExhibitAccountDetailsComponent,
    SortbyComponent,
    SortbyMobileComponent,
    BoothDetailsComponent,
    AccountComponent,
    SendCodeComponent,
    CodeVerificationComponent,
    KioskLinkAccountModalComponent,
    CountryCodeComponent,
    PhoneNumberComponent,
    KioskFreightDetailsComponent,
    MHACarrierComponent,
    MHACarrierTermsComponent,
    FormSelectComponent,
    MHABillingComponent,
    MhaShippingInfoComponent,
    MOPNotFoundComponent,
    AcknowledgeFreightPackageComponent,
    MhaConfirmationComponent,
    MhaConfirmationModalComponent,
    AddServiceRequestComponent,
    ServiceRequestCardComponent,
    BoothFilterComponent,
    ActivityAddCommentMobileComponent,
    AreaComponent,
    BoothAssignmentStatusComponent,
    ServiceProvidersComponent,
    FreightComponent,
    FreightDatetimeComponent,
    DuplicateZonesComponent,
    VipExhibitorConfirmationPopoverComponent,
    MHASubmitSuccessComponent,
    BulkUpdateLineItemStatusModalComponent,
    PartGroupFilterComponent,
    ShowFilterConfirmationComponent,
    ZipcodePipe,
    CreateMHAComponent,
    MhaCardComponent
  ],
  imports: [
    CommonModule,
    VirtualScrollerModule,
    AngularMyDatePickerModule,
    FormsModule,
    MentionModule,
    IonicModule,
    PdfViewerModule,
    ReactiveFormsModule,
    PipesModule
  ],
  providers: [MobileNumberFormatPipe, ZipcodePipe]
})
export class ComponentsModule { }
