import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/*
  Generated class for the ChangeOrdersProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: 'root'
})
export class ChangeOrdersProvider {

  public filterSelections: any[] = [];
  public eventId: string;
	public accountId: string;
	public filterSelectionCount: number = 0;

  constructor(public http: HttpClient) {
  }

  getChangeLogFilterSelection(){
    return this.filterSelections;
  }

  setChangeLogFilterSelection(filterSelections: any[]){
    this.filterSelections = [...filterSelections];

    this.filterSelectionCount = 0;
		this.filterSelections.forEach(criteriaSelection => {
			if (criteriaSelection.value.length) {
				this.filterSelectionCount += 1;
			}
		});
  }

  resetChangeLogFilterSelection(){
    this.filterSelections = [
      { id: 1, name: "Vendor", value: [] },
      { id: 2, name: "Setup Start Date", value: [] },
      { id: 3, name: "Setup Complete Date", value: [] },
      { id: 4, name: "Invoice", value: [] },
      { id: 5, name: "Location", value: [] },
      { id: 6, name: "Category", value: [] },
      { id: 7, name: "Change Type", value: [] },
      { id: 8, name: "Change Request", value: [] },
      { id: 9, name: "Created Date", value: [] },
      { id: 10, name: "Revised Date", value: [] },
      { id: 11, name: "Created User", value: [] },
      { id: 12, name: "Revision Reasons", value: [] },
    ];
    this.filterSelectionCount = 0;
  }

}
