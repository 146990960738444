import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonAccordionGroup, ModalController } from '@ionic/angular';
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';
import { AreaTypeEnum } from 'src/app/helpers/area-type-enum';
import { ShowBooth } from 'src/app/models/show-booth-dto';
import { Area } from 'src/app/models/show-setup/area-dto';
import { FreightSetupService } from 'src/app/services/show-setup/freight-setup.service';
import { ShowSetupService } from 'src/app/services/show-setup/show-setup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import * as _ from 'lodash';
import { UserService } from 'src/app/services/user/user.service';
import { ShowAccountUserDTO } from 'src/app/models/show-account-user-dto';
import { CreateFreightZone, FreightZoneBooth, FreightZoneUser } from 'src/app/models/show-setup/create-freight-zone-dto';

@Component({
  selector: 'occ-freight',
  templateUrl: './freight.component.html',
  styleUrls: ['./freight.component.scss'],
})
export class FreightComponent implements OnInit {
  @ViewChild(IonAccordionGroup, { static: true }) accordionGroup: IonAccordionGroup;
  @Input() eventGUID: string;
  @Input() freightItem: any;
  @Input() isFreightZoneCopy: boolean;
  @Input() isNewFreightZone: boolean;
  isFreightZoneSaved: boolean = false;
  isAlreadyExist: boolean = false;
  selectedOption: any = '';
  freightData = <Area>{};
  currentFreightZoneGUID: string = '';
  freightZoneLabel = '';

  allBoothsList: ShowBooth[] = [];
  searchFilteredData: ShowBooth[] = [];
  selectedBooths: ShowBooth[] = [];
  boothSearchParam: string = '';
  boothStartRange: string = '';
  boothEndRange: string = '';
  isAllBoothSelected: boolean = false;
  isSelectedBTVisible: boolean = false;
  isCloseVisible: boolean = false;
  isRangeCloseVisible: boolean = false;
  users: any = [];
  showUserData= <ShowAccountUserDTO> {};
  selectedUsers: ShowAccountUserDTO[] = [];
  userSelected: string ='';
  isUserAlreadyExist: boolean = false;
  createFreightZone = <CreateFreightZone> {}
  boothGuids: FreightZoneBooth[] = [];
  freightZoneUsers: FreightZoneUser[] = [];
  emptiesReturnSelected:boolean = false;
  isValidDate : boolean = false;
  saveInitiated : boolean = false;

  isCalendarOpen: any;

  constructor(private modalCtrl: ModalController, public appBrowserHelper: AppBrowserHelper,
    private freightSetupSvc: FreightSetupService,  public showSetupService: ShowSetupService,
    public toastService: ToastService, 
    public userSvc: UserService,) {
    
  }

  async ngOnInit() {
    this.showSetupService.getBoothsList(this.eventGUID).subscribe((boothsData) => {
      this.allBoothsList = boothsData;
      this.setSelectedBooths();
      this.getSearchResult();
    });
    this.users = await this.getOpsUsersList();
    this.users.forEach(element => { 
      element.displayName = element.displayName.toLowerCase();
    });
    this.users = _.sortBy(this.users, ['displayName'], ['asc'] )
    
    if (this.users)
      this.setSelectedUsers();
    this.emptiesReturnSelected = this.freightItem?.cratesReturnedDate ? true : false;

    if (this.appBrowserHelper.isDesktop) {
      setTimeout(() => {
        this.selectedOption = 'groupname';
      }, 300);
    }
  }

  ngAfterViewInit() {
    this.initializeGroupingForm(this.freightItem);
  }

  getOpsUsersList() {
    return new Promise((resolve, reject) => {
      this.userSvc.getOpsUsers(this.eventGUID).subscribe(users => {
        if (users.length > 0)
          resolve(users);     
        else
          resolve([]);
      }, err => {
        resolve([]);
      })
    })
  }

  toggleAccordion(event) {
    this.selectedOption = this.accordionGroup.value;
  }

  closeModal() {
    this.modalCtrl.dismiss({
      'isFreightZoneSaved': this.isFreightZoneSaved
    });
  }

  initializeGroupingForm(data) {
    this.freightData = <Area>{};
    if (this.isFreightZoneCopy) {
      this.freightData.kafkaId = '';
      this.freightData.isActive = true;
      this.freightData.areaType = AreaTypeEnum.Freight_Zone;
      this.freightData.name = data?.name ? data.name.trim() : '';
      this.currentFreightZoneGUID = '';
      this.checkAreaExist();
    } else {
      this.freightData.kafkaId = data != undefined ? data.areaGuid : '';
      this.freightData.isActive = data != undefined ? null : true;
      this.freightData.areaType = data != undefined ? data.areaType : AreaTypeEnum.Freight_Zone;
      this.freightData.name = data != undefined ? data.name.trim() : '';
      this.currentFreightZoneGUID = data?.areaGuid ? data.areaGuid : '';
      this.freightZoneLabel = this.freightData.name;
    }
  }

  setSelectedBooths(){
    if(this.freightItem && this.freightItem.booths?.length){
      let allSelectedBoothGUIDs = this.freightItem.booths.map(booth => booth.boothGuid);
      this.allBoothsList.forEach(boothItem => {
        if(allSelectedBoothGUIDs.includes(boothItem.boothGUID)){
          boothItem.isBoothSelected = true;
          this.selectedBooths.push(boothItem);
        }
      });
    }
  }

  setSelectedUsers() {
    this.selectedUsers = [];
    if(this.freightItem?.areaGuid){
      this.userSvc.getSelectedUsers(this.eventGUID, this.currentFreightZoneGUID).subscribe((selectedUsers) => {
        if(this.currentFreightZoneGUID == selectedUsers[0].areaGuid){
          let selectedUserList = selectedUsers;
          selectedUserList?.forEach(user => {
           let matchedUser = _.filter(this.users,  ['kafkaId', user.userGuid])[0];
             if(matchedUser && !this.selectedUsers.includes(matchedUser)) {
              matchedUser.isUserSelected = true;
              this.selectedUsers.push(matchedUser)
             }  
             if(!matchedUser && user.kafkaId) {
               user.displayName = user.name;
                this.users.push(user);
                user.isUserSelected = true;
                this.selectedUsers.push(user)
             }
           }); 
        }
       });
    }   
  }

  checkAreaExist() {
    if (this.freightData.name) {
      this.freightData.name = this.freightData.name.trim();

      if(!this.isNewFreightZone && this.freightData.name.toLowerCase() == this.freightItem.name.toLowerCase()){
        this.isAlreadyExist = false;
        return;
      }
      this.freightSetupSvc.checkAreaExist(this.eventGUID, this.freightData.areaType, this.freightData.name).subscribe((submitResponse) => {
        this.isAlreadyExist = submitResponse;
      });
    }
  }

  onBoothSearchParamChange() {
    this.isCloseVisible = this.boothSearchParam ? !this.isCloseVisible : false;
    this.boothSearchParam = this.isCloseVisible ? this.boothSearchParam : '';
    this.getSearchResult();
  }

  getBoothRangeFilterData() {    
    this.isRangeCloseVisible = this.boothStartRange || this.boothEndRange ? !this.isRangeCloseVisible : false;
    this.boothStartRange = this.isRangeCloseVisible ? this.boothStartRange : '';
    this.boothEndRange = this.isRangeCloseVisible ? this.boothEndRange : '';
    this.getSearchResult();
  }

  toggleAllBoothSelection(){
    this.isAllBoothSelected = !this.isAllBoothSelected;
    this.searchFilteredData.forEach(boothItem => {
      boothItem.isBoothSelected = this.isAllBoothSelected;
    });

    if(this.isAllBoothSelected){
      this.searchFilteredData.forEach(boothItem => {
        this.selectedBooths.push(boothItem);
      });
      
      this.selectedBooths = _.uniqBy(this.selectedBooths, function(selectedBoothItem) {
        return selectedBoothItem.boothGUID;
      })
    } else{
      this.searchFilteredData.forEach(boothItem => {
        _.remove(this.selectedBooths, function(selectedBoothItem) {
          return selectedBoothItem.boothGUID == boothItem.boothGUID;
        });
      });
    }
  }

  toggleBoothSelection(boothItem: ShowBooth){
    boothItem.isBoothSelected = !boothItem.isBoothSelected;
    if(boothItem.isBoothSelected){
      this.selectedBooths.push(boothItem);
      this.selectedBooths = _.uniqBy(this.selectedBooths, function(selectedBoothItem) {
        return selectedBoothItem.boothGUID;
      })
    } else{
      _.remove(this.selectedBooths, function(selectedBoothItem) {
        return selectedBoothItem.boothGUID == boothItem.boothGUID;
      });
    }
    this.updateAllSectionStatus();
  }

  updateAllSectionStatus(){
    this.isAllBoothSelected = !this.searchFilteredData.length || _.find(this.searchFilteredData, (boothItem) => { return !boothItem.isBoothSelected; }) ? false : true ;
  }

  getSearchResult(isAllRemoveEnabled?: boolean){
    let isBoothRangeValid = true;
    if(this.boothStartRange?.length && this.boothEndRange?.length){
      isBoothRangeValid = (this.boothEndRange?.toLowerCase().localeCompare(this.boothStartRange?.toLowerCase(), 'en', { numeric: true }) != -1);
      if(!isBoothRangeValid){
        this.toastService.open('Start range must be less than End range for booth search.', 'danger');
        return;
      }
    }

    if(!this.boothSearchParam.length){
      this.searchFilteredData = this.allBoothsList;
    } else {
      this.searchFilteredData = _.filter(this.allBoothsList, (boothItem) => {
        return boothItem.boothName.toLowerCase().includes(this.boothSearchParam?.toLowerCase());
      });
    }
 
    if(this.boothStartRange?.length && this.boothEndRange?.length){
      isBoothRangeValid = (this.boothEndRange?.toLowerCase().localeCompare(this.boothStartRange?.toLowerCase(), 'en', { numeric: true }) != -1);
      if(!isBoothRangeValid){
        this.toastService.open('Start range must be less than End range for booth search.', 'danger');
        return;
      }
    }

    if(isBoothRangeValid){
      if(this.boothStartRange?.length){
        this.searchFilteredData = _.filter(this.searchFilteredData, (srItem) => {
          return srItem.boothName?.toLowerCase().localeCompare(this.boothStartRange?.toLowerCase(), 'en', { numeric: true }) != -1;
        });
      }
  
      if(this.boothEndRange?.length){
        this.searchFilteredData = _.filter(this.searchFilteredData, (srItem) => {
          return srItem.boothName?.toLowerCase().localeCompare(this.boothEndRange?.toLowerCase(), 'en', { numeric: true }) != 1;
        });
      }
    } else {
      this.toastService.open('Start range must be less than End range for booth search.', 'danger');
    }

    let selectedBoothGUIDs = this.selectedBooths.map(booth => booth.boothGUID)
    _.remove(this.searchFilteredData, function(boothItem) {
      return selectedBoothGUIDs.includes(boothItem.boothGUID);
    });

    if(this.boothSearchParam || this.boothStartRange || this.boothEndRange){     
      if(!isAllRemoveEnabled){   
        this.isAllBoothSelected = true;
        this.searchFilteredData.forEach(boothItem => {
          boothItem.isBoothSelected = this.isAllBoothSelected;
        });

        this.searchFilteredData.forEach(boothItem => {
          this.selectedBooths.push(boothItem);
        });
        
        this.selectedBooths = _.uniqBy(this.selectedBooths, function(selectedBoothItem) {
          return selectedBoothItem.boothGUID;
        })
      }
    }

    this.updateAllSectionStatus();
    this.searchFilteredData = this.getSortedDataList(this.searchFilteredData);
  }

  removeSelectedBooth(boothItem: ShowBooth){
    let removedBoothItems = _.remove(this.selectedBooths, function(selectedBoothItem) {
      return selectedBoothItem.boothGUID == boothItem.boothGUID;
    });
    removedBoothItems.forEach(rbItem => {
      rbItem.isBoothSelected = false;
    });
    
    this.allBoothsList = _.uniqBy(this.allBoothsList.concat(removedBoothItems), function(currentBoothItem) {
      return currentBoothItem.boothGUID;
    })
    this.getSearchResult(true);    
    this.isSelectedBTVisible = this.selectedBooths?.length ? true : false;
  }

  saveFreightZoneInfo() {
    if(!this.isValidDateInput()) return;
    this.saveInitiated = !this.saveInitiated;
    this.freightSetupSvc.saveFreightZoneInfo(this.eventGUID, {
      BoothGuids: this.selectedBooths.map(booth => booth.boothGUID),
      FreightUsers: this.prepareFreightZoneUserData(),
      IsNew: (this.isNewFreightZone || this.isFreightZoneCopy) ? true : false,
      Name: this.freightData.name,
      AreaType: AreaTypeEnum.Freight_Zone,
      AreaGuid: this.currentFreightZoneGUID,
      CratesReturnedDate: this.createFreightZone.CratesReturnedDate ?  this.createFreightZone.CratesReturnedDate : this.freightItem?.cratesReturnedDate,
      FibersReturnedDate: this.createFreightZone.FibersReturnedDate ? this.createFreightZone.FibersReturnedDate : this.freightItem?.fibersReturnedDate
    }).subscribe((fzSaveResponse) => {
      if(fzSaveResponse) {
        this.modalCtrl.dismiss({
          'isFreightZoneSaved': true
        });
      }
      this.saveInitiated = !this.saveInitiated;
    });
  }

  getSortedDataList(boothData) {
    return boothData.sort((a, b) => {
      if (!a.boothName || !b.boothName) {
        return;
      }
      return a.boothName.toLowerCase().localeCompare(b.boothName.toLowerCase(), 'en', { numeric: true });
    });
  }

   toggleUserSelection(user){
     user.isUserSelected = !user.isUserSelected;
     if(user.isUserSelected) {
       this.selectedUsers.push(user);
     }else {
       _.remove(this.selectedUsers, {displayName: user.displayName})
     }
   }

  isValidDateInput() {
    if( this.createFreightZone.CratesReturnedDate != null && this.createFreightZone.CratesReturnedDate != undefined && this.createFreightZone.CratesReturnedDate.length == 5) {
      this.toastService.open('Please select valid date', 'danger')
      return false
    } else if(this.createFreightZone.FibersReturnedDate != null && this.createFreightZone.FibersReturnedDate != undefined && this.createFreightZone.FibersReturnedDate.length == 5) {
      this.toastService.open('Please select valid date', 'danger')
      return false
    }
    return true
  } 
  
  onFiberDateChange(date) {
    if(date.freightDateTime) {
      this.isValidDate = !this.isValidDate;
      this.createFreightZone.FibersReturnedDate = date.freightDateTime;
    } else if(date.freightTime){
      this.createFreightZone.FibersReturnedDate = date.freightTime
      this.isValidDate = false;
    }    
  }

  onWoodenDateChange(date) {
    if(date.freightDateTime) {
      this.isValidDate = !this.isValidDate;
      this.createFreightZone.CratesReturnedDate = date.freightDateTime;
      this.emptiesReturnSelected = true;
    }else if(date.freightTime){
      this.createFreightZone.CratesReturnedDate =  date.freightTime;
    }
  }

  prepareFreightZoneUserData() {
    for(var selectedUser of this.selectedUsers) {
      var freightZoneUser = <FreightZoneUser>{};
      freightZoneUser.UserGuid = selectedUser.kafkaId;
      freightZoneUser.Name = selectedUser.displayName;
      this.freightZoneUsers.push(freightZoneUser);
    }
    return this.freightZoneUsers;
  }

  removeAllZoneBooths(){    
    this.allBoothsList = _.uniqBy(this.allBoothsList.concat(this.selectedBooths), function(currentBoothItem) {
      return currentBoothItem.boothGUID;
    })
    this.selectedBooths = [];
    this.allBoothsList.forEach(boothItem => {
      boothItem.isBoothSelected = false;
    });
    this.getSearchResult(true);
  }

  calendarToggle(event) {
    console.log(event);
    if (event == 1) 
      this.isCalendarOpen = true;
    else  
      this.isCalendarOpen = false;
  }
}