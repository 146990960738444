import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { NavParamHelper } from 'src/app/helpers/nav-param-helper';
import { SelectHelper } from 'src/app/helpers/select-helper';
import { ChangeOrderDTO } from 'src/app/models/change-order-dto';
import { CollaborationLineItemDTO } from 'src/app/models/collaboration-line-item-dto';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';
import { FunctionalAreaService } from 'src/app/services/functional-area/functional-area.service';
import { LineItemService } from 'src/app/services/line-item/line-item.service';
import { environment } from 'src/environments/environment';
import { ItemActionMenuComponent } from '../item-action-menu/item-action-menu.component';

@Component({
  selector: 'occ-change-order-item-card',
  templateUrl: './change-order-item-card.component.html',
  styleUrls: ['./change-order-item-card.component.scss'],
})
export class ChangeOrderItemCardComponent implements OnInit {
  @Input() changeOrder: ChangeOrderDTO;
	@Input() revisionInfo: string = "";

	eventId: string;
	accountId: string;
	funcId: string;
	isUnread: boolean = true;
	statusMenuPopover: any;
	envIsUAT = environment.environment === 'UAT';
  constructor(private activatedRoute: ActivatedRoute,
    public router: Router,
    public popoverCtrl: PopoverController,
    public dataSvc: DataRefreshService,
    public navParamHelper: NavParamHelper,
    public funcSvc: FunctionalAreaService,
    public liSvc: LineItemService) { 
    this.eventId = this.activatedRoute.snapshot.paramMap.get('eventId') || this.dataSvc.eventActive;
		this.accountId = this.activatedRoute.snapshot.paramMap.get('accountId') || this.dataSvc.showAccountActive;
		this.funcId = this.dataSvc.functionalAreaActive;
		this.navParamHelper.syncActiveInfo(this.eventId, this.accountId);
  }

  ngOnInit() {}

  pushDetailsPage(item: CollaborationLineItemDTO) {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		// check the current line item value before doing anything
		const currentLineItem = this.liSvc.currentLineItem.getValue();

		// if it doesn't exist or if it's different, update it.
		if (!currentLineItem || currentLineItem !== item) {
			this.liSvc.currentLineItem.next(item);
		}

		const currentFunctionalArea = this.funcSvc.currentFunctionalAreas.find(fa => fa.functionalAreaGuid === item.functionalAreaGuid);
		this.funcSvc.currentFuncAreaSubject.next(currentFunctionalArea);
    this.router.navigate(['/events',this.eventId,
    '/accounts',this.accountId,
    '/functional-areas', item.functionalAreaGuid, 
    '/line-items', item.kafkaId,
    'line-item-details']);
	}

	async presentPopover(event, item: CollaborationLineItemDTO) {
    const popover = await this.popoverCtrl.create({
      component: ItemActionMenuComponent,
      translucent: true,
      event: event,
      componentProps: {
        'item' : item,
        'fromSearch' : true
      }
    });
		this.statusMenuPopover.present();
		event.stopPropagation();
	}

	getDayCalculation(dateString) {
		let createdDate = new Date(dateString);
		let currentDate = new Date();

		var dateDifference = (currentDate.getTime() - createdDate.getTime()) / 1000;

		let numberOfYears = Math.abs(Math.round(dateDifference / (60 * 60 * 24 * 30 * 365)));
		if (numberOfYears) {
			return numberOfYears > 1 ? numberOfYears + " years ago" : numberOfYears + " year ago";
		}

		let numberOfMonths = Math.abs(Math.round(dateDifference / (60 * 60 * 24 * 30)));
		if (numberOfMonths) {
			return numberOfMonths > 1 ? numberOfMonths + " months ago" : numberOfMonths + " month ago";
		}

		let numberOfDays = Math.abs(Math.round(dateDifference / (60 * 60 * 24)));
		if (numberOfDays) {
			return numberOfDays > 1 ? numberOfDays + " days ago" : numberOfDays + " day ago";
		}

		let numberOfHours = Math.abs(Math.round(dateDifference / (60 * 60)));
		if (numberOfHours) {
			return numberOfHours > 1 ? numberOfHours + " hours ago" : numberOfHours + " hour ago";
		}

		let numberOfMinutes = Math.abs(Math.round(dateDifference / 60));
		if (numberOfMinutes) {
			return numberOfMinutes > 1 ? numberOfMinutes + " minutes ago" : numberOfMinutes + " minute ago";
		}

		return "Just now"
	}

	getReviseReasonString(reasonIdString) {
		let concatedReasons = "";
		if (reasonIdString) {
			let idArray = reasonIdString.split(',').filter(Boolean);
			idArray.forEach(reasonId => {
				switch (reasonId) {
					case "1":
						concatedReasons = concatedReasons + ", Quantity Changed";
						break;
					case "11":
						concatedReasons = concatedReasons + ", Date Time Change";
						break;
					case "3":
						concatedReasons = concatedReasons + ", Revised Comments";
						break;
					case "9":
						concatedReasons = concatedReasons + ", Facility Location Change";
						break;
					case "10":
						concatedReasons = concatedReasons + ", Invoice Change";
						break;
					case "5":
						concatedReasons = concatedReasons + ", Other";
						break;
				}
			});
			concatedReasons = concatedReasons.substring(1);
		}
		return concatedReasons;
	}

}
