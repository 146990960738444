import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController, NavParams } from '@ionic/angular';
import { ActivityPhoto } from 'src/app/models/activity-photo';
import { Storage } from '@ionic/storage';
import { ActivityService } from 'src/app/services/activity/activity.service';
import { BoothServiceProvider } from 'src/app/services/booth/booth.service';
import { WorkticketActivityService } from 'src/app/services/workticket-activity/workticket-activity.service';
import { EsBoothsService } from 'src/app/services/es-booths/es-booths.service';

@Component({
	selector: 'occ-photo-carousel',
	templateUrl: './photo-carousel.component.html',
	styleUrls: ['./photo-carousel.component.scss'],
})
export class PhotoCarouselComponent implements OnInit {

	@ViewChild('slidesRef', {static: true}) slidesRef: IonSlides;
	public isLineItem: boolean;
	public photos: ActivityPhoto[];
	public azureUri: string;
	public azureToken: string;
	public isMobile = false;
	public initialSlideIndex: number;
	public wtId: string;
	public boothGUID: string;
	@Input() isExhibitUser: any;
	@Input() serviceRequestGUID: any;
	public slideOpts: any = {
		initialSlide: 0,
		loop:true,
		centeredSlides: true,
		zoom: true
	  };

	constructor(
		public activitySvc: ActivityService,
		public modalCtrl: ModalController,
		public navParams: NavParams,
		public storage: Storage,
		public workTikActivitySvc: WorkticketActivityService,
		public boothService: BoothServiceProvider,
		public esBoothService: EsBoothsService
	) {
		
	}
	ngOnInit(): void {
		this.isLineItem = this.navParams.data.isLineItem;
		this.azureUri = this.navParams.data.azureUri;
		this.azureToken = this.navParams.data.azureToken;
		this.wtId = this.navParams.data.wtId;
		this.boothGUID = this.navParams.data.boothGUID;
		if (this.serviceRequestGUID || this.isExhibitUser) {
			this.photos = this.esBoothService.fetchActivityPhotos();
		} else if (this.boothGUID) {
			this.photos = this.boothService.fetchActivityPhotos();
		} else {
			this.photos = this.wtId ? this.workTikActivitySvc.fetchPhotos() : this.activitySvc.fetchPhotos(this.isLineItem);
		}

		this.isMobile = this.checkMobile();
		const photoId = this.navParams.get('photoId');
		this.initialSlideIndex = this.photos.findIndex(photo => photo.kafkaId === photoId);
		this.slidesRef.slideTo(this.initialSlideIndex);
		this.slidesRef.update();
	}


	checkMobile(): boolean {
		return window.innerWidth <= 767;
	}

	ionViewDidEnter() {
		if (!this.isMobile) {
			this.slidesRef.lockSwipes(true);
		}
	}

	slidePrev() {
		this.slidesRef.lockSwipes(false);
		this.slidesRef.slidePrev();
		this.slidesRef.lockSwipes(true);
	}

	slideNext() {
		this.slidesRef.lockSwipes(false);
		this.slidesRef.slideNext();
		this.slidesRef.lockSwipes(true);
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}

	ionViewDidLoad() {
		document.getElementsByTagName('ion-app').item(0).classList.add('modal-open');
	}

	ionViewWillLeave() {
		if (document.getElementsByTagName('ion-app').item(0).classList.contains('modal-open'))
			document.getElementsByTagName('ion-app').item(0).classList.remove('modal-open');
	}

}
