import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ZipcodePipe } from 'src/app/pipes/zipcode-number-format.pipe';
import { CreateMhaService } from 'src/app/services/create-mha/create-mha.service';
import { KioskService } from 'src/app/services/kiosk/kiosk.service';

@Component({
  selector: 'kiosk-mhabilling',
  templateUrl: './mhabilling.component.html',
  styleUrls: ['./mhabilling.component.scss'],
})
export class MHABillingComponent implements OnInit {
  isInvalidPhoneNumber: boolean = false;
  isSameShippingInfo: boolean = true;
  isFormInvalid: boolean = false;
  billingInfoForm: FormGroup;

  @Input() isFormDisabled: any = false;
  @Input() eventGuid: any;
  @Output() cancelMhaCreation = new EventEmitter();
  @Output() loadNextMHASection = new EventEmitter();
  @Output() onFormInvalid = new EventEmitter<any>();
  @Output() loadPreviousMHASection = new EventEmitter<any>();

  constructor(public router: Router, 
    public kioskService: KioskService,
    public createMHAService: CreateMhaService,
    private zipcode: ZipcodePipe, 
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.initializeBillingInfoForm(this.createMHAService.createMhaData.billing);
    this.isSameShippingInfo = this.createMHAService.createMhaData.billing?.isSameShippingInfo;
    if(this.isSameShippingInfo && this.createMHAService.createMhaData.shipping && this.createMHAService.createMhaData.carrier.isExhibitTransportation != 1){      
      this.createMHAService.createMhaData.billing = this.getBillingDataFromShipping();
      this.initializeBillingInfoForm(this.createMHAService.createMhaData.billing);      
    } 
  }

  initializeBillingInfoForm(data = null) {
    this.billingInfoForm = this.formBuilder.group({      
      firstName: new FormControl(data && data.firstName || null, [Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/)]),
      lastName: new FormControl(data && data.lastName || null, [Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/)]),
      mobileNo: new FormControl(data && data.mobileNo || null, Validators.minLength(12)),
      companyName: new FormControl(data && data.companyName || null, [Validators.required]),
      addressLine1: new FormControl(data && data.addressLine1 || null, [Validators.required]),
      addressLine2: new FormControl(data && data.addressLine2 || null),
      city: new FormControl(data && data.city || null, [Validators.required]),
      state: new FormControl(data && data.state || null, [Validators.required]),
      country: new FormControl(data && data.country || null, [Validators.required]),
      postalCode: new FormControl(data && data.postalCode || null, [Validators.required, Validators.pattern(/^[0-9-]*$/), Validators.minLength(5)])
    });

    setTimeout(() => {
      this.billingInfoForm.valueChanges.subscribe(() => {
        Object.keys(this.billingInfoForm.value).forEach((key) => {
          this.createMHAService.createMhaData.billing[key] = this.billingInfoForm.value[key];
        });
      })
    }, 500);
  }

  getBillingDataFromShipping(){
    let billingData = {
      isSameShippingInfo: true,
      firstName: this.createMHAService.createMhaData.shipping.contactFName,
      lastName: this.createMHAService.createMhaData.shipping.contactLName,
      phoneCountryCode: {},
      mobileNo:this.createMHAService.createMhaData.shipping.phoneNumber,
      companyName:this.createMHAService.createMhaData.shipping.companyName,
      addressLine1:this.createMHAService.createMhaData.shipping.address,
      addressLine2:this.createMHAService.createMhaData.shipping.address2,
      city:this.createMHAService.createMhaData.shipping.city,
      state:this.createMHAService.createMhaData.shipping.state,
      country:this.createMHAService.createMhaData.shipping.country,
      postalCode:this.createMHAService.createMhaData.shipping.zipCode
    }
    return billingData;
  }

  onPhoneNumberChange(numberChangeInfo){
    let mobileNumber = "";
    if (!numberChangeInfo.isNumberInvalid) {
      mobileNumber = numberChangeInfo.phoneNumber;
      this.isInvalidPhoneNumber = false;
    }else{
      this.isInvalidPhoneNumber = true;
    }
    this.billingInfoForm.patchValue({
      mobileNo: mobileNumber
    });
  }

  setCountryCodeSelection(currentCountryCodeSelection) {
  } 

  changeBillingInfo(){
    if(!this.isSameShippingInfo){
      this.createMHAService.createMhaData.billing = {isSameShippingInfo: this.isSameShippingInfo};
      this.initializeBillingInfoForm(this.createMHAService.createMhaData.billing);
    } else {
      this.createMHAService.createMhaData.billing = this.getBillingDataFromShipping();
      this.initializeBillingInfoForm(this.createMHAService.createMhaData.billing);
    }
  }

  onSelectionChange(event, controlName) {
    let objectToUpdate = {};
    objectToUpdate[controlName] = event;
    this.billingInfoForm.patchValue(objectToUpdate);
    this.createMHAService.createMhaData.billing
  }

  cancelMHACreation() {
    this.cancelMhaCreation.emit();  
  }

  goToPreviousStep() {
    this.loadPreviousMHASection.emit(2);
  }

  async createNewMHARecord() {
    if (this.createMHAService.mhaGUID) {
      this.loadNextMHASection.emit(4);
      return;
    }
    this.isFormInvalid = false;
    if ((!this.isSameShippingInfo && !this.billingInfoForm.valid) || this.isInvalidPhoneNumber) {
      this.isFormInvalid = true;
      this.onFormInvalid.emit();
      return;
    } else if (!this.isSameShippingInfo) {
      let res = await this.createMHAService.validateZipCode(this.eventGuid, 'billing');
      if (!res) {
        this.onFormInvalid.emit("Postal Code is invalid for the selected state");
        return;
      } else {
        Object.keys(this.billingInfoForm.value).forEach((key) => {
          this.createMHAService.createMhaData.billing[key] = this.billingInfoForm.value[key];
        });
        this.loadNextMHASection.emit(4);
      }
    } else {
      Object.keys(this.billingInfoForm.value).forEach((key) => {
        this.createMHAService.createMhaData.billing[key] = this.billingInfoForm.value[key];
      });
      this.loadNextMHASection.emit(4);
    }
  }

  getCarrierName(){
    if(this.createMHAService.createMhaData.carrier.otherCarrierName || (this.createMHAService.createMhaData.carrier.anotherCarrierInfo && this.createMHAService.createMhaData.carrier.anotherCarrierInfo.display)){
      return this.createMHAService.createMhaData.carrier.otherCarrierName ? this.createMHAService.createMhaData.carrier.otherCarrierName : this.createMHAService.createMhaData.carrier.anotherCarrierInfo.display;
    } else {
      return "Freeman Transportation";
    }
    
  }

  changeZipCodeFormat(event){
    let postalCode = this.zipcode.transform(event.target.value);
    this.billingInfoForm.patchValue({postalCode});
  }
}