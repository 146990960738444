import { Injectable } from "@angular/core";


@Injectable({
	providedIn: 'root'
})
export class FileDownloadHelper {


    downloadFile(data: Blob, name, loading) {
		const url= window.URL.createObjectURL(data);
		var anchor = document.createElement("a");
		anchor.download = name;
		anchor.href = url;
		anchor.click();
		loading.dismiss();
	  }
 }