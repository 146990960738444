import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ShowFiltersService } from 'src/app/services/show-setup/show-filters.service';

@Component({
  selector: 'occ-show-filter-confirmation',
  templateUrl: './show-filter-confirmation.component.html',
  styleUrls: ['./show-filter-confirmation.component.scss'],
})
export class ShowFilterConfirmationComponent implements OnInit {

  @Input() eventId: any;
  @Input() popupType: any;
  @Input() requestData: any;

  constructor(
    private popoverCtrl: PopoverController,
    private showFiltersService: ShowFiltersService
  ) { }

  ngOnInit() {
    if (this.popupType == 'success')
      this.showSuccessPopup();
  }

  showSuccessPopup() {
    this.popupType = 'success';
    setTimeout(() => {
      this.closePopover();
    }, 4000);
  }

  closePopover() {
    this.popoverCtrl.dismiss();
  }

  saveFilters() {
    this.showFiltersService.saveShowFilters(this.eventId, this.requestData).then(res => {
      if (res) {
        this.popoverCtrl.dismiss({ isSaved: true });
        this.showFiltersService.getShowFilters(this.eventId).then(res => {
          if (res)
            this.showFiltersService.getCurrentShowFilters(this.eventId);
        })
      }

    });
  }

}
