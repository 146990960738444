import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ToastService } from '../toast/toast.service';
import { AppBrowserHelper } from '../../helpers/app-browser-helper';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

	url = environment.downloadBaseUrl;
	public latestAppVersion: string;
	public currentAppVersion = environment.version;

	constructor(public http: HttpClient, public toastService: ToastService, public appBrowserHelper: AppBrowserHelper,) {}

	async get() {
		this.http
			.get<any>(`${this.url()}version`, { responseType: 'text' as 'json' })
			.subscribe(async data => {
				this.latestAppVersion = data;
				console.log('currentAppVersion', this.currentAppVersion);
				console.log('latestAppVersion', data);
				if ((this.latestAppVersion !== this.currentAppVersion) && this.appBrowserHelper.isCapacitor) {
					this.toastService.open('There is a new version of the application, Click Here to Update.', 'primary', true, 5000);
				}
			}, err => {
				console.log('error: ', err);
				if (err.status !== 401 && err.status !== 403) {
					//this.toastService.open('There was an error retrieving app version information', 'danger');
				}
			});
	}
}
