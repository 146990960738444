export class FileTypeHelper {

	public static fileTypeEnum = {
		PDF: { id: 1, color: 'file-pdf', icon: 'fa-file-pdf', extension: 'pdf' },
		Unknown: {id: 2, color: 'file-unknown', icon: 'fa-file', extension: 'unknown'},
		Excel: {id: 3, color: 'file-excel', icon: 'fa-file-excel', extension: 'excel' },
		Word: {id: 4, color: 'file-word', icon: 'fa-file-word', extension: 'word'},
		Image: {id: 5, color: 'file-image', icon: 'fa-file-image', extension: 'image'},
		Zip: {id: 6, color: 'file-zip', icon: 'fa-file-archive', extension: 'zip'},
		PowerPoint: {id: 7, color: 'file-powerpoint', icon: 'fa-file-powerpoint', extension: 'powerpoint'}
	};

	public static fileExtensionObject = {
		'.pdf': FileTypeHelper.fileTypeEnum.PDF,
		'.xlsx': FileTypeHelper.fileTypeEnum.Excel,
		'.xlsm': FileTypeHelper.fileTypeEnum.Excel,
		'.xltx': FileTypeHelper.fileTypeEnum.Excel,
		'.xltm': FileTypeHelper.fileTypeEnum.Excel,
		'.csv': FileTypeHelper.fileTypeEnum.Excel,
		'.xls': FileTypeHelper.fileTypeEnum.Excel,
		'.doc': FileTypeHelper.fileTypeEnum.Word,
		'.docx': FileTypeHelper.fileTypeEnum.Word,
		'.txt': FileTypeHelper.fileTypeEnum.Word,
		'.img': FileTypeHelper.fileTypeEnum.Image,
		'.png': FileTypeHelper.fileTypeEnum.Image,
		'.jpg': FileTypeHelper.fileTypeEnum.Image,
		'.jpeg': FileTypeHelper.fileTypeEnum.Image,
		'.bmp': FileTypeHelper.fileTypeEnum.Image,
		'.tiff': FileTypeHelper.fileTypeEnum.Image,
		'.zip': FileTypeHelper.fileTypeEnum.Zip,
		'.7z': FileTypeHelper.fileTypeEnum.Zip,
		'.rar': FileTypeHelper.fileTypeEnum.Zip,
		'.ppt': FileTypeHelper.fileTypeEnum.PowerPoint,
		'.pptx': FileTypeHelper.fileTypeEnum.PowerPoint,
		'.ppsx': FileTypeHelper.fileTypeEnum.PowerPoint,
	};

}
