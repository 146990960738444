import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { KioskSessionService } from '../services/kiosk/kiosk-session.service';
import { AuthTokenHelper } from './auth-token-helper';
import { Storage } from '@ionic/storage';

@Injectable({
    providedIn: 'root'
})
export class KioskAuthGuard implements CanActivate {
    isActive: boolean;
    constructor(
        public authTokenHelper: AuthTokenHelper,
        public router: Router,
        private kioskSessionService: KioskSessionService,
        private storage: Storage) { }
    canActivate(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.authTokenHelper.isTokenValid().then((data) => {
            this.isActive = data;
            if (!this.isActive) {
                this.router.navigateByUrl("/login");
            }
            else {
                if ((state.url == '/kiosk-authorization') || (state.url == '/kiosk-landing')) {
                    this.storage.get('isKioskSessionValid').then((data) => {
                        if (data == true)
                            this.router.navigate(['/kiosk-booth-info']);
                        else
                            this.router.navigate([state.url]);
                    })
                } else {
                    this.storage.get('isKioskSessionValid').then((data) => {
                        if (data == true)
                            this.isActive = true;
                        else
                            this.router.navigate(['/kiosk-landing']);
                    })
                }
            }
            return this.isActive;
        });
    }
}
