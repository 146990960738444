import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthTokenHelper } from 'src/app/helpers/auth-token-helper';
import { FilterHelper } from 'src/app/helpers/filter-helper';
import { SelectHelper } from 'src/app/helpers/select-helper';
import { StatusHelper } from 'src/app/helpers/status-helper';
import { Status } from 'src/app/models/status';
import { FilteredLineItemTimeline, FilteredWorkTicketTimeline } from 'src/app/models/work-ticket-timeline-filter-dto';
import { LineItemFilterServiceProvider } from 'src/app/services/line-item-filter/line-item-filter.service';
import { WorkTicketTimelineService } from 'src/app/services/work-ticket-timeline/work-ticket-timeline.service';
import { WorkTicketService } from 'src/app/services/work-ticket/work-ticket.service';

@Component({
  selector: 'line-item-filter',
  templateUrl: './line-item-filter.component.html',
  styleUrls: ['./line-item-filter.component.scss'],
})

export class LineItemFilterComponent {
	@Output() closeFilter = new EventEmitter<boolean>();
	statuses: Array<Status>;
	activeFilters: Array<Status> = [];
	isLoading = false;
	salesUser: any;
	externalUser: any

	constructor(
		public filterHelper: FilterHelper,
		public workTicketSvc: WorkTicketService,
		public workTikTimelineSvc: WorkTicketTimelineService,
		public filterSvc: LineItemFilterServiceProvider,
		private authTokenHelper: AuthTokenHelper
		) {
		this.getFilterOptions();
		
		}

	async getFilterOptions() {
		this.statuses = StatusHelper.LineItemStatuses;
		this.filterSvc.chosenFilters.subscribe(f => {
			if ((f !== null || f.length > 0) && this.activeFilters.length === 0) {
				this.repopulateFilterList(f); 
			}
		});
	}

	applyFilter(status: Status, index: number): void {
		if (this.activeFilters.findIndex(s => s === status) > -1) {
			this.activeFilters.splice(
				this.activeFilters.findIndex(s => s === status),
				1
			);
		} else {
			this.activeFilters.push(status);
		}
		this.submitFilteredItems();
	}

	submitFilteredItems() {
		const filters: FilteredWorkTicketTimeline[] = [];
		if (this.activeFilters.length === 0) {
			this.filterSvc.chosenFilters.next([]);
		} else {
			this.activeFilters.forEach(af => {
				if (af.id < 8) {
					filters.push({
						postType: 'StatusChange',
						statusId: af.id
					});				
				} else {
					filters.push({
						postType: af.title,
						statusId: null
					});
				}
			});
			this.filterSvc.chosenFilters.next(filters);
		}
	}
	getCurrentStatus(status: Status) {
		if (this.activeFilters.findIndex(s => s === status) > -1) {
			return true;
		} else {
			return false;
		}
	}
	resetFilter() {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		if (this.activeFilters.length === 0) return;
		this.activeFilters = [];
		this.submitFilteredItems();
	}

	repopulateFilterList(filter: FilteredLineItemTimeline[]) {
		this.statuses.forEach(status => {
			if (filter.find(f => (f.postType === status.title && f.statusId === null) || (f.postType === 'StatusChange' && f.statusId === status.id) || (f.postType === 'ChangeLog' && f.statusId === status.id))) {
				this.activeFilters.push(status);
			}
		});
	}
}
