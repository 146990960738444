import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zipcode'
})
export class ZipcodePipe implements PipeTransform {

  transform(zipcode: any, ...args: any[]): any {
    let formattedValue = zipcode.replace(/-/g, "");
    if (formattedValue.length > 5) {
    formattedValue = formattedValue.slice(0, 5) + "-" + formattedValue.slice(5,formattedValue.length);
    }
    return formattedValue;
  }
}
