import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'occ-kiosk-print-confirmation-modal',
  templateUrl: './kiosk-print-confirmation-modal.component.html',
  styleUrls: ['./kiosk-print-confirmation-modal.component.scss'],
})
export class KioskPrintConfirmationModalComponent implements OnInit {

  constructor(public popoverCtrl : PopoverController) { }

  ngOnInit() {}
  dismiss(){
    this.popoverCtrl.dismiss({
      'isPrintMore': false
    });
  }
  PrintMore(){
    this.popoverCtrl.dismiss({
      'isPrintMore': true
    });
  }
}
