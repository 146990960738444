import { env } from './env';

const currentEnv = 'test';

export const environment = {
	production: true,
	environment: 'test',
    version:env.applicationVersion,
	freemanShowInformation: env.freemanShowInformation,
    floorplanVault: env.floorplanVault,
	encryptionKey: env.encryptKey,
	createAccountURL: env[currentEnv].createAccountURL,
	forgotAccountURL: env[currentEnv].forgotAccountURL,
	
	apiUrl(): string {
		return env[currentEnv].apiUrl;
	},

	loginUrl(): string {
		return env[currentEnv].loginUrl;
	},

	fileBaseUrl(): string {
		return env[currentEnv].fileBaseUrl;
	},
	identityBaseUrl(): string {
		return env[currentEnv].identityBaseUrl;
	},

	downloadBaseUrl(): string {
		return env[currentEnv].downloadBaseUrl;
	},

	userList(): string {
		return env.userlist;
	},

	jreportBaseUrl(): string {
		return env[currentEnv].jreportBaseUrl;
	},

	jreportAuth(): string {
		return env[currentEnv].jreportAuth;
	},

	jreportFolder(): string {
		return env[currentEnv].jreportFolder;
	},

	domainName(): string {
		return env[currentEnv].domainName;
	}
};