import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'esBoothsFilter'
})
export class EsBoothsFilterPipe implements PipeTransform {

  transform(dataSet: any, ...filterCriteriaDataArray: any): any {
    let filteredBoothData = [];
    
    if(filterCriteriaDataArray.length){
      let filterCriteriaData = filterCriteriaDataArray[0];
      dataSet.forEach(boothItem => {
        let isFilterCriteriaPassed = true;
        if(filterCriteriaData.range && (filterCriteriaData.range.startRange && filterCriteriaData.range.endRange) && (boothItem.boothName?.toLowerCase().localeCompare(filterCriteriaData.range.startRange?.toLowerCase(), 'en', { numeric: true }) == -1 || boothItem.boothName?.toLowerCase().localeCompare(filterCriteriaData.range.endRange?.toLowerCase(), 'en', { numeric: true }) == 1)){
          isFilterCriteriaPassed = false;
        }
        if(filterCriteriaData.features.length){
          filterCriteriaData.features.forEach(feature => {
            if((feature == '1' && !boothItem?.totalServiceRequest) || (feature == '2' && !boothItem?.totalLineItems) || (feature == '3' && !boothItem?.totalFreightReceiver) || (feature == '4' && !boothItem?.isMHA)){
              isFilterCriteriaPassed = false;
            }            
          });
        }
        if((!boothItem.statusId && !(filterCriteriaData.status.includes(1))) || (filterCriteriaData.status.length && !(filterCriteriaData.status.includes(boothItem.statusId)))){
          isFilterCriteriaPassed = false;
        }

        if(filterCriteriaData.squareFoot && ((filterCriteriaData.squareFoot ==  'LESS_THAN_200' && boothItem.totalSquareFoot > 200) 
        || (filterCriteriaData.squareFoot ==  'LESS_THAN_400' && boothItem.totalSquareFoot > 400)
        || (filterCriteriaData.squareFoot ==  'GREATER_THAN_400' && boothItem.totalSquareFoot < 401)))
        {
          isFilterCriteriaPassed = false;
        }

        isFilterCriteriaPassed ? filteredBoothData.push(boothItem) : "";
      });
      
      return filteredBoothData;
    } else {
      return dataSet;
    }
  }

}
