import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';

@Component({
  selector: 'occ-kiosk-print-unsuccessful',
  templateUrl: './kiosk-print-unsuccessful.component.html',
  styleUrls: ['./kiosk-print-unsuccessful.component.scss'],
})
export class KioskPrintUnsuccessfulComponent implements OnInit {

  @Output() onTabChangedEvent?= new EventEmitter<boolean>();

  constructor(
    private viewCtrl: ModalController,
    public appBrowserHelper: AppBrowserHelper,
    private router: Router
  ) {
  }
  ngOnInit(): void {
  }

  closeModalPopup() {
    this.viewCtrl.dismiss();
  }

  goToHomePage() {
    this.viewCtrl.dismiss({
      'isDismissed': true
    });
  }
}
