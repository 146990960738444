import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'occ-empty-data-indicator',
  templateUrl: './empty-data-indicator.component.html',
  styleUrls: ['./empty-data-indicator.component.scss'],
})
export class EmptyDataIndicatorComponent implements OnInit {
  @Input() itemType: string;
  constructor() { }

  ngOnInit() {}

}
