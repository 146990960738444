import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { OpsTimelineDTO } from 'src/app/models/opsTimeline-dto';
import { TimelineDTO } from 'src/app/models/timeline-dto';

@Component({
  selector: 'occ-timeline-mention',
  templateUrl: './timeline-mention.component.html',
  styleUrls: ['./timeline-mention.component.scss'],
})
export class TimelineMentionComponent implements OnInit {
  @Input() item: TimelineDTO;
	@Input() opsItem: OpsTimelineDTO;
	date: string;
	statusString: string;

  constructor() { }

	ngOnInit() {
		if (this.item) {
			this.date = moment(this.item.createdDate).utc().fromNow();
			this.statusString = this.item.status ? 'to ' + this.item.status.title : '';
		} else {
			this.date = moment(this.opsItem.createdDate).utc().fromNow();
			this.statusString = this.opsItem.status ? 'to ' + this.opsItem.status.title : '';
		}
	}
}
