import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'occ-mhacarrier-terms',
  templateUrl: './mhacarrier-terms.component.html',
  styleUrls: ['./mhacarrier-terms.component.scss'],
})
export class MHACarrierTermsComponent implements OnInit {

  constructor(public modalCtrl: ModalController) { }

  ngOnInit() {}

  closeTermsModal(){
    this.modalCtrl.dismiss();
  }

}
