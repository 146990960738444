import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ShowSetupService } from 'src/app/services/show-setup/show-setup.service';
import * as _ from 'lodash';
import { IonAccordionGroup, ModalController } from '@ionic/angular';

@Component({
  selector: 'duplicate-zones',
  templateUrl: './duplicate-zones.component.html',
  styleUrls: ['./duplicate-zones.component.scss'],
})
export class DuplicateZonesComponent implements OnInit {
  @ViewChild(IonAccordionGroup, { static: true }) accordionGroup: IonAccordionGroup;

  @Input() eventGUID: any;
  @Input() emptyReturnDate: any;
  @Input() duplicateShowGuid: any;
  areaType: any = 2;
  selectedOption: any = '';
  selectedZones: any = [];
  freightZonesList: any = [];
  isDuplicateZoneSaved: boolean = false;
  includeBooths: any;
  isZonesIncluded: any;
  isEmptiesReturnIncluded: any;
  showLoader: any;

  constructor(
    private modalCtrl: ModalController,
    private showSetupService: ShowSetupService
  ) { }

  ngOnInit() {
    this.getFreightZoneList();
    setTimeout(() => {
      this.selectedOption = 'zones';
    }, 300);
  }

  toggleAccordion(event: any) {
    if (this.accordionGroup.value == 'emptiesReturn' && !this.isZonesIncluded) {
      this.accordionGroup.value = this.selectedOption;
      return;
    }
    this.selectedOption = this.accordionGroup.value;
  }

  getFreightZoneList() {
    this.showSetupService.getFreightZone(this.duplicateShowGuid, this.areaType).subscribe((zoneList) => {
      this.freightZonesList = _.filter(zoneList, ['isActive', true]);
    });
  }

  toggleZone(zone) {
    zone['selected'] = !zone.selected;
    if (!zone.selected) {
      let index = this.selectedZones.findIndex(x => x.areaGuid == zone.areaGuid);
      if (index > -1)
        this.selectedZones.splice(index, 1);
    } else {
      this.selectedZones.push(zone);
    }

  }

  includeZones() {
    this.isZonesIncluded = true;
    this.accordionGroup.value = 'emptiesReturn';
    // this.toggleAccordion('emptiesReturn');
  }

  includeEmptiesReturn() {
    this.isEmptiesReturnIncluded = true;
  }

  closeModal() {
    this.modalCtrl.dismiss({
      'isDuplicateZoneSaved': this.isDuplicateZoneSaved
    });
  }

  duplicateZone() {
    if (this.showLoader)
      return;
    this.showLoader = true;
    let areaGuids: any = _.map(this.selectedZones, 'areaGuid');

    let requestData: any = {
      areaGuids,
      duplicateShowGuid: this.duplicateShowGuid,
      includeBooth: this.includeBooths || false,
      includeEmptyReturnTime: this.isEmptiesReturnIncluded || false
    };

    this.showSetupService.duplicateZonesData(this.eventGUID, requestData).subscribe(res => {
      this.showLoader = false;
      if (res)
        this.modalCtrl.dismiss({ 'isDuplicateZoneSaved': true });
    })
  }
}
