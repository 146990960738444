import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { ShowBooth } from 'src/app/models/show-booth-dto';
import { ShowArea } from 'src/app/models/show-setup/show-area-dto';
import { environment } from '../../../environments/environment';
import { Area } from '../../models/show-setup/area-dto';


import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class ShowSetupService {
  url = environment.apiUrl;

  constructor(
    public http: HttpClient,
    public toastService: ToastService,) { }

  submitAreaGrouping(showGuid: any, area: Area) {
    return this.http.post(`${this.url()}events/${showGuid}/showsetup/area/save`, area).pipe(map((createAreaResponse: any) => {
      if (createAreaResponse && createAreaResponse.status == "success") {
        this.toastService.open(createAreaResponse.message, 'success');
        return createAreaResponse;
      } else {
        this.toastService.open(createAreaResponse.message, 'danger');
        return false;
      }
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error while creating area', 'danger');
      }
      return false;
    }));
  }

  submitServiceTypes(showGuid: any, type: any, serviceTypeGUID: any) {
    let serviceTypePayload = { "Type": type, "kafkaId": serviceTypeGUID };
    return this.http.post(`${this.url()}events/${showGuid}/ServiceType/save`, serviceTypePayload).pipe(map((createAreaResponse: any) => {
      if (createAreaResponse && createAreaResponse.status == "success") {
        this.toastService.open(createAreaResponse.message, 'success');
        return createAreaResponse;
      } else {
        this.toastService.open(createAreaResponse.message, 'danger');
        return false;
      }
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error while creating area', 'danger');
      }
      return false;
    }));
  }

  deleteServiceTypes(showGuid: any, serviceTypeGUID: any) {
    return this.http.delete(`${this.url()}events/${showGuid}/ServiceType/${serviceTypeGUID}/delete`).pipe(map((data) => {
      this.toastService.open('Service type deleted successfully','success');
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error while creating area', 'danger');
      }
      return false;
    }));
  }
  getAreaList(eventGUID: string) {
    return this.http.get<Array<ShowArea>>(`${this.url()}events/${eventGUID}/showsetup/areas/1`).pipe(map((areaList: ShowArea[]) => {
      return (areaList && areaList?.length) ? areaList : [];
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error while getting show areas', 'danger');
      }
    }));
  }

  changeAreaActivationStatus(eventId, postData) {
    return this.http.post<any>(`${this.url()}events/${eventId}/showsetup/area/save`, postData).pipe(map(data => {
      if (data.status === 'error') {
        this.toastService.open(data.message, 'danger');
        return false;
      } else if (data.status === 'success') {
        this.toastService.open(data.message, 'success');
        return true;
      }
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error while activate area ', 'danger');
      }
      return false;
    }));
  }

  getBoothsList(eventId: string) {
    return this.http.get<Array<ShowBooth>>(`${this.url()}events/${eventId}/getBooths`).pipe(map((data: any) => {
      if (data != null) {
        data = _.orderBy(_.uniqBy(data, 'boothGUID'), ['boothName'], ['asc']);
      }
      return data;
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error to getting booths data', 'danger');
      }
    }));
  }

  checkAreaExist(eventGUID: string, areatype, name) {
    return this.http.get(`${this.url()}events/${eventGUID}/showsetup/area/${areatype}/${name}`).pipe(map((data: boolean) => {
      return data;
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error while getting  name', 'danger');
      }
    }));
  }
  
  submitAreaBooths(eventGUID: any, areaBoothData: any) {
    return this.http.post(`${this.url()}events/${eventGUID}/showsetup/area/boothAssignment/save`, areaBoothData).pipe(map((boothUpdateResponse: any) => {
      if (boothUpdateResponse && boothUpdateResponse.status == "success") {
        this.toastService.open(boothUpdateResponse.message, 'success');
        return boothUpdateResponse;
      } else {
        this.toastService.open('There was an error while adding booths', 'danger');
        return false;
      }
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error while adding booths', 'danger');
      }
      return false;
    }));
  }

  saveBoothAssignments(eventGUID: string, boothAssignmentData: any) {
    return this.http.post(`${this.url()}events/${eventGUID}/showsetup/area/boothAssignment/multiple/save`, boothAssignmentData).pipe(map((boothAssignmentResponse: any) => {
      if (boothAssignmentResponse && boothAssignmentResponse.status == "success") {
        this.toastService.open(boothAssignmentResponse.message, 'success');
        return boothAssignmentResponse;
      } else {
        this.toastService.open('There was an error while saving booths assignment', 'danger');
        return false;
      }
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error while saving booths assignment', 'danger');
      }
      return false;
    }));
  }

  getServiceType(eventId: string) {
    return this.http.get<Array<any>>(`${this.url()}events/${eventId}/exhibitorservice/requestType`).pipe(map((data: any) => {
      if (data != null) {
        return data;
      }
      return [];
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error to getting Service Type data', 'danger');
      }
    }));
  }

  checkServiceTypeExist(showGuid: any, type: any, serviceTypeGUID: any) {
    if(serviceTypeGUID == undefined){
      serviceTypeGUID = null
    }
    return this.http.get(`${this.url()}events/${showGuid}/serviceType/${type}/parentKafkaId/${serviceTypeGUID}`).pipe(map((data: boolean) => {
      return data;
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error while getting  name', 'danger');
      }
    }));
  }


getFreightZone(eventGUID: any,areatype:any) {
    return this.http.get<Array<any>>(`${this.url()}events/${eventGUID}/showsetup/areas/${areatype}`).pipe(map((zoneList: any) => {
      return (zoneList && zoneList?.length) ? zoneList : [];
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error while getting freightZone', 'danger');
      }
    }));
  }

  submitServiceSubtype(eventGUID: any, serviceSubtypeData: any) {
    return this.http.post(`${this.url()}events/${eventGUID}/ServiceType/subServiceType/save`, serviceSubtypeData).pipe(map((subtypeSaveResponse: any) => {
      if (subtypeSaveResponse && subtypeSaveResponse.status == "success") {
        this.toastService.open(subtypeSaveResponse.message, 'success');
        return subtypeSaveResponse;
      } else {
        this.toastService.open('There was an error while saving Service Sub-types', 'danger');
        return false;
      }
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error while saving Service Sub-types', 'danger');
      }
      return false;
    }));
  }

  updatePriorityDate(eventGUID: any, emptyReturnDate: any) {
    const uploadDto: any= {};
    uploadDto.EmptyReturnedDate = emptyReturnDate;//;"2022-03-08 15:56:43.9232038"
    return this.http.put<any>(`${this.url()}events/${eventGUID}/freightSetup/freight/saveprioritydate`, uploadDto)
    .pipe(map((createAreaResponse: any) => {
      if (createAreaResponse && createAreaResponse.status == "success") {
        this.toastService.open(createAreaResponse.message, 'success');
        return createAreaResponse;
      } else {
        this.toastService.open(createAreaResponse.message, 'danger');
        return false;
      }
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error while creating area', 'danger');
      }
      return false;
    }));
  }

  updatefreightDates(eventGUID: any, freightZoneList: any) {
    return this.http.put<any>(`${this.url()}events/${eventGUID}/freightSetup/freight/updatefreightzonetime`, freightZoneList)
    .pipe(map((createAreaResponse: any) => {
      if (createAreaResponse && createAreaResponse.status == "success") {
        this.toastService.open(createAreaResponse.message, 'success');
        return createAreaResponse;
      } else {
        this.toastService.open(createAreaResponse.message, 'danger');
        return false;
      }
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error while creating area', 'danger');
      }
      return false;
    }));
  }

  duplicateZonesData(eventId: any, requestData: any) {
    return this.http.post<any>(`${this.url()}events/${eventId}/freightSetup/freight/duplicate`, requestData).pipe(map((res) => {
      if (res && res.message)
        this.toastService.open(res.message, 'success');
      return res;
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error while duplicating zone data', 'danger');
      }
    }));
  }

  updateVipStatus(eventId: any, requestData: any) {
    return this.http.put<any>(`${this.url()}events/${eventId}/exhibitorService/setVIPExhibitor`, requestData).pipe(map((res) => {
      if (res && res.message)
        this.toastService.open(res.message, 'success');
      return res;
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error while updating VIP status', 'danger');
      }
    }));
  }
}
