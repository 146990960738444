import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonItemSliding, ModalController, PopoverController } from '@ionic/angular';
import { takeWhile } from 'rxjs/operators';
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';
import { StatusHelper } from 'src/app/helpers/status-helper';
import { CollaborationLineItemDTO } from 'src/app/models/collaboration-line-item-dto';
import { EsBoothDetailsItems } from 'src/app/models/es-booth-details-items';
import { ShowBooth } from 'src/app/models/show-booth-dto';
import { Status } from 'src/app/models/status';
import { BoothServiceProvider } from 'src/app/services/booth/booth.service';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';
import { EsBoothsService } from 'src/app/services/es-booths/es-booths.service';
import { ExhibitorStatusChangeComponent } from '../exhibitor-status-change/exhibitor-status-change.component';

@Component({
  selector: 'occ-service-request-card',
  templateUrl: './service-request-card.component.html',
  styleUrls: ['./service-request-card.component.scss'],
})
export class ServiceRequestCardComponent implements OnInit {
  currentServiceRequest: any;
  statusMenuPopover: any;
  currentBoothItem: ShowBooth;
  eventId: string;
  subscribe: boolean = true;
  @Input() serviceRequestItem: any;
  @Input() isOpsServiceRequestDetails: any;
  @Input() isSalesServiceRequestDetails: boolean = false;
  isMobileView: boolean = false;
  statusDataObject: any;
  @Input() isBoothDetailsPage: boolean;

  constructor(
    public popoverCtrl: PopoverController,
    public boothService: BoothServiceProvider,
    public exhibitorBoothSvc: EsBoothsService,
    private activatedRoute: ActivatedRoute,
    public dataSvc: DataRefreshService,
    private router: Router,
    private appBrowserHelper: AppBrowserHelper,
  ) { }

  ngOnInit() {
    this.statusDataObject = StatusHelper.Status;
    this.isMobileView = !this.appBrowserHelper.isDesktop;
    
    if(this.isSalesServiceRequestDetails){
      this.boothService.activeBooth.pipe(takeWhile(() => this.subscribe)).subscribe(currentBoothData => {
        this.currentBoothItem = currentBoothData;
      });
    } else {
      this.exhibitorBoothSvc.activeBoothSubject.pipe(takeWhile(() => this.subscribe)).subscribe(currentBoothData => {
        this.currentBoothItem = currentBoothData;
      });
    }
    
    this.eventId = this.activatedRoute.snapshot.paramMap.get('eventId') || this.dataSvc.eventActive;
    this.serviceRequestItem.status = StatusHelper.GetServiceRequestStatusFromId(this.serviceRequestItem.currentStatus ? this.serviceRequestItem.currentStatus : StatusHelper.ServiceRequestOptions.NewService.id);
  }

  async presentStatusPopover(event, item: EsBoothDetailsItems) {
    this.currentServiceRequest = item;
    this.statusMenuPopover = await this.popoverCtrl.create({
      component: ExhibitorStatusChangeComponent,
      cssClass: 'exhibitor-status-change',
      componentProps: {
        serviceRequest: item,
        serviceBoothData: this.currentBoothItem,
        eventId: this.eventId
      },
      event: event
    });

    await this.statusMenuPopover.present();
    this.boothService.esServiceRequestStatusSubject.pipe(takeWhile(() => this.subscribe)).subscribe(serviceRequestStatusUpdate => {
      if (serviceRequestStatusUpdate) {
        if (serviceRequestStatusUpdate.serviceRequestGuid == this.currentServiceRequest.kafkaId) {
          this.currentServiceRequest.status = StatusHelper.ExhibitGetStatusFromId(serviceRequestStatusUpdate.statusId);
          this.currentServiceRequest.currentStatus = serviceRequestStatusUpdate.statusId;
          this.currentServiceRequest.statusUpdatedDate = new Date().toISOString();
          this.currentServiceRequest.commentsCount = serviceRequestStatusUpdate.commentsCount;
        }
      }
    });
  }

  navigateToServiceDetailsActivity(serviceItem: any) {  
    if (this.isOpsServiceRequestDetails)
      this.router.navigateByUrl('events/' + this.eventId + '/ops-service-requests/' + serviceItem.kafkaId + '/service-request-details' + '/1');
    else{
      let selectedBoothGuid = this.currentBoothItem.boothGUID ? this.currentBoothItem.boothGUID : serviceItem.boothGuid;
      this.router.navigateByUrl('events/' + this.eventId + '/booths/' + selectedBoothGuid + '/service-requests/' + serviceItem.kafkaId + '/service-request-details' + '/1');
    }
  }

  closeBoothSRSliding(boothSrItem: any, newStatus: Status, SrItemSliding: IonItemSliding) {
		setTimeout(() => {
			SrItemSliding.close();
			this.changeBoothSRStatus(boothSrItem, newStatus);
		}, 100);
	}

  async changeBoothSRStatus(boothSrItem: any, newStatus: Status, boothSliding?: IonItemSliding) {
		if (boothSliding) {
			boothSliding.close();
		}
    this.currentServiceRequest = boothSrItem;
		this.boothService.setServiceRequestStatus(this.eventId, boothSrItem, newStatus.id);

    this.boothService.esServiceRequestStatusSubject.pipe(takeWhile(() => this.subscribe)).subscribe(serviceRequestStatusUpdate => {
      if (serviceRequestStatusUpdate) {
        let itemIdToBeMatched = this.currentServiceRequest.kafkaId? this.currentServiceRequest.kafkaId : this.currentServiceRequest.issueItemGuid;
        if (serviceRequestStatusUpdate.serviceRequestGuid == itemIdToBeMatched) {
          this.currentServiceRequest.status = StatusHelper.ExhibitGetStatusFromId(serviceRequestStatusUpdate.statusId);
        }
      }
    });
	}

  async presentItemActionModal(event, itemSliding: any, item: any) {
		this.presentStatusPopover(event, item);
		itemSliding.close();
	}
}
