import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KioskSessionService } from 'src/app/services/kiosk/kiosk-session.service';
import { KioskService } from 'src/app/services/kiosk/kiosk.service';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'kiosk-link-account-modal',
  templateUrl: './kiosk-link-account-modal.component.html',
  styleUrls: ['./kiosk-link-account-modal.component.scss'],
})
export class KioskLinkAccountModalComponent implements OnInit {

  @Input() accountName: any;
  @Input() modalType: any;
  @Input() mobileInfo: any;
  @Input() name: any;

  timeout: any = 5000;

  constructor(
    public router: Router,
    public kioskSessionService: KioskSessionService,
    public kioskService: KioskService,
    private storage: Storage,
    private platform: Platform) { }

  ngOnInit() {
    if (!this.accountName) {
      this.storage.get('kiosk-account-info').then((accountData) => {
        if (accountData != null){
          let accountInfo = JSON.parse(accountData);
          this.accountName = accountInfo.accountName;
        }
      });
    }
    if (this.modalType == 'success' || this.modalType == 'locate-account-success') {
      setTimeout(() => {
        this.navigateToHomeScreen();
      }, this.timeout);
    }
    else if(this.modalType == 'locate-account')
    {
    if(!this.isDesktopView()){
    setTimeout(() => {
      this.navigateToAccountSearch();
     }, this.timeout);
     }
    }
  }

  navigateToHomeScreen() {
    this.storage.set('isKioskSessionValid', true).then(() => {
      this.kioskSessionService.sessionValid = true;
      this.kioskSessionService.startActivityTracking();
      if(this.isDesktopView())
      {
        this.router.navigate(['kiosk-booth-info'], { replaceUrl: true });
      }
      else{
        this.router.navigate(['kiosk-shipping-option-mobile'], { replaceUrl: true });
      }
      setTimeout(() => {
        this.kioskService.linkAccountData = null;
      }, 1500);
    });
  }

  closeModalPopup() {
    this.navigateToHomeScreen();
  }

  navigateToAccountSearch() {
    this.kioskService.linkAccountData = {
      mobileInfo: this.mobileInfo
    }
    this.router.navigate(['kiosk-account-search']);
  }

  isDesktopView() {
    return this.platform.width() > 767 ? true : false;
  }

}
