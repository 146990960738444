import { Injectable } from '@angular/core';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { AccountType } from 'src/app/helpers/account-type-helper';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JReportService {
  jreportBaseUrl = environment.jreportBaseUrl;
  jreportAuth = environment.jreportAuth;
  constructor(private ga: GoogleAnalytics) { }


  openWTForBrowser(tssShowNumber: number, tssWorkTicketNumber: number, showAccountTypeId: number) {
    this.ga.trackEvent('FileAccessed', 'FileAccessed');
    if (showAccountTypeId == AccountType.EXHIBIT) {
      window.open(
        `${this.jreportBaseUrl()}jrserver?jrs.cmd=jrs.web_vw&jrs.result_type=2&jrs.rst=test.pdf&jrs.catalog=/workticket/workticket.cat&jrs.report=/workticket/ticketheader.cls&jrs.param$showkey=${tssShowNumber}&jrs.param$ticketkey=${tssWorkTicketNumber}&jrs.authorization=${this.jreportAuth()}`,
        '_system'
      );
    }
    else {
      window.open(
        `${this.jreportBaseUrl()}jrserver?jrs.cmd=jrs.web_vw&jrs.result_type=2&jrs.rst=test.pdf&jrs.catalog=/workticket/workticket.cat&jrs.report=/workticket/emsticketheader.cls&jrs.param$showkey=${tssShowNumber}&jrs.param$ticketkey=${tssWorkTicketNumber}&jrs.authorization=${this.jreportAuth()}`,
        '_system'
      );
    }
  }
}
