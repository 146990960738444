import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mobileNumberFormat'
})
export class MobileNumberFormatPipe implements PipeTransform {

  transform(mobileNumber: any, ...args: any[]): unknown {
    if(mobileNumber){
      let formattedValue = mobileNumber.replace(/-/g, "");
      if (formattedValue.length > 5) {
        formattedValue = formattedValue.slice(0, 3) + "-" + formattedValue.slice(3);
        formattedValue = formattedValue.slice(0, 7) + "-" + formattedValue.slice(7);
      } else if (formattedValue.length > 2 && formattedValue.length < 6) {
        formattedValue = formattedValue.slice(0, 3) + "-" + formattedValue.slice(3);
      }
      formattedValue = formattedValue ? formattedValue.substring(0, 12) : "";
      return formattedValue;
    } else {
      return "";
    }
  }

}
