import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { StatusHelper } from 'src/app/helpers/status-helper';
import { CollaborationLineItemDTO } from 'src/app/models/collaboration-line-item-dto';
import { takeWhile } from 'rxjs/operators';
import { ShowBooth } from 'src/app/models/show-booth-dto';
import { BoothServiceProvider } from 'src/app/services/booth/booth.service';
import { EsBoothsService } from 'src/app/services/es-booths/es-booths.service';
import { ExhibitorStatusChangeComponent } from '../exhibitor-status-change/exhibitor-status-change.component';

@Component({
  selector: 'occ-mha-card',
  templateUrl: './mha-card.component.html',
  styleUrls: ['./mha-card.component.scss'],
})
export class MhaCardComponent implements OnInit {
  currentMHA: any;
  statusMenuPopover: any;
  currentBoothItem: ShowBooth;
  eventId: string;
  subscribe: boolean = true;
  @Input() MHAItem: any;

  constructor(
    public popoverCtrl: PopoverController,
    public boothService: BoothServiceProvider,
    public exhibitorBoothSvc: EsBoothsService,
  ) { }

  ngOnInit() {
    this.exhibitorBoothSvc.activeBoothSubject.pipe(takeWhile(() => this.subscribe)).subscribe(currentBoothData => {
      this.currentBoothItem = currentBoothData;
    });
  }


  getMHAStatusText(mhaItemStatus){
    if(mhaItemStatus.toLowerCase() == "pending"){
      return this.MHAItem.mhaItemStatus ? 'MHA Complete': 'Pending';
    }else{
      return 'Submitted';
    }
  }

}
