import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Attachment } from '../../models/attachment';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from '../toast/toast.service';
import {FileExtensionHelper} from '../../helpers/file-extension-helper';
import { FileTypeHelper } from '../../helpers/file-type-helper';
import { FileType } from '../../models/file-type';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

	url = environment.apiUrl;
	faAttachments = new BehaviorSubject<Attachment[]>([]);
	liAttachments = new BehaviorSubject<Attachment[]>([]);
	wtLiAttachments = new BehaviorSubject<Attachment[]>([]);

	constructor(
		public http: HttpClient,
		public toastService: ToastService,
		private fileHelper: FileExtensionHelper
		) { }

	get(eventId: string, projectAccountId: string, functionalAreaId: string, lineItemId?: string): void {
		const faFiles = this.faAttachments.getValue();

		if (lineItemId) {
			const liFiles = this.liAttachments.getValue();
			if (liFiles.length > 0) {
				const file = liFiles[0];
				if (file.showAccountId === projectAccountId && file.lineItemId === lineItemId) {
					return;
				}
			}
			this.refresh(eventId, projectAccountId, functionalAreaId, lineItemId);
		} else
			if (faFiles.length > 0) {
				const file = faFiles[0];
				if (file.showAccountId === projectAccountId && file.functionalAreaId === functionalAreaId) {
					return;
				}
			}
		this.refresh(eventId, projectAccountId, functionalAreaId);
	}

	refresh(showId: string, showAccountId: string, functionalAreaId: string, lineItemId?: string, errorCallback?: Function): void {
		if (lineItemId) {
			this.http.get<Attachment[]>(`${this.url()}events/${showId}/accounts/${showAccountId}/functional-areas/${functionalAreaId}/line-items/${lineItemId}/files`)
			.subscribe(data => {
				data.forEach(file => {
					file.fileType = this.getFileType(file);
				});

				this.liAttachments.next(data);
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error refreshing the attachment data', 'danger');
				}
				if (errorCallback) errorCallback();
			});
		} else {
			this.http.get<Attachment[]>(`${this.url()}events/${showId}/accounts/${showAccountId}/functional-areas/${functionalAreaId}/files`)
			.subscribe(data => {
				data.forEach(file => {
					file.fileType = this.getFileType(file);
				});
				this.faAttachments.next(data);
			}, err => {
				if (err.status !== 401 && err.status !== 403) {
					this.toastService.open('There was an error refreshing the attachment data', 'danger');
				}
				if (errorCallback) errorCallback();
			});
		}

	}

	getWorkTicketLineItemFiles(showId: string, workTicketId: string, lineItemId: string) {
		this.http.get<Attachment[]>(`${this.url()}events/${showId}/work-tickets/${workTicketId}/line-items/${lineItemId}/files`)
		.subscribe(data => {
			data.forEach(file => {
				file.fileType = this.getFileType(file);
			});
			this.wtLiAttachments.next(data);
		}, err => {
			if (err.status !== 401 && err.status !== 403) {
				this.toastService.open('There was an error refreshing the attachment data', 'danger');
			}
		});
	}

	private getFileType(file: Attachment): FileType {
		const fileExtension = file.name.match(/\.[0-9a-z]+$/i).toString().toLowerCase();
		return FileTypeHelper.fileExtensionObject[fileExtension] || FileTypeHelper.fileTypeEnum.Unknown;
	}

	showFileErrorToast(fileName: string) {
		this.toastService.open(`There was an error downloading ${fileName}. Please try again.`, 'danger');
	}

	downloadFunctionalAreaFile(showId: string, showAccountId: string, functionalAreaId: string, fileId: string, fileName: string): Promise<any> {
		return this.http.get(`${this.url()}events/${showId}/accounts/${showAccountId}/functional-areas/${functionalAreaId}/files/${fileId}`, {responseType: 'blob'})
			.toPromise()
			.then(data => {

				const blobObj = <Blob> data;
				return this.fileHelper.saveFile(fileId, fileName, blobObj)
					.catch(err => {
						console.log('Failed to save fa attachment ' + JSON.stringify(err));
						this.showFileErrorToast(fileName);
					});

			})
			.catch((err) => {
				console.log('Error attempting to download fa attachement: ' + JSON.stringify(err));
				this.showFileErrorToast(fileName);
			});
	}

	downloadLineItemAttachment(showId: string, showAccountId: string, functionalAreaId: string, lineItemId: string, fileId: string, fileName: string): Promise<any> {
		return this.http.get(`${this.url()}events/${showId}/accounts/${showAccountId}/functional-areas/${functionalAreaId}/line-items/${lineItemId}/files/${fileId}`, {responseType: 'blob'})
			.toPromise()
			.then(data => {

				const blobObj = <Blob> data;
				return this.fileHelper.saveFile(fileId, fileName, blobObj)
					.catch(err => {
						console.log('Failed to save line item attachment ' + JSON.stringify(err));
						this.showFileErrorToast(fileName);
					});

			})
			.catch((err) => {
				console.log('Error attempting to download li attachement: ' + JSON.stringify(err));
				this.showFileErrorToast(fileName);
			});
	}
}
