import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { FunctionalAreaService } from './../../services/functional-area/functional-area.service';
import { AppBrowserHelper } from './../../helpers/app-browser-helper';
import { CollaborationLineItemDTO } from './../../models/collaboration-line-item-dto';
import { CameraHelper } from './../../helpers/camera-helper';
import { FunctionalArea } from '../../models/functional-area';
import { LineItemService } from './../../services/line-item/line-item.service';
import { Status } from '../../models/status';
import { ActionSheetController, ModalController, NavParams, PopoverController } from '@ionic/angular';
// import { AddNoteModal } from '../add-note-modal/add-note-modal';
import { ActivityService } from '../../services/activity/activity.service';
import { StatusHelper } from '../../helpers/status-helper';
import { DataRefreshService } from '../../services/data-refresh/data-refresh.service';
import { SelectHelper } from '../../helpers/select-helper';
import { WorkTicketDTO } from '../../models/work-ticket-dto';
import { AuthTokenHelper } from '../../helpers/auth-token-helper';
import { environment } from '../../../environments/environment';
// import { CancelOrderConfirmationComponent } from '../cancel-order-confirmation/cancel-order-confirmation';
import { EventService } from '../../services/event/event.service';
// import { NewOrderModalPage } from '../../pages/new-order-modal/new-order-modal';
import { ShowBooth } from '../../models/show-booth-dto';
import { BoothServiceProvider } from '../../services/booth/booth.service';
import { AddNoteComponent } from '../add-note/add-note.component';
import { CancelOrderConfirmationComponent } from '../cancel-order-confirmation/cancel-order-confirmation.component';
import { NewOrderModalPage } from 'src/app/pages/new-order-modal/new-order-modal.page';
import { takeWhile } from 'rxjs/operators';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import * as _ from 'lodash';

@Component({
	selector: 'app-item-action-menu',
	templateUrl: './item-action-menu.component.html',
	styleUrls: ['./item-action-menu.component.scss'],
})
export class ItemActionMenuComponent implements OnInit {
	@ViewChild('fileinput') fileInput: ElementRef;

	@Input() functionalAreaId: string;
	@Input() functionalArea: FunctionalArea;
	@Input() boothData: ShowBooth;
	@Input() boothLineItem: CollaborationLineItemDTO;
	@Input() lineItem: CollaborationLineItemDTO;
	@Input() workTicketLineItem: CollaborationLineItemDTO;
	@Input() ticket: WorkTicketDTO;

	public eventId: string;
	public accountId: string;

	public lineItemId: string;
	public isEmsClosed: boolean;
	subscribe: boolean = true;


	constructor(
		public funcSvc: FunctionalAreaService,
		public lineItemSvc: LineItemService,
		public cameraHelper: CameraHelper,
		public modalCtrl: ModalController,
		public actionSheetCtrl: ActionSheetController,
		public activitySvc: ActivityService,
		public appBrowserHelper: AppBrowserHelper,
		public dataSvc: DataRefreshService,
		public authTokenHelper: AuthTokenHelper,
		public popoverCtrl: PopoverController,
		public eventSvc: EventService,
		public boothService: BoothServiceProvider,
		public navParams: NavParams,
		public ga: GoogleAnalytics,
	) {
		
	}

	ngOnInit(): void {
		this.boothData = this.navParams.data.booth? this.navParams.data.booth : this.navParams.data.boothData;
		this.boothLineItem = this.navParams.data.boothLineItem;
		this.functionalArea = this.navParams.data.area || this.funcSvc.currentFunctionalAreas.find(x => x?.functionalAreaGuid === this.navParams.data.item?.functionalAreaGuid) || this.funcSvc.currentFunctionalArea;

		this.lineItem = this.navParams.data.item;
		this.workTicketLineItem = this.navParams.data.workTicketLineItem;
		this.ticket = this.navParams.data.ticket;
		this.eventId = this.dataSvc.eventActive;
		this.accountId = this.dataSvc.showAccountActive ? this.dataSvc.showAccountActive : this.workTicketLineItem ? this.workTicketLineItem.showAccountGuid : null;
		
		this.functionalAreaId = this.functionalArea ? this.functionalArea.functionalAreaGuid : this.workTicketLineItem ? this.workTicketLineItem.functionalAreaGuid : null;
		this.lineItemId = this.lineItem ? this.lineItem.kafkaId : this.workTicketLineItem ? this.workTicketLineItem.kafkaId : null;
		this.eventSvc.currentEvent.pipe(takeWhile(() => this.subscribe)).subscribe(showDetails => {
            this.isEmsClosed = this.eventSvc.getEMSCloseStatus(this.eventId);
        });
	}

	ionViewDidLoad() {
		document
			.getElementsByTagName('ion-app')
			.item(0)
			.classList.add('modal-open');
	}

	ionViewWillLeave() {
		if (
			document
				.getElementsByTagName('ion-app')
				.item(0)
				.classList.contains('modal-open')
		)
			document
				.getElementsByTagName('ion-app')
				.item(0)
				.classList.remove('modal-open');
	}

	async openAddNoteModal() {
		const isModalOpened = await this.modalCtrl.getTop();
		if (isModalOpened) {
			this.modalCtrl.dismiss();
		}

		const isPopupOpened = await this.popoverCtrl.getTop();
		if (isPopupOpened) {
			this.popoverCtrl.dismiss();
		}

		if (this.lineItem) {
			const wtModal = await this.modalCtrl.create({
				component: AddNoteComponent,
				cssClass: 'photo-verification-modal',
				componentProps: { faID: this.lineItem.kafkaId, faName: this.lineItem.displayName, liId: this.lineItem.kafkaId }
			});
			await wtModal.present();
		} else if (this.boothLineItem) {
			const boothLINoteModal = await this.modalCtrl.create({
				component: AddNoteComponent,
				cssClass: 'photo-verification-modal',
				componentProps: { boothData: this.boothData, boothLineItem: this.boothLineItem }
			});
			await boothLINoteModal.present();
		} else if (this.boothData) {
			const boothNoteModal = await this.modalCtrl.create({
				component: AddNoteComponent,
				cssClass: 'photo-verification-modal',
				componentProps: { boothData: this.boothData }
			});
			await boothNoteModal.present();
		} else if (this.workTicketLineItem) {
			const lineItemModal = await this.modalCtrl.create({
				component: AddNoteComponent,
				cssClass: 'photo-verification-modal',
				componentProps: { showAccountId: this.workTicketLineItem.showAccountGuid, faID: this.workTicketLineItem.functionalAreaGuid, faName: this.workTicketLineItem.displayName, liId: this.workTicketLineItem.kafkaId }
			});
			await lineItemModal.present();
		} else if (this.ticket) {
			const lineItemModal = await this.modalCtrl.create({
				component: AddNoteComponent,
				cssClass: 'photo-verification-modal',
				componentProps: { wtId: this.ticket.workTicketGuid }
			});
			await lineItemModal.present();
		} else {
			const detailsModal = await this.modalCtrl.create({
				component: AddNoteComponent,
				cssClass: 'photo-verification-modal',
				componentProps: { faID: this.functionalArea?.functionalAreaGuid, faName: this.functionalArea?.name }
			});
			detailsModal.onDidDismiss().then(data => {
				if(this.navParams.data.Pagename == 'FunctionalArea') {
					this.ga.trackEvent('FunctionalAreaMainPage', 'NoteAdded');
				} else if(this.navParams.data.Pagename == 'Search') {
					this.ga.trackEvent('FunctionalAreaSearchPage', 'NoteAdded');
				}
			});
			await detailsModal.present();
		}
	}

	async dismiss() {
		const isModalOpened = await this.modalCtrl.getTop();
		if (isModalOpened) {
			this.modalCtrl.dismiss();
		}

		const isPopupOpened = await this.popoverCtrl.getTop();
		if (isPopupOpened) {
			this.popoverCtrl.dismiss();
		}
	}

	async presentCameraOptions() {
		const lineItemGuid = this.lineItem ? this.lineItem.kafkaId : null;

		const actionSheet = await this.actionSheetCtrl.create({
			header: 'Add a Photo',
			buttons: [
				{
					text: 'Take a Photo',
					handler: () => {
						if (this.ticket) {
							this.cameraHelper.takeWorkTicketPicture(this.eventId, this.ticket.workTicketGuid, lineItemGuid, false, false);
						} else if (this.workTicketLineItem) {
							this.cameraHelper.takePicture(this.eventId, this.workTicketLineItem.showAccountGuid, this.workTicketLineItem.functionalAreaGuid, this.workTicketLineItem.kafkaId, false, false);
						} else if (this.boothLineItem) {
							this.cameraHelper.takePicture(this.eventId, null, null, null, false, false, false, this.boothData.boothGUID, this.boothLineItem);
						} else if (this.boothData) {
							this.cameraHelper.takePicture(this.eventId, null, null, null, false, false, false, this.boothData.boothGUID);
						} else {
							this.cameraHelper.takePicture(this.eventId, this.accountId, this.functionalArea.functionalAreaGuid, lineItemGuid, false, false);
						}
					}
				},
				{
					text: 'Upload From Gallery',
					handler: () => {
						if (this.ticket) {
							this.cameraHelper.takeWorkTicketPicture(this.eventId, this.ticket.workTicketGuid, lineItemGuid, false, true);
						} else if (this.workTicketLineItem) {
							this.cameraHelper.takePicture(this.eventId, this.workTicketLineItem.showAccountGuid, this.workTicketLineItem.functionalAreaGuid, this.workTicketLineItem.kafkaId, false, true);
						} else if (this.boothLineItem) {
							this.cameraHelper.takePicture(this.eventId, null, null, null, false, true, false, this.boothData.boothGUID, this.boothLineItem);
						} else if (this.boothData) {
							this.cameraHelper.takePicture(this.eventId, null, null, null, false, true, false, this.boothData.boothGUID);
						} else {
							this.cameraHelper.takePicture(this.eventId, this.accountId, this.functionalArea.functionalAreaGuid, lineItemGuid, false, true);
						}
					}
				},
				{
					text: 'Cancel',
					role: 'cancel',
					handler: () => {
						this.actionSheetCtrl.dismiss();
					}
				}
			]
		});
		await actionSheet.present();
		const modalDetails = await this.modalCtrl.getTop();
		if(modalDetails){
			this.modalCtrl.dismiss().then((data) => {
				if(this.navParams.data.Pagename == 'FunctionalArea') {
					this.ga.trackEvent('FunctionalAreaMainPage', 'PhotoUploaded');
				} else if(this.navParams.data.Pagename == 'Search') {
					this.ga.trackEvent('FunctionalAreaSearchPage', 'PhotoUploaded');
				}
			});
		}

		const isPopupOpened = await this.popoverCtrl.getTop();
		if(isPopupOpened){
			this.popoverCtrl.dismiss();
		}
	}

	openFileViewer(): void {
		this.fileInput.nativeElement.value = null;
		this.fileInput.nativeElement.click();
		this.popoverCtrl.dismiss();
	}

	async presentCancelOrder() {
		this.popoverCtrl.dismiss();

		const cancelOrder = await this.popoverCtrl.create({
			component: CancelOrderConfirmationComponent
		});
		await cancelOrder.present();
		cancelOrder.onDidDismiss().then((isConfirmed) => {
			if (isConfirmed.data) {
				if (this.boothLineItem) {
					this.boothService.cancelOrderForLineItem(this.eventId, this.boothLineItem.showAccountGuid, this.boothLineItem.kafkaId).subscribe();
				} else {
					this.lineItemSvc.cancelOrderForLineItem(this.eventId, this.accountId, this.lineItem.kafkaId).subscribe();
				}
			}
		});
	}

	async presentCopyOrder() {
		this.popoverCtrl.dismiss();

		const copyOrderModal = await this.modalCtrl.create({
			component: NewOrderModalPage,
			componentProps: { 
			  lineItemData: this.lineItem,
			  boothGUID: this.boothData ? this.boothData.boothGUID : "",
			  boothLineItemID: this.boothLineItem ? this.boothLineItem.kafkaId : ""
			},
			  cssClass: 'change-order-modal'
		  });
  
		  await copyOrderModal.present();

	}

	checkCancelOrderDisabled(): boolean {
		const liData = this.lineItem ? this.lineItem : this.boothLineItem;
		return liData ? (liData.isInvoiced || liData.isFreightOrder || liData.isShowClosed) : false;
	}
}
