import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Area } from "src/app/models/show-setup/area-dto";
import { environment } from "src/environments/environment";
import { ToastService } from "../toast/toast.service";
import * as _ from 'lodash';
import { FreightHistoryDTO } from "src/app/models/show-setup/freight-history-dto";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FreightSetupService {
  url = environment.apiUrl;
  public freightZoneStatusSubject = new BehaviorSubject<any>(null);

  constructor(
    public http: HttpClient,
    public toastService: ToastService
  ) { }

  checkAreaExist(eventGUID: string, areatype, name) {
    return this.http.get(`${this.url()}events/${eventGUID}/showsetup/area/${areatype}/${name}`).pipe(map((data: boolean) => {
      return data;
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error while getting  name', 'danger');
      }
    }));
  }

  saveFreightZoneInfo(eventGUID: any, freightZoneData: any) {
    return this.http.post(`${this.url()}events/${eventGUID}/freightSetup/freight/save`, freightZoneData).pipe(map((freightZoneResponse: any) => {
      if (freightZoneResponse && freightZoneResponse.status == "success") {
        this.toastService.open(freightZoneResponse.message, 'success');
        return freightZoneResponse;
      } else {
        this.toastService.open('There was an error while saving freight zone info', 'danger');
        return false;
      }
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error while saving freight zone info', 'danger');
      }
      return false;
    }));
  }

  getShowHistoryData(eventId: string) {
    return this.http.get<Array<FreightHistoryDTO>>(`${this.url()}events/${eventId}/freightSetup/freight/historyDetails`).pipe(map((data: any) => {
      if (data != null) {
        data = _.orderBy(_.uniqBy(data, 'showGuid'), ['name'], ['asc']);
        return data;
      } else {
        return [];
      }
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error to getting past show data', 'danger');
      }
      return [];
    }));
  }

  setfreightZoneStatus(eventId: string, freightZoneList: any, statusId: number) {
    const uploadDto: any = {};
    uploadDto.Status = statusId;
    uploadDto.AreaGuid = freightZoneList.areaGuid;
    this.http.put<any>(`${this.url()}events/${eventId}/freightSetup/freightstatusupdate`, uploadDto)
    .subscribe(data => {
      this.freightZoneStatusSubject.next({ "areaGuid": freightZoneList.areaGuid, "statusId": statusId});
    });
  }   
}