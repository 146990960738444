import { Component, Input, OnInit } from '@angular/core';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { InAppBrowserObject, InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { ModalController, Platform, LoadingController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { AuthTokenHelper } from 'src/app/helpers/auth-token-helper';
import { FileExtensionHelper } from 'src/app/helpers/file-extension-helper';
import { SelectHelper } from 'src/app/helpers/select-helper';
import { Attachment } from 'src/app/models/attachment';
import { AttachmentService } from 'src/app/services/attachment/attachment.service';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';
import { LineItemService } from 'src/app/services/line-item/line-item.service';
import { WorkTicketService } from 'src/app/services/work-ticket/work-ticket.service';
import { WorkticketLineItemService } from 'src/app/services/workticket-line-item/workticket-line-item.service';
import { environment } from 'src/environments/environment';
import { FileDisplayComponent } from '../file-display/file-display.component';
import { takeWhile } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'occ-work-ticket-lineitem-file-viewer',
  templateUrl: './work-ticket-line-item-file-viewer.component.html',
  styleUrls: ['./work-ticket-line-item-file-viewer.component.scss'],
})
export class WorkTicketLineItemFileViewerComponent implements OnInit {

  @Input() isLineItems: boolean;

  browser: InAppBrowserObject;
  fileModal: any;
  loading: any;
  attachments: Attachment[] = [];
  attachmentSubject: BehaviorSubject<Attachment[]>;
  subscribe = true;
  isLoading: boolean;
  firstLoad = false;
  itemType: string;
  eventId: string;
  workTicketGuid: string;
  baseUrl = environment.fileBaseUrl();

  constructor(
    public attachmentSvc: AttachmentService,
    public modalCtrl: ModalController,
    private authTokenHelper: AuthTokenHelper,
    public inAppBrowser: InAppBrowser,
    public platform: Platform,
    public loadCtrl: LoadingController,
    public lineItemSvc: LineItemService,
    public workTicketSvc: WorkTicketService,
    public wtLiSvc: WorkticketLineItemService,
    public dataSvc: DataRefreshService,
    private fileHelper: FileExtensionHelper,
    private fileOpener: FileOpener,
    private activatedRoute: ActivatedRoute,
  ) {
    this.eventId = this.activatedRoute.snapshot.paramMap.get('eventId');
    this.workTicketGuid = this.activatedRoute.snapshot.paramMap.get('workTicketGuid');
  }

  ngOnInit() {
    this.isLoading = true;
    // TODO: Add the ternary to handle work ticket attachments
    this.attachmentSubject = this.attachmentSvc.wtLiAttachments;
    this.attachmentSubject.pipe(takeWhile(() => this.subscribe)).subscribe(files => {
      this.attachments = files;
      if (this.firstLoad) {
        this.firstLoad = false;
      }
      this.isLoading = false;
      this.itemType = this.isLineItems ? 'file' : 'attachment';
    });
    this.firstLoad = true;
    this.refresh();
  }

  ngOnDestroy() {
    this.subscribe = false;
    if (this.fileModal) {
      this.fileModal.dismiss();
    }
  }


  refresh(refresher?): void {
    if (this.isLineItems) {
      this.attachmentSvc.getWorkTicketLineItemFiles(this.eventId, this.workTicketGuid, this.wtLiSvc.currentLineItem.getValue().kafkaId);
    }
    // TODO: Add in the getter for workticket files when we figure that out.
    if (refresher) {
      refresher.target.complete();
    }

  }

  getAndDisplayFile(name: string, fileId: string) {
    // this.fileHelper.checkFile(fileId, name)
    //   .then((fileExists) => {
    //     const mimeType = this.fileHelper.getMimeType(name);
    //     if (fileExists) {
    //       const fileUrl = this.fileHelper.getFileUrl(fileId, name);
    //       console.log('getAndDisplayPdf - File exists ' + fileUrl);
    //       this.fileOpener.open(fileUrl, mimeType)
    //         .then((params) => {
    //           console.log(params);
    //           console.log('successfully opened file');
    //         })
    //         .catch((err) => console.log(err));
    //     } else {
    //       this.downloadFile(fileId, name).then((fileUrl) => {
    //         if (this.loading && this.loading.dismiss) {
    //           this.loading.dismiss();
    //         }
    //         this.fileOpener.open(fileUrl, mimeType)
    //           .then((params) => {
    //             console.log(params);
    //             console.log('successfully opened file');
    //           })
    //           .catch((err) => console.log(err));
    //       });
    //     }
    //   })
    //   .catch((err) => { console.log('There was an error attempting to access file storage.', JSON.stringify(err)); });
    const mimeType = this.fileHelper.getMimeType(name);
		this.downloadFile(fileId, name).then((fileUrl) => {

			if (this.loading && this.loading.dismiss) {
				this.loading.dismiss();
			}
			this.fileOpener.open(fileUrl, mimeType)
				.then((params) => {
					console.log(params);
					console.log('successfully opened file');
				})
				.catch((err) => console.log(err));
		});
  }

  showFile(name: string, fileId: string) {
    if (SelectHelper.CheckSelectedText(window.getSelection())) return;
    if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
      this.handleFileForBrowser(name, fileId);
    } else {
      this.getAndDisplayFile(name, fileId);
    }
  }

  handleFileForBrowser(name: string, fileId: string) {
    const isPdf = FileExtensionHelper.isPdf(name);
    if (isPdf) {
      this.getAndDisplayPdfBrowser(name, fileId);
    } else {
      this.displayFileInHubDocumentViewer(name, fileId);
    }
  }

  displayFileInHubDocumentViewer(name: string, fileId: string) {
    // since the document id property is called 'fileId' on functional area attachments and 'passportFileId' on line item files, the document id is being set dynamically depending on which parameter comes in
    const documentId = fileId;
    const fileName = name;
    //  Security key to allow file view on android and ie11
    const securityKey = 'AA64D121-F079-4BE2-9B40-891A4C03EFC8';
    this.authTokenHelper.GetAccessToken().then(async token => {
      const fileUrl = `${this.baseUrl}${documentId}&filename=${fileName}&t=${token}&s=${securityKey}`;
      if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
        this.fileModal = await this.modalCtrl.create({
          component: FileDisplayComponent,
          cssClass: 'file-viewer-modal',
          showBackdrop: true,
          componentProps: { fileUrl: fileUrl, name: name }
        });
        await this.fileModal.present();
      } else {
        const iabOptions: InAppBrowserOptions = {
          presentationstyle: 'pagesheet',
          toolbarposition: 'top'
        };
        this.browser = this.inAppBrowser.create(fileUrl, '_self', iabOptions);
      }
    });
  }

  async getAndDisplayPdfBrowser(name: string, fileId: string) {
    const pdfFileUrl = `${environment.apiUrl()}events/${this.eventId}/work-tickets/${this.workTicketGuid}/line-items/${this.wtLiSvc.currentLineItem.getValue().kafkaId}/files/${fileId}`;

    this.fileModal = await this.modalCtrl.create({
      component: FileDisplayComponent,
      cssClass: 'file-viewer-modal',
      showBackdrop: true,
      componentProps: { fileUrl: pdfFileUrl, name: name, isPdf: true }
    });
    await this.fileModal.present();
  }

  async downloadFile(fileId: string, fileName: string): Promise<any> {
    this.loading = await this.loadCtrl.create({
      spinner: 'bubbles',
      message: 'Downloading file..'
    });
    await this.loading.present();
    return this.attachmentSvc.downloadLineItemAttachment(
      this.eventId,
      this.wtLiSvc.currentLineItem.getValue().showAccountGuid,
      this.wtLiSvc.currentLineItem.getValue().functionalAreaGuid,
      this.wtLiSvc.currentLineItem.getValue().kafkaId,
      fileId,
      fileName);
  }
}
