import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingController, ModalController, NavParams } from '@ionic/angular';
import { LocationDTO } from 'src/app/models/location-dto';
import { LineItemService } from 'src/app/services/line-item/line-item.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'occ-location-selection-modal',
  templateUrl: './location-selection-modal.component.html',
  styleUrls: ['./location-selection-modal.component.scss'],
})
export class LocationSelectionModalComponent implements OnInit, OnDestroy {

  locations: LocationDTO[];
	subscribe = true;
	selectedLocationId: string;
	callback: Function;
	isLoading: boolean;
	loading: any;

	constructor(
		public lineItemSvc: LineItemService,
		public viewCtrl: ModalController,
		public navParams: NavParams,
		public loadingCtrl: LoadingController
	) {
		
	}
  async ngOnInit(): Promise<void> {
    this.isLoading = true;
		this.loading = await this.loadingCtrl.create({
			message: 'Fetching locations...'
		});
		await this.loading.present();

		this.callback = this.navParams.data.cb;
		this.lineItemSvc.locationDto.pipe(takeWhile(() => this.subscribe)).subscribe(locationDto => {
			this.locations = locationDto;
			this.loading.dismiss().then(() => {
				this.isLoading = false;
			});
		});
		this.selectedLocationId = this.navParams.data.selectedLocationId;
  }

	selectLocation() {
		if (this.callback) {
			if (this.selectedLocationId) {
				this.callback(this.selectedLocationId);
			} else {
				this.callback('None');
			}
		}
		this.viewCtrl.dismiss();
	}

	cancelModal() {
		this.viewCtrl.dismiss();
	}

	ngOnDestroy() {
		this.subscribe = false;
	}

}
