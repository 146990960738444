import {Injectable} from '@angular/core';
import { File } from '@ionic-native/file/ngx';
import * as mimeTypes from 'mime';

@Injectable()
export class FileExtensionHelper {

	public FILE_CACHE_DIR = 'file_cache';

	constructor(private file: File) {}

	private getFileCacheFullPath() {
		return this.file.cacheDirectory + this.FILE_CACHE_DIR + '/';
	}

	public static isPdf(fileName): boolean {
		if (!fileName || typeof(fileName) !== 'string') return false;

		return fileName.toLowerCase().indexOf('.pdf') !== -1;
	}

	public getMimeType(fileName: string): string {
		return mimeTypes.getType(fileName) || 'application/octet-stream';
	}

	public purgeCache(): void {
		this.checkCacheDir()
			.then((dirExists) => {

				if (dirExists) {
					this.file.removeRecursively(this.file.cacheDirectory, this.FILE_CACHE_DIR)
						.then((successObj) => {
							console.log('Successfully purged file cache');
							return successObj;
						})
						.catch((err) => console.log('Failed to purge file cache', JSON.stringify(err)));
				}

			})
			.catch((err) => console.log('Failed to check cache directory.\n' + JSON.stringify(err)));

	}

	private composeFileName(fileId: string, fileName: string) {
		const fileNameWithoutWhiteSpace = fileName.replace(/\s/g, '');
		return `${fileId}_${fileNameWithoutWhiteSpace}`;
	}

	public checkDir(parentDirectory: string, dirToCheck: string) {
		return this.file.checkDir(parentDirectory, dirToCheck)
			.catch((err) => {
				// Since the api is horrible, we need to check manually if the path doesn't exist
				if (err && err.code === 1) return false;
			});
	}

	public checkCacheDir() {
		return this.checkDir(this.file.cacheDirectory, this.FILE_CACHE_DIR);
	}

	private createCacheDir() {
		const replaceDirIfExists = true;
		return this.file.createDir(this.file.cacheDirectory, this.FILE_CACHE_DIR, replaceDirIfExists)
			.catch((err) => console.log('Failed to create cache directory ' + JSON.stringify(err)));
	}

	public checkFile(fileId: string, fileName: string) {

		const adjustedFileName = this.composeFileName(fileId, fileName);
		console.log(`fileExtensionHelper - checkFile - Attempting to check file: ${this.getFileCacheFullPath() + adjustedFileName}`);

		return this.file.checkFile(this.getFileCacheFullPath(), adjustedFileName)
			.catch((err) => {
				// Since the api is horrible, it returns an error with code "1" if the file doesn't exist
				if (err && err.code === 1) return false;
				console.log('fileExtensionHelper - checkFile - encountered error while checking file ' + JSON.stringify(err));
				return err;
			});
	}

	public getFileUrl(fileId: string, fileName: string) {
		return this.getFileCacheFullPath() + this.composeFileName(fileId, fileName);
	}

	private checkAndSaveFile(fileId: string, fileName: string, fileBlob: Blob) {

		const adjustedFileName = this.composeFileName(fileId, fileName);
		console.log('checkAndSaveFile - Checking if file exists: ' + adjustedFileName);

		return this.checkFile(fileId, fileName).then(fileExists => {

			console.log('checkAndSaveFile - Successfully checked if file exists:  ' + fileExists);
			if (fileExists) {
				console.log('checkAndSaveFile - File exists, returning file url: ' + this.getFileUrl(fileId, fileName));
				return this.getFileUrl(fileId, fileName);
			}

			console.log(`checkAndSaveFile - Preparing to write file: ${this.getFileCacheFullPath() + adjustedFileName}` );
			return this.file.writeFile(this.getFileCacheFullPath(), adjustedFileName, fileBlob)
				.then((fileEntry) => {

					console.log('checkAndSaveFile - Successfully wrote file, native url: ' + fileEntry.nativeURL);
					return fileEntry.nativeURL;

				})
				.catch((err) => console.log('checkAndSaveFile - Failed to save file due to error: ' + JSON.stringify(err)));
		});
	}

	public saveFile(fileId: string, fileName: string, fileBlob: Blob) {

		console.log('saveFile - starting save process');

		return this.checkCacheDir()
			.then((dirExists) => {

				if (dirExists) {
					return this.checkAndSaveFile(fileId, fileName, fileBlob);
				}

				return this.createCacheDir()
					.then(() => {
						return this.checkAndSaveFile(fileId, fileName, fileBlob);
					});
		});

	}
}
