import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonAccordionGroup, ModalController, PopoverController } from '@ionic/angular';
import * as moment from 'moment';
import { Moment } from 'moment';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';
import { TimeValidator } from 'src/app/helpers/time-validation-helper';
import { WorkticketLineItemService } from 'src/app/services/workticket-line-item/workticket-line-item.service';
import * as _ from 'lodash';
import { ShowFiltersService } from 'src/app/services/show-setup/show-filters.service';
import { Storage } from '@ionic/storage-angular';
import { ShowFilterConfirmationComponent } from '../show-setup/show-filter-confirmation/show-filter-confirmation.component';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';

@Component({
  selector: 'occ-makeover-filter',
  templateUrl: './makeover-filter.component.html',
  styleUrls: ['./makeover-filter.component.scss'],
})
export class MakeoverFilterComponent implements OnInit {
  @ViewChild(IonAccordionGroup, { static: true }) accordionGroup: IonAccordionGroup;
  selectedOption: any = '';
  pgSearchedText: string = '';
  faSearchedText: string = '';
  boothSearchedText: string = '';
  enSearchedText: string = '';
  selectedFilterItemList: any = [];
  selectedItem: any;
  startDate: any;
  datepicker
  completeDate: any;
  lowerBoundDate: any;
  datepickerLowerBoundDate: any;
  upperBoundDate: any;
  datepickerUpperBoundDate: any;
  starttimeperiodType: string = "AM";
  completetimeperiodType: string = "AM";
  startHour: string = '';
  startMinute: string = '';
  completeHour: string = '';
  completeMinute: string = '';
  selectedStartDateTime: any;
  selectedCompleteDateTime: any;
  boothLowerRange: string;
  boothUpperRange: string;

  @Input() filterData: any;
  isAllPartGroupSelected: boolean = false;
  isAllFASelected: boolean = false;
  isAllLocationSelected: boolean = false;
  isAllBoothSelected: boolean = false;
  isAllExhibitorsSelected: boolean = false;

  myDatePickerOptions: IAngularMyDpOptions = {
    alignSelectorRight: true,
    // showClearDateBtn: false,
    dateFormat: 'mm/dd/yyyy'
  };

  upperBoundDateOptions: IAngularMyDpOptions = {
    alignSelectorRight: true,
    // showClearDateBtn: false,
    dateFormat: 'mm/dd/yyyy',
    disableUntil: {
      year: 0,
      month: 0,
      day: 0
    }
  }

  public placeholder: string = 'MM/DD/YYYY';
  filteredLineItems: any;

  @Input() eventId: any;
  @Input() allLineItems: any;
  @Input() isSetupShowFilters: any;
  applyToAllEvents: any;
  resetPartGroupData: boolean = true;

  appliedPartFilterData: any = [];

  isCalendarOpen: any;

  constructor(
    private modalCtrl: ModalController,
    public appBrowserHelper: AppBrowserHelper,
    public showFiltersService: ShowFiltersService,
    public wtLineItemService: WorkticketLineItemService,
    private timeValidator: TimeValidator,
    private storage: Storage,
    private popoverCtrl: PopoverController,
		private ga: GoogleAnalytics
  ) {

  }


  ngOnInit() {
    this.appliedPartFilterData = this.filterData.appliedPartFilter || [];
    this.checkForSingleFacility(true);
    this.getAllSelectionStatus(0);
    this.getAllSelectionStatus(1);
    this.getAllSelectionStatus(3);
    this.getAllSelectionStatus(4);
    this.getAllSelectionStatus(8);
    this.setDateFilters();
    this.clearSearch(0);
    this.clearSearch(1);
    this.clearSearch(4);
    this.clearSearch(8);
    this.boothLowerRange = this.filterData.boothLowerRange.name;
    this.boothUpperRange = this.filterData.boothUpperRange.name;
    this.dynamicFilter(0);
    this.dynamicFilter(1);
    this.dynamicFilter(2);
    this.dynamicFilter(8);

    if (this.appBrowserHelper.isDesktop) {
      setTimeout(() => {
        this.selectedOption = 'partgroup';
      }, 300);
    }
  }

  toggleAccordion(event) {
    this.selectedOption = this.accordionGroup.value;
  }

  calendarToggle(event) {
    console.log(event);
    if (event == 1) 
      this.isCalendarOpen = true;
    else  
      this.isCalendarOpen = false;
  }

  selectAllOptions(currentAccordianIndex: any) {
    switch (currentAccordianIndex) {
      case 0:
        this.isAllPartGroupSelected = !this.isAllPartGroupSelected;
        this.filterData.partgroups.forEach(partGroup => {
          partGroup.isChecked = this.isAllPartGroupSelected;
        });
        break;
      case 1:
        this.isAllFASelected = !this.isAllFASelected;
        this.filterData.functional_areas.forEach(functionaArea => {
          functionaArea.isChecked = this.isAllFASelected;
        });
        break;
      case 3:
        this.isAllLocationSelected = !this.isAllLocationSelected;
        this.filterData.locations.forEach(locationItem => {
          locationItem.isChecked = this.isAllLocationSelected;
          if (!locationItem.isChecked) {
            locationItem.booths.forEach(boothItem => {
              boothItem.isChecked = false;
            });
          }
        });
        this.setBoothData();
        this.getAllSelectionStatus(4);
        break;
      case 4:
        this.isAllBoothSelected = !this.isAllBoothSelected;
        this.filterData.booths.forEach(booth => {
          booth.isChecked = this.isAllBoothSelected;
        });
        break;
      case 8:
        this.isAllExhibitorsSelected = !this.isAllExhibitorsSelected;
        this.filterData.exhibitorNames.forEach(exhibitor => {
          exhibitor.isChecked = this.isAllExhibitorsSelected;
        });
        break;
      default:
        break;
    }
  }

  getSearchData(searchString: string, currentAccordianIndex: any) {
    switch (currentAccordianIndex) {
      case 0:
        this.filterData.partgroups.forEach(partGroup => {
          if (searchString.length) {
            if (partGroup.name.toLowerCase().includes(searchString.toLowerCase())) {
              partGroup.isSearchMatched = true;
            } else {
              partGroup.isSearchMatched = false;
            }
          } else {
            partGroup.isSearchMatched = true;
          }
        });
        break;
      case 1:
        this.filterData.functional_areas.forEach(functional_area => {
          if (searchString.length) {
            if (functional_area.name.toLowerCase().includes(searchString.toLowerCase())) {
              functional_area.isSearchMatched = true;
            } else {
              functional_area.isSearchMatched = false;
            }
          } else {
            functional_area.isSearchMatched = true;
          }
        });
        break;
      case 4:
        this.filterData.booths.forEach(booth => {
          if (searchString.length) {
            if (booth.name.toLowerCase().includes(searchString.toLowerCase())) {
              booth.isSearchMatched = true;
            } else {
              booth.isSearchMatched = false;
            }
          } else {
            booth.isSearchMatched = true;
          }
        });
        break;
      case 8:
        this.filterData.exhibitorNames.forEach(exhibitor => {
          if (searchString.length) {
            if (exhibitor.name.toLowerCase().includes(searchString.toLowerCase())) {
              exhibitor.isSearchMatched = true;
            } else {
              exhibitor.isSearchMatched = false;
            }
          } else {
            exhibitor.isSearchMatched = true;
          }
        });
        break;
      default:
        break;
    }
  }

  getSearchCount(currentAccordianIndex: any) {
    let serachCount = 0;
    switch (currentAccordianIndex) {
      case 0:
        this.filterData.partgroups.forEach(partGroup => {
          if (partGroup.isSearchMatched) {
            ++serachCount;
          }
        });
        break;
      case 1:
        this.filterData.functional_areas.forEach(functional_area => {
          if (functional_area.isSearchMatched) {
            ++serachCount;
          }
        });
        break;
      case 4:
        this.filterData.booths.forEach(booth => {
          if (booth.isSearchMatched) {
            ++serachCount;
          }
        });
        break;
      case 8:
        this.filterData.exhibitorNames.forEach(exhibitor => {
          if (exhibitor.isSearchMatched) {
            ++serachCount;
          }
        });
        break;
      default:
        break;
    }
    return serachCount;
  }

  clearSearch(currentAccordianIndex: any) {
    switch (currentAccordianIndex) {
      case 0:
        this.pgSearchedText = "";
        this.getSearchData(this.pgSearchedText, currentAccordianIndex);
        break;
      case 1:
        this.faSearchedText = "";
        this.getSearchData(this.faSearchedText, currentAccordianIndex);
        break;
      case 4:
        this.boothSearchedText = "";
        this.getSearchData(this.boothSearchedText, currentAccordianIndex);
        break;
      case 8:
        this.enSearchedText = "";
        this.getSearchData(this.enSearchedText, currentAccordianIndex);
        break;
      default:
        break;
    }
  }

  setItemSelection(item: any, currentAccordianIndex: any) {
    switch (currentAccordianIndex) {
      case 0:
        item.isChecked = !item.isChecked;
        this.getAllSelectionStatus(currentAccordianIndex);
        this.dynamicFilter(currentAccordianIndex);
        break;
      case 1:
        item.isChecked = !item.isChecked;
        this.getAllSelectionStatus(currentAccordianIndex);
        this.dynamicFilter(currentAccordianIndex);
        break;
      case 2:
        this.filterData.facilities.forEach(facility => {
          facility.isChecked = false;
          // facility.isChecked = item.name == facility.name ? true : false;
          if (item.name == facility.name)
            item.isChecked = !item.isChecked;
        });
        this.dynamicFilter(currentAccordianIndex);
        item.locations.forEach(location => {
          location.isChecked = false;
          location.booths.forEach(boothItem => {
            boothItem.isChecked = false;
          });
        });
        this.filterData.locations = item.locations;
        this.filterData.booths = [];
        this.getAllSelectionStatus(3);
        this.getAllSelectionStatus(4);

        break;
      case 3:
        item.isChecked = !item.isChecked;
        if (!item.isChecked) {
          item.booths.forEach(boothItem => {
            boothItem.isChecked = false;
          });
        }
        this.setBoothData();
        this.getAllSelectionStatus(currentAccordianIndex);
        this.getAllSelectionStatus(4);
        break;
      case 4:
        item.isChecked = !item.isChecked;
        this.getAllSelectionStatus(currentAccordianIndex);
        break;
      case 8:
        item.isChecked = !item.isChecked;
        this.getAllSelectionStatus(currentAccordianIndex);
        this.dynamicFilter(currentAccordianIndex);
        break;
      default:
        break;
    }
  }

  getAllSelectionStatus(currentAccordianIndex: any) {
    switch (currentAccordianIndex) {
      case 0:
        if (this.filterData.partgroups.length > 0) {
          this.isAllPartGroupSelected = this.filterData.partgroups.find(partGroup => partGroup.isChecked == false) ? false : true;
        }
        break;
      case 1:
        if (this.filterData.functional_areas.length > 0) {
          this.isAllFASelected = this.filterData.functional_areas.find(functional_area => functional_area.isChecked == false) ? false : true;
        }
        break;
      case 3:
        if (this.filterData.locations.length > 0) {
          this.isAllLocationSelected = this.filterData.locations.find(location => location.isChecked == false) ? false : true;
        }
        break;
      case 4:
        if (this.filterData.booths.length > 0) {
          this.isAllBoothSelected = this.filterData.booths.find(booth => booth.isChecked == false) ? false : true;
        }
        break;
      case 8:
        if (this.filterData.exhibitorNames.length > 0) {
          this.isAllExhibitorsSelected = this.filterData.exhibitorNames.find(exhibitor => exhibitor.isChecked == false) ? false : true;
        }
        break;
      default:
        break;
    }
  }

  setBoothData() {
    this.filterData.booths = [];
    this.filterData.locations.forEach(location => {
      if (location.isChecked) {
        this.filterData.booths = _.uniqBy(this.filterData.booths.concat(location.booths), "name")
      }
    });
  }

  applyFilterSelection() {
    this.filterData.appliedPartFilter = this.appliedPartFilterData;
    this.setAppliedDateFilters();
    this.modalCtrl.dismiss({
      isfilterApplied: true,
      appliedFilersData: this.filterData
    });
    this.ga.trackEvent('FilterApplied', 'OpsLineItemFilter');
  }

  clearFilterSelection() {
    if (!this.showFiltersService.presetFilters) {
      this.filterData.appliedPartFilter = [];
      this.filterData.partgroups.forEach(partGroup => {
        partGroup.isChecked = false;
      });

      if (this.filterData.functional_areas) {
        this.filterData.functional_areas.forEach(functionalArea => {
          functionalArea.isChecked = false;
        });
      }

      this.filterData.facilities.forEach(facility => {
        facility.isChecked = false;
        facility.locations.forEach(locationItem => {
          locationItem.isChecked = false;
          locationItem.booths.forEach(booth => {
            booth.isChecked = false;
          });
        });
        this.checkForSingleFacility(false);
      });

      this.filterData.locations = [];

      this.filterData.booths = [];

      this.isAllPartGroupSelected = false;
      this.isAllFASelected = false;
      this.isAllLocationSelected = false;
      this.isAllBoothSelected = false;

      this.filterData.setupStartDate = {
        filterType: "5",
        name: ""
      }
      this.filterData.setupCompleteDate = {
        filterType: "6",
        name: ""
      };

      this.setDatePickerValues("", "");
    }
    this.wtLineItemService.isOpsFilterClearSubject.next(true);
    this.closeFilterModal();
    this.ga.trackEvent('FilterCleared', 'OpsLineItemFilter');
  }

  closeFilterModal() {
    this.modalCtrl.dismiss({
      isfilterApplied: false
    });
  }

  checkForSingleFacility(isSetChecked: boolean) {
    if (this.filterData.facilities.length == 1) {
      this.filterData.facilities[0].isChecked = isSetChecked;
      this.filterData.locations = this.filterData.facilities[0].locations;
    }
  }

  setDateFilters() {
    this.selectedStartDateTime = this.filterData.setupStartDate.name;
    this.selectedCompleteDateTime = this.filterData.setupCompleteDate.name;
    this.setDatePickerValues(this.selectedStartDateTime, this.selectedCompleteDateTime);
  }

  setDatePickerValues(selectedStartDateTime: any, selectedCompleteDateTime: any) {
    this.lowerBoundDate = '';
    this.upperBoundDate = '';
    this.datepickerLowerBoundDate = "";
    this.datepickerUpperBoundDate = "";
    this.startHour = '';
    this.startMinute = '';
    this.completeHour = '';
    this.completeMinute = '';
    this.starttimeperiodType = 'AM';
    this.completetimeperiodType = 'AM';
    if (selectedStartDateTime) {
      let startDate = new Date(selectedStartDateTime);
      let startHour = startDate.getHours();
      startHour = ((startHour + 11) % 12 + 1);
      this.startHour = startHour < 10 ? '0' + startHour.toString() : startHour.toString();

      let startMinute = startDate.getMinutes();
      this.startMinute = startMinute < 10 ? '0' + startMinute.toString() : startMinute.toString();

      this.lowerBoundDate = { date: { month: startDate.getMonth() + 1, day: startDate.getDate(), year: startDate.getFullYear() } };
      this.datepickerLowerBoundDate = {
        singleDate: this.lowerBoundDate
      };

      if (selectedStartDateTime.includes('AM')) {
        this.starttimeperiodType = 'AM';
      } else {
        this.starttimeperiodType = 'PM';
      }
    }
    if (selectedCompleteDateTime) {
      let completeDate = new Date(selectedCompleteDateTime);
      let completeHour = completeDate.getHours();
      completeHour = ((completeHour + 11) % 12 + 1);
      this.completeHour = completeHour < 10 ? '0' + completeHour.toString() : completeHour.toString();

      let completeMinute = completeDate.getMinutes();
      this.completeMinute = completeMinute < 10 ? '0' + completeMinute.toString() : completeMinute.toString();

      this.upperBoundDate = { date: { month: completeDate.getMonth() + 1, day: completeDate.getDate(), year: completeDate.getFullYear() } };
      this.datepickerUpperBoundDate = {
        singleDate: this.upperBoundDate
      }

      if (selectedCompleteDateTime.includes('AM')) {
        this.completetimeperiodType = 'AM';
      } else {
        this.completetimeperiodType = 'PM';
      }
    }
  }

  checkForValidEntry(completeDate = false) {
    setTimeout(() => {

      let lowerBoundDate: Moment;
      let upperBoundDate: Moment;

      this.lowerBoundDate = this.datepickerLowerBoundDate ? this.datepickerLowerBoundDate.singleDate : this.lowerBoundDate;
      this.upperBoundDate = this.datepickerUpperBoundDate ? this.datepickerUpperBoundDate.singleDate : this.upperBoundDate;

      if (this.lowerBoundDate && !completeDate) {
        let dateValue = moment(this.lowerBoundDate.jsDate).subtract(1, 'day');
        this.upperBoundDateOptions.disableUntil = {
          year: Number(dateValue.format('Y')),
          month: Number(dateValue.format('M')),
          day: Number(dateValue.format('D'))
        }
        this.upperBoundDateOptions = JSON.parse(JSON.stringify(this.upperBoundDateOptions));
        if (this.upperBoundDate) {
          if (moment(this.upperBoundDate.jsDate).diff(moment(this.lowerBoundDate.jsDate)) < 0) {
            this.upperBoundDate = null;
          }
        }
      }

      if (!this.lowerBoundDate) {
        this.upperBoundDateOptions.disableUntil = {
          day: 0,
          month: 0,
          year: 0
        }
        this.upperBoundDateOptions = JSON.parse(JSON.stringify(this.upperBoundDateOptions));
      }

      if (this.lowerBoundDate) {
        lowerBoundDate = this.lowerBoundDate.formatted ? moment.utc(this.lowerBoundDate.formatted) : moment.utc(this.lowerBoundDate, 'MM/DD/YYYY');
      }
      if (this.upperBoundDate) {
        upperBoundDate = this.upperBoundDate.formatted ? moment.utc(this.upperBoundDate.formatted) : moment.utc(this.upperBoundDate, 'MM/DD/YYYY');
      }
    }, 300);
  }

  //Date time display formatting
  setAppliedDateFilters() {
    if (this.lowerBoundDate) {
      this.startHour = this.startHour ? this.startHour : '12';
      this.startMinute = this.startMinute ? this.startMinute : '00';
      this.filterData.setupStartDate.name = this.lowerBoundDate.date.year.toString() + '-' + (this.lowerBoundDate.date.month < 10 ? '0' + this.lowerBoundDate.date.month.toString() : this.lowerBoundDate.date.month.toString()) + '-' + (this.lowerBoundDate.date.day < 10 ? '0' + this.lowerBoundDate.date.day.toString() : this.lowerBoundDate.date.day.toString()) + ' ' + (parseInt(this.startHour) < 10 ? '0' + parseInt(this.startHour) : this.startHour) + ':' + (parseInt(this.startMinute) < 10 ? '0' + parseInt(this.startMinute) : this.startMinute) + ' ' + this.starttimeperiodType;
    }
    if (this.upperBoundDate) {
      this.completeHour = this.completeHour ? this.completeHour : '12';
      this.completeMinute = this.completeMinute ? this.completeMinute : '00';
      this.filterData.setupCompleteDate.name = this.upperBoundDate.date.year.toString() + '-' + (this.upperBoundDate.date.month < 10 ? '0' + this.upperBoundDate.date.month.toString() : this.upperBoundDate.date.month.toString()) + '-' + (this.upperBoundDate.date.day < 10 ? '0' + this.upperBoundDate.date.day.toString() : this.upperBoundDate.date.day.toString()) + ' ' + (parseInt(this.completeHour) < 10 ? '0' + parseInt(this.completeHour) : this.completeHour) + ':' + (parseInt(this.completeMinute) < 10 ? '0' + parseInt(this.completeMinute) : this.completeMinute) + ' ' + this.completetimeperiodType;
    }
  }

  inputChange(validationType, startValue, element) {
    let timeValue;
    if (validationType == 'hours') {
      timeValue = this.timeValidator.validateHours(element);
      startValue ? this.startHour = timeValue : this.completeHour = timeValue;
    } else {
      timeValue = this.timeValidator.validateMinutes(element);
      startValue ? this.startMinute = timeValue : this.completeMinute = timeValue;
    }
  }

  saveBoothRange() {
    this.filterData.boothLowerRange.name = this.boothLowerRange;
    this.filterData.boothUpperRange.name = this.boothUpperRange;
  }

  dynamicFilter(currentAccordianIndex: any) {
    let allWTLineItems = this.isSetupShowFilters ? this.allLineItems : this.wtLineItemService.collaborationLineItemDto.getValue();
    // let allWTLineItems = this.wtLineItemService.collaborationLineItemDto.getValue();
    _.remove(allWTLineItems, (lineItem) => !lineItem.booth);

    this.filteredLineItems = _.cloneDeep(allWTLineItems);
    let checkedPartGroups = _.filter(this.filterData.partgroups, ['isChecked', true]).map(partGroup => partGroup.name);
    let checkedFunctionalAreas = _.filter(this.filterData.functional_areas, ['isChecked', true]).map(functionalAreas => functionalAreas.name);
    let checkedFacilities = _.filter(this.filterData.facilities, ['isChecked', true]).map(facilities => facilities.name);
    let checkedExhibitorNames = _.filter(this.filterData.exhibitorNames, ['isChecked', true]).map(exhibitorNames => exhibitorNames.name);

    switch (currentAccordianIndex) {
      case 0:
        // Below changes to filter lineitems based on Groups, Subgroups & Parts
        let partFilteredLineItems = [];
        if (this.appliedPartFilterData.length) {
          this.appliedPartFilterData.forEach(filterItem => {
            switch (filterItem.type) {
              case "group":
                if (filterItem?.selectedSubgroups?.length) {
                  filterItem.selectedSubgroups.forEach(sgItem => {
                    if (sgItem?.selectedParts?.length) {
                      sgItem.selectedParts.forEach(partItem => {
                        partFilteredLineItems = partFilteredLineItems.concat(_.filter(this.filteredLineItems, (lineItem) => { return partItem.partNumber == lineItem.partNumber; }));
                      });
                    } else {
                      let splittedPartNumber = sgItem.partNumber.split("-");
                      partFilteredLineItems = partFilteredLineItems.concat(_.filter(this.filteredLineItems, (lineItem) => { return lineItem.partNumber.startsWith(splittedPartNumber[0] + "-" + splittedPartNumber[1] + "-"); }));
                    }
                  });
                } else {
                  let splittedPartNumber = filterItem.partNumber.split("-");
                  partFilteredLineItems = partFilteredLineItems.concat(_.filter(this.filteredLineItems, (lineItem) => { return lineItem.partNumber.startsWith(splittedPartNumber[0] + "-"); }));
                }
                break;
              case "subgroup":
                if (filterItem?.selectedParts?.length) {
                  filterItem.selectedParts.forEach(partItem => {
                    partFilteredLineItems = partFilteredLineItems.concat(_.filter(this.filteredLineItems, (lineItem) => { return partItem.partNumber == lineItem.partNumber; }));
                  });
                } else {
                  let splittedPartNumber = filterItem.partNumber.split("-");
                  partFilteredLineItems = partFilteredLineItems.concat(_.filter(this.filteredLineItems, (lineItem) => { return lineItem.partNumber.includes("-" + splittedPartNumber[1] + "-"); }));
                }
                break;
              case "part":
                partFilteredLineItems = partFilteredLineItems.concat(_.filter(this.filteredLineItems, (lineItem) => { return filterItem.partNumber == lineItem.partNumber; }));
                break;

              default:
                break;
            }
          });
          this.filteredLineItems = partFilteredLineItems;
          // this.filteredLineItems = _.filter(this.filteredLineItems,  (lineItem) => { return checkedPartGroups.includes(lineItem.partGroup); });
        }
        this.filterByItemSelection(currentAccordianIndex, checkedFunctionalAreas, checkedFacilities, checkedExhibitorNames);
        break;
      case 1:
        this.filteredLineItems = checkedPartGroups.length ? _.filter(this.filteredLineItems, (lineItem) => { return checkedPartGroups?.includes(lineItem.partGroup); }) : this.filteredLineItems;
        if (checkedFunctionalAreas.length) {
          this.filteredLineItems = _.filter(this.filteredLineItems, (lineItem) => { return checkedFunctionalAreas.includes(lineItem.functionalAreaName); });
        }
        this.filterByItemSelection(currentAccordianIndex, checkedFunctionalAreas, checkedFacilities, checkedExhibitorNames);
        break;
      case 2:
        this.filteredLineItems = checkedPartGroups.length ? _.filter(this.filteredLineItems, (lineItem) => { return checkedPartGroups?.includes(lineItem.partGroup); }) : this.filteredLineItems;
        this.filteredLineItems = checkedFunctionalAreas.length ? _.filter(this.filteredLineItems, (lineItem) => { return checkedFunctionalAreas?.includes(lineItem.functionalAreaName); }) : this.filteredLineItems;
        if (checkedFacilities.length) {
          this.filteredLineItems = _.filter(this.filteredLineItems, (lineItem) => { return checkedFacilities.includes(lineItem.facility); });
        }
        this.filterByItemSelection(currentAccordianIndex, checkedFunctionalAreas, checkedFacilities, checkedExhibitorNames);
        break;
      case 8:
        this.filteredLineItems = checkedPartGroups.length ? _.filter(this.filteredLineItems, (lineItem) => { return checkedPartGroups?.includes(lineItem.partGroup); }) : this.filteredLineItems;
        this.filteredLineItems = checkedFunctionalAreas.length ? _.filter(this.filteredLineItems, (lineItem) => { return checkedFunctionalAreas?.includes(lineItem.functionalAreaName); }) : this.filteredLineItems;
        this.filteredLineItems = checkedFacilities.length ? _.filter(this.filteredLineItems, (lineItem) => { return checkedFunctionalAreas?.includes(lineItem.facility); }) : this.filteredLineItems;
        if (checkedExhibitorNames.length) {
          this.filteredLineItems = _.filter(this.filteredLineItems, (lineItem) => { return checkedExhibitorNames.includes(lineItem.accountName); });
        }
        this.filterByItemSelection(currentAccordianIndex, checkedFunctionalAreas, checkedFacilities, checkedExhibitorNames);
        break;
      default:
        break;
    }
  }

  filterByItemSelection(index: Number, checkedFunctionalAreas: any, checkedFacilities: any, checkedExhibitorNames: any) {
    let locationData = [];
    let boothsData = [];
    if (index == 0) {
      this.filterData.functional_areas = [];
    }
    if ((index == 0) || (index == 1)) {
      this.filterData.facilities = [];
    }
    if ((index == 0) || (index == 1) || (index == 2)) {
      this.filterData.exhibitorNames = [];
    }
    this.filteredLineItems.forEach(lineItem => {
      if (index == 0 && lineItem.functionalAreaName) {
        this.filterData.functional_areas.push({
          filterType: "1",
          name: lineItem.functionalAreaName,
          isChecked: checkedFunctionalAreas.includes(lineItem.functionalAreaName) ? true : false,
          isSearchMatched: true
        });
      }
      if ((index == 0) || (index == 1) && lineItem.facility) {
        this.filterData.facilities.push({
          filterType: "2",
          name: lineItem.facility,
          isChecked: checkedFacilities.includes(lineItem.facility) ? true : false,
          isSearchMatched: true,
          locations: [],
        });
      }
      locationData.push({
        filterType: "3",
        name: lineItem.locationInfo,
        facility: lineItem.facility,
        isChecked: false,
        isSearchMatched: true,
        booths: []
      });
      boothsData.push({
        filterType: "4",
        name: lineItem.booth,
        facility: lineItem.facility,
        locationInfo: lineItem.locationInfo,
        isChecked: false,
        isSearchMatched: true
      });
      if ((index == 0) || (index == 1) || (index == 2) && lineItem.accountName) {
        this.filterData.exhibitorNames.push({
          filterType: "8",
          name: lineItem.accountName,
          isChecked: checkedExhibitorNames.includes(lineItem.accountName) ? true : false,
          isSearchMatched: true
        });
      }
    });

    this.filterData.partgroups = _.sortBy(_.uniqBy(this.filterData.partgroups, 'name'), [function (item) { return item.name; }]);
    this.filterData.functional_areas = _.sortBy(_.uniqBy(this.filterData.functional_areas, 'name'), [function (item) { return item.name; }]);
    this.filterData.facilities = _.sortBy(_.uniqBy(this.filterData.facilities, 'name'), [function (item) { return item.name; }]);
    this.filterData.exhibitorNames = _.sortBy(_.uniqBy(this.filterData.exhibitorNames, 'name'), [function (item) { return item.name; }]);
    this.filterData.facilities.forEach(facility => {
      facility.locations = _.uniqBy(locationData, 'name').filter(locationItem => locationItem.facility == facility.name);
      facility.locations = _.sortBy(facility.locations, [function (item) { return item.name; }])
      facility.locations.forEach(locationItem => {
        locationItem.booths = _.uniqBy(boothsData.filter(booth => booth.locationInfo == locationItem.name && booth.facility == facility.name), 'name');
        locationItem.booths = _.sortBy(locationItem.booths, [function (item) { return item.name; }])
      });
    });
  }

  setShowFilters() {
    let selectedFilterData = {
      appliedPartFilter: this.appliedPartFilterData,
      booths: _.map(_.filter(this.filterData.booths, ["isChecked", true]), (item) => item.name),
      locations: _.map(_.filter(this.filterData.locations, ["isChecked", true]), (item) => item.name),
      facilities: _.map(_.filter(this.filterData.facilities, ["isChecked", true]), (item) => item.name),
      functional_areas: _.map(_.filter(this.filterData.functional_areas, ["isChecked", true]), (item) => item.name),
      exhibitorNames: _.map(_.filter(this.filterData.exhibitorNames, ["isChecked", true]), (item) => item.name)
    };
    this.openConfirmationPopup(selectedFilterData);
  }

  async openConfirmationPopup(selectedFilters) {
    let requestData = {
      PartGroupJson: this.showFiltersService.applyToAllEvents ? JSON.stringify(selectedFilters.appliedPartFilter) : "",
      filterJson: JSON.stringify(selectedFilters),
      IsAllShowEnabled: this.showFiltersService.applyToAllEvents
    };

    const popover = await this.popoverCtrl.create({
      component: ShowFilterConfirmationComponent,
      cssClass: 'show-filter-confirmation',
      backdropDismiss: false,
      animated: false,
      mode: 'md',
      componentProps: {
        popupType: 'confirmation',
        eventId: this.eventId,
        requestData
      }
    });
    await popover.present();

    popover.onDidDismiss().then(dismissData => {
      if (dismissData && dismissData.data && dismissData.data.isSaved) {
        this.showSucessPopup();
      }

    })
    this.modalCtrl.dismiss();
  }

  async showSucessPopup() {
    const successPopup = await this.popoverCtrl.create({
      component: ShowFilterConfirmationComponent,
      cssClass: 'show-filter-confirmation',
      backdropDismiss: false,
      animated: false,
      mode: 'md',
      componentProps: {
        popupType: 'success',
      }
    });
    await successPopup.present();
  }

  onPartFilterChange(partFilterData) {
    this.appliedPartFilterData = partFilterData;
    // this.filterData.appliedPartFilter = partFilterData;
    this.dynamicFilter(0);
  }

  setPresetFiltersFlag() {
    setTimeout(() => {

      this.storage.set('disablePresetFilters', !this.showFiltersService.presetFilters);

      let allWTLineItems = this.isSetupShowFilters ? this.allLineItems : this.wtLineItemService.collaborationLineItemDto.getValue();

      this.filterData = this.showFiltersService.getFilterData(allWTLineItems);
      this.appliedPartFilterData = this.filterData.appliedPartFilter;
      this.resetPartGroupData = false;

      setTimeout(() => {
        this.resetPartGroupData = true;
        this.checkForSingleFacility(true);
        this.getAllSelectionStatus(0);
        this.getAllSelectionStatus(1);
        this.getAllSelectionStatus(3);
        this.getAllSelectionStatus(4);
        this.getAllSelectionStatus(8);
        this.setDateFilters();
        this.clearSearch(0);
        this.clearSearch(1);
        this.clearSearch(4);
        this.clearSearch(8);
        this.boothLowerRange = this.filterData.boothLowerRange.name;
        this.boothUpperRange = this.filterData.boothUpperRange.name;
        this.dynamicFilter(0);
        this.dynamicFilter(1);
        this.dynamicFilter(2);
        this.dynamicFilter(8);
      }, 300);
    }, 500);
  }

  openCalendar(datePickerRef: any){
    setTimeout(() => {
      datePickerRef.openCalendar()
    }, 100);
  }
}
