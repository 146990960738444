import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { IonInfiniteScroll, LoadingController, ModalController, NavParams, Platform, PopoverController } from '@ionic/angular';
import { StatusHelper } from 'src/app/helpers/status-helper';
import { productionDateTime } from 'src/app/models/bulk-status/production-date-time-dto';
import { CollaborationLineItemDTO } from 'src/app/models/collaboration-line-item-dto';
import { Status } from 'src/app/models/status';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { WorkticketLineItemService } from 'src/app/services/workticket-line-item/workticket-line-item.service';
import { BulkUpdateConfirmationModalComponent } from '../bulk-update-confirmation-modal/bulk-update-confirmation-modal-component';
import * as _ from 'lodash';

@Component({
  selector: 'occ-bulk-update-line-item-status-modal',
  templateUrl: './bulk-update-line-item-status-modal.component.html',
  styleUrls: ['./bulk-update-line-item-status-modal.component.scss'],
})
export class BulkUpdateLineItemStatusModalComponent implements OnInit {
  
  // @ViewChild('statusVSContent') statusVSContent: any;
  // @ViewChild('statusVS') statusVS: any;
  @ViewChild('statusUpdateContent') statusUpdateContent: any;
  @ViewChild('defaultStatusUpdateContent') defaultStatusUpdateContent: any;
  @ViewChild('statusUpdateList') statusUpdateList: any;
  @ViewChild('addNoteContent') addNoteContent: any;
  @ViewChild('defaultNoteContent') defaultNoteContent: any;
  @ViewChild('addNoteList') addNoteList: any;
  @ViewChild('productionDateContent') productionDateContent: any;
  @ViewChild('defaultDateContent') defaultDateContent: any;
  @ViewChild('productionDateList') productionDateList: any;
  @ViewChild("bulkUpdateInfiniteScroll") infiniteScroll: IonInfiniteScroll;

  // statusVSContentHeight: string = '';
  statusUpdateContentHeight: string = '';
  addNoteContentHeight: string = '';
  productionDateContentHeight: string = '';
  screenHeight: number;

  selectedOption: any;
  pgSelectedValues: string = '';
  lineItemsToUpdate: CollaborationLineItemDTO[] = [];
  lineItemsToUpdateForSetupTime: CollaborationLineItemDTO[] = [];
  lineItemsToUpdateForAddNote: CollaborationLineItemDTO[] = [];
  newStatus: Status;
  eventId: string;
  accountId: string; 
  functionalAreaId: string;
  statuses: Array<Status>;
  lineItems: any = [];  
  infiniteLIData: any = [];
  itemType: any = 0; // 0 -> Service Requests; 1 ->Line Items ; 2 -> FRT RR;
  productionSetupDate: productionDateTime[] = [];
  proSetupDateSelected: boolean = false;
  isValidStartDate: boolean = true;
  isValidEndDate: boolean = true;
  prodDefaultStartDate: Date;
  prodDefaultEndDate: Date;
  BulkUpdateConfirmationPopup: any;
  defaultNotes: string;
  notes: string;
  public showAccountId: string;
  public faID: string;
  isSelectAllDateChecked: boolean;
  isLISingleChecked = false;

  constructor(private modalCtrl: ModalController,
    private navParams: NavParams,
    public wtLineItemSvc: WorkticketLineItemService,
    public popoverCtrl: PopoverController,
    public dataSvc: DataRefreshService,
    public toastService: ToastService,
    private loadingController: LoadingController,
    public platform: Platform,
		private ga: GoogleAnalytics
  ) {
    this.lineItems = this.navParams.data.lineItems;
    this.eventId = this.navParams.data.eventId;
    this.statuses = StatusHelper.WorkTicketStatuses;
    this.faID = this.navParams.get('faID');
    this.showAccountId = this.navParams.get('showAccountId') || this.dataSvc.showAccountActive;
  }

  ngOnInit() {
    this.lineItems.forEach(lineItem => {
      lineItem.productionStartDate = !lineItem.productionStartDate ? lineItem.setupDate : lineItem.productionStartDate;
      lineItem.productionEndDate = !lineItem.productionEndDate ? lineItem.endDate : lineItem.productionEndDate;
      lineItem.notes = lineItem.notes;
    }); 
    this.lineItems = _.uniqBy(this.lineItems,'kafkaId');
    this.addLineItemsTolist();
    this.isSelectAllDateChecked = false;
    this.toggleAccordion('statusUpdate');
    
    this.platform.resize.subscribe(async () => {
      this.getScreenSize();
    });

    setTimeout(() => {
      this.selectedOption = 'statusUpdate';
    }, 300);
  }

  closeModalPopup() {
    this.modalCtrl.dismiss();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  toggleAccordion(selectedOption: any) {
    this.selectedOption = this.selectedOption == selectedOption ? '' : selectedOption; 
    this.getScreenSize();
  }

  isLiChecked(lineItem) {
    return this.lineItemsToUpdate.find(li => li.kafkaId === lineItem.kafkaId);
  }

  checkMark(lineItem) {
    const queuedItem = this.lineItemsToUpdate.findIndex(li => li.kafkaId === lineItem?.kafkaId);
    if (queuedItem !== -1) {
      this.lineItemsToUpdate.splice(queuedItem, 1);
    } else {
      this.lineItemsToUpdate.push(lineItem);
    }
  }

  async updateLineItems() {
    if (this.lineItemsToUpdateForSetupTime.length > 0) {
      if (this.isValidStartDate == false || this.isValidEndDate == false) {
        this.toastService.open('Please select valid date', 'danger');
        return;
      }
    }

    this.lineItemsToUpdateForSetupTime.forEach(element => {
      if (this.prodDefaultStartDate) {
        element.productionStartDate = this.prodDefaultStartDate;
      }
      if (this.prodDefaultEndDate) {
        element.productionEndDate = this.prodDefaultEndDate;
      }
    });
    this.lineItemsToUpdateForAddNote.forEach(element => {
      if(this.defaultNotes) {
        element.notes = this.defaultNotes;
      }
    });

    setTimeout(async () => {
      this.BulkUpdateConfirmationPopup = await this.popoverCtrl.create({
        component: BulkUpdateConfirmationModalComponent,
        cssClass: 'bulk-update-status-popup',
        backdropDismiss: false,
        componentProps: {}
      });
      await this.BulkUpdateConfirmationPopup.present();
      await this.BulkUpdateConfirmationPopup.onDidDismiss().then((confirmStatus) => {
        if (confirmStatus.data && confirmStatus.data.dismissed === true) {
          if (this.newStatus != null && this.lineItemsToUpdate.length > 0) {
            this.presentLoading();
            this.bulkUpdateLineItems();
          }
          if (this.lineItemsToUpdateForSetupTime.length > 0) {
            this.productionSetupDateTime();
          }
          if(this.lineItemsToUpdateForAddNote.length > 0) {
            this.addNotesInBulkUpdate();
          }
        }
      });
    }, 350);
  }
  async bulkUpdateLineItems() {
    if (this.newStatus != null && this.lineItemsToUpdate.length > 0) {
      this.wtLineItemSvc.putBulkUpdate(this.eventId, this.lineItemsToUpdate, this.newStatus);
      this.wtLineItemSvc.isBulkUpdateStatusSubject.subscribe(bulkUpdateStatus => {
        if (bulkUpdateStatus) {
          this.loadingController.dismiss();
          this.modalCtrl.dismiss({
            'dismissed': true
          });
          this.ga.trackEvent('BulkUpdateCompleted', this.newStatus?.title);
        }
      });
    }
    else {
      if (this.lineItemsToUpdate.length == 0) {
        this.toastService.open('Please select Line Items for update', 'danger');
      }
      else {
        this.toastService.open('Please select Status for update', 'danger');
      }
    }
  }

  productionSetupDateTime() {
    this.presentLoading();
    let productionSetupDateTime = null;
    let productionSetupList = [];
    this.lineItemsToUpdateForSetupTime.forEach(element => {
      productionSetupDateTime = {
        "LineItemGuid": element.kafkaId,
        "ProductionStartDate": element.productionStartDate,
        "ProductionEndDate": element.productionEndDate
      }
      productionSetupList.push(productionSetupDateTime);
    });
    this.wtLineItemSvc.productionScheduleDateTime(this.eventId, productionSetupList).subscribe((productionScheduleResponse) => {
      if (productionScheduleResponse) {
        this.loadingController.dismiss();
        this.modalCtrl.dismiss({
          'dismissed': true
        });
      } else {
        this.toastService.open('Please select production start and complete date & time', 'danger');
        return false;
      }

    })
  }
  selectAllChecked() {
    if (this.lineItemsToUpdate && this.lineItems) {
      return this.lineItemsToUpdate.length === this.lineItems?.length;
    }
    return false;
  }

  selectAll() {
    if (this.lineItemsToUpdate.length === this.lineItems?.length) {
      this.lineItemsToUpdate = [];
    }
    else {
      this.lineItemsToUpdate = [];
      this.lineItems?.forEach(li => {
        let lineItem: CollaborationLineItemDTO = li;
        this.lineItemsToUpdate.push(lineItem);
      });
    }
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      message: 'Bulk update is in progress...',
    });
    await loading.present();
  }
  /**
   * Production Schedule
   */
   selectAllProductionDate() {
    if (this.lineItemsToUpdateForSetupTime.length === this.lineItems?.length) {
      this.lineItemsToUpdateForSetupTime = [];
    }
    else {
      this.lineItemsToUpdateForSetupTime = [];
      this.lineItems?.forEach(li => {
        let lineItem: CollaborationLineItemDTO = li;
        this.lineItemsToUpdateForSetupTime.push(lineItem);
      });
    }
  }

  selectAllDateChecked() {
    if (this.lineItemsToUpdateForSetupTime && this.lineItems) {
      return this.lineItemsToUpdateForSetupTime.length === this.lineItems?.length;
    }
    return false;
  }

  isProdLiChecked(lineItem) {
    return this.lineItemsToUpdateForSetupTime.find(li => li.kafkaId === lineItem.kafkaId);
  }

  checkMarkProd(lineItem) {
    const queuedItem = this.lineItemsToUpdateForSetupTime.findIndex(li => li.kafkaId === lineItem?.kafkaId);
    if (queuedItem !== -1) {
      this.lineItemsToUpdateForSetupTime.splice(queuedItem, 1);
    } else {
      this.lineItemsToUpdateForSetupTime.push(lineItem);
    }
  }

  isValidDateInput(date) {
    if (date.freightDateTime == null || date.freightDateTime == undefined || date.freightDateTime.length <= 5) {
      return false
    }
    return true;
  }

  onDefaultStartDateChange(date) {
    if (!this.isValidDateInput(date)) {
      this.isValidStartDate = false;
      return;
    } else {
      this.isValidStartDate = true;
    }

    this.prodDefaultStartDate = date.freightDateTime
    if ((this.prodDefaultEndDate != null || this.prodDefaultEndDate != undefined) && (this.prodDefaultEndDate < date.freightDateTime)) {
      this.toastService.open('Default start date should be less than Default complete date', 'danger');
      this.isValidStartDate = false;
      return
    }
  }

  onDefaultEndDateChange(date) {
    if (!this.isValidDateInput(date)) {
      this.isValidEndDate = false;
      return;
    } else {
      this.isValidEndDate = true;
    }

    this.prodDefaultEndDate = date.freightDateTime
    if ((this.prodDefaultStartDate != null || this.prodDefaultStartDate != undefined) && (this.prodDefaultStartDate > date.freightDateTime)) {
      this.toastService.open('Default Complete date should be greater than Default start date', 'danger');
      this.isValidEndDate = false;
      return
    }
  }

  onStartDateChange(date, lineItem) {
    if (!this.isValidDateInput(date)) {
      this.isValidStartDate = false;
      return;
    } else {
      this.isValidStartDate = true;
    }

    if (date.freightDateTime != null || date.freightDateTime != undefined) {
      if (lineItem.setupDate && date.freightDateTime > lineItem.setupDate) {
        this.toastService.open('Production Start date must be less than setup start Date', 'danger')
        return false;
      }
    }

    if (date.freightDateTime && lineItem.productionEndDate && date.freightDateTime > new Date(lineItem.productionEndDate)) {
      this.toastService.open('Production Start date must be less than Production Complete date', 'danger')
      return false;
    }

    lineItem.productionStartDate = date.freightDateTime;
  }

  onEndDateChange(date, lineItem) {
    if (!this.isValidDateInput(date)) {
      this.isValidEndDate = false;
      return;
    } else {
      this.isValidEndDate = true;
    }

    if (date.freightDateTime != null || date.freightDateTime != undefined) {
      if (lineItem.endDate && date.freightDateTime > lineItem.endDate) {
        this.toastService.open('Production Complete date must be less than Setup End Date', 'danger')
        return false;
      }
    }

    if (date.freightDateTime && lineItem.productionStartDate && date.freightDateTime < new Date(lineItem.productionStartDate)) {
      this.toastService.open('Production Complete date must be greater than Production Start date', 'danger')
      return false;
    }

    lineItem.productionEndDate = date.freightDateTime;
  }

  loadNextBatchLineItems(event) {
    setTimeout(() => {
      event.target.complete();
      this.addLineItemsTolist();
      
      if (this.infiniteLIData.length === this.lineItems.length) {
        event.target.disabled = true;
      }
    }, 100);
  }

  addLineItemsTolist(){
    for (let liIndex = 0; liIndex < 50 && this.infiniteLIData.length != this.lineItems.length ; liIndex++) {
      this.infiniteLIData.push(this.lineItems[liIndex]);        
    }

    if (this.infiniteLIData.length === this.lineItems.length && this.infiniteScroll) {
      this.infiniteScroll.disabled = true;
      this.infiniteScroll.complete();
    }
  }

  updateContentHeight(){
    setTimeout(() => {
      if(this.selectedOption == 'statusUpdate'){
        // if(this.statusVS.el.clientHeight < this.statusVSContent.el.clientHeight){
        //   this.statusVSContentHeight = this.statusVS.el.clientHeight;
        // }
        if((this.defaultStatusUpdateContent.nativeElement.clientHeight + this.statusUpdateList.nativeElement.clientHeight) < this.statusUpdateContent.el.clientHeight){
          this.statusUpdateContentHeight = (this.defaultStatusUpdateContent.nativeElement.clientHeight + this.statusUpdateList.nativeElement.clientHeight);
        }
      }
      if(this.selectedOption == 'addNote'){
        if((this.defaultNoteContent.nativeElement.clientHeight + this.addNoteList.nativeElement.clientHeight) < this.addNoteContent.el.clientHeight){
          this.addNoteContentHeight = (this.defaultNoteContent.nativeElement.clientHeight + this.addNoteList.nativeElement.clientHeight);
        }
      }
      if(this.selectedOption == 'setupTimeDate'){
        if((this.defaultDateContent.nativeElement.clientHeight + this.productionDateList.nativeElement.clientHeight) < this.productionDateContent.el.clientHeight){
          this.productionDateContentHeight = (this.defaultDateContent.nativeElement.clientHeight + this.productionDateList.nativeElement.clientHeight);
        }
      }
    }, 500);
  }
  
  getScreenSize(event?: any) {
    // this.statusVSContentHeight = ((window.innerHeight / 2) - 20).toString();
    this.statusUpdateContentHeight = ((window.innerHeight / 2) -20).toString();
    this.addNoteContentHeight = (window.innerHeight / 2).toString();
    this.productionDateContentHeight = (window.innerHeight / 2).toString();
    console.log(this.screenHeight);
    this.updateContentHeight();
  }

  //add note section
  selectAllAddNote() {
    if (this.lineItemsToUpdateForAddNote.length === this.lineItems?.length) {
      this.defaultNotes = '' ;
      if(this.defaultNotes == ''){
        this.lineItemsToUpdateForAddNote.forEach(element => { 
          element.notes = '';
        })
      this.lineItemsToUpdateForAddNote = [];
      }
    }
    else {
      this.lineItemsToUpdateForAddNote = [];
      this.lineItems?.forEach(li => {
        let lineItem: CollaborationLineItemDTO = li;
        this.lineItemsToUpdateForAddNote.push(lineItem);
      });
      if(this.defaultNotes){
        this.lineItemsToUpdateForAddNote.forEach(element => { 
          element.notes = this.defaultNotes;
        })
      }
    }
  }
  selectAllNotesChecked() {
    if (this.lineItemsToUpdateForAddNote && this.lineItems) {
      return this.lineItemsToUpdateForAddNote.length === this.lineItems?.length;
    }
    return false;
  }
  isAddNoteChecked(lineItem) {
    return this.lineItemsToUpdateForAddNote.find(li => li.kafkaId === lineItem.kafkaId);
  }
  checkMarkAddNote(lineItem) {
    const queuedItem = this.lineItemsToUpdateForAddNote.findIndex(li => li.kafkaId === lineItem?.kafkaId);
    if (queuedItem !== -1) {
      this.lineItemsToUpdateForAddNote.splice(queuedItem, 1);
    } else {
      this.lineItemsToUpdateForAddNote.push(lineItem);
    }
  }

  addNotesInBulkUpdate() {
    let addNote = null;
    let addNoteList = [];
    this.lineItemsToUpdateForAddNote.filter(element => !_.isNil(element?.notes) && !_.isEmpty(element.notes)).forEach(element => {
      addNote= {
        "LineItemGuid": element.kafkaId,
        "NoteBody": element.notes
      }
      addNoteList.push(addNote);
    });
    if (addNoteList.length == 0) {
      this.toastService.open('Please add note to update lineitems', 'danger');
    } else {
      this.presentLoading();
      this.wtLineItemSvc.addNoteBulkUpdate(this.eventId, this.showAccountId, this.faID,addNoteList).subscribe((addNoteResponse) => {
        if (addNoteResponse) {
          this.loadingController.dismiss();
          this.modalCtrl.dismiss({
            'dismissed': true
          });
        } else {
          this.toastService.open('Please add note', 'danger');
          return false;
        }
  
      })
    }
  }

  changeModel(event) {​
    this.lineItemsToUpdateForAddNote.forEach(element => {
      if(event) {
        element.notes = event;
      }else {
        element.notes = '';
      }
    });
  }
}