import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Storage } from '@ionic/storage';
import { CreateMhaService } from 'src/app/services/create-mha/create-mha.service';

@Component({
  selector: 'mha-confirmation-modal',
  templateUrl: './mha-confirmation-modal.component.html',
  styleUrls: ['./mha-confirmation-modal.component.scss'],
})
export class MhaConfirmationModalComponent implements OnInit {

  @Input() type: any = 'confirm-prompt';
  timeout: any = 4000;
  currentBoothInfo: any;
  @Output() onClosePopup = new EventEmitter<any>();
  @Output() onCloseSaveDraftPopup = new EventEmitter<any>();

  constructor(
    public createMhaService: CreateMhaService,
    public storage: Storage
  ) { }

  async ngOnInit() {
    this.currentBoothInfo = await this.storage.get('currentBooth');  
  }

  showCancelConfirmationPrompt() {
    this.type = 'cancel-prompt';
  }

  showSuccessPopup() {
    this.type = 'success';
    setTimeout(() => {
      this.onClosePopup.emit({ notify: true });
    }, this.timeout);
  }

  cancel() {
    this.onClosePopup.emit({ notify: false });
  }

  closePopup(showSuccessPopup = false) {
    if (showSuccessPopup || this.type == 'success') {
      showSuccessPopup ? this.showSuccessPopup() : this.onClosePopup.emit({ notify: true });
    } else {
      this.onClosePopup.emit();
    }
  }

  closeSaveDraftPopup(value = null) {
    this.onCloseSaveDraftPopup.emit(value);
  }

}
