import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';
import { ChangeOrderDTO } from 'src/app/models/change-order-dto';
import { LineItemService } from 'src/app/services/line-item/line-item.service';

@Component({
  selector: 'occ-change-order-confirmation-modal',
  templateUrl: './change-order-confirmation-modal.component.html',
  styleUrls: ['./change-order-confirmation-modal.component.scss'],
})
export class ChangeOrderConfirmationModalComponent {

	changeOrderDto: ChangeOrderDTO;
	associatedLineItems: any[];
	isAssociatedLIPopup: boolean = false;
	isNewOrder: boolean = false;

	constructor(
		public params: NavParams,
		public lineItemSvc: LineItemService,
    public popoverCtrl: PopoverController
	) {
		this.changeOrderDto = this.params.get('changeOrder');
		this.associatedLineItems = this.params.get('associatedLineItems');
		this.isAssociatedLIPopup = this.params.get('isAssociatedLIPopup');
		this.isNewOrder = this.params.get('isNewOrder');
	}
	dismiss() {
		if(this.isNewOrder){
			this.popoverCtrl.dismiss({
				'dismissed': false
			});
		}else{
			this.changeOrderDto.confirmSave = false;
			this.popoverCtrl.dismiss();
		}
	}
	saveChangeOrder() {
		if(this.isNewOrder){
			this.popoverCtrl.dismiss({
				'dismissed': true
			});
		}else{
			this.changeOrderDto.confirmSave = true;
			this.popoverCtrl.dismiss();
		}
	}
}

