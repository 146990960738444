import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { AuthTokenHelper } from './auth-token-helper';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  isActive: boolean;
  constructor(public authTokenHelper: AuthTokenHelper, public router: Router,
    public storage: Storage, public authSvc: AuthenticationService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
     return this.authTokenHelper.isTokenValid().then((data) => {
        this.isActive = data;
        if(!this.isActive)
        {
          this.router.navigateByUrl("/login"); 
        }
        else {
          this.authTokenHelper.IsKioskUser().then(isKiosk => {
            if (isKiosk && state.url == '/events'){
              this.router.navigate(['/kiosk-landing']);
            }
          })
          if(!state.url.includes('kiosk'))
          {
            this.storage.get('isSelfCheckIn').then((data) => {
              if(data == true)
              {
              this.authSvc.logout();
              this.router.navigateByUrl("/login");
              }
            })
          }
        }
      return this.isActive;      
      });
     
  }
  
}
