import { Pipe, PipeTransform } from '@angular/core';
import { CollaborationLineItemDTO } from '../models/collaboration-line-item-dto';

@Pipe({
	name: 'lineItemSearch',
})
export class LineItemSearchPipe implements PipeTransform {

	transform(lineItems: Array<CollaborationLineItemDTO>, searchText: string): Array<CollaborationLineItemDTO> {
		if (!lineItems) return [];
		if (!searchText) return lineItems;

		searchText = searchText.toLocaleLowerCase().trim();

		const results: Array<CollaborationLineItemDTO> = [];
		const leftovers: Array<CollaborationLineItemDTO> = [];

		// Name - Starts With
		lineItems.forEach(area => {
			if (area.description && area.description.toLowerCase().startsWith(searchText)) {
				results.push(area);
			} else {
				leftovers.push(area);
			}
		});

		// ID - Starts With
		for (let i = 0; i < leftovers.length; i++) {
			const area = leftovers[i];
			if (area.passportId && area.passportId.toString().startsWith(searchText)) {
				results.push(area);
				leftovers.splice(i, 1);
				i--;
			}
		}

		// Vendor Name - Starts With
		for (let i = 0; i < leftovers.length; i++) {
			const area = leftovers[i];
			if (area.vendorName && area.vendorName.toLowerCase().startsWith(searchText)) {
				results.push(area);
				leftovers.splice(i, 1);
				i--;
			}
		}

		// Location - Starts With
		for (let i = 0; i < leftovers.length; i++) {
			const area = leftovers[i];
			if (area.locationInfo && area.locationInfo.toLowerCase().startsWith(searchText)) {
				results.push(area);
				leftovers.splice(i, 1);
				i--;
			}
		}

		// Status - Starts With
		for (let i = 0; i < leftovers.length; i++) {
			const area = leftovers[i];
			if (area.status.title && area.status.title.toLowerCase().startsWith(searchText)) {
				results.push(area);
				leftovers.splice(i, 1);
				i--;
			}
		}

		// Ticket Number - Starts With
		for (let i = 0; i < leftovers.length; i++) {
			const area = leftovers[i];
			if (area.ticketNumber && area.ticketNumber.toString().startsWith(searchText)) {
				results.push(area);
				leftovers.splice(i, 1);
				i--;
			}
		}

		return results;
	}
}
