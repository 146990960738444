import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'esBoothListFilter'
})
export class EsBoothListFilterPipe implements PipeTransform {

  transform(dataSet: any, ...filterCriteriaDataArray: any): any {
    let filteredBoothDataList = [];
    
    if(filterCriteriaDataArray.length){
      let filterCriteriaData = filterCriteriaDataArray[0];
      let statusIds = [];
      filterCriteriaData.statuses.forEach(statusData => {
        statusIds.push(statusData.id);
      });

      let orderFilterCombinations = [];
      if (filterCriteriaData.orders.length) {        
        filterCriteriaData.orders.forEach(partGroupItem => {
          if(partGroupItem.isChecked){
            let subGroupNames = [];
            partGroupItem.subgroups.forEach((subGroupItem) => {
              if(subGroupItem.isChecked){
                orderFilterCombinations.push(partGroupItem.name + "-" + subGroupItem.name);
                subGroupNames.push(subGroupItem.name);
              }
            });
            if(!subGroupNames.length){
              orderFilterCombinations.push(partGroupItem.name);
            }          
          }
        });
      }

      dataSet.forEach(boothDataItem => {
        let isFilterCriteriaPassed = true;
        if (boothDataItem.isLineItem) {
          if (statusIds.length) {
            if((!boothDataItem.currentStatus || !statusIds.includes(boothDataItem.currentStatus))){
              isFilterCriteriaPassed = false;
            }
          }

          if(orderFilterCombinations.length){
            if(!(orderFilterCombinations.includes(boothDataItem.partGroup) || orderFilterCombinations.includes(boothDataItem.partGroup + "-" + boothDataItem.subGroup))){
              isFilterCriteriaPassed = false;
            }
          }
        }
        isFilterCriteriaPassed ? filteredBoothDataList.push(boothDataItem) : "";
      });
      return filteredBoothDataList;
    } else {
      return dataSet;
    }
  }

}
