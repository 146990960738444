import *  as moment from 'moment';
import { Storage } from '@ionic/storage-angular';
import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';

@Injectable()
export class AuthTokenHelper {

	public currentUserGuid = new BehaviorSubject(null);
	public currentUserName = new BehaviorSubject(null);
	public isOpsUser: boolean;
	public isCustomerUser: boolean = false;
	public isExhibitUser: boolean = false;
	public isUserLoggedIn = new BehaviorSubject<boolean>(false);
	constructor(
		private storage: Storage,
		private ga: GoogleAnalytics,
		) {
		//this.SetCurrentUser();
		//this.checkUserType();
	}

	async checkUserType() {
		this.isOpsUser = await this.IsOpsUser();
		this.isExhibitUser = await this.IsExhibitUser();
	}

	public async IsSalesUser(): Promise<boolean> {
		try {
			const token = await this.storage.get('occ_token');
			const decodedToken = jwtDecode(token);
			const roles: string[] = decodedToken['role'];
			return Promise.resolve(roles.indexOf('Sales') !== -1);
		} catch (error) {
			return Promise.reject('Unable to determine if user was a sales user.');
		}
	}

	public async IsKioskUser(): Promise<boolean> {
		try {
			const token = await this.storage.get('occ_token');
			const decodedToken = jwtDecode(token);

			return Promise.resolve(decodedToken['IsKioskUser'] === 'true');
		} catch (error) {
			return Promise.reject('Unable to determine if user was a kiosk user.');
		}
	}

	public async IsOpsUser(): Promise<boolean> {
		try {
			const token = await this.storage.get('occ_token');
			const decodedToken = jwtDecode(token);
			return Promise.resolve(decodedToken['IsOpsUser'] === 'true');
		} catch (error) {
			return Promise.reject('Unable to determine if user was a ops user.');
		}
	}

	public async IsExternalUser(): Promise<boolean> {
		try {
			const token = await this.storage.get('occ_token');
			const decodedToken = jwtDecode(token);
			const roles: string[] = decodedToken['role'];
			return Promise.resolve(roles.indexOf('External') !== -1);
		} catch (error) {
			return Promise.reject('Unable to determine if user was a external user.');
		}
	}

	public async IsExhibitUser(): Promise<boolean> {
		try {
			const token = await this.storage.get('occ_token');
			const decodedToken = jwtDecode(token);

			return Promise.resolve(decodedToken['IsExhbitUser'] === 'true');
		} catch (error) {
			return Promise.reject('Unable to determine if user was a ops user.');
		}
	}

	public async SetCurrentUser() {
		const token = await this.storage.get('occ_token');
		const decodedToken = jwtDecode(token);

		const userGuid: string = decodedToken['nameid'];
		const userName: string = decodedToken['unique_name'];
		this.currentUserGuid.next(userGuid);
		this.currentUserName.next(userName);

		const roles = decodedToken['role'];

		//Added isCustomerUser flag as async function call from html causes application freeze issue and also memory usage is too high.
		this.isCustomerUser = roles.toString().includes("External") ? true : false;
		this.setGoogleAnalyticsUser(decodedToken);
	}

	setGoogleAnalyticsUser(decodedToken: any) {
		this.ga.setUserId(decodedToken['nameid']);
		const userRole = decodedToken['IsOpsUser'] === 'true' ? 'operations' : decodedToken['role'].indexOf('Sales') !== -1 ? 'internal' : 'external';
		this.ga.addCustomDimension(1, userRole);
	}

	
	public async GetToken(): Promise<string> {
		try {
			const token = await this.storage.get('occ_token');
			const tokenExpiration = await this.storage.get('token_expiration');
			const diff = moment.utc().diff(tokenExpiration);

			if (diff <= 0) {
				return Promise.resolve(token);
			}
			return Promise.reject('Token expired');

		} catch (error) {
			Promise.reject('No token available.');
		}
	}

	public async GetAccessToken(): Promise<string> {
		const token = await this.storage.get('token');
		const tokenExpiration = await this.storage.get('token_expiration');

		if (!token || !tokenExpiration) {
			Promise.reject('No token available.');
		}

		const diff = moment.utc().diff(tokenExpiration);

		if (diff <= 0) {
			return Promise.resolve(token);
		}
		return Promise.reject('Token expired');
	}

	public async GetIDToken(): Promise<string> {
		try {
			const token = await this.storage.get('token');
			return Promise.resolve(token);
		} catch (error) {
			Promise.reject('No token available.');
		}
	}

	public async GetRefreshToken(): Promise<string> {

		const refreshToken = await this.storage.get('refresh_token');
		const refreshTokenExpiration = await this.storage.get('refresh_token_expiration');

		if (!refreshToken || !refreshTokenExpiration) {
			return Promise.reject('No refresh token available.');
		}

		const diff = moment.utc().diff(refreshTokenExpiration);

		if (diff <= 0) {
			return Promise.resolve(refreshToken);
		}

		return Promise.reject('Refresh token expired');
	}

	public async isTokenValid(): Promise<boolean> {
		try {
			const token = await this.GetToken();
			const expiration = await this.storage.get('token_expiration');
			const diff = moment.utc().diff(expiration);
			if (expiration && token && diff <= 0) {
				return Promise.resolve(true);
			}
			return Promise.resolve(false);
		} catch (error) {
			return Promise.resolve(false);
		}
	}

	public async isRefreshTokenValid(): Promise<boolean> {
		try {
			const refreshToken = await this.GetRefreshToken();
			const expiration = await this.storage.get('refresh_token_expiration');
			const diff = moment.utc().diff(expiration);
			if (expiration && refreshToken && diff <= 0) {
				return Promise.resolve(true);
			}
			return Promise.resolve(false);
		} catch (error) {
			return Promise.resolve(false);
		}
	}

	public async isEitherTokenValid(): Promise<boolean> {
		try {
			const tokenValid = await this.isTokenValid();
			const refreshTokenValid = await this.isRefreshTokenValid();
			return Promise.resolve(tokenValid || refreshTokenValid);
		} catch (error) {
			return Promise.resolve(false);
		}
	}

	public async isAzureTokenValid(): Promise<boolean> {
		try {
			const azToken = await this.storage.get('azure_token');
			const azureUri = await this.storage.get('azure_uri');
			return Promise.resolve(azToken && azureUri);
		} catch (error) {
			return Promise.resolve(false);
		}
	}

	public async checkUserLoggedIn(): Promise<void> {
		const token = await this.storage.get('occ_token');
		if(token != null)
			this.isUserLoggedIn.next(true);
		else
			this.isUserLoggedIn.next(false);
	}
}
