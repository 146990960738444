import { Component, Input, OnInit } from '@angular/core';
import { ShowBooth } from 'src/app/models/show-booth-dto';
import { EsBoothsService } from 'src/app/services/es-booths/es-booths.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';
import { ActionSheetController } from '@ionic/angular';
import { CameraHelper } from 'src/app/helpers/camera-helper';
import { StyleHelper } from 'src/app/helpers/style-helper';
import { MentionConfig } from 'angular-mentions';
import { takeWhile } from 'rxjs/operators';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'occ-booth-details',
  templateUrl: './booth-details.component.html',
  styleUrls: ['./booth-details.component.scss'],
})
export class BoothDetailsComponent implements OnInit {
  @Input() currentTab: number;
  eventId: string;
  public currentBooth: ShowBooth = <ShowBooth>{};
  mentionConfig: MentionConfig;
  subscribe: boolean = true;
  posting: boolean = false;
  postNoteString: string = '';

  constructor(
    public activatedRoute: ActivatedRoute,
    public appBrowserHelper: AppBrowserHelper,
    public esBoothService: EsBoothsService,
    public actionSheetCtrl: ActionSheetController,
    public cameraHelper: CameraHelper,
    public styleHelper: StyleHelper,
    public userSvc: UserService
  ) { }

  ngOnInit() {
    this.eventId = this.activatedRoute.snapshot.paramMap.get('eventId');
    this.esBoothService.activeBoothSubject.pipe(takeWhile(() => this.subscribe)).subscribe(boothDetails => {
			this.currentBooth = boothDetails
		});
    this.userSvc.mentionsConfig.pipe(takeWhile(() => this.subscribe)).subscribe(mentionConfig => {
			this.mentionConfig = mentionConfig;
		});
  }

  getTargetDate(isDate: boolean){ 
    if(this.currentBooth.targetDate){
      let targetDate = new Date(this.currentBooth.targetDate);
      
      if(isDate){
        let splittedDate = this.currentBooth.targetDate.slice(0, 10).split("-");
        return "Date: " + splittedDate[1] + "/" +splittedDate[2] + "/" + splittedDate[0];
      } else{
        let hours: any  = parseInt(this.currentBooth.targetDate.slice(11, 13));
        let minutes: any = parseInt(this.currentBooth.targetDate.slice(15, 16));
        hours = hours > 12 ? hours - 12 : hours;
        let am_pm = targetDate.getHours() >= 12 ? "PM" : "AM";
        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        return "Time: " + hours + ":" + minutes + " " + am_pm;
      }   
    }
    return '';
  }

  async presentCameraOptionsModal() {
		const actionSheet = await this.actionSheetCtrl.create({
			header: 'Add a Photo',
			buttons: [
				{
					text: 'Take a Photo',
					handler: () => {
						this.cameraHelper.takePicture(this.eventId, this.currentBooth.showAccountGUID, null, null, false, false, false, this.currentBooth.boothGUID);
					}
				},
				{
					text: 'Upload From Gallery',
					handler: () => {
						this.cameraHelper.takePicture(this.eventId, this.currentBooth.showAccountGUID, null, null, false, true, false, this.currentBooth.boothGUID);
					}
				},
				{
					text: 'Cancel',
					role: 'cancel',
				}
			]
		});
		await actionSheet.present();
	}

  postNote(body) {
		this.setPostingStatus(true);
		this.esBoothService.addNote(body, this.eventId, this.currentBooth, null, this.clearInput.bind(this));
	}

  setPostingStatus(enabled) {
		this.posting = enabled;
	}

  clearInput(clearText) {
		this.styleHelper.textAreaAdjust();
		if (clearText) {
			this.postNoteString = '';
		}
		this.setPostingStatus(false);
	}
}
