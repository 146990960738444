import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { AuthTokenHelper } from 'src/app/helpers/auth-token-helper';
import { CreateMhaService } from 'src/app/services/create-mha/create-mha.service';
import { KioskService } from 'src/app/services/kiosk/kiosk.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'kiosk-mop-not-found',
  templateUrl: './mop-not-found.component.html',
  styleUrls: ['./mop-not-found.component.scss'],
})
export class MOPNotFoundComponent implements OnInit {
  
  eventGUID: string;  
  subTitleMessage: string;  
  isKioskUser: boolean = false;

  constructor(
    public modalCtrl: ModalController,
    public createMHAService: CreateMhaService,
    public kioskService: KioskService,
    public toastService: ToastService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public platform: Platform,
    public tokenHelper: AuthTokenHelper
  ) { }

  async ngOnInit() {
    this.kioskService.currentShowName.subscribe(showData => {
      if (showData != null)
        this.eventGUID = showData.kafkaId;
    });
    
    this.isKioskUser = await this.tokenHelper.IsKioskUser();
    if(this.isKioskUser){
      this.subTitleMessage = "Please visit the service desk to update your account";
    }else{
      this.subTitleMessage = "Please update the account";
    }
  }

  saveAsDraft(){
    if(this.isKioskUser){
      this.createMHAService.updateMHA(this.eventGUID, this.createMHAService.mhaGUID).subscribe(data => {
        this.toastService.open('Outbound Shipping paperwork draft saved successfully!', 'success');
        this.modalCtrl.dismiss({isDraft: true});
        // this.router.navigate([this.createMHAService.currentBoothId + '/'+ this.createMHAService.boothNumber + '/kiosk-outbound-shipping-labels']); 
      }, err => {
        if (err.status !== 401 && err.status !== 403) {
          this.toastService.open('There was an error while saving Outbound Shipping paperwork draft', 'danger');
        }
        this.modalCtrl.dismiss();
      });
    }else{
      this.modalCtrl.dismiss({isDraft: true});
    }  
  }

  closeModal(){
    this.modalCtrl.dismiss();
  }
}
