import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';
import { takeWhile } from 'rxjs/operators';
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';
import { FilterHelper } from 'src/app/helpers/filter-helper';
import { SelectHelper } from 'src/app/helpers/select-helper';
import { StatusHelper } from 'src/app/helpers/status-helper';
import { CollaborationLineItemDTO } from 'src/app/models/collaboration-line-item-dto';
import { Status } from 'src/app/models/status';
import { FilteredLineItemTimeline } from 'src/app/models/work-ticket-timeline-filter-dto';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';
import { FunctionalAreaService } from 'src/app/services/functional-area/functional-area.service';
import { LineItemFilterServiceProvider } from 'src/app/services/line-item-filter/line-item-filter.service';
import { LineItemService } from 'src/app/services/line-item/line-item.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { WorkTicketService } from 'src/app/services/work-ticket/work-ticket.service';
import { ItemActionMenuComponent } from '../item-action-menu/item-action-menu.component';

@Component({
  selector: 'occ-line-item-list',
  templateUrl: './line-item-list.component.html',
  styleUrls: ['./line-item-list.component.scss'],
})
export class LineItemListComponent implements OnInit, OnDestroy {
	@ViewChild('searchbarInput') searchbarInput: ElementRef;
	@Input('isWorkTicketPage') isWorkTicketPage: Boolean;

	eventId: string;
	accountId: string;
	functionalAreaId: string;
	lineItemDto: CollaborationLineItemDTO[];
	lineItemCopyDto: CollaborationLineItemDTO[];
	menuOpen = false;
	statusMenuPopover: any;
	itemActionModal: any;
	selectedItem: CollaborationLineItemDTO;
	refreshEnabled = true;
	showMobileSearchbar = false;
	searchText = '';
	showFilter = false;
	needsAttentionModal: any;
	isLoading: boolean;
	wtId: string;
	wtNum: number;
	filters: FilteredLineItemTimeline[];
	Status = StatusHelper.Status;

	private subscribe = true;

	constructor(
		public lineItemSvc: LineItemService,
		public popoverCtrl: PopoverController,
		public funcSvc: FunctionalAreaService,
		public toastService: ToastService,
		public appBrowserHelper: AppBrowserHelper,
		public modalCtrl: ModalController,
		public dataSvc: DataRefreshService,
		public workTikSvc: WorkTicketService,
		public filterSvc: LineItemFilterServiceProvider,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private filterHelper: FilterHelper
	) {
		this.eventId = activatedRoute.snapshot.paramMap.get('eventId') || this.dataSvc.eventActive;
		this.accountId = activatedRoute.snapshot.paramMap.get('accountId') || this.dataSvc.showAccountActive;
		this.functionalAreaId = activatedRoute.snapshot.paramMap.get('functionalAreaId') || this.funcSvc.activeFunctionalAreaId.getValue();
	}

	ngOnInit() {
		this.eventId = this.activatedRoute.snapshot.paramMap.get('eventId');
		this.accountId = this.activatedRoute.snapshot.paramMap.get('accountId');
		this.dataSvc.eventActive = this.eventId;
		this.dataSvc.showAccountActive = this.accountId;
		this.lineItemSvc.lineItemsLoading.subscribe(load => this.isLoading = load);
		this.lineItemSvc.collaborationLineItemDto.pipe(takeWhile(() => this.subscribe)).subscribe(cliDto => {
			//this.lineItemDto = cliDto;
			this.lineItemCopyDto = cliDto;
			this.filterLineItems();
		});
		this.lineItemSvc.refresh(this.eventId, this.accountId, this.functionalAreaId).subscribe();
		this.filterSvc.chosenFilters
			.pipe(takeWhile(() => this.subscribe))
			.subscribe(() => {	
				this.filterLineItems();
			});
	}

	ionViewWillLeave() {
		if (this.statusMenuPopover) {
			this.statusMenuPopover.dismiss();
		}
		if (this.itemActionModal) {
			this.itemActionModal.dismiss();
		}
		// if (this.needsAttentionModal) {
		// 	this.itemActionModal.dismiss();
		// }
	}

	filterLineItems() {
		this.filterSvc.chosenFilters.subscribe(f => this.filters = f);			
		if(this.filters.length > 0) {
			this.lineItemDto = this.lineItemCopyDto.filter(({status}) => this.filters.some(({statusId: t}) => t === status.id));
		}
		else {
			this.lineItemDto = this.lineItemCopyDto;
		}
	}

	changedList(event) {
		// checks to see if the index of the start item is 0
		return this.refreshEnabled = event.start === 0;
	}


	refresh(refresher): void {
		this.lineItemSvc.refresh(this.eventId, this.accountId, this.functionalAreaId).subscribe();
		refresher.target.complete();
	}

	async presentPopover(event, item: CollaborationLineItemDTO) {
		this.statusMenuPopover = await this.popoverCtrl.create({
			component: ItemActionMenuComponent,
			event:event,
			componentProps: { item: item, eventId: this.eventId }
    });
		await this.statusMenuPopover.present();
		await this.statusMenuPopover.onWillDismiss(data => {
			this.clearSelectedItem();
		});

		event.stopPropagation();
	}

	changeStatus(lineItemId: any, status: Status) {
		this.lineItemSvc.put(this.eventId, this.accountId, this.functionalAreaId, lineItemId, status);
	}

	delayAndCloseIonItemSliding(itemSliding) {
		setTimeout(() => {
			itemSliding.close();
		}, 1);
	}

	updateSearchText(searchText: string): void {
		this.searchText = searchText;
	}

	toggleFilter(): boolean {
		if (this.showFilter === true) {
			return this.showFilter = false;
		}

		this.showFilter = true;
	}

	async presentLineItemStatusMenuModal(item: CollaborationLineItemDTO, itemSliding: any) {
		this.itemActionModal = await this.modalCtrl.create({
      component: ItemActionMenuComponent,
      componentProps: { item: item }
    });
		await this.itemActionModal.present();
		await this.itemActionModal.onDidDismiss(data => {
			itemSliding.close();
		});
	}

	pushDetailsPage(lineItem: CollaborationLineItemDTO): void {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		// check the current line item value before doing anything
		const currentLineItem = this.lineItemSvc.currentLineItem.getValue();

		// if it doesn't exist or if it's different, update it.
		if (!currentLineItem || currentLineItem !== lineItem) {
			this.lineItemSvc.currentLineItem.next(lineItem);
		}

		this.router.navigateByUrl('events/'+ this.eventId + '/accounts/' + this.accountId + '/functional-areas/' + this.functionalAreaId + '/line-items/'+ lineItem.kafkaId +'/line-item-details');
	}

	clearSelectedItem() {
		this.selectedItem = null;
	}

	ngOnDestroy() {
		this.subscribe = false;
		if (this.statusMenuPopover) {
			this.statusMenuPopover.dismiss();
		}

		if (this.itemActionModal) {
			this.itemActionModal.dismiss();
		}

	}

}

