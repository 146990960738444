import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import * as _ from 'lodash';
import { CollaborationLineItemDTO } from 'src/app/models/collaboration-line-item-dto';
import { environment } from '../../../environments/environment';
import { ToastService } from '../toast/toast.service';

@Injectable({
    providedIn: 'root'
})
export class ShowFiltersService {
    url = environment.apiUrl;
    savedFilters: any;
    savedGroupData: any;
    applyToAllEvents: any;
    presetFilters: any;
    allFiltersData: any;
    currentShowId: any;
    resetFilters: any;

    constructor(
        public http: HttpClient,
        private storage: Storage,
        public toastService: ToastService,) { }

    getFilterData(allLineItemsData, isSetShowFilters = false) {
        let reset = isSetShowFilters ? false : !this.presetFilters;
        let savedFiltersAvailable = true;
        if (!this.savedFilters) {
            reset = true;
            savedFiltersAvailable = false;
        }
        let locationData = [];
        let boothsData = [];
        let allFilterData = {
            partgroups: [],
            appliedGroups: [],
            subgroups: [],
            appliedSubgroups: [],
            parts: [],
            appliedParts: [],
            functional_areas: [],
            facilities: [],
            locations: [],
            booths: [],
            setupStartDate: {
                filterType: "5",
                name: ""
            },
            setupCompleteDate: {
                filterType: "6",
                name: "",
            },
            exhibitorNames: [],
            boothLowerRange: {
                name: "",
                filterType: "9"
            },
            boothUpperRange: {
                name: "",
                filterType: "10"
            },
            isExhibitor: false,
            appliedPartFilter: null
        };

        _.forEach(allLineItemsData, (lineItem) => {
            if (lineItem.partGroup) {
                allFilterData.partgroups.push({
                    filterType: "0",
                    name: lineItem.partGroup,
                    isChecked: false,
                    isSearchMatched: true,
                    partNumber: lineItem.partNumber
                });
            }
            if (lineItem.functionalAreaName) {
                allFilterData.functional_areas.push({
                    filterType: "1",
                    name: lineItem.functionalAreaName,
                    isChecked: reset ? false : this.savedFilters.functional_areas.includes(lineItem.functionalAreaName) ? true : false,
                    isSearchMatched: true
                });
            }
            if (lineItem.facility) {
                allFilterData.facilities.push({
                    filterType: "2",
                    name: lineItem.facility,
                    isChecked: reset ? false : (this.savedFilters.facilities.includes(lineItem.facility) ? true : false),
                    isSearchMatched: true,
                    locations: [],
                });
            }
            if (lineItem.locationInfo) {
                locationData.push({
                    filterType: "3",
                    name: lineItem.locationInfo,
                    facility: lineItem.facility,
                    isChecked: reset ? false : (this.savedFilters.locations.includes(lineItem.locationInfo) ? true : false),
                    isSearchMatched: true,
                    booths: []
                });
            }
            if (lineItem.booth) {
                boothsData.push({
                    filterType: "4",
                    name: lineItem.booth,
                    facility: lineItem.facility,
                    locationInfo: lineItem.locationInfo,
                    isChecked: reset ? false : (this.savedFilters.booths.includes(lineItem.booth) ? true : false),
                    isSearchMatched: true
                });
            }
            if (lineItem.accountName) {
                allFilterData.exhibitorNames.push({
                    filterType: "8",
                    name: lineItem.accountName,
                    isChecked: reset ? false : (this.savedFilters.exhibitorNames.includes(lineItem.accountName) ? true : false),
                    isSearchMatched: true
                });
            }
            if (lineItem.partGroup) {
                allFilterData.subgroups.push({
                    filterType: "11",
                    name: lineItem.partSubGroup,
                    isChecked: false,
                    isSearchMatched: true,
                    partNumber: lineItem.partNumber
                });
            }
            if (lineItem.detailDescription) {
                allFilterData.parts.push({
                    filterType: "12",
                    name: lineItem.detailDescription,
                    isChecked: false,
                    isSearchMatched: true,
                    partNumber: lineItem.partNumber
                });
            }
        });

        allFilterData.partgroups = _.sortBy(_.uniqBy(allFilterData.partgroups, 'name'), [function (item) { return item.name; }]);
        allFilterData.subgroups = _.sortBy(_.uniqBy(allFilterData.subgroups, 'name'), [function (item) { return item.name; }]);
        allFilterData.parts = _.sortBy(_.uniqBy(allFilterData.parts, 'name'), [function (item) { return item.name; }]);
        allFilterData.functional_areas = _.sortBy(_.uniqBy(allFilterData.functional_areas, 'name'), [function (item) { return item.name; }]);
        allFilterData.facilities = _.sortBy(_.uniqBy(allFilterData.facilities, 'name'), [function (item) { return item.name; }]);
        allFilterData.exhibitorNames = _.sortBy(_.uniqBy(allFilterData.exhibitorNames, 'name'), [function (item) { return item.name; }]);

        allFilterData.facilities.forEach(facility => {
            facility.locations = _.uniqBy(locationData, 'name').filter(locationItem => locationItem.facility == facility.name);
            facility.locations = _.sortBy(facility.locations, [function (item) { return item.name; }]);
            if (facility.isChecked)
                allFilterData.locations = allFilterData.locations.concat(facility.locations);
            facility.locations.forEach(locationItem => {
                locationItem.booths = _.uniqBy(boothsData.filter(booth => booth.locationInfo == locationItem.name && booth.facility == facility.name), 'name');
                locationItem.booths = _.sortBy(locationItem.booths, [function (item) { return item.name; }])
                if (facility.isChecked)
                    allFilterData.booths = allFilterData.booths.concat(locationItem.booths);
            });
        });

        // allFilterData.appliedPartFilter = reset ? !savedFiltersAvailable && this.savedGroupData ? this.savedGroupData : null : this.savedGroupData;

        if (isSetShowFilters && this.savedGroupData)
            allFilterData.appliedPartFilter = this.savedGroupData;
        else if (!this.presetFilters)
            allFilterData.appliedPartFilter = null;
        else {
            if (this.savedGroupData) {
                allFilterData.appliedPartFilter = this.savedGroupData
            }
        }

        // this.isFilterOptionPrepared = true;
        return JSON.parse(JSON.stringify(allFilterData));
    }

    getAllLineItemsData(showId: string) {
        return new Promise((resolve, reject) => {
            this.http.get<CollaborationLineItemDTO[]>(`${this.url()}events/${showId}/work-tickets/showaccounttype/0/all-line-items`)
                .subscribe(data => {
                    resolve(data);
                }, err => {
                    if (err.status !== 401 && err.status !== 403) {
                        this.toastService.open('There was an error while getting show filters data', 'danger');
                    }
                    reject();
                });
        })
    }

    getShowFilters(showId: string) {
        this.storage.get('disablePresetFilters').then(res => {
            this.presetFilters = !res;
        });
        return new Promise((resolve, reject) => {
            this.http.get<any>(`${this.url()}events/${showId}/showsetupfilter/filters`)
                .subscribe(data => {
                    this.allFiltersData = data;
                    resolve(data);
                }, err => {
                    if (err.status !== 401 && err.status !== 403) {
                        this.toastService.open('There was an error while getting show filters data', 'danger');
                    }
                    reject();
                });
        })
    }

    getCurrentShowFilters(showId: string) {
        this.savedFilters = null;
        this.savedGroupData = null;
        this.applyToAllEvents = false;
        this.currentShowId = showId;
        // this.resetFilters = false;

        let isAllShowEnabledFilter = this.allFiltersData.find(x => x.isAllShowEnabled == true);

        let filterData = this.allFiltersData.find(x => x.showGuid == showId);
        if (filterData) {
            this.applyToAllEvents = filterData.isAllShowEnabled;
            if (filterData.endValue) {
                this.savedFilters = JSON.parse(filterData.endValue);
            }
        }
        if (isAllShowEnabledFilter && isAllShowEnabledFilter.startValue) {
            this.savedGroupData = JSON.parse(isAllShowEnabledFilter.startValue);
        } else if (this.savedFilters && this.savedFilters.appliedPartFilter)
            this.savedGroupData = this.savedFilters.appliedPartFilter
    }

    saveShowFilters(showId: string, requestData) {
        return new Promise((resolve, reject) => {
            this.http.post<any>(`${this.url()}events/${showId}/showsetupfilter/save`, requestData)
                .subscribe(data => {
                    if (data.status == 'success') {
                        this.resetFilters = true;
                        resolve(data);
                    }
                    else {
                        this.toastService.open(data.message, 'danger');
                        reject();
                    }
                }, err => {
                    if (err.status !== 401 && err.status !== 403) {
                        this.toastService.open('There was an error while saving show filters', 'danger');
                    }
                    reject();
                });
        })
    }
}