import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MobileNumberFormatPipe } from 'src/app/pipes/mobile-number-format.pipe';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
})
export class PhoneNumberComponent implements OnInit {
  @Input() phoneNumber?: any;
  @Input() showValidationError: any = true;
  @Output() onPhoneNumberChange ?= new EventEmitter();
  public isNumberInvalid: boolean = false;
  public enableSendCode: boolean = false;
  allCountryCodes = [{
    display: "+1",
    value: 1,
    regExData: [/[0-9]/g, /[0-9]/g, /[0-9]/g, /[-]/g, /[0-9]/g, /[0-9]/g, /[0-9]/g, /[-]/g, /[0-9]/g, /[0-9]/g, /[0-9]/g, /[0-9]/g],
    placeholder: "XXX-XXX-XXXX"
  }];
  defaultCountryCode:any = {};

  constructor(private toastService: ToastService,
      private mobileNumberFormatPipe: MobileNumberFormatPipe
    ) { }

  ngOnInit() {
    this.defaultCountryCode = this.allCountryCodes[0];
    this.phoneNumber ? this.formatPhoneNumber(): "";
  }

  formatPhoneNumber() {
    this.isNumberInvalid = false;
    this.enableSendCode = false;
    this.phoneNumber = this.mobileNumberFormatPipe.transform(this.phoneNumber);
    let phoneNumberString = this.phoneNumber;

    if (phoneNumberString.length) {
      let usPhoneNoRegexArray = this.defaultCountryCode.regExData;
      if (phoneNumberString.length <= usPhoneNoRegexArray.length) {
        let splittedPhoneNumber = phoneNumberString.split("");
        for (let charIndex = 0; charIndex < splittedPhoneNumber.length; charIndex++) {
          if (!splittedPhoneNumber[charIndex].match(usPhoneNoRegexArray[charIndex])) {
            this.isNumberInvalid = true;
          }
        }
        if (!this.isNumberInvalid && phoneNumberString.length == 12) {
          this.enableSendCode = true;
        }        
      }
    } else {
      this.phoneNumber = phoneNumberString;
    }
    
    this.onPhoneNumberChange.emit({
      isNumberInvalid: this.isNumberInvalid,
      enableSendCode: this.enableSendCode,
      phoneNumber: this.phoneNumber,
    })
  }

}
