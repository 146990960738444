import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'occ-activity-timeline-tabs',
  templateUrl: './activity-timeline-tabs.component.html',
  styleUrls: ['./activity-timeline-tabs.component.scss'],
})
export class ActivityTimelineTabsComponent implements OnInit {
  @Input() tabEventId: string;
	@Input() accountId?: string;
	@Input() boothId?: string;
	showActivity = true;
	showNotifications = false;
	showFilter = false;

  constructor() { }

  ngOnInit() {}

  activityTab() {
		this.showActivity = true;
		this.showNotifications = false;
	}
	notificationTab() {
		this.showActivity = false;
		this.showNotifications = true;
	}

	toggleFilter() {
		this.showFilter = !this.showFilter;
	}

	closeFilter(close: boolean) {
		this.showFilter = false;
	}

}
