import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { TimelineDTO } from '../../models/timeline-dto';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, switchMap  } from 'rxjs/operators';
import { ToastService } from '../toast/toast.service';
import { StatusHelper } from '../../helpers/status-helper';
import { AuthTokenHelper } from '../../helpers/auth-token-helper';
import * as moment from 'moment';
import { TimelineFilterService } from '../timeline-filter/timeline-filter.service';
import { Observable, Subject } from 'rxjs';
import { FilteredWorkTicketTimeline } from '../../models/work-ticket-timeline-filter-dto';
import { TimelineSearchDTO } from '../../models/timeline-search-dto';

@Injectable({
  providedIn: 'root'
})
export class TimelineService {
	url = environment.apiUrl;
	currentTimelineSubject = new BehaviorSubject<TimelineDTO[]>(null);
	currentTimelineNotificationSubject = new BehaviorSubject<TimelineDTO[]>(null);
	timelineLoading = new BehaviorSubject<boolean>(true);
	activityTimelineService = new Subject();
	notificationTimelineService = new Subject();
	filters: FilteredWorkTicketTimeline[];
	notificationStartDate: Date;
	timelineStartDate: Date;

	constructor(public http: HttpClient, public toastSvc: ToastService, public authTokenHelper: AuthTokenHelper, public filterSvc: TimelineFilterService) {
		this.filterSvc.chosenFilters.subscribe(f => this.filters = f);
		this.resetTimelineStartDate();
		this.resetNotificationStartDate();
		this.activityTimelineService.pipe(debounceTime(500)).pipe(switchMap((criteria: TimelineSearchDTO) => this.filters.length > 0 ? this.getFilteredTimeline(criteria.eventId, criteria.accountId, this.filters) : this.getTimeline(criteria.eventId, criteria.accountId)
		)).subscribe(data => {
			this.setCurrentUserName(data);
			this.currentTimelineSubject.next(data);
			if (data.length < 1) {
				this.timelineLoading.next(false);
			}
		});
		this.notificationTimelineService.pipe(debounceTime(500)).pipe(switchMap((criteria: TimelineSearchDTO) => this.filters.length > 0 ? this.getFilteredTimelineNotifications(criteria.eventId, criteria.accountId, this.filters) : this.getTimelineNotifications(criteria.eventId, criteria.accountId)
		)).pipe(debounceTime(500)).subscribe(data => {
			this.setCurrentUserName(data);
			this.currentTimelineNotificationSubject.next(data);
			if (data.length < 1) {
				this.timelineLoading.next(false);
			}
		});
	}

	// TODO: Add 5ish minutes to these to handle for posting during refresh
	resetNotificationStartDate() {
		this.notificationStartDate = moment.utc(moment.now()).toDate();

	}
	resetTimelineStartDate() {
		this.timelineStartDate = moment.utc(moment.now()).toDate();

	}

	public getTimeline(eventId: string, accountId: string, ignoreError?: boolean): Observable<TimelineDTO[]> {
		this.timelineLoading.next(true);
		return this.http.post<TimelineDTO[]>(`${this.url()}events/${eventId}/accounts/${accountId}/timeline`, this.timelineStartDate);
	}
	public getFilteredTimeline(eventId: string, accountId: string, filterStrings: FilteredWorkTicketTimeline[], ignoreError?: boolean): Observable<TimelineDTO[]> {
		this.timelineLoading.next(true);
		return this.http.post<TimelineDTO[]>(`${this.url()}events/${eventId}/accounts/${accountId}/timeline/filter`,  { startDate: this.timelineStartDate, filters: filterStrings });
	}

	public getTimelineNotifications(eventId: string, accountId: string): Observable<TimelineDTO[]> {
		this.timelineLoading.next(true);
		return this.http.post<TimelineDTO[]>(`${this.url()}events/${eventId}/accounts/${accountId}/timeline/${this.authTokenHelper.currentUserGuid.getValue()}`, this.notificationStartDate);
	}
	public getFilteredTimelineNotifications(eventId: string, accountId: string, filterStrings: FilteredWorkTicketTimeline[]): Observable<TimelineDTO[]> {
		this.timelineLoading.next(true);
		return this.http.post<TimelineDTO[]>(`${this.url()}events/${eventId}/accounts/${accountId}/timeline/${this.authTokenHelper.currentUserGuid.getValue()}/filter`, { startDate: this.timelineStartDate, filters: filterStrings });
	}

	private setCurrentUserName(data: TimelineDTO[]) {
		data.forEach(i => {
			if (i.statusId) {
				i.status = StatusHelper.GetStatusFromId(i.statusId);
			}
			i.senderName = i.senderGuid === this.authTokenHelper.currentUserGuid.getValue() ? 'YOU' : i.senderName;
			i.receiverName = i.receiverGuid === this.authTokenHelper.currentUserGuid.getValue() ? 'YOU' : i.receiverName;
			i.isActiveUser = i.receiverGuid === this.authTokenHelper.currentUserGuid.getValue();
			i.location = i.functionalAreaName ? i.functionalAreaName :  `LI#${i.lineItemNumber}`;
		});
	}
}
