import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'occ-ops-line-items-sortby',
  templateUrl: './ops-line-items-sortby.component.html',
  styleUrls: ['./ops-line-items-sortby.component.scss'],
})

export class OpsLineItemsSortbyComponent {

  text: string;

  constructor(public modalCtrl: ModalController) {
    
  }

  CloseModalPopup() {
    this.modalCtrl.dismiss();
  }

  dismiss(sortType: string) {
    this.modalCtrl.dismiss(sortType);
  }

}

