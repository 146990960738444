import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonAccordionGroup, ModalController } from '@ionic/angular';
import { StatusHelper } from 'src/app/helpers/status-helper';
import * as _ from 'lodash';
import { SearchFilterPipe } from 'src/app/pipes/search-filter.pipe';
import { EsBoothsService } from 'src/app/services/es-booths/es-booths.service';
import { element } from 'protractor';

@Component({
  selector: 'occ-booth-details-filter',
  templateUrl: './booth-details-filter.component.html',
  styleUrls: ['./booth-details-filter.component.scss'],
})
export class BoothDetailsFilterComponent implements OnInit {
  @ViewChild(IonAccordionGroup, { static: true }) accordionGroup: IonAccordionGroup;
  selectedOption: any = '';
  statuses: Array<any>;
  @Input() filterData: any;
  searchFilteredData: any[] = [];
  searchParam: string = '';
  currentSelectedStatus: any;
  currentSelectedSubGroupArray: any[] = [];
  isFound: boolean = false;
  isShow: boolean = false;
  partArray: any;
  isSelectedGroupAvailable: boolean = false;
  isAllGroupSelected: boolean = false;
  isGroupChecked: boolean = true;

  constructor(
    private modalCtrl: ModalController,
    public exhibitorBoothSvc: EsBoothsService
  ) {
   }

  ngOnInit() {
    this.statuses = _.values(StatusHelper.LineItemStatuses);
    this.statuses.forEach(status => {
      status.isChecked = _.find(this.exhibitorBoothSvc.appliedBoothListFilters.statuses, ['id', status.id]) ? true : false;
    });

    this.getGroupSelectionStatus();
    this.getAllGroupSelectionStatus();
  }

  closeFilterModal() {
    this.modalCtrl.dismiss({});
    this.exhibitorBoothSvc.boothListFilterClearSubject.next(false);
  }
  toggleAccordion(event) {
    this.selectedOption = this.accordionGroup.value;
  }

  getSearchResult() {
    this.searchFilteredData = new SearchFilterPipe().transform(this.filterData.partgroups, { type: 'esBooth-filter', searchParam: this.searchParam });
  }
  clearSearch() {
    this.searchParam='';
    this.getSearchResult();
  }

  setSelection(currentSelectedItem) {
    currentSelectedItem.isChecked = true; 
    if(currentSelectedItem?.filterType == "0"){
      this.isGroupChecked = false;
      this.getGroupSelectionStatus();
      this.getAllGroupSelectionStatus();
    }
  }

  clearSelection(currentSelectedItem: any) { 
    currentSelectedItem.isChecked = false;
    if(currentSelectedItem?.filterType == "0"){
      this.getGroupSelectionStatus();
      this.getAllGroupSelectionStatus();

      if( currentSelectedItem?.subgroups?.length){
        currentSelectedItem.subgroups.forEach(subGroupItem => {
          subGroupItem.isChecked = false;
        });
      }
    }
  }

  applyFilterSelection() {
    this.exhibitorBoothSvc.appliedBoothListFilters = {
      'statuses': _.filter(this.statuses, ['isChecked', true]),
      'orders': this.filterData.partgroups      
    };

    this.modalCtrl.dismiss({
      isfilterApplied: true
    });
    this.exhibitorBoothSvc.boothListFilterClearSubject.next(true);
  }

  clearFilterSelection() {
    this.exhibitorBoothSvc.initializeBoothListFilters();
    this.statuses.forEach(status => {
      status.isChecked = false;
    });
    this.filterData?.partgroups.forEach(partGroupItem => {
      partGroupItem.isChecked = false;
      partGroupItem.subgroups?.forEach(subGroupItem => {
        subGroupItem.isChecked = false;
      });
    });
    this.exhibitorBoothSvc.appliedBoothListFilters.orders = this.filterData.partgroups;
    this.exhibitorBoothSvc.boothListFilterClearSubject.next(true);
    this.modalCtrl.dismiss();
  }

  showGroupList(){
    this.isGroupChecked = true;
  }

  getGroupSelectionStatus(){
    this.isSelectedGroupAvailable = (_.find(this.filterData?.partgroups, ["isChecked", true]) ? true : false);
  }

  getAllGroupSelectionStatus(){
    this.isAllGroupSelected = (_.find(this.filterData?.partgroups, ["isChecked", false]) ? false : true); 
  }
}

interface GroupArray {
  'partGroup': any;
  'subGroup': any;
}
