import { Component, Input, OnInit } from '@angular/core';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { takeWhile } from 'rxjs/operators';
import { AccountType } from 'src/app/helpers/account-type-helper';
import { FileDisplayHelper } from 'src/app/helpers/file-display-helper';
import { Attachment } from 'src/app/models/attachment';
import { CollaborationLineItemDTO } from 'src/app/models/collaboration-line-item-dto';
import { Show } from 'src/app/models/show';
import { WorkTicketDetails } from 'src/app/models/work-ticket-details';
import { WorkTicketDTO } from 'src/app/models/work-ticket-dto';
import { JReportService } from 'src/app/services/jReport/j-report.service';
import { WorkTicketService } from 'src/app/services/work-ticket/work-ticket.service';
import { WorkticketLineItemService } from 'src/app/services/workticket-line-item/workticket-line-item.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'occ-work-ticket-file-viewer',
	templateUrl: './work-ticket-file-viewer.component.html',
	styleUrls: ['./work-ticket-file-viewer.component.scss'],
})
export class WorkTicketFileViewerComponent implements OnInit {

	@Input() workTicket: WorkTicketDTO;
	@Input() show: Show;
	functionalAreaAttachments: Attachment[];
	isLoading: boolean;
	public pdfUrl: string;
	public subscribe = true;
	liDto: CollaborationLineItemDTO;
	jreportBaseUrl = environment.jreportBaseUrl;
	jreportAuth = environment.jreportAuth;

	constructor(
		public workTicketSvc: WorkTicketService,
		public fileHelper: FileDisplayHelper,
		public ga: GoogleAnalytics,
		public wtLineItemSvc: WorkticketLineItemService,
		private jReportService: JReportService
	) { }

	ngOnInit(): void {
		this.isLoading = true;
		this.wtLineItemSvc.refresh(this.show.kafkaId, this.workTicket.workTicketGuid).subscribe();
		this.workTicketSvc.functionalAreaAttachment.subscribe(
			data => (this.functionalAreaAttachments = data)
		);
		this.workTicketSvc.faAttachmentsLoading.subscribe(attachmentLoading => this.isLoading = attachmentLoading);
		this.workTicketSvc.getFunctionalAreaAttachements(
			this.show.kafkaId,
			this.workTicketSvc.activeWorkTicketId
		);
		this.wtLineItemSvc.collaborationLineItemDto.pipe(takeWhile(() => this.subscribe)).subscribe(li => {
			if(li.length > 0){
				this.liDto = li[0];
			}
		});
	}

	showFile(fileName, fileId) {
		this.fileHelper.occAttachmentType = 'workTicket';
		this.fileHelper.showFile(fileName, fileId);
	}

	handleFileForBrowser() {
		let showAccountTypeId = AccountType.EXPO;
		if(this.liDto && this.liDto.showAccountTypeId == AccountType.EXHIBIT)
		{
			showAccountTypeId = AccountType.EXHIBIT
		}
		this.jReportService.openWTForBrowser(this.show.tssShowNumber, this.workTicket.tssWorkTicketNumber, showAccountTypeId);
	}

}
