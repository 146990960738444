import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(public toastController: ToastController,
	public router: Router) {
   }

  async open(msg: string, color: string, isDownload?: boolean, duration?: number) {
		const toast = await this.toastController.create({
		  message: msg,
		  duration: duration != undefined ? duration : 2000,
		  position: 'top',
		  color: color,
		  buttons: [
			{
			  text: 'X',
			  role: 'cancel',
			  handler: () => {
				console.log('Cancel clicked');
				isDownload = false;
			  }
			}
		  ]
		});
		await toast.present();
			(await this.toastController.getTop()).addEventListener("click", () => {
				if(isDownload) {
					this.router.navigate(["/download"]);
				}
				this.toastController.dismiss();
			  });
	  }
}
