export const env = {
	applicationVersion: '6.0.2',
	dev: {
		environment: 'dev',
		apiUrl: 'https://checklist-dev.freemanco.com/project-validation/api/',
		loginUrl: 'https://checklist-dev.freemanco.com/permissions/',
		fileBaseUrl: 'https://filevieweruat.freemanco.com/File/File?documentid=',
		identityBaseUrl: 'https://identitytrn.freemanco.com/',
		downloadBaseUrl: 'https://stgstdoccstoragenonprod.blob.core.windows.net/occ-dev-container/update/',
		jreportBaseUrl: `https://reports-uat.freemanco.com/`,
		jreportAuth: 'dGljazp3b3Jr',
		jreportFolder: 'kioskmha',
		domainName: 'checklist-dev.freemanco.com',
		createAccountURL:'http://folstg.freemanco.com/store/customer/customerSearch.jsp?source=44836b493e2d85159f915859aaa76441',
		forgotAccountURL:'http://folstg.freemanco.com/store/user/forget-username.jsp?source=44836b493e2d85159f915859aaa76441'

	},
	test: {
		environment: 'test',
		apiUrl: 'https://checklist-test.freemanco.com/project-validation/api/',
		loginUrl: 'https://checklist-test.freemanco.com/permissions/',
		fileBaseUrl: 'https://filevieweruat.freemanco.com/File/File?documentid=',
		identityBaseUrl: 'https://identitytrn.freemanco.com/',
		downloadBaseUrl: 'https://stgstdoccstoragenonprod.blob.core.windows.net/occ-test-container/update/',
		jreportBaseUrl: `https://reports-uat.freemanco.com/`,
		jreportAuth: 'dGljazp3b3Jr',
		jreportFolder: 'kioskmha',
		domainName: 'checklist-test.freemanco.com',
		createAccountURL:'http://folstg.freemanco.com/store/customer/customerSearch.jsp?source=44836b493e2d85159f915859aaa76441',
		forgotAccountURL:'http://folstg.freemanco.com/store/user/forget-username.jsp?source=44836b493e2d85159f915859aaa76441'

	},
	uat: {
		environment: 'uat',
		apiUrl: 'https://checklist-uat.freemanco.com/project-validation/api/',
		loginUrl: 'https://checklist-uat.freemanco.com/permissions/',
		fileBaseUrl: 'https://filevieweruat.freemanco.com/File/File?documentid=',
		identityBaseUrl: 'https://identitytrn.freemanco.com/',
		downloadBaseUrl: 'https://stgstdoccstoragenonprod.blob.core.windows.net/occ-uat-container/update/',
		jreportBaseUrl: `https://reports-uat.freemanco.com/`,
		jreportAuth: 'dGljazp3b3Jr',
		jreportFolder: 'kioskmha',
		domainName: 'checklist-uat.freemanco.com',
		createAccountURL:'http://folstg.freemanco.com/store/customer/customerSearch.jsp?source=44836b493e2d85159f915859aaa76441',
		forgotAccountURL:'http://folstg.freemanco.com/store/user/forget-username.jsp?source=44836b493e2d85159f915859aaa76441'

	},
	prod: {
		environment: 'prod',
		apiUrl: 'https://checklist.freemanco.com/project-validation/api/',
		loginUrl: 'https://checklist.freemanco.com/permissions/',
		fileBaseUrl: 'https://fileviewer.freemanco.com/File/File?documentid=',
		identityBaseUrl: 'https://identity.freemanco.com/',
		downloadBaseUrl: 'https://stgstdoccstorageprod.blob.core.windows.net/occ-prod-container/update/',
		jreportBaseUrl: `https://reports.freemanco.com/`,
		jreportAuth: 'ZWNzOndvcmt0aWNrZXQ=',
		jreportFolder: 'kioskmha',
		domainName: 'checklist.freemanco.com',
		createAccountURL:'http://www.freemanco.com/store/customer/customerSearch.jsp?source=916df6dabccb420c1ee355d7dd7dd89b',
		forgotAccountURL:'http://www.freemanco.com/store/user/forget-username.jsp?source=916df6dabccb420c1ee355d7dd7dd89b'

	},
    local: {
		environment: 'dev',
		apiUrl: 'https://checklist-dev.freemanco.com/project-validation/api/',
		loginUrl: 'http://localhost:55546/',
		fileBaseUrl: 'https://filevieweruat.freemanco.com/File/File?documentid=',
		identityBaseUrl: 'https://identitytrn.freemanco.com/',
		downloadBaseUrl: 'https://stgstdoccstoragenonprod.blob.core.windows.net/occ-dev-container/update/',
		jreportBaseUrl: `https://reports-uat.freemanco.com/`,
		jreportAuth: 'dGljazp3b3Jr',
		jreportFolder: 'kioskmha',
		domainName: 'checklist-dev.freemanco.com',
		createAccountURL:'http://folstg.freemanco.com/store/customer/customerSearch.jsp?source=44836b493e2d85159f915859aaa76441',
		forgotAccountURL:'http://folstg.freemanco.com/store/user/forget-username.jsp?source=44836b493e2d85159f915859aaa76441'

	},
	userlist: 'Adam Lacey, Sarah Aivaz, Carl Rasquin, Cory Hall, Dana Jecker, Sudhish Sukumaran, Vishnu Buchaiahgari, Ganesh Jadhav, Ashutosh Shukla, Mike Kane, Todd Wharton, Courtney Samuels, Gina Calderon, Lou Loveland, Andrew Bohman, Amit Kumar Gautam, psp-ae-test12, ECS-Production- support-(corp-it)',
	freemanShowInformation: 'https://freemanco.sharepoint.com/teams/ShowInformation/Shared%20Documents/Forms/AllItems.aspx?id=%2fteams%2fShowInformation%2fShared%20Documents%2f2022%20Shows&viewid=8e4be893%2D4649%2D47a8%2D8150%2D9aa59c712d19',
	floorplanVault: 'http://dalprdvltapp01/AutodeskTC/Landing',
	encryptKey: '0e2ba9b7-1835-444e-b727-a5ac9802b5d9'
};
