export class StyleHelper {
	textAreaAdjust(e?) {
		const noteInput = document.getElementById('note-input-textarea');
		noteInput.style.height  = '1px';

		let targetHeight: number = e ? (10 + e.target.scrollHeight) : 46;
		const maxHeight: number = window.innerHeight - (.25 * window.innerHeight);

		if (targetHeight <= 69) {
			targetHeight = 69;
		}

		if (targetHeight <= maxHeight) {
			return noteInput.style.height = targetHeight + 'px';
		}
		noteInput.style.height = maxHeight + 'px';
	}
}
