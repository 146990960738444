import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { takeWhile } from 'rxjs/operators';
import { CreateMhaService } from 'src/app/services/create-mha/create-mha.service';
import { KioskService } from 'src/app/services/kiosk/kiosk.service';
import { MHACarrierTermsComponent } from '../mhacarrier-terms/mhacarrier-terms.component';

@Component({
  selector: 'kiosk-mhacarrier',
  templateUrl: './mhacarrier.component.html',
  styleUrls: ['./mhacarrier.component.scss'],
})
export class MHACarrierComponent implements OnInit {

  public selectedCarrierSegment: number = 1;
  public carrierData: any = [];
  public selectedCarrier: any;
  public otherCarrierName: string;
  public isTermAgreed: boolean = false;
  public subscribe: boolean = true;
  public isFormInvalid: boolean = false;

  @Input() isFormDisabled: any = false;
  @Output() cancelMhaCreation = new EventEmitter();
  @Output() loadNextMHASection = new EventEmitter();
  @Output() onFormInvalid = new EventEmitter<any>();
  @Output() loadPreviousMHASection = new EventEmitter<any>();

  constructor(public modalCtrl: ModalController, public router: Router, public createMHAService: CreateMhaService, public kioskService: KioskService) { }

  ngOnInit() {
    this.selectedCarrierSegment = this.createMHAService.createMhaData.carrier.isExhibitTransportation;
    this.selectedCarrier = this.createMHAService.createMhaData.carrier.anotherCarrierInfo;
    this.otherCarrierName = this.createMHAService.createMhaData.carrier.otherCarrierName;
    this.isTermAgreed = this.createMHAService.createMhaData.carrier.isAgreeChecked;

    this.carrierData = [];
    this.kioskService.getCarrierList();
    this.kioskService.carrierDataSubject.pipe(takeWhile(() => this.subscribe)).subscribe(carrierData => {
      this.carrierData = []
      carrierData.forEach((carrierItem) => {
        this.carrierData.push({
          display: carrierItem.name,
          value: carrierItem.carrierId
        })
      });
    });
  }

  changeSegment(value) {
    this.selectedCarrierSegment = value;
    this.onCarrierSegmentChanged();
  }

  onCarrierSegmentChanged() {
    this.isFormInvalid = false;
    this.selectedCarrier = null;
    this.otherCarrierName = "";
    if (this.selectedCarrierSegment == 1)
      this.isTermAgreed = false;
    this.updatateFormValidity();
  }

  onSelectionChange(currentSelection) {
    this.isFormInvalid = false;
    this.selectedCarrier = currentSelection;
    if (!currentSelection)
      this.otherCarrierName = "";
    this.updatateFormValidity();
  }

  async showTermsModal() {
    const termsModal = await this.modalCtrl.create({
      component: MHACarrierTermsComponent,
      cssClass: 'terms-modal',
      showBackdrop: true,
      backdropDismiss: false
    });
    await termsModal.present();
  }

  updatateFormValidity() {
    this.createMHAService.isCarrierFormValid = true;
    this.createMHAService.createMhaData.carrier.otherCarrierName = this.otherCarrierName;
    this.createMHAService.createMhaData.carrier.isExhibitTransportation = this.selectedCarrierSegment;
    this.createMHAService.createMhaData.carrier.anotherCarrierInfo = this.selectedCarrier;
    this.createMHAService.createMhaData.carrier.isAgreeChecked = this.isTermAgreed;
    if (this.selectedCarrierSegment == 2 && ((!this.selectedCarrier) || (this.selectedCarrier?.value == 0 && !this.otherCarrierName) || !this.isTermAgreed)) {
      this.createMHAService.isCarrierFormValid = false;
    }
  }

  cancelMHACreation() {
    this.cancelMhaCreation.emit();
  }

  loadBillingSection() {
    if (this.createMHAService.mhaGUID) {
      this.loadNextMHASection.emit(3);
      return;
    } 
    this.isFormInvalid = false;
    if (!this.getNextButtonEnabled()) {
      this.createMHAService.createMhaData.carrier.otherCarrierName = this.otherCarrierName;
      this.createMHAService.createMhaData.carrier.isExhibitTransportation = this.selectedCarrierSegment;
      this.createMHAService.createMhaData.carrier.anotherCarrierInfo = this.selectedCarrier;
      this.createMHAService.createMhaData.carrier.isAgreeChecked = this.isTermAgreed;

      this.loadNextMHASection.emit(3);
    } else {
      this.isFormInvalid = true;
    }
  }

  goToPreviousStep() {
    this.loadPreviousMHASection.emit(1);
  }

  getNextButtonEnabled() {
    if (this.selectedCarrierSegment == 1) {
      return false;
    } else {
      if ((!this.selectedCarrier) || (this.selectedCarrier?.value == 0 && !this.otherCarrierName) || !this.isTermAgreed) {
        this.onFormInvalid.emit();
        return true;
      } else {
        return false;
      }
    }
  }

  getNonEditCarrierInfo() {
    if (this.selectedCarrierSegment == 1) {
      return 'Freeman Transportation';
    } else {
      return this.selectedCarrier.value == 0 ? 'Another Carrier > Other Carrier > ' + this.otherCarrierName : 'Another Carrier > ' + this.selectedCarrier.display;
    }
  }
}
