import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Printer } from '@ionic-native/printer/ngx';
import { ModalController, Platform, PopoverController } from '@ionic/angular';
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';
import { CreateMhaService } from 'src/app/services/create-mha/create-mha.service';
import { KioskService } from 'src/app/services/kiosk/kiosk.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { environment } from 'src/environments/environment';
import { KioskPrintConfirmationModalComponent } from '../../kiosk-print-confirmation-modal/kiosk-print-confirmation-modal.component';
import { KioskPrintLoaderComponent } from '../../kiosk-print-loader/kiosk-print-loader.component';
import { KioskPrintUnsuccessfulComponent } from '../../kiosk-print-unsuccessful/kiosk-print-unsuccessful.component';

@Component({
  selector: 'kiosk-acknowledge-freight-package',
  templateUrl: './acknowledge-freight-package.component.html',
  styleUrls: ['./acknowledge-freight-package.component.scss'],
})
export class AcknowledgeFreightPackageComponent implements OnInit {
  
  @Input() isAcknowledgementVisible: boolean = true;
  @Input() isDraftMessageVisible: boolean = false;
  @Input() isPrintViewVisible: boolean = false;
  @Input() isDraft: boolean = false;
  @Input() isPrintDraft: boolean = false;
  
  numberOfCopy: number = 1;
  isConfirmed: boolean = false;
  eventGUID: string;

  jreportBaseUrl = environment.jreportBaseUrl;
  jreportAuth = environment.jreportAuth;
  private jReportPrintUrl: string;
  kioskConfirmationModal: any;
  printUnSuccessModal: any;
  resultedPdFToBePrint: any;
  printSuccessModalComponent: any;

  constructor(
    public modalCtrl: ModalController,
    public createMHAService: CreateMhaService,
    public kioskService: KioskService,
    public toastService: ToastService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public platform: Platform,
    private popoverCtrl: PopoverController,
    private printer: Printer,
    private appBrowserHelper: AppBrowserHelper
  ) { }  

  ngOnInit() {
    this.kioskService.currentShowName.subscribe(showData => {
      if (showData != null)
        this.eventGUID = showData.kafkaId;
    });
  }

  saveAsDraft(){
    this.createMHAService.updateMHA(this.eventGUID, this.createMHAService.mhaGUID).subscribe(saveDraftResponse => {
      this.modalCtrl.dismiss({
        'isSaveAsDraft': true
      });
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error while submitting Outbound Shipping paperwork.', 'danger');
      }
      this.modalCtrl.dismiss();
    });
  }

  submitMHA(){
    this.createMHAService.updateMHA(this.eventGUID, this.createMHAService.mhaGUID, true).subscribe(submitResponse => {
      this.modalCtrl.dismiss({
        'isSubmitMHA': true
      });
      
    }, err => {
      if (err.status !== 401 && err.status !== 403) {
        this.toastService.open('There was an error while saving Outbound Shipping paperwork draft.', 'danger');
      }
      this.modalCtrl.dismiss();
    });
  }

  async closeModal(){
    if(await this.modalCtrl.getTop()){
      this.modalCtrl.dismiss();
    }  
    if((this.isDraftMessageVisible || this.isPrintViewVisible) && !this.isPrintDraft){
      this.router.navigate([this.createMHAService.currentBoothId + '/'+ this.createMHAService.boothNumber + '/kiosk-outbound-shipping-labels']); 
    }  
  }

  decreaseMhaPrintItem(){
    this.numberOfCopy = this.numberOfCopy > 1 ? --this.numberOfCopy : 1;
  }

  increaseMhaPrintItem(){
    this.numberOfCopy = this.numberOfCopy < 1000 ? ++this.numberOfCopy : 999;
  }

  async printShippingLabel(){
    if(await this.modalCtrl.getTop()){
      this.modalCtrl.dismiss();
    }
    this.preparePrintData();
  }

  async preparePrintData() {
    this.kioskService.isKioskPrintDataLoaded = false;
    this.printSuccessModalComponent = await this.modalCtrl.create({
      component: KioskPrintLoaderComponent,
      cssClass: 'print-modal',
      backdropDismiss: false,
      componentProps: {
        isMHASubmitted: true
      },
    });

    await this.printSuccessModalComponent.present();
    
    const jReportBaseUrl = environment.jreportBaseUrl;
    const authorizationKey = environment.jreportAuth;
    const jreportFolder = environment.jreportFolder;
    let selectedMhaId = this.createMHAService.mhaId;
    let showNUmber = this.kioskService.currentShowName.getValue().tssShowNumber;
    
    this.numberOfCopy = this.numberOfCopy ? this.numberOfCopy : 1;
    this.jReportPrintUrl = `${jReportBaseUrl()}jrserver?jrs.cmd=jrs.web_vw&jrs.result_type=2&jrs.rst=test.pdf&jrs.catalog=/${jreportFolder()}/order/order.cat&jrs.report=/${jreportFolder()}/order/kiosk_mhalabels.cls&jrs.param$shownbr=${showNUmber}&jrs.param$mhakey=${selectedMhaId}&jrs.param$qtynbr=${this.numberOfCopy}&jrs.authorization=${authorizationKey()}`;
    this.resultedPdFToBePrint = await this.printShippingLabelApi(this.jReportPrintUrl, this.numberOfCopy);
    await this.capturePrintRequest(this.numberOfCopy, selectedMhaId);
    this.kioskService.isKioskPrintDataLoaded = true;

    const { data } = await this.printSuccessModalComponent.onWillDismiss();
    if (data && data.isDismissed) {
      if (this.resultedPdFToBePrint) {
        this.fetchPdfData(this.resultedPdFToBePrint);
      } else {
        this.printErrorModal();
      }
    }
  }

  fetchPdfData(resultPdf: any) {
    var blob = new Blob([resultPdf], { type: 'application/pdf' });
    const blobUrl = URL.createObjectURL(blob);
    if (!this.appBrowserHelper.isAndroid && !this.appBrowserHelper.isIos && !this.appBrowserHelper.isCapacitor) {
      this.printOnBrowser(blobUrl);
    } else {
      this.printOnDevice(blobUrl);
    }
  }

  printOnBrowser(blobUrl: string) {
    var iframe: any;
    iframe = document.getElementById("printframe");
    if (!iframe) {
      iframe = document.createElement('iframe');
      iframe.setAttribute("id", "printframe");
      iframe.style.display = 'none';
    }
    iframe.src = blobUrl;
    iframe.onload = () => {
      setTimeout(() => {
        iframe.contentWindow.print();
        setTimeout(() => {
          this.showConfirmBox();
        }, 2000);
      }, 500);
    }
    document.body.appendChild(iframe);
  }

  printOnDevice(blobUrl: string) {
    this.printer.isAvailable().then(() => {
      this.printer.print(blobUrl).then(() => {
        this.showConfirmBox();
      }, err => {
        this.printErrorModal();
        return err;
      });
    }, err => {
      this.printErrorModal();
      return err;
    });
  }

  printShippingLabelApi(ShippingLabelPdfUrl: string, numberOfCopy: number) {
    return new Promise((resolve, reject) => {
      this.kioskService.getShippingLabelUrl(this.kioskService.currentShowName.getValue().kafkaId, ShippingLabelPdfUrl, numberOfCopy).subscribe(
        (resPdf: any) => {
          resolve(resPdf);
        }, err => {
          this.printSuccessModalComponent.dismiss();
          this.printErrorModal();
          reject();
          console.log('error :' + err);
        });
    });
  }

  capturePrintRequest(numberOfCopy: number, mhaId: number) {
    var showId = this.kioskService.currentShowName.getValue().kafkaId;
    var boothId = this.createMHAService.currentBoothId;
    return new Promise((resolve, reject) => {
      this.kioskService.captureMHAPrintRequest(showId, boothId, numberOfCopy, mhaId).subscribe(
        (resBool: boolean) => {
          resolve(resBool);
        }, err => {
          console.log('error :' + err);
          this.printErrorModal();
          reject(err);
        });
    })
  }

  async printErrorModal() {
    this.printUnSuccessModal = await this.modalCtrl.create({
      component: KioskPrintUnsuccessfulComponent,
      cssClass: 'print-modal',
      backdropDismiss: false
    });

    await this.printUnSuccessModal.present();

    const { data } = await this.printUnSuccessModal.onWillDismiss();
    if (data && data.isDismissed) {
      this.redirectToHomePage();
    } else{
      this.router.navigate([this.createMHAService.currentBoothId + '/'+ this.createMHAService.boothNumber + '/kiosk-mha-list']); 
    }
  }

  async showConfirmBox() {
    this.kioskConfirmationModal = await this.popoverCtrl.create({
      component: KioskPrintConfirmationModalComponent,
      cssClass: 'kiosk-print-confirm-popup',
      backdropDismiss: false
    });
    await this.kioskConfirmationModal.present();
    await this.kioskConfirmationModal.onDidDismiss().then((confirmStatus) => {
      if (confirmStatus.data && confirmStatus.data.isPrintMore === false) {
        if (this.isDraft) {
          this.router.navigate([this.createMHAService.currentBoothId + '/'+ this.createMHAService.boothNumber + '/kiosk-outbound-shipping-labels']); 
        } else {
          this.redirectToHomePage();
        }
      } else{
        this.router.navigate([this.createMHAService.currentBoothId + '/'+ this.createMHAService.boothNumber + '/kiosk-mha-list']); 
      }
    });
  }

  redirectToHomePage() {
    this.router.navigate(['kiosk-booth-info']);
  }
}
