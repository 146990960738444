import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController, NavParams, PopoverController } from '@ionic/angular';
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';
import { NavParamHelper } from 'src/app/helpers/nav-param-helper';
import { SelectHelper } from 'src/app/helpers/select-helper';
import { FunctionalArea } from 'src/app/models/functional-area';
import { SearchItem } from 'src/app/models/search-item';
import { ActivityService } from 'src/app/services/activity/activity.service';
import { AttachmentService } from 'src/app/services/attachment/attachment.service';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';
import { FunctionalAreaService } from 'src/app/services/functional-area/functional-area.service';
import { LineItemService } from 'src/app/services/line-item/line-item.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { ItemActionMenuComponent } from '../item-action-menu/item-action-menu.component';

@Component({
  selector: 'occ-search-area-card',
  templateUrl: './search-area-card.component.html',
  styleUrls: ['./search-area-card.component.scss'],
})
export class SearchAreaCardComponent implements OnInit {

  @Input() functionalArea: FunctionalArea;
	@Input() searchItem: SearchItem;
	eventId: string;
	accountId: string;
	statusMenuPopover: any;

	constructor(
		public funcSvc: FunctionalAreaService,
		public activitySvc: ActivityService,
		public attachmentSvc: AttachmentService,
		public lineItemSvc: LineItemService,
		public navCtrl: NavController,
		public appBrowserHelper: AppBrowserHelper,
		public dataSvc: DataRefreshService,
		public navParamHelper: NavParamHelper,
		public popoverCtrl: PopoverController,
    private activatedRoute: ActivatedRoute,
    private router: Router
	) {
		this.eventId = this.activatedRoute.snapshot.paramMap.get('eventId') || this.dataSvc.eventActive;
		this.accountId = this.activatedRoute.snapshot.paramMap.get('accountId') || this.dataSvc.showAccountActive;
		this.navParamHelper.syncActiveInfo(this.eventId, this.accountId);
	}

  ngOnInit() {}

	getCount(area: FunctionalArea): string {
		return (area.completedLineItems || 0) + '/' + (area.totalLineItems || 0);
	}

	pushDetailsPage(area) {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		this.funcSvc.activeFunctionalAreaId.next(area.functionalAreaGuid);

		let funcArea = this.funcSvc.currentFuncAreaSubject.getValue();
		if (!funcArea || funcArea !== area) {
			funcArea = area;
			this.funcSvc.currentFuncAreaSubject.next(funcArea);
			this.activitySvc.functionalAreaActivityItems.next([]);
			this.attachmentSvc.faAttachments.next([]);
			this.lineItemSvc.collaborationLineItemDto.next([]);
			this.lineItemSvc.currentLineItem.next(null);
		}

    this.router.navigateByUrl('events/' + this.eventId + '/accounts/' +  this.accountId + '/functional-areas/' +  area.functionalAreaGuid + '/functional-area-details');
	}

	async presentPopover(event, area: FunctionalArea) {
    this.statusMenuPopover = await this.popoverCtrl.create({
			component: ItemActionMenuComponent,
			event: event,
			componentProps: { area: area, eventId: this.eventId, Pagename: 'Search' }
		});

		await this.statusMenuPopover.present();
		event.stopPropagation();
	}

}
