import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { SelectHelper } from 'src/app/helpers/select-helper';
import { FunctionalArea } from 'src/app/models/functional-area';
import { ShowBooth } from 'src/app/models/show-booth-dto';
import { WorkTicketDTO } from 'src/app/models/work-ticket-dto';
import { ActivityService } from 'src/app/services/activity/activity.service';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';
import { FunctionalAreaService } from 'src/app/services/functional-area/functional-area.service';

@Component({
  selector: 'occ-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})

export class DetailsComponent implements OnInit, OnDestroy {
	@Input() functionalAreaId?: string;
	@Input() workTicketId?: string;
	@Input() boothDetails?: ShowBooth;
	activityItem: any;
	functionalArea: FunctionalArea;
	subscribe = true;
	noteExpanded = false;
	workTicket: WorkTicketDTO;

	constructor(
		public activitySvc: ActivityService,
		public funcSvc: FunctionalAreaService,
		public dataSvc: DataRefreshService
	) {
	}

	ngOnInit() {
		if (this.functionalAreaId) {
			this.activitySvc.get(this.dataSvc.eventActive, this.dataSvc.showAccountActive, this.funcSvc.activeFunctionalAreaId.getValue());

			this.funcSvc.currentFuncAreaSubject.pipe(takeWhile(() => this.subscribe)).subscribe(fa => {
				this.functionalArea = fa;
			});
		} else {

		}

	}

	refresh(refresher): void {
		this.funcSvc.refresh(this.dataSvc.eventActive, this.dataSvc.showAccountActive);
		this.activitySvc.refresh(this.dataSvc.eventActive, this.dataSvc.showAccountActive, this.funcSvc.activeFunctionalAreaId.getValue());
		refresher.target.complete();
	}

	ngOnDestroy() {
		this.subscribe = false;
	}

	toggleFANote() {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		if (this.functionalArea.notes)
			this.noteExpanded = !this.noteExpanded;
	}
}

