import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController, IonFab, NavController, PopoverController } from '@ionic/angular';
import { takeWhile } from 'rxjs/operators';
import { AuthTokenHelper } from 'src/app/helpers/auth-token-helper';
import { CollaborationLineItemDTO } from 'src/app/models/collaboration-line-item-dto';
import { NewOrderModalPage } from 'src/app/pages/new-order-modal/new-order-modal.page';
import { BoothServiceProvider } from 'src/app/services/booth/booth.service';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';
import { EventService } from 'src/app/services/event/event.service';
import { LineItemService } from 'src/app/services/line-item/line-item.service';
import { CancelOrderConfirmationComponent } from '../cancel-order-confirmation/cancel-order-confirmation.component';
import { ChangeOrderModalComponent } from '../change-order-modal/change-order-modal.component';
import { AddServiceRequestComponent } from '../exhibitor-service/add-service-request/add-service-request.component';

@Component({
  selector: 'occ-fab',
  templateUrl: './occ-fab.component.html',
  styleUrls: ['./occ-fab.component.scss'],
})

export class OccFabComponent implements OnInit {
    @ViewChild('desktopfab') desktopfab: ElementRef;
    @Input() styles: any = {};
    @Input() isReviseVisible?: boolean = false;
    @Input() isCancelVisible?: boolean = false;
    @Input() isCopyVisible?: boolean = false;
    @Input() isBoothPage?: boolean = false;
    @Input() lineItem?: CollaborationLineItemDTO;
    @Input() selectedOrder?: any;
    @Input() boothGUID?: string;
    @Input() boothLineItemID?: string;
    @Input() isActivityTab?: boolean;
    @Input() isSRVisible?: boolean = false;
    @Input() isAccountPageActive?: boolean = false;

    public eventId: string;
    public accountId: string;

    isActionVisible: boolean = false;
    isEmsClosed: boolean = false;
    subscribe: boolean = true;

    @HostListener('document:click', ['$event.target'])

    public onClick(target) {
        if (this.desktopfab && !this.desktopfab.nativeElement.contains(target)) {
            // this click event from outside
            this.isActionVisible = false;
        }
    }

    constructor(
        public authTokenHelper: AuthTokenHelper,
        public modalCtrl: ModalController,
        public lineItemSvc: LineItemService,
        public dataSvc: DataRefreshService,
        public popoverCtrl: PopoverController,
        public navCtrl: NavController,
        public eventSvc: EventService,
        public boothService: BoothServiceProvider,
        public activatedRoute: ActivatedRoute
    ) {
    }
    ngOnInit(): void {
        this.eventId = this.activatedRoute.snapshot.paramMap.get('eventId') || this.dataSvc.eventActive;
        if(this.boothGUID){
			this.accountId = this.boothService.activeBooth.getValue()?.showAccountGUID;
		}else{
            this.accountId = this.activatedRoute.snapshot.paramMap.get('accountId') || null;
        }
        this.eventSvc.currentEvent.pipe(takeWhile(() => this.subscribe)).subscribe(showDetails => {
            this.isEmsClosed = this.eventSvc.getEMSCloseStatus(this.eventId);
        });
      
        if (this.selectedOrder) {
            this.lineItemSvc.getUpdatedLineItem(this.eventId, this.accountId, this.selectedOrder.functionalAreaGuid, this.selectedOrder.lineItemGuid).subscribe(liDetails => {
                this.lineItem = liDetails;
            });
        }

        this.boothService.activeBooth.pipe(takeWhile(() => this.subscribe)).subscribe(boothData => {
			if(boothData){
				this.accountId = boothData.showAccountGUID;
			}
		});
    }

    toggleActions() {
        this.isActionVisible = !this.isActionVisible;
    }

    async presentChangeOrderModal(fab?: any) {
        if (fab) {
            this.isActionVisible = !this.isActionVisible;
        }
        if (this.lineItem) {
            const changeOrder = await this.modalCtrl.create({
              component :ChangeOrderModalComponent,
              componentProps: {
                functionalArea: this.lineItem.functionalAreaGuid,
                lineItem: this.lineItem,
                boothGUID: this.boothGUID,
                boothLineItemID: this.boothLineItemID
              },
                cssClass: 'change-order-modal'
            });
            await changeOrder.present();
            await changeOrder.onDidDismiss().then(() => {
                if(!this.boothGUID){
                    this.lineItemSvc.getUpdatedLineItem(this.dataSvc.eventActive, this.dataSvc.showAccountActive, this.lineItem.functionalAreaGuid, this.lineItem.kafkaId).subscribe(liDetails => {
                        this.lineItem = liDetails
                    });
                }
            });
        }
    }

    async presentNewOrderModal(fab?: any) {
        if (fab) {
            this.isActionVisible = !this.isActionVisible;
        }

        const newOrderModal = await this.modalCtrl.create({
          component: NewOrderModalPage,
          componentProps: {
            boothGUID: this.boothGUID,
            boothLineItemID: this.boothLineItemID
          },
            cssClass: 'change-order-modal'
        });

        newOrderModal.present();
    }

    async presentCancelOrder(fab?: any) {
        if (fab) {
            this.isActionVisible = !this.isActionVisible;
        }

        if (this.lineItem) {
            const cancelOrder = await this.popoverCtrl.create({
              component: CancelOrderConfirmationComponent});
              cancelOrder.onDidDismiss().then((isConfirmed) => {
                if (isConfirmed.data) {
                    if(this.boothLineItemID){
                        this.boothService.cancelOrderForLineItem(this.eventId, this.accountId, this.lineItem.kafkaId).subscribe((response) => {
                            this.navCtrl.pop();
                        });
                    }else{
                        this.lineItemSvc.cancelOrderForLineItem(this.eventId, this.accountId, this.lineItem.kafkaId).subscribe((response) => {
                            this.navCtrl.pop();
                        });
                    }
                }
            });
            return await cancelOrder.present();
        }
    }

    async presentCopyOrder(fab?: any) {
        if (fab) {
            this.isActionVisible = !this.isActionVisible;
        }

        if(this.lineItem){
            const copyOrderModal = await this.modalCtrl.create({
              component: NewOrderModalPage,
              componentProps: { 
                lineItemData: this.lineItem,
                boothGUID: this.boothGUID,
                boothLineItemID: this.boothLineItemID
              },
                cssClass: 'change-order-modal'
            });
    
            copyOrderModal.present();
        }
    }

    async addServiceRequest(fab?: any) {
        if (fab) {
            this.isActionVisible = !this.isActionVisible;
        }

        let addSRModal = await this.modalCtrl.create({
            component: AddServiceRequestComponent,
            cssClass: 'add-sr-modal',
            backdropDismiss: false,
            componentProps: {
              eventGUID: this.eventId,
              accountGUID: this.accountId
            }
          });
          await addSRModal.present();
          await addSRModal.onDidDismiss();
          this.boothService.getBoothServiceRequestsList(this.eventId, this.boothGUID);

    }
}

