import { Component, Input, NgZone, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import * as moment from 'moment';
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';
import { AuthTokenHelper } from 'src/app/helpers/auth-token-helper';
import { SelectHelper } from 'src/app/helpers/select-helper';
import { FATabsEnum, LITabsEnum } from 'src/app/helpers/tabs-enum';
import { OpsTimelineDTO } from 'src/app/models/opsTimeline-dto';
import { Show } from 'src/app/models/show';
import { TimelineDTO } from 'src/app/models/timeline-dto';
import { TimelineSearchDTO } from 'src/app/models/timeline-search-dto';
import { FilteredWorkTicketTimeline } from 'src/app/models/work-ticket-timeline-filter-dto';
import { BoothServiceProvider } from 'src/app/services/booth/booth.service';
import { LineItemService } from 'src/app/services/line-item/line-item.service';
import { TimelineFilterService } from 'src/app/services/timeline-filter/timeline-filter.service';
import { TimelineService } from 'src/app/services/timeline/timeline.service';
import { WorkTicketTimelineService } from 'src/app/services/work-ticket-timeline/work-ticket-timeline.service';
import { WorkTicketService } from 'src/app/services/work-ticket/work-ticket.service';
import { takeWhile } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'occ-my-notifications-timeline',
  templateUrl: './my-notifications-timeline.component.html',
  styleUrls: ['./my-notifications-timeline.component.scss'],
})
export class MyNotificationsTimelineComponent implements OnInit {
  @Input() eventId: string;
	@Input() accountId?: string;
	@Input() boothId?: string;
	show: Show;
	subscribe = true;
	isLoading: boolean;
	opsTimelineNotificationItems: OpsTimelineDTO[] = [];
	salesTimelineNotificationItems: TimelineDTO[] = [];
	boothTimelineNotificationItems: TimelineDTO[] = [];
	filteredNotificationItems: TimelineDTO[] = [];
	loadAdditionalNotificationData: boolean;
	endOfList = false;
	displayItems: any;
	filter: FilteredWorkTicketTimeline[] = [];
	activeView = false;
	timelineArguments: TimelineSearchDTO;

	constructor(
		public navCtrl: NavController,
		public workTikTimelineSvc: WorkTicketTimelineService,
		public zone: NgZone,
		public workTicketSvc: WorkTicketService,
		public timelineSvc: TimelineService,
		public filterSvc: TimelineFilterService,
		public boothService: BoothServiceProvider,
		public appBrowserHelper: AppBrowserHelper,
		public authTokenHelper: AuthTokenHelper,
		public lineItemSvc: LineItemService,
    public router: Router,
	) {
		if (this.accountId) {
			this.timelineSvc.currentTimelineNotificationSubject.next([]);
		} else {
			this.workTikTimelineSvc.currentTimelineNotificationSubject.next([]);
		}
	}
	ngOnInit() {
		this.displayItems = [];
		this.isLoading = true;

		if (this.boothId) {
			this.boothTimelineSetup()
		} else {
			this.timelineRequest();
			if (this.accountId) {
				this.salesUserTimelineSetup();
			} else {
				this.opsUserTimelineSetup();
			}
		}

		this.filterSvc.chosenFilters.pipe(takeWhile(() => this.subscribe)).subscribe(() => {
			this.displayItems = [];
			if (this.boothId) {
				this.applyFilters();
			}else{
				this.timelineRequest();
			}
		});
	}
	opsUserTimelineSetup() {
		this.workTikTimelineSvc.currentTimelineNotificationSubject
			.pipe(takeWhile(() => this.subscribe))
			.subscribe(tln => {
				if (tln) {
					if (this.endOfList) {
						this.opsTimelineNotificationItems = this.opsTimelineNotificationItems.concat(
							tln
						);
						this.endOfList = false;
					} else {
						this.opsTimelineNotificationItems = tln;
					}
				}
				this.updateView();
			});
		this.workTikTimelineSvc.timelineLoading
			.pipe(takeWhile(() => this.subscribe))
			.subscribe(l => {
				this.isLoading = l;
			});
		this.workTikTimelineSvc.timelineLoading.next(true);
	}
	salesUserTimelineSetup() {
		this.timelineArguments = {
			eventId: this.eventId,
			accountId: this.accountId
		};
		this.timelineSvc.currentTimelineNotificationSubject
			.pipe(takeWhile(() => this.subscribe))
			.subscribe(tln => {
				if (tln) {
					if (this.endOfList) {
						this.salesTimelineNotificationItems = this.salesTimelineNotificationItems.concat(
							tln
						);
						this.endOfList = false;
					} else {
						this.salesTimelineNotificationItems = tln;
					}
				}
				this.updateView();
			});
		this.timelineSvc.timelineLoading
			.pipe(takeWhile(() => this.subscribe))
			.subscribe(l => {
				this.isLoading = l;
			});
		this.timelineSvc.timelineLoading.next(true);
	}

	boothTimelineSetup() {
		this.boothService.currentTimelineNotificationSubject.pipe(takeWhile(() => this.subscribe))
			.subscribe(tln => {
				if (tln) {
					if (this.endOfList) {
						this.boothTimelineNotificationItems = this.boothTimelineNotificationItems.concat(tln);
						this.endOfList = false;
					} else {
						this.boothTimelineNotificationItems = tln;
					}
				}
				this.applyFilters();
				this.isLoading = false;
			});
	}

	goToNotification(item): void {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		if (this.authTokenHelper.isOpsUser) {
			if (item.lineItemGuid === null) {
				// check the current work ticket value before doing anything
				const currentWorkTicket = this.workTicketSvc.currentWorkTicketSubject.getValue();

				// if it doesn't exist or if it's different, update it.
				if (!currentWorkTicket || currentWorkTicket !== item) {
					this.workTicketSvc.currentWorkTicketSubject.next(item);
				}
				this.router.navigateByUrl('events/' + this.eventId + '/worktickets/' +  item.workTicketGuid + '/workticket-details');
			} else {
        this.router.navigateByUrl('events/' + this.eventId + '/workticket-details/' +  item.workTicketGuid + '/line-items/' +  item.lineItemGuid + '/workticket-line-item-details');
			}
		} else {
			if (this.boothId) {
				if (item.changeOrderId !== 0) {
          this.router.navigateByUrl('events/' + this.eventId + '/booths/' +  this.boothId + '/booth-change-orders/' +  item.changeOrderId + '/booth-change-log-details');
				} else if (item.lineItemGuid === null) {
          this.router.navigateByUrl('events/' + this.eventId + '/booths/' +  this.boothId + '/booth-details');
					} else {
						if (SelectHelper.CheckSelectedText(window.getSelection())) return;
						this.boothService.getBoothLiDetails(this.eventId, this.boothService.activeBooth.getValue().showAccountGUID, item.lineItemGuid).subscribe(lineitemData => {
							this.router.navigateByUrl('events/' + this.eventId + '/booths/' +  this.boothId + '/booth-lineitems/' +  item.lineItemGuid + '/booth-lineitem-details');
						}, error => {
	
						});
					}
			} else {
				if (item.changeOrderId !== 0) {
          this.router.navigateByUrl('events/' + this.eventId + '/accounts/' +  this.accountId + '/changeorders/' +  item.changeOrderId + '/change-log-details');
				} else if (item.lineItemGuid === null) {
          this.router.navigateByUrl('events/' + this.eventId + '/accounts/' +  this.accountId + '/functional-areas/' +  item.functionalAreaGuid + '/functional-area-details');
				} else {
					if (SelectHelper.CheckSelectedText(window.getSelection())) return;
					// check the current line item value before doing anything
					this.lineItemSvc.getUpdatedLineItem(this.eventId, this.accountId, item.functionalAreaId, item.lineItemGuid).subscribe(lineitemData => {
						this.lineItemSvc.currentLineItem.next(lineitemData);
            this.router.navigateByUrl('events/' + this.eventId + '/accounts/' +  this.accountId + '/functional-areas/' +  item.functionalAreaGuid + '/line-items/'+  item.lineItemGuid + '/line-item-details');
					}, error => {
	
					});
				}
			}
		}
	}
	showLoadMoreIndicator() {
		if (this.displayItems.length !== 0 && this.endOfList) {
			return true;
		} else {
			return false;
		}
	}

	updateView() {
		this.displayItems = [];
		if (this.boothId) {
			this.displayItems = this.filteredNotificationItems;
		} else {
			this.displayItems = this.accountId ? this.salesTimelineNotificationItems : this.opsTimelineNotificationItems;
		}
	}
	timelineRequest() {
		if (this.boothId) {

		} else {
			if (this.accountId) {
				this.timelineSvc.notificationTimelineService.next(
					this.timelineArguments
				);
			} else if (this.eventId !== null && this.eventId !== undefined && this.eventId.length > 0) {
				this.workTikTimelineSvc.notificationTimelineService.next(
					this.eventId
				);
			}
		}
	}

	clearTimelineItems() {
		if (this.boothId) {
			this.filteredNotificationItems = [];
		} else {
			if (this.accountId) {
				this.salesTimelineNotificationItems = [];
				this.timelineSvc.resetNotificationStartDate();
			} else {
				this.opsTimelineNotificationItems = [];
				this.workTikTimelineSvc.resetNotificationStartDate();
			}
		}
		this.timelineRequest();
	}
	scrollEvent(event: any) {
		const end = event.scrollTop + event.scrollHeight;
		if (event.scrollElement.scrollHeight - end < 10) {
			this.endOfList = true;
			if (!this.isLoading && this.displayItems.length > 200) {
				this.prepareNotificationRequest();
				this.timelineRequest();
			}
		}
	}
	prepareNotificationRequest() {
		if (this.endOfList) {
			if (this.accountId) {
				this.timelineSvc.timelineStartDate = moment
					.utc(
						this.displayItems[this.displayItems.length - 1]
							.createdDate
					)
					.toDate();
			} else {
				this.workTikTimelineSvc.timelineStartDate = moment
					.utc(
						this.displayItems[this.displayItems.length - 1]
							.createdDate
					)
					.toDate();
			}
		}
	}

	applyFilters() {
		this.filteredNotificationItems = [];
		const selectedFilters = this.filterSvc.chosenFilters.getValue();
		this.boothTimelineNotificationItems.forEach(timelineItem => {
			if (selectedFilters && selectedFilters.length > 0) {
				let isAnyCriteriaMateched = false;
				selectedFilters.forEach(filterItem => {
					switch (timelineItem.postType) {
						case "StatusChange":
							if (timelineItem.statusId == filterItem.statusId) {
								isAnyCriteriaMateched = true;
							}
							break;
						case "ChangeLog":
							if (timelineItem.statusId == filterItem.statusId) {
								isAnyCriteriaMateched = true;
							}
							break;
						case "Photo":
							isAnyCriteriaMateched = true;
							break;
						case "Note":
							isAnyCriteriaMateched = true;
							break;
						default:
							break;
					}
				});
				isAnyCriteriaMateched ? this.filteredNotificationItems.push(timelineItem) : "";
			} else {
				this.filteredNotificationItems = [...this.boothTimelineNotificationItems];
			}
		});
		this.updateView();
	}
}
