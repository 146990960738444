import { Component, Input, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { ModalController, NavController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';
import { AuthTokenHelper } from 'src/app/helpers/auth-token-helper';
import { CameraHelper } from 'src/app/helpers/camera-helper';
import { SelectHelper } from 'src/app/helpers/select-helper';
import { LITabsEnum } from 'src/app/helpers/tabs-enum';
import { ActivityItem } from 'src/app/models/activity-item';
import { ActivityService } from 'src/app/services/activity/activity.service';
import { BoothServiceProvider } from 'src/app/services/booth/booth.service';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';
import { FunctionalAreaService } from 'src/app/services/functional-area/functional-area.service';
import { SocialService } from 'src/app/services/social/social.service';
import { UserService } from 'src/app/services/user/user.service';
import { PhotoCarouselComponent } from '../photo-carousel/photo-carousel.component';
import { takeWhile } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { EsBoothsService } from 'src/app/services/es-booths/es-booths.service';

@Component({
	selector: 'occ-photo-note-viewer',
	templateUrl: './photo-note-viewer.component.html',
	styleUrls: ['./photo-note-viewer.component.scss'],
})
export class PhotoNoteViewerComponent implements OnInit, OnDestroy {
	@Input() eventId: string;
	@Input() showAccountId: string;
	@Input() functionalAreaId?: string;
	@Input() boothGUID?: string;
	@Input() lineItemId: string;
	@Input() serviceRequestGUID: any;
	@Input() showNewLayout: boolean = false;
	@Input() isOpsServiceRequestDetails: boolean;
	@Input() isExhibitUser: any;
	@ViewChild('scroll') scroll: any;
	@ViewChildren('itemElements') itemElements: any[];
	public base64Image: string;
	public inputText: string;
	public photoViewerModal;
	public azureUri: string;
	public azureToken: string;
	public activityItems: ActivityItem[];
	public isLineItem: boolean;
	public firstLoad = false;
	private subscribe = true;
	private activityItemsSubject: BehaviorSubject<ActivityItem[]>;
	public isLoading: boolean;
	public showOverlay = true;
	public activeUserGuid: string;
	public activeUserName: string;
	public isMobile = false;

	constructor(
		public activitySvc: ActivityService,
		public cameraHelper: CameraHelper,
		public keyboard: Keyboard,
		public modalCtrl: ModalController,
		public funcSvc: FunctionalAreaService,
		public dataSvc: DataRefreshService,
		public authTokenHelper: AuthTokenHelper,
		private storage: Storage,
		public navCtrl: NavController,
		public appBrowserHelper: AppBrowserHelper,
		public userSvc: UserService,
		public socialSvc: SocialService,
		public boothService: BoothServiceProvider,
		public esBoothService: EsBoothsService
	) {
		this.isMobile = this.checkMobile();
		this.storage.get('azure_uri').then((val) => {
			this.azureUri = val;
		});
		this.storage.get('azure_token').then((val) => {
			this.azureToken = val;
		});
	}

	ngOnInit() {
		this.isLoading = true;
		this.subscribe = true;
		this.activityItems = [];
		if (this.authTokenHelper.isExhibitUser || this.isOpsServiceRequestDetails) {
			this.activityItemsSubject = this.esBoothService.boothLIActivityItems;
			this.esBoothService.getActivityData(this.eventId, this.boothGUID, this.lineItemId, this.serviceRequestGUID, this.isOpsServiceRequestDetails ? this.showAccountId : null);
			this.esBoothService.notePostedScroll.pipe(takeWhile(() => this.subscribe)).subscribe(() => {
				setTimeout(() => {
					this.scroller();
				}, 100);
			});
		} else if (this.boothGUID) {
			const lineItemData = this.boothService.activeBoothLineItem.getValue()
			this.activityItemsSubject = this.boothService.boothLIActivityItems;
			this.boothService.getActivityData(this.eventId, this.boothGUID, this.lineItemId);
			this.boothService.notePostedScroll.pipe(takeWhile(() => this.subscribe)).subscribe(() => {
				setTimeout(() => {
					this.scroller();
				}, 100);
			});
		} else {
			this.activityItemsSubject = this.lineItemId ? this.activitySvc.lineItemActivityItems : this.activitySvc.functionalAreaActivityItems;
			this.activitySvc.get(this.eventId, this.showAccountId, this.functionalAreaId, this.lineItemId);
			this.activitySvc.notePostedScroll.pipe(takeWhile(() => this.subscribe)).subscribe(() => {
				setTimeout(() => {
					this.scroller();
				}, 100);
			});
		}

		this.activityItemsSubject.pipe(takeWhile(() => this.subscribe)).subscribe(items => {
			// this.activityItems = [];
			// if (this.activitySvc.refreshActivityItems) {
			// 	this.activitySvc.refreshActivityItems = false;
			// } else {
			this.activityItems = items;
			if (this.firstLoad) {
				// setTimeout(() => {
				// 	this.scroller();
				// }, 100);
				this.checkForItemsReady();
				this.firstLoad = false;
				this.isLoading = false;
			}

			// }
			this.isLineItem = this.lineItemId ? true : false;

		});
		this.authTokenHelper.currentUserGuid.pipe(takeWhile(() => this.subscribe)).subscribe(x => this.activeUserGuid = x);
		this.authTokenHelper.currentUserName.pipe(takeWhile(() => this.subscribe)).subscribe(x => this.activeUserName = x);
		this.userSvc.showAccountUserDTO.pipe(takeWhile(() => this.subscribe)).subscribe(users => {
			this.activityItems = this.highlightMentions(this.activityItems);
		});

		this.firstLoad = true;
	}

	highlightMentions(items: ActivityItem[]): ActivityItem[] {
		items.forEach(item => {
			if (item.note) {
				item.note = this.activitySvc.styleMentionedUsers(item.note);
			}
		});
		return items;
	}

	checkMobile(): boolean {
		return window.innerWidth <= 767;
	}

	checkForItemsReady(): void {
		let timer = 0;
		const interval = setInterval(() => {
			timer += 10;
			if ((this.itemElements && this.itemElements.length > 0 && this.scroll) || timer >= 10000) {
				this.scroller();
				this.showOverlay = false;
				clearInterval(interval);
			}
		});
	}

	ngOnDestroy() {
		this.subscribe = false;
		this.lineItemId = null;
	}

	// refresh(refresher): void {
	// 	this.activitySvc.refresh(this.dataSvc.eventActive, this.dataSvc.showAccountActive, this.funcSvc.activeFunctionalAreaId.getValue(), this.lineItemId);
	// 	refresher.complete();
	// }

	textAreaAdjust(e) {
		e.target.style.height = '1px';
		e.target.style.height = (10 + e.target.scrollHeight) + 'px';
	}

	scroller(): void {
		if (this.scroll)
			this.scroll.nativeElement.scrollTop = this.scroll.nativeElement.scrollHeight;
	}

	async presentPhotoViewer(photoId: string) {

		this.photoViewerModal = await this.modalCtrl.create({
			component: PhotoCarouselComponent,
			cssClass: 'photo-viewer-modal',
			showBackdrop: true,
			componentProps: {
				photoId: photoId,
				azureUri: this.azureUri,
				azureToken: this.azureToken,
				isLineItem: this.isLineItem,
				boothGUID: this.boothGUID,
				isExhibitUser: this.isExhibitUser,
				serviceRequestGUID: this.serviceRequestGUID
			}
		});
		await this.photoViewerModal.present();
	}

	pushLineItemsPage(lineItem: string) {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		if (!lineItem) return;
		if (this.boothGUID) {
			const linkParams = {
				eventId: this.eventId,
				boothId: this.boothGUID,
				boothLineItemId: lineItem,
			};

			//uncomment below code once 'booth-lineitem-details' page implemented

			// this.navCtrl.setRoot('booth-lineitem-details', linkParams, { animate: !this.appBrowserHelper.isDesktop }, x => {
			// 	if (x === true) {
			// 		this.tabSvc.setHistory('booth-lineitem-details', linkParams);
			// 	}
			// });
		} else {
			const linkParams = {
				eventId: this.eventId,
				functionalAreaId: this.functionalAreaId,
				accountId: this.showAccountId,
				lineItemId: lineItem,
				pageTab: LITabsEnum.Activity
			};

			//uncomment below code once 'line-item-details' page implemented

			// this.navCtrl.setRoot('line-item-details', linkParams, { animate: !this.appBrowserHelper.isDesktop }, x => {
			// 	if (x === true) {
			// 		this.tabSvc.setHistory('line-item-details', linkParams);
			// 	}
			// });
		}
	}

	sharePicture(photoId: string, photoName: string) {
		this.socialSvc.shareImageOnMobileSocialMedia(this.eventId, this.showAccountId, photoId, photoName);
	}
}
