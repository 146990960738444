import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { IonContent, LoadingController, ModalController, NavParams, Platform, PopoverController } from '@ionic/angular';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { takeWhile } from 'rxjs/operators';
import { AppBrowserHelper } from 'src/app/helpers/app-browser-helper';
import { AuthTokenHelper } from 'src/app/helpers/auth-token-helper';
import { SelectHelper } from 'src/app/helpers/select-helper';
import { StatusHelper } from 'src/app/helpers/status-helper';
import { BoothDTO } from 'src/app/models/booth-dto';
import { ChangeOrderDTO } from 'src/app/models/change-order-dto';
import { CollaborationLineItemDTO } from 'src/app/models/collaboration-line-item-dto';
import { LocationDTO } from 'src/app/models/location-dto';
import { SelectableOption } from 'src/app/models/selectable-option';
import { ShowBooth } from 'src/app/models/show-booth-dto';
import { BoothServiceProvider } from 'src/app/services/booth/booth.service';
import { DataRefreshService } from 'src/app/services/data-refresh/data-refresh.service';
import { FunctionalAreaService } from 'src/app/services/functional-area/functional-area.service';
import { LineItemService } from 'src/app/services/line-item/line-item.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';
import { BoothSelectionModalComponent } from '../booth-selection-modal/booth-selection-modal.component';
import { ChangeOrderConfirmationModalComponent } from '../change-order-confirmation-modal/change-order-confirmation-modal.component';
import { LocationSelectionModalComponent } from '../location-selection-modal/location-selection-modal.component';

@Component({
	selector: 'occ-change-order-modal',
	templateUrl: './change-order-modal.component.html',
	styleUrls: ['./change-order-modal.component.scss'],
})

export class ChangeOrderModalComponent implements OnInit, OnDestroy {
	public errorMessage: string;
	@ViewChild('content') content: IonContent;
	myDatePickerOptions: IAngularMyDpOptions = {
		alignSelectorRight: true,
		dateFormat: 'mm/dd/yyyy'
		// width: '240px',
		// height: '42px',
		// showTodayBtn: false,
		// indicateInvalidDate: true
	};

	url = environment.apiUrl;
	lineItem: CollaborationLineItemDTO;
	associatedLineItems: any[] = [];
	lineItemDto: CollaborationLineItemDTO[];
	changeOrder: ChangeOrderDTO;
	locationDto: LocationDTO[];
	location: LocationDTO;
	selectedLocation: LocationDTO;
	selectedLocationId: string;
	boothDto: BoothDTO;
	booths: BoothDTO[];
	selectedBooth: BoothDTO;
	selectedBoothId: string;
	selectedOptionsData: SelectableOption[][] = [];

	locationSelectionModal: any;
	boothSelectionModal: any;
	modalTitle: string;
	cb: Function;
	keyboardIsOpen: boolean;
	noneSelected: boolean;
	isCopyItemComment: boolean = false;

	isQuantityChangeChecked: boolean;
	isRevisedCommentsChecked: boolean;
	isLocationChangeChecked: boolean;
	isBoothNumberChangeChecked: boolean;
	isDateTimeChangeChecked: boolean;
	isOtherChecked: boolean;
	setupDate: any;
	datepickerSetupDate: any;
	setupTime: any;
	endDate: any;
	datepickerEndDate: any;
	endTime: any;
	teardownStartDate: any;
	datepickerTeardownStartDate: any;
	tearDownStartTime: any;
	teardownEndDate: any;
	datepickerTeardownEndDate: any;
	tearDownEndTime: any;
	canSave: boolean;
	groupNumber: string;
	disableSave: boolean = false;
	isAssociatedLIPopup: boolean = false;
	itemExpanded = false;
	ticketExpanded = false;

	private subscribe = true;
	public eventId: string;
	public accountId: string;
	public functionalAreaId: string;
	public locationPlaceholderText: string;
	public boothPlaceholderText: string;
	boothGUID: string;
	boothLineItemID: string;
	boothDetails: ShowBooth;
	salesUser = false;

	constructor(
		public http: HttpClient,
		public appBrowserHelper: AppBrowserHelper,
		public navParams: NavParams,
		public lineItemService: LineItemService,
		public modalCtrl: ModalController,
		public domSanitizer: DomSanitizer,
		public keyboard: Keyboard,
		public dataSvc: DataRefreshService,
		public platform: Platform,
		public userSvc: UserService,
		private authTokenHelper: AuthTokenHelper,
		public popoverCtrl: PopoverController,
		public toastSvc: ToastService,
		public loadingCtrl: LoadingController,
		public funcSvc: FunctionalAreaService,
		public boothService: BoothServiceProvider
	) {
		this.lineItem = this.navParams.data.lineItem;
		this.eventId = this.dataSvc.eventActive;
		this.accountId = this.dataSvc.showAccountActive;
		this.keyboard.hideFormAccessoryBar(false);
		this.keyboard.disableScroll(true);
		this.cb = this.navParams.data.cb;
		this.boothGUID = this.navParams.data.boothGUID;
		this.boothLineItemID = this.navParams.data.boothLineItemID;

		//Initialize Change Order
		this.changeOrder = new ChangeOrderDTO();
		this.changeOrder.kafkaId = this.lineItem.kafkaId;
		this.changeOrder.functionalAreaGuid = this.boothGUID ? this.boothService.emptyFAGUID : this.lineItem.functionalAreaGuid;
		this.functionalAreaId = this.boothGUID ? this.boothService.emptyFAGUID : this.lineItem.functionalAreaGuid;
		this.changeOrder.description = this.lineItem.description;
		this.changeOrder.locationInfo = this.lineItem.locationInfo;
		this.changeOrder.unitOfMeasure = this.lineItem.unitOfMeasure;
		this.changeOrder.quantity = this.lineItem.quantity;
		this.changeOrder.lineItemId = this.lineItem.passportId;
		this.changeOrder.partNumber = this.lineItem.partNumber;
		this.changeOrder.detailDescription = this.lineItem.detailDescription;
		this.changeOrder.ticketNumber = this.lineItem.ticketNumber
		this.changeOrder.setupStart = this.lineItem.setupDate;
		this.changeOrder.setupEnd = this.lineItem.endDate;
		this.changeOrder.strikeEnd = this.lineItem.teardownStartDate;
		this.changeOrder.strikeEnd = this.lineItem.teardownEndDate;
		this.changeOrder.booth = this.lineItem.booth;
		this.changeOrder.displayName = this.lineItem.displayName;
		this.changeOrder.vendorComments = this.lineItem.vendorComments;
		this.changeOrder.ticketComments = this.lineItem.ticketComments;
		this.changeOrder.revisionComments = this.lineItem.revisionComments;
		this.changeOrder.showAccountGuid = this.lineItem.showAccountGuid;
		this.changeOrder.partSelections = this.lineItem.partSelections;
		this.changeOrder.partDescription = this.lineItem.detailDescription;
		this.changeOrder.vendorName = this.lineItem.revisionComments;
		this.changeOrder.locationInfo = this.lineItem.locationInfo;
		this.changeOrder.changeRequest = false;
		this.changeOrder.men = this.lineItem.men;
		this.changeOrder.hours = this.lineItem.hours;

		this.modalTitle = this.changeOrder ? this.changeOrder.displayName + '' + (this.changeOrder.lineItemId ? ' (' + this.changeOrder.lineItemId + ')' : '') : '';
	}

	ionViewWillEnter() {
		/*this.lineItemService.getLocations(this.eventId, this.accountId, this.lineItem.functionalAreaGuid).subscribe( locations => {
			this.locationDto = locations;
			this.locationPlaceholderText = this.locationDto.length === 0 ? 'None' : 'Select';
			this.selectedLocationId = this.locationDto.find(l => l.name == this.lineItem.locationInfo).kafkaId;
		});
	
		this.lineItemService.getLocations(this.eventId, this.accountId, this.lineItem.functionalAreaGuid);
		this.lineItemService.locationDto.takeWhile(() => this.subscribe).subscribe(loDto => {
			this.locationDto = loDto;
			this.locationPlaceholderText = this.locationDto.length === 0 ? 'None' : 'Select';
			this.selectedLocationId = this.locationDto.find(l => l.name == this.lineItem.locationInfo).kafkaId;
		});

		if(this.selectedLocationId){
			this.getBoothsForSelectedLocation(this.selectedLocationId);
			this.selectedBoothId = this.booths.find(b => b.name == this.lineItem.booth).kafkaId;
		}*/

	}

	ngOnDestroy() {
		if (this.locationSelectionModal) {
			this.locationSelectionModal.dismiss();
		}
		if (this.boothSelectionModal) {
			this.boothSelectionModal.dismiss();
		}
		this.subscribe = false;
	}

	ngOnInit() {
		this.canSave = true;
		this.isQuantityChangeChecked = false;
		this.isRevisedCommentsChecked = false;
		this.isLocationChangeChecked = false;
		this.isBoothNumberChangeChecked = false;
		this.isDateTimeChangeChecked = false;
		this.isOtherChecked = false;
		this.isCopyItemComment = false;

		this.boothService.activeBooth.pipe(takeWhile(() => this.subscribe)).subscribe(boothData => {
			if (boothData) {
				this.boothDetails = boothData;
				this.accountId = this.boothDetails.showAccountGUID;
				this.changeOrder.booth = this.boothDetails.boothName;
				this.changeOrder.showBoothId = this.boothDetails.boothGUID;
				this.selectedBoothId = this.boothDetails.boothGUID;
			}
		});

		this.lineItemService.getLocations(this.eventId, this.accountId, this.changeOrder.functionalAreaGuid).subscribe(locations => {
			this.locationDto = locations;
			this.locationPlaceholderText = this.locationDto.length === 0 ? 'None' : this.changeOrder.locationInfo;
			this.selectedLocationId = this.locationDto.find(l => l.name == this.changeOrder.locationInfo).kafkaId;
			if (this.selectedLocationId) {
				this.setSelectedLocation(this.selectedLocationId);
				this.getBoothsForSelectedLocation(this.selectedLocationId);
			}
		});

		this.setupPage();
		this.initalizeDates();

		// Labor Group Numbers - 30, 31, 35, 36, 37, 38, 39, 51, eg: (G-SG-Item#) 30-3-18
		var partNumberSplit = this.changeOrder.partNumber.toString().split('-');
		this.groupNumber = partNumberSplit[0];
		this.changeOrder.revisionReasonTypeId = '';

		this.lineItemService.getSelectableOptions(this.eventId, this.accountId, this.changeOrder.functionalAreaGuid, this.lineItem.kafkaId).subscribe(optionsData => {
			this.selectedOptionsData = optionsData;
			this.disableSave = this.selectedOptionsData.length > 0 ? true : false;
		});

		this.lineItemService.getAssociatedLineItems(this.eventId, this.accountId, this.changeOrder.functionalAreaGuid, this.lineItem.kafkaId).subscribe(associatedLineItemsArray => {
			this.associatedLineItems = associatedLineItemsArray
		});
	}

	async setupPage() {
		this.salesUser = await this.authTokenHelper.IsSalesUser();
	}

	setKeyboardOpenStatus(isOpen: boolean) {
		this.keyboardIsOpen = isOpen;
	}

	dismiss() {
		this.keyboard.hideFormAccessoryBar(true);
		this.keyboard.disableScroll(false);
		this.modalCtrl.dismiss();
		// to set the modalOpen property and replace the footer.
		if (this.cb) {
			this.cb();
		}
	}

	async presentLocationSelectionModal() {
		let selectedId = null;
		if (this.selectedLocation) {
			selectedId = this.selectedLocation.kafkaId;
		}
		this.locationSelectionModal = await this.modalCtrl.create({
			component: LocationSelectionModalComponent,
			componentProps: { cb: this.setSelectedLocation.bind(this), selectedLocationId: selectedId },
			cssClass: 'location-selection-modal'
		});
		await this.locationSelectionModal.present();
	}

	setSelectedLocation(locationId: string) {
		if (locationId === 'None') {
			this.noneSelected = true;
		}
		this.selectedLocation = this.locationDto.find(l => l.kafkaId === locationId);
		this.changeOrder.showLocationId = locationId;
		this.selectedLocationId = locationId;
		if (this.selectedLocation) {
			this.changeOrder.locationInfo = this.selectedLocation.name;
		}
		this.getBoothsForSelectedLocation(locationId);
	}

	async presentBoothSelectionModal() {
		let selectedId = null;
		if (this.selectedBooth) {
			selectedId = this.selectedBooth.kafkaId;
		}
		this.boothSelectionModal = await this.modalCtrl.create({
			component: BoothSelectionModalComponent,
			componentProps: { cb: this.setSelectedBooth.bind(this), selectedBoothId: selectedId },
			cssClass: 'booth-selection-modal'
		});
		await this.boothSelectionModal.present();
	}

	setSelectedBooth(boothId: string) {
		if (boothId === 'None') {
			this.noneSelected = true;
		}
		this.changeOrder.showBoothId = boothId;
		this.selectedBooth = this.booths.find(b => b.kafkaId === boothId);
		this.selectedBoothId = this.selectedBooth ? this.selectedBooth.kafkaId : "";
		this.changeOrder.booth = this.selectedBooth ? this.selectedBooth.name : "";
	}

	getBoothsForSelectedLocation(locationId: string) {
		this.lineItemService.getBooths(this.eventId, this.accountId, this.changeOrder.functionalAreaGuid, locationId).subscribe(boothdtos => {
			this.booths = boothdtos;
			this.boothPlaceholderText = this.booths.length === 0 ? 'None' : this.changeOrder.booth;
			this.selectedBoothId = this.booths.find(b => b.name == this.changeOrder.booth) ? this.booths.find(b => b.name == this.changeOrder.booth).kafkaId : "";
			if (this.selectedBoothId) {
				this.setSelectedBooth(this.selectedBoothId);
			}
		});
	}

	initalizeDates() {
		if (this.changeOrder.setupStart && this.changeOrder.setupEnd) {
			var start: Date = new Date(this.changeOrder.setupStart);
			this.setupDate = { date: { year: start.getUTCFullYear(), month: start.getUTCMonth() + 1, day: start.getUTCDate() }, jsDate: start };
			this.datepickerSetupDate = {
				singleDate: this.setupDate
			}
			var end: Date = new Date(this.changeOrder.setupEnd);
			this.endDate = { date: { year: end.getUTCFullYear(), month: end.getUTCMonth() + 1, day: end.getUTCDate() }, jsDate: end };
			this.datepickerEndDate = {
				singleDate: this.endDate
			};

			if (!this.changeOrder.strikeStart) {
				this.changeOrder.strikeStart = this.changeOrder.setupEnd;
			}

			var tearStart: Date = new Date(this.changeOrder.strikeStart);
			this.teardownStartDate = { date: { year: tearStart.getUTCFullYear(), month: tearStart.getUTCMonth() + 1, day: tearStart.getUTCDate() }, jsDate: tearStart };
			this.datepickerTeardownStartDate = {
				singleDate: this.teardownStartDate
			}

			if (!this.changeOrder.strikeEnd) {
				this.changeOrder.strikeEnd = this.changeOrder.setupEnd;
			}
			var tearEnd: Date = new Date(this.changeOrder.strikeEnd);
			this.teardownEndDate = { date: { year: tearEnd.getUTCFullYear(), month: tearEnd.getUTCMonth() + 1, day: tearEnd.getUTCDate() }, jsDate: tearEnd };
			this.datepickerTeardownEndDate = {
				singleDate: this.teardownEndDate
			};

			var time = this.changeOrder.setupStart.toString().split('T');
			this.setupTime = time[1].split('Z')[0];
			time = this.changeOrder.setupEnd.toString().split('T');
			this.endTime = time[1].split('Z')[0];
			time = this.changeOrder.strikeStart.toString().split('T');
			this.tearDownStartTime = time[1].split('Z')[0];
			time = this.changeOrder.strikeEnd.toString().split('T');
			this.tearDownEndTime = time[1].split('Z')[0];
		}
	}

	toggleComments() {
		if (SelectHelper.CheckSelectedText(window.getSelection())) return;
		if (this.lineItem.vendorComments) {
			this.itemExpanded = !this.itemExpanded;
		}
		if (this.lineItem.vendorComments) {
			this.ticketExpanded = !this.ticketExpanded;
		}
	}

	checkForValidQuantity(): boolean {
		this.canSave = true;
		if (this.groupNumber != "30" && this.groupNumber != "31" && this.groupNumber != "35" && this.groupNumber != "36"
			&& this.groupNumber != "37" && this.groupNumber != "38" && this.groupNumber != "39" && this.groupNumber != "51") {
			if (this.changeOrder.quantity == 0 || !this.changeOrder.quantity) {
				this.canSave = false;
				this.errorMessage = 'Please enter valid quantity';
			}
		}
		return this.canSave;
	}

	onMenChange() {
		this.checkForValidMen();
		this.calculateLaborQuantity();
	}

	checkForValidMen(): boolean {
		this.canSave = true;
		if (this.groupNumber == "30" || this.groupNumber == "31" || this.groupNumber == "35" || this.groupNumber == "36"
			|| this.groupNumber == "37" || this.groupNumber == "38" || this.groupNumber == "39" || this.groupNumber == "51") {
			if (this.changeOrder.men == 0 || !this.changeOrder.men) {
				this.canSave = false;
				this.errorMessage = 'Please enter valid men';
			}
		}
		return this.canSave;
	}

	onHoursChange() {
		this.checkForValidHours();
		this.calculateLaborQuantity();
	}

	checkForValidHours(): boolean {
		this.canSave = true;
		if (this.groupNumber == "30" || this.groupNumber == "31" || this.groupNumber == "35" || this.groupNumber == "36"
			|| this.groupNumber == "37" || this.groupNumber == "38" || this.groupNumber == "39" || this.groupNumber == "51") {
			if (this.changeOrder.hours == 0 || !this.changeOrder.hours) {
				this.canSave = false;
				this.errorMessage = 'Please enter valid hours';
			}
		}
		return this.canSave;
	}

	calculateLaborQuantity() {
		if (this.changeOrder.men) {
			this.changeOrder.men = parseInt(this.changeOrder.men.toString().replace(".", ""));
			this.changeOrder.men = parseInt(this.changeOrder.men.toString().replace("-", ""));
		}

		if (this.changeOrder.hours) {
			this.changeOrder.hours = parseFloat(this.changeOrder.hours.toString().replace("-", ""));
		}

		if (this.changeOrder.men && this.changeOrder.hours) {
			this.changeOrder.quantity = parseFloat((this.changeOrder.men * this.changeOrder.hours).toFixed(2));
		} else {
			this.changeOrder.quantity = 0;
		}
	}

	checkForValidDate() {

			if (!this.appBrowserHelper.isDesktop) {
				if (this.changeOrder.setupStart && this.changeOrder.setupEnd && this.changeOrder.strikeStart && this.changeOrder.strikeEnd) {
					let setupStartDate = new Date(this.changeOrder.setupStart);
					let setupEndDate = new Date(this.changeOrder.setupEnd);
					let teardownStartDate = new Date(this.changeOrder.strikeStart);
					let teardownEndDate = new Date(this.changeOrder.strikeEnd);
	
					if (!(setupStartDate <= setupEndDate)) {
						this.errorMessage = 'Setup Start Time cannot be after Setup Complete Time.';
						this.canSave = false;
					}
					if (!(setupEndDate <= teardownStartDate)) {
						this.errorMessage = 'Tear Down Start Time cannot be before Setup Complete Time';
						this.canSave = false;
					}
					if (!(teardownStartDate <= teardownEndDate)) {
						this.errorMessage = 'Tear Down Complete Time cannot be before Tear Down Start Time.';
						this.canSave = false;
					}
				}
				else if (this.changeOrder.setupStart && this.changeOrder.setupEnd) {
					let setupStartDate = new Date(this.changeOrder.setupStart);
					let setupEndDate = new Date(this.changeOrder.setupEnd);
	
					if (!(setupStartDate <= setupEndDate)) {
						this.errorMessage = 'Setup Start Time cannot be after Setup Complete Time.';
						this.canSave = false;
					}
				}
			}
	
			if (this.appBrowserHelper.isDesktop) {
				this.setupDate = this.datepickerSetupDate ? this.datepickerSetupDate.singleDate : this.setupDate;
				this.endDate = this.datepickerEndDate ? this.datepickerEndDate.singleDate : this.endDate;
				this.teardownStartDate = this.datepickerTeardownStartDate ? this.datepickerTeardownStartDate.singleDate : this.teardownStartDate;
				this.teardownEndDate = this.datepickerTeardownEndDate ? this.datepickerTeardownEndDate.singleDate : this.teardownEndDate;

				if (this.setupDate && this.endDate && this.teardownStartDate && this.teardownEndDate) {
					if (!(this.setupDate.date.year <= this.endDate.date.year)) {
						this.errorMessage = 'Setup Start Time cannot be after Setup Complete Time.';
						this.canSave = false;
						if (!(this.setupDate.date.month <= this.endDate.date.month)) {
							this.errorMessage = 'Setup Start Time cannot be after Setup Complete Time.';
							this.canSave = false;
							if (!(this.setupDate.date.day <= this.endDate.date.day)) {
								this.errorMessage = 'Setup Start Time cannot be after Setup Complete Time.';
								this.canSave = false;
							}
						}
					}
	
					if (!(this.endDate.date.year <= this.teardownStartDate.date.year)) {
						this.errorMessage = 'Tear Down Start Time cannot be before Setup Complete Time.';
						this.canSave = false;
						if (!(this.endDate.date.month <= this.teardownStartDate.date.month)) {
							this.errorMessage = 'Tear Down Start Time cannot be before Setup Complete Time.';
							this.canSave = false;
							if (!(this.endDate.date.day <= this.teardownStartDate.date.day)) {
								this.errorMessage = 'Tear Down Start Time cannot be before Setup Complete Time.';
								this.canSave = false;
							}
						}
					}
	
					if (!(this.teardownStartDate.date.year <= this.teardownEndDate.date.year)) {
						this.errorMessage = 'Tear Down Complete Time cannot be before Tear Down Start Time.';
						this.canSave = false;
						if (!(this.teardownStartDate.date.month <= this.teardownEndDate.date.month)) {
							this.errorMessage = 'Tear Down Complete Time cannot be before Tear Down Start Time.';
							this.canSave = false;
							if (!(this.teardownStartDate.date.day <= this.teardownEndDate.date.day)) {
								this.errorMessage = 'Tear Down Complete Time cannot be before Tear Down Start Time.';
								this.canSave = false;
							}
						}
					}
				}
				else if (this.setupDate && this.endDate) {
					if (!(this.setupDate.date.year <= this.endDate.date.year)) {
						this.errorMessage = 'Setup Start Time cannot be after Setup Complete Time.';
						this.canSave = false;
						if (!(this.setupDate.date.month <= this.endDate.date.month)) {
							this.errorMessage = 'Setup Start Time cannot be after Setup Complete Time.';
							this.canSave = false;
							if (!(this.setupDate.date.day <= this.endDate.date.day)) {
								this.errorMessage = 'Setup Start Time cannot be after Setup Complete Time.';
								this.canSave = false;
							}
						}
					}
				}
			}
	
			if (this.canSave) {
				this.errorMessage = '';
				if (this.appBrowserHelper.isDesktop) {
					if (this.setupDate && this.endDate && this.teardownStartDate && this.teardownEndDate) {
						if (this.setupTime && this.endTime && this.tearDownStartTime && this.tearDownEndTime) {
							this.changeOrder.setupStart = new Date(this.setupDate.date.year.toString() + "-" + this.setupDate.date.month.toString() + "-" + this.setupDate.date.day.toString() + " " + this.setupTime);
							this.changeOrder.setupEnd = new Date(this.endDate.date.year.toString() + "-" + this.endDate.date.month.toString() + "-" + this.endDate.date.day.toString() + " " + this.endTime);
							this.changeOrder.strikeStart = new Date(this.teardownStartDate.date.year.toString() + "-" + this.teardownStartDate.date.month.toString() + "-" + this.teardownStartDate.date.day.toString() + " " + this.tearDownStartTime);
							this.changeOrder.strikeEnd = new Date(this.teardownEndDate.date.year.toString() + "-" + this.teardownEndDate.date.month.toString() + "-" + this.teardownEndDate.date.day.toString() + " " + this.tearDownEndTime);
						}
					}
					else if (this.setupDate && this.endDate) {
						if (this.setupTime && this.endTime) {
							this.changeOrder.setupStart = new Date(this.setupDate.date.year.toString() + "-" + this.setupDate.date.month.toString() + "-" + this.setupDate.date.day.toString() + " " + this.setupTime);
							this.changeOrder.setupEnd = new Date(this.endDate.date.year.toString() + "-" + this.endDate.date.month.toString() + "-" + this.endDate.date.day.toString() + " " + this.endTime);
						}
					}
				}
			}
	
			return this.canSave;

	}

	checkForValidRevision(): boolean {
		if (!(this.isDateTimeChangeChecked || this.isLocationChangeChecked || this.isBoothNumberChangeChecked || this.isDateTimeChangeChecked || this.isQuantityChangeChecked || this.isRevisedCommentsChecked || this.isOtherChecked)) {
			this.errorMessage = "Select a revision reason."
			this.canSave = false;
		}
		else {
			this.errorMessage = "";
			this.canSave = true;
		}

		return this.canSave;
	}

	isValidSave() {
		if (this.boothGUID) {
			this.selectedBoothId = this.boothGUID;
		}
		if (!(this.checkForValidQuantity())) {
			return this.canSave;
		}

		if (!this.checkForValidMen() || !this.checkForValidHours()) {
			return this.canSave;
		}
		if (!(this.checkForValidQuantity())) {
			return this.canSave;
		}
		else if (!this.selectedLocationId) {
			this.canSave = false;
			this.errorMessage = 'Please select valid location';
			return this.canSave;
		}
		else if (!this.selectedBoothId) {
			this.canSave = false;
			this.errorMessage = 'Please select valid Booth';
			return this.canSave;
		}
		else if (!(this.checkForValidRevision())) {
			return this.canSave;
		}
		return this.checkForValidDate();
	}

	async saveChangeOrder() {
		if (this.isValidSave()) {
			if (this.isQuantityChangeChecked) {
				this.changeOrder.revisionReasonTypeId = "1";
			}
			if (this.isDateTimeChangeChecked) {
				this.changeOrder.revisionReasonTypeId = this.changeOrder.revisionReasonTypeId + ",11";
			}
			if (this.isRevisedCommentsChecked) {
				this.changeOrder.revisionReasonTypeId = this.changeOrder.revisionReasonTypeId + ",3";
			}
			if (this.isLocationChangeChecked) {
				this.changeOrder.revisionReasonTypeId = this.changeOrder.revisionReasonTypeId + ",9";
			}
			if (this.isBoothNumberChangeChecked) {
				this.changeOrder.revisionReasonTypeId = this.changeOrder.revisionReasonTypeId + ",10";
			}
			if (this.isOtherChecked) {
				this.changeOrder.revisionReasonTypeId = this.changeOrder.revisionReasonTypeId + ",5";
			}

			this.changeOrder.showLocationId = this.selectedLocationId;
			this.changeOrder.showBoothId = this.selectedBoothId;
			this.changeOrder.createdDate = new Date();
			this.changeOrder.modifiedDate = new Date();

			if (this.isQuantityChangeChecked || this.isDateTimeChangeChecked || this.isLocationChangeChecked) {
				this.changeOrder.currentStatus = StatusHelper.Status.OnHold.id;
			} else {
				this.changeOrder.currentStatus = this.lineItem.currentStatus;
			}

			if ((this.isDateTimeChangeChecked || this.isLocationChangeChecked || this.isBoothNumberChangeChecked) && this.associatedLineItems.length) {
				this.isAssociatedLIPopup = true;
			} else {
				this.isAssociatedLIPopup = false;
			}

			if (!this.appBrowserHelper.isDesktop) {
				if (this.changeOrder.setupStart) {
					let date = new Date(this.changeOrder.setupStart);
					this.changeOrder.setupStart = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
				}

				if (this.changeOrder.setupEnd) {
					let date = new Date(this.changeOrder.setupEnd);
					this.changeOrder.setupEnd = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
				}

				if (this.changeOrder.strikeStart) {
					let date = new Date(this.changeOrder.strikeStart);
					this.changeOrder.strikeStart = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
				}

				if (this.changeOrder.strikeEnd) {
					let date = new Date(this.changeOrder.strikeEnd);
					this.changeOrder.strikeEnd = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
				}
			}

			const saveChangeOrder = await this.popoverCtrl.create({
				component: ChangeOrderConfirmationModalComponent,
				componentProps: { changeOrder: this.changeOrder, associatedLineItems: this.associatedLineItems, isAssociatedLIPopup: this.isAssociatedLIPopup },
				cssClass: this.isAssociatedLIPopup ? 'confirmation-popover' : ''
			});
			await saveChangeOrder.present();
			await saveChangeOrder.onDidDismiss().then(async () => {
				if (this.changeOrder.confirmSave) {
					let loading = await this.loadingCtrl.create({
						spinner: 'crescent',
						message: 'Saving Change Order...'
					});

					await loading.present();
					this.http.post(`${this.url()}events/${this.eventId}/accounts/${this.accountId}/change-orders`, this.changeOrder)
						.subscribe(data => {
							this.toastSvc.open('Success! Change Order Saved.', 'success');
							if (!this.boothGUID) {
								this.lineItemService.getUpdatedLineItem(this.eventId, this.accountId, this.functionalAreaId, this.lineItem.kafkaId).subscribe(li => {
									this.lineItem = li;
								});
								let lineItemStatus = StatusHelper.GetStatusFromId(this.changeOrder.currentStatus);

								this.lineItem.status = lineItemStatus;
								this.lineItemService.currentLineItem.next(this.lineItem);
								this.lineItemService.currentLineItemDto.forEach((lineItem) => {
									if (lineItem.kafkaId === this.lineItem.kafkaId) {
										lineItem.status = lineItemStatus;
									}
								});

								this.lineItemService.collaborationLineItemDto.next(this.lineItemService.currentLineItemDto);

								let currentFA = this.funcSvc.currentFunctionalArea;
								if (currentFA) {
									currentFA.status = lineItemStatus;
									this.funcSvc.currentFuncAreaSubject.next(currentFA);
								}
							} else {
								this.boothService.getBoothLiDetails(this.eventId, this.boothDetails.showAccountGUID, this.lineItem.kafkaId);
							}
							loading.dismiss();
							this.dismiss();
						}, error => {
							loading.dismiss();
							this.toastSvc.open('There was an error while saving your change order', 'danger');
						})
				}
			});
		}
	}

	setSelectableOptionValues(selectedValues) {
		this.changeOrder.partSelections = selectedValues;
		this.disableSave = this.changeOrder.partSelections.length !== this.selectedOptionsData.length ? true : false;
	}

	onCopyCheckboxChecked() {
		if (this.isCopyItemComment) {
			this.copyItemComment();
		}
	}

	copyItemComment() {
		this.changeOrder.ticketComments = this.changeOrder.vendorComments;
	}
}


