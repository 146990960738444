import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(dataSet: any, ...args: any): any {
    if(!dataSet)return null;
    if(!args)return dataSet;

    switch (args[0].type) {
      case 'events':
        {
          let results: Array<any> = [];
          results = _.filter(dataSet, function (freemanEvent) {
            return args[0].isActiveEventsVisible ? new Date(freemanEvent.closeDate).getTime() >= new Date().getTime() : new Date(freemanEvent.closeDate).getTime() < new Date().getTime();
          });

          results = _.filter(results, function (freemanEvent) {
            return freemanEvent.name.toLowerCase().startsWith(args[0].searchParam?.toLowerCase());
          });
          if(args[0].isActiveEventsVisible) {
            if(args[0].isSort) {
              return _.sortBy(_.orderBy(results, [function (event) { return event.name; }], ['asc',item => item.name.toLowerCase()]), ['isFavorite',item => item.name.toLowerCase()]);
            } else {
              return _.sortBy(_.orderBy(results, [function (event) { return new Date(event.openDate).getTime(); }], ['asc']), ['isFavorite']);
            }
          } else {
            if(args[0].isSort) {
              return _.sortBy(_.orderBy(results, [function (event) { return event.name; }], ['asc',item => item.name.toLowerCase()]), ['isFavorite',item => item.name.toLowerCase()]);
            } else {
              return _.sortBy(_.orderBy(results, [function (event) { return new Date(event.openDate).getTime(); }], ['asc']), ['isFavorite']);
            }
          }
          break;
      }

      case 'ops-line-items':
        {
          let results: Array<any> = [];
          if(args[0].searchParam){
            results = _.filter(dataSet, function (opsLineItem) {
              switch (args[0].lineItemType) {
                case "0":
                  return opsLineItem.booth?.toLowerCase().startsWith(args[0].searchParam?.toLowerCase()) || opsLineItem.ticketNumber?.toString().toLowerCase().startsWith(args[0].searchParam?.toLowerCase()) || opsLineItem.locationInfo?.toString().toLowerCase().startsWith(args[0].searchParam?.toLowerCase()) || opsLineItem.functionalAreaName?.toString().toLowerCase().startsWith(args[0].searchParam?.toLowerCase());
                  break
                case "1":
                  return opsLineItem.ticketNumber?.toString().toLowerCase().startsWith(args[0].searchParam?.toLowerCase()) || opsLineItem.partGroup?.toString().toLowerCase().startsWith(args[0].searchParam?.toLowerCase()) || opsLineItem.locationInfo?.toString().toLowerCase().startsWith(args[0].searchParam?.toLowerCase()) || opsLineItem.functionalAreaName?.toString().toLowerCase().startsWith(args[0].searchParam?.toLowerCase());
                  break
                case "2":
                  return opsLineItem.booth?.toLowerCase().startsWith(args[0].searchParam?.toLowerCase()) || opsLineItem.partGroup?.toString().toLowerCase().startsWith(args[0].searchParam?.toLowerCase());
                  break
                default:
                  return opsLineItem.booth?.toLowerCase().startsWith(args[0].searchParam?.toLowerCase()) || opsLineItem.ticketNumber?.toString().toLowerCase().startsWith(args[0].searchParam?.toLowerCase()) || opsLineItem.locationInfo?.toString().toLowerCase().startsWith(args[0].searchParam?.toLowerCase()) || opsLineItem.functionalAreaName?.toString().toLowerCase().startsWith(args[0].searchParam?.toLowerCase());
                  break;
              }
            });
          } else {
            results = dataSet
          }
          
          return results;
        }

      case 'ops-users':
        {
          let results: Array<any> = [];
          if(args[0].searchParam){
            results = _.filter(dataSet, function (opsUser) {
              return opsUser.firstName?.toLowerCase().startsWith(args[0].searchParam?.toLowerCase()) || opsUser.lastName?.toString().toLowerCase().startsWith(args[0].searchParam?.toLowerCase()) || opsUser.emailAddress?.toString().toLowerCase().startsWith(args[0].searchParam?.toLowerCase()) || (opsUser.firstName + " " + opsUser.lastName)?.toLowerCase().startsWith(args[0].searchParam?.toLowerCase());
            });
          } else {
            results = dataSet
          }
          return _.sortBy(results, ['firstName', 'lastName']);
        }

      case 'service-request-account':
        {
          let results: Array<any> = [];
          if(args[0].searchParam){
            results = _.filter(dataSet, function (accountData) {
              return accountData?.name?.toString().toLowerCase().startsWith(args[0].searchParam?.toLowerCase());
            });
          } else {
            results = dataSet
          }
          return _.sortBy(results, ['name']);
        }

      case 'service-request-booths':
        {
          let results: Array<any> = [];
          if(args[0].showAccountGUID){
            results = _.filter(dataSet, function (boothData) {
              return boothData?.showAccountGUID == args[0].showAccountGUID;
            });
          } else {
            results = dataSet
          }
          return _.sortBy(results, ['boothName']);
        }
        
        case 'esBooth-filter':
        {
          let results: Array<any> = [];
          if(args[0].searchParam){
            results = _.filter(dataSet, function (partData) {
              return partData?.name?.toString().toLowerCase().startsWith(args[0].searchParam?.toLowerCase());
            });
          } else {
            results = dataSet
          }
          return _.sortBy(results, ['name']);
        }
    
      default:
        return dataSet
        break;
    }
  }

}
