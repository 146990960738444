import { Injectable, Injector } from '@angular/core';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http';
import { AuthTokenHelper } from './auth-token-helper';
import { AppBrowserHelper } from './app-browser-helper';
import { from, Observable, throwError } from 'rxjs';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(
		private authTokenHelper: AuthTokenHelper,
		private injector: Injector,
		private authService: AuthenticationService,
		private appBrowserHepler: AppBrowserHelper) { }

	refreshInFlight = false;

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (request.url.includes('login') || request.url.includes('refreshLogin') || request.url.includes('version')) {
			return next.handle(request);
		}
		if (request.url.includes('sso')) {
			return from(this.configureSsoRequest(request, next));
		}
		if (request.url.includes('users/activity'))
			return from(this.configureActivityRequest(request, next));

		return from(this.configureRequest(request, next));
	}

	private async configureRequest(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
		try {
			const token = await this.authTokenHelper.GetToken();
			const idToken = await this.authTokenHelper.GetIDToken();
			this.refreshInFlight = false;
			return next.handle(this.addToken(request, token, idToken)).pipe(catchError((err: HttpErrorResponse) => {
				if (err.status == 401) {
					this.authService.logout();
				}
				return throwError(err);
			})).toPromise();
		} catch (error) {
			// if (!this.appBrowserHepler.isApp) {
			// 	return next.handle(request).toPromise();
			// }
			try {
				const refreshToken = await this.authTokenHelper.GetRefreshToken();
				if (!this.refreshInFlight) {
					this.refreshInFlight = true;
					const token = await this.getNewToken(refreshToken);
					const idToken = await this.authTokenHelper.GetIDToken();
					this.refreshInFlight = false;
					return next.handle(this.addToken(request, token, idToken)).toPromise();
				} else {
					setTimeout(() => {
						return this.configureRequest(request, next);
					}, 500);
				}
			} catch (error) {
				return next.handle(request).toPromise();
			}
		}
	}

	private async configureSsoRequest(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
		const token = await this.authTokenHelper.GetAccessToken();
		if (token) {
			return next.handle(this.addToken(request, token)).toPromise();
		}
		return next.handle(request).toPromise();
	}

	private async configureActivityRequest(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
		return next.handle(this.addToken(request, this.authService.token, this.authService.idToken)).toPromise();
	}

	private async getNewToken(refreshToken: string): Promise<string> {
		const authSvc = this.injector.get(AuthenticationService);
		try {
			const newToken = await authSvc.refresh(refreshToken);
			return Promise.resolve(newToken);
		} catch (error) {
			return Promise.reject('Unable to retrieve a new token with the refresh token');
		}
	}

	private addToken(request, token, idToken?): HttpRequest<any> {
		if (token) {
			request = request.clone({
				setHeaders: {
					Authorization: 'Bearer ' + token, // this should be the Freeman access token
					IDToken: idToken || '',
				}
			});
		}

		return request.clone({
			setHeaders: {
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache'
			}
		});
	}
}
