import { Component, Input, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { WorkTicketGroup } from '../../models/work-ticket-group';
import { WorkTicketService } from '../../services/work-ticket/work-ticket.service';
import { WorkTicketDTO } from '../../models/work-ticket-dto';
import { Status } from '../../models/status';
import { WorkticketLineItemService } from '../../services/workticket-line-item/workticket-line-item.service';
import { StatusHelper } from '../../helpers/status-helper';
import { ChangeStatusMenuComponent } from '../change-status-menu/change-status-menu.component';
import { CollaborationLineItemDTO } from 'src/app/models/collaboration-line-item-dto';
import { FunctionalArea } from 'src/app/models/functional-area';
import { ShowBooth } from 'src/app/models/show-booth-dto';

@Component({
	selector: 'app-bulk-update-modal',
	templateUrl: './bulk-update-modal.component.html',
	styleUrls: ['./bulk-update-modal.component.scss'],
})
export class BulkUpdateModalComponent implements OnInit {

	@Input() eventId: string;
	@Input() isFAView: boolean;
	@Input() wtGroup: WorkTicketGroup;
	@Input() functionalArea?: FunctionalArea;
	@Input() lineItem?: CollaborationLineItemDTO;
	@Input() workTicket?: WorkTicketDTO;
	@Input() workTicketLineItem?: CollaborationLineItemDTO;
	@Input() boothData?: ShowBooth;
	@Input() boothLineItem?: CollaborationLineItemDTO;
	newStatus: Status;
	filteredWorkTickets: WorkTicketDTO[];
	menuOpen: boolean;
	popover: any;
	ticketsToUpdate: WorkTicketDTO[] = [];

	constructor(
		public workTicketSvc: WorkTicketService,
		public popoverCtrl: PopoverController,
		public workTicketLineItemSvc: WorkticketLineItemService,
		public modalCtrl: ModalController,
	) {
	}

	ngOnInit() {
		if (this.isFAView) {
			this.workTicketSvc.allWorkTicketsSubject.subscribe(wts => {
				this.filteredWorkTickets = wts.filter(wt => {
					return (wt.functionalAreaName && wt.functionalAreaName.toLowerCase() === this.wtGroup.groupName.toLowerCase());
				});
			});
		} else {
			this.workTicketSvc.allWorkTicketsSubject.subscribe(wts => {
				this.filteredWorkTickets = wts.filter(wt => {
					return (wt.partGroup && wt.partGroup.toLowerCase() === this.wtGroup.groupName.toLowerCase());
				});
			});
		}
	}

	async presentPopover(ev: any) {
		this.popover = await this.popoverCtrl.create({
			component: ChangeStatusMenuComponent,
			cssClass: 'change-status-popup',
			event:ev
		});
		await this.popover.present();

		const selectedStatus = await this.popover.onDidDismiss();
		if(selectedStatus.data && selectedStatus.data.newStatus){
			this.newStatus = selectedStatus.data.newStatus;
		}
		this.toggleMenu();
	}

	toggleMenu() {
		this.menuOpen = !this.menuOpen;
	}

	confirm() {
		this.ticketsToUpdate.forEach(wt => {
			this.workTicketSvc.put(this.eventId, wt, this.newStatus, this.workTicketLineItemSvc.collaborationLineItemDto);
		});
		this.modalCtrl.dismiss();
	}

	dismiss() {
		this.ticketsToUpdate = [];
		this.modalCtrl.dismiss();
	}

	checkMark(workTicket) {
		const queuedTicket = this.ticketsToUpdate.findIndex(wt => wt.workTicketGuid === workTicket.workTicketGuid);
		if (queuedTicket !== -1) {
			this.ticketsToUpdate.splice(queuedTicket, 1);
		} else {
			this.ticketsToUpdate.push(workTicket);
		}
	}

	isWtChecked(workTicket) {
		return this.ticketsToUpdate.find(wt => wt.workTicketGuid === workTicket.workTicketGuid);
	}

	selectAllChecked() {
		if (this.ticketsToUpdate && this.filteredWorkTickets) {
			return this.ticketsToUpdate.length === this.filteredWorkTickets.length;
		}
		return false;
	}

	selectAll() {
		this.ticketsToUpdate = this.ticketsToUpdate.length === this.filteredWorkTickets.length ? [] : this.filteredWorkTickets.slice();
	}
}
